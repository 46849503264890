import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import constants from 'app/constants';

// import en from './en/translation.json';
// import { convertLanguageJsonToObject } from './translations';

// export const translationsJson = {
//   en: {
//     translation: en,
//   },
// };

// // Create the 'translations' object to provide full intellisense support for the static json files.
// convertLanguageJsonToObject(en);

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(Backend)
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // resources: translationsJson,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV === 'development' && process.env.REACT_APP_I18N_DEBUG
        ? true
        : false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `${constants.env.REACT_APP_CIO_API_SERVER}/api/v1/translations/{{lng}}/{{ns}}.json`,
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  });

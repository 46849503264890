import { Size } from 'app/design-lib/types';
import React, { useState } from 'react';
import { User as UserIcon } from 'iconoir-react';

export interface AvatarProps {
  initials?: string;
  src?: string;
  size?: AvatarSize | string;
  active?: boolean;
}

export type AvatarSize = Size | 'xl';

const Avatar = ({ src, initials, size = 'md', active }: AvatarProps) => {
  // if source is passed but bad link, force to use initials or avatar
  const [ignoreSrc, setIgnoreSrc] = useState(false);
  let avatarClassName: string;
  let badgeClassName: string;

  switch (size) {
    // case 'xs':
    //   avatarClassName = `h-4 w-4 text-micro font-medium`;
    //   badgeClassName = `h-1.5 w-1.5 border-[1px] -right-[1px] -bottom-[1px]`;
    //   break;
    case 'sm':
      avatarClassName = `h-5 w-5 text-xs font-medium`;
      badgeClassName = `h-1.5 w-1.5 border-[1px]`;
      break;
    case 'md':
      avatarClassName = `h-6 w-6 text-sm font-medium`;
      badgeClassName = `h-2.5 w-2.5 border-[1.5px]`;
      break;
    case 'lg':
      avatarClassName = `h-10 w-10 text-lg font-medium`;
      badgeClassName = `h-2.5 w-2.5 border-[1.5px]`;
      break;
    case 'xl':
      avatarClassName = `h-12 w-12 text-xl font-medium`;
      badgeClassName = `h-3 w-3 border-[1.5px]`;
  }

  return (
    <div className={'rounded-full w-min h-min   relative'}>
      <div
        // @ts-ignore
        className={`text-white rounded-full grid place-items-center border-border-neutral bg-gray-60  ${avatarClassName} aspect-square`}
      >
        {src && !ignoreSrc ? (
          <img
            onError={() => setIgnoreSrc(true)}
            // @ts-ignore
            className={`${avatarClassName} rounded-full`}
            src={src}
            alt="Rounded avatar"
          />
        ) : initials ? (
          <span className={`uppercase font-sans font-medium`}>{initials}</span>
        ) : (
          <UserIcon fr={undefined} />
        )}
      </div>
      {/*
      {typeof active === 'boolean' ? (
        <span
          className={`absolute border-white ${badgeClassName} right-0 bottom-0 transition-colors duration-300 rounded-full aspect-square  ${
            active ? 'bg-green-50' : 'bg-gray-20'
          }`}
        ></span>
      ) : null}
*/}
    </div>
  );
};
export default Avatar;

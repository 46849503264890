import { Tooltip } from 'app/design';
import { Trash as DeleteIcon } from 'iconoir-react';

import { getAtPath, setAtPath } from 'app/utilities';
import React, { memo, useRef } from 'react';
import { Handle } from 'react-flow-renderer';

// import useStyles from './styles';
import { useHoverDirty } from 'react-use';

import { InTemplate } from '../common/InTemplate';
import { Button, IconButton } from '../../../../../design-lib';
import { Repeat, Timer } from 'iconoir-react';

const OptionNode = memo(({ data }) => {
  const {
    skipEditing,
    callflow,
    setCallflow,
    modifyPath, // to ".targets"
    targetKey,
    text,
    templateParent,
  } = data;

  const optionRef = useRef(null);
  const isHovering = useHoverDirty(optionRef);

  // const classes = useStyles({ mode: isHovering ? 'normal' : 'ignore' });

  const handleRemove = () => {
    const targets = getAtPath(callflow, modifyPath);
    delete targets[targetKey];
    setAtPath(callflow, modifyPath, targets);
    setCallflow({ ...callflow });
  };

  let reservedWords = {
    _: {
      text: 'Invalid Entry',
      icon: <Repeat />,
    },
    timeout: {
      text: 'Timeout',
      icon: <Timer />,
    },
  };

  const isReserved = reservedWords.hasOwnProperty(text) ? true : false;
  const isWide = text.length > 1 || isReserved ? true : false;

  return (
    <div
      ref={optionRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      <Handle
        type="target"
        position="top"
        style={{ top: 4, background: '#555', visibility: 'hidden' }}
        onConnect={params => console.log('handle onConnect', params)}
      />
      <Button
        // disabled
        variant={isReserved ? 'fill' : 'outline'}
        size={'sm'}
        color={isReserved ? 'neutral' : 'accent'}
        startIcon={reservedWords[text]?.icon}
        className={`min-w-[24px]`}
        // className={`border border-neutral-60 rounded text-white drop-shadow-lg bg-neutral-50 text-sm p-1 px-2`}
        // disabled={skipEditing}
        // onClick={handleClick}
      >
        <span>
          {isReserved ? (
            reservedWords[text].text
          ) : Number.isInteger(Number.parseInt(text)) ? (
            <div className="">
              <span className="font-thin">
                Key{text?.length > 1 ? 's' : ''}:{' '}
              </span>
              <span className="font-bold inline-block  bg-accent-60 text-white px-2 rounded">
                {text}
              </span>
            </div>
          ) : (
            text
          )}
        </span>
      </Button>
      {skipEditing || isReserved ? null : (
        <span
          className={`opacity-50 hover:opacity-100`}
          style={{ position: 'absolute', top: 0, right: -28 }}
        >
          <Tooltip
            title={<>Remove option: {text}</>}
            placement="bottom"
            type="light"
            arrow
          >
            <IconButton
              onClick={handleRemove}
              size="sm"
              variant="ghost"
              color="neutral"
              pill
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      )}
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 4,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {/*<div
        style={{
          position: 'relative',
          // width: isWide ? 80 : 30,
          // height: isWide ? null : 30,
          // borderRadius: isWide ? 3 : 15,
          // border: '1px solid #ddd',
          // background: 'rgb(254, 255, 255)',
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
        className={`border border-neutral-60 rounded text-white drop-shadow-lg bg-neutral-50 text-sm p-1 px-2`}
        // className={`
        //     inline-block
        //     rounded ring-2
        //     px-2
        //     py-2
        //     ${
        //       isReserved
        //         ? `
        //     ring-purple-600 text-md text-purple-500 leading-normal shadow-md
        //     bg-purple-100/25
        //     `
        //         : `
        //         ring-emerald-600 text-lg text-emerald-500 leading-normal shadow-md
        //     bg-emerald-100/50
        //     `
        //     }
        //
        // `}
      >
        <Handle
          type="target"
          position="top"
          style={{ top: 4, background: '#555', visibility: 'hidden' }}
          onConnect={params => console.log('handle onConnect', params)}
        />
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <span>{isReserved ? reservedWords[text].text : text}</span>
          {skipEditing || isReserved ? null : (
            <span
              // className={classes.opacity}
              style={{ position: 'absolute', top: 0, right: -34 }}
            >
              <Tooltip
                title={<>Remove this option/route</>}
                placement="bottom"
                type="light"
                arrow
              >
                <IconButton
                  onClick={handleRemove}
                  size="small"
                  variant="outlined"
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </span>
          )}
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </div>*/}
    </div>
  );
});

export default OptionNode;

import { Box, Grid, ThemeProvider, Typography } from 'app/design';
import { cloneDeep, merge } from 'lodash';
import React, { ReactNode } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface ViewContainerProps {
  title?: ReactNode;
  searchComponent?: ReactNode;
  titleAction?: ReactNode;
  children?: ReactNode;
  show: boolean;
}

const ViewContainer = ({
  title,
  searchComponent,
  titleAction,
  children,
  show,
}: ViewContainerProps) => {
  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto',
        display: show ? 'block' : 'none',
        // @ts-ignore
        // background: theme => theme.palette.content.background,
        py: '20px',
        px: '30px',
      }}
    >
      {/* <Grid
        container
        alignItems={'center'}
        sx={{ height: 35 }}
        justifyContent={'space-between'}
      >
        <Grid item>
          <Typography variant={'h6'} sx={{ fontSize: '18px' }}>
            {title}
          </Typography>
        </Grid>
        {titleAction ? <Grid item>{titleAction}</Grid> : null}
      </Grid>
      <br /> */}

      {searchComponent ? (
        <>
          {/* <ThemeProvider
            // @ts-ignore
            theme={outerTheme => {
              return merge(cloneDeep(outerTheme), {
                components: {
                  MuiInputAdornment: {
                    styleOverrides: {
                      root: {
                        // @ts-ignore
                        // color: `${outerTheme.palette.primary.dark} !important`,
                      },
                    },
                  },
                  MuiAutocomplete: {
                    styleOverrides: {
                      input: {
                        width: '100%',
                        // color: 'white !important',
                        '&::placeholder': {
                          textOverflow: 'ellipsis',
                          color: 'rgba(15, 97, 113, 0.53)',
                          opacity: 1,
                        },
                        color: 'rgba(15, 97, 113, 0.53)',
                      },
                      endAdornment: {
                        opacity: 0,
                      },
                      // groupLabel: {
                      //   fontStyle: 'italic',
                      //   fontWeight: 700,
                      //   borderTop: '1px solid #eee',
                      //   borderBottom: '1px solid #fafafa',
                      // },
                      listbox: {
                        maxHeight: '80vh',
                      },
                    },
                  },
                  MuiOutlinedInput: {
                    styleOverrides: {
                      root: {
                        padding: '4px !important',
                        background: 'white',
                      },
                      notchedOutline: {
                        // borderColor: 'white !important',
                        borderColor: 'rgba(27, 125, 144, 0.18) !important',
                        borderWidth: '1px !important',
                      },
                    },
                  },
                },
              });
            }}
          >*/}
          {searchComponent}
          {/*</ThemeProvider>*/}
        </>
      ) : null}
      <Box sx={{}}>{children}</Box>
    </Box>
  );
};

export default ViewContainer;

import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  TextField,
} from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateMediaFull } from 'app/hooks/mutations/media';
import useMediaQuery from 'app/hooks/queries/media/useMediaQuery';
import {
  parseAndSetKazooMutationErrors,
  useToggleReducer,
} from 'app/utilities';
import Joi from 'joi';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'app/design-lib';
import { Clock, EditPencil } from 'iconoir-react';
import { useState } from 'react';

interface NameForm {
  name: string;
}

// interface declaring which props are required/allowed
interface ContentProps {
  onCancel: () => void;
  initialName?: string;
  onComplete: (name: string) => void;
  moduleType: string;
  allowEmpty?: boolean;
}

interface EditModuleNameDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

const EditModuleNameDialog = ({
  ContentProps,
  DialogProps,
}: EditModuleNameDialogProps) => {
  return (
    <Dialog open={DialogProps.open} onClose={ContentProps.onCancel}>
      <EditModuleNameDialogContent {...ContentProps} />
    </Dialog>
  );
};

const EditModuleNameDialogContent = ({
  onCancel,
  onComplete,
  initialName,
  moduleType,
  allowEmpty,
}: ContentProps) => {
  console.log('allow empty', allowEmpty);
  const formMethods = useForm<NameForm>({
    defaultValues: {
      name: initialName,
    },
    resolver: joiResolver(
      Joi.object({
        name: allowEmpty
          ? Joi.string().allow('').max(128).optional()
          : Joi.string().max(128).required(),
      }),
    ), // pass in custom validation
  });
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
    setError,
    watch,
  } = formMethods;

  const onSubmit = async ({ name }: NameForm) => {
    onComplete(name);
  };

  return (
    <>
      <DialogHeader
        title={`Edit ${moduleType} Module Name`}
        onClose={onCancel}
      />
      <DialogContent>
        <TextField
          {...register('name')}
          autoComplete={'off'}
          // fullWidth
          autoFocus
          name={'name'}
          label={'Name'}
          hasError={!!formErrors['name']}
          helperText={formErrors['name']?.message}
        />
      </DialogContent>
      <DialogActions className={`justify-between`}>
        <Button
          size={'md'}
          onClick={onCancel}
          color={'negative'}
          variant={'fill'}
        >
          Cancel
        </Button>
        <Button
          size={'md'}
          onClick={handleSubmit(onSubmit)}
          color={'accent'}
          variant={'fill'}
        >
          Update Name
        </Button>
      </DialogActions>
    </>
  );
};

const EditModuleNameButton = ({
  icon,
  name,
  onUpdate,
  moduleType,
  allowEmpty,
  skipEditing,
}) => {
  const [open, toggleOpen] = useToggleReducer();
  const [showIcon, setShowIcon] = useState(false);

  return (
    <>
      <EditModuleNameDialog
        DialogProps={{ open }}
        ContentProps={{
          allowEmpty,
          onCancel: toggleOpen,
          onComplete: onUpdate,
          moduleType,
          initialName: name,
        }}
      />
      <div
        onMouseOver={() => setShowIcon(true)}
        onMouseOut={() => setShowIcon(false)}
        className={`text-sm ${
          skipEditing ? 'pointer-events-none' : ''
        } flex items-center space-x-2 whitespace-nowrap`}
      >
        {React.cloneElement(icon, { width: 14, height: 14 })}
        <button
          onClick={toggleOpen}
          className={`hover:underline max-w-[120px] flex space-x-1 hover:text-accent-60`}
        >
          <p className={`truncate`}>{name}</p>
          <EditPencil
            className={`${showIcon ? '' : 'opacity-0'}`}
            width={14}
            height={14}
            fr={undefined}
          />
        </button>
      </div>
    </>
  );
};

export default EditModuleNameButton;

import { Button, Grid } from 'app/design';
import { Person as PersonIcon } from 'app/design/icons-material';
import { useQuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useUserQuery } from 'app/hooks/queries/user';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

const OwnerInput = ({ value, onChange }) => {
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  const { data: user, isFetching } = useUserQuery(value);

  const {
    toggleOpen: toggleOpenQuickFinderUserDialog,
    Dialog: QuickFinderUserDialog,
    DialogProps: QuickFinderUserDialogProps,
  } = useQuickFinderUserDialog({});

  const handleSelect = (selected: string[]) => {
    const [userId] = selected;
    toggleOpenQuickFinderUserDialog();
    onChange(userId);
  };

  const handleClear = () => {
    toggleOpenQuickFinderUserDialog();
    onChange(null);
  };

  return (
    <>
      <QuickFinderUserDialog
        {...QuickFinderUserDialogProps}
        onSelect={handleSelect}
        onCancel={toggleOpenQuickFinderUserDialog}
        onClear={handleClear}
        initialSelected={value ? [value] : undefined}
        selectionTitle={
          user ? `${user.doc.first_name} ${user.doc.last_name}` : null
        }
      />
      <Button
        onClick={toggleOpenQuickFinderUserDialog}
        variant={'outlined'}
        sx={{ maxWidth: 130, minWidth: 130, maxHeight: 36.5 }}
        loading={isFetching}
      >
        <Grid container alignItems={'center'} spacing={1} wrap={'nowrap'}>
          <Grid item>
            <div
              style={{
                display: 'inline-block',
                paddingTop: 5,
              }}
            >
              <PersonIcon fontSize={'small'} />
            </div>
          </Grid>
          <Grid item>
            <div style={{ overflow: 'hidden', width: '75px' }}>
              <span
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '75px',
                }}
              >
                {user
                  ? `${user.doc.first_name} ${user.doc.last_name}`
                  : 'no owner'}
              </span>
            </div>
          </Grid>
        </Grid>
      </Button>
    </>
  );
};

export default OwnerInput;

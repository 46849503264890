import { Grid, Typography, Box } from 'app/design';
import { ContactNameDisplay } from 'app/components/Webphone/UI/ContactsView/components/CombinedContacts/CombinedContacts';
import React from 'react';
import { Contact } from 'types/contact';
import { AvatarCell } from '../../../ListUsers/components/AvatarCell';
import { PhoneNumberDisplay } from '../../../PhoneNumberDisplay';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import { Avatar } from 'app/design-lib';

interface ContactDisplayProps {
  contact: Contact;
  number?: string;
  notification?: boolean;
}

const ContactDisplay = ({
  contact,
  number,
  notification,
}: ContactDisplayProps) => {
  return (
    <div className={'flex items-center space-x-2'}>
      {contact.user ? (
        <UserAvatar user={contact.user} size={'sm'} />
      ) : (
        <Avatar
          size={'sm'}
          initials={
            `${contact.info?.firstName?.[0] ?? ''}${
              contact.info?.lastName?.[0] ?? ''
            }` ?? contact.info.company[0]
          }
        />
      )}
      <span className={`text-neutral-60 text-sm`}>
        {contact.info?.firstName + ' ' + contact.info?.lastName}
      </span>
      {/*  <AvatarCell
        resource={{
          doc: {
            first_name: contact.info.firstName,
            last_name: contact.info.lastName,
            company: contact.info.company,
          },
        }}
        size={36}
        sx={{ mr: 1 }}
      />
      <div>
        <ContactNameDisplay contact={contact} />

        {number ? (
          // <Grid item sx={{ textAlign: 'right' }}>
          <PhoneNumberDisplay ptn={number} />
        ) : // </Grid>
        undefined}
      </div>
*/}
      {/*<Grid item sx={{ flex: 1 }}>
          <Typography>
            {contact.info.firstName ? (
              contact.info.lastName?.length ? (
                <Typography sx={{ display: 'inline-block' }}>
                  {contact.info.firstName}&nbsp;
                </Typography>
              ) : (
                <Typography
                  sx={{ display: 'inline-block', fontWeight: 'bold' }}
                >
                  {contact.info.firstName}
                </Typography>
              )
            ) : null}
            {contact.info.lastName ? (
              <Typography sx={{ display: 'inline-block', fontWeight: 'bold' }}>
                {contact.info.lastName}{' '}
              </Typography>
            ) : null}
          </Typography>
          {contact.info?.company?.length ? (
            contact.info.firstName || contact.info.lastName ? (
              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                {contact.info?.company}
              </Typography>
            ) : (
              <Typography sx={{ display: 'inline-block', fontWeight: 'bold' }}>
                {contact.info?.company}
              </Typography>
            )
          ) : null}
        </Grid>*/}
    </div>
  );
};

export default ContactDisplay;

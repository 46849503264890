import React, { memo, useRef } from 'react';
import { Handle } from 'react-flow-renderer';

const OptionHiddenNode = memo(({ data }) => {
  const optionRef = useRef(null);
  return (
    <>
      <div
        ref={optionRef}
        style={{
          display: 'flex',
          justifyContent: 'center',
          // border: '1px solid #ddd',
          // borderRadius: 4,
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ top: 'auto', background: '#555', visibility: 'hidden' }}
          // onConnect={params => console.log('handle onConnect', params)}
        />
        <div className="h-1">&nbsp;</div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    </>
  );
});

export default OptionHiddenNode;

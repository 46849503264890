import React, { useEffect, useState } from 'react';
import { Box, Button, TextareaAutosize } from 'app/design';

import { isArray, isFunction } from 'lodash';
import copy from 'copy-to-clipboard';

const NotesEditor = props => {
  const { call, showNotes, onUpdate, defaultNotes } = props;

  const [copied, setCopied] = useState(null);
  const handleCopy = () => {
    const didCopy = copy(notes.data);
    setCopied(didCopy ? true : null);
  };

  const [notes, setNotes] = useState(
    defaultNotes || { type: 'text', data: '' },
  );

  const handleChangeNotes = e => {
    setCopied(null);
    setNotes({
      type: 'text',
      data: e.target.value,
    });
  };

  useEffect(() => {
    onUpdate(notes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  if (!showNotes) {
    return null;
  }

  // TODO: handle editing in SlateJS (for markdown,etc)

  return (
    <Box
      sx={{
        padding: 1,
        paddingBottom: 0,
        lineHeight: '10px',
        '& textarea': {
          width: '100%',
          fontFamily: 'inherit',
          resize: 'vertical',
          minHeight: 23,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Box sx={{ flex: 1, marginRight: 2, paddingTop: 1 }}>
          <TextareaAutosize
            style={{ border: '1px solid #ccc' }}
            placeholder="Type notes here"
            value={notes.data}
            onChange={handleChangeNotes}
          />
        </Box>
        <Box sx={{ width_old: 80, textAlign: 'left' }}>
          <Button size="xsmall" variant="default" onClick={handleCopy}>
            {copied ? 'Copied!' : 'Copy'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotesEditor;

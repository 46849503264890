import { Tooltip } from 'app/design-lib';
import { InfoOutlined as InfoIcon } from 'app/design/icons-material';
import React from 'react';

export enum KeywordType {
  Exact,
  Free,
}

export type Keywords = {
  [key: string]: { values?: string[]; description: string; type: KeywordType };
};

export interface KeywordTooltipProps {
  keywords: Keywords;
}

const KeywordTooltip = ({ keywords }: KeywordTooltipProps) => {
  return (
    <Tooltip
      content={
        <div>
          <span className={'text-lg'}>Keyword search:</span>
          <span className={'text-sm italic'}>
            format: keyword:value (ex: 'name:John')
          </span>
          <table>
            {Object.entries(keywords).map(
              ([keyword, { description, values, type }]) => (
                <tr>
                  <th>
                    <span className={'font-medium'}>{`${keyword}${
                      type === KeywordType.Exact ? '' : '*'
                    }:`}</span>
                  </th>
                  <th>
                    <span>{`${description} ${
                      !!values ? `(${values.join(', ')})` : ''
                    }`}</span>
                  </th>
                </tr>
              ),
            )}
          </table>
          <span className={'text-sm italic'}>
            * keywords marked with '*' can use wildcards in the search value
            (ex: 'ext:10*' will match 101, 102 etc.)
          </span>
        </div>
      }
    >
      <span
        className={'text-content-accent'}
        // sx={{ color: theme => theme.palette.primary.main, fontSize: 14 }}
      >
        <InfoIcon fontSize={'inherit'} />
      </span>
    </Tooltip>
  );
};

export default KeywordTooltip;

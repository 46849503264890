import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Button, Listbox } from 'app/design-lib';

import { useListVmboxesQuery, useVmboxQuery } from 'app/hooks/queries/vmbox';
import { usePipesQuery } from 'app/pages/v2/common/sdk';

import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import { UserName } from 'app/components/UserName';

import { Voicemail as VoicemailIcon } from 'app/design/icons-material';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';
import { AudioPlayer } from '../../../../AudioPlayer';
import { useBuilderContext } from 'app/components/IvrBuilder/IvrBuilder';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { Emoji } from 'emoji-picker-react';
// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';

import { Box } from '../common/Box';
import { ModifyNodeDialogVmbox } from './ModifyNodeDialogVmbox';
import { InTemplate } from '../common/InTemplate';
import { MenuButton } from '../common/MenuButton';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';
import { GreetingDialog } from '../Menu/GreetingDialog';
import { setAtPath } from '../../../../../utilities';
import { Dialpad, Strategy, Voice, WarningTriangle } from 'iconoir-react';
import { Tooltip } from '@mui/material';
import { VmboxGreetingDialog } from './VmboxGreetingDialog';
import { useUpdateVmboxPartial } from '../../../../../hooks/mutations/vmbox';
import useEnsureModuleItemId from '../common/useEnsureModuleItemId';
import { ModeSelectModuleThis } from '../common/ModeSelectModule';
import { EditModuleNameDialog } from '../../../../EditModuleNameDialog';

// why use memo??
const VoicemailNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
    pipe,
  } = data;

  // add id if does not exist
  useEnsureModuleItemId(data);

  const { setDisableScroll } = useBuilderContext();

  // Load vmbox via query
  const {
    data: vmbox,
    isLoading,
    isFetched,
  } = useVmboxQuery({ id: moduleItem?.data?.id });
  const pipeListQuery = usePipesQuery({
    // skip: queryPageIndex * queryPageSize,
    // take: queryPageSize,
    // orderBy: [['lastName', 'asc']],
    filters: {
      // searchInput: querySearchInput,
      NOT: {
        type: 'room',
      },
    },
  });
  const { pipes = [], totalCount = 0 } = pipeListQuery.data ?? {};
  const vmboxPipe = pipes?.find(p => p.id === vmbox?.doc?.pipe_id);
  const {
    data: vmboxesPage,
    isLoading: vmboxesLoading,
    refetch: vmboxesRefetch,
  } = useListVmboxesQuery({
    take: 500,
    filters: {
      // ids: pipe.vmboxes,
      // context: {
      //   ownerIds: pipe.id, // TODO: use pipe_id instead of owner_id ?
      // },
      // raw: [
      //   {
      //     variables: {
      //       docFilters: [['pipe_id', '=', pipe?.id]],
      //     },
      //   },
      // ],
    },
    options: {},
  });
  const vmboxes = vmboxesPage?.vmboxes ?? [];
  // const [vmbox] = vmboxes;
  const greeting_id = vmbox?.doc?.media?.unavailable;
  const updateVmbox = useUpdateVmboxPartial();
  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  const { invalidModules, setInvalidModules } = useBuilderContext();
  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${modifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  let possibleVariables = [
    {
      id: 'vmbox_id',
      name: 'Voicemail Box ID',
      read: {
        type: 'vmbox_id',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.id',
        },
      },
    },
    {
      id: 'vm_max_msg_length', // max message length (500)
      name: 'Max Message Length',
      read: {
        type: 'vm_max_msg_length',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.max_message_length',
        },
      },
    },
  ];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    // if (data.optsPath === modifyPath) {
    //   setShowModifyDialog(true);
    // }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateName = name => {
    setAtPath(callflow, `${modifyPath}.name`, name);
    setCallflow({ ...callflow });
  };

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <ModeSelectModuleThis id={moduleItem?.id} />
      <VmboxGreetingDialog
        onComplete={async mediaId => {
          await updateVmbox.mutateAsync({
            id: vmbox?.id,
            media: {
              ...vmbox?.doc.media,
              unavailable: mediaId,
            },
          });
          setShowModifyDialog(false);
          vmboxesRefetch();
        }}
        isLoading={updateVmbox.isLoading}
        onClose={() => setShowModifyDialog(false)}
        open={!!showModifyDialog}
        greeting_id={greeting_id}
      />
      <div
        className={`flex flex-col items-center  whitespace-nowrap w-full pt-4 px-2 pb-2`}
      >
        <EditModuleNameDialog
          icon={<Voice />}
          name={moduleItem.name || 'Voicemail'}
          onUpdate={handleUpdateName}
          moduleType={'Voicemail'}
          allowEmpty
          skipEditing={skipEditing}
        />

        <div
          className={`text-md w-full flex flex-col text-center justify-center font-medium`}
        >
          {skipEditing ? (
            vmbox ? (
              <div className={'flex space-x-2  min-w-0 mx-auto items-center'}>
                {vmboxPipe?.type === 'personal' ? (
                  <UserAvatar user_ext={vmboxPipe?.key} size="sm" />
                ) : vmboxPipe?.metadata.name ? (
                  <Emoji
                    unified={vmboxPipe?.metadata?.emoji || '1f937'}
                    size={16}
                  />
                ) : null}
                <div className="text-left py-2">
                  {vmboxPipe?.type === 'personal' ? (
                    <div>
                      <UserName user_ext={vmboxPipe?.key} />
                    </div>
                  ) : vmboxPipe?.metadata.name ? (
                    <div>{vmboxPipe?.metadata.name}</div>
                  ) : null}
                  <div className={'truncate text-neutral-60 text-xs'}>
                    <PhoneNumberDisplay hideFlag ptn={vmbox.doc.mailbox} />
                  </div>
                </div>
              </div>
            ) : (
              <Button
                color={'neutral'}
                variant={'ghost'}
                size={'sm'}
                className={`self-center`}
                startIcon={<WarningTriangle fr={undefined} />}
                // onClick={() => setShowModifyDialog(true)}
                disabled
              >
                not assigned
              </Button>
            )
          ) : (
            <Listbox
              // disabled={skipEditing}
              size={'sm'}
              placeholder="Select Line Voicemail"
              className={`w-full mt-1 self-center`}
              onOpenChanged={setDisableScroll}
              options={vmboxes
                // .filter(pipe => pipe.type === 'external')
                .map(vmbox => {
                  const pipe = pipes.find(p => p.id === vmbox.doc?.pipe_id);
                  return {
                    label: (
                      <div className={'flex space-x-2  min-w-0 items-center'}>
                        {pipe?.type === 'personal' ? (
                          <UserAvatar user_ext={pipe?.key} size="sm" />
                        ) : pipe?.metadata.name ? (
                          <Emoji
                            unified={pipe?.metadata?.emoji || '1f937'}
                            size={16}
                          />
                        ) : null}
                        <div className="text-left py-2">
                          {pipe?.type === 'personal' ? (
                            <div>
                              <UserName user_ext={pipe?.key} />
                            </div>
                          ) : pipe?.metadata.name ? (
                            <div>{pipe?.metadata.name}</div>
                          ) : null}
                          <div className={'truncate text-neutral-60 text-xs'}>
                            <PhoneNumberDisplay
                              hideFlag
                              ptn={vmbox.doc.mailbox}
                            />
                          </div>
                        </div>
                      </div>
                    ),
                    value: vmbox.id,
                  };
                })}
              value={moduleItem?.data?.id}
              onChange={id => {
                handleUpdate({
                  ...(moduleItem ?? {}),
                  data: {
                    ...(moduleItem?.data ?? {}),
                    id,
                    // timeout: timeoutDefault,
                  },
                });
              }}
            />
          )}
          {vmbox ? (
            <>
              {greeting_id ? (
                <AudioPlayer
                  mini
                  variant={'ghost'}
                  mediaId={greeting_id}
                  // onEdit={
                  //   skipEditing ? undefined : () => setShowModifyDialog(true)
                  // }
                  updated={vmbox?.updatedAt}
                />
              ) : (
                <Button
                  color={'attention'}
                  variant={'ghost'}
                  size={'sm'}
                  className={`self-center`}
                  startIcon={<WarningTriangle />}
                  onClick={() => setShowModifyDialog(true)}
                  disabled={skipEditing}
                >
                  {skipEditing ? 'No' : 'Add'} greeting
                </Button>
              )}
            </>
          ) : null}
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            type={'termination'}
            current={'VoicemailBox'}
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              'replace',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );

  // return (
  //   <>
  //     <Box
  //       pref={boxRef}
  //       borderColor="#1E88E5"
  //       position={data?.position}
  //       data={data}
  //       // height={100}
  //       // onHeight={}
  //       // onDimensions={handleDimensions}
  //     >
  //       {showModifyDialog && (
  //         <ModifyNodeDialogVmbox
  //           {...data}
  //           vmboxId={moduleItem?.data?.id}
  //           // vmbox={vmbox}
  //           onClose={setShowModifyDialogWrap(null)}
  //         />
  //       )}
  //       {showVariables && (
  //         <VariablesEditor // Dialog
  //           {...data}
  //           possibleVariables={possibleVariables}
  //           onClose={setShowVariablesWrap(null)}
  //         />
  //       )}
  //       <Handle
  //         type="target"
  //         position="top"
  //         style={{ background: '#555', visibility: 'hidden' }}
  //       />
  //       <div style={{ position: 'relative' }}>
  //         {skipEditing ? (
  //           // display for when skipping editing (QuickDisplay)
  //           <NodeInfoDisplay
  //             type={'Voicemail'}
  //             icon={<VoicemailIcon />}
  //             title={
  //               vmbox
  //                 ? `${vmbox?.doc?.mailbox} - ${vmbox?.doc?.name}`
  //                 : 'None Selected'
  //             }
  //             content={
  //               vmbox ? (
  //                 vmbox?.doc?.media?.unavailable ? (
  //                   // <SmallMediaPlayer mediaId={vmbox?.doc?.media?.unavailable} />
  //                   <span style={{ opacity: 0.6 }}>
  //                     <AudioPlayer mediaId={vmbox?.doc?.media?.unavailable} />
  //                   </span>
  //                 ) : (
  //                   <span style={{ opacity: 0.6 }}>[No Greeting]</span>
  //                 )
  //               ) : null
  //             }
  //             footer={
  //               vmbox ? (
  //                 vmbox?.doc?.is_setup ? null : (
  //                   <div
  //                     style={{
  //                       marginTop: vmbox?.doc?.media?.unavailable ? -15 : -5,
  //                     }}
  //                   >
  //                     <Typography variant={'caption'} color={'secondary'}>
  //                       Voicemail Not Set Up
  //                     </Typography>
  //                   </div>
  //                 )
  //               ) : null
  //             }
  //           />
  //         ) : (
  //           // full ivr editor node display
  //           <NodeInfoDisplay
  //             type={'Voicemail'}
  //             icon={<VoicemailIcon />}
  //             title={
  //               vmbox ? (
  //                 `${vmbox?.doc?.mailbox} - ${vmbox?.doc?.name}`
  //               ) : (
  //                 <Link onClick={setShowModifyDialogWrap(true)}>
  //                   Select Voicemail
  //                 </Link>
  //               )
  //             }
  //             content={
  //               vmbox ? (
  //                 vmbox?.doc?.media?.unavailable ? (
  //                   // <SmallMediaPlayer mediaId={vmbox?.doc?.media?.unavailable} />
  //                   <span style={{ opacity: 0.6 }}>
  //                     <AudioPlayer mediaId={vmbox?.doc?.media?.unavailable} />
  //                   </span>
  //                 ) : (
  //                   <span style={{ opacity: 0.6 }}>[No Greeting]</span>
  //                 )
  //               ) : null
  //             }
  //             footer={
  //               vmbox ? (
  //                 vmbox?.doc?.is_setup ? null : (
  //                   <div
  //                     style={{
  //                       marginTop: vmbox?.doc?.media?.unavailable ? -15 : -5,
  //                     }}
  //                   >
  //                     <Typography variant={'caption'} color={'secondary'}>
  //                       Voicemail Not Set Up
  //                     </Typography>
  //                   </div>
  //                 )
  //               ) : null
  //             }
  //           />
  //         )}
  //         {skipEditing ? null : (
  //           <>
  //             <MenuButton
  //               data={data}
  //               mode={isHovering ? 'normal' : 'ignore'}
  //               menuItems={[
  //                 {
  //                   type: 'modify',
  //                   props: { onClick: setShowModifyDialogWrap(true) },
  //                 },
  //                 // 'add',
  //                 'add-before',
  //                 'move',
  //                 'duplicate',
  //                 'copy',
  //                 process.env.NODE_ENV === 'development'
  //                   ? 'create_template'
  //                   : null,
  //                 templateParent ||
  //                 callflow.type === 'template' ||
  //                 process.env.NODE_ENV === 'development'
  //                   ? {
  //                       type: 'variables',
  //                       props: { onClick: setShowVariablesWrap(true) },
  //                     }
  //                   : null,
  //                 'remove',
  //               ]}
  //             />
  //           </>
  //         )}
  //       </div>
  //       {templateParent ? <InTemplate template={templateParent} /> : ''}
  //     </Box>
  //   </>
  // );
});

export default VoicemailNode;

import { Tooltip } from 'app/design';

const DisabledTooltip = ({ children }) => {
  return (
    <Tooltip
      arrow
      title={
        'A "disabled" phone is not allowed to make calls, and will not be rung (call forwarding settings will be ignored).\n' +
        '\n' +
        'Only an Admin can disable phones.'
      }
    >
      {children}
    </Tooltip>
  );
};

export default DisabledTooltip;

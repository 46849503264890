const templateQueryKeys = {
  all: ['template'] as const,
  list: (
    orderBy: any,
    filters: any,
    queryPageSize: number,
    queryPageIndex: number,
  ) =>
    [
      ...templateQueryKeys.all,
      'list',
      orderBy,
      filters,
      queryPageSize,
      queryPageIndex,
    ] as const,
  // byId: (id: string) => [...scheduleQueryKeys.all, id] as const,
};

export default templateQueryKeys;

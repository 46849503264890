import { OwnerTableCell } from 'app/components/OwnerTableCell';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { startCase } from 'lodash';
import * as React from 'react';
import { Device } from 'types/device';
import { User } from 'types/user';
import { Chip, Grid, InfoTooltip } from 'app/design';

interface DeviceTypeProps {
  device: Device;
}

const DeviceType = ({ device }: DeviceTypeProps) => {
  const { t } = useMarkdownTranslate();

  switch (device.doc.device_type) {
    case 'sip_device':
      if (device.doc.provision?.endpoint_model) {
        return <>{device.doc.provision?.endpoint_model}</>;
      } else {
        console.log('Unknown SIP Device:', device.doc);
        return <>SIP Device</>;
      }
    case 'softphone':
      switch (device.doc.subtype) {
        case 'webphone':
          return <>Webphone</>;
        case 'appphone':
          return <>Mobile App</>;
        default:
          return <>Softphone</>;
      }
    case 'webphone':
      return <>Webphone</>;
    case 'appphone':
      return <>Mobile App</>;
    case 'application':
      return <>{startCase(device.doc.subtype ?? 'Application')}</>;
    default:
      break;
  }
  return <>{device?.doc?.device_type}</>;
};

export default DeviceType;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useContext,
  createContext,
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
  Controls,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';
import dagre from 'dagre';
// import initialElements from './initial-elements';
// import './layouting.css';

import { useLocalSelector } from 'app/data/local';
import { useSelector } from 'react-redux';

import { Close as CloseIcon } from 'app/design/icons-material';

import {
  Grid,
  Button,
  ButtonGroup,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from 'app/design';

import { find, toInteger, cloneDeep } from 'lodash';

import {
  createReducerContext,
  useEffectOnce,
  createStateContext,
  useHoverDirty,
} from 'react-use';

// import { useCallflowQuery } from 'app/hooks/queries/callflow';

import useComponentSize from '@rehooks/component-size';
// import ConvertCallflowToBaseElements from '../Flow/nodesonvertCallflowToBaseElements';

// import * as OptionComponents from '../../../Strategies/components';

// import { useSetupHook } from '../SetupHook';

// import store from '../../../../../store';

// import { Wizard } from './Wizard';
import { Flow } from './Flow';

import eventEmitter from './eventEmitter';
import { boolean } from 'joi';

// was going to use to have multiple layouted elements, not sure now...
export const [useSharedFlow, SharedFlowProvider] = createStateContext({
  view: 'flow', // flow, wizard
});

// eventemitter shared in context for things like "duplicate" and "move"
export const IvrMenuEventEmitterContext = React.createContext({});

const MessageRoutingVisualBuilder = ({
  callflow,
  pipe = undefined,
  onSave = undefined,
  onCancel = undefined,
}) => {
  // this simply wraps the available space (required by react-flow)
  // const [contentRef, contentDimensions] = useDimensions(); // { x, y, width }

  const [{ top, left }, setSizeInfo] = useState({ top: -1, left: -1 });
  const contentRef = useCallback(contentNode => {
    setSizeInfo(contentNode?.getBoundingClientRect());
  }, []);

  const { topPadding, leftPadding } = {
    topPadding: 24,
    leftPadding: 24,
  };

  // console.log('TOP:', top, contentRef);

  return (
    <div
      ref={contentRef}
      style={{
        position: 'relative',
      }}
      className={`w-full h-full`}
    >
      {/*<div
        style={{
          position: 'relative',
          // top: `-${topPadding}px`,
          // left: `-${leftPadding}px`,
          height: `calc(80vh - ${top}px + ${topPadding}px)`,
          // width: `calc(100vw - ${left}px + ${leftPadding}px)`,
          width: '100%',
        }}
      >*/}
      {/* wait for parent placement to exist before displaying */}
      {top > -1 ? (
        <ReactFlowProvider>
          <SharedFlowProvider>
            <IvrMenuEventEmitterContext.Provider value={eventEmitter}>
              <IvrViewFlowOrWizard
                pipe={pipe}
                callflow={callflow}
                onSave={onSave}
                onCancel={onCancel}
              />
            </IvrMenuEventEmitterContext.Provider>
          </SharedFlowProvider>
        </ReactFlowProvider>
      ) : (
        ''
      )}
      {/*</div>*/}
    </div>
  );
};

let chId = 1;
const IvrViewFlowOrWizard = ({
  callflow,
  pipe = undefined,
  onSave,
  onCancel,
}) => {
  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const [disableScroll, setDisableScroll] = useState(false);

  useSharedFlowSetup({ callflow, sharedFlow, setSharedFlow });
  // console.log('MessageRoutingVisualBuilder123:', callflow);
  const [editingCallflow, setEditingCallflow] = useState(() =>
    cloneDeep(callflow.doc),
  );

  const setEditingCallflowWrap = useSetEditingCallflowWrap({
    sharedFlow,
    setSharedFlow,
    setEditingCallflow,
  });

  // @ts-ignore
  if (!sharedFlow.setup) {
    // console.log('sharedFlow NOT setup, rendering null');
    return null;
  }

  // console.log('sharedFlow.view:', sharedFlow);
  return (
    <>
      {sharedFlow.view === 'flow' ? (
        <BuilderContext.Provider value={{ disableScroll, setDisableScroll }}>
          <Flow
            pipe={pipe}
            rootCallflow={callflow}
            editingCallflow={editingCallflow}
            setEditingCallflow={setEditingCallflow}
            setEditingCallflowWrap={setEditingCallflowWrap}
            onSave={onSave}
            onCancel={onCancel}
          />
        </BuilderContext.Provider>
      ) : (
        ''
      )}
      {/* {sharedFlow.view === 'wizard' ? (
        <Wizard
          editingCallflow={editingCallflow}
          setEditingCallflow={setEditingCallflow}
          setEditingCallflowWrap={setEditingCallflowWrap}
        />
      ) : (
        ''
      )} */}
    </>
  );
};

const useSetEditingCallflowWrap = ({
  sharedFlow,
  setSharedFlow,
  setEditingCallflow,
}) => {
  return (
    cf, // callflow
    actionDetails = {}, // .name, etc
    skipUndo = false,
    // replaceInsteadOfPush: used in "undo" for when doing an action that doesnt push a state, but instead replaces the current state
    // - ie, for after adding a new component and setting the "default" values for it (by the component)
    //   - we want the "undo" to go back to the correct place, without accidentally triggering the "default" value replacement again
    replaceInsteadOfPush = false,
  ) => {
    // console.log('setEditingCallflowWrap called', actionDetails, skipUndo);
    const freshState = JSON.parse(JSON.stringify({ ...cf, changed: true }));
    if (!skipUndo) {
      if (actionDetails === true) {
        console.error('meant to do skipUndo=true!');
        debugger;
      }
      // @ts-ignore
      if (!actionDetails?.name) {
        actionDetails = {
          name: 'Unknown Change',
        };
      }
      // cache saved version

      let addAmount = replaceInsteadOfPush ? 0 : 1;

      const cacheHistory = sharedFlow.cacheHistory;
      cacheHistory.splice(
        sharedFlow.cachePosition + addAmount,
        cacheHistory.length,
      ); // clear any after this
      cacheHistory.push(
        JSON.parse(
          JSON.stringify({
            id: chId++, // randomString(5),
            actionDetails,
            state: freshState,
          }),
        ),
      );

      setSharedFlow({
        ...sharedFlow,
        cachePosition: sharedFlow.cachePosition + addAmount,
        cacheHistory,
      });
    }
    setEditingCallflow(freshState);
  };
};

const useSharedFlowSetup = ({ callflow, sharedFlow, setSharedFlow }) => {
  // setup sharedFlow on first run
  useEffect(() => {
    setSharedFlow({
      ...sharedFlow,
      cachePosition: 0,
      cacheHistory: [
        {
          id: chId++,
          actionDetails: { name: 'Loaded Callflow' },
          state: cloneDeep(callflow.doc),
        },
      ],
      showJson: null,
      setup: true,
    });
  }, []);

  return [];
};

export default MessageRoutingVisualBuilder;

export type BuilderContent = {
  disableScroll: boolean;
  setDisableScroll: (scroll: boolean) => void;
};
//@ts-ignore
export const BuilderContext = createContext<BuilderContent>(undefined);
export const useBuilderContext = () => useContext(BuilderContext);

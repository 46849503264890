import { SquareProgress } from 'app/design-lib/index';
import * as React from 'react';

interface SettingsPanelLoaderProps {
  label?: string;
}

const SettingsPanelLoader = ({
  label = 'Loading...',
}: SettingsPanelLoaderProps) => {
  return (
    <div className={`w-full h-full grid place-items-center`}>
      <div className={`flex space-x-2 items-center`}>
        <SquareProgress size={'sm'} />
        <span className={`font-medium text-md`}>{label}</span>
      </div>
    </div>
  );
};

export default SettingsPanelLoader;

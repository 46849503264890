import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
// import { Account, AccountUpdatePartialDoc } from 'types/account';

export const useKeyValueWrite = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, any, unknown>((subjectAndData: any) =>
    sdk.keyvalue.mutate.write(subjectAndData, {
      authToken: authState?.auth_token,
    }),
  );
};

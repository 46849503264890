const contactQueryKeys = {
  all: ['contact'] as const,
  list: (
    orderBy: any,
    filters: any,
    queryPageSize: number,
    queryPageIndex: number,
  ) =>
    [
      ...contactQueryKeys.all,
      'list',
      orderBy,
      filters,
      queryPageSize,
      queryPageIndex,
    ] as const,
  maybeList: () => [...contactQueryKeys.all, 'maybe', 'list'] as const,
  byId: (id?: string) => [...contactQueryKeys.all, id] as const,
};

export default contactQueryKeys;

// import { patch } from 'app/sdk/utils/kazoo';
import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import { phoneNumberQueryKeys } from 'app/hooks/queries/phoneNumber';
// import { patchResource, patchResourceKeys } from 'app/kazooSDK';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { PhoneNumberUpdatePartialDoc } from 'types/phoneNumber';

export const useUpdatePhoneNumberPartial = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, PhoneNumberUpdatePartialDoc, unknown>(
    (partialDoc: any) =>
      sdk.phoneNumber.mutate.updatePartial(partialDoc, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(phoneNumberQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
    // patchResource(patchResourceKeys.phoneNumber, partialDoc),
  );
};

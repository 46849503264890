import { Transition } from '@headlessui/react';
import { Color, Size, Variant } from 'app/design-lib/types';
import React, {
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { InfoEmpty as InfoIcon, User as UserIcon } from 'iconoir-react';
import PersonIcon from '@mui/icons-material/Person';
import { InputWrapper } from 'app/design-lib/components/InputWrapper';
export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  helperText?: ReactNode | string;
  hasError?: boolean;
  multiline?: boolean;
  infoText?: string;
  startIcon?: ReactElement;
}

const TextField = React.forwardRef(
  (
    {
      helperText,
      multiline,
      name,
      label,
      hasError,
      infoText,
      startIcon,
      ...props
    }: TextFieldProps,
    ref: React.LegacyRef<any> | undefined,
  ) => {
    return (
      <InputWrapper
        helperText={helperText}
        hasError={hasError}
        infoText={infoText}
        label={label}
        name={name}
        disabled={props.disabled}
        startIcon={startIcon}
      >
        {multiline ? (
          // @ts-ignore
          <textarea
            aria-describedby={helperText ? `${name}-help-text` : undefined}
            id={name}
            name={name}
            {...props}
            className={`${
              props.className ?? ''
            } resize-none overflow-y-auto h-full form-input w-full focus:ring-content-accent focus:border-content-accent text-content-neutral ${
              hasError
                ? `border-border-negative-secondary`
                : `border-border-neutral`
            } rounded-lg border`}
            rows={3}
            ref={ref}
          />
        ) : (
          <input
            type={'text'}
            aria-describedby={helperText ? `${name}-help-text` : undefined}
            id={name}
            name={name}
            {...props}
            className={`${props.disabled ? 'opacity-50' : ''} ${
              props.className ?? ''
            } ${
              startIcon ? 'pl-8' : ''
            } form-input focus:ring-content-accent focus:border-content-accent text-content-neutral ${
              hasError
                ? `border-border-negative-secondary`
                : `border-border-neutral`
            } rounded-lg border`}
            ref={ref}
          />
        )}
      </InputWrapper>
    );
  },
);
export default TextField;

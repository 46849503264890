import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { ReactElement } from 'react';

export interface RadioGroupProps {
  value: any;
  onChange: (value: any) => void;
  options: any[];
  render?: (value: any) => ReactElement;
  className?: string;
  // label: string;
}

export const RadioGroup = ({
  value,
  onChange,
  options,
  render,
  className,
}: // label,
RadioGroupProps) => {
  return (
    <HeadlessRadioGroup
      className={`flex flex-col space-y-2 ${className}`}
      value={value}
      onChange={onChange}
    >
      {/*<HeadlessRadioGroup.Label>{label}</HeadlessRadioGroup.Label>*/}
      {options.map((option, index) => (
        <HeadlessRadioGroup.Option
          key={index}
          value={option}
          className={({ active, checked }) =>
            `flex space-x-2 items center focus:outline-none w-full focus:ring-0`
          }
        >
          {({ active, checked }) => (
            <>
              <div className="flex space-x-2 items-center  w-full justify-between">
                <div
                  className={`rounded-full cursor-pointer  h-5 w-5 aspect-square ${
                    checked
                      ? 'border-accent-50 border-[6px]'
                      : 'hover:border-neutral-30 border-neutral-20 border-[2px]'
                  }`}
                ></div>
                <HeadlessRadioGroup.Label as="div" className={`text-md flex-1`}>
                  {render ? render(option) : option}
                </HeadlessRadioGroup.Label>
                {/*<HeadlessRadioGroup.Description></HeadlessRadioGroup.Description>*/}
              </div>
            </>
          )}
        </HeadlessRadioGroup.Option>
      ))}
    </HeadlessRadioGroup>
  );
};

export default RadioGroup;

import { TextField } from 'app/design';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const VoicemailBoxMaxMessageLength = ({ item, modifyPath }) => {
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showFinderDialog, toggleShowFindVmboxDialog] = useToggleReducer(false);

  const key = varItem.key;
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);
  const defaultValueInFlow = varItem.output_value;
  const simpleValueInCallflow = variables[key];

  const onChangeVmboxSelect = selected => {
    const [vmboxId] = selected;
    // setAtPath(tmpModuleItem, `data.id`, vmboxId); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    // toggleShowFindVmboxDialog();
  };

  const handleClear = () => {
    // setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    // toggleShowFindVmboxDialog();
  };
  // console.log('varItem123:', defaultValueInFlow, varItem);
  return (
    <div>
      <Controller
        control={control}
        name={`${modifyPath}callflow.strategy.simple.variables.${key}`}
        defaultValue={defaultValueInFlow}
        render={({ field: { onChange, value, ...props } }) => (
          <TextField
            label={varItem.simple_title}
            value={value}
            type="number"
            onChange={e => {
              onChange(e.target.value);
            }}
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default VoicemailBoxMaxMessageLength;

import { sdk } from 'app/sdk';
import React from 'react';

const setupAccountDetails = async ({
  auth_token,
  accountId,
  companyName,
  address,
}) => {
  const accountResult = await sdk.account.mutate.updatePartial(
    {
      id: accountId,
      name: companyName,
      // address: {
      //   street_address: address.streetLine, // line 1
      //   extended_address: address.secondary,
      //   locality: address.city,
      //   region: address.state, // state
      //   postal_code: address.zipcode,
      //   plus_four: '',
      // },
      // onboarding_complete: true, // this func is called at the start of onboarding, and this prop belongs to internal now so
      // moving to end of onboarding
    },
    auth_token,
  );
  console.log('accountResult:', accountResult);
  if (accountResult.status !== 'success') {
    throw new Error('Failed saving account');
  }

  // const accountId = accountResult.data.id;
  // return { accountId };
};

export default setupAccountDetails;

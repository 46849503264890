const plans = [
  {
    id: 'free1',
    name: 'Free',
    canUpgrade: true,
  },
  {
    id: 'paid1',
    name: 'Paid',
    canUpgrade: false,
  },
  {
    id: 'custom1',
    name: 'Enterprise',
    canUpgrade: false,
  },
];

export default plans;

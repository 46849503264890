import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { Dialog } from 'app/design-lib';
import { GroupRingStrategyCard } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard';
import { DetailsDialogGroup } from 'app/components/DetailsDialogGroup';
import { DeleteBulkGroups } from './components/DeleteBulkGroups';
import * as React from 'react';
import { useState } from 'react';
import { Group } from 'types/group';
import { useImmer } from 'use-immer';
import { useToggleReducer } from 'app/utilities';

interface BulkActionsProps {
  selectedRows?: Group[];
  onComplete: () => void;
  toggleSelect?: (set?: boolean) => void;
  toggleRowSelected?: any;
  vertical?: boolean;
}

const BulkActionsGroups = ({
  selectedRows: selectedGroups,
  toggleSelect,
  onComplete,
  vertical = false,
  toggleRowSelected,
}: BulkActionsProps) => {
  const [showDialog, setShowDialog] = useImmer<null | string>(null);
  const [menuItems] = useState(() => [
    ...(selectedGroups?.length === 1
      ? [
          {
            text: 'Edit',
            onClick: async e => {
              setShowDialog('edit');
            },
          },
        ]
      : []),
    ...(selectedGroups?.length === 1
      ? [
          {
            text: 'Rename',
            onClick: async e => {
              toggleShowEditNameDialog();
            },
          },
        ]
      : []),
    {
      text: 'Delete',
      onClick: async e => {
        setShowDialog('delete');
      },
    },
  ]);

  const handleCancel = () => {
    setShowDialog(null);
  };

  const handleOnComplete = () => {
    if (toggleSelect && showDialog === 'delete') toggleSelect(false);
    setShowDialog(null);
    onComplete();
  };

  const [showEditNameDialog, toggleShowEditNameDialog] =
    useToggleReducer(false);

  return (
    <>
      <DeleteBulkGroups
        open={showDialog === 'delete'}
        toggleRowSelected={toggleRowSelected}
        selectedRows={selectedGroups}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
      />
      <DetailsDialogGroup
        DialogProps={{
          open: showEditNameDialog,
        }}
        ContentProps={{
          groupId: selectedGroups![0].id,
          onCancel: toggleShowEditNameDialog,
          onComplete: handleOnComplete,
        }}
      />
      {showDialog === 'edit' ? (
        <EditGroupDialog
          // pipe={pipe}
          group={selectedGroups![0]}
          onCancel={() => {
            handleOnComplete();
          }}
          // onSend={toggleOpenSendDialog}
        />
      ) : null}
      <ButtonDropdownMenu
        label="Actions"
        variant={vertical ? 'default' : 'button'}
        menuItems={menuItems}
        menuItemsDependencies={[menuItems]}
        vertical={vertical}
      />
    </>
  );
};

const EditGroupDialog = ({ group, onCancel }) => {
  return (
    <Dialog size={'4xl'} open={!!group} onClose={onCancel}>
      {group ? (
        <GroupRingStrategyCard group={group} onComplete={onCancel} />
      ) : null}
    </Dialog>
  );
};

export default BulkActionsGroups;

// stateless component
import * as React from 'react';
import { IconProps, Tooltip, TooltipProps } from '@mui/material';
import {
  InfoOutlined as InfoOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';

export interface InfoTooltipProps {
  title?: any;
  children?: Node[];
  size?: IconProps['fontSize'];
  arrow?: boolean;
  placement?: TooltipProps['placement'];
  icon?: string;
}

const InfoTooltip = ({
  title,
  children,
  size = 'inherit',
  arrow = true,
  placement,
  icon = 'info',
}: InfoTooltipProps) => {
  let IconComponent;
  switch (icon) {
    case 'help':
      IconComponent = HelpOutlineIcon;
      break;
    case 'info':
    default:
      IconComponent = InfoOutlinedIcon;
      break;
  }
  return (
    // @ts-ignore
    <Tooltip title={title || children} arrow={arrow} placement={placement}>
      <IconComponent fontSize={size} sx={{ color: 'text.secondary' }} />
    </Tooltip>
  );
};

export default InfoTooltip;

import { getTimeZones } from '@vvo/tzdb';

const TIMEZONES = getTimeZones();

export const getTimezoneName = (timezone: string) => {
  const tz = TIMEZONES.find(tz => tz.name === timezone);
  if (!tz) {
    return 'Invalid Timezone';
  }
  return `${tz.name} (${tz.abbreviation})`;
};

import { DropdownMenu } from '@radix-ui/react-dropdown-menu';
import {
  Alert,
  Button,
  ButtonDropdown,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogProps,
  IconButton,
} from 'app/design-lib';
import './DatePicker.css';
import './DateRangePicker.css';
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from 'app/design/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import AudioEditor from 'app/components/AudioEditor/AudioEditor';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { Autocomplete, TextField } from 'app/design';
import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import { Calendar, Cancel, Copy, Plus, WarningTriangle } from 'iconoir-react';
import { watch } from 'fs';
import * as React from 'react';
import { TIMEZONE_LIST } from 'app/components/TimezoneDialogAccount/TimezoneDialogAccount';
import { useAccountQuery } from 'app/hooks/queries/account';
import { CallflowDoc } from 'types/callflow';
import walkStrategyModules from 'app/components/IvrBuilder/Flow/walkStrategyModules';
import { useMemo, useState } from 'react';
import DatePicker from 'react-date-picker';
import './Calendar.css';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { DateRangePicker } from '@wojtekmaj/react-daterange-picker';
interface ScheduleNodeDialogProps extends Omit<DialogProps, 'children'> {
  category: any;
  onComplete: (greeting_id: string) => void;
  callflow: CallflowDoc;
  currentScheduleId: string;
}

export const ScheduleNodeDialog = ({
  open,
  onClose,
  category,
  onComplete,
  callflow,
  currentScheduleId,
}: ScheduleNodeDialogProps) => {
  return (
    <Dialog size={'4xl'} open={open} onClose={onClose}>
      <ScheduleNodeContent
        callflow={callflow}
        category={category}
        onClose={onClose}
        onComplete={onComplete}
        currentScheduleId={currentScheduleId}
      />
    </Dialog>
  );
};

const deserializeValues = (values: any[]) => {
  return (
    values?.map(value => {
      return {
        ...value,
        // TODO: replace need for conversion for select box
        data: {
          ...value.data,
          from: value.data.from
            ? `${value.data.from.hours}:${value.data.from.minutes}`
            : undefined,
          to: value.data.to
            ? `${value.data.to.hours}:${value.data.to.minutes}`
            : undefined,
        },
      };
    }) ?? []
  );
};

const ScheduleNodeContent = ({
  category,
  callflow,
  onClose,
  onComplete,
  currentScheduleId,
}) => {
  const { data: account } = useAccountQuery();
  // console.log('category', category);
  const formMethods = useForm({
    defaultValues: {
      id: category?.id ?? Date.now() + 103,
      name: category?.name ?? '',
      // timezone: TIMEZONE_LIST.find(
      //   tz => tz.value === category?.timezone ?? account?.doc?.timezone,
      // ),
      values: deserializeValues(category.values),
    },
  });

  // console.log('form', formMethods.watch());

  const onSubmit = (form: any) => {
    onComplete({
      ...form,
      timezone: form.timezone?.value,
      values: form.values.map(value => {
        const [fromHours, fromMinutes] = value.data.from?.split(':') ?? [];
        const [toHours, toMinutes] = value.data.to?.split(':') ?? [];

        return {
          ...value,
          data: {
            ...value.data,
            // TODO: replace need for conversion for select box
            from: fromHours
              ? {
                  hours: Number.parseInt(fromHours),
                  minutes: Number.parseInt(fromMinutes),
                }
              : undefined,
            to: toHours
              ? {
                  hours: Number.parseInt(toHours),
                  minutes: Number.parseInt(toMinutes),
                }
              : undefined,
          },
        };
      }),
    });
  };

  return (
    <>
      <DialogHeader title={'Edit Route Time(s)'} onClose={onClose} />
      <FormProvider {...formMethods}>
        <DialogContent className={`overflow-y-auto min-h-[32rem]`}>
          <HookFormTextField name={'name'} label={'Name'} />
          {/*<Controller*/}
          {/*  name="timezone"*/}
          {/*  control={formMethods.control}*/}
          {/*  defaultValue={TIMEZONE_LIST.find(*/}
          {/*    tz => tz.value === account?.doc.timezone,*/}
          {/*  )}*/}
          {/*  render={({ field: { value, onChange } }) => (*/}
          {/*    <Autocomplete*/}
          {/*      options={TIMEZONE_LIST}*/}
          {/*      getOptionLabel={option => option.label}*/}
          {/*      value={value}*/}
          {/*      onChange={(event, value) => {*/}
          {/*        onChange(value);*/}
          {/*      }}*/}
          {/*      renderInput={params => {*/}
          {/*        // @ts-ignore*/}
          {/*        params.InputLabelProps.shrink = true;*/}
          {/*        return (*/}
          {/*          <TextField*/}
          {/*            {...params}*/}
          {/*            inputProps={{*/}
          {/*              ...params.inputProps,*/}
          {/*              className:*/}
          {/*                'focus:border-0 p-0 m-0 h-max max-h-[0.2rem] focus:ring-0 focus:outline-none',*/}
          {/*            }}*/}
          {/*            label={'Timezone'}*/}
          {/*            placeholder={'select'}*/}
          {/*          />*/}
          {/*        );*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}
          <ValuesDisplay
            callflow={callflow}
            currentScheduleId={currentScheduleId}
            categoryId={category.id}
          />
        </DialogContent>
      </FormProvider>
      <DialogActions className={`w-full justify-between`}>
        <Button
          variant={'fill'}
          color={'negative'}
          size={'sm'}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant={'fill'}
          color={'accent'}
          size={'sm'}
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          Update Route Times
        </Button>
      </DialogActions>
    </>
  );
};

const ValuesDisplay = ({ callflow, currentScheduleId, categoryId }) => {
  const { trigger, watch } = useFormContext();
  const valueFields = watch('values');
  const {
    // fields: valueFields,
    update,
    append,
    remove,
    insert,
  } = useFieldArray({ name: 'values' });

  return (
    <>
      <div className="mt-4"></div>
      {valueFields.length ? (
        <>
          {/* <div
            className={`grid w-full grid-cols-12 mt-4 -mb-2 gap-x-2 col-span-2 items-end`}
          >
            <div className={`col-span-3 flex space-x-2 items-end`}>
              <span className={`ml-4`}>Type</span>
            </div>
            <div className={`col-span-2 ml-3`}>Day</div>
            <div className={`col-span-3 ml-3`}>From</div>
            <div className={`col-span-3 ml-3`}>To</div>
            <div className={`flex flex-col col-span space-y-1`}></div>
          </div> */}
          {valueFields.map((value, index) => (
            <ValueField
              key={index}
              value={value}
              index={index}
              onRemove={() => {
                remove(index);
              }}
              onDuplicate={() => {
                insert(index + 1, { ...value, id: Date.now() + 104 });
              }}
            />
          ))}
        </>
      ) : (
        <Alert
          className={`w-full col-span-2`}
          label={'No Rules'}
          reverse
          body={'Add at least one rule for this route.'}
          icon={<WarningTriangle fr={undefined} />}
        />
      )}
      <div className={`w-full space-x-2 mt-4 mb-2 flex`}>
        <Button
          endIcon={<Plus fr={undefined} />}
          size={'sm'}
          // className={`mt-7`}
          variant={'fill'}
          color={`accent`}
          onClick={() => {
            append({
              id: Date.now() + 102,
              type: 'day_of_week_hours',
              data: {
                day_of_week: 1,
                from: '9:0',
                to: '17:0',
                date: startOfDay(new Date()),
                date_range: [
                  startOfDay(new Date()),
                  endOfDay(addDays(new Date(), 2)),
                ],
              },
            });
          }}
        >
          Add Rule
        </Button>
        <CopyRules
          callflow={callflow}
          append={append}
          currentScheduleId={currentScheduleId}
          categoryId={categoryId}
        />
        <div className={`flex-1`}></div>
        <Button
          endIcon={<Cancel fr={undefined} />}
          size={'sm'}
          // className={`mt-7`}
          variant={'fill'}
          color={`negative`}
          onClick={() => {
            remove();
          }}
        >
          Remove All
        </Button>
      </div>
    </>
  );
};

const CopyRules = ({
  append,
  callflow,
  currentScheduleId,
  categoryId: currentCategoryId,
}) => {
  const copyValues = (values: any[]) => () => {
    append(deserializeValues(values));
  };

  const menuItems = useMemo(() => {
    const menuItems: any[] = [];
    walkStrategyModules(callflow, ({ module, callflow: rootCallflow }) => {
      if (module.type === 'Schedule') {
        const scheduleId = module.data.schedule_id;
        const schedule = callflow.schedules[scheduleId];

        schedule.categories.forEach(cat => {
          if (
            !(scheduleId === currentScheduleId && cat.id === currentCategoryId)
          ) {
            menuItems.push({
              label: `${schedule.name} - ${cat.name} (${cat.values.length} Rules)`,
              onClick: copyValues(cat.values),
            });
          }
        });
      }
    });
    return menuItems;
  }, [callflow]);

  return (
    <ButtonDropdown
      startIcon={<Copy fr={undefined} />}
      size={'sm'}
      // className={`mt-7`}
      variant={'fill'}
      color={`accent`}
      menuItems={menuItems}
    >
      Copy Rules From...
    </ButtonDropdown>
  );
};

const ValueField = ({ index, value, onRemove, onDuplicate }) => {
  const { register, control } = useFormContext();

  const showLabel = index === 0;
  const showHours =
    value.type === 'day_of_week_hours' ||
    value.type === 'weekends' ||
    value.type === 'weekdays';
  const showDay =
    value.type === 'day_of_week_hours' || value.type === 'day_of_week';
  return (
    <>
      <span className={`sm:hidden text-neutral-60 text-sm`}>
        Rule {index + 1}
      </span>
      <div
        className={`grid sm:border-0 border-b-2 border-neutral-20 sm:pb-0 pb-2 w-full grid-cols-12 gap-2 col-span-2 items-center my-2`}
      >
        <div className={`col-span-6 sm:col-span-3 flex space-x-2 items-end`}>
          {/* <span className={` mb-2`}>{index + 1}.</span> */}
          <HookFormListbox
            className={`w-full`}
            name={`values.${index}.type`}
            // label={showLabel ? 'Type' : undefined}
            options={[
              { label: 'Hours of Day', value: 'day_of_week_hours' },
              { label: 'Day of Week', value: 'day_of_week' },
              { label: 'Weekdays (M-F)', value: 'weekdays' },
              { label: 'Weekends (Sat, Sun)', value: 'weekends' },
              { label: 'Single Date', value: 'date' },
              { label: 'Date Range', value: 'date_range' },
            ]}
          />
        </div>
        {showDay ? (
          <div className={`col-span-6 sm:col-span-2`}>
            <HookFormListbox
              className={`w-full`}
              name={`values.${index}.data.day_of_week`}
              // label={showLabel ? 'Day of the Week' : undefined}
              options={[
                { label: 'Sunday', value: 0 },
                { label: 'Monday', value: 1 },
                { label: 'Tuesday', value: 2 },
                { label: 'Wednesday', value: 3 },
                { label: 'Thursday', value: 4 },
                { label: 'Friday', value: 5 },
                { label: 'Saturday', value: 6 },
              ]}
            />
          </div>
        ) : null}
        {showHours ? (
          <div
            className={`col-span-11 sm:col-span-5 flex items-center space-x-1`}
          >
            <div className="flex-auto">
              <HookFormListbox
                className={`w-full`}
                name={`values.${index}.data.from`}
                // label={showLabel ? 'Form' : undefined}
                options={HoursOfWeekValues}
              />
            </div>
            <div className="">to</div>
            <div className="flex-auto">
              <HookFormListbox
                className={`w-full`}
                name={`values.${index}.data.to`}
                // label={showLabel ? 'To' : undefined}
                options={HoursOfWeekValues}
              />
            </div>
          </div>
        ) : null}
        {value.type === 'date' ? (
          <div className={`col-span-7 flex`}>
            <Controller
              control={control}
              name={`values.${index}.data.date`}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  required
                  calendarIcon={
                    <Calendar height={16} width={16} fr={undefined} />
                  }
                  onChange={date => onChange(startOfDay(date as Date))}
                  value={value}
                  clearIcon={null}
                />
              )}
            />
          </div>
        ) : null}
        {value.type === 'date_range' ? (
          <div className={`col-span-7 flex`}>
            <Controller
              control={control}
              name={`values.${index}.data.date_range`}
              render={({ field: { onChange, value } }) => (
                <DateRangePicker
                  required
                  calendarIcon={
                    <Calendar height={16} width={16} fr={undefined} />
                  }
                  onChange={dates =>
                    onChange([startOfDay(dates![0]), endOfDay(dates![1])])
                  }
                  value={value}
                  clearIcon={null}
                />
              )}
            />
          </div>
        ) : null}

        <div
          className={`flex flex-col sm:flex-row justify-end  col-span-1 sm:col-span-2 space-y-1 sm:space-y-0 sm:space-x-2`}
        >
          <IconButton
            onClick={onDuplicate}
            variant={'outline'}
            className={``}
            color={'accent'}
            size={'sm'}
          >
            <Copy fr={undefined} />
          </IconButton>
          <IconButton
            onClick={onRemove}
            variant={'outline'}
            className={``}
            color={'negative'}
            size={'sm'}
          >
            <Cancel fr={undefined} />
          </IconButton>
        </div>
      </div>
    </>
  );
};

const generateHoursOfDayArray = () => {
  const hoursOfDay: any[] = [];
  for (let hour = 0; hour < 24; hour++) {
    let labelHour = hour % 12;
    let isAm = true;
    if (hour > 11) {
      isAm = false;
      labelHour = labelHour;
    }

    if (labelHour === 0) labelHour = 12;

    hoursOfDay.push({
      data: { hour, minute: 0 },
      label: `${labelHour}:00 ${isAm ? 'AM' : 'PM'}`,
      value: `${hour}:0`,
    });
    hoursOfDay.push({
      data: { hour, minute: 30 },
      label: `${labelHour}:30 ${isAm ? 'AM' : 'PM'}`,
      value: `${hour}:30`,
    });
  }

  return hoursOfDay;
};

const HoursOfWeekValues = generateHoursOfDayArray();

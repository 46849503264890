import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { GroupCreateDialog } from 'app/components/GroupCreateDialog';
import { DeviceListOptions } from 'app/components/ListDevices/components/DeviceListOptions';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { DeviceType } from 'app/components/ListDevices/components/DeviceType';
import { GroupListOptions } from 'app/components/ListGroups/components/GroupListOptions';

import { RingStrategyCell } from 'app/components/ListGroups/components/RingStrategyCell';
import { UsersAndDevicesCell } from 'app/components/ListGroups/components/UsersAndDevicesCell';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';

import { useListGroupsQuery } from 'app/hooks/queries/group';
import { GroupRingStrategyCard } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard';
import { DeviceDisableSettings } from 'app/pages/v2/settings/Devices/View/components/DeviceDisableSettings';
import { usePNContext } from 'app/utilities/pubnub';

import useToggleReducer from 'app/utilities/useToggleReducer';
import { Plus as PlusIcon } from 'iconoir-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

function Groups() {
  // useRouteMatch() // no argument, returns current match? (but that is the PARENT Route, not the Setting Route...)

  const {
    data: groupsPage,
    isLoading: groupsLoading,
    refetch,
  } = useListGroupsQuery({
    take: 500,
    filters: {},
    options: {},
  });
  const groups = groupsPage?.groups ?? [];

  const [openCreateDialog, toggleOpenCreateDialog] = useToggleReducer(false);
  const [editGroup, editGroupSet] = React.useState<any>(null);

  // @ts-ignore
  const { onlineUsers } = usePNContext();

  return (
    <>
      {editGroup ? (
        <EditGroupDialog
          // pipe={pipe}
          group={editGroup}
          onCancel={() => {
            editGroupSet(null);
            refetch();
          }}
          // onSend={toggleOpenSendDialog}
        />
      ) : null}
      <GroupCreateDialog
        ContentProps={{
          onComplete: () => {
            toggleOpenCreateDialog();
            refetch();
          },
          navigateOnComplete: false,
          onCancel: toggleOpenCreateDialog,
        }}
        DialogProps={{
          open: openCreateDialog,
        }}
      />
      <SettingsPageWrapper
        isLoading={groupsLoading}
        loadingLabel={'Loading groups...'}
        label={'Groups'}
        subLabel={'Manage ring strategies and order'}
        buttonProps={{
          children: 'Add group',
          onClick: toggleOpenCreateDialog,
          startIcon: <PlusIcon fr={undefined} />,
        }}
      >
        {groups.length ? (
          <Table>
            {groups.map((group, index) => {
              let onlineCount = 0;

              Object.keys(group.doc.endpoints).forEach(member => {
                if (onlineUsers?.[member]) onlineCount++;
              });

              const color = !group.doc.type
                ? 'negative'
                : onlineCount > 0
                ? 'positive'
                : 'attention';

              return (
                <TableRow
                  showBorder={groups.length > 1 && index < groups.length - 1}
                  key={group.id}
                >
                  <TableCell className={`font-medium`} flex>
                    {group.doc.name}
                  </TableCell>
                  <TableCell flex>
                    <RingStrategyCell group={group} />
                  </TableCell>
                  <TableCell flex>
                    <div className={'w-full flex justify-start'}>
                      <UsersAndDevicesCell group={group} />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="h-full whitespace-nowrap flex items-center">
                      {
                        !group.doc.type ? (
                          <Badge
                            color={'negative'}
                            onClick={() => editGroupSet(group)}
                            variant={'fill'}
                            reverse
                          >
                            Requires setup
                          </Badge>
                        ) : null
                        // <Badge color={color} variant={'fill'} reverse>
                        //   {`${onlineCount} Member
                        // ${onlineCount > 1 || onlineCount === 0 ? 's' : ''}
                        // Online`}
                        // </Badge>
                      }
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="h-full flex items-center">
                      {/* <button>Actions</button> */}
                      <GroupListOptions
                        onDelete={() => {
                          refetch();
                        }}
                        group={group}
                        onComplete={() => {
                          refetch();
                        }}
                        toggleRowSelected={() => {}}
                        vertical={true}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <Alert
            reverse
            label={'No groups'}
            body={'No groups could be found associated with your account'}
          />
        )}
      </SettingsPageWrapper>
    </>
  );

  // return (
  //   <>
  //     {editGroup ? (
  //       <EditGroupDialog
  //         // pipe={pipe}
  //         group={editGroup}
  //         onCancel={() => {
  //           editGroupSet(null);
  //           refetch();
  //         }}
  //         // onSend={toggleOpenSendDialog}
  //       />
  //     ) : null}
  //
  //     <GroupCreateDialog
  //       ContentProps={{
  //         onComplete: () => {
  //           toggleOpenCreateDialog();
  //           refetch();
  //         },
  //         navigateOnComplete: false,
  //         onCancel: toggleOpenCreateDialog,
  //       }}
  //       DialogProps={{
  //         open: openCreateDialog,
  //       }}
  //     />
  //
  //     <div
  //       className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //     >
  //       <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
  //         <SimpleBar className={'h-full'} style={{ height: 'inherit' }}>
  //           <div className={'px-8 py-3 h-full'}>
  //             <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
  //               <div className={'flex  items-center space-x-2'}>
  //                 <div
  //                   className={
  //                     'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
  //                   }
  //                 >
  //                   <GroupIcon
  //                     className={'fill-content-accent '}
  //                     sx={{ fontSize: 'inherit' }}
  //                   />
  //                 </div>
  //                 <div>
  //                   <div className="font-bold text-xl">Groups</div>
  //                   <div className="text-base text-neutral-500">
  //                     Groups can have custom ring strategies
  //                   </div>
  //                 </div>
  //               </div>
  //               <Button
  //                 color={'accent'}
  //                 size={'sm'}
  //                 variant={'outline'}
  //                 className={'h-min'}
  //                 onClick={toggleOpenCreateDialog}
  //                 startIcon={<GroupAddIcon />}
  //               >
  //                 Add Group
  //               </Button>
  //             </div>
  //             <div className="p-3 h-full">
  //               <table className="w-full table-fixed border-separate border-spacing-y-3">
  //                 <tbody className="space-y-4">
  //                   {groups.map(group => {
  //                     let onlineCount = 0;
  //
  //                     Object.keys(group.doc.endpoints).forEach(member => {
  //                       if (onlineUsers?.[member]) onlineCount++;
  //                     });
  //
  //                     const color = !group.doc.type
  //                       ? 'negative'
  //                       : onlineCount > 0
  //                       ? 'positive'
  //                       : 'attention';
  //
  //                     return (
  //                       <tr
  //                         key={group.id}
  //                         className="flex justify-between border-solid border-0 border-b pb-4 border-border-neutral/50"
  //                       >
  //                         <td className={'w-1/4'}>
  //                           <div className="flex items-center space-x-6">
  //                             <div className="">
  //                               <div className="font-semibold">
  //                                 {group.doc.name}
  //                               </div>
  //                               <div className="text-neutral-400">
  //                                 <RingStrategyCell group={group} />
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </td>
  //                         <td className={'w-1/6'}>
  //                           <UsersAndDevicesCell group={group} />
  //                         </td>
  //                         <td className={'w-1/4'}>
  //                           <div className="h-full whitespace-nowrap flex items-center">
  //                             <button
  //                               // @ts-ignore
  //                               onClick={e => editGroupSet(group)}
  //                               type="button"
  //                               className={`cursor-pointer
  //                               bg-background-${color}-secondary
  //                               text-content-${color}
  //                               border-none
  //                               text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
  //                             >
  //                               {!group.doc.type
  //                                 ? 'REQUIRES SET UP'
  //                                 : `${onlineCount} MEMBER${
  //                                     onlineCount > 1 || onlineCount === 0
  //                                       ? 'S'
  //                                       : ''
  //                                   } ONLINE`}
  //                             </button>
  //                           </div>
  //                         </td>
  //                         <td>
  //                           <div className="h-full flex items-center">
  //                             {/* <button>Actions</button> */}
  //                             <GroupListOptions
  //                               onDelete={() => {
  //                                 refetch();
  //                               }}
  //                               group={group}
  //                               onComplete={() => {
  //                                 refetch();
  //                               }}
  //                               toggleRowSelected={() => {}}
  //                               vertical={true}
  //                             />
  //                           </div>
  //                         </td>
  //                       </tr>
  //                     );
  //                   })}
  //                 </tbody>
  //               </table>
  //               {groupsLoading ? (
  //                 <div className={'pt-5 text-neutral-400 w-full text-center'}>
  //                   Loading Groups...
  //                 </div>
  //               ) : null}
  //               {!groupsLoading && !groups?.length ? (
  //                 <div className={'pt-5 text-neutral-400 w-full text-center'}>
  //                   No groups have been created yet
  //                 </div>
  //               ) : null}
  //             </div>
  //           </div>
  //         </SimpleBar>
  //       </div>
  //     </div>
  //   </>
  // );

  // return (
  //   <>
  //     {editGroup ? (
  //       <EditGroupDialog
  //         // pipe={pipe}
  //         group={editGroup}
  //         onCancel={() => {
  //           editGroupSet(null);
  //           refetch();
  //         }}
  //         // onSend={toggleOpenSendDialog}
  //       />
  //     ) : null}
  //     <div
  //       className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //     >
  //       <div className="overflow-hidden relative h-screen">
  //         <SimpleBar style={{ height: '100%' }}>
  //           <div>
  //             <div className="">
  //               <div className="text-2xl">Groups</div>
  //               <div className="flex justify-between">
  //                 <div className="text-md mb-2 text-neutral-500">
  //                   Groups can have custom ring strategies.
  //                 </div>
  //                 <div className="">
  //                   <CreateGroupButton
  //                     onComplete={refetch}
  //                     navigateOnComplete={false}
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="p-4 ">
  //               <table className="w-full border-separate border-spacing-y-3">
  //                 <tbody className="">
  //                   {groups.map(group => {
  //                     return (
  //                       <tr
  //                         key={group.id}
  //                         className="flex justify-between items-center"
  //                       >
  //                         <td>
  //                           <div className="flex">
  //                             <div className="">
  //                               <div className="font-semibold">
  //                                 {group.doc.name}
  //                               </div>
  //                               <div className="text-neutral-400">
  //                                 <RingStrategyCell group={group} />
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </td>
  //                         <td>
  //                           <div>
  //                             <UsersAndDevicesCell group={group} />
  //                           </div>
  //                         </td>
  //                         <td>
  //                           <div>
  //                             <button
  //                               // @ts-ignore
  //                               onClick={e => editGroupSet(group)}
  //                               type="button"
  //                             >
  //                               Customize
  //                             </button>
  //                           </div>
  //                         </td>
  //                         <td>
  //                           <div>
  //                             <GroupListOptions
  //                               onDelete={() => {
  //                                 refetch();
  //                               }}
  //                               group={group}
  //                               onComplete={() => {
  //                                 refetch();
  //                               }}
  //                               toggleRowSelected={() => {}}
  //                               vertical={false}
  //                             />
  //                           </div>
  //                         </td>
  //                       </tr>
  //                     );
  //                   })}
  //                 </tbody>
  //               </table>
  //               {groupsLoading ? <div>Loading Groups...</div> : null}
  //               {!groupsLoading && !groups?.length ? (
  //                 <div>No groups have been created yet</div>
  //               ) : null}
  //             </div>
  //           </div>
  //         </SimpleBar>
  //       </div>
  //     </div>
  //   </>
  // );
}

export const EditGroupDialog = ({ group, onCancel }) => {
  return (
    <Dialog size={'4xl'} open={!!group} onClose={onCancel}>
      {group ? (
        <GroupRingStrategyCard group={group} onComplete={onCancel} />
      ) : null}
    </Dialog>
  );
};

export default Groups;

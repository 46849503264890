import { joiResolver } from '@hookform/resolvers/joi';
import { ExtensionInUseWarnings } from 'app/components/ExtensionInUseWarnings';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import {
  AnimationDialog,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from 'app/design';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import { useExtensionUsedByQuery } from 'app/hooks/general/useExtensionUseQuery';
import { useCreateVmbox } from 'app/hooks/mutations/vmbox';
import {
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Vmbox } from 'types/vmbox';
import { useImmer } from 'use-immer';

import { OwnerInput } from 'app/components/OwnerInput';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  owner_id: Joi.string(),
  owner_type: Joi.string(),
  name: Joi.string().max(128).required(),
  mailbox: Joi.number().max(9999).min(0).required(),
});

interface VmboxCreateForm {
  owner_id?: string;
  owner_type?: string;
  name?: string;
  mailbox?: string;
}

// interface declaring which props are required/allowed
interface VmboxCreateDialogContentProps {
  formDefaults: VmboxCreateForm;
  onCancel: () => void;
  startDirty?: boolean;
  navigateOnComplete?: boolean;
  onComplete?: (data: any) => void;
}

interface VmboxCreateDialogProps {
  ContentProps: VmboxCreateDialogContentProps;
  DialogProps: AnimationDialogProps;
}

const VmboxCreateDialog = ({
  ContentProps,
  DialogProps,
}: VmboxCreateDialogProps) => {
  return (
    <AnimationDialog {...DialogProps}>
      <VmboxCreateDialogContent {...ContentProps} />
    </AnimationDialog>
  );
};

const VmboxCreateDialogContent = ({
  formDefaults,
  onCancel,
  startDirty = false,
  onComplete,
  navigateOnComplete = true,
}: VmboxCreateDialogContentProps) => {
  const formMethods = useForm<VmboxCreateForm>({
    defaultValues: formDefaults,
    resolver: joiResolver(schema), // pass in custom validation
  });
  const createVmbox = useCreateVmbox();
  const router = useHistory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isSubmitting },
    setError,
    watch,
  } = formMethods;

  const onSubmit = async (vmboxForm: VmboxCreateForm) => {
    const doc = {
      pin: undefined,
      require_pin: false,
      announcement_only: false,
      include_message_on_notify: true,
      include_transcription_on_notify: true,
      is_setup: false,
      is_voicemail_ff_rw_enabled: false,
      check_if_owner: true,
      delete_after_notify: false,
      save_after_notify: false,
      skip_envelope: false,
      skip_greeting: false,
      skip_instructions: false,
      transcribe: false,
      transcribe2: true,
      notify_email_addresses: [],
      oldest_message_first: false,
      ...vmboxForm,
    };

    // sanitize by removing values that may be unset (owner_id cannot be NULL)
    const sanitizedDoc = pickBy(doc, value => value !== null);

    // TODO: Device owner instead of user?
    if (vmboxForm.owner_id) {
      doc.owner_type = 'user';
    } else {
      // @ts-ignore
      doc.owner_type = null;
    }

    // convert mailbox number to string for kazoo validation
    sanitizedDoc.mailbox = sanitizedDoc.mailbox?.toString();

    // try {
    try {
      // @ts-ignore
      const resp = await createVmbox.mutateAsync(sanitizedDoc);
    } catch (err: any) {
      parseAndSetKazooMutationErrors({ response: err.response, setError });
    }
  };

  const extWatch = watch('mailbox');
  const extUsedByQuery = useExtensionUsedByQuery({
    exts: extWatch ? [extWatch] : [],
  });

  function handleComplete() {
    if (onComplete) onComplete(createVmbox.data.data.id);

    if (navigateOnComplete) {
      // redirect to Vmbox
      router.push(`/admin/vmboxes/view/${createVmbox.data.data.id}`);
    }
  }

  return (
    <GenericMutationDialogContent
      onComplete={handleComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'Voicemail box created'}
      isLoadingLabel={'Creating voicemail box'}
      submitButtonLabel={'Create'}
      title={'Create Voicemail Box'}
      mutation={createVmbox}
      formMethods={formMethods}
      submitButtonDisabled={extUsedByQuery.isFetching}
      startDirty={startDirty}
    >
      <Grid container spacing={2}>
        <Grid item md={5}>
          <HookFormTextField
            label={'Mailbox'}
            name={'mailbox'}
            type={'number'}
            options={{ maxLength: 4 }}
            errorMessageParser={error => {
              return error?.message || 'Mailbox cannot exceed 4 digits';
            }}
          />
        </Grid>
        <Grid item md={6}>
          <HookFormTextField
            label={'Name'}
            name={'name'}
            // error={!!formErrors.name}
            // helperText={formErrors.name?.message?.replace('"name"', 'Name')}
          />
        </Grid>
      </Grid>
      <br />
      <div>
        <Controller
          control={control}
          name={'owner_id'}
          render={({ field: { onChange, value, ...props } }) => (
            <OwnerInput
              value={value}
              onChange={onChange}
              // error={!!formErrors.mailbox}
              // helperText={formErrors.mailbox?.message?.replace(
              //   '"mailbox"',
              //   'Mailbox',
              // )}
            />
          )}
        />
        {!watch('owner_id') ? (
          <>
            <br />
            <Typography variant="caption">
              A voicemail box without an owner is "shared" and available to
              everyone
            </Typography>
          </>
        ) : null}
      </div>
      <ExtensionInUseWarnings
        query={extUsedByQuery}
        config={{
          // userError: true,
          userAppend: ': this may cause confusion',
          vmboxAppend: ': this mailbox number is already in use',
          extAppend: ': this may cause confusion',
          vmboxError: true,
        }}
      />
    </GenericMutationDialogContent>
  );
};

export const useVmboxCreateDialog = DialogBuilder(VmboxCreateDialog);

export default VmboxCreateDialog;

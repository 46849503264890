import { gql } from 'graphql-request';
import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPatchDefault,
  patchResourceDefault,
  jwtDecode,
  md5,
  buildPutDefault,
  buildDeleteDefault,
} from 'app/sdk/utils/kazoo';

///////////////
// TODO: jsDoc!
///////////////

const exports = {
  // Queries
  // - graphql
  query: {
    search: ({ search }, fetchOptions) => {
      const vars = {
        take: 10,
        filtersDefault: {
          searchInput: `name:"${search}"`,
        },
        filtersUsers: {
          searchInput: `name:"${search}"`,
        },
        maskDefault: '*',
        maskUsers: '*',
      };
      return fetchFromGraphql('omniSearch', vars, fetchOptions, { full: true });
    },
  },
};

export default exports;

import React, { useState } from 'react';

import { Azure } from './Azure';
import { Slack } from './Slack';
import { Csv } from './Csv';

export const DialogImportUsers = ({ type = 'azure', onClose, ...rest }) => {
  switch (type) {
    case 'azure':
      return <Azure onClose={onClose} {...rest} />;
    case 'slack':
      return <Slack onClose={onClose} {...rest} />;
    case 'csv':
      return <Csv onClose={onClose} {...rest} />;
    default:
      console.error('Invalid Import Type specified:', type);
      break;
  }
  return null;
};

export default DialogImportUsers;

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Directory } from 'types/directory';
import directoryQueryKeys from './directoryQueryKeys';

export const useDirectoryById = (
  id: string | undefined,
  options?: Omit<
    UseQueryOptions<Directory, Error, Directory, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = directoryQueryKeys.byId(id);

  const query = useQuery<Directory, Error>(
    queryKey,
    () =>
      sdk.directory.query.directoryById(
        { id },
        { authToken: authState.auth_token },
      ),
    {
      enabled: id ? true : false, // authState?.auth_token_decoded?.owner_id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useDirectoryById;

import { joiResolver } from '@hookform/resolvers/joi';
import { Box, DialogInProgress, Divider, Grid, Typography } from 'app/design';

import {
  DialogTitle,
  Dialog,
  DialogHeader,
  DialogContent,
  IconButton,
  DialogLoading,
} from 'app/design-lib';
import {
  RemoveCircle as RemoveIcon,
  AddCircleOutlined as AddIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import {
  useCreateContact,
  useUpdateContactPartial,
} from 'app/hooks/mutations/contact';
import { getAtPath } from 'app/utilities';
import useDialogBuilder from 'app/utilities/useDialog';
import { startCase } from 'lodash';
import InputMask from 'react-input-mask';
import { TextField as InputTextField } from 'app/design';

import Joi from 'joi';
import { errors } from 'msw/lib/types/context';
import React, { useEffect } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { Contact } from 'types/contact';
import { v4 as uuidv4 } from 'uuid';
import { Plus, Cancel } from 'iconoir-react';

interface EditContactDialogProps {
  contact?: Contact;
  open: boolean;
  onCancel: () => void;
  onComplete: () => void;
  initialNumber?: string;
  initialName?: string;
}

interface Form {}

const phoneNumbersSchema = Joi.array()
  .items(
    Joi.object({
      id: Joi.string().required(),
      countryCode: Joi.string().required(),
      // TODO: https://www.npmjs.com/package/joi-phone-number when we allow different country codes
      number: Joi.string().allow(''),
      // .required()
      // .label('Number')
      // .regex(/[_]/, { invert: true }),
      digits: Joi.string().allow(null, ''), // parsed from number so not required, set during saving
      label: Joi.string().allow(null, '').label('Label'),
    }),
  )
  // .min(1)
  .label('Phone Numbers');

const firstNameSchema = Joi.string().max(128).label('First Name');
const lastNameSchema = Joi.string().max(128).label('First Name');
const companySchema = Joi.string().max(128).label('First Name');

const schema = Joi.object({
  // couldn't get this alternatives to work with just info({...}).or('lastName', etc...) due to conflict with needing to allow('')
  info: Joi.alternatives().try(
    Joi.object({
      firstName: firstNameSchema.allow(''),
      lastName: lastNameSchema.allow(''),
      company: companySchema,
      phoneNumbers: phoneNumbersSchema,
    }),
    Joi.object({
      firstName: Joi.string().max(128).label('First Name').allow(''),
      lastName: lastNameSchema,
      company: companySchema.allow(''),
      phoneNumbers: phoneNumbersSchema,
    }),
    Joi.object({
      firstName: Joi.string().max(128).label('First Name'),
      lastName: lastNameSchema.allow(''),
      company: companySchema.allow(''),
      phoneNumbers: phoneNumbersSchema,
    }),
  ),
});

const initialNameHandler = (name?: string) => {
  // console.log('name', name);
  if (!name) {
    return {};
  }

  if (name.includes(' ')) {
    const segments = name.split(' ');

    if (segments.length === 2) {
      return {
        firstName: segments[0],
        lastName: segments[1],
      };
    } else {
      return {
        company: name,
      };
    }
  }

  return {
    company: name,
  };
};

const EditContactDialog = ({
  contact,
  open,
  onCancel,
  onComplete,
  initialNumber,
  initialName,
}: EditContactDialogProps) => {
  const createContactMutation = useCreateContact();
  const updateContactMutation = useUpdateContactPartial();
  const formMethods = useForm({
    defaultValues: contact
      ? { info: contact.info }
      : {
          info: {
            phoneNumbers: [
              {
                id: uuidv4(),
                countryCode: 'us',
                number: initialNumber ?? '',
                digits: initialNumber ?? '',
                label: 'mobile',
              },
            ],
            ...initialNameHandler(initialName),
          },
        },
    resolver: joiResolver(schema),
  });

  const handleSave = async ({
    info: { phoneNumbers, ...infoRest },
    ...rest
  }) => {
    const numbers: any[] = [];

    phoneNumbers.forEach(n => {
      // const num = n.number.replace(/(\+1 )/g, '');
      //
      // console.log('n', n);
      if (!n.number) {
        return;
      }

      numbers.push({
        ...n,
        digits: n.number,
        // number: num,
        // digits: num.replace(/([() -])/g, ''),
      });
    });

    const data = {
      ...rest,
      info: {
        ...(infoRest as Contact['info']),
        phoneNumbers: numbers,
      },
      source: null,
      schema: 'v0.1' as const,
    };

    // console.log('data', { ...contact, ...data });

    if (contact) {
      const updateContactPromise = updateContactMutation.mutateAsync({
        ...contact,
        ...data,
      });

      // toast.promise(updateContactPromise, {
      //   pending: 'Updating contact...',
      //   success: 'Contact updated!',
      //   error: 'Error updating contact.',
      // });

      let result = await updateContactPromise;
      // onComplete();
    } else {
      const createContactPromise = createContactMutation.mutateAsync(data, {
        // onSuccess: onComplete,
      });

      // toast.promise(createContactPromise, {
      //   pending: 'Creating contact...',
      //   success: 'Contact added!',
      //   error: 'Error creating contact.',
      // });

      let result = await createContactPromise;
      // onComplete();
    }
  };

  // console.log('saving errors', formMethods.formState.errors);
  // error thrown for not having at least one of the following fields: 'firstName', 'lastName', 'company'
  const requiredFieldError =
    // @ts-ignore
    formMethods.formState.errors?.info?.type === 'alternatives.match';

  useEffect(() => {
    const fields = [
      'info.firstName',
      'info.lastName',
      'info.company',
    ] as FieldPath<Pick<Contact, 'info'>>[];
    if (requiredFieldError) {
      fields.forEach(field => {
        formMethods.setError(field, {
          message: 'Contact requires at least one of these fields.',
        });
      });
    } else {
      formMethods.clearErrors(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFieldError]);

  const maybeCreate = (!!initialName || !!initialNumber) && !contact;

  const isSuccess =
    updateContactMutation.isSuccess || createContactMutation.isSuccess;
  return (
    // @ts-ignore
    <>
      {updateContactMutation.isLoading || createContactMutation.isLoading ? (
        <DialogLoading
          label={
            updateContactMutation.isLoading
              ? 'Updating contact...'
              : 'Creating contact...'
          }
        />
      ) : (
        <FormProvider {...formMethods}>
          {isSuccess ? null : (
            <DialogHeader
              onClose={onCancel}
              title={`${contact ? 'Edit' : 'New'} Contact`}
            />
          )}
          <DialogContent>
            {isSuccess ? (
              <div className={`grid place-items-center p-8`}>
                <span>Contact {contact ? 'updated!' : 'created!'}</span>
              </div>
            ) : (
              <div className={`grid  gap-2 grid-cols-2`}>
                <HookFormTextField
                  name={'info.firstName'}
                  label={'First Name'}
                  autoFocus={!contact}
                />
                <HookFormTextField name={'info.lastName'} label={'Last Name'} />
                <div className={`col-span-2`}>
                  <HookFormTextField name={'info.company'} label={'Company'} />
                </div>
                <HookFormPhoneNumbers name={'info.phoneNumbers'} />
              </div>
            )}
          </DialogContent>
          <DefaultDialogActions
            onSave={
              isSuccess ? onComplete : formMethods.handleSubmit(handleSave)
            }
            saveDisabled={maybeCreate ? false : !formMethods.formState.isDirty}
            saveLabel={
              isSuccess
                ? 'Dismiss'
                : formMethods.formState.isDirty || maybeCreate
                ? `${contact ? 'Update' : 'Create'} Contact`
                : 'No changes'
            }
            onCancel={isSuccess ? undefined : onCancel}
          />
        </FormProvider>
      )}
    </>
  );
};

const HookFormPhoneNumbers = ({ name }) => {
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const { append, remove, fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name, // unique name for your Field Array
    // shouldUnregister: true,
  });

  const handleRemove = (index: number) => () => {
    remove(index);
  };

  const handleAdd = () => {
    append({
      id: uuidv4(),
      countryCode: 'us',
      number: '',
      digits: '',
      label: '',
    });
  };

  return (
    <div className={`grid col-span-2 grid-cols-2 gap-2`}>
      <div className={`flex col-span-2 justify-between w-full`}>
        <span className={'text-md font-medium'}>Numbers</span>
        <IconButton variant={'ghost'} size={'sm'} onClick={handleAdd}>
          <Plus fr={undefined} />
        </IconButton>
      </div>
      {fields.map((field, index) => (
        <>
          <HookFormTextField
            // fullWidth
            // size={'small'}
            name={`${name}.${index}.label`}
            label={'Label'}
          />

          <div className={`space-x-2 flex items-center justify-between`}>
            <HookFormTextField
              // @ts-ignore
              label={'Number'}
              name={`${name}.${index}.number`}
            />
            {/*<HookFormPhoneNumber*/}
            {/*  name={`${name}.${index}.number`}*/}
            {/*  errorMessageParser={error => 'Invalid number.'}*/}
            {/*/>*/}

            {fields.length > 1 ? (
              <IconButton
                variant={'ghost'}
                size={'sm'}
                color={'negative'}
                onClick={handleRemove(index)}
              >
                <Cancel fr={undefined} />
              </IconButton>
            ) : null}
          </div>
        </>
      ))}
    </div>
  );

  return (
    <>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span className={'text-md font-medium'}>Numbers:</span>
          {formErrors?.info?.phoneNumbers?.message ? (
            <Typography variant={'caption'} color={'error'}>
              {formErrors.info.phoneNumbers.message}
            </Typography>
          ) : null}
        </div>
        <IconButton onClick={handleAdd}>
          <AddIcon color={'success'} />
        </IconButton>
      </Box>
      <div style={{ padding: 5 }}>
        {fields.map((field, index) => (
          <Grid
            sx={{ mt: 2 }}
            container
            alignItems={'bottom'}
            columnSpacing={2}
          >
            <Grid item xs={5}>
              <HookFormTextField
                // fullWidth
                // size={'small'}
                name={`${name}.${index}.label`}
                label={'Label'}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                // @ts-ignore
                label={<>&nbsp;</>}
                name={`${name}.${index}.number`}
              />
              {/*<HookFormPhoneNumber*/}
              {/*  name={`${name}.${index}.number`}*/}
              {/*  errorMessageParser={error => 'Invalid number.'}*/}
              {/*/>*/}
            </Grid>
            <Grid item xs={1}>
              {fields.length > 1 ? (
                <IconButton onClick={handleRemove(index)}>
                  <RemoveIcon color={'error'} />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
};

const HookFormPhoneNumber = ({
  name,
  errorMessageParser,
}: {
  name: string;
  errorMessageParser?: (error: any) => string;
}) => {
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const error = getAtPath(formErrors, name);

  const errorMessage = error
    ? errorMessageParser
      ? errorMessageParser(error)
      : error.message
    : undefined;
  return (
    <Controller
      // @ts-ignore
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur, ...props } }) => (
        <InputMask
          mask="+1 (999) 999-9999"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        >
          <InputTextField
            // @ts-ignore
            {...props}
            // @ts-ignore
            label="Number"
            size="small"
            fullWidth
            error={!!errorMessage}
            helperText={errorMessage?.replace(`"${name}"`, startCase(name))}
          />
        </InputMask>
      )}
    />
  );
};

export const useEditContactDialog = (initialOpen = false) => {
  const useDialog = useDialogBuilder(EditContactDialogWrapper);
  return useDialog({ initialOpen });
};

const EditContactDialogWrapper = (props: EditContactDialogProps) => {
  return (
    <Dialog open={props.open} size={'lg'} onClose={props.onCancel}>
      <EditContactDialog {...props} />
    </Dialog>
  );
};

export default EditContactDialogWrapper;

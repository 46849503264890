// link to theme explorer: https://mui.com/customization/default-theme/

// Figure out how to fix Typescript so we can add new color variations
// TODO: https://mui.com/customization/palette/#adding-new-colors

const customPalette = {
  content: {
    color: 'rgb(11, 32, 39)', // for text
    // background: "#FBFBFB",
    background: 'rgb(248,253,253)', //"rgba(84,199,214,0.04)",
  },
  // sidebar: {
  //   main: "#00BCD4",
  //   light: "#62EFFF",
  //   dark: "#008BA3",
  //   contrastText: "#fff",
  // },
};
const palette = {
  ...customPalette,
  primary: {
    main: '#09AFCF', // 00BCD4
    mainAlt: '#1B7D90',
    mainAlt2: '#0BB093', // used for Extension
    light: '#62EFFF',
    dark: '#008BA3',
    contrastText: '#fff',
  },
  secondary: {
    main: '#EAB464',
    light: '#FFE693',
    dark: '#B58437',
    contrastText: '#fff',
  },
  error: {
    main: '#F44336',
    light: '#F88078',
    dark: '#E31B0C',
    contrastText: '#fff',
  },
  info: {
    main: '#2196F3',
    light: '#64B6F7',
    dark: '#0B79D0',
    contrastText: '#fff',
  },
  warning: {
    main: '#ED6C02',
    light: '#FFB547',
    dark: '#C77700',
    contrastText: '#fff',
  },
  success: {
    main: '#4CAF50',
    light: '#7BC67E',
    dark: '#3B873E',
    contrastText: '#fff',
  },
  gray: {
    main: '#9e9e9e',
    light: '#300',
    dark: '#424242',
    contrastText: '#fff',
  },
  white: {
    main: '#fff',
    light: '#fff',
    dark: '#fff',
    contrastText: '#fff',
  },
  black: {
    main: '#000',
    light: '#000',
    dark: '#000',
    contrastText: '#000',
  },
  background: {
    paper: '#ffffff',
    default: customPalette.content.background, // body background
  },
  card: {
    errorBg: '#FEE8E8',
    border: '#D6E8EB',
  },
  text: {
    primary: customPalette.content.color, // #0B2027
    secondary: 'rgba(0,0,0,0.6)', // do not remove this property; @react-theming/storybook-addon will throw error
    disabled: 'rgba(0,0,0,0.38)',
  },
};

export default palette;

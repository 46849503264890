import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    conferences: (vars, fetchOptions) =>
      fetchFromGraphql('conferences', vars, fetchOptions),

    // Fetch a single conference by id
    conferenceById: (vars, fetchOptions) =>
      fetchFromGraphql('conferenceById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    create: buildPutDefault('conferences', { buildUrlOpts: { skipId: true } }),
    updatePartial: buildPatchDefault('conferences'),
    delete: buildDeleteDefault('conferences'),
  },
};

export default exports;

import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import vmboxQueryKeys from 'app/hooks/queries/vmbox/vmboxQueryKeys';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { UserUpdatePartialDoc } from 'types/user';
import { VmboxUpdatePartialDoc } from 'types/vmbox';

export const useUpdateVmboxPartial = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, VmboxUpdatePartialDoc, unknown>(
    (vmboxUpdatePartialDoc: VmboxUpdatePartialDoc) =>
      sdk.vmbox.mutate.updatePartial(
        vmboxUpdatePartialDoc,
        authState?.auth_token,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(vmboxQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Account } from 'types/account';
import { User } from 'types/user';
import accountQueryKeys from './accountQueryKey';

export const useAccountStripeCustomerQuery = (
  options?: Omit<
    UseQueryOptions<any, Error, any, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  const authState = useAuthSelector();
  // const { account_id } = authState.auth_token_decoded.account_id;
  const queryKey = accountQueryKeys.stripe_customer;

  // console.log('authState:', authState, authState?.owner_id ? true : false);
  const query = useQuery<any, Error>(
    queryKey,
    () =>
      sdk.account.query.stripeCustomer(
        {},
        { authToken: authState?.auth_token },
      ), // fetchAccount(authState.auth_token),
    {
      //
      enabled: authState?.owner_id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useAccountStripeCustomerQuery;

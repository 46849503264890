import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Device as device_id } from './components/device_id';
import { Extension as extension } from './components/extension';
import { Group as group_id } from './components/group_id';
import { MediaId as media_id } from './components/media_id';
import { MenuGreeting as menu_greeting } from './components/menu_greeting';
import { MenuName as menu_name } from './components/menu_name';
import { MenuTargets as menu_targets } from './components/menu_targets';
import { UserFailOnSingleReject as user_fail_on_single_reject } from './components/user_fail_on_single_reject';
import { User as user_id } from './components/user_id';
import { UserRingTime as user_ring_time } from './components/user_ring_time';
import { VoicemailBoxMaxMessageLength as vm_max_msg_length } from './components/vm_max_msg_length';
import { VoicemailBox as vmbox_id } from './components/vmbox_id';

const MAP = {
  extension,
  device_id,
  group_id,
  media_id,
  menu_name,
  menu_greeting,
  menu_targets,
  user_fail_on_single_reject,
  user_ring_time,
  user_id,
  // transfer_ext,
  vmbox_id,
  vm_max_msg_length,
};

const SimpleUIComponent = ({
  item,
  modifyPath = '',
  onChangeSimple,
  ee,
}: any) => {
  const { varItem, moduleItem } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const Component = MAP[varItem.read.type];
  if (!Component) {
    console.error('Invalid SimpleUIComponent:', varItem);
    return null;
  }

  return (
    <Component
      item={item}
      modifyPath={modifyPath}
      onChangeSimple={onChangeSimple}
      ee={ee}
    />
  );
};

export default SimpleUIComponent;

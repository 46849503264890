import {
  Container,
  Divider,
  Drawer,
  Grid,
  CircularProgress,
  Button,
} from 'app/design';
import { useAuthSelector } from 'app/data/auth';
import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import axios from 'axios';
import { useEffect, useState } from 'react';
import * as React from 'react';
import _ from 'lodash';
import constants from 'app/constants';

interface FeatureCodeDrawerProps {
  onClose: () => void;
}

const FeatureCodesDrawer = ({ onClose }: FeatureCodeDrawerProps) => {
  return (
    <Drawer anchor="right" open onClose={onClose}>
      <FeatureCodeContainer />
    </Drawer>
  );
};

export const FeatureCodeContainer = () => {
  const { auth_token } = useAuthSelector();

  const [addLoading, setAddLoading] = useState(false);
  const [featureCodesOutput, setFeatureCodesOutput] = useState<any>(null);
  const {
    data: featureCodesResp,
    isLoading: featureCodesAreLoading,
    isError: featureCodesError,
    refetch: refetchFeatureCodes,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [['featurecode', 'exists']],
          },
        },
      ],
    },
    // options: {
    //   onSuccess: callflowPageResp => {
    //     const { callflows } = callflowPageResp;
    //     setFeatureCodesOutput(featureCodesFormatData(callflows));
    //   },
    // },
  });

  const handleAdd = async () => {
    setAddLoading(true);
    try {
      // Create feature codes
      const result = await axios.post(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/v1/feature_codes`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': auth_token ?? '', // Uncomment or modify to test 401
          },
        },
      );
      console.log('feature code results', result);
    } catch (e) {
      console.error(e);
    }

    await refetchFeatureCodes();
    setAddLoading(false);
  };

  useEffect(() => {
    if (featureCodesResp?.callflows) {
      setFeatureCodesOutput(featureCodesFormatData(featureCodesResp.callflows));
    } else {
      setFeatureCodesOutput([]);
    }
  }, [featureCodesResp?.callflows]);
  return (
    <div className={`text-neutral-90 flex flex-col space-y-4 p-6 pt-0`}>
      <div className={`flex flex-col space-y-2`}>
        <span className={`text-lg font-medium`}>Feature Codes</span>
        <span className={`text-md text-neutral-60`}>
          These are codes/numbers that can be dialed from internal devices
        </span>
      </div>
      <Divider />
      <div>
        {featureCodesAreLoading ? (
          <>
            <CircularProgress variant={'indeterminate'} />
            <span>Loading Feature Codes...</span>
          </>
        ) : featureCodesError ? (
          <span>Error loading feature codes.</span>
        ) : featureCodesOutput?.length ? (
          featureCodesOutput.map((featureCode, i) => (
            <div key={i} className={`pb-3 flex flex-col space-y-4`}>
              <div>
                <span className={`font-medium text-md`}>
                  {featureCode.label}
                </span>
              </div>
              {featureCode.items.map((item, i) => (
                <div className={`grid gap-2 grid-cols-5`}>
                  <div className={`col-span-2`}>
                    <div className={`text-sm whitespace-nowrap`}>
                      {item.label}
                    </div>
                    <div className={`text-sm font-medium`}>
                      {item.hasStar ? '*' : ''}
                      {item.number}
                    </div>
                  </div>

                  {item.tooltip ? (
                    <div className={`text-neutral-60 text-sm col-span-3`}>
                      {item.tooltip}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          ))
        ) : (
          <>
            <span>Feature Codes have not been set up for this account</span>
            <Button loading={addLoading} onClick={handleAdd}>
              Add Feature Codes
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const featureCodesFormatData = function (featureCodeData) {
  var defaultCategories = _.map(categories, function (codecs, category) {
    return {
      key: category,
      items: codecs,
    };
  });

  return _.chain(featureCodeData)
    .filter(function (callflow) {
      // Some old callflows have been created with the feature code key, so we check
      // to make sure they also have a name associated
      return _.has(callflow, 'doc.featurecode.name');
    })
    .groupBy(function (callflow) {
      var string = callflow.doc.featurecode.name.match(/^(\w+){1}/)[0],
        category = _.has(categories, string) ? string : 'misc',
        defaultCategory = _.find(defaultCategories, function (category) {
          return _.includes(category.items, string);
        });

      return _.get(defaultCategory, 'key', category);
    })
    .map(function (codes, category) {
      return {
        label: featureCodeLang.categories[category],
        items: _.chain(codes)
          .map(function (code) {
            // console.log('code:', code);
            var i18n = _.get(featureCodeLang.labels, code.doc.featurecode.name);

            return {
              hasStar:
                (!_.isEmpty(code.doc.numbers) &&
                  _.startsWith(code.doc.numbers[0], '*')) ||
                (!_.isEmpty(code.doc.patterns) &&
                  code.doc.patterns[0].match(/^(\^?\\\*)/)),
              label: _.get(
                i18n,
                'label',
                _.capitalize(code.doc.featurecode.name),
              ),
              number: _.has(code.doc.featurecode, 'number')
                ? _.replace(code.doc.featurecode.number, /\\/g, '')
                : '',
              tooltip: _.get(i18n, 'tooltip'),
            };
          })
          .sortBy(function (code) {
            var number = _.toNumber(code.number);

            return _.isNaN(number) ? -1 : number;
          })
          .value(),
      };
    })
    .sortBy('label')
    .value();
};

const categories = {
  qubicle: [
    'qubicle login',
    'qubicle set ready',
    'qubicle set away',
    'qubicle logout',
  ],
  call_forward: [
    'call_forward[action=activate]',
    'call_forward[action=deactivate]',
    'call_forward[action=toggle]',
    'call_forward[action=update]',
    'call_forward[action=on_busy_enable]',
    'call_forward[action=on_busy_disable]',
    'call_forward[action=no_answer_enable]',
    'call_forward[action=no_answer_disable]',
  ],
  hotdesk: [
    'hotdesk[action=login]',
    'hotdesk[action=logout]',
    'hotdesk[action=toggle]',
  ],
  parking: ['park_and_retrieve', 'valet', 'retrieve'],
  do_not_disturb: [
    'donotdisturb[action="enable"]',
    'donotdisturb[action="disable"]',
    'donotdisturb[action="toggle"]',
  ],
  misc: [
    'voicemail[action=check]',
    'voicemail[action="direct"]',
    'intercom',
    'privacy[mode=full]',
    'directory',
    'time',
    'call_waiting[action=enable]',
    'call_waiting[action=disable]',
    'sound_test_service',
    'call_recording',
    'move',
  ],
};

const featureCodeLang = {
  title: 'Available Feature Codes',
  noFeatureCodeMessage:
    'There are no feature codes enabled on your account yet. Feature codes are automatically created when you load the Main Number page.',
  mainNumberLink: 'Go to Main Number',
  labels: {
    directed_ext_pickup: {
      label: 'Directed Extension Pickup',
      tooltip: '(Dial *87, then the extension number of the ringing device)',
    },
    'qubicle login': {
      label: 'Agent Login',
      tooltip: '',
    },
    'qubicle set ready': {
      label: 'Set Agent Ready',
      tooltip: '',
    },
    'qubicle set away': {
      label: 'Set Agent Away',
      tooltip: '',
    },
    'qubicle logout': {
      label: 'Agent Logout',
      tooltip: '',
    },
    'call_forward[action=activate]': {
      label: 'Enable Call-Forward',
      tooltip: '(Dial *72, then the number to forward to)',
    },
    'call_forward[action=deactivate]': {
      label: 'Disable Call-Forward',
      tooltip: '(Dial *73)',
    },
    'call_forward[action=toggle]': {
      label: 'Toggle Call-Forward',
      tooltip:
        '(Dial *74) The system forwards to that same number as previous forward.',
    },
    'call_forward[action=update]': {
      label: 'Update Call-Forward',
      tooltip:
        '(Dial *56, then number to forward to) This will update where your calls are forwarded to.',
    },
    'call_forward[action=on_busy_enable]': {
      label: 'Enable Call-Forward on Busy',
      tooltip: '',
    },
    'call_forward[action=on_busy_disable]': {
      label: 'Disable Call-Forward on Busy',
      tooltip: '',
    },
    'call_forward[action=no_answer_enable]': {
      label: 'Enable Call-Forward No Answer',
      tooltip: '',
    },
    'call_forward[action=no_answer_disable]': {
      label: 'Disable Call-Forward No Answer',
      tooltip: '',
    },
    'hotdesk[action=login]': {
      label: 'Enable Hot Desking',
      tooltip:
        '(Dial *11 + Hotdesk ID) Hotdesk ID can be configured in SmartPBX under User. All calls will be sent to this device.',
    },
    'hotdesk[action=logout]': {
      label: 'Disable Hot Desking',
      tooltip: '(Dial *12) "logout" of this phone.',
    },
    'hotdesk[action=toggle]': {
      label: 'Toggle Hot Desking',
      tooltip:
        '(Dial *13 + Hotdesk ID) To turn Hotdesk on/off from any device.',
    },
    'voicemail[action=check]': {
      label: 'Check Voicemail',
      tooltip:
        '(Dial *97 + extension number) You can log into other Mailboxes.',
    },
    'voicemail[action="direct"]': {
      label: 'Direct to Voicemail',
      tooltip:
        '(Press transfer, then ** + extension number of recipient) Call will go straight to voicemail.',
    },
    'voicemail[single_mailbox_login]': {
      label: 'Check Voicemail (auto login)',
      tooltip:
        '(Dial *98 then password) If auto-login is enabled, you do not require a password.',
    },
    intercom: {
      label: 'Intercom',
      tooltip:
        '(Dial *0 + the extension number) If the phone supports intercom it will answer hands-free and you can speak.',
    },
    'privacy[mode=full]': {
      label: 'Privacy',
      tooltip:
        '(Dial *67 + the number you are calling) This will block your Caller ID.',
    },
    park_and_retrieve: {
      label: 'Park and Retrieve',
      tooltip:
        '(Dial *3 plus a specific number) to park a call in a specific number. Example: *3 + 101 = call is parked in spot 101',
    },
    valet: {
      label: 'Valet',
      tooltip:
        "(Dial *4) This will announce the next available 'Parking Spot #'.",
    },
    retrieve: {
      label: 'Retrieve',
      tooltip:
        '(Dial *5 plus a specific parked number) to retrieve parked call. Example: *5+101 = call is picked up from parking spot 101',
    },
    'donotdisturb[action="enable"]': {
      label: 'Enable Do not disturb',
      tooltip: '',
    },
    'donotdisturb[action="disable"]': {
      label: 'Disable Do not disturb',
      tooltip: '',
    },
    'donotdisturb[action="toggle"]': {
      label: 'Toggle Do not disturb',
      tooltip: '',
    },
    directory: {
      label: 'Directory',
      tooltip: '',
    },
    time: {
      label: 'Check Time',
      tooltip: '',
    },
    'call_waiting[action=enable]': {
      label: 'Enable Call-Waiting',
      tooltip: '',
    },
    'call_waiting[action=disable]': {
      label: 'Disable Call-Waiting',
      tooltip: '',
    },
    sound_test_service: {
      label: 'Sound Test Service',
      tooltip: '',
    },
    call_recording: {
      label: 'Call Recording',
      tooltip: '',
    },
    move: {
      label: 'Move',
      tooltip:
        '(Dial from any phone not in use) The system will scan for a user\'s call on a different phone and "move" it to this phone.',
    },
  },
  __comment: 'UI-1028, v3.20_s0: Added categories for the feature codes.',
  categories: {
    qubicle: 'Call Center',
    call_forward: 'Call-Forward',
    hotdesk: 'Hot-Desking',
    parking: 'Parking',
    do_not_disturb: 'Do Not Disturb',
    misc: 'Miscellaneous',
  },
};

export default FeatureCodesDrawer;

import { QuickFinderVmboxDialog } from 'app/components/QuickFinderVmboxDialog';
import { Button, Typography } from 'app/design';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { useFormContext } from 'react-hook-form';

// interface declaring which props are required/allowed
// interface VoicemailBoxProps {
//   // directoryId: string;
//   callflow?: any;
//   open?: boolean;
//   onCancel: () => void;
//   // onComplete: () => void;
// }

const VoicemailBox = ({ item, modifyPath, onChangeSimple }) => {
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showFinderDialog, toggleShowFindVmboxDialog] = useToggleReducer(false);

  const key = varItem.key;
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);

  // simple variable values
  const currentVarOutputValue = varItem.output_value;
  const defaultValueInFlow = varItem.output_value_default;
  const simpleValueInCallflow = variables[key];

  // get CURRENT value FROM MODULE
  // - for when saved by the Advanced Editor!
  //   - that doesnt update the varItem, it only updates the actual flow!
  const currentValue = moduleItem?.data?.id;
  const vmboxId = simpleValueInCallflow ?? currentValue;

  const {
    data: vmboxCurrent,
    isLoading: vmboxCurrentIsLoading,
    isFetched: vmboxCurrentIsFetched,
  } = useVmboxQuery({ id: currentValue });

  const {
    data: vmboxSimple,
    isLoading: vmboxSimpleIsLoading,
    isFetched: vmboxSimpleIsFetched,
  } = useVmboxQuery({ id: simpleValueInCallflow });

  const onChangeVmboxSelect = selected => {
    const [vmboxId] = selected;
    setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, vmboxId);
    setValue(`${modifyPath}callflow${moduleModifyPath}.data.id`, vmboxId, {
      shouldDirty: true,
    });
    onChangeSimple && onChangeSimple();
    // setAtPath(tmpModuleItem, `data.id`, vmboxId); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindVmboxDialog();
  };

  const handleClear = () => {
    // setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, null);
    setValue(`${modifyPath}callflow${moduleModifyPath}.data.id`, null, {
      shouldDirty: true,
    });
    onChangeSimple && onChangeSimple();

    toggleShowFindVmboxDialog();
  };

  // console.log('varitem voicemailbox:', varItem, {
  //   defaultValueInFlow,
  //   simpleValueInCallflow,
  // });

  return (
    <div>
      <QuickFinderVmboxDialog
        open={showFinderDialog}
        onSelect={onChangeVmboxSelect}
        onCancel={toggleShowFindVmboxDialog}
        onClear={handleClear}
        initialSelected={simpleValueInCallflow ? [simpleValueInCallflow] : []}
        selectionTitle={vmboxSimple ? `${vmboxSimple.doc.name}` : null}
        allowSelectNone
      />
      <Typography variant="h3">Voicemail Box: </Typography>

      <Typography variant="bodyBig" sx={{ fontWeight: 'bold' }} paragraph>
        {vmboxCurrentIsLoading
          ? 'Loading...'
          : vmboxCurrent
          ? vmboxCurrent.doc?.name ?? 'Invalid voicemail box'
          : 'None set'}
      </Typography>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={toggleShowFindVmboxDialog}
      >
        Change Voicemail Box
      </Button>
      {/* <Typography variant="body1">
        Current:{' '}
        {vmboxCurrentIsLoading
          ? 'Loading...'
          : vmboxCurrent
          ? vmboxCurrent.doc?.name ?? 'Invalid voicemail box'
          : 'None set'}
      </Typography>
      <Typography variant="body1" onClick={toggleShowFindVmboxDialog}>
        Custom:
        {vmboxSimpleIsLoading
          ? 'Loading...'
          : vmboxSimple
          ? vmboxSimple.doc?.name ?? 'Invalid voicemail box'
          : 'None set'}
      </Typography> */}
    </div>
  );
};

export default VoicemailBox;

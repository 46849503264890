import buildResourceUrl from './buildResourceUrl';
import { patch } from './methods';

// Default PATCH-building function
// - uses buildResourceUrl and passes thru options from opts.buildUrlOpts
// - expects the "id" to exist at "doc.id" (option to "delete doc.id after building URL" for phoneNumbers?)
export const buildPatchDefault =
  (resourceApiPathname, opts) => async (doc, auth_token) => {
    // uses a PATCH request to update the user doc
    // - expect id in doc.id
    if (!doc.id) {
      throw new Error('Missing doc.id for resource to update');
    }

    return patchResourceDefault({
      url: buildResourceUrl(
        resourceApiPathname,
        doc.id,
        auth_token,
        opts?.buildUrlOpts,
      ),
      doc,
      auth_token,
    });
  };

export const patchResourceDefault = async ({ url, doc, auth_token }) => {
  const resp = await patch(url, doc, { auth_token });
  return resp.data;
};

import { AudioPlayer } from 'app/components/AudioPlayer';
import { Button, SquareProgress, TextField } from 'app/design-lib';
import { ButtonProps } from 'app/design-lib/components/Button/Button';
import {
  useCreateMedia,
  useUpdateMediaFull,
  useUploadMedia,
} from 'app/hooks/mutations/media';
import { Mic as MicIcon, Pause, Play, Square } from 'iconoir-react';
import React, { useCallback, useEffect, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useDropzone } from 'react-dropzone';
import { MediaType } from 'types/media';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';
import { MediaSubType } from 'app/components/AudioEditor/AudioEditor';
import RecordAudio from 'app/components/MediaDialog/components/RecordAudio/RecordAudio';

interface UploadViewProps {
  onComplete: (mediaId: string | null) => Promise<void>;
  onCancel: () => void;
  subType: MediaSubType;
  mediaId?: string;
  mediaName?: string;
  isGreeting?: boolean;
  loadingLabel?: string;
  setIsMutating: React.Dispatch<boolean>;
}

const RecordView = ({
  onComplete,
  onCancel,
  subType,
  isGreeting,
  mediaName,
  mediaId,
  loadingLabel,
  setIsMutating,
}: UploadViewProps) => {
  const [file, setFile] = useState<string | ArrayBuffer | null | undefined>(
    null,
  );
  const [audio, setAudio] = useState<any>(null);
  const [name, setName] = useState(mediaName ?? '');
  const [isUploading, setIsUploading] = useState(false);
  const [externalMutating, setExternalMutating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const createMedia = useCreateMedia();
  const uploadMedia = useUploadMedia();
  const updateMediaFull = useUpdateMediaFull();

  useEffect(() => {
    setIsMutating(externalMutating || isUploading);
  }, [externalMutating, isUploading]);

  const recorderControls = useAudioRecorder();
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    isRecording,
    isPaused,
    recordingBlob,
  } = recorderControls;

  let buttonProps: any;

  if (isRecording) {
    buttonProps = {
      color: 'negative',
      children: 'Stop recording',
      startIcon: <Square fr={undefined} />,
    };
  } else {
    buttonProps = {
      color: 'neutral',
      children: recordingBlob ? 'Re-record audio' : 'Record audio',
      startIcon: <MicIcon fr={undefined} />,
    };
  }

  useEffect(() => {
    if (recordingBlob) {
      // setFile(URL.createObjectURL(recordingBlob));
      var reader = new FileReader();
      reader.onloadend = function (evt) {
        const file = evt.target?.result;
        setFile(file);
      };
      reader.onerror = function (err) {
        console.error('Reader Error:', err);
        alert(
          'Sorry, there was a problem recording audio, please try again or record the audio locally and upload as a file.',
        );
      };

      setAudio(new Audio(URL.createObjectURL(recordingBlob)));
      reader.readAsDataURL(
        new Blob([recordingBlob], {
          type: `audio/mp3`,
        }),
      );
    }
  }, [recordingBlob]);

  const onUpload = async () => {
    setIsUploading(true);
    setExternalMutating(true);
    let newMedia: any;

    try {
      const mutation = isGreeting && mediaId ? updateMediaFull : createMedia;
      const resp1 = await mutation.mutateAsync({
        id: mediaId!,
        name,
        media_source: MediaType.Recording,
      });

      if (resp1.status !== 'success') {
        throw new Error(
          'Error creating media file. Please try again later or contact support.',
        );
      }

      newMedia = resp1.data;

      const mediaToUpload = {
        id: newMedia.id,
        encodedFile: file as string,
      };

      const resp = await uploadMedia.mutateAsync(mediaToUpload);

      if (resp.status === 'success') {
        newMedia.updated_at = Date.now();
        newMedia.sub_type = subType;
        await updateMediaFull.mutateAsync(newMedia);
      }
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);

      console.error(e);
      setErrorMessage(
        'Error uploading media. Please try again later or contact support.',
      );
      return;
    }

    try {
      await onComplete(newMedia.id);
    } catch (e) {
      console.error(e);
      setErrorMessage(
        'Media successfully uploaded but an error occurred when setting the media. Please try again from the library view.',
      );
    }
    setExternalMutating(false);
  };

  return (
    <div className={`flex`}>
      {externalMutating || isUploading ? (
        <div className={'w-full h-full grid place-items-center'}>
          <div className={'flex flex-col justify-center items-center'}>
            <SquareProgress />
            <span className={'text-md font-medium'}>
              {isUploading ? 'Uploading recording...' : loadingLabel}
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className={'flex flex-col w-full space-y-2'}>
            <div className={'flex flex-wrap space-x-4 items-end'}>
              <RecordAudio setValue={setFile} />
            </div>
            {file ? (
              <>
                {isGreeting ? null : (
                  <TextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label={'Media name'}
                    helperText="This will be available in your Library"
                    placeholder={'Audio name'}
                  />
                )}
                <div className={`flex mt-2 space-x-2 items-center`}>
                  <Button
                    size={'md'}
                    color={`accent`}
                    variant={`fill`}
                    onClick={onUpload}
                    disabled={isUploading || !name}
                  >
                    Upload & apply
                  </Button>
                  <Button
                    size={'md'}
                    color={`neutral`}
                    variant={`ghost`}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : null}
            {errorMessage ? (
              <span className={`text-negative-60 font-medium text-md`}>
                {errorMessage}
              </span>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default RecordView;

import { Tooltip } from '@mui/material';
import { BuyPhoneNumberDialog } from 'app/components/BuyPhoneNumberDialog';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import { AvatarGroup } from 'app/design-lib';
import { DialogTitle, Divider, DialogContent, useMediaQuery } from 'app/design';
import {
  Alert,
  Button,
  IconButton,
  Setting,
  Table,
  TableCell,
  TableRow,
  Dialog,
  DialogHeader,
} from 'app/design-lib';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { SettingsPanelLoader } from 'app/design-lib/components/SettingsPanelLoader.tsx';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { InfoEmpty as InfoIcon, SineWave as SineWaveIcon } from 'iconoir-react';
import SimpleBar from 'simplebar-react';
import { Tab } from '@headlessui/react';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import AddIcon from '@mui/icons-material/Add';
import { Link, useHistory } from 'react-router-dom';
import NumbersIcon from '@mui/icons-material/Numbers';
import { useMutation, useQueryClient } from 'react-query';
import { useImmer } from 'use-immer';
import { Controller, useForm } from 'react-hook-form';
import { DeletePipeDialog } from './components/DeletePipeDialog';
import useDevMode from 'app/utilities/useDevMode';

import {
  PhoneOutcome as PhoneOutcomeIcon,
  Group as GroupIcon,
  User as UserIcon,
  FileNotFound as FileNotFoundIcon,
  Plus as PlusIcon,
  MoreHoriz,
} from 'iconoir-react';

import { useTour } from '@reactour/tour';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';

import { Emoji } from 'emoji-picker-react';
import { useCreateCallflow } from 'app/hooks/mutations/callflow';

import { usePipeCreate, usePipesQuery } from 'app/pages/v2/common/sdk';
import {
  useAuthenticatedUserQuery,
  useListUsersQuery,
} from 'app/hooks/queries/user';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { useCreateVmbox } from 'app/hooks/mutations/vmbox';
import { useCreateMedia } from 'app/hooks/mutations/media/useCreateMedia';
import BuyNumbersButton from './components/BuyNumbersButton/BuyNumbersButton';
import { AddSharedLineDialog } from 'app/pages/v2/settings/Pipes/components/AddSharedLineDialog';

const Pipes = () => {
  const { devMode } = useDevMode();

  const { data: user } = useAuthenticatedUserQuery();
  const [openNewPipeDialog, toggleNewPipeDialog] = useToggleReducer();
  const pipesQuery = usePipesQuery({
    filters: {
      NOT: {
        type: 'room',
      },
    },
  });

  const {
    data: phoneNumbersData,
    isLoading: phoneNumbersIsLoading,
    refetch: phoneNumbersRefetch,
  } = useListPhoneNumbersQuery({
    skip: 0,
    take: 10, // take
    orderBy: [], // order by
    filters: {}, // filters
  });
  const { phoneNumbers } = phoneNumbersData ?? {};
  // console.log('Phone Numbers:', phoneNumbers);

  const pipes = pipesQuery.data?.pipes ?? [];
  // console.log('test', [
  //   // @ts-ignore
  //   ...new Set(pipes.flatMap(pipe => Object.keys(pipe.users))),
  // ]);
  const usersQuery = useListUsersQuery({
    filters: {
      context: {
        // @ts-ignore

        ids: [...new Set(pipes.flatMap(pipe => Object.keys(pipe.users)))],
      },
    },
    options: {
      enabled: !!pipes.length,
    },
  });
  const users = usersQuery.data?.users ?? [];

  const history = useHistory();

  // console.log('PIPES:', pipes);

  const pipeNew = usePipeNew();
  const { isOpen, currentStep, steps, setSteps, setIsOpen, setCurrentStep } =
    useTour();

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  const handlePurchase = async numbers => {
    let users = {};
    if (user) {
      // @ts-ignore
      users[user.id] = { enabled: true };
    }

    toggleBackdrop();
    console.log('Purchased numbers:', numbers);
    // create pipe for each number
    for (let num of numbers) {
      const pipe = await pipeNew.mutateAsync({
        name: 'Alternate',
        emoji: '1f4a0', // diamond
        ext: num,
        type: 'external',
        users,
      });
    }

    pipesQuery.refetch();

    toggleBackdrop();
  };

  const handleNewMissing = async ({ name, ext }: any) => {
    let users = {};
    if (user) {
      // @ts-ignore
      users[user.id] = { enabled: true };
    }

    const pipe = await pipeNew.mutateAsync({
      name: name,
      emoji: '1f64c', // raised hands
      ext: ext.toString(),
      type: 'external',
      users,
    });
    pipesQuery.refetch();
    phoneNumbersRefetch();
  };

  const [openBuyNumber, toggleBuyNumber] = useToggleReducer();

  return (
    <>
      <BuyPhoneNumberDialog open={openBuyNumber} onClose={toggleBuyNumber} />
      <AddSharedLineDialog
        onCancel={toggleNewPipeDialog}
        onComplete={pipeId => {
          pipesQuery.refetch();
          history.push(`/v2/settings/pipes/${pipeId}`);
        }}
        user={user}
        open={openNewPipeDialog}
      />
      <SettingsPageWrapper
        isLoading={pipesQuery.isLoading || phoneNumbersIsLoading}
        loadingLabel={'Loading lines...'}
        label={'Lines'}
        subLabel={'Manage lines and extensions'}
        buttonProps={{
          children: 'Add phone number',
          onClick: toggleBuyNumber,
          startIcon: <PlusIcon fr={undefined} />,
        }}
      >
        <SettingsPanel
          leftChild={
            <Setting
              label={'Shared extensions'}
              body={'Internal extension numbers for team use.'}
              iconButtonProps={{
                onClick: toggleNewPipeDialog,
                variant: 'fill',
                color: 'accent',
                pill: true,
                children: <PlusIcon fr={undefined} />,
              }}
            />
          }
          rightChild={
            <PipeList
              type={'shared extensions'}
              pipes={pipes?.filter(pipe => pipe.type === 'shared')}
              users={users}
              pipesQuery={pipesQuery}
              usersQuery={usersQuery}
              helpText={
                <>
                  <span className="font-bold">External</span> lines have a phone
                  number, and multiple team members can have access.
                </>
              }
            />
          }
        />
        <SettingsPanel
          leftChild={
            <Setting
              label={'Private extensions'}
              body={'Personal extension number per teammate.'}
              // buttonProps={{
              //   onClick: toggleNewPipeDialog,
              //   children: <>add</>,
              // }}
            />
          }
          rightChild={
            <PipeList
              type={'private extensions'}
              pipes={pipes?.filter(pipe => pipe.type === 'personal')}
              users={users}
              pipesQuery={pipesQuery}
              usersQuery={usersQuery}
              helpText={
                <>
                  <span className="font-bold">External</span> lines have a phone
                  number, and multiple team members can have access.
                </>
              }
            />
          }
        />
        <SettingsPanel
          leftChild={
            <Setting
              label={'Lines'}
              body={'Public phone numbers for team use.'}
            />
          }
          rightChild={
            <>
              <Alert
                label={`External Lines (Phone Numbers) Coming Soon!`}
                color="accent"
                reverse
                icon={<SineWaveIcon fr={undefined} />}
                body={
                  <>
                    We're working as fast as possible with our partner
                    TrunkingIO to bring you a low-cost, fantastic approach to
                    buying and managing phone numbers, minutes, and SMS/MMS.
                  </>
                }
                className="mb-4"
              />

              <PipeList
                type={'lines'}
                pipes={pipes?.filter(pipe => pipe.type === 'external')}
                users={users}
                pipesQuery={pipesQuery}
                usersQuery={usersQuery}
                helpText={
                  <>
                    <span className="font-bold">External</span> lines have a
                    phone number, and multiple team members can have access.
                  </>
                }
              />

              {devMode &&
              !pipesQuery.isLoading &&
              !phoneNumbersIsLoading &&
              !pipeNew.isLoading ? (
                <div>
                  {phoneNumbers
                    ?.filter(ptn => !pipes.find(p => p.key === ptn.id))
                    .map(ptn => (
                      <div
                        key={ptn.id}
                        className="cursor-pointer underline p-1"
                        onClick={() =>
                          handleNewMissing({
                            ext: ptn.id,
                            name: 'Alt',
                          })
                        }
                      >
                        {ptn.id}
                      </div>
                    ))}
                </div>
              ) : null}
            </>
          }
        />
      </SettingsPageWrapper>
    </>
  );

  // return (
  //   <>
  //     <AddSharedLineDialog
  //       onCancel={toggleNewPipeDialog}
  //       onComplete={pipeId => {
  //         pipesQuery.refetch();
  //         history.push(`/v2/settings/pipes/${pipeId}`);
  //       }}
  //       user={user}
  //       open={openNewPipeDialog}
  //     />
  //     <CustomBackdrop
  //       {...CustomBackdropProps}
  //       // open={isFetching && !isLoading}
  //       label="Setting up lines, please wait..."
  //     />
  //     <div
  //       className={`max-w-[700px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //       data-tour-settings-pipes
  //     >
  //       <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
  //         <SimpleBar className={'h-full'} style={{ height: 'inherit' }}>
  //           <div className={'px-8 py-3 h-full'}>
  //             <div className={'flex items-center space-x-2'}>
  //               <div
  //                 className={
  //                   'btn-accent-outline text-2xl mt-[5px] pointer-events-none rounded-full p-2 flex items-center justify-center'
  //                 }
  //               >
  //                 <NumbersIcon
  //                   className={'fill-content-accent '}
  //                   sx={{ fontSize: 'inherit' }}
  //                 />
  //               </div>
  //               <div className="flex-auto">
  //                 <div className="font-bold text-xl">Lines</div>
  //                 {/* <div className="text-sm text-neutral-500">
  //                     Each user on your account has a Personal Line
  //                     (Extension/Presence ID), and you can have as many Shared
  //                     and External Lines as you'd like.
  //                   </div> */}
  //               </div>
  //               <div className="flex items-center w-min pb-1 w-full space-x-2">
  //                 <BuyNumbersButton onComplete={handlePurchase} />
  //                 <Button
  //                   startIcon={<AddIcon />}
  //                   color={'accent'}
  //                   variant={'outline'}
  //                   size={'sm'}
  //                   onClick={toggleNewPipeDialog}
  //                 >
  //                   {'Add a Shared Line'}
  //                 </Button>
  //               </div>
  //             </div>
  //             <div className="mt-4">
  //               <Tab.Group defaultIndex={0}>
  //                 <div className="flex-none border-b border-gray-20 flex items-end">
  //                   <Tab.List className="flex space-x-1 overflow-x-auto px-2">
  //                     <Tab
  //                       className={({ selected }) =>
  //                         [
  //                           'w-full px-2 text-sm font-medium leading-4 py-1 focus:outline-0 border-b-2',
  //                           selected
  //                             ? 'font-medium border-neutral-900'
  //                             : 'border-transparent text-neutral-500',
  //                         ].join(' ')
  //                       }
  //                     >
  //                       <div className="whitespace-nowrap">
  //                         <PhoneOutcomeIcon
  //                           fr={undefined}
  //                           height={16}
  //                           width={16}
  //                           className={['inline-block'].join(' ')}
  //                         />
  //                         &nbsp; External
  //                       </div>
  //                     </Tab>
  //                     <Tab
  //                       className={({ selected }) =>
  //                         [
  //                           'w-full px-2 text-sm font-medium leading-4 py-1 focus:outline-0 border-b-2',
  //                           selected
  //                             ? 'font-medium border-neutral-900'
  //                             : 'border-transparent text-neutral-500',
  //                         ].join(' ')
  //                       }
  //                     >
  //                       <div className="whitespace-nowrap">
  //                         <GroupIcon
  //                           fr={undefined}
  //                           height={16}
  //                           width={16}
  //                           className={['inline-block'].join(' ')}
  //                         />
  //                         &nbsp; Shared
  //                       </div>
  //                     </Tab>
  //                     <Tab
  //                       className={({ selected }) =>
  //                         [
  //                           'w-full px-2 text-sm font-medium leading-4 py-1 focus:outline-0 border-b-2',
  //                           selected
  //                             ? 'font-medium border-neutral-900'
  //                             : 'border-transparent text-neutral-500',
  //                         ].join(' ')
  //                       }
  //                     >
  //                       <div className="whitespace-nowrap">
  //                         <UserIcon
  //                           fr={undefined}
  //                           height={16}
  //                           width={16}
  //                           className={['inline-block'].join(' ')}
  //                         />
  //                         &nbsp; Personal
  //                       </div>
  //                     </Tab>
  //                   </Tab.List>
  //                 </div>
  //                 <div className="flex-auto overflow-hidden">
  //                   <Tab.Panels className="h-full">
  //                     <Tab.Panel className={''} unmount={false}>
  //                       <PipeList
  //                         pipes={pipes?.filter(
  //                           pipe => pipe.type === 'external',
  //                         )}
  //                         users={users}
  //                         pipesQuery={pipesQuery}
  //                         usersQuery={usersQuery}
  //                         helpText={
  //                           <>
  //                             <span className="font-bold">External</span> lines
  //                             have a phone number, and multiple team members can
  //                             have access.
  //                           </>
  //                         }
  //                       />
  //                       {!pipesQuery.isLoading &&
  //                       !phoneNumbersIsLoading &&
  //                       phoneNumbers?.length !=
  //                         pipes?.filter(pipe => pipe.type === 'external')
  //                           .length ? (
  //                         <div>
  //                           {phoneNumbers?.map(ptn => (
  //                             <div
  //                               key={ptn.id}
  //                               className="cursor-pointer"
  //                               onClick={() =>
  //                                 handleNewMissing({
  //                                   ext: ptn.id,
  //                                   name: 'Alt',
  //                                 })
  //                               }
  //                             >
  //                               {ptn.id}
  //                             </div>
  //                           ))}
  //                         </div>
  //                       ) : null}
  //                     </Tab.Panel>
  //                     <Tab.Panel className={''} unmount={false}>
  //                       <PipeList
  //                         pipes={pipes?.filter(pipe => pipe.type === 'shared')}
  //                         users={users}
  //                         pipesQuery={pipesQuery}
  //                         usersQuery={usersQuery}
  //                         helpText={
  //                           <>
  //                             <span className="font-bold">Shared</span> lines
  //                             have an internal extension number, and multiple
  //                             team members can have access.
  //                           </>
  //                         }
  //                       />
  //                     </Tab.Panel>
  //                     <Tab.Panel className={''} unmount={false}>
  //                       <PipeListPersonal
  //                         pipes={pipes?.filter(
  //                           pipe => pipe.type === 'personal',
  //                         )}
  //                         users={users}
  //                         pipesQuery={pipesQuery}
  //                         usersQuery={usersQuery}
  //                       />
  //                     </Tab.Panel>
  //                   </Tab.Panels>
  //                 </div>
  //               </Tab.Group>
  //             </div>
  //           </div>
  //         </SimpleBar>
  //       </div>
  //     </div>
  //   </>
  // );
};

const PipeList = ({ pipesQuery, usersQuery, pipes, users, helpText, type }) => {
  const [showDeleteDialogPipe, setShowDeleteDialogPipe] =
    useImmer<boolean>(false);
  const [deleteDialogPipe, setDeleteDialogPipe] = useImmer<null | any>(null);
  const { isOpen, currentStep, steps, setSteps, setIsOpen, setCurrentStep } =
    useTour();

  const { devMode } = useDevMode();

  const router = useHistory();
  const isSmallMobile = useMediaQuery('(max-width:500px)', { noSsr: true });

  if (
    pipesQuery.isLoading ||
    (type === 'private extensions' && usersQuery.isLoading)
  ) {
    return <SettingsPanelLoader label={`Loading ${type}...`} />;
  }

  if (!pipes.length) {
    return (
      <Alert
        label={`No ${type}`}
        icon={<InfoIcon fr={undefined} />}
        body={`No ${type} could be found associated with your account.`}
        reverse
      />
    );

    // return (
    //   <div className="h-[120px] grid place-items-center">
    //     <div className="text-center text-neutral-400">
    //       <FileNotFoundIcon
    //         fr={undefined}
    //         height={20}
    //         width={20}
    //         className={['inline-block'].join(' ')}
    //       />
    //       <div className="text-md mt-1">None created yet</div>
    //     </div>
    //   </div>
    // );
  }

  return (
    <>
      <DeletePipeDialog
        open={showDeleteDialogPipe}
        onClose={() => setShowDeleteDialogPipe(false)}
        pipe={deleteDialogPipe}
      />
      <Table>
        {pipes
          ?.sort((a, b) => {
            // @ts-ignore
            return a.key > b.key ? 1 : -1;
          })
          .map((pipe, index) => {
            const userIds = Object.keys(pipe.users).filter(
              userId => pipe.users[userId].enabled,
            );

            const pipeUsers = users.filter(user => userIds.includes(user.id));
            const userCount = pipeUsers.length;

            // console.log('pipe Users', pipeUsers, userIds);

            return (
              <TableRow
                key={pipe.id}
                {...(pipe.type === 'personal'
                  ? { 'data-tour-settings-pipes-personal': true }
                  : pipe.type === 'external'
                  ? { 'data-tour-settings-pipes-external': true }
                  : {})}
                showBorder={pipes.length > 1 && index < pipes.length - 1}
                // className="flex justify-between items-center border-solid border-0 border-b pb-4 border-border-neutral/50"
              >
                <TableCell flex>
                  {type === 'private extensions' ? (
                    <>
                      {pipeUsers.map(user => (
                        <Link
                          to={`/v2/settings/users#${user.id}`}
                          className={'text-md font-medium'}
                          onClick={() => {
                            if (isOpen) {
                              setCurrentStep(v => v + 1);
                            }
                          }}
                        >
                          <div className={'flex space-x-2 items-center'}>
                            <UserAvatar key={user.id} user={user} size={'sm'} />
                            <span className={'text-md font-medium'}>
                              {user.extra.fullName}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </>
                  ) : (
                    <div className={'flex w-full items-center space-x-2'}>
                      <Emoji
                        unified={pipe.metadata?.emoji || '1f937'}
                        size={16}
                      />
                      <Link
                        to={`/v2/settings/pipes/${pipe.id}`}
                        className={'text-md font-medium'}
                        onClick={() => {
                          if (isOpen) {
                            setCurrentStep(v => v + 1);
                          }
                        }}
                      >
                        {pipe.metadata.name?.length
                          ? pipe.metadata.name
                          : 'Unnamed'}
                      </Link>
                    </div>
                  )}
                </TableCell>
                <TableCell flex>
                  <Link
                    to={`/v2/settings/pipes/${pipe.id}`}
                    className={'text-md hover:underline text-left w-full'}
                  >
                    <PhoneNumberDisplay ptn={pipe.key} />
                  </Link>
                </TableCell>
                {/* <TableCell className={'w-1/4'}>
                  {pipe.type === 'personal' ? (
                    <div className="inline-block uppercase bg-background-positive-secondary text-content-positive text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-emerald-200 dark:text-emerald-800">
                      {pipe.type}
                    </div>
                  ) : null}
                  {pipe.type === 'shared' ? (
                    <div className="inline-block uppercase bg-background-attention-secondary text-content-attention text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-emerald-200 dark:text-emerald-800">
                      {pipe.type}
                    </div>
                  ) : null}
                  {pipe.type === 'external' ? (
                    <div className="inline-block uppercase bg-background-accent-secondary text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-emerald-800">
                      {pipe.type}
                    </div>
                  ) : null}
                </TableCell> */}
                {type === 'private extensions' ? null : (
                  <TableCell className={isSmallMobile ? 'hidden' : ''} flex>
                    {usersQuery.isLoading ? (
                      <span>loading...</span>
                    ) : (
                      <Tooltip
                        arrow
                        title={
                          <div
                            className={'flex items-start flex-col space-y-4'}
                          >
                            {pipeUsers.map(user => (
                              <div
                                key={user.id}
                                className="flex items-center justify-center space-x-2"
                              >
                                <div>
                                  <UserAvatar user={user} size={'sm'} />
                                </div>
                                <div className="">
                                  <div className="font-semibold">
                                    {user.extra.fullName}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        }
                      >
                        <div className="flex items-center justify-left w-min space-x-2">
                          <AvatarGroup>
                            {pipeUsers.map(user => (
                              <UserAvatar
                                key={user.id}
                                user={user}
                                size={'sm'}
                              />
                            ))}
                          </AvatarGroup>
                        </div>
                      </Tooltip>
                    )}
                    {/*{userCount} {`User${userCount === 1 ? '' : 's'}`}*/}
                  </TableCell>
                )}
                <TableCell>
                  <IconButtonDropdown
                    hideArrow
                    align={'right'}
                    variant={'ghost'}
                    menuItems={[
                      type === 'private extensions'
                        ? {
                            label: 'View Team Member',
                            onClick: () => {
                              router.push(
                                `/v2/settings/users#${pipeUsers?.[0]?.id}`,
                              );
                            },
                          }
                        : null,
                      {
                        label: 'Edit',
                        onClick: () => {
                          router.push(`/v2/settings/pipes/${pipe.id}`);
                        },
                      },
                      type !== 'private extensions' || devMode
                        ? {
                            label: 'Delete',
                            onClick: () => {
                              setShowDeleteDialogPipe(true);
                              setDeleteDialogPipe(pipe);
                            },
                          }
                        : null,
                      type === 'shared extensions' ? null : null,
                    ]}
                  >
                    <MoreHoriz fr={undefined} />
                  </IconButtonDropdown>
                </TableCell>
              </TableRow>
            );
          })}
      </Table>
    </>
  );

  // return (
  //   <div className="">
  //     <div className="p-4 m-2 text-neutral-500 rounded bg-neutral-100 text-md">
  //       {helpText}
  //     </div>
  //     <table className="w-full border-separate border-spacing-y-3">
  //       <tbody className="space-y-4">
  //         {pipes
  //           ?.sort((a, b) => {
  //             // @ts-ignore
  //             return a.key > b.key ? 1 : -1;
  //           })
  //           .map(pipe => {
  //             const userIds = Object.keys(pipe.users).filter(
  //               userId => pipe.users[userId].enabled,
  //             );
  //
  //             const pipeUsers = users.filter(user => userIds.includes(user.id));
  //             const userCount = pipeUsers.length;
  //
  //             // console.log('pipe Users', pipeUsers, userIds);
  //
  //             return (
  //               <tr
  //                 key={pipe.id}
  //                 {...(pipe.type === 'personal'
  //                   ? { 'data-tour-settings-pipes-personal': true }
  //                   : pipe.type === 'external'
  //                   ? { 'data-tour-settings-pipes-external': true }
  //                   : {})}
  //                 className="flex justify-between items-center border-solid border-0 border-b pb-4 border-border-neutral/50"
  //               >
  //                 <td className="w-[64px] text-center [&_img]:inline-block">
  //                   <Emoji
  //                     unified={pipe.metadata?.emoji || '1f937'}
  //                     size={25}
  //                   />
  //                 </td>
  //                 <td className="w-auto mr-auto">
  //                   <div className="text-base">
  //                     <Link
  //                       to={`/v2/settings/pipes/${pipe.id}`}
  //                       className="underline text-blue-500 hover:text-blue-400"
  //                       onClick={() => {
  //                         if (isOpen) {
  //                           setCurrentStep(v => v + 1);
  //                         }
  //                       }}
  //                     >
  //                       {pipe.metadata.name?.length
  //                         ? pipe.metadata.name
  //                         : 'Unnamed'}
  //                     </Link>
  //                     <div>
  //                       <PhoneNumberDisplay ptn={pipe.key} />
  //                     </div>
  //                   </div>
  //                 </td>
  //                 {/* <td className={'w-1/4'}>
  //                 {pipe.type === 'personal' ? (
  //                   <div className="inline-block uppercase bg-background-positive-secondary text-content-positive text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-emerald-200 dark:text-emerald-800">
  //                     {pipe.type}
  //                   </div>
  //                 ) : null}
  //                 {pipe.type === 'shared' ? (
  //                   <div className="inline-block uppercase bg-background-attention-secondary text-content-attention text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-emerald-200 dark:text-emerald-800">
  //                     {pipe.type}
  //                   </div>
  //                 ) : null}
  //                 {pipe.type === 'external' ? (
  //                   <div className="inline-block uppercase bg-background-accent-secondary text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-emerald-800">
  //                     {pipe.type}
  //                   </div>
  //                 ) : null}
  //               </td> */}
  //                 <td className={'w-1/4'}>
  //                   {usersQuery.isLoading ? (
  //                     <span>loading...</span>
  //                   ) : (
  //                     <Tooltip
  //                       arrow
  //                       title={
  //                         <div
  //                           className={'flex items-start flex-col space-y-4'}
  //                         >
  //                           {pipeUsers.map(user => (
  //                             <div
  //                               key={user.id}
  //                               className="flex items-center justify-center space-x-2"
  //                             >
  //                               <div>
  //                                 <UserAvatar user={user} size={'md'} />
  //                               </div>
  //                               <div className="">
  //                                 <div className="font-semibold">
  //                                   {user.extra.fullName}
  //                                 </div>
  //                               </div>
  //                             </div>
  //                           ))}
  //                         </div>
  //                       }
  //                     >
  //                       <div className="flex items-center justify-center space-x-2">
  //                         <AvatarGroup max={3}>
  //                           {pipeUsers.map(user => (
  //                             <UserAvatar
  //                               key={user.id}
  //                               user={user}
  //                               size={'md'}
  //                             />
  //                           ))}
  //                         </AvatarGroup>
  //                       </div>
  //                     </Tooltip>
  //                   )}
  //                   {/*{userCount} {`User${userCount === 1 ? '' : 's'}`}*/}
  //                 </td>
  //               </tr>
  //             );
  //           })}
  //       </tbody>
  //     </table>
  //   </div>
  // );
};

const PipeListPersonal = ({ pipesQuery, usersQuery, pipes, users }) => {
  const { isOpen, currentStep, steps, setSteps, setIsOpen, setCurrentStep } =
    useTour();

  if (pipesQuery.isLoading) {
    return (
      <div className="h-[120px] grid place-items-center">
        <div className="text-center text-neutral-400">
          <div className="text-md mt-1">Loading lines</div>
        </div>
      </div>
    );
  }

  if (!pipes.length) {
    return (
      <div className="h-[120px] grid place-items-center">
        <div className="text-center text-neutral-400">
          <FileNotFoundIcon
            fr={undefined}
            height={20}
            width={20}
            className={['inline-block'].join(' ')}
          />
          <div className="text-md mt-1">None created yet</div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="p-4 m-2 text-neutral-500 rounded bg-neutral-100 text-md">
        Each team member has a <span className="font-bold">Personal</span> line,
        with an internal extension number.
      </div>
      <table className="w-full border-separate border-spacing-y-3">
        <tbody className="space-y-4">
          {pipes
            ?.sort((a, b) => {
              // @ts-ignore
              return a.key > b.key ? 1 : -1;
            })
            .map(pipe => {
              const userIds = Object.keys(pipe.users).filter(
                userId => pipe.users[userId].enabled,
              );

              const pipeUsers = users.filter(user => userIds.includes(user.id));
              const userCount = pipeUsers.length;

              // console.log('pipe Users', pipeUsers, userIds);

              return (
                <tr
                  key={pipe.id}
                  {...(pipe.type === 'personal'
                    ? { 'data-tour-settings-pipes-personal': true }
                    : pipe.type === 'external'
                    ? { 'data-tour-settings-pipes-external': true }
                    : {})}
                  className="flex justify-between items-center border-solid border-0 border-b pb-4 border-border-neutral/50"
                >
                  <td className="w-1/8 px-6">
                    <div className="text-base">
                      <Link
                        to={`/v2/settings/pipes/${pipe.id}`}
                        className="underline text-blue-500 hover:text-blue-400"
                        onClick={() => {
                          if (isOpen) {
                            setCurrentStep(v => v + 1);
                          }
                        }}
                      >
                        <PhoneNumberDisplay ptn={pipe.key} />
                      </Link>
                    </div>
                  </td>
                  <td className="w-full text-left">
                    {usersQuery.isLoading ? (
                      <span>loading...</span>
                    ) : (
                      <>
                        {pipeUsers.map(user => (
                          <div
                            key={user.id}
                            className="flex items-center space-x-2"
                          >
                            <div>
                              <UserAvatar user={user} size={'md'} />
                            </div>
                            <div className="">
                              <div className="font-semibold">
                                {user?.extra?.fullName ?? 'Unknown User'}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export const usePipeNew = () => {
  const queryClient = useQueryClient();
  const pipeCreate = usePipeCreate();
  const callflowCreate = useCreateCallflow();
  const createMedia = useCreateMedia();
  const vmboxCreate = useCreateVmbox();

  return useMutation(async (info: any) => {
    const ext = info.ext;

    /*
    info: {
      ext,
      name,
      emoji,
      type, // personal, shared, external
      users
    }
    */

    // create:
    // - pipe
    // - call route
    // - vmbox

    // pipe
    const pipe = await pipeCreate.mutateAsync({
      key: ext,
      metadata: {
        name: info.name,
        emoji: info.emoji || '1f937',
      },
      type: info.type,
      users: info.users || {},
    });

    // create things for non-rooms
    if (info.type !== 'room') {
      // create Greeting for voicemail
      // - TODO: use even nicer?
      // TODO: reenable once TTS issue is debugged, using platform default for now
      // const mediaResp = await createMedia.mutateAsync({
      //   name: `VM Greeting: ${ext}`,
      //   // @ts-ignore
      //   media_source: 'tts',
      //   // @ts-ignore
      //   tts: {
      //     text: 'Sorry, we are unavailable. Please leave a message and we will return your call as soon as we are able.',
      //     // @ts-ignore
      //     voice: 'female/en-US',
      //   },
      //   language: 'en-us',
      // });
      //
      // // console.log('mediaResp:', mediaResp);
      // const media = mediaResp.data;

      // vmbox
      const vmboxResp = await vmboxCreate.mutateAsync({
        pin: undefined,
        require_pin: false,
        announcement_only: false,
        include_message_on_notify: true,
        include_transcription_on_notify: true,
        is_setup: true,
        is_voicemail_ff_rw_enabled: false,
        check_if_owner: true,
        delete_after_notify: false,
        save_after_notify: false,
        skip_envelope: false,
        skip_greeting: false,
        skip_instructions: false,
        transcribe: false,
        transcribe2: true,
        notify_email_addresses: [],
        oldest_message_first: false,
        // ...vmboxForm,
        // media: {
        //   unavailable: media.id,
        // },
        mailbox: ext,
        name: `VM: ${ext}`,
        // @ts-ignore
        pipe_id: pipe.id,
      });

      // console.log('Vmbox Resp:', vmboxResp);

      const vmbox = vmboxResp.data;

      // call route
      // -
      const callflow = await callflowCreate.mutateAsync({
        type: 'main',
        owner_type: 'pipe',
        owner_id: pipe.id,
        numbers: [ext],
        strategy: {
          default: true, // has NOT been changed by the user at all
          type: 'blank',
          data: {
            modules: [
              {
                data: {
                  data: {
                    id: pipe.id,
                  },
                  ring_type: 'line',
                  variables: {},
                },
                type: 'Ring',
                options: {
                  props: {
                    required: true,
                    defaultRingType: 'line',
                  },
                },
              },
              {
                type: 'VoicemailBox',
                data: {
                  id: vmbox.id,
                },
              },
            ],
          },
        },
        message_strategy:
          info.type === 'external'
            ? {
                default: true, // has NOT been changed by the user at all
                type: 'blank',
                data: {
                  modules: [
                    {
                      type: 'GenericMatch',
                      data: {
                        match: [
                          {
                            data: {
                              timeframe: [['startOfYesterday']],
                            },
                            type: 'time_since_communication',
                          },
                        ],
                        targets: {
                          no_match: { callflow: null },
                          match: {
                            callflow: {
                              id: 'inline',
                              message_strategy: {
                                type: 'blank',
                                data: {
                                  modules: [
                                    {
                                      type: 'Message',
                                      data: {
                                        text: `Thanks for contacting us! We'll review your message as soon as possible. 
                        
This is an automated message.`,
                                      },
                                    },
                                  ],
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  ],
                },
              }
            : undefined,
      });
    }

    // console.log('pipeUpdateById mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    return pipe;
  });
};

export default Pipes;

import React, { ReactElement, ReactNode, useState } from 'react';
import { Tooltip as HeadlessTooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export interface TooltipProps {
  content: ReactNode;
  children: ReactElement;
}

const Tooltip = ({ content, children }: TooltipProps) => {
  const [id] = useState(() => `tooltip-${uuidv4()}`);
  return (
    <>
      {React.cloneElement(children, { id })}
      <HeadlessTooltip
        className={
          'cursor-default w-min text-content font-sans font-medium bg-white opacity-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border-solid border border-border-neutral'
        }
        anchorId={id}
        clickable
      >
        {content}
      </HeadlessTooltip>
    </>
  );
};
export default Tooltip;

import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import {
  AnimationDialog,
  Button,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { Dialog } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useDeviceQuery } from 'app/hooks/queries/device';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface DeviceDetailsFields {
  name: string;
}

// interface declaring which props are required/allowed
interface ContentProps {
  deviceId: string;
  onCancel: () => void;
  onComplete: () => void;
}

interface DeviceDetailsDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

const DetailsDialogDevice = ({
  DialogProps,
  ContentProps,
}: DeviceDetailsDialogProps) => {
  return (
    <Dialog size={'lg'} open={DialogProps.open} onClose={ContentProps.onCancel}>
      <DetailsDialogDeviceContent {...ContentProps} />
    </Dialog>
  );
};

const DetailsDialogDeviceContent = ({
  deviceId,
  onCancel,
  onComplete,
}: ContentProps) => {
  const { data: device, isLoading: deviceIsLoading } = useDeviceQuery(deviceId);
  const formMethods = useForm<DeviceDetailsFields>({
    defaultValues: {
      name: device?.doc.name,
    },
  });
  const { setError, handleSubmit } = formMethods;

  const updateDevice = useUpdateDevicePartial();

  const onSubmit = async (deviceForm: DeviceDetailsFields) => {
    try {
      const resp = await updateDevice.mutateAsync({
        id: deviceId,
        ...deviceForm,
      });
    } catch (e: any) {
      parseAndSetKazooMutationErrors({ response: e.response, setError });
    }
  };

  return (
    <GenericMutationDialogContent
      onComplete={onComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'Device name has been updated'}
      isLoadingLabel={'Updating device name...'}
      title={'Edit Device Name'}
      mutation={updateDevice}
      formMethods={formMethods}
      queryIsLoading={deviceIsLoading}
      queryLabel={'Loading device name'}
    >
      <HookFormTextField name={'name'} label={'name'} />
    </GenericMutationDialogContent>
  );

  // return (
  //   <Dialog open={true} fullWidth maxWidth={'xs'}>
  //     {deviceIsLoading ? (
  //       <DialogInProgress title={'Loading device details info...'} />
  //     ) : updateDevice.isLoading ? (
  //       <DialogInProgress title={'Updating Device details...'} />
  //     ) : (
  //       <>
  //         <DialogTitle>Edit Device Details</DialogTitle>
  //         <Divider />
  //         {device ? (
  //           <DeviceDetailsForm
  //             defaultFormValues={formDefaults}
  //             onCancel={onCancel}
  //             onSubmit={handleSubmit}
  //             errorMessage={
  //               updateDevice.error
  //                 ? `${updateDevice.error.message} ${
  //                     updateDevice.error.response
  //                       ? `: ${updateDevice.error.response?.statusText}`
  //                       : ''
  //                   }`
  //                 : undefined
  //             }
  //           />
  //         ) : (
  //           <>
  //             <DialogContent>
  //               <Typography color={'error'}>Invalid Device ID.</Typography>
  //             </DialogContent>
  //             <Divider />
  //             <DialogActions sx={{ justifyContent: 'left' }}>
  //               <Button variant={'outlined'} color={'error'} onClick={onCancel}>
  //                 Cancel
  //               </Button>
  //             </DialogActions>
  //           </>
  //         )}
  //       </>
  //     )}
  //   </Dialog>
  // );
};

export default DetailsDialogDevice;

import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  TextField,
} from 'app/design-lib';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import * as React from 'react';

interface ContentProps {
  onCancel: () => void;
  initialName?: string;
  onComplete: (name: string) => void;
}

interface EditFallbackNameDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

export const EditFallbackNameDialog = ({
  ContentProps,
  DialogProps,
}: EditFallbackNameDialogProps) => {
  return (
    <Dialog open={DialogProps.open} onClose={ContentProps.onCancel}>
      <EditFallbackNameDialogContent {...ContentProps} />
    </Dialog>
  );
};

const EditFallbackNameDialogContent = ({
  onCancel,
  onComplete,
  initialName,
}: ContentProps) => {
  const formMethods = useForm({
    defaultValues: {
      name: initialName,
    },
    resolver: joiResolver(
      Joi.object({
        name: Joi.string().allow('').max(128).optional(),
      }),
    ), // pass in custom validation
  });

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
    setError,
    watch,
  } = formMethods;

  const onSubmit = async ({ name }: any) => {
    onComplete(name);
  };

  return (
    <>
      <DialogHeader title={`Edit Fallback Option Name`} onClose={onCancel} />
      <DialogContent>
        <TextField
          {...register('name')}
          autoComplete={'off'}
          // fullWidth
          autoFocus
          name={'name'}
          label={'Name'}
          hasError={!!formErrors['name']}
          helperText={formErrors['name']?.message}
        />
      </DialogContent>
      <DialogActions className={`justify-between`}>
        <Button
          size={'md'}
          onClick={onCancel}
          color={'negative'}
          variant={'fill'}
        >
          Cancel
        </Button>
        <Button
          size={'md'}
          onClick={handleSubmit(onSubmit)}
          color={'accent'}
          variant={'fill'}
        >
          Update Name
        </Button>
      </DialogActions>
    </>
  );
};

import { Link } from 'app/design';

import { getAtPath, setAtPath } from 'app/utilities';
import React, { memo, useEffect, useRef } from 'react';
import { Handle } from 'react-flow-renderer';

// import useStyles from './styles';
import { useHoverDirty } from 'react-use';

const FixCreateBlankNode = memo(({ data }) => {
  const { callflow, setCallflow, modifyPath, fixData, fixBySpliceIdx } = data;

  // console.log('FixCreateBlankNode', data);
  const optionRef = useRef(null);
  const isHovering = useHoverDirty(optionRef);

  // const classes = useStyles({ mode: isHovering ? 'normal' : 'ignore' });

  const handleFix = () => {
    const blankCallflow = {
      id: 'inline',
      flow: {},
      numbers: [],
      message_strategy: {
        type: 'blank',
        data: {
          modules: [],
        },
      },
    };
    if (fixBySpliceIdx > -1) {
      let newArr = getAtPath(callflow, modifyPath, []);
      newArr.splice(fixBySpliceIdx, 0, fixData || blankCallflow);
      setAtPath(callflow, modifyPath, newArr);
      setCallflow({ ...callflow }, { name: 'Fix Missing' }, true);
      return;
    }
    setAtPath(callflow, modifyPath, fixData || blankCallflow);
    setCallflow({ ...callflow }, { name: 'Fix Missing' }, true);
  };

  useEffect(() => {
    handleFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={optionRef}
      style={{
        width: 150,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: 60,
          height: 30,
          borderRadius: 3,
          border: '1px solid #ddd',
          background: '#f7f7f7',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ top: 4, background: '#555', visibility: 'hidden' }}
        />
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <Link onClick={handleFix}>Fix</Link>
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    </div>
  );
});

export default FixCreateBlankNode;

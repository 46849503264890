import Icon from '@mui/material/Icon';
import { IvrQuickDisplay } from 'app/components/IvrBuilder';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from 'app/design';
import { DialogBuilder, useToggleReducer } from 'app/utilities';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import Lottie from 'react-lottie-player';
import { useHoverDirty } from 'react-use';

import { default as TEMPLATES, TEMPLATES_BY_ID } from './templates';

const CATEGORIES = [
  {
    icon: 'star',
    name: 'Most Popular',
    items: [
      {
        icons: ['arrow_circle_right'],
        title: 'Transfer to Extension',
        template: TEMPLATES_BY_ID.account_transfer_to_ext,
      },
      {
        icons: ['person', 'voicemail'],
        title: 'Ring a User, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_ring_user_go_to_vm,
      },
      {
        icons: ['groups', 'voicemail'],
        title: 'Ring a Ring Group, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_ring_group_go_to_vm,
      },
      {
        icons: ['audiotrack'],
        title: 'Play Audio, then Hangup',
        template: TEMPLATES_BY_ID.account_audio,
      },
      {
        icons: ['format_list_numbered'],
        title: 'Menu',
        template: TEMPLATES_BY_ID.account_menu_blank,
      },
    ],
  },
  {
    name: 'Simple',
    items: [
      {
        icons: ['arrow_circle_right'],
        title: 'Transfer to Extension',
        template: TEMPLATES_BY_ID.account_transfer_to_ext,
      },
      {
        icons: ['person'],
        title: 'Ring a User, Hangup if no answer',
        template: TEMPLATES_BY_ID.account_ring_user,
      },
      {
        icons: ['groups'],
        title: 'Ring a Ring Group, Hangup if no answer',
        template: TEMPLATES_BY_ID.account_ring_group,
      },
      {
        icons: ['device_unknown'],
        title: 'Ring a Device, Hangup if no answer',
        template: TEMPLATES_BY_ID.account_ring_device,
      },
      {
        icons: ['audiotrack'],
        title: 'Play Audio, then Hangup',
        template: TEMPLATES_BY_ID.account_audio,
      },
      {
        icons: ['voicemail'],
        title: 'Straight to Voicemail',
        template: TEMPLATES_BY_ID.account_vm,
      },
    ],
  },
  {
    name: 'Commonly Used',
    items: [
      {
        icons: ['person', 'voicemail'],
        title: 'Ring a User, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_ring_user_go_to_vm,
      },
      {
        icons: ['groups', 'voicemail'],
        title: 'Ring a Ring Group, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_ring_group_go_to_vm,
      },
      {
        icons: ['device_unknown', 'voicemail'],
        title: 'Ring a Device, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_ring_device_go_to_vm,
      },
      {
        icons: ['audiotrack', 'voicemail'],
        title: 'Play Audio, then go to Voicemail',
        template: TEMPLATES_BY_ID.account_audio_go_to_vm,
      },
      {
        icons: ['audiotrack', 'arrow_circle_right'],
        title: 'Play Audio, then, Transfer to Extension',
        template: TEMPLATES_BY_ID.account_audio_transfer_to_ext,
      },
    ],
  },
  {
    name: 'Complex',
    items: [
      {
        icons: ['audiotrack', 'person', 'voicemail'],
        title: 'Play Audio, Ring a User, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_audio_ring_user_go_to_vm,
      },
      {
        icons: ['audiotrack', 'groups', 'voicemail'],
        title: 'Play Audio, Ring a Ring Group, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_audio_ring_group_go_to_vm,
      },
      {
        icons: ['audiotrack', 'device_unknown', 'voicemail'],
        title: 'Play Audio, Ring a Device, Go to Voicemail',
        template: TEMPLATES_BY_ID.account_audio_ring_device_go_to_vm,
      },
    ],
  },
];

// interface declaring which props are required/allowed
interface DialogProps {
  owner_type?: string;
  type?: string;
  parent_obj?: string;
  filterFn?: () => true;
  onCancel: () => void;
  onComplete: (args: any) => void;
}

const AccountChooseTemplateDialog = ({
  owner_type = 'account', // for limit/filter
  type = 'main', // for limit/filter
  parent_obj = 'root', // for limit/filter
  onCancel,
  onComplete,
}: DialogProps) => {
  const [editStructure, toggleEditStructure] = useToggleReducer(false);

  const formMethods = useForm({
    defaultValues: {},
    // resolver: joiResolver(schema), // pass in custom validation
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = formMethods;

  const handleCancel = () => {
    // if (isEditMode) {
    //   SetEditMode(false);
    // } else {
    onCancel();
    // }
  };

  const [previewCallflow, setPreviewCallflow] = useState(null);
  const [previewCallflowKey, setPreviewCallflowKey] = useState(0);
  useEffect(() => {
    setPreviewCallflowKey(v => {
      return v + 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(previewCallflow)]);

  const options = TEMPLATES.filter(template => {
    return (
      // @ts-ignore
      ((template.limit?.owner_type &&
        // @ts-ignore
        template.limit?.owner_type?.includes(owner_type)) ||
        // @ts-ignore
        (template.limit?.type && template.limit?.type?.includes(type))) &&
      // @ts-ignore
      (!template.limit?.skip_parent_obj ||
        // @ts-ignore
        (template.limit?.skip_parent_obj &&
          // @ts-ignore
          !template.limit?.skip_parent_obj?.includes(parent_obj)))
    );
  }).map(template => ({
    label: template.name,
    value: template.id,
    full: template,
  }));

  // const handlePreview = templateFull => () => {
  //   setPreviewCallflow(templateFull);
  // };

  const handleChoose = item => {
    if (!item.template) {
      return;
    }
    onComplete({
      value: item.template.id,
      full: { ...item.template, name: item.title },
    });
  };

  return (
    <Dialog open fullWidth maxWidth={'lg'}>
      {/* <DialogTitle>Choose Call Routing Template</DialogTitle> */}
      <DialogContent dividers>
        <Box>
          <Grid container columnSpacing={3}>
            <Grid item md={6}>
              {CATEGORIES.map(category => {
                return (
                  <Paper key={category.name} variant="outlined" sx={{ mb: 2 }}>
                    <MenuList>
                      <MenuItem>
                        {category.icon ? (
                          <ListItemIcon>
                            <Icon>{category.icon}</Icon>
                          </ListItemIcon>
                        ) : null}
                        <ListItemText>
                          <Typography variant="h6">{category.name}</Typography>
                        </ListItemText>
                      </MenuItem>
                      <Divider />
                      {category.items.map((item, i) => {
                        return (
                          <ShowMenuItem
                            key={i}
                            item={item}
                            onComplete={handleChoose}
                            previewCallflow={previewCallflow}
                            setPreviewCallflow={setPreviewCallflow}
                          />
                        );
                      })}
                    </MenuList>
                  </Paper>
                );
              })}

              {/* {options.map(opt => (
                // ON HOVER: highlight and show a Preview in the right sidebar!
                <ShowOption
                  key={opt.value}
                  option={opt}
                  // modifyPath={modifyPath}
                  onComplete={onComplete}
                  previewCallflow={previewCallflow}
                  setPreviewCallflow={setPreviewCallflow}
                />
              ))} */}
            </Grid>
            <Grid item md={6} sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  width: '500px',
                  height: '500px',
                }}
              >
                {previewCallflow ? (
                  <IvrQuickDisplay
                    // id={JSON.stringify(callflow)}
                    key={previewCallflowKey} // causes a rerender when changed!
                    callflow={previewCallflow}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ textAlign: 'center', mt: '240px' }}
                  >
                    Hover over an option on the left to see a preview here
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={handleCancel}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ShowMenuItem = ({
  item,
  onComplete,
  previewCallflow,
  setPreviewCallflow,
}) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const ref = useRef();
  // @ts-ignore
  const isHovering = useHoverDirty(ref);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (isHovering) {
      setPreviewCallflow(item.template);
    } else {
      setPreviewCallflow(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovering]);

  const preview = previewCallflow;
  const isInPreview = preview?.id === item.template?.id ? true : false;

  const handlePreview = templateFull => {
    setPreviewCallflow(templateFull);
  };
  const handleChoose = item => () => {
    onComplete(item);
  };

  // console.log('lottieData:', lottieData.goTo, lottieData);
  return (
    // @ts-ignore
    <MenuItem
      ref={ref}
      onClick={handleChoose(item)}
      // onMouseEnter={e => handlePreview(item.template)}
      // onMouseLeave={e => handlePreview(null)}
    >
      {item.icons.map((icon, i) => (
        <ListItemIcon key={i}>
          <Icon>{icon}</Icon>
        </ListItemIcon>
      ))}
      <ListItemText>{item.title}</ListItemText>
    </MenuItem>
  );
};

const ShowOption = ({
  option,
  onComplete,
  previewCallflow,
  setPreviewCallflow,
}) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [lottieData, setLottieData] = useState({
    currentFrame: 0, // NOT used by lottie, just for storing some data for the goTo
    // goTo: 0,
    loop: false,
    play: false,
    direction: 1,
    segments: false,
  });
  const preview = previewCallflow;
  const isInPreview = preview?.id === option.full.id ? true : false;

  const handlePreview = templateFull => {
    // console.log('handlePreview:', templateFull); // ? true : false);
    // setValue('preview_template', templateFull, {
    //   shouldDirty: true,
    // });
    setPreviewCallflow(templateFull);
  };
  const handleChoose = opt => {
    onComplete(opt);
    // setValue(`${modifyPath}callflow.strategy.template.parent_id`, opt.value, {
    //   shouldDirty: true,
    // });
    // setValue(
    //   `${modifyPath}callflow.strategy.template.parent_callflow`,
    //   opt.full,
    //   {
    //     shouldDirty: true,
    //   },
    // );
    // setValue(`${modifyPath}callflow.strategy.type`, opt.full.strategy.type, {
    //   shouldDirty: true,
    // });
    // setValue(
    //   `${modifyPath}callflow.strategy.simple.variables`,
    //   {},
    //   { shouldDirty: true },
    // );
    // setValue(`${modifyPath}callflow.strategy.data`, null, {
    //   shouldDirty: true,
    // });
    // handlePreview(null);
  };

  const handleLoopComplete = () => {
    console.log('loop complete');
  };
  const handleAnimationComplete = () => {
    console.log('animation complete');
    if (lottieData.direction > 0) {
      // start looping now?
      setLottieData(d => ({
        ...d,
        goTo: option.full.animationRepeatStart,
      }));
    } else {
      setLottieData(d => ({
        ...d,
        play: false,
      }));
    }
  };
  const handleFrame = frame => {
    // // console.log('frame:', frame);
    if (frame.currentTime > 0) {
      setLottieData(d => ({
        ...d,
        currentFrame: frame.currentTime,
        goTo: frame.currentTime,
      }));
    }
  };

  useEffect(() => {
    // console.log('isInPreview:', isInPreview);
    if (isInPreview) {
      setLottieData(d => ({
        ...d,
        play: true,
        direction: 1,
      }));
    } else {
      // rewind at 2x!
      // - only when already playing (ie, skip first run)
      if (lottieData.play) {
        // console.log('rewinding', lottieData.currentFrame);
        setLottieData(d => ({
          ...d,
          direction: -1,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInPreview]);

  // console.log('lottieData:', lottieData.goTo, lottieData);
  return (
    <Box
      sx={{
        mb: 2,
        cursor: 'pointer',
        '&:hover': {
          // boxShadow: theme => `0 1px 4px ${theme.palette.primary.dark}`,
        },
        // @ts-ignore
        border: theme => `1px solid ${theme.palette.primary.mainAlt}`,
        borderRadius: 2,
      }}
      onClick={e => {
        handleChoose(option);
      }}
      onMouseEnter={e => handlePreview(option.full)}
      onMouseLeave={e => handlePreview(null)}
    >
      {/* Image Here?? */}
      <Box
        sx={{
          height: '80px',
          // border: theme => `1px solid ${theme.palette.primary.mainAlt}`,
          borderRadius: 2,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          background: '#fff',
          // borderBottom: 0,
          borderBottom: '1px solid #eee',
        }}
      >
        {option.full.animationUrl ? (
          <Box
          // sx={{
          //   overflow: 'hidden',
          //   width: 300,
          //   height: 165,
          //   margin: '0 auto',
          // }}
          >
            {/* @ts-ignore */}
            <Lottie
              path={option.full.animationUrl}
              // background="transparent"
              // speed="1"
              style={{
                width: '100%',
                height: 80,
                // height: 300,
                // marginTop: -50,
                // marginLeft: -50,
              }}
              // hover
              // speed={lottieData.direction > 0 ? 1 : 2}
              {...lottieData}
              onLoopComplete={handleLoopComplete}
              onComplete={handleAnimationComplete}
              onEnterFrame={handleFrame}
            />
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          // border: theme => `1px solid ${theme.palette.primary.mainAlt}`,
          // borderRadius: 2,
          // borderTopLeftRadius: 0,
          // borderTopRightRadius: 0,
          // borderTop: 0,
          p: 1,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          {option.label}
        </Typography>
        {/* <Typography variant="body2" sx={{ fontWeight: 300 }}>
          This is a longer description of the template, describing things it
          does. It may be longer, or it may be shorter?
        </Typography> */}
      </Box>
    </Box>
  );
};

export const useAccountChooseTemplateDialog = DialogBuilder(
  AccountChooseTemplateDialog,
);

export default AccountChooseTemplateDialog;

import { Color, Size, Variant } from 'app/design-lib/types';
import React, { ReactElement, ReactNode } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
export interface CheckboxProps {
  checked: boolean | 'indeterminate';
  onClick?: () => void;
  size?: Size;
  label?: ReactNode | string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
}

/* TODO:
 *
 *  questions:
 *   states for checkbox? ie hover, clicked, etc.
 *    why fixed spacing after checkbox? what if wanting to use in smaller component?
 *    figma border radius different on first col than second
 * */

const Checkbox = ({
  checked = false,
  onClick,
  size = 'md',
  label,
  helperText,
  disabled,
  error,
}: CheckboxProps) => {
  let className = `relative flex items-center justify-center p-0 cursor-pointer rounded-sm aspect-square border-solid border-2`;
  let fontSize: string;
  switch (size) {
    case 'sm':
      className += ` w-4`;
      fontSize = '16';
      break;
    case 'lg':
      className += ` w-6`;
      fontSize = '24';
      break;
    case 'md':
    default:
      className += ` w-5`;
      fontSize = '20';
      break;
  }

  // indeterminate or checked
  if (!!checked) {
    className += ` bg-${
      disabled ? 'gray-30' : error ? 'background-negative' : 'background-accent'
    } ${
      disabled
        ? ' border-gray-30 pointer-events-none'
        : error
        ? ' border-background-negative'
        : ' border-background-accent'
    }`;
  } else {
    className += ` bg-transparent ${
      disabled
        ? 'border-border-neutral-disabled pointer-events-none'
        : error
        ? 'border-border-negative-secondary-hover'
        : 'border-border-neutral'
    }`;
  }

  return (
    <div>
      <div className={'flex space-x-2 items-center'}>
        <button type={'button'} onClick={onClick} className={className}>
          {checked === true ? (
            <CheckIcon className={'absolute text-white'} style={{ fontSize }} />
          ) : checked === 'indeterminate' ? (
            <RemoveIcon
              style={{ fontSize }}
              className={'absolute text-white'}
            />
          ) : null}
        </button>
        {label ? (
          <span
            onClick={onClick}
            className={`cursor-pointer text-${size} ${
              disabled
                ? 'text-content-neutral-disabled'
                : 'text-content-neutral-active'
            }`}
          >
            {label}
          </span>
        ) : null}
      </div>
      {helperText ? (
        <span
          className={`${
            disabled
              ? 'text-content-neutral-disabled'
              : error
              ? 'text-content-negative'
              : 'text-content-neutral'
          } text-sm whitespace-nowrap font-medium`}
        >
          {helperText}
        </span>
      ) : null}
    </div>
  );
};

export default Checkbox;

import { useMediaQuery } from '@mui/material';
import { useEffect, useRef, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

interface SettingsPanelProps {
  id?: string;
  leftChild: ReactElement;
  rightChild: ReactElement | null;
  rightClassName?: string;
}

export const SettingsPanel = ({
  id,
  leftChild,
  rightChild,
  rightClassName = '',
}: SettingsPanelProps) => {
  const location = useLocation();
  // const ref = useRef();
  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  const isLinkFocus = id?.length && location.hash === `#${id}` ? true : false; // id matches hash
  // useEffect(() => {
  //   if (isLinkFocus) {
  //     setTimeout(() => {
  //       // // @ts-ignore
  //       // console.log('focusing on ', id, ref?.current, ref?.current?.focus());
  //       // // @ts-ignore
  //       // ref?.current?.focus();
  //     }, 100);
  //   }
  // }, [isLinkFocus]);

  return (
    <div
      // ref={ref}
      id={id}
      className={[
        `relative ${isMobile ? 'space-y-4 flex-col' : 'space-x-4'} flex`,
        isLinkFocus ? 'checklist-highlight' : '',
      ].join(' ')}
    >
      {/* {isLinkFocus ?
    <div className={['absolute -z-1 bg-emerald-100/20 rounded-lg'].join(' ')}></div>:null } */}
      <div className={'flex-1'}>{leftChild}</div>
      <div className={`flex-[2] ${rightClassName}`}>{rightChild}</div>
    </div>
  );
};

export default SettingsPanel;

import {
  Business as BusinessIcon,
  Dialpad as DialpadIcon,
  Voicemail as VoicemailIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  PhoneIphone as PhoneIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import CreatedAtTableCell from 'app/components/CreatedAtTableCell/CreatedAtTableCell';
import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { CallRouteOwnerCell } from 'app/components/ListAllCallflows/components/CallRouteOwnerCell';
import { NameAndTypeCell } from 'app/components/ListAllCallflows/components/NameAndTypeCell';
import { MediaSourceCell } from 'app/components/ListMedia/components/MediaSourceCell';
import { TemplateListOptions } from 'app/components/ListTemplates/components/TemplateListOptions';
import { OwnerTableCell } from 'app/components/OwnerTableCell';
import PlayAudioTableCell from 'app/components/PlayTableCell/PlayAudioTableCell';
import { TextToSpeechTableCell } from 'app/components/TextToSpeechTableCell';
import { Box, Chip, Typography } from 'app/design';
import prepareStickyColumns from 'app/utilities/prepareStickyColumns';
import * as React from 'react';
import { useMemo } from 'react';
import { Media } from 'types/media';
import { UsedBy } from 'types/media/Media';
import { RouterLink } from '../RouterLink';
import { MediaListOptions } from './components/MediaListOptions';

export const useMediaColumns = (onComplete: () => void) =>
  useMemo(() => {
    const columns = [
      {
        Header: 'Type',
        id: 'type',
        accessor: (media: Media) => media.doc.media_source,
        Cell: ({ row }) => {
          const { original: media }: { original: Media } = row;

          return <MediaSourceCell media={media} />;
        },
        disableToggle: true,
        disableSortBy: true,
        headerProps: {
          width: 130,
          position: 'sticky',
        },
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: (media: Media) => media.doc.name,
        Cell: ({ row, value: name }) => {
          const { original: media }: { original: Media } = row;

          return name;
        },
        disableToggle: true,
        headerProps: {
          width: 220,
          position: 'sticky',
        },
      },
      {
        Header: 'TTS',
        id: 'tts',
        accessor: (media: Media) => media.doc.tts?.text,
        Cell: ({ row }) => {
          const { original: media }: { original: Media } = row;

          return <TextToSpeechTableCell media={media} />;
        },
        headerProps: {
          width: 260,
        },
        disableSortBy: true,
      },
      {
        Header: 'Play',
        id: 'play',
        accessor: (media: Media) => media.doc.tts?.text,
        Cell: ({ row }) => {
          const { original: media }: { original: Media } = row;

          return <PlayAudioTableCell media={media} />;
        },
        headerProps: {
          width: 260,
        },
        disableSortBy: true,
      },
      {
        Header: 'Usage',
        id: 'usage',
        Cell: ({ row }) => {
          const { original: media }: { original: Media } = row;
          return <UsedByColumn usedByArray={media?.usedBy} />;
        },
        headerProps: {
          width: 260,
        },
        disableSortBy: true,
      },
      {
        Header: 'Created',
        id: 'createdAt',
        accessor: (media: Media) => media.createdAt,
        Cell: ({ value: createdAt }: { value: Date }) => (
          <CreatedAtTableCell createdAt={createdAt} />
        ),
        headerProps: {
          width: 135,
        },
      },
      {
        id: 'options',
        Cell: cellProps => {
          const { row, toggleRowSelected } = cellProps;

          const onDelete = () => {
            toggleRowSelected(row.original.id, false);
          };

          return (
            <MediaListOptions
              toggleRowSelected={toggleRowSelected}
              onDelete={onDelete}
              media={row.original}
              onComplete={onComplete}
            />
          );
        },
        disableSortBy: true,
        disableToggle: true,
        headerProps: {
          width: 70,
        },
      },
    ];

    prepareStickyColumns(columns);

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

export const UsedByColumn = ({ usedByArray }: { usedByArray: UsedBy[] }) => {
  console.log('usedByarry', usedByArray);
  if (!usedByArray?.length) {
    return <EmptyTableCell label={'not in use'} />;
  }

  const [usedBy] = usedByArray;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>{<UsedByDisplay usedBy={usedBy} />}</Box>
      {usedByArray.length > 1 ? (
        <Tooltip
          title={usedByArray.map(usedBy => (
            <Typography>{<UsedByDisplay usedBy={usedBy} />}</Typography>
          ))}
        >
          <Chip
            color={'primary'}
            size={'small'}
            label={`${usedByArray.length} total`}
            sx={{ ml: 1 }}
          />
        </Tooltip>
      ) : null}
    </Box>
  );
};

const UsedByDisplay = ({
  usedBy,
}: {
  usedBy: { type: string; value: any };
}) => {
  switch (usedBy?.type) {
    case 'callflow':
      return (
        <NameAndTypeCell
          row={{ original: usedBy.value }}
          value={usedBy.value.doc.name}
          // onComplete={null}
        />
      );
      break;
    case 'device':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PhoneIcon color={'primary'} sx={{ mr: 0.5 }} />
          <RouterLink
            underline={'hover'}
            to={`/admin/devices/view/${usedBy.value?.doc.id}`}
            variant={'body1'}
            sx={{ fontWeight: 500 }}
          >
            {usedBy.value?.doc.name}
          </RouterLink>
        </Box>
      );
    case 'group':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <GroupIcon color={'primary'} sx={{ mr: 0.5 }} />
          <RouterLink
            underline={'hover'}
            to={`/admin/ring_groups/view/${usedBy.value?.doc.id}`}
            variant={'body1'}
            sx={{ fontWeight: 500 }}
          >
            {usedBy.value?.doc.name}
          </RouterLink>
        </Box>
      );
      break;
    case 'user':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PersonIcon color={'primary'} sx={{ mr: 0.5 }} />
          <RouterLink
            underline={'hover'}
            to={`/admin/users/view/${usedBy.value?.doc.id}`}
            variant={'body1'}
            sx={{ fontWeight: 500 }}
          >
            {usedBy.value?.extra?.fullName}
          </RouterLink>
        </Box>
      );
      break;
    case 'account':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BusinessIcon color={'primary'} sx={{ mr: 0.5 }} />
          <RouterLink
            underline={'hover'}
            to={`/admin/dashboard`}
            variant={'body1'}
            sx={{ fontWeight: 500 }}
          >
            Account
          </RouterLink>
        </Box>
      );
      break;
    case 'menu':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DialpadIcon color={'primary'} sx={{ mr: 0.5 }} />
          <RouterLink
            underline={'hover'}
            to={`/admin/menus?id=${usedBy.value?.doc.id}`}
            variant={'body1'}
            sx={{ fontWeight: 500 }}
          >
            {usedBy.value?.doc.name}
          </RouterLink>
        </Box>
      );
      break;
    case 'vmbox':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <VoicemailIcon color={'primary'} sx={{ mr: 0.5 }} />
          <RouterLink
            underline={'hover'}
            to={`/admin/vmboxes/view/${usedBy.value?.doc.id}`}
            variant={'body1'}
            sx={{ fontWeight: 500 }}
          >
            {usedBy.value?.doc.name}
          </RouterLink>
        </Box>
      );
      break;
    default:
      return null;
  }
};

import {
  Box,
  Button,
  Divider,
  Grid,
  InputTextField,
  Link,
  Typography,
} from 'app/design';
// import {
//   Sidebar,
//   SidebarCard,
//   SidebarCardContent,
//   SidebarCardHeader,
// } from 'app/design';

import { QuickFinderDirectoryDialog } from 'app/components/QuickFinderDirectoryDialog';
import { useDirectoryQuery } from 'app/hooks/queries/directory';
// import { KazooSDK } from '@KazooSDK';
import { getAtPath, setAtPath, useToggleReducer } from 'app/utilities';
import {
  FontDownload as FontDownloadIcon,
  Directory as DirectoryIcon,
} from 'app/design/icons-material';
// import { ToastSaving } from '@Util/toast';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useHoverDirty } from 'react-use';

import { cloneDeep } from 'lodash';

import { IvrMenuEventEmitterContext } from '../../../..';

// import store from '../../../../../../../../store';
// import { syncSingleItem } from '../../../../../../Actions/lists';
// // import { SidebarGreeting } from '../../../../../AdminBoxes/directory/View/Greeting';
// import { AutocompleteDirectory } from '../../../../AutocompleteDirectory';
// import { HelpToolTip } from '../../../../LogsAndCharts/shared/tables/components/ReportTable/HelpToolTip';

// import { useSetupHook } from '../../../../SetupHook';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';
import { NodeEditDialog } from '../../../../Dialogs';

export const ModifyDialog = props => {
  const {
    callflow,
    setCallflow,
    modifyPath,
    moduleItem,
    directoryId: initialDirectoryId,
    onClose,
  } = props;

  // const { dispatchSyncSingleItem } = useSetupHook();

  const [tmpModuleItem, setModifyModuleItem] = useState(() =>
    cloneDeep(moduleItem),
  );
  const directoryId = tmpModuleItem?.data?.id;
  // const [directoryId, setDirectoryId] = useState(initialDirectoryId);

  const directoryQuery = useDirectoryQuery(directoryId);

  const handleSave = async () => {
    moduleItem.data.id = directoryId;
    setAtPath(callflow, modifyPath, tmpModuleItem);
    setCallflow({ ...callflow });
    onClose();
  };

  const [initialState] = useState({
    id: initialDirectoryId,
  });

  const saveLabel = !directoryId
    ? 'Leave Empty'
    : !initialDirectoryId
    ? 'Add'
    : initialDirectoryId === directoryId
    ? 'Done'
    : 'Update';

  return (
    <>
      <NodeEditDialog
        title={directoryId ? 'Edit Directory Settings' : 'Select Directory'}
        // titleButton={
        // directoryId ? (
        //   <Button color="primary" onClick={() => setDirectoryData(null)}>
        //     Change Directory
        //   </Button>
        // ) : null
        // <Button color="primary" onClick={toggleShowAddDirectoryDialog}>
        //   New Directory
        // </Button>
        // }
        maxWidth={'sm'}
        onClose={onClose}
        onCancel={
          //   directoryId && !directory
          //     ? () => setDirectoryData(null)
          //     : directory && !directoryId
          //     ? () => setDirectoryData({ ...directory?.doc })
          //     : onClose
          onClose
        }
        onSave={handleSave}
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        cancelLabel={
          // directoryId && !directory ? 'Back' : directory && !directoryId ? 'Back' : null
          'Cancel'
        }
        // isLoading={directoryQuery.isLoading}
        // loadingLabel={'Saving Directory Settings...'}
        // error={error}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* <Typography variant={'h4'}>User Settings</Typography>
            <Divider /> */}
            <DirectorySettings
              {...props}
              tmpModuleItem={tmpModuleItem}
              setModifyModuleItem={setModifyModuleItem}
              directoryQuery={directoryQuery}
            />
          </Grid>
        </Grid>
        {/* {directoryData ? (
          <EditDirectorySettings directory={directoryData} setDirectory={setDirectoryData} />
        ) : (
          <ChooseDirectory onChoose={setDirectoryData} />
        )} */}
      </NodeEditDialog>
    </>
  );
};

const DirectorySettings = ({
  tmpModuleItem,
  setModifyModuleItem,
  directoryQuery,
}) => {
  const {
    data: directory,
    isLoading: directoryIsLoading,
    error: directoryError,
    refetch: refetchDirectory,
  } = directoryQuery;

  const directoryId = tmpModuleItem?.data?.id;
  console.log('DirectoryId:', directoryId, tmpModuleItem);

  // Finder Dialog
  const [showFinderDialog, toggleShowFindDirectoryDialog] =
    useToggleReducer(false);

  const onChangeDirectorySelect = selected => {
    const [directoryId] = selected;
    setAtPath(tmpModuleItem, `data.id`, directoryId); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindDirectoryDialog();
  };

  const handleClear = () => {
    setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindDirectoryDialog();
  };

  return (
    <>
      {showFinderDialog ? (
        <QuickFinderDirectoryDialog
          onSelect={onChangeDirectorySelect}
          onCancel={toggleShowFindDirectoryDialog}
          onClear={handleClear}
          initialSelected={directoryId ? [directoryId] : []}
          selectionTitle={directory ? `${directory.doc.name}` : null}
          allowSelectNone
        />
      ) : null}
      {directoryId ? (
        <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
          {directoryIsLoading ? 'Loading Directory' : null}
          {directory ? (
            <Link
              variant="h1"
              href={`/admin/directories/view/${directoryId}`}
              underline="hover"
            >{`${directory.doc.name}`}</Link>
          ) : null}
          {directoryError ? (
            <Typography variant="h3" color="error.main">
              Failed loading directory
            </Typography>
          ) : null}
          <div>
            <Link onClick={toggleShowFindDirectoryDialog}>
              Change Directory
            </Link>
          </div>
        </Box>
      ) : (
        <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Link onClick={toggleShowFindDirectoryDialog} underline="hover">
            Choose Directory
          </Link>
        </Box>
      )}
    </>
  );
};

// const ChooseDirectory = props => {
//   const { onChoose, onClose } = props;

//   const handleSelect = value => {
//     onChoose(value?.directory?.doc);
//   };

//   return (
//     <>
//       {/* <AutocompleteDirectory
//         label=""
//         placeholder={'Search Directory box by name...'}
//         defaultValue={null}
//         onChange={handleSelect}
//       /> */}
//       AutocompleteVmBox
//     </>
//   );
// };

// const EditDirectorySettings = props => {
//   const { directory, setDirectory, onClose } = props;

//   return (
//     <Grid
//       container
//       spacing={1}
//       // direction={'column'}
//       alignItems={'top'}
//       justify={'center'}
//     >
//       <Grid item xs={12}>
//         <Grid container>
//           <Grid item xs={12}>
//             <Typography variant={'h7'} style={{ display: 'inline-block' }}>
//               Directory Settings
//             </Typography>
//             {/* <HelpToolTip
//               title={
//                 'Changing these settings will affect the Directory wherever it is used'
//               }
//             /> */}
//           </Grid>
//           <Grid item xs={12}>
//             {directory ? (
//               <>Sidebar??</>
//             ) : (
//               // <Sidebar>
//               //   <Divider />
//               //   <DirectoryBoxName
//               //     directory={{ doc: directory }}
//               //     onComplete={directory => setDirectory(directory)}
//               //   />
//               //   <Divider />
//               //   <SidebarGreeting
//               //     directory={{ doc: directory, id: directory.id }}
//               //     onComplete={directory => setDirectory(directory)}
//               //   />
//               //   <Divider />
//               // </Sidebar>
//               'Invalid Directory.'
//             )}
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );

//   // return (
//   //   <>
//   //     {showModifyAudioDialog && (
//   //       <ModifyDialogAudio
//   //         mediaId={data.media?.unavailable}
//   //         onSave={afterUpdateMedia}
//   //         onClose={setShowModifyAudioDialogWrap(null)}
//   //       />
//   //     )}
//   //     <div>
//   //       <InputTextField
//   //         label="Name"
//   //         value={getAtPath(data, 'name')}
//   //         onChange={handeChangeText('name')}
//   //       />
//   //     </div>
//   //     <Divider />
//   //     <br />
//   //     {data.media?.unavailable ? (
//   //       <div>
//   //         <SmallMediaPlayer mediaId={data.media?.unavailable} includeName />
//   //         <Link onClick={setShowModifyAudioDialogWrap(true)}>
//   //           Modify Greeting
//   //         </Link>
//   //       </div>
//   //     ) : (
//   //       <div>
//   //         <Link onClick={setShowModifyAudioDialogWrap(true)}>Add Greeting</Link>
//   //       </div>
//   //     )}
//   //
//   //     <Divider />
//   //     <div>
//   //       <InputTextField
//   //         label="Mailbox"
//   //         value={getAtPath(data, 'mailbox')}
//   //         onChange={handeChangeText('mailbox')}
//   //       />
//   //     </div>
//   //   </>
//   // );
// };

// const DirectoryBoxName = ({ directory, onComplete }) => {
//   const dispatch = useDispatch();
//   const [showDialog, setShowDialog] = useState(false);

//   const {
//     mutate: mutateDirectoryBox,
//     isLoading: isLoadingMutate,
//     error: mutateError,
//   } = useMutation(data => {
//     // KazooSDK.resourceDirectoryesPatch(data)
//   });

//   const handleSave = name => {
//     // const directoryData = {
//     //   ...directory.doc,
//     //   name,
//     // };
//     // let saveToast = ToastSaving({ title: 'Saving Directory Name...' });
//     // mutateDirectoryBox(directoryData, {
//     //   onSuccess: async response => {
//     //     setShowDialog(false);
//     //     dispatch(syncSingleItem({ collectionKey: 'directoryes', id: directory.id }));
//     //     // await sleep(1000);
//     //     saveToast.saved({ title: 'Directory Name Saved!' });
//     //     console.log(response.data.data);
//     //     onComplete && onComplete(response.data.data);
//     //   },
//     //   onError: error => {
//     //     saveToast.error(error);
//     //   },
//     // });
//   };

//   return (
//     <>
//       {showDialog && (
//         <EditDetailsDialog
//           type={'Directory'}
//           progressLabel={'Saving Directory Name...'}
//           name={directory?.doc?.name}
//           onCancel={() => setShowDialog(false)}
//           onClose={() => setShowDialog(false)}
//           onSave={handleSave}
//           errors={[mutateError]}
//           isLoadingMutate={isLoadingMutate}
//         />
//       )}
//       {/* <SidebarCard>
//         <SidebarCardHeader
//           label={'Directory Name'}
//           icon={
//             <FontDownloadIcon
//               style={{ verticalAlign: 'middle', color: '#6736F1' }}
//             />
//           }
//           editButtonProps={{
//             onClick: () => setShowDialog(true),
//           }}
//         />
//         <SidebarCardContent>
//           <div>
//             <Typography variant="body1">{directory?.doc?.name}</Typography>
//           </div>
//         </SidebarCardContent>
//       </SidebarCard> */}
//       SidebarStuff
//     </>
//   );
// };

export default ModifyDialog;

import React, { useState, useCallback } from 'react';

// import * as OptionComponents from '../../components';
// import * as MODULES from '../../flowTransformModules';

import { orderBy } from 'lodash';

import { v4 as uuidv4 } from 'uuid';
import {
  Clock,
  Community,
  Dialpad,
  Group,
  MusicNote,
  Phone,
  SmartphoneDevice,
  Strategy,
  User,
  Voice,
  Message,
  SkipNext,
  PhoneDisabled,
} from 'iconoir-react';

export const USER_ADDABLE_COMPONENTS = new Map([
  [
    'misc',
    [
      {
        name: 'Play Audio',
        icon: <MusicNote />,
        type: 'PlayAudio',
        details: 'Play an audio file of your choice to caller.',
        options: {
          props: {},
        },
        allowAfter: true,
      },
      {
        name: 'Send Message',
        icon: <Message />,
        details: 'Send an SMS/MMS to the caller',
        type: 'Message',
        options: {
          props: {},
        },
        data: {},
        allowAfter: true,
        allowFunc: ({ pipe, devMode, isInTemplate, endpointAlreadyInArr }) => {
          // if (!isInTemplate || (isInTemplate && endpointAlreadyInArr)) {
          //   // console.log({ isInTemplate, endpointAlreadyInArr });
          //   return false;
          // }
          // return true;
          // console.log('PIPE:', pipe);
          if (pipe?.type === 'external' || devMode) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'Menu',
        icon: <Dialpad />,
        details:
          'Present the caller with a menu providing options to direct their call.',
        type: 'Menu',
        options: {
          props: {
            // type: ['is_menu', 'time_of_day_menu'],
            // possibleStrategyTypes:[],
            required: true,
          },
        },
        data: {
          targets: {
            _: {},
            timeout: {},
          },
        },
      },
      {
        name: 'Schedule', // and Options
        type: 'Schedule',
        icon: <Clock />,
        details: 'Route callers based on your business hours.',
        options: {
          props: {},
        },
      },
    ],
  ],
  [
    'ring',
    [
      {
        name: "Ring a Line's Users",
        icon: <Community />,
        type: 'Ring',
        details: 'Ring all the users associated with the specified line.',
        options: {
          props: {
            required: true,
            defaultRingType: 'line',
          },
        },
        data: {
          ring_type: 'line',
          data: {},
        },
        allowAfter: true,
      },
      {
        name: 'Ring a User',
        details: 'Ring a specified user.',
        icon: <User />,
        type: 'Ring',
        options: {
          props: {
            required: true,
            defaultRingType: 'user',
          },
        },
        data: {
          ring_type: 'user',
          data: {},
        },
        allowAfter: true,
      },
      {
        name: 'Ring a Device',
        details: 'Ring a specified device.',
        icon: <Phone />,
        type: 'Ring',
        options: {
          props: {
            required: true,
            defaultRingType: 'device',
          },
        },
        data: {
          ring_type: 'device',
          data: {},
        },
        allowAfter: true,
      },
      {
        name: 'Ring a Ring Group',
        details: 'Ring a specified ring group.',

        icon: <Group />,
        type: 'Ring',
        options: {
          props: {
            required: true,
            defaultRingType: 'group',
          },
        },
        data: {
          ring_type: 'group',
          data: {},
        },
        allowAfter: true,
      },
    ],
  ],
  [
    'termination',
    [
      // {
      //   name: 'Template',
      //   icon: 'add_to_photos',
      //   type: 'Template',
      //   options: { props: {} },
      //   allowFunc: ({ isInTemplate, endpointAlreadyInArr }) => {
      //     return isInTemplate ? false : true;
      //   },
      // },
      {
        name: 'Template Endpoint',
        icon: 'stop',
        type: 'TemplateEndpoint',
        options: { props: {} },
        allowFunc: ({ isInTemplate, endpointAlreadyInArr }) => {
          if (!isInTemplate || (isInTemplate && endpointAlreadyInArr)) {
            // console.log({ isInTemplate, endpointAlreadyInArr });
            return false;
          }
          return true;
        },
      },
      {
        name: 'Voicemail',
        icon: <Voice />,
        type: 'VoicemailBox',
        details: 'Send the caller to voicemail.',
        options: { props: {} },
      },
      {
        name: 'Hangup',
        icon: <PhoneDisabled />,
        type: 'Hangup',
        details: 'Hangup on the caller.',
        options: { props: {} },
      },
      {
        name: 'Transfer to Line',
        icon: <Strategy />,
        type: 'Transfer',
        details: 'Transfer the caller to another line.',
        options: {
          props: {},
        },
      },
      {
        name: 'Jump to Module',
        icon: <SkipNext />,
        details: 'Skip to any other part of the call route',
        type: 'GoToInFlow',
        options: {
          props: {},
        },
        data: {},
        allowAfter: false,
        allowFunc: ({ pipe, devMode, isInTemplate, endpointAlreadyInArr }) => {
          // if (!isInTemplate || (isInTemplate && endpointAlreadyInArr)) {
          //   // console.log({ isInTemplate, endpointAlreadyInArr });
          //   return false;
          // }
          // return true;
          // console.log('PIPE:', pipe);;
          // console.log("DEVMODE:'", devMode);
          // return devMode;
          return true;
        },
      },
    ],
  ],
]);

export const OLD_USER_ADDABLE_COMPONENTS = orderBy(
  [
    // {
    //   name: 'Caller ID Routing',
    //   type: 'CallerIdRouting',
    //   options: {
    //     props: {
    //       required: true,
    //     },
    //   },
    // },
    {
      name: 'Play Audio',
      icon: <MusicNote />,
      type: 'PlayAudio',
      options: {
        props: {},
      },
      allowAfter: true,
    },
    // {
    //   name: 'Schedule', // and Options
    //   icon: 'schedule',
    //   type: 'Schedule',
    //   options: {
    //     props: {},
    //   },
    // },
    // {
    //   name: 'Directory',
    //   icon: 'folder',
    //   type: 'Directory',
    //   options: {
    //     props: {},
    //   },
    // },
    // {
    //   name: 'Conference Room',
    //   icon: 'videocam',
    //   type: 'ConferenceRoom',
    //   options: {
    //     props: {},
    //   },
    // },
    // {
    //   name: 'Choose Menu',
    //   type: 'ChooseMenu',
    //   options: {
    //     props: {
    //       type: ['is_menu', 'time_of_day_menu'],
    //       required: true,
    //     },
    //   },
    // },
    // {
    //   name: 'Transfer to Menu',
    //   type: 'ChooseMenu',
    //   options: {
    //     props: {
    //       required: true,
    //     },
    //   },
    // },
    {
      name: 'Menu',
      icon: <Dialpad />,
      type: 'Menu',
      options: {
        props: {
          // type: ['is_menu', 'time_of_day_menu'],
          // possibleStrategyTypes:[],
          required: true,
        },
      },
      data: {
        targets: {
          _: {},
          timeout: {},
        },
      },
    },
    // {
    //   name: 'Go To Callflow',
    //   type: 'ContinueToCallflow',
    //   options: {
    //     props: {
    //       types: ['office_menu'],
    //       showChooser: true,
    //       required: true,
    //     },
    //   },
    // },
    // {
    //   name: 'Ring Group',
    //   type: 'RingGroup',
    //   options: {
    //     props: {
    //       required: true,
    //     },
    //   },
    // },
    {
      name: "Ring a Line's Users",
      icon: <Community />,
      type: 'Ring',
      options: {
        props: {
          required: true,
          defaultRingType: 'line',
        },
      },
      data: {
        ring_type: 'line',
        data: {},
      },
      allowAfter: true,
    },
    {
      name: 'Ring a User',
      icon: <User />,
      type: 'Ring',
      options: {
        props: {
          required: true,
          defaultRingType: 'user',
        },
      },
      data: {
        ring_type: 'user',
        data: {},
      },
      allowAfter: true,
    },
    {
      name: 'Ring a Device',
      icon: <Phone />,
      type: 'Ring',
      options: {
        props: {
          required: true,
          defaultRingType: 'device',
        },
      },
      data: {
        ring_type: 'device',
        data: {},
      },
      allowAfter: true,
    },
    {
      name: 'Ring a Ring Group',
      icon: <Group />,
      type: 'Ring',
      options: {
        props: {
          required: true,
          defaultRingType: 'group',
        },
      },
      data: {
        ring_type: 'group',
        data: {},
      },
      allowAfter: true,
    },
    // {
    //   name: 'Simple',
    //   icon: 'bolt',
    //   type: 'Simple',
    //   options: { props: {} },
    // },
    // {
    //   name: 'Ring Custom',
    //   icon: 'folder_special',
    //   type: 'Ring',
    //   options: {
    //     props: {
    //       required: true,
    //       defaultRingType: 'custom',
    //     },
    //   },
    // },
    // {
    //   name: 'User',
    //   type: 'User',
    //   options: { props: { defaultToFirst: true } },
    // },
    // {
    //   name: 'User Desk Phone',
    //   type: 'UserDeskPhone',
    //   options: { props: { defaultToFirst: true } },
    // },
    // {
    //   name: 'User Cell Phone',
    //   type: 'UserCellPhone',
    //   options: { props: { defaultToFirst: true } },
    // },
    {
      name: 'Transfer to Line',
      icon: <Strategy />,
      type: 'Transfer',
      options: {
        props: {},
      },
    },
    // {
    //   name: 'Template',
    //   icon: 'add_to_photos',
    //   type: 'Template',
    //   options: { props: {} },
    //   allowFunc: ({ isInTemplate, endpointAlreadyInArr }) => {
    //     return isInTemplate ? false : true;
    //   },
    // },
    {
      name: 'Template Endpoint',
      icon: 'stop',
      type: 'TemplateEndpoint',
      options: { props: {} },
      allowFunc: ({ isInTemplate, endpointAlreadyInArr }) => {
        if (!isInTemplate || (isInTemplate && endpointAlreadyInArr)) {
          // console.log({ isInTemplate, endpointAlreadyInArr });
          return false;
        }
        return true;
      },
    },
    {
      name: 'Voicemail',
      icon: <Voice />,
      type: 'VoicemailBox',
      options: { props: {} },
    },
  ],
  'name',
);

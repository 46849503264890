/**
 * Create the store with dynamic reducers
 */

import {
  configureStore,
  getDefaultMiddleware,
  StoreEnhancer,
  combineReducers,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mask from 'json-mask';

import { pickBy } from 'lodash';
import { createReducer } from './reducers';

import { persistMask as authPersistMask } from 'app/data/auth';
import { persistMask as webphonePersistMask } from 'app/data/webphone';
import { persistMask as localPersistMask } from 'app/data/local';

// .replace(/\s/g, '')
// console.log('PERSISTMASK:', persistMask);
export const persistData = store => next => action => {
  // const persistKeys = ['auth'];
  // TODO: debounce like https://typeofnan.dev/debouncing-with-redux-middleware/
  const persistMask = {
    auth: authPersistMask,
    webphone: webphonePersistMask,
    local: localPersistMask,
  };
  // TODO: DEBOUNCE!!
  // persist certain keys to localstorage
  // - should only be doing when those keys->data have changed...
  //   - can NOT do by checking "action.type" as that may be "logout" or something generic (ie we dont know if the type actually changed)
  // - expecting those keys to correlate to injected slices (ie "auth") that are read from localstorage
  //   - would be nice if I could simply do persistence in each slice, instead of having to use middleware for it
  // if (action.type === 'UPDATE_USER_DETAILS') {
  //   saveDebounce(store);
  // }
  // console.log('dataSaver:', action?.type);
  setTimeout(() => {
    try {
      const state = store.getState();
      Object.keys(state).forEach(stateKey => {
        const maskVal = persistMask[stateKey];
        if (maskVal?.length) {
          const persistState = mask(state[stateKey], maskVal);
          localStorage.setItem(
            `app_persist_state_${stateKey}`,
            JSON.stringify(persistState || {}),
          );
        }
      });
    } catch (e) {
      console.error('persist error: e', e);
    }
    // console.log('Saved state to localStorage:', persistState);
  }, 1);
  return next(action);
};

export const configureAppStore = (preloadedState?: any | undefined) => {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware, persistData];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(), //persistedReducer, //createReducer(),
    // use concat/prepend to avoid loss of type info
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        thunk: false,
      }).concat(middlewares),
    // middleware: [
    //   ...getDefaultMiddleware({
    //     serializableCheck: {
    //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    //     },
    //   }),
    //   ...middlewares,
    // ],
    // preloadedState, // NOT preloading/hydrating here! (instead doing per-slice)
    devTools: process.env.NODE_ENV === 'development' ? true : false,
    enhancers,
  });

  // // Make reducers hot reloadable, see http://mxs.is/googmo
  // /* istanbul ignore next */
  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     forceReducerReload(store);
  //   });
  // }

  return store;
};

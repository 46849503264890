import { Box, Chip, Grid, Tooltip, Typography } from 'app/design';
import { EmptyTableCell } from 'app/components/EmptyTableCell';
import React from 'react';
import { Link } from 'react-router-dom';

interface ExtensionsTableCellProps {
  extensions: string[];
}

const ExtensionsTableCell = ({ extensions }: ExtensionsTableCellProps) => {
  if (!extensions.length) {
    return <EmptyTableCell label={'none'} />;
  }

  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <Typography>
          <Link to={`/admin/extensions?search=${extensions[0]}`}>
            {extensions[0]}
          </Link>
        </Typography>
      </Grid>
      {extensions.length > 1 ? (
        <Grid item>
          <Tooltip
            title={extensions.slice(1).map(ext => (
              <Typography
                key={ext}
                variant={'caption'}
                sx={{ display: 'block' }}
              >
                <Link
                  style={{ color: 'white' }}
                  to={`/admin/extensions?search=${ext}`}
                >
                  {ext}
                </Link>
              </Typography>
            ))}
            arrow
          >
            <Box>
              <Chip
                size={'small'}
                color={'primary'}
                label={
                  <Typography variant={'caption'}>{`+ ${
                    extensions.length - 1
                  }`}</Typography>
                }
              />
            </Box>
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ExtensionsTableCell;

import HelpModeToggleSwitch from 'app/components/Sidebar2/components/HelpModeToggleSwitch/HelpModeToggleSwitch';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
// import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { Alert, Avatar, ButtonDropdown } from 'app/design-lib';
import { usePNContext } from 'app/utilities/pubnub';
import { usePipesQuery } from 'app/pages/v2/common/sdk';

import SimpleBar from 'simplebar-react';
import {
  WarningSquare,
  Settings,
  HeadsetHelp,
  ClipboardCheck,
  NavArrowRight,
  DeleteCircle,
  WarningTriangle,
} from 'iconoir-react';
import { User, UserPrivLevel } from 'types/user';
import { Link } from 'react-router-dom';
import { TrialButton } from 'app/components/Header/Header';
import { useHint, useHints } from 'app/utilities';
import React, { ReactNode } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import { store } from 'store';
import { useAuthenticatedUserQuery } from '../../../../hooks/queries/user';
import {
  useAccountQuery,
  useAccountStripeCustomerQuery,
} from '../../../../hooks/queries/account';
import { isBefore } from 'date-fns';

import { useTioQuery } from 'app/pages/v2/common/sdk';
import currency from 'currency.js';

interface SidebarSettingsProps {
  user: User;
}

const SidebarSettings = ({ user }: SidebarSettingsProps) => {
  // @ts-ignore
  const { onlineUsers } = usePNContext();
  const isOnline = onlineUsers?.[user.id] ? true : false;

  const onReportBug = () => {
    // @ts-ignore
    if (!!window.Marker) {
      // @ts-ignore
      window.Marker.capture('fullscreen');
    } else {
      window.alert('error: you can not report a bug at this time');
    }
  };

  return (
    <div className={'text-md w-full flex flex-col items-center'}>
      <div className={'w-full border-t border-neutral-20 flex flex-col'}></div>
      <Checklist />
      <TrialButton />
      <BillingWarnings />
      <div className={'w-full border-neutral-20 flex flex-col'}>
        {/* <HelpModeToggleSwitch /> */}
        {/* <IntegrationTrunkingIO /> */}
        <a
          // onClick={onReportBug}
          className={`text-md px-4 py-2 font-medium hover:bg-transparency-10 flex text-content space-x-2 w-full items-center`}
          href={`https://support.calling.io/`}
          target={`_blank`}
        >
          <HeadsetHelp fr={undefined} height={20} width={20} />
          <span>Support</span>
        </a>
        {/* </div> */}
        {/* <div className={'w-full border-t border-gray-20'}></div> */}
        {/* <div className={'px-4 w-full flex flex-col space-y-4'}> */}
        <Link
          to={
            user.doc.priv_level === UserPrivLevel.Admin
              ? `/v2/settings/account`
              : `/v2/settings/my_profile`
          }
          className={
            'flex text-content px-4 py-2 space-x-2 hover:bg-transparency-10 w-full items-center'
          }
          data-tour-is-settings-button
        >
          <Settings fr={undefined} height={20} width={20} />
          <span className={'text-md font-medium'}>Settings</span>
        </Link>
        <Link
          to={`/v2/settings/my_profile`}
          className={
            'flex text-content px-4 py-2 space-x-2 hover:bg-transparency-10 w-full items-center'
          }
        >
          <UserAvatar user={user} size={'sm'} enabled={isOnline} />
          <span className={'text-md font-medium'}>{user.extra.fullName}</span>
        </Link>
      </div>
    </div>
  );
};

const Checklist = () => {
  const [isHiddenChecklist, hideChecklist, refetch, setChecklistValue] =
    useHint('admin.setup.checklist');

  const { hints, refetchHints } = useHints([
    'admin.setup.checklist.record_vm_greeting',
    'admin.setup.checklist.customize_incoming_calls',
    // 'admin.setup.checklist.enable_auto_replies',
    'admin.setup.checklist.add_team_member',
    'admin.setup.checklist.download_mobile_app',
  ]);

  const count = Object.entries(hints).length;
  const done = Object.entries(hints).filter(([k, v]) => !!v).length;

  const pipesQuery = usePipesQuery({
    filters: {
      users: {
        path: [store.getState().auth.owner_id, 'enabled'],
        equals: true,
      },
      NOT: {
        type: 'room',
      },
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];
  const pipe = pipes?.[0];

  if (isHiddenChecklist) {
    return null;
  }

  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>
        <div
          className={
            'cursor-pointer w-full border-neutral-20 flex flex-col bg-accent-40 hover:bg-accent-50'
          }
        >
          <div
            className={
              'flex text-content text-white px-4 py-2 space-x-2 w-full items-center'
            }
          >
            <ClipboardCheck fr={undefined} height={20} width={20} />
            <span className={'text-md font-medium'}>
              Startup Checklist {done}/{count}
            </span>
            <NavArrowRight fr={undefined} height={14} width={14} />
          </div>
        </div>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          className="bg-accent-40 drop-shadow p-2 border border-neutral-20 rounded-md outline-0"
          sideOffset={4}
          side={'right'}
        >
          <RadixPopover.Close
            id="startup-checklist-button"
            className="absolute top-2 right-2 invisible"
            aria-label="Close"
          >
            <DeleteCircle
              className="text-white "
              fr={undefined}
              height={20}
              width={20}
            />
          </RadixPopover.Close>
          <div className={'p-2 pt-2'}>
            <ChecklistItem path="admin.setup.checklist.record_vm_greeting">
              {({ complete, onComplete }) => (
                <Link
                  to={`/v2/settings/pipes/${pipe?.id}#voicemail`}
                  className={[
                    'flex text-content text-white py-2 space-x-2 hover:underline w-full items-center',
                    complete ? 'line-through' : '',
                  ].join(' ')}
                  onClick={onComplete}
                >
                  <span className={'text-md font-medium'}>
                    1. Record voicemail greeting
                  </span>
                </Link>
              )}
            </ChecklistItem>
            <ChecklistItem path="admin.setup.checklist.customize_incoming_calls">
              {({ complete, onComplete }) => (
                <Link
                  to={`/v2/settings/pipes/${pipe?.id}#call_routing`}
                  className={[
                    'flex text-content text-white py-2 space-x-2 hover:underline w-full items-center',
                    complete ? 'line-through' : '',
                  ].join(' ')}
                  onClick={onComplete}
                >
                  <span className={'text-md font-medium'}>
                    2. Customize incoming calls
                  </span>
                </Link>
              )}
            </ChecklistItem>
            {/* <ChecklistItem path="admin.setup.checklist.enable_auto_replies">
              {({ complete, onComplete }) => (
                <Link
                  to={`/v2/settings/pipes/${pipe?.id}#message_routing`}
                  className={[
                    'flex text-content text-white py-2 space-x-2 hover:underline w-full items-center',
                    complete ? 'line-through' : '',
                  ].join(' ')}
                  onClick={onComplete}
                >
                  <span className={'text-md font-medium'}>
                    3. Enable auto-replies for text messages
                  </span>
                </Link>
              )}
            </ChecklistItem> */}
            <ChecklistItem path="admin.setup.checklist.add_team_member">
              {({ complete, onComplete }) => (
                <Link
                  to={`/v2/settings/users#add_teammate`}
                  className={[
                    'flex text-content text-white py-2 space-x-2 hover:underline w-full items-center',
                    complete ? 'line-through' : '',
                  ].join(' ')}
                  onClick={onComplete}
                >
                  <span className={'text-md font-medium'}>
                    3. Add a team member
                  </span>
                </Link>
              )}
            </ChecklistItem>
            <ChecklistItem path="admin.setup.checklist.add_team_member">
              {({ complete, onComplete }) => (
                <Link
                  to={`/v2/settings/my_devices#mobile_app`}
                  className={[
                    'flex text-content text-white py-2 space-x-2 hover:underline w-full items-center',
                    complete ? 'line-through' : '',
                  ].join(' ')}
                  onClick={onComplete}
                >
                  <span className={'text-md font-medium'}>
                    4. Download mobile app
                  </span>
                </Link>
              )}
            </ChecklistItem>
            <div
              className="text-white text-sm cursor-pointer hover:underline mt-2 text-right"
              onClick={() => setChecklistValue(!isHiddenChecklist)}
            >
              Hide Checklist
            </div>
          </div>
          <RadixPopover.Arrow className="fill-accent-40" />
        </RadixPopover.Content>
      </RadixPopover.Portal>
      {/* <RadixPopover.Close className="RadixPopoverClose" aria-label="Close">
        <Cross2Icon />
      </RadixPopover.Close> */}
    </RadixPopover.Root>
  );
};

const ChecklistItem = ({ path, children }) => {
  const [value, hide, refetch, setValue] = useHint(path);

  return children({
    complete: value,
    onComplete: () => {
      // close menu on link click (somehow this is broken in radix popover, they demand we use a button??)
      document.getElementById('startup-checklist-button')?.click();
      setValue(process.env.NODE_ENV === 'development' ? !value : true); // allow toggling (in development)
    },
  });
};

const BillingWarnings = () => {
  const { data: user } = useAuthenticatedUserQuery();
  const { data: account, refetch } = useAccountQuery();
  const { data, refetch: refetchStripe } = useAccountStripeCustomerQuery();

  const sub = data?.data?.subscriptions?.data?.[0];
  const customer = data?.data?.customer;
  const card = data?.data?.cards?.data?.[0];
  const invoices = data?.data?.invoices?.data;
  const {
    status,
    trial_end,
    cancel_at_period_end,
    current_period_end,
    test_clock,
  } = sub ?? {};

  if (status === 'canceled') {
    return (
      <Alert
        reverse
        className={`m-1 mt-2`}
        color={'negative'}
        // icon={<WarningTriangle fr={undefined} />}
        label={'Inactive Account'}
        body={'Contact customer support to re-enable your account.'}
      />
    );
  }

  if (status === 'active' && !customer.default_source) {
    return (
      <Alert
        reverse
        className={`m-1 mt-2`}
        color={'negative'}
        // icon={<WarningTriangle fr={undefined} />}
        label={'Missing Payment Method'}
        body={'Add a card to avoid any interruptions in your service.'}
        actionProps={{ children: <>{'Add payment method'}</> }}
      />
    );
  }

  if (status === 'active' && cardIsExpired(card)) {
    return (
      <Alert
        reverse
        className={`m-1 mt-2`}
        color={'negative'}
        // icon={<WarningTriangle fr={undefined} />}
        label={'Card Expired'}
        body={'Update your billing to avoid any interruptions in your service.'}
        actionProps={{ children: <>{'Update payment method'}</> }}
      />
    );
  }

  if (invoices?.find(inv => inv.payment_intent?.last_payment_error)) {
    return (
      <Alert
        reverse
        className={`m-1 mt-2`}
        color={'negative'}
        // icon={<WarningTriangle fr={undefined} />}
        label={'Payment Error'}
        body={
          'Pay your invoice now to avoid any interruptions in your service.'
        }
        actionProps={{ children: <>{'Pay Invoice'}</> }}
      />
    );
  }

  return null;
};

const IntegrationTrunkingIO = () => {
  const accountQuery = useAccountQuery();
  const latest_ledger = useTioQuery(
    `/partner_ledgers?page_size=1&order_by=id,desc`,
    accountQuery.data?.internal?.integrations?.trunkingio?.account_id
      ? true
      : false,
  );

  if (!accountQuery.data?.internal?.integrations?.trunkingio?.account_id) {
    return null;
  }

  // Only show balance when below $0!
  if (latest_ledger.isLoading || latest_ledger.data?.data?.[0]?.balance > 0) {
    return null;
  }

  return (
    <Link
      to={`/v2/settings/billing`}
      className={
        'flex text-content bg-negative-10 px-4 py-2 space-x-2 hover:bg-transparency-10 w-full items-center'
      }
    >
      <span className={'text-md font-medium'}>TrunkingIO Balance: </span>
      <span
        className={`text-md font-medium ${
          latest_ledger.isLoading
            ? 'text-neutral-70'
            : latest_ledger.data?.data?.[0]?.balance > 0
            ? 'text-positive-70'
            : 'text-negative-70'
        }`}
      >
        {latest_ledger.isFetching
          ? '--'
          : currency(latest_ledger.data?.data?.[0]?.balance).format()}
      </span>
    </Link>
  );
};

export const cardIsExpired = card => {
  return card
    ? isBefore(new Date(card.exp_year, card.exp_month - 1, 1), new Date())
    : false;
};

export default SidebarSettings;

import { gql } from 'graphql-request';

export default gql`
  query numbersStats(
    $skip: Int
    $take: Int
    $filters: JSON
    $orderBy: JSON
    $mask: String
  ) {
    phoneNumbers(
      skip: $skip
      take: $take
      filters: $filters
      orderBy: $orderBy
    ) {
      totalCount
    }
    extensions(
      skip: $skip
      take: $take
      orderBy: $orderBy
      filters: $filters
      mask: $mask
    ) {
      totalCount
    }
  }
`;

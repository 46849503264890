import { Button } from 'app/design-lib';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { useToggleReducer } from 'app/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MediaType } from 'types/media';
import { ReactMic } from '@cleandersonlobo/react-mic';
import { Mic, Pause, Play, Square } from 'iconoir-react';
import { Dialog } from '@headlessui/react';

const RecordAudio = ({ setValue }: { setValue: any }) => {
  const [isRecording, toggleRecording] = useToggleReducer(false);
  const [file, setFile] = useState<string | ArrayBuffer | null | undefined>(
    null,
  );
  const [countDown, setCountDown] = useState<number | null>(null);
  // useEffect(() => register(`${MediaType.Recording}.file`), [register]);

  const handleStop = data => {
    const recordedBlob = data.blob;
    var reader = new FileReader();
    reader.onloadend = function (evt) {
      const file = evt.target?.result;
      setValue(file);
      setFile(file);
    };
    reader.onerror = function (err) {
      console.error('Reader Error:', err);
      alert(
        'Sorry, there was a problem recording audio, please try again or record the audio locally and upload as a file',
      );
    };

    reader.readAsDataURL(recordedBlob);
  };

  const handleToggle = () => {
    if (!isRecording) {
      setCountDown(3);
      return;
    }

    toggleRecording();
  };

  useEffect(() => {
    if (countDown === null) return;

    if (countDown === 0) {
      toggleRecording();
      setCountDown(null);
      return;
    }

    const timeout = setTimeout(() => {
      setCountDown(prev => (prev ? prev - 1 : 3));
    }, 1000);

    return () => clearTimeout(timeout);
  }, [countDown]);

  return (
    <>
      <Dialog onClose={() => {}} open={!!countDown}>
        <Dialog.Backdrop
          className={`'fixed inset-0 bg-red-500 backdrop-blur-sm`}
        />
        <Dialog.Panel
          className={`z-50 bg-accent-60 grid place-items-center w-20 aspect-square text-white  p-2 rounded-full absolute top-1/2 left-1/2 text-7xl -translate-x-1/2 -translate-y-1/2`}
        >
          <span>{countDown}</span>
        </Dialog.Panel>
      </Dialog>

      <div className={`flex w-full mb-2 space-x-2 items-end`}>
        <Button
          size={'md'}
          startIcon={
            !isRecording ? <Mic fr={undefined} /> : <Square fr={undefined} />
          }
          className={`mb-1`}
          variant={'outline'}
          color={isRecording ? 'negative' : 'positive'}
          onClick={handleToggle}
        >
          {isRecording
            ? 'Stop Recording'
            : file
            ? 'Re-record Audio'
            : 'Start Recording'}
        </Button>
        {file && !isRecording ? (
          <div className={'flex-1'}>
            <div className={'text-md ml-1.5 font-medium'}>Preview</div>
            <AudioPlayer mediaId={file as string} />
          </div>
        ) : null}
        <div
          style={{ display: isRecording ? '' : 'none' }}
          className={'flex-1'}
        >
          <div className={'text-md ml-1.5 font-medium'}>Recording</div>
          <div
            className={`p-1 px-4 h-10 w-full flex items-center rounded-full border border-neutral-20`}
          >
            <ReactMic
              className="w-full h-8"
              key={isRecording || 'hidden'}
              record={isRecording}
              onStop={handleStop}
              strokeColor="#0060E5"
              mimeType={'audio/mp3'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordAudio;

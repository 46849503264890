import { Tab } from '@headlessui/react';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import { BuiltInAudio } from 'app/components/MediaDialog/components/BuiltInAudio';
import { BuyGreeting } from 'app/components/MediaDialog/components/BuyGreeting';
import { RecordAudio } from 'app/components/MediaDialog/components/RecordAudio';
import { TextToSpeech } from 'app/components/MediaDialog/components/TextToSpeech';
import { UploadAudio } from 'app/components/MediaDialog/components/UploadAudio';
import QuickFinderMediaDialog from 'app/components/QuickFinderMediaDialog/QuickFinderMenuDialog';
import { Button, Dialog, DialogProps, TextField } from 'app/design-lib';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateMediaFull } from 'app/hooks/mutations/media';
import { useCreateMedia } from 'app/hooks/mutations/media/useCreateMedia';
import { useUploadMedia } from 'app/hooks/mutations/media/useUploadMedia';
import { useListMediasQuery } from 'app/hooks/queries/media';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';
import { useToggleReducer } from 'app/utilities';
import { startCase } from 'lodash';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { MediaDoc, MediaType } from 'types/media';
import { voices } from './components/TextToSpeech/TextToSpeech';

interface ContentProps {
  mediaId?: string | null;
  mediaNameToSaveAs: string;
  onComplete: (mediaId: string | null) => void;
  externalMutation?: {
    isLoading?: boolean;
    error?: any;
    isSuccess?: boolean;
    onMutate: (mediaId: string | null) => void;
  };
  onCancel: () => void;
  allowedTypes?: MediaType[];
  type?:
    | 'Media'
    | 'Greeting'
    | 'Temporary Greeting'
    | 'Hold Music'
    | 'Call Routing Audio'
    | string; // TODO: remove string and optional
  saveLabel?: string;
  mediaDoc?: MediaDoc;
  onClear?: () => void;
  allowRemove?: boolean;
  allowReplace?: boolean;
}

interface MediaDialogProps extends ContentProps, Pick<DialogProps, 'open'> {
  noWrapper?: boolean;
}

const MediaDialog = ({ noWrapper, open, ...props }: MediaDialogProps) => {
  if (noWrapper) return <MediaDialogContent {...props} />;

  return (
    <Dialog size={'2xl'} onClose={props.onCancel} open={open}>
      <MediaDialogContent {...props} />
    </Dialog>
  );
};

const MediaDialogContent = ({
  type = 'Media',
  mediaId,
  mediaDoc,
  onComplete,
  onCancel,
  saveLabel,
  externalMutation,
  allowedTypes = [
    MediaType.BuiltIn,
    MediaType.Tts,
    MediaType.Upload,
    MediaType.Recording,
    MediaType.Existing,
  ],
  mediaNameToSaveAs,
  allowRemove = true,
}: ContentProps) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const createMedia = useCreateMedia();
  const uploadMedia = useUploadMedia();
  const updateMediaFull = useUpdateMediaFull();
  const [showAdvanced, toggleShowAdvanced] = useToggleReducer();
  const startDirty = !mediaId && allowedTypes[0] === MediaType.BuiltIn;
  const formMethods = useForm({
    defaultValues: {
      name: mediaDoc?.name ?? mediaNameToSaveAs,
      ...(mediaDoc?.media_source === 'tts'
        ? {
            [mediaDoc.media_source]: {
              text: mediaDoc.tts?.text ?? '',
              voice: mediaDoc.tts?.voice ?? voices[0].value,
            },
          }
        : {
            tts: {
              text: '',
              voice: voices[0].value,
            },
          }),
      ...(mediaDoc?.media_source === 'built_in'
        ? {
            [mediaDoc.media_source]: {
              link: mediaId,
            },
          }
        : {}),
    },
  });
  const { register, handleSubmit, control, setValue, watch } = formMethods;

  const verbLoading = mediaId ? 'Updating' : 'Uploading';
  const verbCompleted = mediaId ? 'updated' : 'uploaded';

  const [showMediaSelect, toggleMediaSelect] = useToggleReducer();
  const [tabValue, setTabValue] = useState(
    mediaDoc?.media_source ?? allowedTypes[0],
  );
  // @ts-ignore
  const watchValue = watch(tabValue);

  const handleMediaUpload = async (newMedia, file, isLoaded = false) => {
    if (isLoaded) {
      const mediaToUpload = {
        id: newMedia.id,
        encodedFile: file as string,
      };

      const resp = await uploadMedia.mutateAsync(mediaToUpload);

      if (resp.status === 'success') {
        newMedia.updated_at = Date.now();
        await updateMediaFull.mutateAsync(newMedia);
        await queryClient.removeQueries(mediaQueryKeys.all);
      }

      return;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = async function (evt) {
        const file = evt.target?.result;

        const mediaToUpload = {
          id: newMedia.id,
          encodedFile: file as string,
        };

        const resp = await uploadMedia.mutateAsync(mediaToUpload);

        if (resp.status === 'success') {
          newMedia.updated_at = Date.now();
          await updateMediaFull.mutateAsync(newMedia);
          resolve(reader.result);
        }
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  const onSave = async formData => {
    try {
      const mediaFullDoc = convertFormDataToFullMediaDoc(formData, tabValue);

      // if built in audio (url/link)
      if (typeof mediaFullDoc === 'string') {
        // used to pass url externally to mutate functions
        setSelectedId(mediaFullDoc);
        if (externalMutation) await externalMutation.onMutate(mediaFullDoc);
      } else {
        let resp: any;

        if (mediaDoc) {
          resp = await updateMediaFull.mutateAsync({
            ...mediaFullDoc,
            id: mediaId,
            name: formData.name ?? mediaDoc?.name,
          });
          await queryClient.removeQueries(mediaQueryKeys.all);
        } else {
          resp = await createMedia.mutateAsync({
            ...mediaFullDoc,
            name: formData.name ?? mediaNameToSaveAs,
          });
        }

        if (resp.status === 'success') {
          const newMedia = resp.data;

          if (tabValue === MediaType.Upload) {
            await handleMediaUpload(newMedia, formData.upload.file);
          }

          if (tabValue === MediaType.Recording) {
            await handleMediaUpload(newMedia, formData.recording.file, true);
          }

          if (externalMutation) await externalMutation.onMutate(newMedia.id);
        }
      }
    } catch (e) {
      console.error('e', e);
      // rethrow so RHF isSubmitSuccessful is false
      throw e;
    }
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleRemove = async () => {
    if (externalMutation) await externalMutation.onMutate(null);
  };

  return (
    <>
      <QuickFinderMediaDialog
        open={showMediaSelect}
        onCancel={() => {
          // setTabValue(allowedTypes.find(type => type !== MediaType.Existing)!);
          toggleMediaSelect();
        }}
        onSelect={async selectedIds => {
          const [selected] = selectedIds;
          // track selected for onComplete
          setSelectedId(selected);
          toggleMediaSelect();
          if (externalMutation) await externalMutation.onMutate(selected);
        }}
      />
      <GenericMutationDialogContent
        onComplete={() => {
          const id = mediaId ?? selectedId ?? createMedia.data?.data.id;

          // if (!id) {
          //   throw new Error('No ID for media onComplete');
          // }

          onComplete(id);
        }}
        startDirty={startDirty}
        onCancel={onCancel}
        onSubmit={handleSubmit(onSave)}
        onSuccessLabel={`${startCase(type)} ${verbCompleted}`}
        isLoadingLabel={`${verbLoading} ${type?.toLowerCase()}...`}
        title={
          mediaId
            ? `${
                !!mediaDoc || mediaId.startsWith('http') ? 'Edit' : 'Replace'
              } ${type}`
            : `Add ${type}`
        }
        mutation={mediaId ? updateMediaFull : createMedia}
        formMethods={formMethods}
        forceLoading={
          createMedia.isLoading ||
          uploadMedia.isLoading ||
          externalMutation?.isLoading
        }
        forceError={externalMutation?.error}
        forceSuccess={externalMutation?.isSuccess}
        additionalActions={
          <>
            {mediaId && allowRemove ? (
              <Button
                onClick={handleRemove}
                variant={'outline'}
                color={'attention'}
              >
                Remove Current Audio
              </Button>
            ) : null}
          </>
        }
        submitButtonDisabled={checkDisabled(watchValue, tabValue)}
        submitButtonLabel={
          saveLabel ?? (mediaId ? 'Update Audio' : 'Add Audio')
        }
      >
        <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
          <Tab.Group
            manual
            selectedIndex={allowedTypes.indexOf(tabValue)}
            onChange={index => {
              const value = allowedTypes[index];
              if (value !== MediaType.Existing) {
                setTabValue(value);
              } else {
                toggleMediaSelect();
              }
            }}
          >
            <Tab.List className={' mb-8 flex justify-center'}>
              {allowedTypes.map(type => (
                <Tab as={Fragment} key={type}>
                  {({ selected }) => (
                    <button
                      className={`p-2 text-lg font-medium ${
                        selected
                          ? 'text-content-accent border-b-2 border-content-accent'
                          : 'text-content-neutral border-b border-border-neutral'
                      } `}
                    >
                      {type.toUpperCase().replace('_', ' ')}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className={'w-full flex justify-center'}>
              {allowedTypes.map(type => {
                return (
                  <Tab.Panel
                    key={type}
                    className={'w-full flex justify-center'}
                  >
                    {type === MediaType.Tts ? (
                      <TextToSpeech
                        control={control}
                        register={register}
                        // required={tabValue === MediaType.Tts}
                      />
                    ) : type === MediaType.Upload ? (
                      <UploadAudio
                        register={register}
                        setValue={setValue}
                        // required={tabValue === MediaType.Upload}
                      />
                    ) : type === MediaType.BuiltIn ? (
                      <BuiltInAudio
                        mediaId={mediaId}
                        register={register}
                        setValue={setValue}
                        // required={tabValue === MediaType.BuiltIn}
                      />
                    ) : type === MediaType.Recording ? (
                      <RecordAudio
                        // register={register}
                        setValue={setValue}
                        // required={tabValue === MediaType.BuiltIn}
                      />
                    ) : type === MediaType.Custom ? (
                      <BuyGreeting
                      // register={register}
                      // setValue={setValue}
                      // required={tabValue === MediaType.BuiltIn}
                      />
                    ) : null}
                  </Tab.Panel>
                );
              })}
            </Tab.Panels>
          </Tab.Group>
          {/*<Tabs value={tabValue} onChange={handleTabChange}>
            {allowedTypes?.includes(MediaType.Existing) ? (
              <Tab
                label={'Select Existing'}
                onClick={e => {
                  setTabValue(tabValue);
                  toggleMediaSelect();
                }}
                // value={MediaType.Existing}
              />
            ) : null}
            {allowedTypes?.includes(MediaType.Tts) ? (
              <Tab label={'TTS'} value={MediaType.Tts} />
            ) : null}
            {allowedTypes?.includes(MediaType.BuiltIn) ? (
              <Tab label={'Built-in'} value={MediaType.BuiltIn} />
            ) : null}
            {allowedTypes?.includes(MediaType.Upload) ? (
              <Tab label={'Upload'} value={MediaType.Upload} />
            ) : null}
            {allowedTypes?.includes(MediaType.Recording) ? (
              <Tab label={'Record with Mic'} value={MediaType.Recording} />
            ) : null}
            {allowedTypes?.includes(MediaType.Custom) ? (
              <Tab label={'Buy Greeting'} value={MediaType.Custom} />
            ) : null}
          </Tabs>*/}

          <br />
          {tabValue !== MediaType.BuiltIn &&
          tabValue !== MediaType.Existing &&
          tabValue !== MediaType.Custom ? (
            <>
              <Button variant={'ghost'} onClick={toggleShowAdvanced}>
                {showAdvanced
                  ? 'hide advanced options'
                  : 'show advanced options'}
              </Button>
              {showAdvanced ? (
                <>
                  <br />
                  <TextField
                    label={'Media Name'}
                    defaultValue={''}
                    {...register('name', { required: true })}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </GenericMutationDialogContent>
    </>
  );
};

const checkDisabled = (watchValue, type) => {
  switch (type) {
    case MediaType.Upload:
      return !watchValue?.file;
    // Disable save on custom greeting until integrated
    case MediaType.Custom:
      return true;
    default:
      return false;
  }
};

const convertFormDataToFullMediaDoc = (formData, mediaType: MediaType) => {
  switch (mediaType) {
    case MediaType.Tts:
      return {
        name: formData.name,
        media_source: mediaType,
        tts: formData.tts,
        language: 'en-us',
      };
    case MediaType.Upload:
      return {
        name: formData.name,
        media_source: mediaType,
      };
    case MediaType.Recording:
      return {
        name: formData.name,
        media_source: MediaType.Upload,
      };
    case MediaType.BuiltIn:
      return formData[MediaType.BuiltIn].link;
    default:
      throw new Error(`Unhandled media type ${mediaType}`);
  }
};

interface MediaDialogWrapperProps extends MediaDialogProps {}

const MediaDialogWrapper = (props: MediaDialogWrapperProps) => {
  const [replaceMedia, setReplaceMedia] = useState<boolean | null>(null);
  const { data, isLoading, isFetching } = useListMediasQuery({
    skip: 0,
    take: 1,
    orderBy: [['name', 'asc']],
    filters: {
      context: {
        ids: [props.mediaId],
      },
    },
    options: { enabled: !!props.mediaId },
  });

  const [media] = data?.medias ?? [];

  // not existing - just open add media dialog
  if (!props.mediaId) {
    return <MediaDialog {...props} />;
  }

  const mediaUsed =
    isLoading ||
    (props.mediaId && media?.usedBy?.length > 1 && replaceMedia === null);

  // show warning if media is used in multiple locations, otherwise
  // just show edit
  return (
    <>
      <Dialog size={'2xl'} open={props.open} onClose={props.onCancel}>
        <>
          {mediaUsed ? (
            isLoading ? (
              <DialogCircularProgress label={'Loading media...'} />
            ) : (
              <>
                <div>Media Usage</div>
                <div>
                  <div style={{ display: 'grid', placeItems: 'center' }}>
                    <span className={'text-lg text-content-negative'}>
                      Warning: This media is used in multiple locations.
                    </span>
                    <span className={'font-medium'}>
                      Editing it will result in changing it for all locations.
                    </span>
                    {props.allowReplace ? (
                      <>
                        <br />
                        <span className={'font-medium text-content-positive'}>
                          Click replace audio to only change audio for this
                          resource.
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
                <DefaultDialogActions
                  onSave={
                    props.allowReplace ? () => setReplaceMedia(true) : undefined
                  }
                  saveLabel={'Replace'}
                  onDelete={() => setReplaceMedia(false)}
                  deleteLabel={'Edit'}
                  onCancel={props.onCancel}
                  skipConfirm
                />
              </>
            )
          ) : (
            <MediaDialog
              noWrapper
              {...props}
              mediaDoc={replaceMedia ? undefined : media?.doc}
            />
          )}
        </>
      </Dialog>
    </>
  );
};

export default MediaDialogWrapper;

import { Link } from 'app/design';
import { Tooltip } from 'app/design-lib';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import React from 'react';
// import Flag from 'react-flags';
import { PhoneNumberFlag } from '../PhoneNumberFlag';

interface PhoneNumberDisplayProps {
  ptn?: string;
}

export const normalizeNumber = number => {
  // TODO: make better
  if (!number?.length) {
    return '';
  }
  // replace all except numbers and "+" sign
  number = number.replace(/[^0-9+]/g, '');
  let phone_number;
  if (number.includes('+')) {
    phone_number = parsePhoneNumber(number);
    return phone_number.number;
  } else {
    if (number.length === 11 && `${parseInt(number, 10)}`.length === 11) {
      // make sure is a valid number to avoid parsing strings
      phone_number = parsePhoneNumber('+' + number);
      return phone_number.number;
    } else if (
      number.length === 10 &&
      `${parseInt(number, 10)}`.length === 10
    ) {
      phone_number = parsePhoneNumber('+1' + number);
      return phone_number.number;
    } else {
      return number;
    }
  }
};

export const useNumber = ({ ptn }) => {
  let asYouType;
  let number;
  let code;

  let isExtension = ptn?.length < 8 ? true : false;
  if (isExtension) {
    // display as-is
  } else if (ptn?.length) {
    // default to USA if only 10 digits
    if (ptn.indexOf('+') === 0) {
      // console.log('1', ptn);
      asYouType = new AsYouType();
      asYouType.input(ptn);
    } else {
      if (ptn?.length == 10) {
        // console.log('2');
        asYouType = new AsYouType('US');
        asYouType.input(ptn);
      } else if (ptn?.length == 11) {
        // console.log('3', ptn);
        asYouType = new AsYouType();
        asYouType.input(`+${ptn}`);
      } else {
        // console.log('4', ptn);
        asYouType = new AsYouType();
        asYouType.input(ptn);
      }
    }
    number = asYouType.getNumber();
    code = number?.country ?? 'unknown';
    // console.log('number:', number);
  }
  return {
    number,
    code,
    isExtension,
    national: number?.formatNational(),
    international: number?.formatInternational(),
  };
};

export const PhoneNumberDisplay = ({
  ptn,
  onClick,
  // size = 'small',
  // allowExtension = false,
  width = undefined,
  flagStyle = {},
  hideFlag = false,
  flagOnly = false,
}: {
  ptn: any;
  width?: any;
  flagStyle?: any;
  hideFlag?: any;
  flagOnly?: any;
  onClick?: () => void;
}) => {
  const { number, code, isExtension } = useNumber({ ptn });

  // const sizes = {
  //   small: 12,
  //   medium: 24,
  //   large: 36,
  // };

  const wrapStyle = {
    display: 'inline-block',
    width: width || '100%',
    whiteSpace: 'nowrap',
  };

  flagStyle = { verticalAlign_OLD: 'middle', ...flagStyle };
  const numStyle = { marginLeft: 4 };

  if (isExtension) {
    if (onClick) {
      return (
        <Link onClick={onClick} underline={'hover'}>
          {ptn}
        </Link>
      );
    }

    return (
      // @ts-ignore
      <span style={wrapStyle}>
        <span>{ptn}</span>
      </span>
    );
  }

  return flagOnly ? (
    <span style={flagStyle}>
      <PhoneNumberFlag ptn={number?.number} />
    </span>
  ) : !hideFlag ? (
    // @ts-ignore
    <span style={wrapStyle}>
      <Tooltip
        content={<>{number?.formatInternational()}</>}
        // placement="top"
        // type="light"
        // arrow
      >
        <span className={'whitespace-nowrap'} style={flagStyle}>
          <PhoneNumberFlag ptn={number?.number} />
        </span>
      </Tooltip>
      {onClick ? (
        <Link onClick={onClick} underline={'hover'} style={numStyle}>
          {number?.formatNational()}
        </Link>
      ) : (
        <span style={numStyle}>{number?.formatNational()}</span>
      )}
    </span>
  ) : (
    <span>{number?.formatNational().split(' ').join(' ').trim()}</span>
  );
};

export default PhoneNumberDisplay;

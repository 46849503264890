import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    vmboxes: (vars, fetchOptions) =>
      fetchFromGraphql('vmboxes', vars, fetchOptions),

    // Fetch a single vmbox by id
    vmboxById: (vars, fetchOptions) =>
      fetchFromGraphql('vmboxById', vars, fetchOptions),

    // only the vmbox doc
    vmboxesQuick: (vars, fetchOptions) =>
      fetchFromGraphql('vmboxesQuick', vars, fetchOptions, { full: true }),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    create: buildPutDefault('vmboxes', { buildUrlOpts: { skipId: true } }),
    updatePartial: buildPatchDefault('vmboxes'),
    delete: buildDeleteDefault('vmboxes'),
  },
};

export default exports;

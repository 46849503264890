import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import {
  AnimationDialog,
  Autocomplete,
  Button,
  DialogActions,
  TextField,
} from 'app/design';
import { DialogHeader, DialogContent, DialogTitle } from 'app/design-lib';
import { getTimeZones } from '@vvo/tzdb';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

export const TIMEZONE_LIST = getTimeZones().map(tz => ({
  label: `${tz.name.replace('_', ' ')} (${tz.abbreviation})`,
  value: tz.name,
}));

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface TimezoneForm {
  timezone: { label: string; value: string };
}

// interface declaring which props are required/allowed
interface AccountTimezoneDialogProps {
  initialTimezone?: string;
  onCancel: () => void;
  onComplete: (timezone: string | null) => void;
}

const TimezoneDialogContent = ({
  onCancel,
  onComplete,
  initialTimezone,
}: AccountTimezoneDialogProps) => {
  // console.log('TIMEZONE CONTENT MOUNTED');
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<TimezoneForm>();

  const onSubmit = async (form: TimezoneForm) => {
    onComplete(form.timezone?.value ?? null);
  };

  const value = watch('timezone.value');

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <DialogHeader title={'Select timezone'} onClose={handleCancel} />
      <DialogContent>
        <Controller
          name="timezone"
          control={control}
          defaultValue={TIMEZONE_LIST.find(tz => tz.value === initialTimezone)}
          // rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              options={TIMEZONE_LIST}
              getOptionLabel={option => option.label}
              value={value}
              onChange={(event, value) => {
                onChange(value);
              }}
              renderInput={params => {
                // @ts-ignore
                params.InputLabelProps.shrink = true;

                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      className:
                        'focus:border-0 p-0 m-0 h-max max-h-[0.2rem] focus:ring-0 focus:outline-none',
                    }}
                    label={'Timezone'}
                    placeholder={'select'}
                  />
                );
              }}
            />
          )}
        />
      </DialogContent>
      <DefaultDialogActions
        // onCancel={handleCancel}
        onSave={handleSubmit(onSubmit)}
        saveLabel={'Set Timezone'}
      />
      {/*<DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant={'outlined'}
          color={'success'}
          // disabled={!isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          {'Set Timezone'}
        </Button>
      </DialogActions>*/}
    </>
  );
};

interface TimezoneDialogProps extends AnimationDialogProps {
  initialTimezone?: string;
  onCancel: () => void;
  onComplete: (timezone: string | null) => void;
}

const TimezoneDialog = ({
  initialTimezone,
  onCancel,
  onComplete,
  ...dialogProps
}: TimezoneDialogProps) => {
  return (
    // <AnimationDialog fullWidth maxWidth={'sm'} {...dialogProps}>
    <TimezoneDialogContent
      onCancel={onCancel}
      onComplete={onComplete}
      initialTimezone={initialTimezone}
    />
    // </AnimationDialog>
  );
};

export default TimezoneDialog;

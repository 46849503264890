import React, { memo, useState, useRef } from 'react';
import {
  Handle,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';

import {
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from 'app/design';

import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import {
  Phone as PhoneIcon,
  ArrowDownward as ArrowDownwardIcon,
} from 'app/design/icons-material';

import { getAtPath, parseCallflowNumbers } from 'app/utilities';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { CallflowNumbers } from 'app/components/CallflowNumbers';

import { cloneDeep } from 'lodash';
import { useHoverDirty } from 'react-use';

import { Box as IvrNodeWrap } from '../common/Box';
import { PhoneIncome } from 'iconoir-react';

const IncomingNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  const {
    ptns: accountPtns,
    ptnsParsed: accountPtnsParsed,
    extensions: accountExtensions,
  } = parseCallflowNumbers(data.callflow.numbers);

  if (data.isInlineCallflow) {
    // CustomInline version
    // - TODO: let the user pass in what text we want displayed here?
    return <IncomingCustomInlineDisplayNode {...props} />;
  }

  if (data.skipEditing) {
    // QuickDisplay version
    return <IncomingQuickDisplayNode {...props} />;
  }

  if (data.callflow.type === 'template') {
    return <IncomingTemplateNode {...props} />;
  }

  return (
    <IvrNodeWrap
      pref={boxRef}
      // borderColor="#5E35B1"
      position={data?.position}
      data={data}
    >
      <div
        className={`flex flex-col items-center space-x-2 whitespace-nowrap w-full p-4`}
      >
        <div className={`flex space-x-2`}>
          <div
            className={`text-sm flex items-center space-x-4 whitespace-nowrap`}
          >
            <PhoneIncome width={14} height={14} />
            <div>
              <div className={`text-neutral-60`}>
                Incoming call <br />
                (or transfer) to
              </div>
              <div className={`text-md font-medium`}>
                <PhoneNumberDisplay
                  hideFlag
                  ptn={accountPtns[0] || accountExtensions[0]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 4,
          background: '#555',
          visibility: 'hidden',
        }}
      />
    </IvrNodeWrap>
  );

  return (
    <>
      {showDialog && (
        <IncomingDialog {...props} onClose={e => setShowDialog(null)} />
      )}
      <IvrNodeWrap
        pref={boxRef}
        // borderColor="#5E35B1"
        position={data?.position}
        data={data}
      >
        <Box
          sx={{ padding: 1 }}
          // onClick={e => setShowDialog(true)}
        >
          <div style={{ textAlign: 'center' }}>
            <PhoneIcon size="small" />
          </div>
          <div>
            {
              accountPtns.length ? (
                <div>
                  {accountPtns.map(ptn => (
                    <div key={ptn}>
                      <span className={'text-lg font-medium'}>
                        <PhoneNumberDisplay width="auto" ptn={ptn} />
                      </span>
                    </div>
                  ))}
                </div>
              ) : null
              //   (
              // <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
              //   No Direct Numbers
              // </Typography>
              // )
            }
          </div>
          <div>
            {
              accountExtensions.length ? (
                <div>
                  {accountExtensions.map(ext => (
                    <div key={ext}>
                      <PhoneNumberDisplay
                        width="auto"
                        size="small"
                        ptn={ext}
                        allowExtension
                      />
                    </div>
                  ))}
                </div>
              ) : null
              // (
              // <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
              //   No Extensions
              // </Typography>
              // )
            }
          </div>
        </Box>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </IvrNodeWrap>
    </>
  );
});

const IncomingTemplateNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div onClick={e => setShowDialog(true)}>
          <div style={{ textAlign: 'center' }}>
            <ArrowDownwardIcon size="small" />
            <br />
            <Typography variant="body2">Template Input</Typography>
          </div>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 4,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </div>
      </div>
    </>
  );
});

const IncomingDialog = props => {
  const { data, onClose } = props;
  const { callflow, setCallflow, modifyPath } = data;

  const [editingCallflow, setEditingCallflow] = useState(cloneDeep(callflow));

  const handleSave = () => {
    setCallflow({ ...editingCallflow });
    onClose(); // already done for some reason when setCallflow?
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Incoming Numbers</DialogTitle>
      <Divider />
      <DialogContent>
        <CallflowNumbers
          neverCollapse
          strategyCallflow={editingCallflow}
          setStrategyCallflow={setEditingCallflow}
          modifyPath={modifyPath}
        />
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        // errors={errors}
        saveLabel="Update"
        onSave={handleSave}
        onCancel={onClose}
      />
    </Dialog>
  );
};

const IncomingQuickDisplayNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div onClick={e => setShowDialog(true)}>
          <div className={`flex space-x-2 text-neutral-60 items-center`}>
            <div>
              <PhoneIncome width={14} height={14} />
            </div>
            <span className={`font-medium  text-sm`}>Incoming call</span>
          </div>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 4,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </div>
      </div>
    </>
  );
});

const IncomingCustomInlineDisplayNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div onClick={e => setShowDialog(true)}>
          <div style={{ textAlign: 'center' }}>
            <ArrowDownwardIcon size="small" />
            <br />
            <Typography variant="body2">Call from previous</Typography>
          </div>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 4,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </div>
      </div>
    </>
  );
});

export default IncomingNode;

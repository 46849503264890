import { TrialButton } from 'app/components/Header/Header';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import React from 'react';
import { Paper, Box, Typography, Grid, Chip, Link } from 'app/design';
import {
  Alert,
  Badge,
  Button,
  Setting,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';
// import { useStripeCustomerQuery } from 'app/hooks/queries/stripe';
import AddCardIcon from '@mui/icons-material/AddCard';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import { useCreditCardAddDialog } from 'app/components/CreditCardAddDialog';
import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import {
  Plus as AddIcon,
  MoreHoriz as OptionsIcon,
  WarningTriangle,
} from 'iconoir-react';
import SimpleBar from 'simplebar-react';
import { cardIsExpired } from '../../../../../../components/Sidebar2/components/SidebarSettings/SidebarSettings';
function Cards({ customer, cards, refetch }) {
  const {
    toggleOpen: toggleOpenCreditCardAddDialog,
    Dialog: CreditCardAddDialog,
    DialogProps: CreditCardAddDialogProps,
  } = useCreditCardAddDialog({ initialOpen: false });

  // console.log('cards:', cards);

  const handleCardAdded = () => {
    refetch();
    toggleOpenCreditCardAddDialog();
  };

  const paymentMethodCards =
    cards?.data?.filter(c => c.object === 'card') ?? [];
  const paymentMethodOther =
    cards?.data?.filter(c => c.object !== 'card') ?? [];

  return (
    <>
      <CreditCardAddDialog
        {...CreditCardAddDialogProps}
        formDefaults={{ setDefault: !paymentMethodCards?.length }}
        onComplete={handleCardAdded}
      />
      <SettingsPanel
        leftChild={
          <Setting
            label={'Payment methods'}
            iconButtonProps={{
              children: <AddIcon fr={undefined} />,
              onClick: toggleOpenCreditCardAddDialog,
              color: 'accent',
              pill: true,
              variant: 'fill',
            }}
            body={'Manage cards on file.'}
          />
        }
        rightChild={
          <div
            className={`flex w-full space-y-2 justify-center flex-col ${
              paymentMethodCards.length ? 'items-end' : ''
            }`}
          >
            {paymentMethodCards.length ? (
              // <SimpleBar style={{ height: '100%' }}>
              <Table>
                {paymentMethodCards.map((card, index) => {
                  return (
                    <Card
                      key={card.id}
                      customer={customer}
                      card={card}
                      refetch={refetch}
                      showBorder={
                        paymentMethodCards.length > 1 &&
                        index < paymentMethodCards.length - 1
                      }
                    />
                  );
                })}
              </Table>
            ) : (
              <Alert
                label={'No cards on file'}
                body={'No cards could be found associated with your account.'}
                color={'attention'}
                icon={<WarningTriangle fr={undefined} />}
                reverse
                actionProps={{
                  children: 'Add payment method',
                  onClick: toggleOpenCreditCardAddDialog,
                }}
              />
            )}
            {/* <Button
              size={'sm'}
              color={'accent'}
              onClick={toggleOpenCreditCardAddDialog}
              startIcon={<AddIcon fr={undefined} />}
            >
              Add payment method
            </Button>*/}
          </div>
        }
      />
    </>
  );

  // return (
  //   <div>
  //     <CreditCardAddDialog
  //       {...CreditCardAddDialogProps}
  //       formDefaults={{ setDefault: !paymentMethodCards?.length }}
  //       onComplete={handleCardAdded}
  //     />
  //
  //     <span className={'text-lg font-bold'}>
  //       Cards &nbsp;
  //       {paymentMethodCards.length ? (
  //         <Link variant="body2" onClick={toggleOpenCreditCardAddDialog}>
  //           Add a Backup Credit Card
  //         </Link>
  //       ) : null}
  //     </span>
  //
  //     {!paymentMethodCards.length ? (
  //       <div>
  //         <div className={'text-content-negative italic mb-2'}>
  //           You do not have any payment methods on file
  //         </div>
  //         <Button
  //           startIcon={<AddCardIcon />}
  //           variant="outline"
  //           color={'accent'}
  //           onClick={toggleOpenCreditCardAddDialog}
  //         >
  //           Add a Credit Card
  //         </Button>
  //       </div>
  //     ) : (
  //       <div>
  //         {paymentMethodCards.map(card => {
  //           return <Card customer={customer} card={card} refetch={refetch} />;
  //         })}
  //       </div>
  //     )}
  //   </div>
  // );
}

const Card = ({ customer, card, refetch, showBorder }) => {
  const removeCreditCard = useAccountStripeCustomerMutate();
  const setDefaultCreditCard = useAccountStripeCustomerMutate();

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  const handleRemove = async () => {
    toggleBackdrop();
    await removeCreditCard.mutateAsync({
      data: {
        action: 'removeCard',
        data: {
          source: card.id,
        },
      },
    });
    // window.location.reload();
    refetch();
    toggleBackdrop();
  };

  const handleSetDefault = async () => {
    toggleBackdrop();
    await setDefaultCreditCard.mutateAsync({
      data: {
        action: 'setDefaultCard',
        data: {
          source: card.id,
        },
      },
    });
    // window.location.reload();
    refetch();
    toggleBackdrop();
  };

  // // TODO: show expired, problems, etc.
  // console.log('Card:', card);
  const menuItems = [
    {
      label: 'Remove',
      onClick: handleRemove,
    },
  ];
  if (customer.default_source !== card.id) {
    menuItems.unshift({
      label: 'Set as default',
      onClick: handleSetDefault,
    });
  }
  return (
    <>
      <TableRow showBorder={showBorder}>
        <TableCell
          className={`font-medium`}
          flex
        >{`${card.brand} ending in *${card.last4}`}</TableCell>
        <TableCell>
          {cardIsExpired(card) ? (
            <Badge variant={'fill'} reverse color={'negative'}>
              EXPIRED
            </Badge>
          ) : null}
        </TableCell>
        <TableCell>
          {customer.default_source === card.id ? 'Default' : null}
        </TableCell>
        <TableCell>
          <IconButtonDropdown
            align={'right'}
            variant={'ghost'}
            size={'sm'}
            children={<OptionsIcon fr={undefined} />}
            hideArrow
            menuItems={menuItems}
          />
        </TableCell>
      </TableRow>
    </>
  );

  return (
    <>
      <CustomBackdrop {...CustomBackdropProps} />
      <Paper
        variant={customer.default_source === card.id ? undefined : 'outlined'}
        sx={{ p: 1, mb: 1, maxWidth: '500px' }}
        elevation={2}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item md={5}>
            <div>
              {card.metadata.name ? (
                <Typography variant="h4" component="div">
                  {card.metadata.name}
                </Typography>
              ) : null}
              <Typography variant="h4" component="div">
                {card.brand}
              </Typography>
              <Typography variant="body2" component="div">
                *{card.last4}
              </Typography>
              <Typography variant="body2" component="div">
                expires {card.exp_month}/{card.exp_year.toString().substring(2)}
              </Typography>
            </div>
          </Grid>
          <Grid item md={4}>
            {customer.default_source === card.id ? (
              <Chip size="small" label="default" />
            ) : (
              <Button onClick={handleSetDefault}>Set as Default</Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={handleRemove} variant="outline" color="attention">
              Remove
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Cards;

const userQueryKeys = {
  all: ['user'] as const,
  list: (skip: number, take: number, orderBy: any, filters: any, mask: any) =>
    [...userQueryKeys.all, 'list', skip, take, orderBy, filters, mask] as const,
  listQuick: (
    skip: number,
    take: number,
    orderBy: any,
    filters: any,
    mask: any,
  ) =>
    [
      ...userQueryKeys.all,
      'list-quick',
      skip,
      take,
      orderBy,
      filters,
      mask,
    ] as const,
  byId: (id: string | null | undefined) => [...userQueryKeys.all, id] as const,
  // auth: (id: string) => [...userQueryKeys.all, 'auth', id] as const, reason to use unique?
};

export default userQueryKeys;

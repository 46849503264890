// @ts-ignore - this not being used?
import ring_user_go_to_vm from 'app/components/CallRoutingDialog/templates/ring_user_go_to_vm.json';
import { sdk } from 'app/sdk';

import { setAtPath } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React from 'react';

const setupUser = async ({ auth_token, userId, first_name, last_name }) => {
  const userResult = await sdk.user.mutate.updatePartial(
    { id: userId, first_name, last_name },
    auth_token,
  );
  console.log('userResult:', userResult);
  // if (!userResult.success) {
  //   console.error('user info error:', userResult.message, userResult.data);
  //   throw new Error('Failed saving user info');
  // }
};

export default setupUser;

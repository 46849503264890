import { Button, DialogActions } from 'app/design-lib';
import { ReactNode } from 'react';
import * as React from 'react';

interface QuickFinderDialogActionsProps {
  errorMessage?: ReactNode;
  multiple: boolean;
  allowSelectNone: boolean;
  onSelect: () => void;
  onCancel: () => void;
  onClear?: () => void;
  showComplete?: boolean;
  selectedIds: string[];
}

const QuickFinderDialogActions = ({
  errorMessage,
  multiple,
  allowSelectNone,
  onSelect,
  onCancel,
  onClear,
  selectedIds,
  showComplete,
}: QuickFinderDialogActionsProps) => {
  return (
    <>
      {/*<Divider />*/}
      <DialogActions>
        <div
          className={`w-full flex ${
            showComplete ? 'justify-end' : 'justify-between'
          }`}
        >
          {showComplete ? (
            <div>
              <Button
                size={'md'}
                onClick={onSelect}
                variant={'fill'}
                color={'accent'}
              >
                Done
              </Button>
            </div>
          ) : (
            <>
              <div>
                <Button
                  onClick={onCancel}
                  variant={'ghost'}
                  color={'neutral'}
                  size={'md'}
                >
                  Cancel
                </Button>
              </div>
              {errorMessage ? (
                <div className={'flex-1'}>
                  <div className={'grid place-items-center'}>
                    <span className={'text-content-negative'}>
                      {errorMessage}
                    </span>
                  </div>
                </div>
              ) : null}
              {onClear ? (
                <div>
                  <Button
                    onClick={onClear}
                    variant={'fill'}
                    size={'md'}
                    color={'attention'}
                  >
                    Clear Selection
                  </Button>
                </div>
              ) : null}
              {multiple ? (
                <div>
                  <Button
                    onClick={onSelect}
                    variant={'fill'}
                    color={'accent'}
                    size={'md'}
                    disabled={
                      !allowSelectNone && !selectedIds.length ? true : false
                    }
                  >
                    {allowSelectNone && !selectedIds.length ? (
                      <>Select: None</>
                    ) : (
                      <>
                        Select{' '}
                        {selectedIds.length > 0
                          ? `(${selectedIds.length})`
                          : ''}
                      </>
                    )}
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </DialogActions>
    </>
  );
};

export default QuickFinderDialogActions;

import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import { buildPatchDefault } from 'app/sdk/utils/kazoo';

///////////////
// TODO: jsDoc!
///////////////

const exports = {
  // Queries
  // - graphql
  query: {
    interactions: (vars, fetchOptions) =>
      fetchFromGraphql('interactions', vars, fetchOptions),
    // interactionById: (vars, fetchOptions) =>
    //   fetchFromGraphql('interactionById', vars, fetchOptions),
  },

  // Mutations
  // - via graphql, for transforming "strategy->flow"
  mutate: {},
};

export default exports;

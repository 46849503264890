import { AudioPlayer } from 'app/components/AudioPlayer';
import { Button, Listbox, SquareProgress, TextField } from 'app/design-lib';
import { ButtonProps } from 'app/design-lib/components/Button/Button';
import {
  useCreateMedia,
  useUpdateMediaFull,
  useUploadMedia,
} from 'app/hooks/mutations/media';
import { Mic as MicIcon, Pause, Play, Square } from 'iconoir-react';
import React, { useCallback, useEffect, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useDropzone } from 'react-dropzone';
import { MediaType, VoiceOptions } from 'types/media';
import { MediaSubType } from 'app/components/AudioEditor/AudioEditor';
import { store } from 'store';

interface UploadViewProps {
  onComplete: (mediaId: string | null) => Promise<void>;
  onCancel: () => void;
  subType: MediaSubType;
  mediaId?: string;
  mediaName?: string;
  isGreeting: boolean;
  loadingLabel?: string;
  setIsMutating: React.Dispatch<boolean>;
}

const TextToSpeechView = ({
  onComplete,
  onCancel,
  subType,
  mediaId,
  mediaName,
  isGreeting,
  loadingLabel,
  setIsMutating,
}: UploadViewProps) => {
  const [file, setFile] = useState<string | ArrayBuffer | null | undefined>(
    null,
  );
  const [buildingPreview, setBuildingPreview] = useState(false);
  const [text, setText] = useState('');
  const [name, setName] = useState(mediaName);
  const [voice, setVoice] = useState(VoiceOptions.femaleEnglishUS);
  const createMedia = useCreateMedia();
  const updateMediaFull = useUpdateMediaFull();
  const uploadMedia = useUploadMedia();
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [externalMutating, setExternalMutating] = useState(false);

  useEffect(() => {
    setIsMutating(isUploading || externalMutating);
  }, [isUploading]);

  const handleCreatePreview = async () => {
    setBuildingPreview(true);
    try {
      const serverAudioBlob = await getAudioBlob();

      // setFile(URL.createObjectURL(recordingBlob));
      var reader = new FileReader();
      reader.onloadend = function (evt) {
        const file = evt.target?.result;
        setFile(file);
        setBuildingPreview(false);
      };
      reader.onerror = function (err) {
        console.error('Reader Error:', err);
        setBuildingPreview(false);
        alert(
          'Sorry, there was a problem with audio, please try again or record the audio locally and upload as a file.',
        );
      };

      reader.readAsDataURL(
        new Blob([serverAudioBlob], {
          type: `audio/mp3`,
        }),
      );
    } catch (err) {
      setBuildingPreview(false);
    }
  };

  useEffect(() => {
    setFile(null);
  }, [text, voice]);

  const getAudioBlob = async () => {
    // get audio file from server
    const serverAudioResponse = await fetch(
      `${process.env.REACT_APP_CIO_API_SERVER}/api/v1/tts`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${store.getState().auth.auth_token}`,
        },
        body: JSON.stringify({
          text,
          voice: {
            // many options!
            //  - https://cloud.google.com/text-to-speech/docs/voices
            name:
              voice === VoiceOptions.maleEnglishUS
                ? 'en-US-Neural2-D'
                : 'en-US-Neural2-F',
          },
        }),
      },
    );
    console.log('serverAudioResponse', serverAudioResponse);
    const serverAudioBlob = await serverAudioResponse.blob();
    return serverAudioBlob;
  };

  const onUpload = async () => {
    setError('');
    setIsUploading(true);
    setExternalMutating(true);
    const payload = {
      id: mediaId,
      name,
      media_source: MediaType.Upload,
      upload_subtype: 'tts',
      tts: {
        text,
        voice,
      },
      language: 'en-us',
      sub_type: subType,
    };

    let newMedia: any;

    try {
      let mutation = isGreeting && mediaId ? updateMediaFull : createMedia;
      // @ts-ignore
      const resp = await mutation.mutateAsync(payload);
      if (resp.status !== 'success') {
        console.error({
          msg: 'Media creation/update failed, please try again.',
          resp,
        });
        setError('Media creation/update failed, please try again.');
        setExternalMutating(false);
        setIsUploading(false);
        return;
      }

      newMedia = resp.data;

      // Upload "actual" audio of tts (from google voice)

      // get audio file from server
      const serverAudioBlob = await getAudioBlob();

      const getMediaBase64 = async () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = async function (evt) {
            const base64String = reader.result; //.split(',')[1]; // just reader.result?
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsDataURL(serverAudioBlob);
        });
      };
      const base64file = await getMediaBase64();

      const mediaToUpload = {
        id: newMedia.id,
        encodedFile: base64file as string,
      };

      const uploadResp = await uploadMedia.mutateAsync(mediaToUpload);

      if (uploadResp.status !== 'success') {
        console.error({
          msg: 'Media upload failed, please try again.',
          resp,
        });
        setError('Media upload failed, please try again.');
        setExternalMutating(false);
        setIsUploading(false);
        return;
      }

      newMedia.updated_at = Date.now();
      newMedia.sub_type = subType;
      await updateMediaFull.mutateAsync(newMedia);

      setIsUploading(false);

      try {
        await onComplete(resp.data.id);
      } catch (e) {
        console.error(e);
        setError(
          'Media created but an error occurred when trying to assign it. Please select this media file in the library view and try again.',
        );
      }
    } catch (e) {
      setIsUploading(false);
      console.error('kazoo error', e);
      setError(
        'Error creating media. Please try again later and contact support if this issue persists.',
      );
    }
    setExternalMutating(false);
  };

  return (
    <div className={'flex'}>
      {isUploading || externalMutating ? (
        <div className={'w-full h-full grid place-items-center'}>
          <div className={'flex flex-col justify-center items-center'}>
            <SquareProgress />
            <span className={'text-md font-medium'}>
              {isUploading ? 'Uploading text-to-speech...' : loadingLabel}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={`${
            isUploading ? 'pointer-events-none opacity-50' : ''
          } flex flex-col w-full space-y-2`}
        >
          <TextField
            label={'Text'}
            multiline
            placeholder={subType.includes('greeting') ? 'Greeting' : 'Text'}
            value={text}
            onChange={e => setText(e.target.value)}
          />
          <div className={'flex flex-col sm:flex-row sm:space-x-2'}>
            {isGreeting ? null : (
              <TextField
                label={'Media name'}
                helperText="This will be available in your Library"
                placeholder={'Audio name'}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            )}
            <div className={'w-full sm:w-1/2'}>
              <Listbox
                className={`w-full`}
                label={'Voice'}
                options={[
                  {
                    value: VoiceOptions.femaleEnglishUS,
                    label: 'Female - English US',
                  },
                  {
                    value: VoiceOptions.maleEnglishUS,
                    label: 'Male - English US',
                  },
                ]}
                onChange={setVoice}
                value={voice}
              />
            </div>
            <div
              className={
                'w-full my-4 sm:my-0 sm:w-1/2 h-full sm:grid sm:place-items-center'
              }
            >
              {file ? (
                <AudioPlayer mediaId={file as string} autoPlay />
              ) : (
                <Button
                  size={'md'}
                  color={`accent`}
                  variant={`outline`}
                  onClick={handleCreatePreview}
                  disabled={buildingPreview}
                >
                  Preview
                </Button>
              )}
            </div>
          </div>
          {text ? (
            <>
              <div className={`flex mt-2 space-x-2 items-center`}>
                <Button
                  size={'md'}
                  color={`accent`}
                  variant={`fill`}
                  onClick={onUpload}
                  disabled={!text || !name}
                >
                  Upload & apply
                </Button>
                <Button
                  size={'md'}
                  color={`neutral`}
                  variant={`ghost`}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : null}
          {error ? (
            <span className={`text-negative-60 font-medium text-md`}>
              {error}
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default TextToSpeechView;

import React, { ReactNode } from 'react';

export interface TableCellProps {
  children: ReactNode;
  flex?: boolean;
  className?: string;
}

export const TableCell = ({
  children,
  flex = false,
  className = '',
}: TableCellProps) => {
  return (
    <td className={` ${className} py-2  px-4 ${flex ? 'flex-1' : ''}`}>
      {children}
    </td>
  );
};

export default TableCell;

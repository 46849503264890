import { gql } from 'graphql-request';

export default gql`
  query stripeCustomer {
    stripeCustomer {
      success
      data
    }
  }
`;

import { mutateGraphql, fetchFromGraphql } from 'app/sdk/utils/graphql';
import { buildGetDefault, buildPatchDefault } from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    read: (vars, fetchOptions) =>
      fetchFromGraphql('keyValues', vars, fetchOptions),
  },

  // Mutations
  mutate: {
    write: (vars, fetchOptions) =>
      mutateGraphql('keyValueWrite', vars, fetchOptions),
  },
};

export default exports;

import { Tab } from '@headlessui/react';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  TextField,
} from 'app/design-lib';
import { useUpdateMediaFull } from 'app/hooks/mutations/media';
import { useCreateMedia } from 'app/hooks/mutations/media/useCreateMedia';
import { useUploadMedia } from 'app/hooks/mutations/media/useUploadMedia';
import { useListMediasQuery } from 'app/hooks/queries/media';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';
import { useToggleReducer } from 'app/utilities';
import { startCase } from 'lodash';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { MediaDoc, MediaType } from 'types/media';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useTioMutation, useTioQuery } from 'app/pages/v2/common/sdk';

interface ContentProps {
  brand: any;
  onComplete: () => void;
  onCancel: () => void;
  brandsQuery: any;
}

interface MfaCampaignRegistryDialogProps
  extends ContentProps,
    Pick<DialogProps, 'open'> {
  noWrapper?: boolean;
}

const MfaCampaignRegistryDialog = ({
  noWrapper,
  open,
  ...props
}: MfaCampaignRegistryDialogProps) => {
  if (noWrapper) return <MfaCampaignRegistryDialogContent {...props} />;

  return (
    <Dialog size={'md'} onClose={props.onCancel} open={open}>
      <MfaCampaignRegistryDialogContent {...props} />
    </Dialog>
  );
};

const MfaCampaignRegistryDialogContent = ({
  onComplete,
  onCancel,
  brand,
  brandsQuery,
}: ContentProps) => {
  const brand_id = brand.brandId;

  const [verifyStatus, setVerifyStatus] = useState('');

  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();
  const queryClient = useQueryClient();
  // const two_factor_numbers = useTioQuery(`/mfa/phone_numbers`);
  const sendpin_2fa_number = useTioMutation();
  const verify_2fa_number = useTioMutation();
  const set_pft = useTioMutation();
  const formMethods = useForm({
    defaultValues: {
      brand_id: brand.brandId,
      requested: false,
      pin: '',
    },
  });
  const { register, handleSubmit, control, getValues, setValue, watch } =
    formMethods;

  const handleRequestPin = async () => {
    // @ts-ignore
    await sendpin_2fa_number.mutateAsync({
      method: 'POST',
      url: `/messaging/brands/${brand_id}/sms_otp/send`,
      data: {},
    });
    // @ts-ignore
    setValue('requested', true);
    brandsQuery?.refetch();
    // two_factor_numbers.refetch();
  };

  const handleVerifyNumber = async () => {
    setVerifyStatus('');
    // TODO: should return result and original response (to get status/code/headers!)
    // @ts-ignore
    const result = await verify_2fa_number.mutateAsync({
      method: 'POST',
      url: `/messaging/brands/${brand_id}/sms_otp/verify  `,
      data: {
        // @ts-ignore
        otpPin: watch('pin'),
      },
    });
    console.log('VERIFY:', result);

    if (result?.length) {
      setVerifyStatus('error');
      // for (let item of result) {
      //   switch (item.code) {
      //     case 501:
      //       for (let field of item.fields) {
      //         setError(field, {
      //           type: 'custom',
      //           message: item.description,
      //         });
      //       }
      //       break;

      //     default:
      //       console.error('Invalid item error code:', item);
      //       generalErrors.push({ description: item.description });
      //       break;
      //   }
      // }
    } else {
      setVerifyStatus('success');
    }

    brandsQuery?.refetch();
    // two_factor_numbers.refetch();
  };

  return (
    <>
      <div>
        <DialogTitle>Verify Sole Proprietor Phone Number</DialogTitle>
        <div className="p-4">
          <div className="">
            Verify your mobile number below. Clicking "Request PIN" will send a
            code to your phone:
          </div>
          <div className="my-4 font-bold">
            <PhoneNumberDisplay ptn={brand.mobilePhone} hideFlag />
          </div>

          {watch('brand_id') ? (
            <>
              <Button
                color={'accent'}
                size={'md'}
                // variant={'outline'}
                // className={'-mb-2'}
                onClick={handleRequestPin}
                // startIcon={<PersonAddIcon />}
                disabled={sendpin_2fa_number.isLoading}
              >
                {sendpin_2fa_number.isLoading ? 'Requesting...' : 'Request PIN'}
              </Button>

              <div
                className={[
                  'flex items-center space-x-2 mt-8 transition-all',
                  watch('requested') ? '' : 'opacity-50',
                ].join(' ')}
              >
                <div>
                  <TextField
                    // label="PIN"
                    placeholder="Enter PIN Here"
                    disabled={!watch('requested')}
                    // @ts-ignore
                    {...register(`pin`)}
                    // size="small"
                    // fullWidth
                    // hasError={!!formErrors.users?.[index]?.info?.last_name}
                    // helperText={formErrors.users?.[index]?.info?.last_name?.message}
                    // disabled={disabled}
                  />
                </div>
                <div>
                  <Button
                    color={'accent'}
                    size={'md'}
                    // variant={'fill'}
                    // className={'-mb-2'}
                    onClick={handleVerifyNumber}
                    // startIcon={<PersonAddIcon />}
                    disabled={
                      !watch('requested') || verify_2fa_number.isLoading
                    }
                  >
                    {verify_2fa_number.isLoading ? 'Verifying...' : 'Verify'}
                  </Button>
                </div>
              </div>

              {verifyStatus === 'success' ? (
                <div className="mt-4">
                  <Button
                    color={'positive'}
                    size={'md'}
                    // variant={'fill'}
                    className={'block'}
                    onClick={onComplete}
                  >
                    Done!
                  </Button>
                </div>
              ) : verifyStatus === 'error' ? (
                <div className="my-4 p-2 rounded text-negative-70 bg-negative-10">
                  Failed verifing using that PIN. Please try again.
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MfaCampaignRegistryDialog;

import {
  AnimationDialog,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableCell,
  TableRow,
  Typography,
} from 'app/design';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { MediaDialog } from 'app/components/MediaDialog';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AxiosError } from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { User } from 'types/user';
import { useImmer } from 'use-immer';

interface HoldMusicWrapperProps {
  open: boolean;
  selectedRows?: User[];
  onComplete: () => void;
  onCancel: () => void;
}

interface HoldMusicProps extends HoldMusicWrapperProps {
  mediaId: string | null;
}

const HoldMusicBulkUsersWrapper = (props: HoldMusicWrapperProps) => {
  return (
    <AnimationDialog fullWidth maxWidth={'md'} open={props.open}>
      <HoldMusicBulkUsers {...props} />
    </AnimationDialog>
  );
};

const HoldMusicBulkUsersContent = ({
  mediaId,
  selectedRows: selectedUsers,
  onComplete,
  onCancel,
}: HoldMusicProps) => {
  const updateUser = useUpdateUserPartial();
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const { isLoading } = updateUser;
  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
    }[]
  >(() => selectedUsers?.map(user => ({ status: null })) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  useEffect(() => {
    setMutationArray(selectedUsers?.map(user => ({ status: null })) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  if (!selectedUsers)
    return <Typography variant={'error'}>No users selected!</Typography>;

  const handleSuspend = async () => {
    for (let i = 0; i < selectedUsers.length; i++) {
      if (error) break;

      const user = selectedUsers[i];

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      await updateUser.mutateAsync(
        {
          id: user.id,
          music_on_hold: {
            media_id: mediaId,
          },
        },
        {
          onSuccess: () => {
            setMutationArray(prevState => {
              prevState[i].status = 'success';
            });
          },
          onError: error => {
            setMutationArray(prevState => {
              prevState[i].status = 'error';
            });
            setError(error);
          },
        },
      );
    }
  };

  const handleDone = () => {
    onComplete();
    updateUser.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <DialogTitle>Update User(s) Hold Music:</DialogTitle>
          <Divider />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {updateUser.isSuccess
            ? `Successfully updated user(s) hold music:`
            : updateUser.isLoading
            ? `Updating user(s)...`
            : error
            ? `Error updating user(s) hold music:`
            : `Are you sure you want to update ${selectedUsers.length} user(s) hold music?`}
        </Typography>
        <br />
        <div>
          <Table>
            {selectedUsers.map((user, index) => (
              <TableRow sx={{ height: 65 }}>
                <TableCell>
                  {mutationArray[index].status === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : mutationArray[index].status === 'success' ? (
                    <CheckIcon color={'success'} />
                  ) : mutationArray[index].status === 'error' ? (
                    <ClearIcon color={'error'} />
                  ) : null}
                </TableCell>
                <TableCell>
                  <Typography>
                    {`${user.doc.first_name} ${user.doc.last_name}`}
                  </Typography>
                </TableCell>
                <TableCell>
                  {user.doc.priv_level === 'admin' ? (
                    <Chip label={'Admin'} color={'primary'} size={'small'} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </DialogContent>
      <Divider />
      {!isLoading ? (
        <DefaultDialogActions
          saveLabel={updateUser.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : updateUser.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error
              ? undefined
              : updateUser.isSuccess
              ? handleDone
              : handleSuspend
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

const HoldMusicBulkUsers = (props: HoldMusicWrapperProps) => {
  const { selectedRows: selectedUsers, onCancel, open } = props;
  const [mediaId, setMediaId] = useImmer<string | null | undefined>(undefined);

  return (
    <>
      <MediaDialog
        // allow null so users can unset
        open={mediaId === undefined}
        noWrapper={true}
        mediaNameToSaveAs={'Bulk User Media'}
        type={'Bulk User Media'}
        onCancel={onCancel}
        onComplete={setMediaId}
        saveLabel={'Select'}
      />
      {mediaId !== undefined ? (
        <HoldMusicBulkUsersContent
          {...props}
          mediaId={mediaId!}
          onCancel={() => setMediaId(undefined)}
        />
      ) : null}
    </>
  );
};

export default HoldMusicBulkUsersWrapper;

import {
  AnimationDialog,
  Chip,
  CircularProgress,
  DialogContent,
  // DialogTitle,
  Divider,
  Table,
  TableCell,
  TableRow,
  Typography,
} from 'app/design';
import { Dialog, DialogHeader, DialogTitle } from 'app/design-lib';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { AxiosError } from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { Device } from 'types/device';
import { User } from 'types/user';
import { useImmer } from 'use-immer';
import { Tooltip } from 'app/design';
import { Block as BlockIcon } from 'app/design/icons-material';

interface ChangeOwnerProps {
  selectedRows?: Device[];
  owner: User | null;
  onComplete: () => void;
  onCancel: () => void;
}

const ChangeOwnerBulkDevicesWrapper = (props: WrapperProps) => {
  return (
    <Dialog size={'2xl'} open={props.open} onClose={props.onCancel}>
      <ChangeOwnerBulkDevices {...props} />
    </Dialog>
  );
};

const ChangeOwnerBulkDevicesContent = ({
  selectedRows: selectedDevices,
  owner,
  onComplete,
  onCancel,
}: ChangeOwnerProps) => {
  const updateDevice = useUpdateDevicePartial();
  const { isLoading } = updateDevice;
  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
    }[]
  >(() => selectedDevices?.map(device => ({ status: null })) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  useEffect(() => {
    setMutationArray(selectedDevices?.map(device => ({ status: null })) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevices]);

  if (!selectedDevices)
    return <Typography variant={'error'}>No devices selected!</Typography>;

  const handleDelete = async () => {
    for (let i = 0; i < selectedDevices.length; i++) {
      if (error) break;

      const device = selectedDevices[i];

      // device not sip device? skip
      if (!(device.doc.device_type === 'sip_device')) {
        continue;
      }

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      try {
        const resp = await updateDevice.mutateAsync(
          { id: device.id, owner_id: owner ? owner.doc.id : null },
          // {
          //   onSuccess: () => {
          //     setMutationArray(prevState => {
          //       prevState[i].status = 'success';
          //     });
          //   },
          //   onError: error => {
          //     setMutationArray(prevState => {
          //       prevState[i].status = 'error';
          //     });
          //     setError(error);
          //   },
          // },
        );

        if (resp.status === 'success') {
          setMutationArray(prevState => {
            prevState[i].status = 'success';
          });
        }
      } catch (e: any) {
        setMutationArray(prevState => {
          prevState[i].status = 'error';
        });
        setError(e);
      }
    }
  };

  const handleDone = () => {
    onComplete();
    updateDevice.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <DialogHeader
            onClose={onCancel}
            title={
              owner
                ? `Change Device(s) Owner: ${owner.extra.fullName}`
                : 'Release to Device Pool'
            }
          />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {updateDevice.isSuccess
            ? owner
              ? `Successfully changed device(s) owner to ${owner.extra.fullName}:`
              : `Successfully released device(s):`
            : updateDevice.isLoading
            ? owner
              ? `Changing device(s) owner...`
              : `Releasing device(s)...`
            : error
            ? owner
              ? `Error changing device(s) owner:`
              : `Error releasing device(s)`
            : owner
            ? `Are you sure you want to change ${selectedDevices.length} device(s) owner to ${owner.extra.fullName}?`
            : `Are you sure you want to release these device(s) to the device pool?`}
        </Typography>
        <br />
        <div>
          <Table>
            {selectedDevices.map((device, index) => (
              <TableRow sx={{ height: 65 }}>
                {device.doc.device_type !== 'sip_device' ? (
                  <>
                    <Tooltip
                      arrow
                      placement={'left-end'}
                      title={`You cannot release Web/iOS phones to the device pool. This action will be skipped.`}
                    >
                      <TableCell>
                        <BlockIcon sx={{ color: 'text.secondary' }} />
                      </TableCell>
                    </Tooltip>
                    <TableCell>
                      <Typography
                        sx={{
                          color: 'text.secondary',
                        }}
                      >
                        {device.doc.name}
                      </Typography>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      {mutationArray[index].status === 'loading' ? (
                        <CircularProgress size={20} />
                      ) : mutationArray[index].status === 'success' ? (
                        <CheckIcon color={'success'} />
                      ) : mutationArray[index].status === 'error' ? (
                        <ClearIcon color={'error'} />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Typography>{device.doc.name}</Typography>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </Table>
        </div>
      </DialogContent>
      {!isLoading ? (
        <DefaultDialogActions
          saveLabel={updateDevice.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : updateDevice.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error
              ? undefined
              : updateDevice.isSuccess
              ? handleDone
              : handleDelete
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

interface WrapperProps {
  open: boolean;
  selectedRows?: Device[];
  onComplete: () => void;
  onCancel: () => void;
  unassign?: boolean;
}

const ChangeOwnerBulkDevices = (props: WrapperProps) => {
  const [user, setUser] = useImmer<User | null | undefined>(
    props.unassign ? undefined : null,
  );
  const { unassign, onCancel } = props;
  const handleSelect = (selected: any[]) => {
    const [selectedUser] = selected as User[];
    setUser(selectedUser);
  };

  return (
    <>
      {user || unassign || user === undefined ? (
        <ChangeOwnerBulkDevicesContent
          {...props}
          // @ts-ignore
          owner={user}
          onCancel={unassign ? onCancel : () => setUser(null)}
        />
      ) : null}
      {user === null && !unassign ? (
        <QuickFinderUserDialog
          open={true}
          contentOnly
          onSelect={handleSelect}
          onCancel={onCancel}
          exclude={
            props.selectedRows?.length === 1
              ? [{ id: props.selectedRows[0].doc.owner_id }]
              : undefined
          }
          passResourceOnSelect
        />
      ) : null}
    </>
  );
};

export default ChangeOwnerBulkDevicesWrapper;

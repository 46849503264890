import {
  createPluginFactory,
  Decorate,
  ToggleMarkPlugin,
} from '@udecode/plate-core';
import { NodeEntry, Range, Text } from 'slate';

// export const createCommentPlugin = () => ({
//   renderLeaf: getRenderLeaf(MARK_COMMENT),
//   decorate: getCommentDecorate(),
// });

export const MARK_COMMENT = 'comment';

const getCommentDecorate = (): Decorate => editor => {
  // const options = getPlatePluginOptions(editor, MARK_COMMENT);

  return ([node, path]: NodeEntry) => {
    const ranges: Range[] = [];

    if (Text.isText(node)) {
      const { text } = node;

      if (text.includes('//')) {
        const offset = text.indexOf('//');

        ranges.push({
          anchor: { path, offset },
          focus: { path, offset: text.length },
          // @ts-ignore
          [MARK_COMMENT]: true,
        });
      }
    }

    return ranges;
  };
};

/**
 * Enables support for value formatting
 */
export const createCommentPlugin = createPluginFactory<ToggleMarkPlugin>({
  key: MARK_COMMENT,
  isLeaf: true,
  handlers: {},
  decorate: getCommentDecorate(),
});

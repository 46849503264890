import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useHistory,
} from 'react-router-dom';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { useCustomBackdrop } from 'app/components/CustomBackdrop';

import { Emoji } from 'emoji-picker-react';
import { useCreateCallflow } from 'app/hooks/mutations/callflow';

import { usePipesQuery, usePipeCreate } from 'app/pages/v2/common/sdk';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useCreateVmbox } from 'app/hooks/mutations/vmbox';
import { useCreateMedia } from 'app/hooks/mutations/media/useCreateMedia';
import BuyNumbersButton from './components/BuyNumbersButton/BuyNumbersButton';

const Rooms = () => {
  const { data: user } = useAuthenticatedUserQuery();

  const pipesQuery = usePipesQuery({
    filters: {
      type: 'room',
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];

  // console.log('PIPES:', pipes);

  const history = useHistory();
  const pipeNew = usePipeNew();

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  const handleNewRoom = async () => {
    let users = {};
    if (user) {
      // @ts-ignore
      users[user.id] = { enabled: true };
    }

    const roomKey = prompt('Room (letters and numbers, no spaces):', '');
    if (!roomKey?.length) {
      return null;
    }

    // validate, make sure it doesnt have spaces or start with a symbol
    if (!/^[a-z0-9]*$/.test(roomKey)) {
      alert(
        'Invalid room key specified. Lowercase letters and numbers, no spaces!',
      );
      return null;
    }

    // return false;

    // TODO: validate that room is available!

    // const name = prompt('Group Name:', 'Sales');
    // if (!name?.length) {
    //   return null;
    // }

    // create:
    // - pipe
    // - call route
    // - vmbox

    const pipe = await pipeNew.mutateAsync({
      name: `${roomKey}`,
      emoji: '1f64c', // raised hands
      ext: roomKey,
      type: 'room',
      users,
    });

    pipesQuery.refetch();

    history.push(`/v2/settings/rooms/${pipe.id}`);
  };

  return (
    <>
      <CustomBackdrop
        {...CustomBackdropProps}
        // open={isFetching && !isLoading}
        label="Setting up room, please wait..."
      />
      <div
        className={`max-w-[500px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
      >
        <div className="overflow-hidden relative h-screen">
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            // onScrollY={handleScrollY}
          >
            <div>
              <div className="">
                <div className="text-2xl">Rooms</div>
                <div className="text-md mb-4 text-neutral-500">
                  Useful for internal conversations not related to a phone
                  number or extension.
                </div>
                <button onClick={handleNewRoom}>
                  {pipeNew.isLoading ? 'Adding...' : 'Add a Room'}
                </button>
              </div>
              <div className="p-4">
                <table className="w-full">
                  <tbody className="">
                    {pipes.map(pipe => (
                      <tr key={pipe.id}>
                        <td className="w-4">
                          <div className="px-1">
                            <Emoji
                              unified={pipe.metadata?.emoji || '1f937'}
                              size={25}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="text-sm">
                            <Link to={`/v2/settings/rooms/${pipe.id}`}>
                              {pipe.metadata.name?.length
                                ? pipe.metadata.name
                                : 'Unnamed'}
                            </Link>
                            <div>#{pipe.key}</div>
                          </div>
                        </td>
                        {/* <td>{pipe.type}</td> */}
                        <td>[Users...]</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {pipesQuery.isLoading ? <div>Loading lines...</div> : null}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

export const usePipeNew = () => {
  const queryClient = useQueryClient();
  const pipeCreate = usePipeCreate();
  const callflowCreate = useCreateCallflow();
  const createMedia = useCreateMedia();
  const vmboxCreate = useCreateVmbox();

  return useMutation(async (info: any) => {
    const ext = info.ext;

    /*
    info: {
      ext,
      name,
      emoji,
      type, // personal, shared, external
      users
    }
    */

    // create:
    // - pipe
    // - call route
    // - vmbox

    // pipe
    const pipe = await pipeCreate.mutateAsync({
      key: ext,
      metadata: {
        name: info.name,
        emoji: info.emoji || '1f937',
      },
      type: info.type,
      users: info.users || {},
    });

    // create things for non-rooms
    if (info.type !== 'room') {
      // create Greeting for voicemail
      // - TODO: use even nicer?
      // const mediaResp = await createMedia.mutateAsync({
      //   name: `VM Greeting: ${ext}`,
      //   // @ts-ignore
      //   media_source: 'tts',
      //   // @ts-ignore
      //   tts: {
      //     text: 'Sorry, we are unavailable. Please leave a message and we will return your call as soon as we are able.',
      //     // @ts-ignore
      //     voice: 'female/en-US',
      //   },
      //   language: 'en-us',
      // });

      // console.log('mediaResp:', mediaResp);
      // const media = mediaResp.data;

      // vmbox
      const vmboxResp = await vmboxCreate.mutateAsync({
        pin: undefined,
        require_pin: false,
        announcement_only: false,
        include_message_on_notify: true,
        include_transcription_on_notify: true,
        is_setup: true,
        is_voicemail_ff_rw_enabled: false,
        check_if_owner: true,
        delete_after_notify: false,
        save_after_notify: false,
        skip_envelope: false,
        skip_greeting: false,
        skip_instructions: false,
        transcribe: false,
        transcribe2: true,
        notify_email_addresses: [],
        oldest_message_first: false,
        // ...vmboxForm,
        // media: {
        //   unavailable: media.id,
        // },
        mailbox: ext,
        name: `VM: ${ext}`,
        // @ts-ignore
        pipe_id: pipe.id,
      });

      console.log('Vmbox Resp:', vmboxResp);

      const vmbox = vmboxResp.data;

      // call route
      // -
      const callflow = await callflowCreate.mutateAsync({
        numbers: [ext],
        strategy: {
          type: 'blank',
          data: {
            modules: [
              {
                data: {
                  data: {
                    id: pipe.id,
                  },
                  ring_type: 'line',
                  variables: {},
                },
                type: 'Ring',
                options: {
                  props: {
                    required: true,
                    defaultRingType: 'line',
                  },
                },
              },
              {
                type: 'VoicemailBox',
                data: {
                  id: vmbox.id,
                },
              },
            ],
          },
        },
      });
    }

    // console.log('pipeUpdateById mutationResponse:', mutationResponse);
    queryClient.invalidateQueries({
      queryKey: ['pipes'],
    });

    return pipe;
  });
};

export default Rooms;

import { Listbox, ListboxProps } from 'app/design-lib';
import { getAtPath } from 'app/utilities';
import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { MediaType } from 'types/media';

interface HookFormListboxProps
  extends Omit<ListboxProps, 'hasError' | 'onChange' | 'value'> {
  name: string;
  errorMessageParser?: (error: any) => string;

  // options?: RegisterOptions;
}

const HookFormListbox = ({
  name,
  label,
  errorMessageParser,
  options,
  helperText,
  ...props
}: HookFormListboxProps) => {
  const {
    formState: { errors: formErrors },
    control,
  } = useFormContext();

  const error = getAtPath(formErrors, name);

  const errorMessage = error
    ? errorMessageParser
      ? errorMessageParser(error)
      : error.message
    : undefined;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Listbox
          label={label}
          hasError={!!errorMessage}
          helperText={errorMessage ?? helperText}
          value={value}
          onChange={onChange}
          options={options}
          {...props}
        />
        /*<Select
        value={value}
        onChange={onChange}
        // defaultValue={voices[0].value}
        {...register(`${MediaType.Tts}.voice`, {
          // required,
        })}
      >
        {voices.map(voice => (
          <MenuItem key={voice.value} value={voice.value}>
            {voice.label}
          </MenuItem>
        ))}
      </Select>*/
      )}
    />
  );

  // return (
  //   <Listbox
  //     label={label}
  //     {...props}
  //     {...register(name, options)}
  //     hasError={!!errorMessage}
  //     helperText={errorMessage?.replace(`"${name}"`, startCase(name))}
  //   />
  // );
};

export default HookFormListbox;

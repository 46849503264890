import {
  parsePhoneNumber,
  parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js';

import { isString } from 'lodash';

export const parseCallflowNumbers = tmpNumbers => {
  // TODO: make sure we handle phone number parsing better...
  const numbers = tmpNumbers || [];
  // console.log('numbers:', numbers);
  const all_numbers =
    numbers?.filter(
      num => /^\+?\d+$/.test(num) && num.length < 13 && !num.startsWith('*'),
    ) ?? [];
  const extensions = all_numbers.filter(num => num.length < 12);
  const ptns = all_numbers.filter(num => num.length === 12);
  const ptnsParsed = ptns.map(ptn => parsePhoneNumberFromString(ptn));

  // const e164number = parsedNumber?.format('E.164');

  return { all_numbers, extensions, ptns, ptnsParsed };
};

export function getPhoneNumber(number = '') {
  try {
    let phone_number: string | PhoneNumber = '';
    let phoneNumber;
    if (isString(number) && number.startsWith('conference:')) {
      return 'conference-call';
    }
    if (!number.includes('+')) {
      if (number.length === 11 && `${parseInt(number, 10)}`.length === 11) {
        // make sure is a valid number to avoid parsing strings
        phone_number = parsePhoneNumber('+' + number);
        let phone_num = phone_number.formatInternational();
        let number_arr = phone_num.split(' ');
        phoneNumber =
          number_arr[0] +
          ' ' +
          number_arr[1] +
          '-' +
          number_arr[2] +
          '-' +
          number_arr[3];
        return phoneNumber;
      } else if (
        number.length === 10 &&
        `${parseInt(number, 10)}`.length === 10
      ) {
        phone_number = parsePhoneNumber('+1' + number);
        let phone_num = phone_number.formatInternational();
        let number_arr = phone_num.split(' ');
        phoneNumber =
          number_arr[0] +
          ' ' +
          number_arr[1] +
          '-' +
          number_arr[2] +
          '-' +
          number_arr[3];
        return phoneNumber;
      } else {
        return number;
      }
    } else {
      phone_number = parsePhoneNumber(number);
      let phone_num = phone_number.formatInternational();
      let number_arr = phone_num.split(' ');
      phoneNumber =
        number_arr[0] +
        ' ' +
        number_arr[1] +
        '-' +
        number_arr[2] +
        '-' +
        number_arr[3];
      return phoneNumber;
    }
  } catch (err) {
    // console.error('Failed parsing phone number', number);
    return 'unknown';
  }
}

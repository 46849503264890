// import { DialogBuyNumbers } from '../../Common/DialogBuyNumbers';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { QuickFinderPhoneNumberDialog } from 'app/components/QuickFinderPhoneNumberDialog';
import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  TextField,
  Typography,
} from 'app/design';
import { List as ListIcon } from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';
import { get, orderBy, set, toNumber, uniq } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useExtensionUsedByQuery } from '../../hooks/general/useExtensionUseQuery';
import ExtensionInUseWarnings from '../ExtensionInUseWarnings/ExtensionInUseWarnings';

import { BuyNumbersButton } from './components/BuyNumbersButton';

import useStyles from './styles';

// Select users from list of users
export const CallflowNumbers = props => {
  const {
    neverCollapse,
    strategyCallflow,
    setStrategyCallflow,
    modifyPath = '', // can be nothing!
    setIsValidating,
  } = props;

  // const { phone_numbers, extensions: fullExtensions } = useSelector(
  //   preparePhoneNumberState,
  // );

  // // phone number list
  // const { refetch, isLoading, isFetching, dataUpdatedAt, data } =
  //   useListPhoneNumbersQuery(
  //     0, // skip
  //     1000, // take
  //     [], // order by
  //     // { searchInput: querySearchInput }, // filters
  //   );
  // const { phoneNumbers, totalCount = 0 } = data ?? {};
  // console.log('Phone Numbers:', phoneNumbers);

  const phone_numbers = [];
  const fullExtensions = [];

  const extensions = fullExtensions.map(ext => ext.extension);

  const [showDialog, setShowDialog] = useState(false);
  const [showChooseDialog, setShowChooseDialog] = useState(false);

  const numbers = getAtPath(strategyCallflow, `${modifyPath}.numbers`, []);

  // store initial to not display warnings
  const [initialExts] = useState(
    getAtPath(strategyCallflow, `${modifyPath}.numbers`, []).filter(
      num => num.length < 12,
    ),
  );

  const [isExpanded, setIsExpanded] = useState(
    neverCollapse ? true : numbers?.length ? false : true,
  );

  const classes = useStyles({
    // type
  });

  const possibleRef = useRef(null);
  const currentRef = useRef(null);

  const [newExtension, setNewExtension] = useState('');

  const updateBaseNumbers = useCallback(
    numbers => {
      // console.log('updating numbers', numbers);
      setAtPath(strategyCallflow, `${modifyPath}.numbers`, numbers);
      setStrategyCallflow({ ...strategyCallflow });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [strategyCallflow, setStrategyCallflow],
  );

  const handleAddExtension = () => {
    if (!newExtension) return;
    const newNumbers = [...numbers, newExtension];
    updateBaseNumbers(newNumbers);
    setNewExtension('');
  };

  const possibleNumbers =
    phone_numbers?.filter(num => !numbers?.includes(num.number)) ?? [];

  // console.log('possibleNumbers:', possibleNumbers, phone_numbers);

  const handleAddNumber = useCallback(() => {
    const number = possibleRef.current.value;
    // console.log('handleAddNumber:', number, possibleNumbers);
    // add to numbers_to_switch if already a used number
    if (possibleNumbers.find(num => num.number === number)?.listing?.used_by) {
      let numbers_to_switch = getAtPath(
        strategyCallflow,
        `${modifyPath}.numbers_to_switch`,
        [],
      );
      numbers_to_switch = uniq([...numbers_to_switch, number]);
      // console.log('numbers_to_switch:', numbers_to_switch);
      setAtPath(
        strategyCallflow,
        `${modifyPath}.numbers_to_switch`,
        numbers_to_switch,
      );
      setStrategyCallflow({ ...strategyCallflow });
    }
    // numbers_to_switch;
    const newNumbers = [...numbers, number];
    updateBaseNumbers(newNumbers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    possibleNumbers,
    numbers,
    updateBaseNumbers,
    set,
    get,
    setStrategyCallflow,
  ]);

  const handleRemoveNumber = useCallback(
    number => {
      // const number = currentRef.current.value;
      // console.log('number:', number);
      const newNumbers = [...numbers.filter(num => num !== number)];
      // console.log('newNumbers:', newNumbers);
      let numbers_to_switch = getAtPath(
        strategyCallflow,
        `${modifyPath}.numbers_to_switch`,
        [],
      );
      if (numbers_to_switch.length) {
        setAtPath(
          strategyCallflow,
          `${modifyPath}.numbers_to_switch`,
          numbers_to_switch.filter(num => num !== number),
        );
        setStrategyCallflow({ ...strategyCallflow });
      }
      updateBaseNumbers(newNumbers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [numbers, updateBaseNumbers, set, get, setStrategyCallflow],
  );

  // const handleChangeSelectAddPhoneNumber = e => {
  //   const number = e.target.value;
  //   if (possibleNumbers.find(num => num.number === number)?.listing?.used_by) {
  //     let numbers_to_switch = getAtPath(
  //       strategyCallflow,
  //       `${modifyPath}.numbers_to_switch`,
  //       [],
  //     );
  //     numbers_to_switch = uniq([...numbers_to_switch, number]);
  //     // console.log('numbers_to_switch:', numbers_to_switch);
  //     setAtPath(
  //       strategyCallflow,
  //       `${modifyPath}.numbers_to_switch`,
  //       numbers_to_switch,
  //     );
  //     setStrategyCallflow({ ...strategyCallflow });
  //   }
  //   // numbers_to_switch;
  //   const newNumbers = [...numbers, number];
  //   updateBaseNumbers(newNumbers);
  // };

  const stratTmps = numbers?.filter(num => num.includes('strategy')) ?? [];
  const currentNumbers =
    numbers?.filter(num => !num.includes('strategy')) ?? [];
  const currentPhoneNumbers = currentNumbers.filter(num => num.length === 12);
  const currentExtensions = currentNumbers.filter(num => num.length < 12);
  // console.log('conflict cur', currentExtensions);
  const extensionsNotInCallflow = extensions.filter(
    ext => !currentExtensions.includes(ext),
  );
  // console.log('numbers:', numbers, possibleNumbers);

  const Wrapper = props.wrapper;

  const allExtensions = [...currentExtensions, ...extensions];
  let nextDefaultExt = allExtensions.length
    ? (toNumber(allExtensions.sort().reverse()[0]) + 1).toString()
    : '1001';

  const handleSelect = selectedNumbers => {
    const newNumbers = [...stratTmps, ...selectedNumbers, ...allExtensions];
    updateBaseNumbers(newNumbers);
    setShowChooseDialog(false);
  };

  const extUsedByQuery = useExtensionUsedByQuery({
    exts: currentExtensions.filter(ext => !initialExts.includes(ext)),
  });

  useEffect(() => {
    setIsValidating(extUsedByQuery.isFetching);
  }, [extUsedByQuery.isFetching]);

  return (
    <>
      <QuickFinderPhoneNumberDialog
        open={showChooseDialog}
        onSelect={handleSelect}
        mutationLoading={false}
        mutationLoadingLabel={'Choosing Phone Number...'}
        onCancel={e => setShowChooseDialog(null)}
        multiple
        allowSelectNone
        initialSelected={currentPhoneNumbers} //{['+16505497794', '+16505573959']}
        // TODO: remove - for demo purposes
        // exclude={[
        //   {
        //     id: '69890c0cf6da793da9c7d8ac5557c3d5',
        //     checked: false,
        //     reason: 'This device is not available.',
        //   },
        //   {
        //     id: 'beda688813d786e8324940ed59c66f53',
        //     checked: true,
        //     reason: 'This device is used in another tier',
        //   },
        //   {
        //     id: '33fbe5302d205a3d519509aa35af1380',
        //     indeterminate: true,
        //     reason: 'This device is disabled. Please enable to select.',
        //   },
        // ]}
      />

      <div>
        <div>
          {!isExpanded ? (
            <>
              <div>
                {!currentPhoneNumbers.length && !currentExtensions.length && (
                  <Chip
                    label="No numbers selected"
                    color="default"
                    size="small"
                    variant="outlined"
                    sx={classes.chip}
                  />
                )}
                {currentPhoneNumbers.length
                  ? currentPhoneNumbers.map(num => (
                      <Typography variant="h6" key={num}>
                        <PhoneNumberDisplay
                          // key={num}
                          size="medium"
                          ptn={num}
                        />
                      </Typography>
                    ))
                  : null}
                {currentExtensions.length ? (
                  <div>
                    <Typography variant="body2" gutterBottom>
                      {/* <Box
                        style={{ display: 'inline-block', fontWeight: 'bold' }}
                      >
                        Internal Extensions:{' '}
                      </Box> */}
                      {currentExtensions.map(num => (
                        <Chip
                          key={num}
                          label={`${num}`}
                          // color="primary"
                          // size="small"
                          sx={classes.chip}
                        />
                      ))}
                    </Typography>
                  </div>
                ) : null}
              </div>
              {neverCollapse ? (
                ''
              ) : (
                <div>
                  <p>
                    <Link onClick={e => setIsExpanded(true)}>
                      Change Phone Numbers
                    </Link>
                  </p>
                </div>
              )}
            </>
          ) : null}
        </div>
        {isExpanded ? (
          <>
            <div style={{ padding: 0 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h2">Phone Numbers</Typography>
                  <Box sx={{ p: 2 }}>
                    <Box sx={classes.chipContainer}>
                      {currentPhoneNumbers.length ? (
                        currentPhoneNumbers.map(num => (
                          <Chip
                            key={num}
                            // label={getPhoneNumber(num)}
                            variant="outlined"
                            label={
                              <PhoneNumberDisplay
                                // key={num}
                                size="small"
                                ptn={num}
                              />
                            }
                            onDelete={e => handleRemoveNumber(num)}
                            // color="primary"
                            sx={classes.chip}
                          />
                        ))
                      ) : (
                        <Chip label="No Phone Numbers" variant="outlined" />
                      )}
                    </Box>

                    {/* <div style={{ paddingTop: 8 }}>
                        <Divider />
                      </div> */}
                    <br />

                    <Typography variant="h5" paragraph>
                      Add a Phone Number:
                    </Typography>

                    <Box sx={{}}>
                      <Button
                        // href="/"
                        variant="contained"
                        onClick={e => {
                          e.preventDefault();
                          setShowChooseDialog(true);
                        }}
                        startIcon={<ListIcon />}
                        fullWidth
                        // underline="hover"
                      >
                        Your Numbers
                      </Button>
                    </Box>

                    {/* <div style={{ paddingTop: 8 }}>
                        <Divider>or</Divider>
                      </div> */}

                    {/* <div>
                        <Select
                          value="select"
                          onChange={handleChangeSelectAddPhoneNumber}
                          variant="outlined"
                          margin="dense"
                          style={{ width: '100%' }}
                        >
                          <MenuItem disabled value="select">
                            Choose from existing numbers
                          </MenuItem>
                          {possibleNumbers.map(num => (
                            <MenuItem key={num.number} value={num.number}>
                              {getPhoneNumber(num.number)}{' '}
                              {num.listing?.used_by ||
                              numbers.includes(num.number)
                                ? '(in use)'
                                : '(AVAILABLE)'}
                            </MenuItem>
                          ))}
                        </Select>
                      </div> */}
                    <div>
                      <p>
                        <BuyNumbersButton
                          ButtonProps={{ fullWidth: true }}
                          onComplete={numberArr => {
                            const newNumbers = [
                              ...stratTmps,
                              ...currentNumbers,
                              ...numberArr,
                              // ...allExtensions, // not needed, duplicates ext
                            ];
                            updateBaseNumbers(newNumbers);
                          }}
                        />
                        {/* <Link
                            href="/"
                            onClick={e => {
                              e.preventDefault();
                              setShowDialog(true);
                            }}
                            sx={classes.buyNumber}
                            underline="hover"
                          >
                            Buy a New Number
                          </Link> */}
                      </p>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h2">Extensions</Typography>

                  <Box sx={{ p: 2 }}>
                    <Box sx={classes.chipContainer}>
                      {currentExtensions.length ? (
                        currentExtensions.map(num => (
                          <Chip
                            key={num}
                            label={num}
                            onDelete={e => handleRemoveNumber(num)}
                            // color="primary"
                            variant="outlined"
                            sx={classes.chip}
                          />
                        ))
                      ) : (
                        <Chip label="No Extensions" variant="outlined" />
                      )}
                    </Box>
                    <ExtensionInUseWarnings
                      query={extUsedByQuery}
                      config={{
                        userError: false,
                        userAppend: ': this may cause confusion',
                        vmboxError: false,
                        vmboxAppend: ': this may cause confusion',
                        extError: false,
                        extAppend: 'and will be removed from it',
                      }}
                    />
                    {/* <div style={{ paddingTop: 8 }}>
                      <Divider />
                    </div> */}
                    <br />

                    <Typography variant="h5" paragraph>
                      Add an Extension:
                    </Typography>
                    <div>
                      <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <TextField
                            value={newExtension}
                            variant="outlined"
                            margin="dense"
                            type={'number'}
                            placeholder="Ext Number"
                            onKeyDown={e => {
                              if (e.key === 'Enter') handleAddExtension();
                            }}
                            onChange={e =>
                              // limit input to 4 digits
                              setNewExtension(
                                e.target.value?.substring(
                                  e.target.value.length - 4,
                                ),
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        </Grid>
                        {/* <Grid item>
                          <Tooltip
                            title={
                              <>
                                <strong>Use the next default extension</strong>
                                <br />
                                Click this to add the next available extension
                                (highest extension plus one)
                              </>
                            }
                            placement="bottom"
                            type="light"
                            arrow
                          >
                            <IconButton
                              size="small"
                              variant="outlined"
                              onClick={e => {
                                setNewExtension(nextDefaultExt);
                                e.preventDefault();
                              }}
                            >
                              <PlusOneIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid> */}
                      </Grid>
                      <br />
                      {newExtension?.length ? (
                        <Button
                          onClick={handleAddExtension}
                          disableRipple
                          color="primary"
                          variant="outlined"
                          size="small"
                        >
                          Add Extension
                        </Button>
                      ) : (
                        ''
                      )}
                      {/* <div>
                          Extensions used by other callflows: &nbsp;
                          <select>
                            {extensionsNotInCallflow.map((num) => (
                              <option key={num}>{num}</option>
                            ))}
                          </select>
                        </div> */}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>

            {neverCollapse ? (
              ''
            ) : (
              <div>
                <p>
                  <Link onClick={e => setIsExpanded(false)}>
                    Collapse Phone Numbers and Extensions
                  </Link>
                </p>
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default CallflowNumbers;

import React from 'react';
import { GlobalStyles } from 'app/design';

export const globalStyles = (
  <GlobalStyles
    styles={{
      '#root': {
        height: '100vh',
      },
      '.tmp-tour-class': {
        maxWidth: '800px !important', // cannot use "auto !important" for some reason :( )
      },
      '.tmp-tour-hide-content': {
        opacity: '0 !important',
      },
      '.tmp-mask-class': {
        // opacity: '0.15 !important',
        opacity: '0.25 !important',
      },
    }}
  />
);

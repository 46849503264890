import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';
import { useSelector } from 'react-redux';

const selectSlice = (state: RootState) => state.webphone || initialState;

export const selectWebphone = createSelector([selectSlice], state => state);

export const useWebphoneSelector = () => useSelector(selectWebphone);

import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { PhoneNumber } from 'types/phoneNumber';
import { NameAndTypeCell } from 'app/components/ListAllCallflows/components/NameAndTypeCell';
import { CallRouteOwnerCell } from 'app/components/ListAllCallflows/components/CallRouteOwnerCell';
import { Callflow } from 'types/callflow';
import { Box } from 'app/design';
const QuickFinderPhoneNumberDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Phone Number',
        id: 'number',
        accessor: (phoneNumber: PhoneNumber) => phoneNumber.doc.id,
        Cell: ({ value: number }) => {
          // const { original: phoneNumber }: { original: PhoneNumber } = row;

          return <PhoneNumberDisplay width="auto" ptn={number} />;
        },
        disableToggle: true,
        headerProps: {
          width: 200,
        },
      },
      {
        Header: 'Owner',
        id: 'owner',
        accessor: (phoneNumber: PhoneNumber) => phoneNumber.Callflow,
        Cell: ({ value: callflow }: { value: Callflow }) => (
          <Box sx={{ width: 100 }}>
            <CallRouteOwnerCell callRoute={callflow} owner={callflow?.Owner} />
          </Box>
        ),
      },
      {
        Header: 'Used By',
        id: 'usedBy',
        Cell: ({ row }: { row: { original: PhoneNumber } }) => {
          const callflow = row.original.Callflow;

          if (!callflow) {
            return <EmptyTableCell label={'not in use'} />;
          }

          return (
            <Box sx={{ width: 150 }}>
              <NameAndTypeCell
                row={{
                  original: callflow,
                }}
                value={callflow?.doc.name}
                // onComplete={() => {}}
              />
            </Box>
          );
        },
      },
      // {
      //   Header: 'Internal Label',
      //   id: 'internalLabel',
      //   accessor: (phoneNumber: PhoneNumber) => phoneNumber.doc.name,
      //   Cell: ({ value: name }) => {
      //     return name ?? <EmptyTableCell label={'not set'} />;
      //   },
      //   disableToggle: true,
      //   disableSortBy: true,
      //   headerProps: {
      //     width: 220,
      //   },
      // },
      {
        Header: 'External Caller ID Name',
        id: 'oCID',
        accessor: (phoneNumber: PhoneNumber) =>
          phoneNumber.doc.cnam?.display_name,
        Cell: ({ value: name }: { value: string }) => {
          return name ? name : <EmptyTableCell label={'not set'} />;
        },
        disableToggle: true,
        headerProps: {
          width: 150,
        },
      },
      {
        Header: 'e911 Address',
        id: 'e911',
        accessor: (phoneNumber: PhoneNumber) =>
          phoneNumber.doc.e911?.street_address,
        Cell: ({ value: e911 }: { value: string }) => {
          return e911 ? e911 : <EmptyTableCell label={'not set'} />;
        },
        disableToggle: true,
        headerProps: {
          width: 150,
        },
      },
    ],
    [],
  );

  // phoneNumber list
  const phoneNumberListQuery = useListPhoneNumbersQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['number', 'asc']],
    filters: { searchInput: querySearchInput },
  });
  const { phoneNumbers, totalCount = 0 } = phoneNumberListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      phoneNumberListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Numbers' : 'Select Number'}
      // table
      getRowId={(row: PhoneNumber) => row.id}
      searchPlaceholder={'Search numbers...'}
      totalCount={totalCount}
      columns={columns}
      data={phoneNumbers ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={phoneNumberListQuery.isFetching}
      queryIsLoading={phoneNumberListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderPhoneNumberDialog;

import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    devices: (vars, fetchOptions) =>
      fetchFromGraphql('devices', vars, fetchOptions),

    // only the users doc
    devicesQuick: (vars, fetchOptions) =>
      fetchFromGraphql('devicesQuick', vars, fetchOptions, { full: true }),

    // Fetch a single device by id
    deviceById: (vars, fetchOptions) =>
      fetchFromGraphql('deviceById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    create: buildPutDefault('devices', { buildUrlOpts: { skipId: true } }),
    updatePartial: buildPatchDefault('devices'),
    delete: buildDeleteDefault('devices'),
  },
};

export default exports;

const processEnv = {
  REACT_APP_CIO_API_SERVER: process.env.REACT_APP_CIO_API_SERVER,
  REACT_APP_CIO_GRAPHQL_SERVER: process.env.REACT_APP_CIO_GRAPHQL_SERVER,
  REACT_APP_CIO_HOME: process.env.REACT_APP_CIO_HOME,
  REACT_APP_CIO_QR_CODE_SERVER: process.env.REACT_APP_CIO_QR_CODE_SERVER,
  REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_KAPI_URL: process.env.REACT_APP_KAPI_URL,
  REACT_APP_KAPI_URL_DIRECT: process.env.REACT_APP_KAPI_URL_DIRECT,
  REACT_APP_KCLUSTER_ID: process.env.REACT_APP_KCLUSTER_ID,
  REACT_APP_PROVISIONER_URL: process.env.REACT_APP_PROVISIONER_URL,
  REACT_APP_WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
  REACT_APP_WEBPHONE_SOCKET_URL: process.env.REACT_APP_WEBPHONE_SOCKET_URL,
  REACT_APP_STRIPE_PUBLISHABLE_KEY:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_JSSIP_DEBUG: process.env.REACT_APP_JSSIP_DEBUG,
  REACT_APP_JSSIP_CONFIG: process.env.REACT_APP_JSSIP_CONFIG,
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE: process.env.REACT_APP_DATADOG_SITE,
  REACT_APP_HOTJAR_SITE_ID: process.env.REACT_APP_HOTJAR_SITE_ID,
  REACT_APP_HEAP_ID: process.env.REACT_APP_HEAP_ID,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
};

export const buildEnv = () => {
  let tmpEnv: any = {
    _build: {
      // REACT_APP_*: {
      //   ls: localstorage,
      //   process: process.env.*,
      //   lsOverwrite: boolean
      // }
    },
    ...processEnv,
    // REACT_APP_*: final value
  };
  for (let k of Object.keys(processEnv)) {
    const lsVal = localStorage.getItem(k) ?? '';
    const processVal = processEnv[k];
    const lsOverwrite = lsVal.length > 0 ? true : false;
    const finalVal = lsOverwrite ? lsVal : processVal;
    tmpEnv[k] = finalVal;
    tmpEnv._build[k] = {
      ls: lsVal,
      process: processVal,
      lsOverwrite,
    };
  }
  return tmpEnv;
};

const env = buildEnv();

export default env;

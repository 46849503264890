import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';

const ContinueToCallflowNode = memo(({ data }) => {
  return (
    <div
      style={{
        width: 150,
        height: 50,
        border: '1px solid #ddd',
        borderRadius: 4,
      }}
    >
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
        onConnect={params => console.log('handle onConnect', params)}
      />
      <div
        style={{
          padding: 8,
          textAlign: 'center',
        }}
      >
        ContinueToCallflow
      </div>
      {/* <Handle
        type="source"
        position="bottom"
        // id="b"
        style={{ background: '#555' }}
      /> */}
    </div>
  );
});

export default ContinueToCallflowNode;

import { Tooltip } from 'app/design';
import { EmptyTableCell } from 'app/components/EmptyTableCell';
import * as React from 'react';
import { Media } from 'types/media';

interface MediaSourceCellProps {
  media: Media;
}

const CHAR_LIMIT = 40;

const TextToSpeechTableCell = ({ media }: MediaSourceCellProps) => {
  const tts = media?.doc?.tts?.text;

  if (!tts) {
    return <EmptyTableCell label={'none'} />;
  }

  if (tts.length <= CHAR_LIMIT) {
    return <div>{tts}</div>;
  }

  const words = tts.split(' ');
  let wordsToUse: string[] = [];
  let charCount = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    wordsToUse.push(word);
    charCount += word.length;
    if (charCount > CHAR_LIMIT) break;
  }

  return (
    <Tooltip title={<>{tts}</>} placement="bottom" arrow>
      <div>{`${wordsToUse.join(' ')}...`}</div>
    </Tooltip>
  );
};

export default TextToSpeechTableCell;

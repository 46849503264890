import React, { ReactNode } from 'react';

export interface TableRowProps {
  children: ReactNode;
  showBorder: boolean;
  className?: string;
}

export const TableRow = ({
  children,
  className = '',
  showBorder = false,
}: TableRowProps) => {
  return (
    <tr
      className={`${className} text-md ${
        showBorder ? 'border-b' : ''
      } first:flex-1 border-neutral-20 w-full min-h-10 whitespace-nowrap flex  items-center`}
    >
      {children}
    </tr>
  );
};

export default TableRow;

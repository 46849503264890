const className = 'bg-red-500 h-10 w-10';

export const TabContext = (props: any) => <div className={className}></div>;
export const TabList = (props: any) => <div className={className}></div>;
export const TabPanel = (props: any) => <div className={className}></div>;
export const DatePicker = (props: any) => <div className={className}></div>;
export const LoadingButton = (props: any) => <div className={className}></div>;
export const StaticTimePicker = (props: any) => (
  <div className={className}></div>
);
export const LocalizationProvider = (props: any) => (
  <div className={className}></div>
);
export const AdapterMoment = (props: any) => <div className={className}></div>;

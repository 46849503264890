import {
  BadgeProps,
  default as Badge,
} from 'app/design-lib/components/Badge/Badge';
import Button, { ButtonProps } from 'app/design-lib/components/Button/Button';
import { IconButton } from 'app/design-lib/components/IconButton';
import { IconButtonProps } from 'app/design-lib/components/IconButton/IconButton';
import { Color, Size } from 'app/design-lib/types';
import { Emoji } from 'emoji-picker-react';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

export type BadgeVariant = 'fill' | 'outline';

export interface NavItemProps {
  emoji?: string;
  label: ReactNode;
  body?: ReactNode;
  buttonProps?: Partial<ButtonProps>;
  iconButtonProps?: Partial<IconButtonProps>;
}

const Setting = ({
  emoji,
  label,
  body,
  buttonProps,
  iconButtonProps,
}: NavItemProps) => {
  return (
    <div className={'flex py-1.5 items-start'}>
      {emoji ? (
        <div className={'mt-0.5'}>
          <Emoji unified={emoji} size={16} />
        </div>
      ) : null}
      <div className={`flex flex-1 flex-col`}>
        <div className={'flex-1 flex justify-between items-center'}>
          <span className={'whitespace-nowrap text-md font-medium'}>
            {label}
          </span>
          {buttonProps ? (
            <Button
              variant={'ghost'}
              size={'sm'}
              color={'accent'}
              {...(buttonProps as any)}
            />
          ) : iconButtonProps ? (
            <IconButton
              children={<></>}
              size={'sm'}
              variant={'ghost'}
              color={'neutral'}
              {...iconButtonProps}
            />
          ) : null}
        </div>
        <div className={'text-sm text-neutral-60'}>{body}</div>
      </div>
    </div>
  );
};

const getIconSize = (size: Size) => {
  switch (size) {
    case 'sm':
      return `h-3.5 w-3.5`;
    case 'md':
      return `h-4.5 w-4.5`;
    case 'lg':
      return `h-5 w-5`;
  }
};

const OverlapStyles = (color: Color) => `
  bg-white 
  hover:bg-transparency-10 
  active:bg-transparency-20
  ${color === 'neutral' ? `active:text-${color}-90` : `active:text-${color}-80`}
  text-${color}-60 
  hover:text-${color}-70 
  
  disabled:text-neutral-20
`;

const ReverseOverlapStyles = (color: Color) => `
  bg-neutral-90 
  hover:bg-transparency-30
  active:bg-transparency-60
  text-${color}-30 
  hover:text-${color}-40 
  active:text-${color}-50 
  disabled:text-neutral-60
`;

const getVariantStyling = (
  variant: BadgeVariant,
  color: Color,
  reverse: boolean,
) => {
  if (reverse) {
    switch (variant) {
      case 'fill':
        return `
      border
      border-${color}-20
      hover:border-${color}-30 
      active:border-${color}-40
      bg-${color}-20
      hover:bg-${color}-30 
      active:bg-${color}-40
      disabled:bg-neutral-60
      text-${color}-70
      hover:text-${color}-80
      active:text-${color}-90
      disabled:text-neutral-30
      `;
      case 'outline':
        return `
      border
      border-${color}-60 
      hover:border-${color}-70 
      active:border-${color}-80 
      disabled:border-neutral-60 
      ${ReverseOverlapStyles(color)}    
      `;
    }
  }

  switch (variant) {
    case 'fill':
      return `
      border
      border-${color}-60 
      hover:border-${color}-70 
      active:border-${color}-80
      bg-${color}-60 
      hover:bg-${color}-70 
      active:bg-${color}-80
      text-white disabled:bg-neutral-20`;
    case 'outline':
      return `
      border
      border-${color}-30 
      hover:border-${color}-40 
      active:border-${color}-50
      disabled:border-neutral-20 
      ${OverlapStyles(color)}    
      `;
  }
};

const getSize = (size: Size) => {
  switch (size) {
    case 'sm':
      return `py-1 px-1 text-xs space-x-1 font-medium`;
    case 'md':
      return `py-1 px-2 text-sm space-x-1 font-medium`;
    case 'lg':
      return `py-1.5 px-2 text-md space-x-1.5 font-medium`;
  }
};
export default Setting;

import { buildResourceUrl } from './index';
import { del } from './methods';

// Default DELETE-building function
// - uses buildResourceUrl and passes thru options from opts.buildUrlOpts
export const buildDeleteDefault =
  (resourceApiPathname, opts) => async (id, auth_token) => {
    // uses a DELETE request to delete a user/device/etc.

    if (!id) {
      throw new Error('Missing id for resource to delete');
    }

    return deleteResourceDefault({
      url: buildResourceUrl(
        resourceApiPathname,
        id,
        auth_token,
        opts?.buildUrlOpts,
      ),
      auth_token,
    });
  };

export const deleteResourceDefault = async ({ url, auth_token }) => {
  const resp = await del(url, { auth_token });
  return await resp.data;
};

import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from 'app/design';
import { CallflowNumbers } from 'app/components/CallflowNumbers';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { OwnerInput } from 'app/components/OwnerInput';
import { useUpdateCallflowPartial } from 'app/hooks/mutations/callflow';
import { useCallflowQuery } from 'app/hooks/queries/callflow';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CallflowDoc } from 'types/callflow';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

import { NumberRouting } from 'app/components/CallRoutingDialog/CallRoutingDialog';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers

interface CallflowDetailsFieldsDeep {
  name: string;
  description: string;
  owner_id?: string;
  numbers?: any;
}
interface CallflowDetailsFields {
  callflow: CallflowDetailsFieldsDeep;
}

// interface declaring which props are required/allowed
interface CallflowDetailsDialogProps {
  callflowId: string;
  onCancel: () => void;
  onComplete: (form?: CallflowDetailsFields) => void;
  // used for current implementation of dialog from flow.js
  passForm?: boolean;
  flowForm?: CallflowDoc;
}

const DetailsDialogCallflow = ({
  callflowId,
  onCancel,
  onComplete,
  passForm = false,
  flowForm,
}: CallflowDetailsDialogProps) => {
  const { data: callflow, isLoading: callflowIsLoading } = useCallflowQuery({
    id: callflowId,
    options: {
      onSuccess: callflow => {
        const defaults = {
          callflow: {
            name: callflow.doc.name ?? '',
            description: callflow.doc.description ?? '',
            owner_id: callflow.doc.owner_id ?? '',
            numbers: callflow.doc.numbers ?? [],
            strategy: callflow.doc.strategy,
          },
        };

        reset(defaults);

        // if (!defaults.name) setFocus('name');
        // else if (!defaults.description) setFocus('description');
      },
      // only run query if not using ivr flow form
      enabled: !flowForm,
    },
  });

  const methods = useForm<CallflowDetailsFields>({
    defaultValues: flowForm
      ? // add defaults if passed ivr flow form
        {
          callflow: {
            name: flowForm.name ?? '',
            description: flowForm.description ?? '',
            owner_id: flowForm.owner_id ?? '',
            numbers: flowForm.numbers ?? [],
          },
        }
      : {
          callflow: {},
        },
  });
  const {
    register,
    formState: { errors: formErrors },
    reset,
    handleSubmit,
    setFocus,
    control,
    setValue,
    watch,
  } = methods;

  const updateCallflow = useUpdateCallflowPartial();

  const handleSave = async (callflowForm: CallflowDetailsFields) => {
    if (passForm) {
      onComplete(callflowForm);
    } else {
      const updateCallflowPromise = updateCallflow.mutateAsync(
        {
          id: callflowId,
          ...callflowForm.callflow,
        },
        // {
        //   onSuccess: () => {
        //     onComplete();
        //   },
        // },
      );

      // resolve promise with toast notifications
      const resp = await toast.promise(updateCallflowPromise, {
        pending: 'Updating callflow details...',
        error: 'Failed to update callflow details.',
        success: 'Callflow details updated!',
      });

      if (resp.success) {
        onComplete();
      }
    }
  };

  const editingCallflow = watch('callflow');
  const setEditingCallflow = cf => {
    setValue('callflow', cf, { shouldDirty: true });
  };

  const [isValidating, setIsValidating] = React.useState(false);

  return (
    <FormProvider {...methods}>
      <Dialog open={true} fullWidth maxWidth={'xs'}>
        {callflowIsLoading ? (
          <DialogInProgress title={'Loading callflow details info...'} />
        ) : updateCallflow.isLoading ? (
          <DialogInProgress title={'Updating callflow details...'} />
        ) : (
          <>
            <DialogTitle>Edit Callflow Details</DialogTitle>
            <Divider />
            {callflow ? (
              <>
                <DialogContent>
                  {/* callflow name */}
                  <Grid container columnSpacing={1} alignItems={'center'}>
                    <Grid item>
                      <TextField
                        label={'Name'}
                        {...register('callflow.name')}
                        error={!!formErrors.callflow?.name}
                        helperText={formErrors.callflow?.name?.message?.replace(
                          '"name"',
                          'Name',
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {callflow.doc.owner_type === 'user' ? (
                      <Grid item>
                        <Controller
                          control={control}
                          name={'callflow.owner_id'}
                          render={({
                            field: { onChange, value, ...props },
                          }) => (
                            <OwnerInput value={value} onChange={onChange} />
                          )}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <br />
                  <br />
                  {/* callflow description */}
                  <TextField
                    fullWidth
                    label={'Description'}
                    {...register('callflow.description')}
                    error={!!formErrors.callflow?.description}
                    helperText={formErrors.callflow?.description?.message?.replace(
                      '"description"',
                      'Description',
                    )}
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <br />
                  <br />
                  <NumberRouting />
                </DialogContent>
                <Divider />
                <DefaultDialogActions
                  onCancel={onCancel}
                  onSave={handleSubmit(handleSave)}
                  saveDisabled={isValidating}
                  saveLabel={passForm ? 'Confirm' : undefined}
                />
              </>
            ) : (
              <>
                <DialogContent>
                  <Typography color={'error'}>Invalid Callflow ID.</Typography>
                </DialogContent>
                <Divider />
                <DefaultDialogActions onCancel={onCancel} />
              </>
            )}
          </>
        )}
      </Dialog>
    </FormProvider>
  );
};

export default DetailsDialogCallflow;

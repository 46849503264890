import ScheduleEditorDialog from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
import { Link } from 'app/design';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Schedule } from 'types/schedule';

interface EditScheduleLinkProps {
  schedule: Schedule;
  onComplete: () => void;
  label?: string;
}

const EditScheduleLink = ({
  onComplete,
  schedule,
  label,
}: EditScheduleLinkProps) => {
  // const deleteSchedule = useDeleteSchedule();
  // const updateSchedulePartial = useUpdateSchedulePartial();

  // const { toggleOpen, Dialog, DialogProps } = useScheduleEditorDialog({
  //   initialOpen: false,
  // });
  const [openScheduleEdit, toggleOpenScheduleEdit] = useToggleReducer();

  // const handleDelete = () => {
  //   const createSchedulePromise = deleteSchedule.mutateAsync(schedule.id, {
  //     onSuccess: async resp => {
  //       onComplete();
  //       console.log('delete schedule resp', resp);
  //     },
  //     onError: error => console.error(error),
  //   });
  //
  //   toast.promise(createSchedulePromise, {
  //     pending: 'Deleting schedule...',
  //     success: 'Schedule deleted!',
  //     error: 'Error deleting schedule.',
  //   });
  // };

  const handleComplete = () => {
    onComplete();
    toggleOpenScheduleEdit();
  };

  return (
    <>
      <ScheduleEditorDialog
        DialogProps={{
          open: openScheduleEdit,
        }}
        ContentProps={{
          // userId={user.id}
          scheduleId: schedule.id,

          onCancel: toggleOpenScheduleEdit,
          onComplete: handleComplete,
        }}
      />
      {/*<Dialog
        {...DialogProps}
        scheduleId={schedule.id}
        onCancel={toggleOpen}
        onComplete={handleComplete}
      />*/}
      <button
        className="btn-accent-ghost btn-sm "
        onClick={toggleOpenScheduleEdit}
      >
        {label ?? schedule.doc.name ?? 'Untitled'}
      </button>
    </>
  );
};

export default EditScheduleLink;

import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

// import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../..';

import { IconButton } from 'app/design-lib';
import { USER_ADDABLE_COMPONENTS } from 'app/components/IvrBuilder/strategies/blank';
// import * as OptionComponents from '../../../../../Strategies/components';

import useDevMode from 'app/utilities/useDevMode';
import { TemplateList } from '../TemplateList';
import { Plus } from 'iconoir-react';

export const StrategySelect = props => {
  const {
    pipe,
    placeholder,
    preventComplexBeforeAfter,
    requireAllowAfter, // determines if the Selectable module is required to have the "allowAfter" value set
    requireAllowBefore, // determines if the Selectable module is required to have the "allowBefore" value set
    onChange,
    onChooseTemplate,
    selected,
    isInTemplate,
    showTemplateList, // depends on "inTemplate" of course
    endpointAlreadyInArr, // for the Strategy, to prevent a second Endpoint in an array...(might not be correct?)
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { devMode } = useDevMode(); // broken cuz memoized?? doesnt seem to have an effect

  // TODO: this list of options should be from a different list of possible "before" and "after" possible components
  // - type, options[props] like in Strategies.componentConfig.components
  const miscOptions = USER_ADDABLE_COMPONENTS.get('misc')
    .filter(c =>
      c.allowFunc
        ? c.allowFunc({ pipe, devMode, isInTemplate, endpointAlreadyInArr })
        : true,
    )
    .map(component => {
      // console.log('optKey:', optKey);
      const module = component; //OptionComponents[component.type];
      // console.log('type:', component.type);
      // const allow = requireAllowBefore
      //   ? module.allowBefore === true
      //     ? true
      //     : false
      //   : true && requireAllowAfter
      //   ? module.allowAfter === true
      //     ? true
      //     : false
      //   : true;
      const allow = requireAllowAfter
        ? module.allowAfter === true
          ? true
          : false
        : true;
      return allow ? { ...component, allow: true } : component;
    })
    .map(component => ({
      label: component.name,
      value: component,
      allow: component.allow,
      type: component.type,
      details: component.details,
    }));
  // console.log('miscOptions:', miscOptions);

  const ringOptions = USER_ADDABLE_COMPONENTS.get('ring')
    .filter(c =>
      c.allowFunc
        ? c.allowFunc({ pipe, isInTemplate, endpointAlreadyInArr })
        : true,
    )
    .map(component => {
      // console.log('optKey:', optKey);
      const module = component; //OptionComponents[component.type];
      // console.log('type:', component.type);
      // const allow = requireAllowBefore
      //   ? module.allowBefore === true
      //     ? true
      //     : false
      //   : true && requireAllowAfter
      //   ? module.allowAfter === true
      //     ? true
      //     : false
      //   : true;
      const allow = requireAllowAfter
        ? module.allowAfter === true
          ? true
          : false
        : true;
      return allow ? { ...component, allow: true } : component;
    })
    .map(component => ({
      label: component.name,
      value: component,
      allow: component.allow,
      type: component.type,
      details: component.details,
    }));

  const endOptions = USER_ADDABLE_COMPONENTS.get('termination')
    .filter(c =>
      c.allowFunc
        ? c.allowFunc({ pipe, isInTemplate, endpointAlreadyInArr })
        : true,
    )
    .map(component => {
      // console.log('optKey:', optKey);
      const module = component; //OptionComponents[component.type];
      // console.log('type:', component.type);
      // const allow = requireAllowBefore
      //   ? module.allowBefore === true
      //     ? true
      //     : false
      //   : true && requireAllowAfter
      //   ? module.allowAfter === true
      //     ? true
      //     : false
      //   : true;
      const allow = requireAllowAfter
        ? module.allowAfter === true
          ? true
          : false
        : true;
      return allow ? { ...component, allow: true } : component;
    })
    .map(component => ({
      label: component.name,
      value: component,
      allow: component.allow,
      type: component.type,
      details: component.details,
    }));

  const opt = null;

  return (
    <>
      <div
        className={`flex sm:flex-row flex-col space-x-0 space-y-4 sm:space-y-0 sm:space-x-2`}
      >
        <div className={`flex flex-col space-y-2`}>
          <span className={'font-medium text-neutral-60 text-sm mb-2'}>
            Ring Modules
          </span>
          {ringOptions.map(opt => (
            <button
              disabled={!opt.allow}
              className={`grid p-2 ${
                opt.allow
                  ? 'hover:bg-neutral-20'
                  : 'opacity-40 pointer-events-none'
              } h-20 gap-y-0 gap-x-4 w-full grid-cols-12`}
              key={opt.label}
              onClick={() => onChange(opt)}
            >
              <div className={`col-span-1 mt-0.5 text-neutral-60`}>
                {React.cloneElement(opt.value.icon, { width: 18, height: 18 })}
              </div>
              <div className={`text-start text-md font-medium col-span-11`}>
                {opt.label}
              </div>
              <div className={`col-span-1`}></div>
              <div className={`col-span-11 text-sm text-neutral-60 text-start`}>
                {opt.details}
              </div>
            </button>
          ))}
        </div>
        <div className={`flex flex-col space-y-2`}>
          <span className={'font-medium text-neutral-60 text-sm mb-2'}>
            Misc. Modules
          </span>
          {miscOptions.map(opt => (
            <button
              disabled={!opt.allow}
              className={`grid p-2 ${
                opt.allow
                  ? 'hover:bg-neutral-20'
                  : 'opacity-40 pointer-events-none'
              } h-20 gap-y-0 gap-x-4 w-full grid-cols-12`}
              key={opt.label}
              onClick={() => onChange(opt)}
            >
              <div className={`col-span-1 mt-0.5 text-neutral-60`}>
                {React.cloneElement(opt.value.icon, { width: 18, height: 18 })}
              </div>
              <div className={`text-start text-md font-medium col-span-11`}>
                {opt.label}
              </div>
              <div className={`col-span-1`}></div>
              <div
                className={`col-span-11 h-full text-sm text-neutral-60 text-start`}
              >
                {opt.details}
              </div>
            </button>
          ))}
        </div>
        {endOptions.length ? (
          <div className={`flex flex-col space-y-2`}>
            <span className={'font-medium text-neutral-60 text-sm mb-2'}>
              Termination Modules
            </span>
            {endOptions.map(opt => (
              <button
                disabled={!opt.allow}
                className={`grid p-2 ${
                  opt.allow
                    ? 'hover:bg-neutral-20'
                    : 'opacity-40 pointer-events-none'
                } h-20 gap-y-0 gap-x-4 w-full grid-cols-12`}
                key={opt.label}
                onClick={() => onChange(opt)}
              >
                <div className={`col-span-1 mt-0.5 text-neutral-60`}>
                  {React.cloneElement(opt.value.icon, {
                    width: 18,
                    height: 18,
                  })}
                </div>
                <div className={`text-start text-md font-medium col-span-11`}>
                  {opt.label}
                </div>
                <div className={`col-span-1`}></div>
                <div
                  className={`col-span-11 text-sm text-neutral-60 text-start`}
                >
                  {opt.details}
                </div>
              </button>
            ))}
          </div>
        ) : null}
      </div>
      {requireAllowAfter ? (
        <p className={`text-neutral-40 pt-6 px-2 sm:px-10 text-md`}>
          * Greyed out options can't be inserted between modules.{' '}
          <Link
            href={'https://support.calling.io/hc/en-us/articles/17059442036123'}
            className={`text-md`}
            target={'_blank'}
          >
            Why?
          </Link>
          {/*Use the{' '}
          <div className={`inline-flex`}>
            <IconButton pill variant={'outline'} color={'accent'} size={'sm'}>
              <Plus fr={undefined} />
            </IconButton>
          </div>{' '}
          button to add these to the Call Route. You can remove and re-add steps
          if needed.*/}
        </p>
      ) : null}
    </>
  );

  // return (
  //   <>
  //     <Grid container spacing={3}>
  //       <Grid item style={{ flex: 1 }}>
  //         <div>
  //           <Typography variant="h6" gutterBottom>
  //             Standard Components
  //           </Typography>
  //         </div>
  //
  //         <>
  //           {options
  //             .filter(opt => opt.allow)
  //             .map(opt => (
  //               <Paper
  //                 key={opt.label}
  //                 lcolor={
  //                   JSON.stringify(opt) === JSON.stringify(selected)
  //                     ? 'teal.50'
  //                     : 'grey.50'
  //                 }
  //                 onClick={() => onChange(opt)}
  //                 padding={1}
  //                 sx={{
  //                   margin: '4px 0',
  //                   cursor: 'pointer',
  //                   '&:hover': {
  //                     background: theme => theme.palette.info.light,
  //                   },
  //                 }}
  //                 elevation={0}
  //                 // elevateOnHover
  //               >
  //                 <Grid container alignItems="center">
  //                   <Grid item sx={{ padding: '4px 12px 4px 4px' }}>
  //                     <Icon
  //                       style={{ verticalAlign: 'text-top' }}
  //                       fontSize="small"
  //                     >
  //                       {opt.value.icon ?? 'help_outline'}
  //                     </Icon>
  //                   </Grid>
  //                   <Grid item>
  //                     <Typography variant="body2">{opt.label}</Typography>
  //                   </Grid>
  //                 </Grid>
  //               </Paper>
  //             ))}
  //         </>
  //       </Grid>
  //       {false && showTemplateList && !isInTemplate ? (
  //         <Grid item xs={6}>
  //           {/* <div>
  //             <Typography variant="h7">Templates</Typography>
  //           </div> */}
  //           <TemplateList onChooseTemplate={onChooseTemplate} />
  //         </Grid>
  //       ) : (
  //         ''
  //       )}
  //     </Grid>
  //   </>
  // );
};

export default StrategySelect;

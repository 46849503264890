import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { CollectionListHeader } from 'app/components/CollectionListHeader';
import {
  Button as MUIButton,
  Divider,
  Grid,
  Typography,
  Box,
  Chip,
} from 'app/design';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  TextField,
  IconButton,
} from 'app/design-lib';
import {
  AccessTime as AccessTimeIcon,
  Dehaze as DehazeIcon,
  DragHandle as DragHandleIcon,
  Edit as EditIcon,
  Person as PersonIcon,
  PhoneAndroid as PhoneAndroidIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from 'app/design/icons-material';
import { rgTypes } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard/RingGroup/RingGroup';
import { getAtPath, setAtPath } from 'app/utilities';
import {
  preEndpointsToCallflowModuleFormat,
  preEndpointsToGroupFormat,
} from 'app/utilities/utils';
import {
  Cancel,
  Clock,
  Phone,
  Strategy,
  User,
  WarningTriangle,
  Wrench,
} from 'iconoir-react';
// import { useTranslation } from 'react-i18next';
// import { set } from 'lodash';
import { toInteger } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// import { fetchAllVmboxes } from '../../../../../Actions/voicemails';
import { AddComponent } from '../AddComponent';
import { StepTab } from 'app/components/Onboarding/Vsb/Vsb';
import { useMediaQuery } from '@mui/material';

// Select users from list of users
export const AllAtOnceComponent = props => {
  const {
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    // users,
    // devices,
    defaultToFirst,
    ownerUser,
    // fetchUsers,
    allow,
    errorMessage,
    useGroupFormat, // vs. "ringgroup" Format (requires conversion! has restrictions!)
    onSave,
    startEditing,
    onReset,
    users,
    onClose,
    devices,
  } = props;

  const [isEditing, setIsEditing] = useState(startEditing ? true : false);

  useEffect(() => {
    if (errorMessage) setIsEditing(true);
  }, [errorMessage]);

  const ringGroup = getAtPath(strategyCallflow, `${modifyPath}`, {
    endpoints: useGroupFormat ? {} : [],
    preEndpoints: [], // gets built into "endpoints" depending on the useGroupFormat
    timeout: 20,
    strategy: 'simultaneous',
  });

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${modifyPath}.preEndpoints`,
    [],
  );

  const handleSave = () => {
    const data = getAtPath(strategyCallflow, `${modifyPath}`);

    let { preEndpoints = [] } = data;

    if (!data.timeout) {
      data.timeout = ringTimeout;
    }

    // rebuild preEndpoints timeout and delay
    // - assigns for Tiers
    let delay = 0;
    preEndpoints = preEndpoints.map(endpoint => {
      endpoint.delay = 0;
      endpoint.timeout = data.timeout; // group-level timeout
      return endpoint;
    });

    const endpoints = useGroupFormat
      ? preEndpointsToGroupFormat(preEndpoints)
      : preEndpointsToCallflowModuleFormat(preEndpoints);
    setAtPath(strategyCallflow, `${modifyPath}.endpoints`, endpoints);
    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, preEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
    console.log('AllAtOnce Endpoints Build:', endpoints);
    onSave && onSave();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const endpoints = reorder(
        selectedEndpoints,
        result.source.index,
        result.destination.index,
      );
      setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, endpoints);

      // TODO: convert according to useGroupFormat

      setStrategyCallflow({ ...strategyCallflow });

      // convert according to useGroupFormat
    },
    [selectedEndpoints, modifyPath, strategyCallflow, setStrategyCallflow],
  );

  useEffect(() => {
    // break apart tiers
    if (selectedEndpoints.find(e => e.endpoint_type === 'tier')) {
      let updatedEndpoints: any[] = [];
      selectedEndpoints.forEach(endpoint => {
        if (endpoint.endpoint_type === 'tier') {
          endpoint.tiers.forEach(tierEndpoint => {
            updatedEndpoints.push({
              ...tierEndpoint,
              timeout: endpoint.timeout,
              delay: endpoint.delay,
            });
          });
        } else {
          updatedEndpoints.push(endpoint);
        }
      });
      setAtPath(
        strategyCallflow,
        `${modifyPath}.preEndpoints`,
        updatedEndpoints,
      );
      setStrategyCallflow({
        ...strategyCallflow,
      });
    }
  }, [selectedEndpoints, modifyPath, strategyCallflow, setStrategyCallflow]);

  const handleAdd = new_endpoints_arr => {
    let currentOpts = {
      delay: 0,
      timeout: 10,
    };
    let newArr = new_endpoints_arr.map(t => ({ ...t, ...currentOpts }));

    const updatedEndpoints = [...selectedEndpoints, ...newArr];

    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleSetRingTimeout = timeoutInt => {
    setAtPath(strategyCallflow, `${modifyPath}.timeout`, timeoutInt);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleResetToChooser = () => {
    setAtPath(strategyCallflow, `${modifyPath}.type`, 'none');
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };
  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  const ringTimeout = getAtPath(strategyCallflow, `${modifyPath}.timeout`, 20);

  return (
    <>
      <DialogHeader
        title={'Ring Strategy: All at Once'}
        subTitle={
          'Rings all users and devices simultaneously for the specified duration'
        }
        onClose={onClose}
      />
      <DialogContent className={`overflow-y-auto`}>
        {errorMessage ? (
          <div className={'pt-0 px-2 pb-3'}>
            <Alert
              label={'Error Saving Ring Group'}
              reverse
              color={'negative'}
              icon={<WarningTriangle fr={undefined} />}
              body={errorMessage}
            />
          </div>
        ) : null}
        {isEditing ? (
          <div
            className={`flex pb-4 flex-1 justify-center mx-4 sm:mx-44 items-center space-x-4`}
          >
            <StepTab
              link
              label={'1. Select Strategy'}
              icon={<Strategy width={12} height={12} fr={undefined} />}
              currentStep={1}
              step={0}
              onClick={handleResetToChooser}
            />
            <div
              className={`flex-grow h-[1px] bg-neutral-20 rounded-full`}
            ></div>
            <div className={`w-`}></div>
            <StepTab
              label={'2. Customize Group'}
              icon={<Wrench width={12} height={12} fr={undefined} />}
              currentStep={1}
              step={1}
            />
          </div>
        ) : null}
        <div className={`bg-neutral-10 rounded p-4`}>
          <div
            className={`flex flex-col flex-col-reverse sm:flex-row sm:space-x-4 space-y-reverse space-y-2 sm:space-y-0 items-start`}
          >
            <div className={`flex-1 ${isMobile ? 'w-full' : ''}`}>
              <span className={`text-md font-medium underline`}>Members</span>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedEndpoints.length ? (
                        selectedEndpoints.map((endpoint, i) => (
                          <RingConfig
                            managed={!!ringGroup.managed}
                            key={endpoint.id}
                            index={i}
                            endpoint={endpoint}
                            users={users}
                            devices={devices}
                            timeout={ringGroup.timeout}
                            endpointsModifyPath={`${modifyPath}.preEndpoints`}
                            modifyPath={`${modifyPath}.preEndpoints[${i}]`}
                            strategyCallflow={strategyCallflow}
                            setStrategyCallflow={setStrategyCallflow}
                            provided={provided}
                            isEditing={isEditing}
                          />
                        ))
                      ) : (
                        <Alert
                          className={'mt-2'}
                          icon={<WarningTriangle fr={undefined} />}
                          label={'No users or devices'}
                          color={'attention'}
                          reverse
                          body={
                            'Add at least two users or devices to this ring group'
                          }
                        />
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div>
              <TextField
                startIcon={<Clock fr={undefined} />}
                label="Ring duration (seconds)"
                value={ringTimeout}
                disabled={!isEditing}
                type="number"
                min={0}
                onChange={e =>
                  // setDefaultRingTotalTime(toInteger(e.target.value))
                  handleSetRingTimeout(toInteger(e.target.value))
                }
              />
            </div>
          </div>
          {isEditing && !ringGroup.managed ? (
            <div className={`pt-2`}>
              <AddComponent
                {...props}
                onAdd={handleAdd}
                selectedEndpoints={selectedEndpoints}
              />
            </div>
          ) : null}
        </div>
      </DialogContent>

      <DialogActions
        className={`flex ${isEditing ? 'justify-between' : 'justify-end'}`}
      >
        {isEditing ? (
          <>
            <Button
              color="negative"
              variant="fill"
              size={'md'}
              onClick={e => {
                setIsEditing(false);
                onReset();
              }}
            >
              Cancel Editing
            </Button>
            {/*
            {ringGroup.managed ? null : (
              <Button
                color="attention"
                variant="outline"
                size={'md'}
                onClick={handleResetToChooser}
              >
                Change strategy
              </Button>
            )}
*/}
            <Button
              color="accent"
              variant="fill"
              size={'md'}
              onClick={e => {
                handleSave();
                setIsEditing(false);
              }}
            >
              Save Ring Group
            </Button>
          </>
        ) : (
          <Button
            variant={'fill'}
            color={'accent'}
            size={'md'}
            onClick={e => setIsEditing(true)}
          >
            Edit Ring Group
          </Button>
        )}
      </DialogActions>
    </>
  );

  //   return (
  //     <div
  //     // style={{
  //     //   minWidth: 500,
  //     // }}
  //     >
  //       <CollectionListHeader
  //         // telicon="device-fax"
  //         // teliconStyle={{ marginBottom: -4 }}
  //         icon={<DehazeIcon color={'primary'} style={{ fontSize: 20 }} />}
  //         title={
  //           <div className={'flex items-center space-x-2'}>
  //             <span>Ring Strategy: All At Once</span>
  //             <Chip color={'primary'} label={'Default'} size={'small'} />
  //           </div>
  //         }
  //         subtitle="Rings all users and devices simultaneously for the specified duration"
  //         actionButton={
  //           isEditing ? (
  //             <>
  //               <Grid container spacing={1}>
  //                 {ringGroup.managed ? null : (
  //                   <Grid item>
  //                     <MUIButton
  //                       color="primary"
  //                       variant="contained"
  //                       size="small"
  //                       // startIcon={<EditIcon />}
  //                       disableRipple
  //                       onClick={handleResetToChooser}
  //                     >
  //                       Change Ring Strategy
  //                     </MUIButton>
  //                   </Grid>
  //                 )}
  //                 <Grid item>
  //                   <MUIButton
  //                     color="error"
  //                     variant="outlined"
  //                     size="small"
  //                     // startIcon={<EditIcon />}
  //                     disableRipple
  //                     onClick={e => {
  //                       setIsEditing(false);
  //                       onReset();
  //                     }}
  //                   >
  //                     Cancel
  //                   </MUIButton>
  //                 </Grid>
  //               </Grid>
  //             </>
  //           ) : (
  //             <MUIButton variant={'contained'} onClick={e => setIsEditing(true)}>
  //               Edit
  //             </MUIButton>
  //           )
  //         }
  //       />
  //       <br />
  //       <br />
  //       <Box sx={{ width: '100%', pl: 3, display: 'grid', placeItems: 'center' }}>
  //         <Grid container columnSpacing={2}>
  //           <Grid item xs={8}>
  //             <Box sx={{ width: '80%' }}></Box>
  //           </Grid>
  //           <Grid item xs={4}>
  //
  //           </Grid>
  //         </Grid>
  //         <br />
  //       </Box>
  //
  //       {/*<Grid
  //         container
  //         style={{
  //           minWidth: 500,
  //         }}
  //       >
  //         <Grid item xs={6}>
  //           <Grid
  //             container
  //             direction={'column'}
  //             spacing={1}
  //             style={{ padding: 8 }}
  //           >
  //             <Grid item style={{ height: isEditing ? 90 : 60 }}>
  //               <>
  //                 <div>
  //                   <Typography variant="h7">
  //                     <Grid container alignItems={'center'} spacing={1}>
  //                       <Grid item>
  //                         <div style={{ paddingTop: 4 }}>
  //                           <AccessTimeIcon color={'primary'} />
  //                         </div>
  //                       </Grid>
  //                       <Grid item>Total Ring Time (seconds)</Grid>
  //                     </Grid>
  //                   </Typography>
  //                 </div>
  //                 <div style={{ paddingLeft: 25 }}>
  //                   {isEditing ? (
  //                     <TextField
  //                       label=""
  //                       value={ringTimeout}
  //                       type="number"
  //                       inputProps={{
  //                         min: 0,
  //                       }}
  //                       onChange={e =>
  //                         // setDefaultRingTotalTime(toInteger(e.target.value))
  //                         handleSetRingTimeout(toInteger(e.target.value))
  //                       }
  //                       InputLabelProps={{
  //                         shrink: true,
  //                       }}
  //                       margin="dense"
  //                       variant="outlined"
  //                       size="small"
  //                       style={{ textAlign: 'right' }}
  //                     />
  //                   ) : (
  //                     <Typography variant="body1">{ringTimeout}</Typography>
  //                   )}
  //                 </div>
  //               </>
  //             </Grid>
  //             <Grid item>
  //               <Typography variant="h7">
  //                 <Grid container alignItems={'center'} spacing={1}>
  //                   <Grid item>
  //                     <div style={{ paddingTop: 4 }}>
  //                       <PersonIcon color={'primary'} />
  //                     </div>
  //                   </Grid>
  //                   <Grid item>Group Members</Grid>
  //                 </Grid>
  //               </Typography>
  //               <div style={{ paddingLeft: 25 }}>
  //                 <DragDropContext onDragEnd={onDragEnd}>
  //                   <Droppable droppableId="droppable">
  //                     {provided => (
  //                       <div ref={provided.innerRef} {...provided.droppableProps}>
  //                         {selectedEndpoints.map((endpoint, i) => (
  //                           <RingConfig
  //                             key={endpoint.id}
  //                             index={i}
  //                             endpoint={endpoint}
  //                             users={users}
  //                             devices={devices}
  //                             timeout={ringGroup.timeout}
  //                             endpointsModifyPath={`${modifyPath}.preEndpoints`}
  //                             modifyPath={`${modifyPath}.preEndpoints[${i}]`}
  //                             strategyCallflow={strategyCallflow}
  //                             setStrategyCallflow={setStrategyCallflow}
  //                             provided={provided}
  //                             isEditing={isEditing}
  //                           />
  //                         ))}
  //                         {provided.placeholder}
  //                       </div>
  //                     )}
  //                   </Droppable>
  //                 </DragDropContext>
  //               </div>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //         {isEditing ? (
  //           <Grid item xs={6}>
  //             <Grid
  //               container
  //               direction={'column'}
  //               spacing={1}
  //               style={{
  //                 borderLeft: '1px solid rgba(0,0,0,0.3)',
  //                 paddingLeft: 8,
  //                 height: '100%',
  //               }}
  //             >
  //               <>
  //                 <AddComponent
  //                   {...props}
  //                   onAdd={handleAdd}
  //                   selectedEndpoints={selectedEndpoints}
  //                 />
  //               </>
  //             </Grid>
  //           </Grid>
  //         ) : (
  //           <></>
  //         )}
  //       </Grid>*/}
  //
  //       {/*
  //       {isEditing && (
  //         <>
  //           <br />
  //           <Divider />
  //           <br />
  //           <Grid container justifyContent="space-between" wrap={'nowrap'}>
  //             <Grid item>
  //               {!selectedEndpoints.length ? (
  //                 <Typography variant="body1">
  //                   No users or devices added to ring group
  //                 </Typography>
  //               ) : (
  //                 ''
  //               )}
  //             </Grid>
  //             <Grid item>
  //               <Button
  //                 variant="outlined"
  //                 color="success"
  //                 size="small"
  //                 disableRipple
  //                 onClick={e => {
  //                   handleSave();
  //                   setIsEditing(false);
  //                 }}
  //               >
  //                 Save Group Changes
  //               </Button>
  //             </Grid>
  //           </Grid>
  //         </>
  //       )}
  // */}
  //       {/*<br />*/}
  //     </div>
  //   );
};

const RingConfig = props => {
  const {
    index,
    endpoint,
    timeout,
    users,
    devices,
    endpointsModifyPath,
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    isEditing,
    managed,
  } = props;

  const value = [endpoint.delay, endpoint.timeout + endpoint.delay];

  const handleChange = useCallback(
    (e, value) => {
      // console.log('modifyPath:', modifyPath, value);
      setAtPath(strategyCallflow, `${modifyPath}.delay`, value[0]);
      setAtPath(strategyCallflow, `${modifyPath}.timeout`, value[1] - value[0]);
      setStrategyCallflow({ ...strategyCallflow });
    },
    [modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleRemove = useCallback(() => {
    const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
    endpointsArr.splice(index, 1);
    setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
    setStrategyCallflow({ ...strategyCallflow });
  }, [index, endpointsModifyPath, strategyCallflow, setStrategyCallflow]);

  const user = users.find(d => d.id === endpoint.id);
  const device = devices.find(d => d.id === endpoint.id);

  return (
    <Draggable draggableId={endpoint.id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Grid
            sx={{ width: '100%', p: 1.5 }}
            container
            spacing={1}
            alignItems={'center'}
            wrap={'nowrap'}
          >
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid container sx={{ width: '100%' }} wrap={'nowrap'}>
                <Grid item style={{ width: 34, display: 'none' }}>
                  <MUIButton
                    {...provided.dragHandleProps}
                    style={{ minWidth: 0, padding: 0 }}
                  >
                    <DragHandleIcon style={{ color: 'gray' }} />
                  </MUIButton>
                </Grid>
                <Grid
                  item
                  wrap={'nowrap'}
                  style={{
                    width: '100%',
                    flexGrow: 1,
                  }}
                >
                  <div
                    style={{
                      marginTop: 2,
                      width: '100%',
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {endpoint.endpoint_type === 'user' ? (
                      <div className={`flex space-x-2  sm:w-full items-center`}>
                        <div>
                          <User
                            className={`text-neutral-60`}
                            width={16}
                            height={16}
                            fr={undefined}
                          />
                        </div>
                        <span className={'font-medium truncate text-md'}>
                          {user?.extra?.fullName ?? 'Unknown User'}
                        </span>
                      </div>
                    ) : null}
                    {endpoint.endpoint_type === 'device' ? (
                      <div className={`flex space-x-2 items-center`}>
                        <div>
                          <Phone
                            className={`text-neutral-60`}
                            width={16}
                            height={16}
                            fr={undefined}
                          />
                        </div>
                        <span className={'font-medium truncate text-md'}>
                          {device?.doc?.name}
                        </span>
                      </div>
                    ) : // <>{device?.doc?.name}</>
                    null}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs></Grid> */}

            {isEditing && !managed && (
              <Grid item style={{ width: 34, textAlign: 'left' }}>
                <IconButton
                  variant={'ghost'}
                  color={'negative'}
                  onClick={handleRemove}
                >
                  <Cancel fr={undefined} />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Divider />
        </div>
      )}
    </Draggable>
  );
};

const DividerWithText = ({ children }) => {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Divider style={{ flex: '1 1 auto' }} />
      <span style={{ padding: '0 10px' }}>{children}</span>
      <Divider style={{ flex: '1 1 auto' }} />
    </Grid>
  );
};

export default AllAtOnceComponent; //connect(mapStateToProps, { fetchUsers })(RingGroup);

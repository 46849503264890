import React from 'react';
import * as RadixSlider from '@radix-ui/react-slider';

export interface SliderProps {
  defaultValue?: number;
  max?: number;
  step?: number;

  value?: number;

  onChange?: (value: number) => void;

  orientation?: 'vertical' | 'horizontal';
}

export const Slider = ({
  step = 1,
  max = 100,
  defaultValue = 0,
  value,
  onChange,
  orientation = 'horizontal',
}: SliderProps) => {
  return (
    <form className={`h-full w-full`}>
      <RadixSlider.Root
        className={`relative flex select-none touch-none ${
          orientation === 'vertical'
            ? 'h-full w-2 flex-col justify-center items-center'
            : 'w-full h-2 items-center'
        } `}
        defaultValue={[defaultValue]}
        max={max}
        step={step}
        value={value ? [value] : undefined}
        onValueChange={
          onChange
            ? values => {
                onChange(values[0]);
              }
            : undefined
        }
        aria-label="Volume"
        orientation={orientation}
      >
        <RadixSlider.Track
          className={`relative cursor-pointer bg-accent-20 flex-1 rounded-full ${
            orientation === 'vertical' ? 'w-1 h-full' : 'h-1'
          }`}
        >
          <RadixSlider.Range
            className={`absolute bg-accent-60 rounded-full  ${
              orientation === 'vertical' ? 'w-full' : 'h-full'
            }`}
          />
        </RadixSlider.Track>
        <RadixSlider.Thumb className="block cursor-pointer h-3 w-3 aspect-square bg-accent-60 drop-shadow rounded-full" />
      </RadixSlider.Root>
    </form>
  );
};

export default Slider;

import { RefObject, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export interface ContentRect {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  left: number;
  bottom: number;
}

const useMeasureDirty = (ref: RefObject<HTMLElement>): ContentRect => {
  const frame = useRef(0);
  const [rect, set] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });

  const [observer] = useState(
    () =>
      new ResizeObserver(entries => {
        const entry = entries[0];

        if (entry) {
          cancelAnimationFrame(frame.current);

          frame.current = requestAnimationFrame(() => {
            if (ref.current) {
              set(entry.target.getBoundingClientRect());
            }
          });
        }
      }),
  );

  const newRect = ref?.current?.getBoundingClientRect(); //.contentRect;
  useEffect(() => {
    if (!newRect) {
      return;
    }
    set(newRect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(newRect)]);

  useEffect(() => {
    observer.disconnect();

    if (ref.current) {
      observer.observe(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return rect;
};

export default useMeasureDirty;

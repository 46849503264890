import buildResourceUrl from './buildResourceUrl';
import { get } from './methods';

// Default PATCH-building function
// - uses buildResourceUrl and passes thru options from opts.buildUrlOpts
// - expects the "id" to exist at "doc.id" (option to "delete doc.id after building URL" for phoneNumbers?)
export const buildGetDefault =
  (resourceApiPathname, opts) => async (id, auth_token, urlParams) => {
    // uses a GET request to get the doc
    // - id not required
    // if (!id) {
    //   throw new Error('Missing id for resource to get');
    // }

    return getResourceDefault({
      url: buildResourceUrl(
        resourceApiPathname,
        id,
        auth_token,
        opts?.buildUrlOpts,
      ),
      auth_token,
      urlParams,
    });
  };

export const getResourceDefault = async ({
  url,
  auth_token = null,
  urlParams = {},
}) => {
  const resp = await get(url, { auth_token, urlParams });
  return resp.data;
};

import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import deviceQueryKeys from 'app/hooks/queries/device/deviceQueryKeys';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteDevice = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, string, unknown>(
    (deviceId: string) =>
      sdk.device.mutate.delete(deviceId, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(deviceQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

import { Avatar, Badge, Tooltip } from 'app/design';
import { withStyles } from 'app/design/styles';
import * as React from 'react';
import { useAuthSelector } from 'app/data/auth';

import constants from 'app/constants';

interface AvatarCellProps {
  resource: any;
  resourceType?: string;
  size?: number;
  sx?: any;
  forceInitials?: string;
  forceBadge?: boolean;
  badgeColor?: string;
}

const AvatarCell = ({
  resourceType = 'user',
  resource,
  size,
  sx = {},
  forceInitials,
  forceBadge = false,
  badgeColor = '#FF5722',
}: AvatarCellProps) => {
  let initials: string;

  if (forceInitials) {
    initials = forceInitials;
  } else if (
    resource?.doc?.first_name ||
    resource?.doc?.last_name ||
    resource?.doc?.company
  ) {
    initials =
      resource?.doc?.first_name || resource?.doc?.last_name
        ? `${resource?.doc?.first_name?.charAt(0) ?? ''}${
            resource?.doc?.last_name?.charAt(0) ?? ''
          }`
        : resource?.doc?.company?.charAt(0) ?? '';
  } else {
    // use doc.name
    initials =
      resource?.doc?.name
        ?.split(' ')
        .slice(0, 1)
        .map(v => (v?.length ? v[0] : ''))
        .join('') ?? '-';
  }
  // switch (resourceType) {
  //   case 'group':
  //     initials = resource?.doc?.name
  //       .split(' ')
  //       .map(v => (v?.length ? v[0] : ''))
  //       .join('');
  //     break;
  //   case 'user':
  //   default:
  //     initials = resource
  //       ? resource?.doc?.first_name || resource?.doc?.last_name
  //         ? `${resource?.doc?.first_name?.charAt(0) ?? ''}${
  //             resource?.doc?.last_name?.charAt(0) ?? ''
  //           }`
  //         : resource?.doc?.company?.charAt(0) ?? ''
  //       : '';
  // }

  const { account_id, auth_token } = useAuthSelector();

  const src = `${
    constants.env.REACT_APP_CIO_API_SERVER
  }/api/kproxy/accounts/${account_id}/users/${
    resource?.id
  }/photo?auth_token=${auth_token}&_=${resource?.updatedAt || ''}`;

  // console.log('SRC:', src, resource);

  return (
    <>
      {/* <Tooltip arrow title={resource?.extra?.fullName}> */}
      {/* On a call status*/}
      {/* {resource?.doc.do_not_disturb?.enabled ? <OnCallIcon /> : ''} */}
      {/* Avatar/Badge */}
      {/* @ts-ignore */}
      {forceBadge || (1 == 0 && resource?.doc?.do_not_disturb?.enabled) ? (
        <AvatarBadge
          // color={'#44b700'}
          color={badgeColor}
          alt={resource?.extra?.fullName}
          src={resource?.hasPhoto ? src : undefined}
          tooltip={'Do Not Disturb Enabled'}
          sx={{
            height: size,
            width: size,
            bgcolor: stringToColor(
              resource?.extra?.fullName ??
                resource?.doc?.name ??
                `${resource?.doc?.first_name ?? ''}${
                  resource?.doc?.last_name ?? ''
                }${resource?.doc?.company ?? ''}`,
            ),
            ...sx,
          }}
        >
          {initials}
        </AvatarBadge>
      ) : (
        <Avatar
          alt={resource?.extra?.fullName}
          src={resource?.hasPhoto ? src : undefined}
          // alt={resource?.extra?.fullName}
          // src={resource?.hasPhoto ? KazooSDK.getUserPhotoUrl(resource?.id) : null}
          // src={
          //   resource?.hasPhoto
          //     ? `${constants.env.REACT_APP_CIO_API_SERVER}/api/v1/resourceimage?id=${
          //         resource?.id
          //       }&auth_token=${localStorage.getItem('authToken')}`
          //     : undefined
          // }
          sx={{
            height: size,
            width: size,
            fontSize: size ? size / 2 : undefined,
            // boxShadow: theme => `0 0 3px ${theme.palette.primary.dark}`,
            // @ts-ignore
            // boxShadow: theme => `0 0 3px ${theme.palette.gray.light}`,
            bgcolor: stringToColor(
              resource?.extra?.fullName ??
                resource?.doc?.name ??
                `${resource?.doc?.first_name ?? ''}${
                  resource?.doc?.last_name ?? ''
                }${resource?.doc?.company ?? ''}`,
            ),
            ...sx,
          }}
        >
          {initials}
        </Avatar>
      )}
      {/* </Tooltip> */}
    </>
  );
};

// @ts-ignore
const AvatarBadgeStyled = withStyles(theme => ({
  badge: {
    backgroundColor: props => props.color,
    color: props => props.color,
    boxShadow: `0 0 0 2px warning`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const AvatarBadge = props => {
  const { color, alt, src, tooltip, size } = props;

  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <AvatarBadgeStyled
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
        color={'warning'}
        sx={{ height: size, width: size }}
      >
        <Avatar
          alt={alt}
          src={src}
          sx={{
            border: `1px solid ${color}`,
            height: size,
            width: size,
            bgcolor: stringToColor(alt),
          }}
        >
          {props.children}
        </Avatar>
      </AvatarBadgeStyled>
    </Tooltip>
  );
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export default AvatarCell;

import { Divider } from '@mui/material';
import { Button, Dialog } from 'app/design-lib';
import * as React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
// import {
//   Box,
//   Paper,
//   Link,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogInProgress,
//   DialogTitle,
//   Divider,
//   Grid,
//   IconButton,
//   MenuItem,
//   TextField,
//   Typography,
// } from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import {
  useFeature,
  Feature,
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import { CreditCardRequiredDialog } from 'app/components/CreditCardRequiredDialog';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
// const schema = Joi.object({
//   owner_id: Joi.string(),
//   name: Joi.string().max(128).required(),
//   mailbox: Joi.string().max(5).required(),
// });

interface PlanUpgradeForm {
  name?: string;
}

// interface declaring which props are required/allowed
interface PlanUpgradeDialogProps {
  formDefaults?: any;
  onCancel: () => void;
  startDirty?: boolean;
  onComplete: () => void;
}

const PlanUpgradeDialog = ({
  formDefaults,
  onCancel,
  onComplete,
  startDirty = true,
}: PlanUpgradeDialogProps) => {
  const handleCancel = () => {
    onCancel();
  };

  return (
    // @ts-ignore
    <Feature
      name="has_payment_methods"
      // @ts-ignore
      NotTrueRender={({ updateFeatures }) => (
        <CreditCardRequiredDialog
          onCancel={handleCancel}
          onComplete={() => {
            updateFeatures();
          }}
        />
      )}
    >
      <Dialog open onClose={onCancel}>
        <PlanUpgradeDialogForm
          formDefaults={formDefaults}
          startDirty={startDirty}
          onCancel={onCancel}
          onComplete={onComplete}
        />
      </Dialog>
    </Feature>
  );
};

interface PlanUpgradeDialogFormProps {
  formDefaults: PlanUpgradeForm;
  onCancel: () => void;
  onComplete: () => void;
  startDirty?: boolean;
}

const PlanUpgradeDialogForm = ({
  formDefaults,
  onCancel,
  onComplete,
  // onSubmit,
  // errorMessage,
  startDirty = false,
}: PlanUpgradeDialogFormProps) => {
  const [saveError, setSaveError] = useImmer(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isSubmitting },
    setError,
    watch,
  } = useForm<PlanUpgradeForm>({
    defaultValues: formDefaults,
    // resolver: joiResolver(schema), // pass in custom validation
  });

  const router = useHistory();
  const addIntegration = useAccountStripeCustomerMutate();

  const onSubmit = async (ccForm: PlanUpgradeForm) => {
    // update form defaults if mutation fails

    setSaveError(null);

    const endTrialPromise = addIntegration.mutateAsync({
      data: {
        action: 'endTrial',
        data: {
          // name: ccForm.name,
          // source: token?.id,
          // // @ts-ignore
          // setDefault: formDefaults?.setDefault ? true : false,
        },
      },
    });

    toast.promise(endTrialPromise, {
      pending: 'Upgrading...',
      success: 'Upgraded!',
      error: 'Error Upgrading.',
    });

    let resp;
    try {
      resp = await endTrialPromise;
    } catch (err) {
      // TODO: parse and set errors

      // @ts-ignore
      setSaveError({
        // @ts-ignore
        message: 'Unable to upgrade. Please correct errors and try again.',
      });
      throw err;
    }

    onComplete && onComplete();
  };

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  return (
    <>
      <CustomBackdrop {...CustomBackdropProps} open={isSubmitting} />
      <div className={'flex flex-col items-center space-y-4'}>
        <span className={`font-medium text-lg `}>
          Upgrade your plan to keep your account active.
        </span>
        <div className={'w-full flex justify-between'}>
          <Button variant={'outline'} color={'negative'} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="fill"
            color="accent"
            onClick={handleSubmit(onSubmit)}
          >
            Upgrade Now
          </Button>
        </div>
        {/* @ts-ignore */}
        {saveError ? (
          <div>
            <span color={'warning.dark'}>
              {/* @ts-ignore */}
              {saveError.message}
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const usePlanUpgradeDialog = DialogBuilder(PlanUpgradeDialog);

export default PlanUpgradeDialog;

import React, { lazy, Suspense, useState, useEffect, useMemo } from 'react';
import PubNub from 'pubnub';
import { v4 as uuidv4 } from 'uuid';
import {
  PubNubProvider as OrigPubNubProvider,
  usePubNub as useOriginalPubNub,
} from 'pubnub-react';
import { isArray } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { store } from 'store';
import { useNotificationSound } from '../../hooks/general/useNotificationSound';
import { useAuthenticatedUserQuery } from '../../hooks/queries/user';

export const PNContext = React.createContext({});
export const usePNContext = () => {
  return React.useContext(PNContext);
};

// const UniqueID = PubNub.generateUUID();
export const pubnubClient = new PubNub({
  // publishKey: // not needed for subscribing-only,
  subscribeKey: 'sub-c-b99ea994-7e1f-4c8e-893b-6aed75d3b6b6',
  // TODO: should the following be userId?? That seems like it is required...
  // - https://www.pubnub.com/docs/sdks/javascript/api-reference/configuration
  // uuid: `testing-browser-id-${UniqueID}`, // should include "auth" for user? for granting access to a channel...
  // userId: `${store.getState().auth.account_id}-${
  //   store.getState().auth.owner_id
  // }`,
  uuid: 'anon',
});

// const CHANNEL_TO_ARR_OF_ID_FN = {};
// const listeners = {}

const CHANNEL_TO_ARR_OF_ID_FN = {}; // channelName: [{id: "xyz", fn]
const ID_TO_CHANNELS = {}; // id: [channel1, channel2]

window.ID_TO_CHANNELS = ID_TO_CHANNELS;
window.CHANNEL_TO_ARR_OF_ID_FN = CHANNEL_TO_ARR_OF_ID_FN;
export const PubNubProvider = props => {
  // if I am online, set my state accordingly
  const { data: user } = useAuthenticatedUserQuery();
  const { owner_id, account_id } = useAuthSelector();
  const { play, loaded: soundsLoaded } = useNotificationSound();

  useEffect(() => {
    window.pubnubClient = pubnubClient;
    if (account_id && owner_id) {
      pubnubClient.setUUID(`${account_id}-${owner_id}`);
    }
  }, [pubnubClient, account_id, owner_id]);

  // TODO: how to propagate this to all connected statuses for a user?
  const [userIsOnline, userIsOnlineSet] = useState(true); // TODO: useLocalStorage? store in the user object, elsewhere?

  const handleMessage = event => {
    // console.log(
    //   'pubnub handleMessage provider',
    //   event.message.payload?.type,
    //   event.message,
    // );

    if (
      // only alert if user has dnd disabled
      !user?.doc.do_not_disturb?.enabled &&
      event.message?.type === 'added' &&
      // first is internal inbound, second is external inbound
      (event.message.payload?.data?.direction
        ? event.message.payload?.data?.direction === 'in'
        : !event.message.payload?.data?.from_user_id) &&
      // don't alert on call (will be ringing)
      event.message.payload?.type !== 'call' &&
      event.message.payload?.type !== 'internal' &&
      event.message.payload?.type !== 'internal-activity'
    ) {
      play();
      // console.log('received message!', event);
    }

    const { channel } = event;
    let arr = CHANNEL_TO_ARR_OF_ID_FN[channel];
    // console.log('pubnub ARR:', arr);
    if (arr?.length) {
      for (let data of arr) {
        // console.log('pubnub call fn:', data.fn);
        data.fn(event);
      }
    }
  };

  const [onlineUsers, onlineUsersSet] = useState([]); // [userId, userId, ...]

  const presenceQuery = useQuery(['presence'], () => {
    return new Promise(resolve => {
      pubnubClient.hereNow(
        {
          channels: ['online'],
          includeState: true,
        },
        function (status, response) {
          // console.log('presence herenow:', status, response);
          // {uuid, state}
          resolve(response);
        },
      );
    });
  });

  useEffect(() => {
    if (presenceQuery.data) {
      const users = presenceQuery.data.channels.online.occupants;
      // console.log('Presence Users:', users);
      const userIdsOnline = {
        // userId: isOnline
      };
      let forceAway = {};
      for (let user of users) {
        if (user.state?.isOnline === false) {
          // they are specifically NOT online
          // - TODO: maybe also dont have the user connect to the "online" channel? limit what other users can see?
          forceAway[user.state.userId] = true; // TODO: (fix) currently users can set their state to anything and we trust it blindly
          delete userIdsOnline[user.state.userId]; // might not exist, deleting just in case...
        } else {
          if (user.state && !forceAway[user.state.userId]) {
            userIdsOnline[user.state.userId] = true;
          }
        }
      }
      // set status for this user according to whatever is set in the state!
      // - TODO: uhhh, might break when they have multiple tabs open??
      if (userIdsOnline[owner_id] && !userIsOnline) {
        delete userIdsOnline[owner_id];
      } else if (!userIdsOnline[owner_id] && userIsOnline) {
        userIdsOnline[owner_id] = true;
      }

      onlineUsersSet(userIdsOnline);
      // console.log('Presence userIdsOnline', userIdsOnline);
    }
  }, [presenceQuery.data, owner_id, userIsOnline]);

  const handlePresence = event => {
    // console.log('PRESENCE EVENT:', event);

    if (event?.action === 'interval') {
      presenceQuery.refetch();
    }
    // // // handle presence
    // var action = p.action; // Can be join, leave, state-change, or timeout
    // var channelName = p.channel; // The channel to which the message was published
    // var occupancy = p.occupancy; // Number of users subscribed to the channel
    // var state = p.state; // User State
    // var channelGroup = p.subscription; //  The channel group or wildcard subscription match (if exists)
    // var publishTime = p.timestamp; // Publish timetoken
    // var timetoken = p.timetoken; // Current timetoken
    // var uuid = p.uuid; // User IDs of users who are subscribed to the channel

    // pubnub.setState(
    //   {
    //     state: { mood: 'pumped', isTyping: false },
    //     channels: ['chats.room1'],
    //     channelGroups: ['cg_user123_friends'],
    //   },
    //   function (status, response) {
    //     if (status.isError) {
    //       console.log(status);
    //     } else {
    //       console.log(response);
    //     }
    //   },
    // );
  };

  useEffect(() => {
    // console.log('PUBNUB add listener');
    const listener = { message: handleMessage, presence: handlePresence };
    pubnubClient.addListener(listener);
    pubnubClient.subscribe({ channels: ['online'], withPresence: true });
    return () => {
      // console.log('PUBNUB remove listener');
      pubnubClient.removeListener(listener);

      pubnubClient.unsubscribe({ channels: ['online'] });
    };
  }, [pubnubClient, soundsLoaded, user?.doc.do_not_disturb?.enabled]);

  useEffect(() => {
    if (pubnubClient) {
      pubnubClient.setState(
        {
          state: { isOnline: userIsOnline, userId: owner_id },
          channels: ['online'],
          // channelGroups: ['cg_user123_friends'],
        },
        function (status, response) {
          if (status.isError) {
            console.error('pubnub setState error:', status);
          } else {
            // console.log('pubnub setState response', response);
          }
        },
      );
      // console.log('Presence SETSTATE');
    }
  }, [userIsOnline]);

  // const [CHANNEL_TO_ARR_OF_ID_FN, set_CHANNEL_TO_ARR_OF_ID_FN] = React.useState({});

  const subscribe = React.useCallback(
    (channels, onDataFunc) => {
      const id = uuidv4();
      // localIdToCallback.set(localId, onDataFunc);

      channels = isArray(channels) ? channels : [channels];

      // console.log('pubnub add channels:', channels);
      ID_TO_CHANNELS[id] = channels;

      for (let channel of channels) {
        // let arr = CHANNEL_TO_ARR_OF_ID_FN.hasOwnProperty(channel);
        if (!CHANNEL_TO_ARR_OF_ID_FN.hasOwnProperty(channel)) {
          CHANNEL_TO_ARR_OF_ID_FN[channel] = [];
        }
        CHANNEL_TO_ARR_OF_ID_FN[channel].push({ id, fn: onDataFunc });
        //   uuidToLocalUuids.get(channelUuid).add(localUuid);
        // } else {
        //   // start a new subscription
        //   channelUuid = uuidv4();
        //   channelToUuid.set(channel, channelUuid);
        //   uuidToLocalUuids.set(channelUuid, new Set());
        //   uuidToLocalUuids.get(channelUuid).add(localUuid);
        //   // const msg = createSubscribeMsg(authParams, channel, channelUuid);
        //   // // JSON.stringify({
        //   // //   action: 'subscribe',
        //   // //   auth_token: localStorage.getItem('authToken'),
        //   // //   requestId: channelUuid,
        //   // //   data: {
        //   // //     channel,
        //   // //     account_id: localStorage.getItem('account_id')
        //   // //   }
        //   // // });
        //   // if (ReadyState.OPEN === readyState) {
        //   //   sendMessage(msg);
        //   // } else {
        //   //   waitingToSend.add(msg);
        //   // }
        // }
      }

      // subscribe to new channels (appends subscriptions, not an overwrite)
      // console.log('pubnub Subscribe:', channels);
      // pubnubClient.addListener(listener);
      pubnubClient.subscribe({ channels });

      return id;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pubnubClient],
  );

  const unsubscribe = React.useCallback(
    localId => {
      // console.log('pubnub unsubscribe, removeListener', ID_TO_CHANNELS);
      // pubnubClient.removeListener(listener);
      for (let channel of ID_TO_CHANNELS[localId]) {
        // console.log('pubnub l1:', CHANNEL_TO_ARR_OF_ID_FN[channel]?.length);
        CHANNEL_TO_ARR_OF_ID_FN[channel] =
          CHANNEL_TO_ARR_OF_ID_FN[channel]?.filter(
            data => data.id !== localId,
          ) ?? [];
        // console.log('pubnub l2:', CHANNEL_TO_ARR_OF_ID_FN[channel]?.length);
      }
      // console.log('pubnub unsubscribe');
      delete ID_TO_CHANNELS[localId];
    },
    [pubnubClient],
  );

  const value = {
    subscribe,
    unsubscribe,
    onlineUsers, // array of ids
    userIsOnline,
    userIsOnlineSet,
  };

  return (
    <PNContext.Provider value={value}>
      <OrigPubNubProvider client={pubnubClient}>
        {props.children}
      </OrigPubNubProvider>
    </PNContext.Provider>
  );
};

export const usePubNubSub = (fn, channels, deps = []) => {
  const pubnub = useOriginalPubNub();

  const { subscribe, unsubscribe } = usePNContext();

  // require fn to be a React.useCallback?
  const cbFn = React.useCallback(fn, [channels, ...deps]);

  useEffect(() => {
    if (!channels.length) {
      return;
    }
    const requestId = subscribe(channels, cbFn);
    return () => {
      if (requestId) {
        unsubscribe(requestId);
      }
    };
  }, [channels, cbFn]);

  // // require fn to be a React.useCallback?
  // const cbFn = React.useCallback(fn, deps);

  // useEffect(() => {
  //   const listener = { messsage: handleMessage };

  //   console.log('pubnub set');
  //   if (!channels.length) {
  //     return;
  //   }

  //   pubnub.addListener(listener);
  //   const id = uuidv4();
  //   for (let channel of channels) {
  //     // let arr = CHANNEL_TO_ARR_OF_ID_FN.hasOwnProperty(channel);
  //     if (!CHANNEL_TO_ARR_OF_ID_FN.hasOwnProperty(channel)) {
  //       CHANNEL_TO_ARR_OF_ID_FN[channel] = [];
  //     }
  //     CHANNEL_TO_ARR_OF_ID_FN[channel].push({ id, fn: cbFn });
  //   }
  //   console.log('PUBNUB SUBSCRIBING', Object.keys(CHANNEL_TO_ARR_OF_ID_FN));
  //   pubnub.subscribe({ channels: Object.keys(CHANNEL_TO_ARR_OF_ID_FN) });
  //   console.log('PUBNUB CHANNEL_TO_ARR_OF_ID_FN', CHANNEL_TO_ARR_OF_ID_FN);
  //   return () => {
  //     // pubnub.removeListener(listener);
  //     // // remove id from channel map, unsubscribe in channel no longer has any entries
  //     // for (let channel of channels) {
  //     //   let arr = CHANNEL_TO_ARR_OF_ID_FN[channel].filter(v => v.id !== id);
  //     //   CHANNEL_TO_ARR_OF_ID_FN[channel] = arr;
  //     //   if (!arr.length) {
  //     //     // unsubscribe in 30 seconds if not continuing to be subscribed
  //     //     // pubnub.unsubscribe({ channels });
  //     //   }
  //     // }
  //   };
  // }, [channels, cbFn]);
};

// export const usePNBinding = (bindings, callback) => {
//   const { subscribe, unsubscribe, readyState, getWebSocket } =
//     React.useContext(WebsocketContext);

//   // const context = useContext(WebsocketContext);
//   const [lastMessage, setLastMessage] = useState(null);

//   useEffect(() => {
//     // subscribe
//     // console.log('subscribing to:', binding, callback);

//     // // TODO: remove for working websockets
//     // if (window.sessionStorage.getItem('mobile')) {
//     //   return;
//     // }
//     const requestId = subscribe(bindings, data => {
//       // console.log('new event:', binding);
//       setLastMessage(data);
//       if (callback) {
//         callback(data);
//       }
//     });
//     return () => {
//       if (requestId) {
//         unsubscribe(requestId);
//       }
//     };
//   }, [bindings, callback, subscribe, unsubscribe]);

//   return {
//     lastMessage,
//     readyState,
//     getWebSocket,
//   };
// };

import { Box, CircularProgress, Grid, Typography } from 'app/design';
import { useCreateCallflow } from 'app/hooks/mutations/callflow/useCreateCallflow';
import { useCreateDevice } from 'app/hooks/mutations/device/useCreateDevice';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useCreateWebphone = (auto, refetch) => {
  const { data: userData } = useAuthenticatedUserQuery();

  const { handleCreate, isCreating } = useCreate({ refetch });

  const creating = useRef();
  const createOnce = React.useCallback(() => {
    if (!userData) {
      // console.log('Webphone: no userData');
      return;
    }
    if (creating?.current) {
      // console.log('Webphone: already creating');
      return;
    }
    creating.current = true;
    // console.log('Webphone: Creating2');
    handleCreate(userData);
    // alert('creating webphone cuz not setup!');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (auto && userData) {
      createOnce();
    } else {
      // console.log('Webphone: ', { auto, userData });
    }
  }, [auto, userData, createOnce]);

  return {
    createOnce,
    isCreating,
  };
};

const CreateWebphone = ({ auto, refetch }) => {
  // TODO: make a request to the server to create the webphone for the user

  const { isCreating } = useCreateWebphone(auto, refetch);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%', padding: 3 }}
    >
      <Grid item>
        <Box sx={{ maxWidth: '280px', textAlign: 'center' }}>
          {/* <Typography variant="body1" component="div" gutterBottom>
            <Button onClick={handleCreate} variant="contained" color="primary">
              Setup My Webphone
            </Button>
          </Typography>
          <Typography variant="caption" component="div">
            * in the future this may be automatically done!
          </Typography> */}
          {isCreating ? (
            <>
              <CircularProgress />
              <Typography variant="h2" component="div">
                Activating Webphone
              </Typography>{' '}
            </>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

const useCreate = ({ refetch }) => {
  const [isCreating, setIsCreating] = useState();
  const createDevice = useCreateDevice();
  const createCallflow = useCreateCallflow();
  const { data: accountData } = useAccountQuery();

  const handleCreate = async user => {
    setIsCreating(true);
    try {
      // Create Device
      let device;
      try {
        const deviceResp = await createDevice.mutateAsync({
          // device_type: 'webphone',
          device_type: 'softphone',
          subtype: 'webphone',
          enabled: true,
          name: `${user.extra.fullName}'s Webphone`,
          owner_id: user.id,
          sip: {
            password: uuidv4().substring(0, 32),
            realm: accountData?.doc.realm,
            username: `user_${uuidv4()}`.substring(0, 32),
          },
          suppress_unregister_notifications: false,
        });
        if (deviceResp?.status !== 'success') {
          throw new Error(deviceResp);
        }
        device = deviceResp.data;
      } catch (err) {
        console.error('Failed saving device', err);
        throw new Error('Failed saving device');
      }

      // Create Device Main Callflow
      // - build from template?
      // console.log('device:', device);
      try {
        const deviceMainCallflowResp = await createCallflow.mutateAsync({
          numbers: [],
          owner_id: device.id,
          owner_type: 'device',
          type: 'main',
          strategy: {
            type: 'blank',
            data: {
              modules: [],
            },
          },
        });
        // console.log('deviceMainCallflowResp', deviceMainCallflowResp);
        if (deviceMainCallflowResp?.success !== true) {
          throw new Error(deviceMainCallflowResp);
        }
        // const deviceMainCallflow = deviceMainCallflowResp.data;
      } catch (err) {
        console.error('Failed saving deviceMainCallflow', err);
        throw new Error('Failed saving deviceMainCallflow');
      }
    } catch (err) {
      console.error('Failed to save:', err);
    }
    refetch();

    setIsCreating(null);
  };

  return { handleCreate, isCreating };
};

export default CreateWebphone;

// Default POST-building function
import buildResourceUrl from './buildResourceUrl';
import { post } from './methods';

// Default POST-building function
// - uses buildResourceUrl and passes thru options from opts.buildUrlOpts
export const buildPostDefault =
  (resourceApiPathname, opts) => async (doc, auth_token) => {
    // uses a POST replace full media, etc. obj

    // TODO: any checks on POST?

    return postResourceDefault({
      url: buildResourceUrl(
        resourceApiPathname,
        doc.id,
        auth_token,
        opts?.buildUrlOpts,
      ),
      doc,
      auth_token,
    });
  };

export const postResourceDefault = async ({ url, doc, auth_token }) => {
  const resp = await post(url, doc, { auth_token });
  return resp.data;
};

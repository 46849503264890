import { useMutation } from 'react-query';
import constants from 'app/constants';

export const useWebhook = () => {
  return useMutation(async (data: any) => {
    console.log('data:', data);

    const resp = await fetch(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/v1/webhooks/messaging`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      },
    );
    console.log('resp:', resp);
    // const respJson = await resp.json();
    // console.log('resp result:', respJson);
    // return respJson;
    const respText = await resp.text();
    console.log('resp result:', respText);
    return respText;
  });
};

export default useWebhook;

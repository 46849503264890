const deviceQueryKeys = {
  all: ['device'] as const,
  list: (skip: number, take: number, sortBy: any, filters: any, mask: any) =>
    [
      ...deviceQueryKeys.all,
      'list',
      skip,
      take,
      sortBy,
      filters,
      mask,
    ] as const,
  listQuick: (
    skip: number,
    take: number,
    sortBy: any,
    filters: any,
    mask: any,
  ) =>
    [
      ...deviceQueryKeys.all,
      'list-quick',
      skip,
      take,
      sortBy,
      filters,
      mask,
    ] as const,
  byId: (id?: string | null) => [...deviceQueryKeys.all, id] as const,
};

export default deviceQueryKeys;

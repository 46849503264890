import { useLocalSlice } from 'app/data/local';
import {
  AnimationDialog,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'app/design';
import { store } from 'store';
import { DialogHeader } from 'app/design-lib';
import {
  Block as BlockIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { useDeleteCallflow } from 'app/hooks/mutations/callflow';
import {
  useDeleteDevice,
  useUpdateDevicePartial,
} from 'app/hooks/mutations/device';
import { useMutation, useQueryClient } from 'react-query';
import constants from 'app/constants';
import { useDeleteUser } from 'app/hooks/mutations/user';
import { useDeleteVmbox } from 'app/hooks/mutations/vmbox';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { User } from 'types/user';
import { useImmer } from 'use-immer';
import { gql, request } from 'graphql-request';

interface DeleteProps {
  selectedRows?: User[];
  onComplete: () => void;
  onCancel: () => void;
  toggleRowSelected: (id: string, set: boolean) => void;
}

const mutationDefault = () => ({
  status: null,
  ignoreVmboxes: [],
  ignoreDevices: [],
  ignoreCallRoutes: [],
});

interface DeleteBulkUsersWrapperProps extends DeleteProps {
  open: boolean;
}
const DeleteBulkUsersWrapper = ({
  open,
  ...props
}: DeleteBulkUsersWrapperProps) => {
  return (
    <AnimationDialog maxWidth={'lg'} open={open}>
      <DeleteBulkUsers {...props} />
    </AnimationDialog>
  );
};

const DeleteBulkUsers = ({
  selectedRows: selectedUsersOrig,
  toggleRowSelected,
  onComplete,
  onCancel,
}: DeleteProps) => {
  const queryClient = useQueryClient();
  const [selectedUsers] = useState(cloneDeep(selectedUsersOrig));
  const deleteUser = useDeleteUser();
  const deleteVmbox = useDeleteVmbox();
  const deleteCallflow = useDeleteCallflow();
  const deleteDevice = useDeleteDevice();
  const updateDevice = useUpdateDevicePartial();

  const { data: authenticatedUser } = useAuthenticatedUserQuery();

  const deleteIsPending =
    deleteUser.isLoading ||
    deleteVmbox.isLoading ||
    deleteCallflow.isLoading ||
    deleteDevice.isLoading ||
    updateDevice.isLoading;

  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
      ignoreVmboxes: string[];
      ignoreDevices: string[];
      ignoreCallRoutes: string[];
    }[]
  >(() => selectedUsers?.map(user => mutationDefault()) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);
  const { actions } = useLocalSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    setMutationArray(selectedUsers?.map(user => mutationDefault()) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  if (!selectedUsers)
    return <Typography variant={'error'}>No users selected!</Typography>;

  const handleDelete = async () => {
    // prevent invalidation from delete mutation from causing list display issues
    // this is re-enabled from onComplete of bulk actions
    dispatch(actions.queries_enabled(false));
    let isError = false;
    for (let i = 0; i < selectedUsers.length; i++) {
      if (isError) break;

      const user = selectedUsers[i];

      // skip suspending current user if selected
      if (user.id === authenticatedUser?.id) {
        continue;
      }

      // // user already suspended
      // if (!user.doc.enabled) {
      //   setMutationArray(prevState => {
      //     prevState[i].status = 'success';
      //   });
      //   continue;
      // }

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      try {
        // archive user's personal Pipe
        // - this will NOT delete associated Shared Extensions for a user, only their Personal
        const pipeQueryResponse = await request(
          `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
          gql`
            query pipes($filters: JSON) {
              pipes(filters: $filters) {
                pipes {
                  id
                  key
                }
              }
            }
          `,
          {
            filters: {
              key: user.doc.presence_id,
            },
          },
          // @ts-ignore
          { authorization: store.getState().auth.auth_token },
        );

        // console.log('pipes queryResponse:', queryResponse);

        const pipe = pipeQueryResponse.pipes?.pipes?.[0];
        // console.log('PIPE2:', { pipe, pipeQueryResponse });

        if (pipe) {
          const archiveResp = await request(
            `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
            gql`
              mutation pipeArchiveById($id: ID) {
                pipeArchiveById(id: $id) {
                  success
                  message
                  data
                }
              }
            `,
            {
              id: pipe.id,
            },
            // @ts-ignore
            { authorization: store.getState().auth.auth_token },
          );
        }

        // delete user
        await deleteUser.mutateAsync(user.id, {
          // onSuccess: () => {
          //   setMutationArray(prevState => {
          //     prevState[i].status = 'success';
          //   });
          // },
          // onError: error => {
          //   setMutationArray(prevState => {
          //     prevState[i].status = 'error';
          //   });
          //   setError(error);
          // },
        });
        // toggleRowSelected(user.id, false);

        // // delete user vmboxes if they exist
        // if (user.Vmboxes.length) {
        //   for (let index = 0; index < user.Vmboxes.length; index++) {
        //     const vmboxId = user.Vmboxes[index].id;

        //     // skip if not checked
        //     if (mutationArray[i].ignoreVmboxes.includes(vmboxId)) continue;

        //     await deleteVmbox.mutateAsync(vmboxId);
        //   }
        // }

        // delete / release to pool user devices if they exist
        if (user.Devices.length) {
          for (let index = 0; index < user.Devices.length; index++) {
            const device = user.Devices[index];

            // skip if not checked
            if (mutationArray[i].ignoreDevices.includes(device.id)) continue;

            // sip device?
            if (device.doc.device_type === 'sip_device') {
              // release to device pool
              await updateDevice.mutateAsync({ id: device.id, owner_id: null });
            } else {
              // delete device
              await deleteDevice.mutateAsync(device.id);
            }
          }
        }

        // // delete user call routes if they exist
        // if (user.Callflows.length) {
        //   for (let index = 0; index < user.Callflows.length; index++) {
        //     // skip if not checked
        //     if (
        //       mutationArray[i].ignoreCallRoutes.includes(
        //         user.Callflows[index].id,
        //       )
        //     )
        //       continue;

        //     await deleteCallflow.mutateAsync(user.Callflows[index].id);
        //   }
        // }

        // set success
        setMutationArray(prevState => {
          prevState[i].status = 'success';
        });
      } catch (e: any) {
        setMutationArray(prevState => {
          prevState[i].status = 'error';
        });
        setError(e);
        isError = true;
      }

      queryClient.invalidateQueries({
        queryKey: ['pipes'],
      });
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    }
  };

  const handleDone = () => {
    onComplete();
    dispatch(actions.queries_enabled(true));
    deleteUser.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  const isDone = mutationArray.every(m => m.status === 'success');

  return (
    <>
      {deleteIsPending ? null : (
        <>
          <DialogHeader title={'Delete Users'} onClose={onCancel} />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {isDone
            ? `Successfully deleted user(s):`
            : deleteIsPending
            ? `Deleting user(s)...`
            : error
            ? `Error deleting user(s):`
            : `Are you sure you want to delete ${
                selectedUsers.filter(user => user.id !== authenticatedUser?.id)
                  .length
              } user(s)?`}
        </Typography>
        <br />
        <div>
          <Table>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              {/* <TableCell>Voicemail Boxes</TableCell> */}
              <TableCell>
                Devices <br />
                <Typography variant={'caption'}>
                  (Physical devices will be released to device pool)
                </Typography>
              </TableCell>
              {/* <TableCell>Call Routes</TableCell> */}
            </TableRow>
            {selectedUsers.map((user, index) => {
              const releaseDevices = user.Devices.filter(
                device => device.doc.device_type === 'sip_device',
              );

              return (
                <TableRow>
                  {user.id === authenticatedUser?.id ? (
                    <>
                      <Tooltip
                        arrow
                        placement={'left-end'}
                        title={`You cannot delete yourself. This action will be skipped.`}
                      >
                        <TableCell>
                          <BlockIcon sx={{ color: 'text.secondary' }} />
                        </TableCell>
                      </Tooltip>
                      <TableCell>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontWeight: 'bold',
                          }}
                        >
                          {`${user.doc.presence_id}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontWeight: 'bold',
                          }}
                        >
                          {`${user.extra.fullName}`}
                        </Typography>
                        {user.doc.priv_level === 'admin' ? (
                          <Chip
                            label={'Admin'}
                            color={'primary'}
                            size={'small'}
                          />
                        ) : null}
                      </TableCell>
                      {/* <TableCell></TableCell> */}
                      <TableCell></TableCell>
                      {/* <TableCell></TableCell> */}
                    </>
                  ) : (
                    <>
                      <TableCell>
                        {mutationArray[index].status === 'loading' ? (
                          <CircularProgress size={20} />
                        ) : mutationArray[index].status === 'success' ? (
                          <CheckIcon color={'success'} />
                        ) : mutationArray[index].status === 'error' ? (
                          <ClearIcon color={'error'} />
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {`${user.doc.presence_id}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {`${user.extra.fullName}`}
                        </Typography>
                        {user.doc.priv_level === 'admin' ? (
                          <Chip
                            label={'Admin'}
                            color={'primary'}
                            size={'small'}
                          />
                        ) : null}
                      </TableCell>
                      {/* <TableCell>
                        {user.Vmboxes.length ? (
                          user.Vmboxes.map(vmbox => {
                            const checked = !mutationArray[
                              index
                            ].ignoreVmboxes.includes(vmbox.id);

                            const handleClick = () => {
                              setMutationArray(prevValue => {
                                if (checked) {
                                  prevValue[index].ignoreVmboxes = [
                                    ...prevValue[index].ignoreVmboxes,
                                    vmbox.id,
                                  ];
                                } else {
                                  prevValue[index].ignoreVmboxes = prevValue[
                                    index
                                  ].ignoreVmboxes.filter(id => id !== vmbox.id);
                                }
                              });
                            };

                            return (
                              <Grid container spacing={1} alignItems={'center'}>
                                <Grid item>
                                  <Checkbox
                                    disabled={deleteIsPending || isDone}
                                    checked={checked}
                                    onClick={handleClick}
                                    sx={{ fontSize: 18 }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography>{` - ${vmbox.doc.name} (${vmbox.doc.mailbox})`}</Typography>
                                </Grid>
                              </Grid>
                            );
                          })
                        ) : (
                          <Typography
                            color={'gray'}
                            sx={{ fontStyle: 'italic' }}
                          >
                            None
                          </Typography>
                        )}
                      </TableCell> */}
                      <TableCell>
                        {releaseDevices.length ? (
                          releaseDevices.map(device => {
                            const checked = !mutationArray[
                              index
                            ].ignoreDevices.includes(device.id);

                            const handleClick = () => {
                              setMutationArray(prevValue => {
                                if (checked) {
                                  prevValue[index].ignoreDevices = [
                                    ...prevValue[index].ignoreDevices,
                                    device.id,
                                  ];
                                } else {
                                  prevValue[index].ignoreDevices = prevValue[
                                    index
                                  ].ignoreDevices.filter(
                                    id => id !== device.id,
                                  );
                                }
                              });
                            };

                            return (
                              <Grid container spacing={1} alignItems={'center'}>
                                <Grid item>
                                  <Checkbox
                                    disabled={deleteIsPending || isDone}
                                    checked={checked}
                                    onClick={handleClick}
                                    sx={{ fontSize: 18 }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography>{`- ${device.doc.name} (to pool)`}</Typography>
                                </Grid>
                              </Grid>
                            );
                          })
                        ) : (
                          <>
                            <Typography
                              color={'gray'}
                              sx={{ fontStyle: 'italic' }}
                            >
                              None
                            </Typography>
                          </>
                        )}
                      </TableCell>
                      {/* <TableCell>
                        {user.Callflows.length ? (
                          user.Callflows.map(cf => {
                            const checked = !mutationArray[
                              index
                            ].ignoreCallRoutes.includes(cf.id);

                            const handleClick = () => {
                              setMutationArray(prevValue => {
                                if (checked) {
                                  prevValue[index].ignoreCallRoutes = [
                                    ...prevValue[index].ignoreCallRoutes,
                                    cf.id,
                                  ];
                                } else {
                                  prevValue[index].ignoreCallRoutes = prevValue[
                                    index
                                  ].ignoreCallRoutes.filter(id => id !== cf.id);
                                }
                              });
                            };

                            return (
                              <Grid container spacing={1} alignItems={'center'}>
                                <Grid item>
                                  <Checkbox
                                    disabled={deleteIsPending || isDone}
                                    checked={checked}
                                    onClick={handleClick}
                                    sx={{ fontSize: 18 }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography>{`- ${
                                    cf.doc.name ?? 'Unnamed Call Route'
                                  }`}</Typography>
                                </Grid>
                              </Grid>
                            );
                          })
                        ) : (
                          <Typography
                            color={'gray'}
                            sx={{ fontStyle: 'italic' }}
                          >
                            None
                          </Typography>
                        )}
                      </TableCell> */}
                    </>
                  )}
                </TableRow>
              );
            })}
          </Table>
        </div>
      </DialogContent>
      {!deleteIsPending ? (
        <DefaultDialogActions
          saveLabel={deleteUser.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : deleteUser.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error ? undefined : deleteUser.isSuccess ? handleDone : handleDelete
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

export default DeleteBulkUsersWrapper;

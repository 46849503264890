import { AddUsersFormFields } from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/types';
import { validateEmail } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard/components/NotificationEmailsDialog/NotificationEmailsDialog';
import { setAtPath } from 'app/utilities';
import { validateFormRequired } from 'app/utilities/utils';
import { isNumber } from 'lodash';
import { UnpackNestedValue } from 'react-hook-form';
import { Extension } from 'types/extension';
import { User } from 'types/user';
import { Vmbox } from 'types/vmbox';

const addBulkUsersFormResolver =
  (
    existingUsers: User[],
    existingExtensions: Extension[],
    existingVmboxes: Vmbox[],
    setFormConflicts: ({ formEmails, formPresenceIds }) => void,
  ) =>
  (values: UnpackNestedValue<AddUsersFormFields>, context, options) => {
    const errors = {};
    // add existing extensions/emails to tracking obj
    const exisingExts = {},
      existingEmails = {},
      existingVmboxNumbers = {},
      formPresenceIds: { [key: string]: number } = {},
      formEmails: { [key: string]: number } = {};
    existingUsers.forEach(user => {
      exisingExts[user.doc.presence_id] = true;

      // TODO: verify using username vs email on user doc?
      if (user.doc.username) existingEmails[user.doc.username] = true;
    });

    // console.log('existing ext', exisingExts);

    existingVmboxes.forEach(vmbox => {
      existingVmboxNumbers[vmbox.doc.mailbox] = true;
    });

    values.users.forEach((userFields, index) => {
      // track field presence IDs for conflicts
      const currentPresId = userFields.presence_id;
      if (currentPresId) {
        formPresenceIds[currentPresId] =
          (formPresenceIds[currentPresId] ?? 0) + 1;

        if (formPresenceIds[currentPresId] > 1) {
          // @ts-ignore
          errors.conflictingField = true;
        }
      }

      // track field presence IDs for conflicts
      const currentEmail = userFields.info.email;
      if (currentEmail) {
        formEmails[currentEmail] = (formEmails[currentEmail] ?? 0) + 1;

        if (formPresenceIds[currentPresId] > 1) {
          // @ts-ignore
          errors.conflictingField = true;
        }
      }

      // skip saved fields for re-validation on error
      if (userFields.save_status === 'error' || !userFields.save_status) {
        validatePresenceId(userFields, index, errors, exisingExts);
        validateInfo(userFields, index, errors, existingEmails);
        validateVmbox(userFields, index, errors, existingVmboxNumbers);
      }
    });

    setFormConflicts({ formEmails, formPresenceIds });

    return { values, errors };
  };

const validateVmbox = (userFields, index, errors, existingVmboxNumbers) => {
  // only validate if creating vmbox
  // if vmbox mailbox # exists
  if (
    userFields.voicemail.create &&
    existingVmboxNumbers[userFields.presence_id]
  ) {
    // NOTE: path is not a mistake, piggy backing off presence_id due to the way
    // react-hook-form clears errors
    setAtPath(
      errors,
      `users.${index}.presence_id.voicemail.message`,
      'Mailbox number is in use: please change the users corresponding presence ID',
    );
  }
};

const validateInfo = (userFields, index, errors, existingEmails) => {
  // first/last name
  //  1. required
  // TODO: min length or other req?
  // email
  // 1. valid email
  // 2. required
  // 3. account unique
  // first name not set?
  if (validateFormRequired(userFields.info.first_name)) {
    // first name not set, add error message
    setAtPath(
      errors,
      `users.${index}.info.first_name.message`,
      `First name is required`,
    );
  }

  // last name not set?
  if (validateFormRequired(userFields.info.last_name)) {
    // last name not set, add error message
    setAtPath(
      errors,
      `users.${index}.info.last_name.message`,
      `Last name is required`,
    );
  }

  // email not set?
  if (validateFormRequired(userFields.info.email)) {
    // email not set, add error message
    setAtPath(errors, `users.${index}.info.email.message`, `Email is required`);
  } else {
    if (existingEmails[userFields.info.email]) {
      // email used, add error message
      setAtPath(
        errors,
        `users.${index}.info.email.message`,
        `Email not available: used by existing user`,
      );
    } else {
      // email free, add to list of existing
      existingEmails[userFields.info.email] = index;
    }

    // valid email
    if (!validateEmail(userFields.info.email)) {
      setAtPath(
        errors,
        `users.${index}.info.email.message`,
        `Invalid email format`,
      );
    }
  }

  // validate password
  if (
    !userFields.info.rand_password &&
    validateFormRequired(userFields.info.password)
  ) {
    setAtPath(
      errors,
      `users.${index}.info.password.message`,
      `Password is required`,
    );
  }
};

const validatePresenceId = (userFields, index, errors, exisingExts) => {
  // Presence ID
  //  1. required
  //  2. cannot repeat on form
  //  3. cannot already be used by existing resource
  // TODO: min length or other requirements?

  if (userFields.presence_id.length <= 4) {
    // presence ID is not set?
    if (Number(userFields.presence_id) < 0) {
      setAtPath(
        errors,
        `users.${index}.presence_id.message`,
        `Presence ID must be a postive value`,
      );
    } else if (validateFormRequired(userFields.presence_id)) {
      // ext not set, add error message
      setAtPath(
        errors,
        `users.${index}.presence_id.message`,
        `Presence ID is required`,
      );
    } else {
      if (exisingExts[userFields.presence_id]) {
        setAtPath(
          errors,
          `users.${index}.presence_id.message`,
          `Presence ID not available: used by existing user`,
        );
      } else {
        // ext free, add to list of existing
        exisingExts[userFields.presence_id] = index;
      }
    }
  } else {
    setAtPath(
      errors,
      `users.${index}.presence_id.message`,
      `Presence ID can not exceed 4 digits`,
    );
  }
};

export default addBulkUsersFormResolver;

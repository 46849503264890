import { useLocalSlice } from 'app/data/local';
import {
  AnimationDialog,
  CircularProgress,
  DialogContent,
  Divider,
  Table,
  TableCell,
  TableRow,
  Typography,
} from 'app/design';

import { Dialog, DialogHeader, DialogTitle } from 'app/design-lib';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { useDeleteDevice } from 'app/hooks/mutations/device';
import parseKazooMutationErrorMessage from 'app/utilities/parseKazooMutationErrorMessage';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Device } from 'types/device';
import { useImmer } from 'use-immer';

interface DeleteProps {
  selectedRows?: Device[];
  onComplete: () => void;
  onCancel: () => void;
  toggleRowSelected: (id: string, set: boolean) => void;
}

interface DeleteBulkDevicesWrapperProps extends DeleteProps {
  open: boolean;
}
const DeleteBulkDevicesWrapper = ({
  open,
  ...props
}: DeleteBulkDevicesWrapperProps) => {
  return (
    <Dialog size={'lg'} open={open} onClose={props.onCancel}>
      <DeleteBulkDevices {...props} />
    </Dialog>
  );
};

const DeleteBulkDevices = ({
  selectedRows: selectedDevicesOrig,
  toggleRowSelected,
  onComplete,
  onCancel,
}: DeleteProps) => {
  const deleteDevice = useDeleteDevice();
  const { isLoading } = deleteDevice;
  const { actions } = useLocalSlice();
  const dispatch = useDispatch();
  const [selectedDevices] = useState(cloneDeep(selectedDevicesOrig));
  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
    }[]
  >(() => selectedDevices?.map(device => ({ status: null })) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  // useEffect(() => {
  //   setMutationArray(selectedDevices?.map(device => ({ status: null })) ?? []);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedDevices]);

  if (!selectedDevices)
    return <Typography variant={'error'}>No devices selected!</Typography>;

  const handleDelete = async () => {
    // prevent invalidation from delete mutation from causing list display issues
    // this is re-enabled from onComplete of bulk actions
    dispatch(actions.queries_enabled(false));
    for (let i = 0; i < selectedDevices.length; i++) {
      if (error) break;

      const device = selectedDevices[i];

      // // device already suspended
      // if (!device.doc.enabled) {
      //   setMutationArray(prevState => {
      //     prevState[i].status = 'success';
      //   });
      //   continue;
      // }

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      try {
        const resp = await deleteDevice.mutateAsync(device.id);
        // toggleRowSelected(device.id, false);
        console.log('resp', resp);
        if (resp.status === 'success') {
          setMutationArray(prevState => {
            prevState[i].status = 'success';
          });
        } else {
          setMutationArray(prevState => {
            prevState[i].status = 'error';
          });
          setError(resp.error);
        }
      } catch (e: any) {
        setMutationArray(prevState => {
          prevState[i].status = 'error';
        });
        setError(e);
      }
    }
  };

  const handleDone = () => {
    onComplete();
    dispatch(actions.queries_enabled(true));
    deleteDevice.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <DialogHeader title={'Delete Device(s)'} onClose={onCancel} />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {deleteDevice.isSuccess
            ? `Successfully deleted device(s):`
            : deleteDevice.isLoading
            ? `Deleting device(s)...`
            : error
            ? `Error deleting device(s):`
            : `Are you sure you want to delete ${selectedDevices.length} device(s)?`}
        </Typography>
        <br />
        <div>
          <Table>
            {selectedDevices.map((device, index) => (
              <TableRow sx={{ height: 65 }}>
                <TableCell>
                  {mutationArray[index].status === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : mutationArray[index].status === 'success' ? (
                    <CheckIcon color={'success'} />
                  ) : mutationArray[index].status === 'error' ? (
                    <ClearIcon color={'error'} />
                  ) : null}
                </TableCell>
                <TableCell>
                  <Typography>{device.doc.name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </DialogContent>
      {!isLoading ? (
        <DefaultDialogActions
          saveLabel={deleteDevice.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : deleteDevice.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error
              ? undefined
              : deleteDevice.isSuccess
              ? handleDone
              : handleDelete
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

export default DeleteBulkDevicesWrapper;

import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import { DoubleCheck } from 'iconoir-react';

import { getAtPath, setAtPath } from 'app/utilities';

// import { USER_ADDABLE_COMPONENTS } from '../../../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../../../Strategies/components';

import { ChooseDialog } from '../../ChooseDialog';
import { IconButton } from '../../../../../../../design-lib';
import { LongArrowUpLeft } from 'iconoir-react';
import { Tooltip } from '@mui/material';
import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../../..';
import { walkStrategyModules } from '../../../../walkStrategyModules';
import { v4 as uuidv4 } from 'uuid';

export const MenuItem_AddBefore = props => {
  const { data, mode, onClose } = props;
  const {
    callflow: rootCallflow,
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    currentCallflow,
    componentData,
    componentInfo,
    templateParent,
    // callflow,
    // setCallflow,
    // modifyPath,
  } = data;

  const { callflow, setCallflow, modifyPath } = insertAfterData;

  const [showInsertBefore, setShowInsertBefore] = useState(null);
  const setShowInsertBeforeWrap = setTo => () => setShowInsertBefore(setTo);

  return (
    <>
      {showInsertBefore && (
        <ChooseDialog
          {...insertAfterData}
          requireAllowAfter={true}
          index={insertAfterData.index - 1}
          onClose={setShowInsertBeforeWrap(null)}
        />
      )}
      <Tooltip placement={'right'} title={'Insert before'} arrow>
        <IconButton
          pill
          onClick={e => {
            // if (rootCallflow.type != 'template' && templateParent) {
            //   alert(
            //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
            //   );
            // }
            // onClose();
            setShowInsertBefore(true);
          }}
          size={'sm'}
          variant={'ghost'}
        >
          <LongArrowUpLeft />
        </IconButton>
      </Tooltip>
      <MenuItem_Duplicate {...props} />

      {/*<MenuItem
        onClick={e => {
          // if (rootCallflow.type != 'template' && templateParent) {
          //   alert(
          //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
          //   );
          // }
          onClose();
          setShowInsertBefore(true);
        }}
        component={'a'}
      >
        <ListItemIcon>
          <PresentToAllIcon />
        </ListItemIcon>
        <ListItemText primary="Add Before" />
      </MenuItem>*/}
    </>
  );
};

export const MenuItem_Duplicate = props => {
  const { data, mode, onClose } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    callflow: rootCallflow,
    currentCallflow,
    componentData,
    componentInfo,
    templateParent,
    // callflow,
    // setCallflow,
    modifyPath,
  } = data;

  // const { callflow, setCallflow, modifyPath } = insertAfterData;

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const ee = useContext(IvrMenuEventEmitterContext);
  const handleDuplicateTo = () => {
    // alert('duplicate to');
    // if (rootCallflow.type != 'template' && templateParent) {
    //   alert(
    //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!'
    //   );
    // }
    // console.log('current', currentCallflow, data);
    let modules = JSON.parse(
      JSON.stringify(currentCallflow.strategy.data.modules.slice(infoIdx)),
    );
    // const componentInfo =
    //   currentCallflow.strategy.config.components.slice(infoIdx);
    // const componentData = currentCallflow.strategy.data.opts.slice(infoIdx);

    console.log('infoIdx:', infoIdx);
    console.log('modules:', modules);

    // walk and create new IDs
    // - for schedules: new scheduleID
    const newSchedules = {};
    const newCallflowExample = {
      id: 'inline',
      schedules: rootCallflow.schedules,
      strategy: {
        type: 'blank',
        data: {
          modules,
        },
      },
    };

    // modify by reference works
    walkStrategyModules(
      newCallflowExample,
      ({ module, callflow: rootCallflow }) => {
        module.id = uuidv4();
        console.log('MODULE:', module);
        if (module.type === 'Schedule') {
          // TODO: do not recreate scheduleId if schedule is "shared" (by default, each is entirely separate)
          let newScheduleId = uuidv4();
          newSchedules[newScheduleId] = JSON.parse(
            JSON.stringify(rootCallflow.schedules[module.data.schedule_id]),
          );

          newSchedules[
            newScheduleId
          ].name = `${newSchedules[newScheduleId].name} (Copy)`;
          module.data.schedule_id = newScheduleId;
        }
      },
    );
    modules = newCallflowExample.strategy.data.modules;

    setSharedFlow(s => ({
      ...s,
      state: 'duplicate-to',
      data: {
        // array to concat to wherever it is being duplicated
        // - TODO: different way of doing this? create a new strategy/callflow each time?
        path: modifyPath,
        duplicateJson: JSON.parse(
          JSON.stringify({
            newSchedules,
            modules,
            // componentInfo,
            // componentData,
          }),
        ),
      },
    }));
  };

  return (
    <Tooltip placement={'right'} title={'Duplicate'} arrow>
      <IconButton
        pill
        onClick={e => {
          // if (rootCallflow.type != 'template' && templateParent) {
          //   alert(
          //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
          //   );
          // }
          // onClose();
          handleDuplicateTo();
        }}
        size={'sm'}
        variant={'ghost'}
      >
        <DoubleCheck />
      </IconButton>
    </Tooltip>
  );

  // return (
  //   <MenuItem
  //     onClick={e => {
  //       onClose();
  //       handleDuplicateTo();
  //     }}
  //     component={'a'}
  //     disabled
  //   >
  //     <ListItemIcon>
  //       <FileCopyIcon />
  //     </ListItemIcon>
  //     <ListItemText primary="Duplicate" />
  //   </MenuItem>
  // );
};

// export const ChooseDialog = (props) => {
//   const {
//     callflow,
//     setCallflow,
//     modifyPath,
//     index,
//     requireAllowBefore, // always false
//     requireAllowAfter,
//     onClose,
//     onAdd, // replaces handleAdd from below!
//   } = props;

//   // modifyPath => to root of callflow!

//   const ee = useContext(IvrMenuEventEmitterContext);

//   // useEffectOnce(() => {
//   //   ee.on('focus-node', focusNode);
//   //   return () => {
//   //     ee.removeListener('focus-node', focusNode);
//   //   };
//   // });

//   // console.log('InsertNode:', data);

//   const handleAdd = (selected) => {
//     // const optKey = selected.value;
//     // const optionComponent = OptionComponents[optKey];

//     // TODO: add "recently added" to the data? or use eventemitter?
//     // - triggers self to run?
//     // - problem if multiple things added??

//     const data = {
//       type: selected.value.type,
//       options: selected.value.options,
//     };

//     // const data = {
//     //   type: 'MenuGreetingAndTargets',
//     // };
//     const components = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.config.components`,
//       []
//     );
//     const stratDataOpts = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.data.opts`,
//       []
//     );
//     // console.log('Adding to index:', index);

//     components.splice(index, 0, data);
//     stratDataOpts.splice(index, 0, {});

//     setAtPath(callflow, `${modifyPath}.strategy.config.components`, components);
//     setAtPath(callflow, `${modifyPath}.strategy.data.opts`, stratDataOpts);

//     // console.log('set after insert:', callflow);

//     setCallflow({ ...callflow }, { name: `Inserted ${selected.value.type}` });

//     // // TODO: focus after rebuild, instead of on a Timeout
//     // window.setTimeout(() => {
//     //   ee.emit('focus-node', {
//     //     key: `${modifyPath}.strategy.config.components[${index}]`,
//     //   });
//     // }, 100);

//     // TODO: focus after rebuild, instead of on a Timeout
//     window.setTimeout(() => {
//       ee.emit('node-created', {
//         key: `${modifyPath}.strategy.config.components[${index}]`,
//         optsPath: `${modifyPath}.strategy.data.opts[${index}]`,
//       });
//     }, 100);
//   };

//   return (
//     <Dialog open onClose={onClose} fullWidth={true} maxWidth="sm">
//       {/* <DialogTitle>Select Option</DialogTitle>
//       <Divider /> */}
//       <DialogContent>
//         <StrategySelect
//           requireAllowBefore={false}
//           requireAllowAfter={requireAllowAfter}
//           onChange={onAdd || handleAdd}
//         />
//       </DialogContent>
//       <Divider />
//       <DialogActions>
//         <Button
//           color="secondary"
//           variant="outlined"
//           onClick={(e) => {
//             onClose();
//           }}
//         >
//           Cancel
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export const StrategySelect = (props) => {
//   const {
//     placeholder,
//     preventComplexBeforeAfter,
//     requireAllowAfter, // determines if the Selectable module is required to have the "allowAfter" value set
//     requireAllowBefore, // determines if the Selectable module is required to have the "allowBefore" value set
//     onChange,
//     selected,
//   } = props;

//   const [anchorEl, setAnchorEl] = useState(null);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   // TODO: this list of options should be from a different list of possible "before" and "after" possible components
//   // - type, options[props] like in Strategies.componentConfig.components
//   const options = USER_ADDABLE_COMPONENTS.map((component) => {
//     // console.log('optKey:', optKey);
//     const module = OptionComponents[component.type];
//     // console.log('type:', component.type);
//     const allow = requireAllowBefore
//       ? module.allowBefore === true
//         ? true
//         : false
//       : true && requireAllowAfter
//       ? module.allowAfter === true
//         ? true
//         : false
//       : true;
//     return allow ? { ...component, allow: true } : component;
//   }).map((component) => ({
//     label: component.name,
//     value: component,
//     allow: component.allow,
//     type: component.type,
//   }));

//   const opt = null;
//   return (
//     <>
//       {options
//         .filter((opt) => opt.allow)
//         .map((opt) => (
//           <Paper
//             key={opt.label}
//             lcolor={
//               JSON.stringify(opt) === JSON.stringify(selected)
//                 ? 'teal.50'
//                 : 'grey.50'
//             }
//             onClick={() => onChange(opt)}
//             padding={1}
//             style={{ margin: '4px 0' }}
//             elevation={0}
//             elevateOnHover
//           >
//             <Grid container spacing={1} alignItems="center" noWrap>
//               <Grid item>
//                 <Icon style={{ verticalAlign: 'text-top' }} fontSize="small">
//                   {opt.value.icon ?? 'help_outline'}
//                 </Icon>
//               </Grid>
//               <Grid item>
//                 <Typography variant="body2" strong>
//                   {opt.label}
//                 </Typography>
//               </Grid>
//             </Grid>
//           </Paper>
//         ))}
//     </>
//   );
// };

export default MenuItem_AddBefore;

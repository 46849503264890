import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import {
  buildDeleteDefault,
  buildPatchDefault,
  buildPutDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    contacts: (vars, fetchOptions) =>
      fetchFromGraphql('contacts', vars, fetchOptions),
    maybeContacts: (vars, fetchOptions) =>
      fetchFromGraphql('maybeContacts', vars, fetchOptions),
    // // Fetch a single contact by id
    // contactById: (vars, fetchOptions) =>
    //   fetchFromGraphql('contactById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    create: (vars, fetchOptions) =>
      mutateGraphql('contactCreate', vars, fetchOptions),

    updatePartial: (vars, fetchOptions) =>
      mutateGraphql('contactUpdateById', vars, fetchOptions),

    delete: (vars, fetchOptions) =>
      mutateGraphql('contactDeleteById', vars, fetchOptions),

    // createDev: buildPutDefault('groups', {
    //   buildUrlOpts: { skipId: true, skipInternalSync: true },
    // }),
  },
};

export default exports;

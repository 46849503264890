// const createTemporalRulePlugin = () => ({
//   pluginKeys: ELEMENT_TEMPORAL_RULE,
//   renderElement: getRenderElement(ELEMENT_TEMPORAL_RULE),
//   deserialize: getParagraphDeserialize(),
// });
import { createPluginFactory } from '@udecode/plate-core';

// export const createCommentPlugin = () => ({
//   renderLeaf: getRenderLeaf(MARK_COMMENT),
//   decorate: getCommentDecorate(),
// });
export const ELEMENT_TEMPORAL_RULE = 'temporalRule';

/**
 * Enables support for value formatting
 */
export const createTemporalRulePlugin = createPluginFactory({
  key: ELEMENT_TEMPORAL_RULE,
  isElement: true,
  handlers: {},
});

export default createTemporalRulePlugin;

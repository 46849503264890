import React from 'react';
import { Vsb } from './Vsb';

const Onboarding = () => {
  // Determine which onboarding flow to use
  // const [state, setState] = useState({
  //   open: true,
  //   onboarding_type: null,
  //   data: {
  //     // for dentist, etc
  //   },
  // });
  //
  // const dispatch = useDispatch();

  return (
    // <Dialog
    //   open={state.open}
    //   fullWidth
    //   maxWidth={'sm'}
    //   scroll="body"
    //   // onClose={e => setState({ ...state, open: false })}
    // >
    //   <>
    //     <DialogContent>
    <Vsb />
    // {/* <Generic /> */}
    // {/* {state.onboarding_type === 'generic' ? <Generic /> : null}
    // {state.onboarding_type === 'dentist' ? <Dentist /> : null}
    // {!state.onboarding_type ? (
    //   <>
    //     <Typography variant="h6">Onboarding experience:</Typography>
    //     <Button
    //       onClick={e =>
    //         setState({ ...state, onboarding_type: 'generic' })
    //       }
    //       variant="outlined"
    //       size="small"
    //     >
    //       Use a Simple Setup
    //     </Button>
    //     &nbsp; or, I'm a{' '}
    //     <select>
    //       <option>dentist</option>
    //     </select>
    //     ! Help me &nbsp;
    //     <Button
    //       onClick={e =>
    //         setState({ ...state, onboarding_type: 'dentist' })
    //       }
    //       variant="outlined"
    //       size="small"
    //     >
    //       Setup My Account
    //     </Button>
    //   </>
    // ) : null} */}
    //     </DialogContent>
    //   </>
    // </Dialog>
  );
};

export default Onboarding;

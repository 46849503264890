import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import HandymanIcon from '@mui/icons-material/Handyman';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { CallflowNumbers } from 'app/components/CallflowNumbers';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { IvrCustomInline, IvrQuickDisplay } from 'app/components/IvrBuilder';
// if we get "ReferenceError: Cannot access 'IvrMenuEventEmitterContext' before initialization"
// - buildOutputCallflowFromTemplate and getSimpleVariables MUST be directly imported!
import { buildOutputCallflowFromTemplate } from 'app/components/IvrBuilder/Flow/nodes/Template/buildOutputCallflowFromTemplate';
import { getSimpleVariables } from 'app/components/IvrBuilder/Flow/nodes/Template/getSimpleVariables';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { QuickFinderScheduleDialog } from 'app/components/QuickFinderScheduleDialog';
import ScheduleEditorDialog from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
// import { useScheduleEditorDialog } from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Paper,
  Tab,
  Typography,
  useTheme,
} from 'app/design';
import {
  AltRoute as AltRouteIcon,
  ArrowCircleDown as ArrowCircleDownIcon,
  CalendarMonth as CalendarMonthIcon,
  Star as StarIcon,
} from 'app/design/icons-material';
import { TabContext, TabList, TabPanel } from 'app/design/lab';
import {
  useCreateCallflow,
  useUpdateCallflowPartial,
} from 'app/hooks/mutations/callflow';
import { useCallflowQuery } from 'app/hooks/queries/callflow';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import { CallRouteNumbers } from 'app/pages/admin/AccountCalling3/components/CallHandlingCard/CallHandlingCard';
import { parseCallflowNumbers, useToggleReducer } from 'app/utilities';
import EventEmitter from 'eventemitter3';
import { clone, cloneDeep, startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Lottie from 'react-lottie-player';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAccountChooseTemplateDialog } from './AccountChooseTemplateDialog';
import { SimpleUIComponent } from './components/SimpleUI/Component';

import TEMPLATES from './templates';

const ee = new EventEmitter();

const SCHEDULE_ICONS = {
  open: <SentimentSatisfiedAltIcon />,
  closed: <BedtimeIcon />,
  skip: <BedtimeIcon />,
  holiday: <AcUnitIcon />,
  holidays: <AcUnitIcon />,
  lunch: <LunchDiningIcon />,
  generic: <AccessTimeIcon />,
};
const getScheduleIcon = timeDetails => {
  if (timeDetails.icon) {
    return SCHEDULE_ICONS[timeDetails.icon];
  }
  if (SCHEDULE_ICONS[timeDetails.id]) {
    return SCHEDULE_ICONS[timeDetails.id];
  }
  return SCHEDULE_ICONS['generic'];
};

let CALLFLOW;
const CallRoutingAccount = ({
  // directoryId,
  name_type,
  type,
  resourceId,
  owner_type,
  defaultNumbers,
  callflow,
  onComplete,
}) => {
  const updateCallflow = useUpdateCallflowPartial();
  const createCallflow = useCreateCallflow();

  // if callflow doesnt exist, create a default one for saving
  let defaultCallflow = callflow?.doc
    ? callflow.doc
    : DEFAULT_CALLFLOW({
        id: undefined,
        name_type,
        type,
        resourceId,
        owner_type,
        numbers: defaultNumbers,
      });

  // console.log(defaultCallflow.strategy.type, 'type');
  // ensure "account" strategy type!
  if (defaultCallflow.strategy.type !== 'account') {
    defaultCallflow = DEFAULT_CALLFLOW({
      id: defaultCallflow.id,
      name_type,
      type,
      resourceId,
      owner_type,
      numbers: defaultNumbers,
    });
  }

  // loaded WITH callflow
  const methods = useForm<any>({
    defaultValues: {
      callflow: defaultCallflow,
      // username: username ?? credentials?.username ?? '',
      // account_name: account_name ?? credentials?.account_name ?? '',
      // password: credentials?.account_name ? credentials?.password : '',
    },
    // resolver: joiResolver(schema),
  });

  const enabledSimple = methods.watch('callflow.strategy.simple.enabled'); // for Advanced/Visual-only editing
  const parent_set = methods.watch('callflow.strategy.template.parent_id'); // can be "custom"!

  const onSubmit = async data => {
    const callflow = clone(data.callflow);
    // const template_base = data.callflow.strategy.data.templateBaseCallflow;
    // const output_base = data.callflow.strategy.data.outputCallflow;

    // rebuild the callflow that we'll insert into the data.callflow.strategy.data.outputCallflow

    // // do NOT need to run the following, cuz it is already done by Simple Components (onChangeSimple re-applies all Simple variables)
    // if (enabledSimple) {
    //   // alert('SIMPLE');
    //   const outputCallflow = await buildOutputCallflowFromTemplate({
    //     // more like "Build Template Strategy using rootCallflow variables"
    //     // - or, can be used on regular Callflows too!
    //     rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
    //     templateCallflow: callflow, // has the "ready for simple" (calculated) values!
    //     checkSimple: true,
    //   });
    //   // add the outputCallflow to the final!
    //   callflow.strategy.data = outputCallflow.strategy.data;
    //   methods.setValue('callflow', callflow, { shouldDirty: true });
    //   console.log('built outputcallflow', outputCallflow);
    // }

    // save THIS callflow! (it has correct values inserted, hopefully!)
    // console.log('Final', callflow);

    CALLFLOW = callflow;
    let resp;
    if (callflow.id) {
      resp = await updateCallflow.mutateAsync({
        id: callflow.id,
        numbers: callflow.numbers,
        strategy: callflow.strategy,
      });
    } else {
      resp = await createCallflow.mutateAsync(callflow);
    }

    if (resp.success) {
      onComplete();
    }
    // return callflow value for handleGotoVisual
    // return callflow;
    methods.reset(null, {
      keepValues: true,
      keepErrors: true,
      keepDirty: false,
      keepIsSubmitted: true,
      keepTouched: true,
      keepIsValid: true,
      keepSubmitCount: true,
    });
  };

  const handleGotoVisual = async () => {
    // console.log('handleGotoVisual');
    // let cf = callflow;
    // if (methods.formState.isDirty) {
    //   await methods.handleSubmit(onSubmit)();
    //   cf = CALLFLOW;
    //   console.log('CF:', cf);
    // } else {
    //   // canceling
    //   return;
    // }
    // if (cf?.id) {
    //   router.push(`/admin/ivr/edit/${cf?.id}`);
    // } else {
    //   console.error('No callflow id to redirect to');
    // }
  };

  const inprogressCallflow = methods.watch('callflow');
  const [inprogressCallflowKey, setInprogressCallflowKey] = useState(0);
  useEffect(() => {
    setInprogressCallflowKey(v => {
      return v + 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inprogressCallflow)]);

  const previewCallflow = methods.watch('preview_template');
  const [previewCallflowKey, setPreviewCallflowKey] = useState(0);
  useEffect(() => {
    setPreviewCallflowKey(v => {
      return v + 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(previewCallflow)]);

  const [modifyArray, setModifyArray] = useState<any[]>([]);
  useEffect(() => {
    const onModify = info => {
      // add to array
      console.log('info1:', info);
      setModifyArray(v => {
        return [...v, info];
      });
    };
    ee.on('MODIFY_CALLFLOW', onModify);
    return () => {
      ee.off('MODIFY_CALLFLOW', onModify);
    };
  }, []);

  const handleDoneCustom = callflow => {
    // set/clear value for array
    if (!callflow) {
      // canceled changes
      setModifyArray(v => {
        v.pop();
        return [...v];
      });
      return;
    }

    const info = modifyArray[modifyArray.length - 1];
    info.onDone(callflow);
    console.log('DONE:', callflow);

    setModifyArray(v => {
      v.pop();
      return [...v];
    });
  };

  // if there is a currently-editing array of modules, we use those
  if (modifyArray.length) {
    console.log('modifyArray:', modifyArray);
    return (
      <ModifyCustom
        {...{
          onDone: handleDoneCustom,
          info: modifyArray[modifyArray.length - 1],
        }}
      />
    );
  }

  return (
    <>
      {/* show each possible  */}
      {modifyArray.map((v, i) => (
        <ModifyCustom
          key={i}
          {...{
            onDone: handleDoneCustom,
            info: v,
          }}
        />
      ))}
      {/* Show the general/normal display for Account Call Routing (hidden when showing an Advanced/Visual/Builder view) */}
      {/* @ts-ignore */}
      <FormProvider {...methods}>
        <form id="routing-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{ display: modifyArray.length ? 'none' : undefined }}>
            <Grid container spacing={2}>
              <Grid item sx={{ flex: 1 }}>
                {/* <NumberRouting /> */}
                <AccountDisplayStrategy
                  {...{
                    type,
                    owner_type,
                    onSubmit,
                    updateCallflow,
                    createCallflow,
                  }}
                />
              </Grid>
              <Divider
                orientation="vertical"
                sx={{
                  background: theme => theme.palette.primary.main,
                  margin: '0 12px',
                }}
                flexItem
              />
              <Grid item sx={{ flex: 1 }}>
                {previewCallflow ? (
                  <Box
                    sx={{
                      position: 'sticky',
                      top: '80px',
                      // top: 0,
                      // left: 0,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '500px',
                        height: '500px',
                      }}
                    >
                      <Typography variant="h3" paragraph>
                        Quick preview of selected route:
                      </Typography>
                      <IvrQuickDisplay
                        // id={JSON.stringify(callflow)}
                        key={previewCallflowKey} // causes a rerender when changed!
                        callflow={previewCallflow}
                      />
                    </Box>
                  </Box>
                ) : inprogressCallflow ? (
                  <Box
                    sx={{
                      position: 'sticky',
                      top: '80px',
                      // top: 0,
                      // left: 0,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '500px',
                        height: 'calc(100vh - 140px)',
                      }}
                    >
                      <Typography variant="h3" paragraph>
                        Live visual preview of full call route:
                      </Typography>
                      <IvrQuickDisplay
                        // id={JSON.stringify(callflow)}
                        key={inprogressCallflowKey} // causes a rerender when changed!
                        callflow={inprogressCallflow}
                        showControls
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      textAlign: 'center',
                      display: 'grid',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          overflow: 'hidden',
                          width: 300,
                          height: 165,
                          margin: '0 auto',
                        }}
                      >
                        {/* @ts-ignore */}
                        <Lottie
                          path="https://assets1.lottiefiles.com/private_files/lf30_tjjynp7p.json"
                          // background="transparent"
                          // speed="1"
                          style={{
                            width: 300,
                            height: 300,
                            marginTop: -50,
                            // marginLeft: -50,
                          }}
                          // hover
                          play
                          loop={false}
                        />
                      </Box>
                      <Typography variant="caption" paragraph>
                        Call Routing preview will appear here
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* <Box>

        {!methods.formState.isSubmitting ? (
          <Button
            variant={'link'}
            color={'info'}
            // component={RouterLink}
            onClick={handleGotoVisual}
          >
            {methods.formState.isDirty ? 'Save and ' : null}Go to Visual Editor
          </Button>
        ) : null}
        <Button
          variant={methods.formState.isDirty ? 'contained' : 'outlined'}
          loading={methods.formState.isSubmitting ? true : false}
          color={'success'}
          onClick={methods.handleSubmit(onSubmit)}
          disabled={methods.formState.isDirty ? false : true}
        >
          {methods.formState.isDirty
            ? 'Save Call Routing'
            : 'No Changes to Save'}
        </Button>
      </Box> */}
        </form>
      </FormProvider>
    </>
  );
};

const ModifyCustom = ({ onDone, info }: any) => {
  const callflow = info.callflow;
  // {
  //   strategy: {
  //     type: 'blank',
  //     data: {
  //       modules: [],
  //     },
  //   },
  // };
  console.log('INFO:', info);

  return (
    <IvrCustomInline
      // key={previewCallflowKey} // causes a rerender when changed!
      {...{ callflow, onDone }}
    />
  );
};

// const InlineWrapper = ({children}) => {
//   return children
// }

// const DialogWrapper = (props) => {
//   return <Dialog open={open} maxWidth="lg" fullWidth scroll="body">
//         <DialogContent></DialogContent></Dialog>
// }

const NumberRouting = () => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const editingCallflow = watch('callflow');
  const setEditingCallflow = cf => {
    setValue('callflow', cf, { shouldDirty: true });
  };

  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  const { ptns, ptnsParsed, extensions } = parseCallflowNumbers(
    editingCallflow.numbers,
  );

  return (
    <div>
      {showDialog && (
        <NumberChangeDialog
          callflow={editingCallflow}
          setCallflow={setEditingCallflow}
          modifyPath={''}
          onClose={toggleShowDialog}
        />
      )}
      <Grid container justifyContent="space-between">
        <Grid item>
          <div>
            {!ptns.length && !extensions.length && (
              <Chip
                label="No numbers selected"
                color="default"
                // size="small"
                variant="outlined"
                // sx={{ margin: 0.5 }}
              />
            )}
            {ptns.length
              ? ptns.map(num => (
                  <Typography variant="h6" key={num}>
                    <PhoneNumberDisplay
                      // key={num}
                      // size="medium"
                      ptn={num}
                    />
                  </Typography>
                ))
              : null}
            {extensions.length ? (
              <div>
                <Typography variant="body2">
                  {/* <Box style={{ display: 'inline-block', fontWeight: 'bold' }}>
                    Internal Extensions:{' '}
                  </Box> */}
                  {extensions.map(num => (
                    <Chip
                      key={num}
                      label={`${num}`}
                      color="primary"
                      // size="small"
                      sx={{ margin: 0.5 }}
                    />
                  ))}
                </Typography>
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={toggleShowDialog}>
            {!ptns.length && !extensions.length
              ? 'Add Numbers'
              : 'Change Numbers'}
          </Button>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
    </div>
  );
};

const NumberChangeDialog = props => {
  const { callflow, setCallflow, modifyPath, onClose } = props;

  const [editingCallflow, setEditingCallflow] = useState(cloneDeep(callflow));

  const handleSave = () => {
    setCallflow({ ...editingCallflow });
    onClose(); // already done for some reason when setCallflow?
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Incoming Numbers</DialogTitle>
      <Divider />
      <DialogContent>
        <CallflowNumbers
          neverCollapse
          strategyCallflow={editingCallflow}
          setStrategyCallflow={setEditingCallflow}
          modifyPath={modifyPath}
        />
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        // errors={errors}
        saveLabel="Update"
        onSave={handleSave}
        // onCancel={onClose}
      />
    </Dialog>
  );
};

export const ChooseTemplate = ({
  type,
  owner_type,
  modifyPath,
  skipSchedule = false,
}) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const options = TEMPLATES.filter((template: any) => {
    return (
      (template.limit?.owner_type &&
        template.limit?.owner_type?.includes(owner_type)) ||
      (template.limit?.type && template.limit?.type?.includes(type))
    );
  }).map(template => ({
    label: template.name,
    value: template.id,
    full: template,
  }));

  // const option_val = watch(`${modifyPath}callflow.strategy.template.parent_id`);
  // const optionValue = options.find(opt => opt.value === option_val) || null;

  const onChangeParent = templateFull => {
    if (!templateFull) {
      return;
    }
    console.log('templateFull:', templateFull);
    debugger;
    setValue(
      `${modifyPath}callflow.strategy`,
      {
        type: 'blank',
        template: {
          parent_id: templateFull?.id, //'custom',
          parent_callflow: templateFull,
        },
        simple: {
          enabled: true,
          variables: {},
        },
        data: null,
      },
      {
        shouldDirty: true,
      },
    );
    // setValue(
    //   `${modifyPath}callflow.strategy.template.parent_callflow`,
    //   templateFull,
    //   {
    //     shouldDirty: true,
    //   },
    // );
    // setValue(
    //   `${modifyPath}callflow.strategy.simple.variables`,
    //   {},
    //   { shouldDirty: true },
    // );
    // setValue(`${modifyPath}callflow.strategy.data`, null, {
    //   shouldDirty: true,
    // });
  };

  const handleGoCustom = opt => {
    // console.log('MMM:', modifyPath);
    setValue(
      `${modifyPath}callflow.strategy`,
      {
        type: 'blank',
        template: {
          parent_id: 'custom',
          parent_callflow: null,
        },
        simple: {
          enabled: false,
          variables: {},
        },
        data: {
          modules: [],
        },
      },
      {
        shouldDirty: true,
      },
    );
    // setValue(`${modifyPath}callflow.strategy.template.parent_callflow`, null, {
    //   shouldDirty: true,
    // });
    // setValue(`${modifyPath}callflow.strategy.simple.enabled`, false, {
    //   shouldDirty: true,
    // });
    // setValue(
    //   `${modifyPath}callflow.strategy.simple.variables`,
    //   {},
    //   { shouldDirty: true },
    // );
    // setValue(`${modifyPath}callflow.strategy.data`, null, {
    //   shouldDirty: true,
    // });
  };

  const {
    toggleOpen: toggleOpenAccountChooseTemplate,
    Dialog: AccountChooseTemplateDialog,
    DialogProps: AccountChooseTemplateDialogProps,
  } = useAccountChooseTemplateDialog({
    initialOpen: false,
  });

  const handleUseSchedule = () => {
    setValue(`callflow.strategy.data.schedule.enabled`, true, {
      shouldDirty: true,
    });
  };

  const handleChooseTemplate = opt => {
    console.log('OPT:', { modifyPath, opt, callflow: watch('callflow') });
    // debugger;

    // TODO: we should be applying template variables here?

    setValue(`${modifyPath}callflow.strategy.data`, null, {
      shouldDirty: true,
    });
    setValue(`${modifyPath}callflow.strategy.template.parent_id`, opt.value, {
      shouldDirty: true,
    });
    setValue(
      `${modifyPath}callflow.strategy.template.parent_callflow`,
      opt.full,
      {
        shouldDirty: true,
      },
    );
    setValue(`${modifyPath}callflow.strategy.type`, opt.full.strategy.type, {
      shouldDirty: true,
    });
    setValue(
      `${modifyPath}callflow.strategy.simple.variables`,
      {},
      { shouldDirty: true },
    );
    setValue(`${modifyPath}simpleVariablesForUI`, [], {
      shouldDirty: true,
    });
    // disable preview
    setValue('preview_template', null, {
      shouldDirty: true,
    });
  };

  return (
    <>
      <AccountChooseTemplateDialog
        {...AccountChooseTemplateDialogProps}
        onComplete={handleChooseTemplate}
      />
      <Box sx={{}}>
        <Paper
          sx={{
            cursor: 'pointer',
            p: 2,
            // border: '1px solid white',
            border: 0,
            '&:hover': {
              // border: theme => `1px solid ${theme.palette.primary.main}`,
              boxShadow: '0 0 8px #65CADD',
            },
          }}
          variant="outlined"
          onClick={toggleOpenAccountChooseTemplate}
        >
          <Grid container wrap="nowrap">
            <Grid item xs={1}>
              <StarIcon sx={{ color: 'gold' }} />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Typography variant="h6" gutterBottom>
                Choose from our collection of simple templates
              </Typography>
              <Typography variant="body1" paragraph>
                Get started quickly with a variety of templates to choose from.
                Includes ring, voicemail, and menu options, plus more!
              </Typography>
              <Button variant="outlined" size="small">
                See Templates
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {skipSchedule ? null : (
          <>
            <br />
            <Paper
              sx={{
                cursor: 'pointer',
                p: 2,
                border: 0,
                '&:hover': {
                  // border: theme => `1px solid ${theme.palette.primary.dark}`,
                  boxShadow: '0 0 8px #65CADD',
                },
              }}
              variant="outlined"
              onClick={handleUseSchedule}
            >
              <Grid container wrap="nowrap">
                <Grid item xs={1}>
                  <Typography sx={{ color: 'primary.main' }}>
                    <CalendarMonthIcon />
                  </Typography>
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    Follow a schedule
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Schedules are great if you have Open/Closed hours, or want
                    your calls handled differently on Holidays.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    After you choose a schedule, you'll be able to choose from
                    our templates (or go fully custom) for each schedule
                    category
                  </Typography>
                  <Button variant="outlined" size="small">
                    Use a schedule
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        <br />
        <Paper
          sx={{
            cursor: 'pointer',
            p: 2,
            border: 0,
            '&:hover': {
              // border: theme => `1px solid ${theme.palette.primary.dark}`,
              boxShadow: '0 0 8px #65CADD',
            },
          }}
          variant="outlined"
          onClick={handleGoCustom}
        >
          <Grid container wrap="nowrap">
            <Grid item xs={1}>
              <Typography sx={{ color: 'primary.main' }}>
                <HandymanIcon />
              </Typography>
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Typography variant="h6" gutterBottom>
                Build a custom flow
              </Typography>
              <Typography variant="body1" paragraph>
                Use our visual editor to create any flow you can imagine!
              </Typography>
              <Button variant="outlined" size="small">
                Build a Custom Flow
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );

  // return (
  //   <Controller
  //     control={control}
  //     name={'callflow.strategy.template.parent_id'}
  //     render={({ field: { onChange, value, ...props } }) => (
  //       <Autocomplete
  //         options={options}
  //         // filterOptions={filterOptions}
  //         // @ts-ignore
  //         value={optionValue}
  //         onChange={(e, v) => {
  //           onChange(v?.value);
  //           onChangeParent(v?.full);
  //         }}
  //         renderInput={params => (
  //           <TextField
  //             {...params}
  //             label="Choose template for routing incoming calls"
  //             fullWidth
  //           />
  //         )}
  //         // disableClearable
  //       />
  //     )}
  //   />
  // );
};

const AccountDisplayStrategy = ({
  type,
  owner_type,
  onSubmit,
  updateCallflow,
  createCallflow,
}) => {
  const methods = useFormContext();

  const editingCallflow = methods.watch('callflow');
  const scheduleEnabled = methods.watch(
    'callflow.strategy.data.schedule.enabled',
  );

  console.log('edit callflow', editingCallflow);
  return (
    <>
      <Grid container>
        <Grid item sx={{ flex: 1 }}>
          <Typography variant="h2" paragraph>
            <ArrowCircleDownIcon sx={{ width: 18, height: 18, mb: '-2px' }} />{' '}
            <span style={{ color: '#444' }}>Operator Extension</span> Incoming
            Call Routing
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant={methods.formState.isDirty ? 'contained' : 'outlined'}
            loading={methods.formState.isSubmitting ? true : false}
            color={'success'}
            onClick={methods.handleSubmit(onSubmit)}
            disabled={methods.formState.isDirty ? false : true}
          >
            {methods.formState.isDirty
              ? 'Save Call Routing'
              : updateCallflow.isSuccess
              ? 'Saved'
              : 'No Changes to Save'}
          </Button>
        </Grid>
      </Grid>
      <br />
      <CallRouteNumbers
        onSave={numbers => {
          methods.setValue('callflow.numbers', numbers, { shouldDirty: true });
        }}
        callflowDoc={editingCallflow}
      />
      <br />
      {!scheduleEnabled ? (
        <NoSchedule {...{ type, owner_type }} />
      ) : (
        <DisplayScheduleView />
      )}
    </>
  );
};

const DisplayScheduleView = () => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const editingCallflow = watch('callflow');

  // const handleChangeTemplate = () => {
  //   setValue('callflow.strategy.template.parent_id', null, {
  //     shouldDirty: true,
  //   });
  //   setValue('callflow.strategy.simple.enabled', true, { shouldDirty: true });
  //   setValue('callflow.strategy.simple.variables', {}, { shouldDirty: true });
  //   setValue('callflow.strategy.data', null, { shouldDirty: true });
  // };

  const handleStopSchedule = () => {
    setValue(`callflow.strategy.data.schedule.enabled`, false, {
      shouldDirty: true,
    });
  };

  const scheduleDataInline = watch('callflow.strategy.data.schedule');
  const scheduleQuery = useCallflowQuery({
    id: scheduleDataInline?.callflow?.id,
  });

  return (
    <>
      <DisplaySchedule />
      <br />
      {/* Call Routes for each Schdule Time */}
      <DisplayScheduleTimes />
      <br />
      <br />
      <Box>
        <Typography gutterBottom>
          <Link onClick={handleStopSchedule}>Stop using a schedule</Link>
        </Typography>
        <br />
      </Box>
    </>
  );
};

const DisplaySchedule = () => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showFinderDialog, toggleShowFindScheduleDialog] =
    useToggleReducer(false);

  const scheduleDataInline = watch('callflow.strategy.data.schedule');
  const scheduleQuery = useCallflowQuery({
    id: scheduleDataInline?.callflow?.id,
  });

  const [openScheduleEdit, toggleOpenScheduleEdit] = useToggleReducer();
  // const {
  //   toggleOpen: toggleOpenScheduleEdit,
  //   Dialog: ScheduleEditDialog,
  //   DialogProps: ScheduleEditDialogProps,
  // } = useScheduleEditorDialog({
  //   initialOpen: false,
  // });

  // schedule list
  // - ALL schedules
  const { refetch, isLoading, isFetching, dataUpdatedAt, data } =
    useListSchedulesQuery({
      skip: 0, //skip
      take: 1000, // take
      orderBy: [], // order by
      filters: {
        // raw: [
        //   {
        //     variables: {
        //       docFilters: [['owner_type', '=', 'account']],
        //     },
        //   },
        // ],
      }, // filters
    });
  const { callflows: schedules, totalCount = 0 } = data ?? {};

  console.log('schedules:', schedules);

  return (
    <>
      {/* Create schedule (or edit!) */}
      {/* - account schedules (left sidebar shows first-created [where owner_type=account]) */}
      {/* - user schedules (belongs to a single user, has owner_id) */}
      {/* - general schedules (owner_type=general) */}
      <ScheduleEditorDialog
        DialogProps={{
          open: openScheduleEdit,
        }}
        ContentProps={{
          // userId={user.id}
          name: 'Company Schedule',
          scheduleId: scheduleDataInline?.callflow?.id,
          owner_type: 'account', // no owner_id? should be id of account? (has account_id probably)
          onCancel: toggleOpenScheduleEdit,
          onComplete: scheduleId => {
            setValue(
              `callflow.strategy.data.schedule.callflow.id`,
              scheduleId,
              {
                shouldDirty: true,
              },
            );
            // onComplete(scheduleId);
            toggleOpenScheduleEdit();
          },
        }}
      />
      {/*<ScheduleEditDialog
        {...ScheduleEditDialogProps}
        // userId={user.id}
        name="Company Schedule"
        scheduleId={scheduleDataInline?.callflow?.id}
        owner_type="account" // no owner_id? should be id of account? (has account_id probably)
        onCancel={toggleOpenScheduleEdit}
        onComplete={scheduleId => {
          setValue(`callflow.strategy.data.schedule.callflow.id`, scheduleId, {
            shouldDirty: true,
          });
          // onComplete(scheduleId);
          toggleOpenScheduleEdit();
        }}
      />*/}
      <Accordion
        // expanded={expanded === 'panel1'}
        // onChange={handleChange('panel1')}
        expanded
        // defaultExpanded
      >
        <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        >
          <Box sx={{ width: '100%' }}>
            <Grid container columnSpacing={2} alignItems="center">
              <Grid item>
                <CalendarMonthIcon />
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <Typography variant="h6" sx={{}}>
                  Company Schedule (and Holidays)
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  Hours of Operation, Holidays, etc
                </Typography>
              </Grid>
              {/* {!scheduleQuery.isLoading && !scheduleQuery.data ? (
                scheduleQuery.isError ? (
                  'Error'
                ) : (
                  <Grid item>
                    <Button
                      variant="link"
                      color="primary"
                      onClick={toggleOpenScheduleEdit}
                    >
                      Setup
                    </Button>
                  </Grid>
                )
              ) : null} */}
            </Grid>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {/* <HintAlert hintKey={`admin.account.schedule_routes_1`} randomize>
              Check out our Help Center article on creating and editing
              schedules! <br />
              <a href="/">View Article</a>
            </HintAlert> */}
            {scheduleQuery.isLoading ? (
              'Loading Schedule'
            ) : scheduleQuery.data ? (
              <>
                {/* {scheduleQuery.data.doc.strategy.data.times.map(timeDetails => {
                  return (
                    <Chip
                      key={timeDetails.id}
                      label={timeDetails.name}
                      sx={{
                        background: `${stringToColor(
                          timeDetails.name ?? '',
                        )} !important`,
                        color: 'white !important',
                      }}
                    />
                  );
                })}
                <br /> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={toggleOpenScheduleEdit}
                >
                  Edit Company Schedule
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={toggleOpenScheduleEdit}
                >
                  Create a Company Schedule
                </Button>
                <QuickFinderScheduleDialog
                  open={showFinderDialog}
                  onSelect={selected => {
                    const [scheduleId] = selected;

                    setValue(
                      `callflow.strategy.data.schedule.callflow.id`,
                      scheduleId,
                      {
                        shouldDirty: true,
                      },
                    );
                    toggleShowFindScheduleDialog();
                  }}
                  onCancel={toggleShowFindScheduleDialog}
                  // onClear={handleClear}
                  // initialSelected={scheduleId ? [scheduleId] : []}
                  // selectionTitle={schedule ? `${schedule.doc.name}` : null}
                  // allowSelectNone
                  // allowCreate
                />
                {schedules?.length ? (
                  <Button
                    sx={{ mt: 1 }}
                    // variant="standard"
                    color="primary"
                    fullWidth
                    onClick={toggleShowFindScheduleDialog}
                  >
                    or, Choose from Existing Schedules
                  </Button>
                ) : null}
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const DisplayScheduleTimes = () => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const scheduleDataInline = watch('callflow.strategy.data.schedule');
  const scheduleQuery = useCallflowQuery({
    id: scheduleDataInline?.callflow?.id,
  });

  return (
    <>
      <Box>
        <Accordion
          // expanded={expanded === 'panel1'}
          // onChange={handleChange('panel1')}
          expanded
          // defaultExpanded
        >
          <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          >
            <Box>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <AltRouteIcon />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{}}>
                    Call Routes for Schedule
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    Set how you want calls handled for each of your schedule
                    categories
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {scheduleQuery.isLoading ? (
                'Loading Schedule'
              ) : scheduleQuery.data ? (
                <Times schedule={scheduleQuery.data.doc} />
              ) : (
                <>No schedule yet! Please create one first.</>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

const Times = ({ schedule }) => {
  // console.log('TIMES:', schedule);

  // console.log('initialInputString:', initialInputString);
  // if initialInputString is set and NOT a plus, use that for the default to highlight, otherwise use the first option
  const [tabValue, setTabValue] = useState(
    // initialInputString !== '+' ? initialInputString ?? values[0] : values[0],
    schedule.strategy.data.times ? schedule.strategy.data.times[0].id : null,
  );

  const handleTabChange = (e, id) => {
    setTabValue(id);
  };

  useEffect(() => {
    //  TODO: make sure tab exists (when schedule changes)
  }, []);

  // console.log('tabValue:', tabValue);
  const theme = useTheme();

  return (
    <Box>
      <TabContext value={tabValue}>
        <Box>
          <TabList
            onChange={handleTabChange}
            sx={{
              // color: theme.palette.primary.main,
              '& .MuiTab-root': {
                textTransform: 'none',
              },
              '& .MuiTabs-indicator': {
                bgcolor: theme.palette.primary.main,
              },
              '& .Mui-selected': {
                bgcolor: '#f6ffff',
              },
            }}
            // textColor={theme.palette.primary.main}
            // // textColor={theme.palette.primary.main}
            // indicatorColor="secondary"
          >
            {schedule.strategy.data.times.map(timeDetails => {
              return (
                <Tab
                  key={timeDetails.id}
                  // onClick={handleTabChange}
                  value={timeDetails.id}
                  label={timeDetails.name}
                  icon={getScheduleIcon(timeDetails)}
                  iconPosition="start"
                  sx={{
                    minHeight: 0,
                  }}
                  // label={
                  //   <div>
                  //     <Chip label={timeDetails.name} color="primary" />
                  //   </div>
                  // }
                />
              );
            })}
          </TabList>
          <Divider />
        </Box>

        <Box>
          {schedule.strategy.data.times.map(timeDetails => {
            return (
              <TabPanel
                sx={{ width: '100%', pl: 0, pr: 0 }}
                value={timeDetails.id}
                key={timeDetails.id}
              >
                {/* <div>{timeDetails.name}</div> */}
                <TimeCallflow timeDetails={timeDetails} />
              </TabPanel>
            );
          })}
        </Box>
      </TabContext>
    </Box>
  );
};

const TimeCallflow = ({ timeDetails }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const modifyPath = `callflow.strategy.data.schedule.times.${timeDetails.id}.`;
  const callflowRouteForTime = watch(`${modifyPath}callflow`);

  // const stratExists = watch(`${modifyPath}callflow.strategy`); // for Advanced/Visual-only editing
  const enabledSimple = watch(`${modifyPath}callflow.strategy.simple.enabled`); // for Advanced/Visual-only editing
  const parent_set = watch(`${modifyPath}callflow.strategy.template.parent_id`); // can be "custom"!

  // const handleUseSchedule = () => {
  //   setValue(`callflow.strategy.data.schedule.enabled`, true, {
  //     shouldDirty: true,
  //   });
  // };

  useEffect(() => {
    if (!callflowRouteForTime) {
      console.log('Setting default for schedule time', callflowRouteForTime);
      const val = {
        // strategy, etc.
        id: 'inline',
        strategy: {
          type: 'blank',
          simple: {
            enabled: true,
          },
        },
      };
      setValue(`${modifyPath}callflow`, val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callflowRouteForTime]);

  return (
    <Box>
      {enabledSimple ? (
        <>
          {parent_set ? (
            <DisplayTemplate {...{ modifyPath }} />
          ) : (
            <>
              <Typography>
                How do you want to route incoming calls for this schedule time?
              </Typography>
              <br />
              <ChooseTemplate
                {...{
                  modifyPath,
                  type: 'account',
                  owner_type: 'account',
                  skipSchedule: true,
                }}
              />
            </>
          )}
        </>
      ) : (
        <>
          <DisplayCustom
            {...{
              modifyPath,
            }}
            showPreview
          />
        </>
      )}
    </Box>
  );
};

const DisplayTemplate = ({ modifyPath }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const editingCallflow = watch(`${modifyPath}callflow`);

  const handleChangeTemplate = () => {
    setValue(`${modifyPath}callflow.strategy.template.parent_id`, null, {
      shouldDirty: true,
    });
    setValue(`${modifyPath}callflow.strategy.simple.enabled`, true, {
      shouldDirty: true,
    });
    setValue(
      `${modifyPath}callflow.strategy.simple.variables`,
      {},
      { shouldDirty: true },
    );
    setValue(`${modifyPath}callflow.strategy.data`, null, {
      shouldDirty: true,
    });
  };

  let label =
    editingCallflow?.strategy?.template?.parent_callflow?.name ??
    'Unknown template';
  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <Button onClick={handleChangeTemplate}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Typography variant={'h6'}>{label}</Typography>
        </Grid>
      </Grid>
      <TemplateVariables modifyPath={modifyPath} />
    </>
  );
};

const DisplayCustom = ({ modifyPath, showPreview = false }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const editingCallflow = watch(`${modifyPath}callflow`);

  const handleChangeTemplate = () => {
    setValue(`${modifyPath}callflow.strategy.template.parent_id`, null, {
      shouldDirty: true,
    });
    setValue(`${modifyPath}callflow.strategy.simple.enabled`, true, {
      shouldDirty: true,
    });
    setValue(
      `${modifyPath}callflow.strategy.simple.variables`,
      {},
      { shouldDirty: true },
    );
    setValue(`${modifyPath}callflow.strategy.data`, null, {
      shouldDirty: true,
    });
  };

  const handleModify = () => {
    // emit an event to start a new editor instance
    // - add to chain
    ee.emit('MODIFY_CALLFLOW', {
      callflow: editingCallflow,
      onDone: callflow => {
        setValue(`${modifyPath}callflow`, callflow, {
          shouldDirty: true,
        });
      },
    });
    // @ts-ignore
    window.scrollTo(0, 0);
  };

  let label =
    editingCallflow?.strategy?.template?.parent_callflow?.name ??
    'Unknown template';

  console.log('editingCallflow:', editingCallflow);

  return (
    // <TabPanel sx={{ width: '100%' }} value={inputString}>
    <div>
      {/* Please use the Visual Editor for changes
        <br /> */}
      <Button variant="contained" onClick={handleModify}>
        Modify Custom Flow in Visual Editor
      </Button>
      &nbsp;&nbsp;
      <Button onClick={handleChangeTemplate} variant="outlined">
        Cancel and switch to a simple template
      </Button>
      {showPreview ? (
        <>
          <br />
          <br />
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '300px',
              borderRadius: 2,
              border: '1px solid #ececec',
            }}
          >
            <IvrQuickDisplay
              // id={JSON.stringify(callflow)}
              // key={previewCallflowKey} // causes a rerender when changed!
              isInline
              callflow={editingCallflow}
            />
          </Box>
        </>
      ) : null}
    </div>
    // </TabPanel>
  );

  // return (
  //   <>
  //     <Grid container spacing={1} alignItems={'center'}>
  //       <Grid item sx={{ flex: 1 }}>
  //         <Typography variant={'h6'}>Custom Call Routing</Typography>
  //       </Grid>
  //       <Grid item>
  //         <Button onClick={handleChangeTemplate}>Switch to a Template</Button>
  //       </Grid>
  //     </Grid>
  //     <br />
  //     <Link variant={'h6'} onClick={handleModify}>
  //       Modify Routing
  //     </Link>
  //   </>
  // );
};

const NoSchedule = ({ type, owner_type }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const modifyPath = `callflow.strategy.data.no_schedule.`;

  // const stratExists = watch(`${modifyPath}callflow.strategy`); // for Advanced/Visual-only editing
  const enabledSimple = watch(`${modifyPath}callflow.strategy.simple.enabled`); // for Advanced/Visual-only editing
  const parent_set = watch(`${modifyPath}callflow.strategy.template.parent_id`); // can be "custom"!

  const handleUseSchedule = () => {
    setValue(`callflow.strategy.data.schedule.enabled`, true, {
      shouldDirty: true,
    });
  };

  return (
    <Box>
      {/* <Typography gutterBottom>
        <Link onClick={handleUseSchedule}>Switch to using a schedule?</Link>
      </Typography>
      <br /> */}

      {enabledSimple ? (
        <>
          {parent_set ? (
            <DisplayTemplate {...{ modifyPath }} />
          ) : (
            <>
              <Typography variant="h5">
                Choose from the options below to get started!
              </Typography>
              <br />
              <ChooseTemplate
                {...{
                  modifyPath,
                  type,
                  owner_type,
                }}
              />
            </>
          )}
        </>
      ) : (
        <>
          <DisplayCustom
            {...{
              modifyPath,
            }}
          />
        </>
      )}
    </Box>
  );
};

const TemplateVariables = ({ modifyPath }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showAdvanced, toggleShowAdvanced] = useToggleReducer(false);

  const callflow = watch(`${modifyPath}callflow`);
  const template_base = watch(
    `${modifyPath}callflow.strategy.template.parent_callflow`,
  );
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);
  const simpleVariablesForUI = watch(`${modifyPath}simpleVariablesForUI`, []);

  const applyTemplateAndLoadVariables = useMutation(
    async ({ callflow, template_base }: any) => {
      // console.log('1234 applyTemplateAndLoadVariables');
      // Load all the variables from the template
      // - modifies the template items "output_value" to be the value built from this "apply" step
      // - skips variable checking (no checkSimple)
      let outputCallflow;

      // see if applied previously
      // - modules exist => ran previously
      // - running this again will OVERWRITE any previously-saved stuff in the callflow! (this is usually a bad thing!)
      if (callflow?.strategy?.data) {
        // console.log(
        //   '1234 Already ran applyTemplateAndLoadVariables!',
        //   callflow.strategy.data,
        // );
        outputCallflow = callflow;
        // await buildOutputCallflowFromTemplate({
        //   // rebuild STRATEGY
        //   rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        //   templateCallflow: callflow, // template-level variables (none created)
        //   // checkSimple: false,
        // });
        // outputCallflow = await buildOutputCallflowFromTemplate({
        //   // rebuild STRATEGY
        //   rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        //   templateCallflow: template_base, // template-level variables (none created)
        //   checkSimple: true,
        // });
      } else {
        // console.log('1234 REBUILDING!!!');
        outputCallflow = await buildOutputCallflowFromTemplate({
          // rebuild STRATEGY
          rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
          templateCallflow: template_base, // template-level variables (none created)
          // checkSimple: false,
        });
      }

      // console.log(
      //   '1234 applyTemplateAndLoadVariables outputCallflow1:',
      //   callflow,
      //   outputCallflow,
      // );

      // get all variables and filter to simple-only?
      const simpleVariablesForUI = await getSimpleVariables({
        rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        templateCallflow: outputCallflow, // template-level variables (none created)
      });

      // console.log('1234 simpleVariablesForUI:', simpleVariablesForUI);

      // expecting the type to be "blank" or "schedule" already, and not to be changed??
      // - seems weird to only get data... (maybe should get top-level variables as well? for schedule?)
      setValue(
        `${modifyPath}callflow.strategy.data`,
        outputCallflow?.strategy?.data,
        {
          // shouldDirty: true,
        },
      );
      setValue(`${modifyPath}simpleVariablesForUI`, simpleVariablesForUI, {
        // shouldDirty: true,
      });
    },
  );

  const onChangeSimple = () => {
    // // re-apply simple variables to callflow
    console.log('re-applying variables to callflow/template');
    applyTemplateAndLoadVariables.mutateAsync({ callflow, template_base });
    // // debugger;
  };

  // skip first run?
  const firstRef = useRef(false);
  useEffect(() => {
    // if (!firstRef.current) {
    //   console.log('Skipping applyTemplateAndLoadVariables on first-run');
    //   firstRef.current = true;
    //   return;
    // }
    // console.log(
    //   'Changing applyTemplateAndLoadVariables because template_base changed',
    // );
    applyTemplateAndLoadVariables.mutateAsync({
      callflow,
      template_base,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template_base]);

  //   const {
  //     mutate: mutateDirectoryBox,
  //     isLoading: isLoadingMutate,
  //     error: mutateError,
  //   } = useMutation(data => {
  //     // KazooSDK.resourceDirectoryesPatch(data)
  //   });

  const advancedArr = simpleVariablesForUI?.filter(
    uiVar => uiVar.varItem.simple_advanced && !uiVar.skipShowingInSimple,
  );
  const simpleArr = simpleVariablesForUI?.filter(
    uiVar => !uiVar.varItem.simple_advanced && !uiVar.skipShowingInSimple,
  );
  const hasAdvanced = advancedArr?.length ? true : false;
  const uiElements = showAdvanced ? simpleVariablesForUI : simpleArr;

  // console.log('uiElements1:', uiElements, advancedArr);

  return (
    <Box sx={{ p: 2 }}>
      {/* <br />
      <Divider />
      <br />
      <Typography variant="h2" paragraph>
        Call Routing Options:
      </Typography> */}
      {applyTemplateAndLoadVariables.isLoading ? (
        <Box>
          <Typography variant="caption">
            Loading options for editing...
          </Typography>
        </Box>
      ) : uiElements?.length ? (
        uiElements?.map((uiVar, idx) => (
          <Box
            key={idx}
            sx={{
              margin: '12px 0',
              // mb: 4,
              display: uiVar.skipShowingInSimple ? 'none' : null,
            }}
          >
            <SimpleUIComponent
              item={uiVar}
              modifyPath={modifyPath}
              onChangeSimple={onChangeSimple}
              ee={ee}
            />
          </Box>
        ))
      ) : (
        <Box>
          <Typography variant="caption">No options for template</Typography>
        </Box>
      )}
      {process.env.NODE_ENV === 'development' ? (
        <div>
          {hasAdvanced ? (
            <>
              <br />
              <Divider />
              <br />
              {showAdvanced ? (
                <Link variant="body2" onClick={toggleShowAdvanced}>
                  Hide Advanced Options
                </Link>
              ) : (
                <Link variant="body2" onClick={toggleShowAdvanced}>
                  Show Advanced Options
                </Link>
              )}
            </>
          ) : null}
        </div>
      ) : null}
    </Box>
  );
};

const DEFAULT_CALLFLOW = ({
  id,
  name_type,
  type,
  resourceId,
  owner_type,
  numbers = [],
}) => ({
  id,
  name: `${startCase(name_type)} Main`,
  type,
  owner_id: resourceId,
  owner_type, //
  numbers,
  flow: {
    module: 'callflow',
    data: { id: 'placeholder' },
  },
  metaflow: {},
  strategy: {
    type: 'account',
    data: {
      schedule: {
        enabled: false,
        callflow: {
          id: null, // user will choose a schedule
        },
        // call handling for times
        times: {
          // time_id: { callflow: { ... } }
        },
      },
      no_schedule: {
        callflow: {
          // strategy, etc.
          strategy: {
            type: 'blank',
            simple: {
              enabled: true,
            },
          },
        },
      },
      simple: {
        enabled: false,
        variables: {},
      },
    },
    // type: 'template',
    // data: {
    //   id: null,
    //   templateBaseCallflow: null, // copied from template/id {id, name, limit, strategy...}
    //   variables: {}, // for the selected template (erased when template changes!)
    //   outputCallflow: null,
    // },
  },
});

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export default CallRoutingAccount;

import { ListItemIcon, ListItemText, MenuItem } from 'app/design';
import { SortByAlpha as SortByAlphaIcon } from 'app/design/icons-material';
import React from 'react';

export const MenuItem_Variables = props => {
  const { data, mode, onClick, onClose } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    currentCallflow,
    moduleItem,
    // componentInfo,
    // callflow,
    // setCallflow,
    // modifyPath,
  } = data;

  const { callflow, setCallflow, modifyPath } = insertAfterData;

  return (
    <MenuItem
      onClick={e => {
        onClose();
        onClick();
      }}
      component={'a'}
    >
      <ListItemIcon>
        <SortByAlphaIcon />
      </ListItemIcon>
      <ListItemText
        primary="Variables"
        secondary={
          // TODO: verify data?. is safe
          moduleItem.data?.variables?.enabled ? 'Enabled' : 'Not in use'
        }
      />
    </MenuItem>
  );
};

export default MenuItem_Variables;

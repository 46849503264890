import { GroupCreateDialog } from 'app/components/GroupCreateDialog';
import { MfaCampaignRegistryDialog } from 'app/components/MfaCampaignRegistryDialog';
import { UserListOptions } from 'app/components/ListUsers/components/UserListOptions';
import { usePNContext } from 'app/utilities/pubnub';
import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import SimpleBar from 'simplebar-react';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { CircularProgress } from 'app/design';
import useDevMode from 'app/utilities/useDevMode';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { AlertWithHint } from 'app/components/AlertWithHint';
import {
  Play as PlayIcon,
  CheckCircle as CheckCircleIcon,
  CloudError as CloudErrorIcon,
} from 'iconoir-react';
import {
  Alert,
  Button,
  IconButton,
  Setting,
  Table,
  TableCell,
  TableRow,
  Dialog,
  DialogHeader,
  ButtonDropdown,
  DialogContent,
} from 'app/design-lib';
import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import useToggleReducer from 'app/utilities/useToggleReducer';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { SettingsPanelLoader } from 'app/design-lib/components/SettingsPanelLoader.tsx';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';

import {
  baseTioApiAccountRequest,
  useTioMutation,
  useTioQuery,
  useQueryBrandsList,
  useQueryCampaignsList,
  useQueryBrandFeedback,
  useQueryCampaignMms,
  useQueryCampaignSharingStatus,
  useCampaignSharingSet,
  useCampaignAttachmentUpload,
} from 'app/pages/v2/common/sdk';

function CampaignsHome() {
  const { devMode, toggleDevMode } = useDevMode();

  const accountQuery = useAccountQuery();

  const brandsQuery = useTioQuery(`/messaging/brands`);
  // const brandsQuery = useQueryBrandsList();
  const campaignsQuery = useTioQuery(`/messaging/brands/all/campaigns`);
  // const campaignsQuery = useQueryCampaignsList();
  const campaigns = campaignsQuery.data?.records ?? [];

  // const campaignsQuery = useQueryCampaigns(); // no filter for brands (likely not necessary, we are only going to allow 1 Brand for now)
  const brand = brandsQuery.data?.records?.length
    ? brandsQuery.data.records[0]
    : null;
  // console.log('brandsQuery: ', brand, brandsQuery);
  // console.log('brandsQuery: ', brandsQuery);
  // console.log('campaignsQuery: ', campaignsQuery);
  // console.log('brandFeedback', brandFeedbackQuery);
  // console.log('campaigns:', campaigns)

  return (
    <>
      <SettingsPageWrapper
        isLoading={
          accountQuery.isLoading ||
          brandsQuery.isLoading ||
          campaignsQuery.isLoading
        }
        loadingLabel={'Loading 10DLC details...'}
        label={'10DLC'}
        subLabel={'Manage SMS/MMS Sending'}
        // buttonProps={{
        //   children: 'Add phone number',
        //   onClick: toggleBuyNumber,
        //   startIcon: <PlusIcon fr={undefined} />,
        // }}
      >
        {accountQuery.data?.internal?.integrations?.trunkingio?.account_id ? (
          <>
            <BrandSettings
              brandsQuery={brandsQuery}
              campaignsQuery={campaignsQuery}
            />
            <CampaignSettings
              brandsQuery={brandsQuery}
              campaignsQuery={campaignsQuery}
            />
            <NumberSettings
              brandsQuery={brandsQuery}
              campaignsQuery={campaignsQuery}
            />
          </>
        ) : (
          <div className="text-center">
            TrunkingIO integration required
            <br />
            <br />
            <Button
              color={'accent'}
              size={'sm'}
              variant={'outline'}
              className={'mx-auto'}
              component={Link}
              to="/v2/settings/billing"
            >
              Go to Billing
            </Button>
          </div>
        )}
      </SettingsPageWrapper>
    </>
  );
}

const BrandSettings = ({ brandsQuery, campaignsQuery }) => {
  const { devMode } = useDevMode();
  const campaigns = campaignsQuery.data?.records ?? [];
  // const campaignsQuery = useQueryCampaigns(); // no filter for brands (likely not necessary, we are only going to allow 1 Brand for now)
  const brand = brandsQuery.data?.records?.length
    ? brandsQuery.data.records[0]
    : null;
  // console.log('brandsQuery: ', brand, brandsQuery);
  // console.log('campaignsQuery: ', campaignsQuery);
  // console.log('brandFeedback', brandFeedbackQuery);
  // console.log('campaigns:', campaigns)

  return (
    <SettingsPanel
      leftChild={
        <Setting
          label={'Company Registration'}
          body={'One-time registration'}
        />
      }
      rightChild={
        <>
          <AlertWithHint
            hintKey="admin.settings.10dlc"
            label={`Wondering what "10DLC" is?`}
            labelSize="lg"
            reverse
            color={'accent'}
            body={
              <div className={`text-md`}>
                <br />
                10DLC is a requirement for sending SMS/MMS messages to
                customers. It is required by mobile carrier operators (like
                Verizon, ATT, TMobile, etc), otherwise your messages will fail
                to be delivered.
                <br />
                <br />
                <a
                  href="https://support.calling.io/hc/en-us/articles/19238434000539"
                  className="text-accent-50 hover:text-accent-70 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Check out our Support Article for more details!
                </a>
              </div>
            }
          />
          <br />
          {devMode && brand ? (
            <div className="flex flex-col space-y-1">
              <Button
                color={'accent'}
                size={'sm'}
                variant={'outline'}
                className={'mx-auto'}
                // onClick={toggleOpenCreateDialog}
                // startIcon={<GroupAddIcon />}
                component={Link}
                to="/v2/settings/campaigns/brand/create"
              >
                Register Another Company
              </Button>
            </div>
          ) : null}

          {
            // Brand Loading
            brandsQuery.isLoading ? (
              <div className="grid place-items-center">
                <div className="text-center">
                  <CircularProgress size={40} />
                  <div className="text-base">Loading Brand and Campaigns</div>
                </div>
              </div>
            ) : // Done loading Brand, no Brand
            !brand ? (
              <Button
                color={'accent'}
                size={'lg'}
                variant={'fill'}
                // className={'mx-auto'}
                // onClick={toggleOpenCreateDialog}
                // startIcon={<GroupAddIcon />}
                component={Link}
                to="/v2/settings/campaigns/brand/create"
              >
                Register Company
              </Button>
            ) : (
              // brand(s) exists, no campaigns
              <div className="mt-2 space-y-2">
                {brandsQuery.data?.records.map(brand => (
                  <div key={brand.brandId} className="">
                    <Brand brand={brand} brandsQuery={brandsQuery} />
                    {/* {campaignsQuery ? ( */}
                  </div>
                ))}
              </div>
            )
          }
        </>
      }
    />
  );
};

const CampaignSettings = ({ brandsQuery, campaignsQuery }) => {
  // const campaignsQuery = useQueryCampaigns(); // no filter for brands (likely not necessary, we are only going to allow 1 Brand for now)
  const brand = brandsQuery.data?.records?.length
    ? brandsQuery.data.records[0]
    : null;

  const campaigns = campaignsQuery.data?.records ?? [];

  return (
    <SettingsPanel
      leftChild={
        <Setting
          label={'Campaigns'}
          body={'Details on the types of messages you are sending'}
        />
      }
      rightChild={
        <>
          {campaignsQuery.isLoading ||
          (campaignsQuery.isFetching && !campaigns?.length) ? (
            <SettingsPanelLoader label={'Loading campaigns...'} />
          ) : (
            <>
              {brand ? (
                // SELF_DECLARED = Sole Properietor only!!
                // VETTED_VERIFIED = Brand with an external STANDARD class vet. A VETTED_VERIFIED brand may potentially gain access to higher throughput and additional use-cases across some MNO networks.
                // VERIFIED = standard
                // UNVERIFIED = waiting for verification
                brand.identityStatus === 'UNVERIFIED' && !campaigns?.length ? (
                  <Alert
                    label={``}
                    reverse
                    color={'attention'}
                    body={
                      <div className={``}>
                        Campaigns cannot be created until your company is
                        verified.
                      </div>
                    }
                  />
                ) : !campaigns?.length ? (
                  <>
                    <Button
                      key={brand.brandId}
                      color={'accent'}
                      size={'md'}
                      variant={'fill'}
                      // className={'mx-auto'}
                      // onClick={toggleOpenCreateDialog}
                      // startIcon={<GroupAddIcon />}
                      component={Link}
                      to={`/v2/settings/campaigns/create/${brand.brandId}`}
                    >
                      Create Campaign
                    </Button>

                    {/* {brandsQuery.data.records.map(brand => (
                  <Button
                    key={brand.brandId}
                    color={'accent'}
                    size={'sm'}
                    variant={'outline'}
                    // className={'mx-auto'}
                    // onClick={toggleOpenCreateDialog}
                    // startIcon={<GroupAddIcon />}
                    component={Link}
                    to={`/v2/settings/campaigns/create/${brand.brandId}`}
                  >
                    Create Campaign (for {brand.brandId})
                  </Button>
                ))} */}
                  </>
                ) : null
              ) : (
                <Alert
                  label={``}
                  reverse
                  color={'attention'}
                  body={
                    <div className={`flex flex-col space-y-2`}>
                      Campaigns cannot be created until your company is
                      registered
                    </div>
                  }
                />
              )}
              {
                campaigns?.length ? (
                  <div className="space-y-2">
                    {campaigns.map(campaign => {
                      return (
                        <Campaign
                          key={campaign.campaignId}
                          campaign={campaign}
                          campaignsQuery={campaignsQuery}
                        />
                      );
                    })}
                  </div>
                ) : brand ? null : null // <div className="mt-4">No campaigns created yet</div>
              }
            </>
          )}
        </>
      }
    />
  );
};

const NumberSettings = ({ brandsQuery, campaignsQuery }) => {
  const {
    data: phoneNumbersData,
    isLoading: phoneNumbersIsLoading,
    refetch: phoneNumbersRefetch,
  } = useListPhoneNumbersQuery({
    skip: 0,
    take: 10, // take
    orderBy: [], // order by
    filters: {}, // filters
  });
  const { phoneNumbers } = phoneNumbersData ?? {};

  const brand = brandsQuery.data?.records?.length
    ? brandsQuery.data.records[0]
    : null;
  const campaigns = campaignsQuery.data?.records ?? [];

  return (
    <SettingsPanel
      leftChild={
        <Setting label={'Phone Numbers'} body={'With corresponding Campaign'} />
      }
      rightChild={
        <>
          {!brand ? (
            <Alert
              label={``}
              reverse
              color={'attention'}
              className="mb-4"
              body={
                <div className={`flex flex-col space-y-2`}>
                  Numbers cannot be assigned to a Campaign until your company is
                  registered and a Campaign is created.
                </div>
              }
            />
          ) : !campaigns?.length ? (
            <Alert
              label={``}
              reverse
              color={'attention'}
              className="mb-4"
              body={
                <div className={`flex flex-col space-y-2`}>
                  Waiting for a Campaign to be created before it can be assigned
                  to phone numbers.
                </div>
              }
            />
          ) : null}
          {phoneNumbersIsLoading ? null : phoneNumbers?.length ? (
            <div className="border border-neutral-20 rounded-lg w-full">
              <table className="table-auto w-full rounded-lg border-collapse ">
                {phoneNumbers?.map(ptn => (
                  <NumberInList
                    key={ptn.id}
                    ptn={ptn}
                    campaigns={campaigns}
                    disabled={!brand || !campaigns?.length}
                  />
                ))}
              </table>
            </div>
          ) : (
            <div className="italic">No Phone Numbers Found</div>
          )}
        </>
      }
    />
  );
};

const NumberInList = ({ ptn, disabled, campaigns }) => {
  const messagingSet = useTioMutation();

  const [assignStatus, setAssignStatus] = useState('none');
  const [assignStatusMsg, setAssignStatusMsg] = useState('');
  const [assignCampaignId, setAssignCampaignId] = useState('');

  const handleMessagingSetCampaign = async campaign => {
    setAssignStatus('submitting');
    setAssignStatusMsg('');
    setAssignCampaignId(campaign.campaignId);

    const isImportedResp = await baseTioApiAccountRequest(
      // @ts-ignore
      'GET',
      // @ts-ignore
      `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/imported`,
    );

    console.log('isImportedResp:', isImportedResp);
    if (isImportedResp.status !== 'success') {
      setAssignStatus('error');
      setAssignStatusMsg(
        'Campaign is pending import. Please wait for that to complete before assigning the Campaign to phone numbers. ',
      );
      return;
    }

    // setAssignStatus('submitting');

    // // const campaign_id = prompt('campaign_id', '');
    // if (!campaign_id) {
    //   return;
    // }
    try {
      // @ts-ignore
      const result = await messagingSet.mutateAsync({
        method: 'POST',
        url: `/phone_numbers/${ptn.id}/messaging`,
        data: {
          enable: true,
          campaign_id: campaign.campaignId,
        },
      });
      console.log('CampaignSet RESULT:', result);
      // alert('Campaign Assignment Submitted! Please');
      if (result.status !== 'success') {
        setAssignStatus('success');
      } else {
        setAssignStatus('error');
      }
    } catch (err) {
      // alert('Campaign Assignment Submitted! Please');
      setAssignStatus('error');
      throw err;
    }

    // setAssignStatus('error');
  };

  // console.log('ptn:', ptn);
  return (
    <>
      <Dialog
        size={'md'}
        onClose={() => setAssignStatus('none')}
        open={assignStatus === 'success' || assignStatus === 'error'}
      >
        <DialogContent>
          {assignStatus === 'success' ? (
            <div className="space-y-4">
              <div className="text-xl text-positive-70">
                Campaign Assignment Submitted!
              </div>
              <div className="text-md">
                This will usually take a few minutes for the status to change,
                then you will be able to send messages from this phone number!
              </div>
              <div className="">
                <Button
                  // component={Link}
                  // variant={'ghost'}
                  size={'md'}
                  color={'accent'}
                  // className={'no-underline text-content-accent'}
                  // to="/v2/settings/campaigns"
                  onClick={() => setAssignStatus('none')}
                >
                  Done
                </Button>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="text-xl text-negative-70">
                Failed Assigning Campaign
              </div>

              <div className="text-md">
                There was an error when trying to assign the Campaign ID
                <br />
                <span className="font-bold">{assignCampaignId}</span> to{' '}
                <span className="font-bold">
                  <PhoneNumberDisplay ptn={ptn.id} hideFlag />
                </span>
                <div className="text-md text-negative-70 my-2">
                  {assignStatusMsg?.length
                    ? assignStatusMsg
                    : 'Please contact support!'}
                </div>
              </div>

              <div className="">
                <Button
                  // component={Link}
                  // variant={'ghost'}
                  size={'md'}
                  color={'accent'}
                  // className={'no-underline text-content-accent'}
                  // to="/v2/settings/campaigns"
                  onClick={() => setAssignStatus('none')}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <tr className="text-md border-b first:border-t-0 last:border-b-0 first:flex-1 border-neutral-20 w-full min-h-10 whitespace-nowrap flex items-center">
        <td className="py-2 px-4 flex-1">
          <PhoneNumberDisplay ptn={ptn.id} hideFlag />
        </td>
        <td className="py-2 px-4 flex-1">
          {ptn.doc.trunkingio?.messaging_campaign_id ?? (
            <span className="italic">No Campaign ID</span>
          )}
        </td>
        <td className="py-2 px-4 flex-1">
          {/* <Button
          color={'accent'}
          size={'sm'}
          variant={'outline'}
          className="inline-flex"
          onClick={handleMessagingSetCampaign}
          disabled={disabled}
        >
          Assign Campaign
        </Button> */}

          <ButtonDropdown
            variant={!campaigns?.length ? 'outline' : 'fill'}
            size={'sm'}
            color={'accent'}
            disabled={
              assignStatus === 'submitting' ||
              assignStatus === 'verifying' ||
              !campaigns?.length ||
              ptn.doc.trunkingio?.messaging_campaign_id
            }
            // startIcon={<Plus />}
            // className={`border border-neutral-60 rounded text-white drop-shadow-lg bg-neutral-50 text-sm p-1 px-2`}
            // disabled={skipEditing}
            // onClick={handleClick}
            menuItems={campaigns.map(campaign => ({
              label: <>{campaign.campaignId}</>,
              disabled: campaign.status !== 'ACTIVE',
              onClick: () => {
                // if(campaign.)
                handleMessagingSetCampaign(campaign);
                // alert(1);
              },
            }))}
          >
            {assignStatus === 'submitting' || assignStatus === 'verifying'
              ? 'Submitting...'
              : campaigns?.length
              ? 'Assign to Campaign'
              : 'No campaigns registered'}
          </ButtonDropdown>

          {/* <ButtonDropdownMenu
          // @ts-ignore
          label="Assign Campaign"
          // variant={variant}
          buttonColor={'primary'}
          buttonVariant="contained"
          // icon={<AddIcon />}
          menuItems={[
            {
              text: 'Bring Your Own Phone',
              // onClick: () => router.push('/admin/phones/create'),
            },
            // {
            //   text: 'Buy a Phone',
            //   onClick: () => router.push('/admin/phones/buy'),
            // },
          ]}
          // menuItemsDependencies={[dispatchRedux, actions]}
        /> */}
          {/* <IconButtonDropdown
                    hideArrow
                    align={'right'}
                    variant={'ghost'}
                    menuItems={[{
                            label: 'Delete',
                            onClick: () => {
                              // setShowDeleteDialogPipe(true);
                              // setDeleteDialogPipe(pipe);
                            },
                          }
                    ]}
                  >
                    <MoreHoriz fr={undefined} />
                  </IconButtonDropdown> */}
        </td>
      </tr>
    </>
  );
};

const Brand = ({ brandsQuery, brand }) => {
  // TODO: show different, depending on entityType?
  const { devMode } = useDevMode();

  // const brandFeedbackQuery = useQueryBrandFeedback({
  //   brand_id: brand?.brandId,
  // });
  const brandFeedbackQuery = useTioQuery(
    `/messaging/brands/${brand?.brandId}/feedback`,
  );

  const [openMfaCampaignRegistryDialog, setOpenMfaCampaignRegistryDialog] =
    useState(false);

  console.log('brandFeedbackQuery:', brandFeedbackQuery);

  return (
    <>
      <MfaCampaignRegistryDialog
        onComplete={() => {
          setOpenMfaCampaignRegistryDialog(false);
          brandsQuery.refetch();
        }}
        onCancel={() => {
          setOpenMfaCampaignRegistryDialog(false);
          brandsQuery.refetch();
        }}
        // number={pipe.key}
        open={openMfaCampaignRegistryDialog}
        brand={brand}
        brandsQuery={brandsQuery}
      />
      <div
        className={`border border-neutral-20 overflow-x-auto rounded-lg w-full p-4 text-md font-normal flex justify-between`}
      >
        <div className="">
          <div className="text-base font-bold">{brand.displayName}</div>
          <div className="text-sm mt-1">
            <span className="">Brand ID:</span>{' '}
            <span className="font-bold">{brand.brandId}</span>
          </div>

          {
            // SMS Verify for Sole Proprietor
            brand.entityType === 'SOLE_PROPRIETOR' &&
            brand.identityStatus === 'UNVERIFIED' ? (
              <div className="mt-2">
                <Button
                  color={'accent'}
                  size={'md'}
                  variant={'fill'}
                  className={'h-min'}
                  onClick={() => setOpenMfaCampaignRegistryDialog(true)}
                  // startIcon={<GroupAddIcon />}
                >
                  Phone Number Verification Required
                </Button>
              </div>
            ) : null
          }
          {/* <div className="text-xs">{brand.entityType}</div> */}
        </div>
        <div className="">
          {brand.hasOwnProperty('evpVettingScore') ? (
            <div className="text-2xl">{brand.evpVettingScore}</div>
          ) : null}
          {
            brandFeedbackQuery.isLoading ? (
              <div className="text-xs">Loading Feedback</div>
            ) : brandFeedbackQuery.data?.category?.length ? (
              <div className="">
                {brandFeedbackQuery.data?.category.map((cat, i) => {
                  return (
                    <div key={i} className="text-xs">
                      <div className="text-base">{cat.displayName}</div>
                      <div className="text-xs">{cat.description}</div>
                    </div>
                  );
                })}
              </div>
            ) : null
            // <div className="text-xs text-neutral-400 italic">
            //   No Negative Feedback
            // </div>
          }
        </div>
        <div className="">
          {/* TODO: provide more information about verification! (how long it may take, etc) */}
          {/* - when the identityStatus === 'UNVERIFIED' */}
          {brandsQuery.isFetching ? (
            <div className="inline-block bg-neutral-20 text-content-neutral text-md font-semibold px-2.5 py-0.5 rounded">
              Checking...
            </div>
          ) : brand.identityStatus === 'VERIFIED' ? (
            <div className="inline-block bg-positive-20 text-content-positive text-md font-semibold px-2.5 py-0.5 rounded">
              Verified
            </div>
          ) : brand.identityStatus === 'UNVERIFIED' ? (
            <div className="inline-block bg-negative-20 text-content-negative text-md font-semibold px-2.5 py-0.5 rounded">
              Unverified
            </div>
          ) : brand.identityStatus === 'SELF_DECLARED' ? (
            <div className="inline-block bg-positive-20 text-content-positive text-md font-semibold px-2.5 py-0.5 rounded">
              Self-Declared
            </div>
          ) : brand.identityStatus === 'VETTED_VERIFIED' ? (
            <div className="inline-block bg-positive-20 text-content-positive text-md font-semibold px-2.5 py-0.5 rounded">
              Verified (3rd party)
            </div>
          ) : (
            <div className="inline-block bg-accent-20 text-content-accent text-md font-semibold px-2.5 py-0.5 rounded">
              Pending
            </div>
          )}
          {devMode &&
          (brand.identityStatus === 'VERIFIED' ||
            brand.identifyStatus === 'SELF_DECLARED') ? (
            <Button
              key={brand.brandId}
              color={'accent'}
              size={'sm'}
              variant={'outline'}
              // className={'mx-auto'}
              // onClick={toggleOpenCreateDialog}
              // startIcon={<GroupAddIcon />}
              component={Link}
              to={`/v2/settings/campaigns/create/${brand.brandId}`}
            >
              Create Campaign (for {brand.brandId})
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

const Campaign = ({ campaign, campaignsQuery }) => {
  // TODO: show different, depending on entityType?
  // const uploadAttachment = useCampaignAttachmentUpload();
  // const campaignSharingSet = useCampaignSharingSet();
  const { devMode } = useDevMode();

  const uploadAttachment = useTioMutation();
  const campaignSharingSetCnp = useTioMutation();
  const campaignMmsQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sample_media`,
  );
  const campaignSharingStatusQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sharing`,
  );
  const campaignMnoMetadataQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/mno_metadata`,
  );
  const campaignOperationStatusQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/operation_status`,
  );
  const campaignImportedStatusQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/imported`,
  );
  const campaignTnsQuery = useTioQuery(
    `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/tns`,
    campaignImportedStatusQuery.data?.data?.secondaryDcaSharingStatus ===
      'ACCEPTED'
      ? true
      : false,
  );
  // const campaignMmsQuery = useQueryCampaignMms({
  //   campaign_id: campaign.campaignId,
  // });
  // const campaignSharingStatusQuery = useQueryCampaignSharingStatus({
  //   campaign_id: campaign.campaignId,
  // });

  const handleChangeFile = async (file, e) => {
    // TODO:
    // - validate mms info, otherwise this may fail on the upload!
    // - verify file size, file type

    // TODO: show "uploading" status
    console.log('file change:', file);
    const value = await getBase64(file);
    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot + 1);
    // console.log('value:', ext, file, value);
    // @ts-ignore
    const result = await uploadAttachment.mutateAsync({
      method: 'PUT',
      url: `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sample_media`, // TODO: dont hardcode for Bandwidth
      data: {
        base64: value,
        extension: ext,
      },
    });
    campaignMmsQuery.refetch();
    console.log('result:', result, file);
    // // console.log('file:', file);
    // append({
    //   id: Date.now(),
    //   value: file,
    //   // result
    // });
    e.target.value = null;
  };

  const handleSetSharing = async () => {
    // // @ts-ignore
    // await campaignSharingSet.mutateAsync({
    //   campaign_id: campaign.campaignId,
    //   upstream_cnp_id: 'BANDW', // THIS SHOULD DEPEND ON THE WHICH PHONE NUMBERS WE WANT TO USE IT WITH!
    // });

    // @ts-ignore
    await campaignSharingSetCnp.mutateAsync({
      method: 'POST',
      // url: `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sharing/cnp/BANDW`, // TODO: dont hardcode for Bandwidth
      url: `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sharing/carrier/sys_bandwidth`, // TODO: dont hardcode for Bandwidth
      data: {},
    });
  };

  const handleImportToCarrier = async () => {
    // // @ts-ignore
    // await campaignSharingSet.mutateAsync({
    //   campaign_id: campaign.campaignId,
    //   upstream_cnp_id: 'BANDW', // THIS SHOULD DEPEND ON THE WHICH PHONE NUMBERS WE WANT TO USE IT WITH!
    // });

    // @ts-ignore
    await campaignSharingSetCnp.mutateAsync({
      method: 'POST',
      // url: `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/sharing/cnp/BANDW`, // TODO: dont hardcode for Bandwidth
      url: `/messaging/brands/${campaign.brandId}/campaigns/${campaign.campaignId}/import/carrier/sys_bandwidth`, // TODO: dont hardcode for Bandwidth
      data: {},
    });
  };

  // can set if not already set (and fetched?)
  // - TODO: also needs MNO approval?
  const sharedByMe = campaignSharingStatusQuery?.data?.sharedByMe;
  let canSetSharing: boolean = false;
  let canSetSharingMessage: string | null = null;
  let sharingStatus: string | null = 'pending'; // failed, complete
  if (sharedByMe?.upstreamCnpId) {
    switch (sharedByMe?.sharingStatus) {
      case 'ACCEPTED':
        sharingStatus = 'accepted';
        break;
      case 'PENDING':
        sharingStatus = 'pending';
        break;
      case 'DECLINED':
        sharingStatus = 'declined';
        break;
    }
  }

  if (campaignOperationStatusQuery.data) {
    // !campaignSharingStatusQuery.isLoading && !sharedByMe?.upstreamCnpId;
    canSetSharing = true;

    // don't allow set if already trying to set
    if (sharedByMe?.upstreamCnpId) {
      canSetSharing = false;
    }

    // check if failed on any MNOs, before allowing canSetSharing
    for (let mnoId of Object.keys(campaignOperationStatusQuery.data)) {
      let status = campaignOperationStatusQuery.data[mnoId];

      // [
      //   "REVIEW",
      //   "REJECTED",
      //   "REGISTERED",
      //   "SUSPENDED"
      // ]

      if (status === 'REJECTED') {
        canSetSharing = false;
        canSetSharingMessage = 'failed_mno_register';
        sharingStatus = 'declined_mno';
        break;
      }

      if (status !== 'REGISTERED') {
        canSetSharing = false;
        canSetSharingMessage = 'waiting_mno_registered';
        break;
      }
      // let carrier = campaignOperationStatusQuery.data[mnoId];
      // if (!carrier.qualify) {
      //   canSetSharing = false;
      //   canSetSharingMessage = 'waiting_mno_registered';
      //   break;
      // }
    }
  } else {
    canSetSharingMessage = 'loading_mno_data';
  }

  let canImportToCarrier: boolean = false;
  let canImportToCarrierMessage: string | null = null;
  let importToCarrierStatus: string | null = 'pending_sharing';

  // console.log('sharedByMe:', sharedByMe);
  // console.log('campaignImportedStatusQuery:', campaignImportedStatusQuery);

  if (!campaignImportedStatusQuery.isLoading) {
    // console.log('campaignImportedStatusQuery data', campaignImportedStatusQuery.data?.data);
    if (campaignImportedStatusQuery.data?.status !== 'success') {
      // likely 404, allow import ONLY IF sharedByMe accepted!
      if (
        sharedByMe?.upstreamCnpId &&
        sharedByMe?.sharingStatus === 'ACCEPTED'
      ) {
        canImportToCarrier = true;
        importToCarrierStatus = 'pending_import';
      } else {
        canImportToCarrierMessage = 'waiting_for_sharing';
      }
      // canImportToCarrierMessage = null;
    } else {
      let importData = campaignImportedStatusQuery.data?.data;
      if (importData?.error) {
        // must have been shared in TCR already
        if (sharedByMe) {
          if (sharedByMe?.sharingStatus === 'ACCEPTED') {
            // check if has passed all MNOs! otherwise we need to show that it hasn't passed them yet
            // - this is in the tcr/campaign/sharing section
            // campaignOperationStatusQuery
            // canImportToCarrier = true; // dont show error, just allow them to import
            importToCarrierStatus = 'pending_import';
            if (campaignOperationStatusQuery.data) {
              canImportToCarrier = true;

              for (let mnoId of Object.keys(
                campaignOperationStatusQuery.data,
              )) {
                let carrier = campaignOperationStatusQuery.data[mnoId];
                if (!carrier.qualify) {
                  canImportToCarrier = false;
                  canImportToCarrierMessage = 'failed_mno_qualify';
                  importToCarrierStatus = 'declined_mno';
                  break;
                }
              }
            } else {
              canImportToCarrierMessage = 'loading_mno_data';
            }
          } else if (sharedByMe?.sharingStatus === 'DECLINED') {
            canImportToCarrierMessage = 'sharing_declined';
          } else {
            // PENDING
            canImportToCarrierMessage = 'sharing_pending';
          }
        } else {
          canImportToCarrierMessage = 'waiting_for_sharing';
        }
      } else {
        // check returned status
        // - status of "secondary" is what matters???
        if (importData.secondaryDcaSharingStatus === 'PENDING') {
          canImportToCarrierMessage = 'pending_carrier_approval';
          importToCarrierStatus = 'pending_import';
        } else if (importData.secondaryDcaSharingStatus === 'ACCEPTED') {
          canImportToCarrierMessage = 'accepted';
          importToCarrierStatus = 'accepted';
        } else {
          // DECLINED
          canImportToCarrierMessage = 'declined';
          importToCarrierStatus = 'declined';
        }
      }
    }
  }

  // console.log(
  //   'campaignOperationStatusQuery:',
  //   campaignOperationStatusQuery.data,
  // );

  return (
    <div
      className={`border border-neutral-20 overflow-x-auto rounded-lg w-full p-4 text-md font-normal flex justify-between`}
    >
      <div className="">
        {/* <div className="text-xs">Campaign</div> */}
        <div className="text-lg mt-1">
          <span className="">Campaign ID:</span>{' '}
          <span className="font-bold">{campaign.campaignId}</span>
        </div>
        {/* <div className="text-xs">{campaign.description}</div> */}

        <div className="text-sm mt-2">
          {campaignsQuery.isFetching ? (
            <>Loading campaign registry status...</>
          ) : (
            <>
              <span className="mr-2">Campaign Registry:</span>
              {campaign.status === 'ACTIVE' ? (
                <span className="text-positive-70">
                  <CheckCircleIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-positive-60 inline align-top"
                  />
                  &nbsp;Active
                </span>
              ) : campaign.status === 'EXPIRED' ? (
                <span className="text-negative-70">
                  <CloudErrorIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-negative-60 inline align-top"
                  />
                  &nbsp;Expired
                </span>
              ) : (
                <span className="text-attention-70">
                  <PlayIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-attention-60 inline align-top"
                  />
                  &nbsp;Pending
                </span>
              )}
            </>
          )}
        </div>

        {/* {campaignsQuery.isFetching ? (
          <div className="inline-block bg-neutral-20 text-content-neutral text-md font-semibold px-2.5 py-0.5 rounded">
            Checking...
          </div>
        ) : campaign.status === 'ACTIVE' ? (
          <div className="inline-block bg-positive-20 text-content-positive text-md font-semibold px-2.5 py-0.5 rounded">
            Active
          </div>
        ) : campaign.status === 'EXPIRED' ? (
          <div className="inline-block bg-negative-20 text-content-negative text-md font-semibold px-2.5 py-0.5 rounded">
            Expired
          </div>
        ) : (
          <div className="inline-block bg-accent-20 text-content-accent text-md font-semibold px-2.5 py-0.5 rounded">
            Pending
          </div>
        )} */}

        <div className="text-sm mt-2">
          {campaignSharingStatusQuery.isLoading ? (
            <>Loading sharing status...</>
          ) : (
            <>
              <span className="mr-2">Upstream Sharing:</span>
              {sharingStatus === 'accepted' ? (
                <span className="text-positive-70">
                  <CheckCircleIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-positive-60 inline align-top"
                  />
                  &nbsp;Accepted
                  {/* ({campaignSharingStatusQuery?.data?.sharedByMe?.upstreamCnpId}) */}
                </span>
              ) : sharingStatus === 'pending' ? (
                <span className="text-attention-70">
                  <PlayIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-attention-60 inline align-top"
                  />
                  &nbsp;Pending
                </span>
              ) : sharingStatus === 'declined' ? (
                <span className="text-negative-70">
                  <CloudErrorIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-negative-60 inline align-top"
                  />
                  &nbsp;Declined
                </span>
              ) : sharingStatus === 'declined_mno' ? (
                <span className="text-negative-70">
                  <CloudErrorIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-negative-60 inline align-top"
                  />
                  &nbsp;Declined (MNO)
                </span>
              ) : null}
              {/* 
              {canSetSharing ? (
                <>
                  <Button
                    color={'accent'}
                    size={'sm'}
                    variant={'outline'}
                    className={'h-min w-max inline-flex'}
                    onClick={handleSetSharing}
                  >
                    Share Upstream (to Carrier)
                  </Button>
                </>
              ) : (
                <>
                  {campaignSharingStatusQuery?.data?.sharedByMe
                    ?.upstreamCnpId ?? 'None'}
                  (
                  {campaignSharingStatusQuery?.data?.sharedByMe
                    ?.sharingStatus ?? '--'}
                  )
                </>
              )}
              {canSetSharingMessage === 'waiting_mno_registered' ? (
                <div className="">Waiting for MNO qualifications</div>
              ) : canSetSharingMessage === 'loading_mno_data' ? (
                <div className="">Loading MNO information</div>
              ) : canSetSharingMessage === 'failed_mno_register' ? (
                <div className="">Failed registration with an MNO</div>
              ) : null} */}
            </>
          )}
        </div>
        <div className="text-sm mt-2">
          {campaignImportedStatusQuery.isLoading ? (
            <>Loading import status...</>
          ) : (
            <>
              <span className="mr-2">Carrier Import:</span>
              {importToCarrierStatus === 'accepted' ? (
                <span className="text-positive-70">
                  <CheckCircleIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-positive-60 inline align-top"
                  />
                  &nbsp;Accepted
                </span>
              ) : importToCarrierStatus === 'pending_sharing' ? (
                <span className="text-attention-70">
                  <PlayIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-attention-60 inline align-top"
                  />
                  &nbsp;Pending Sharing
                </span>
              ) : importToCarrierStatus === 'pending_import' ? (
                <span className="text-attention-70">
                  <PlayIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-attention-60 inline align-top"
                  />
                  &nbsp;Pending
                </span>
              ) : importToCarrierStatus === 'declined' ? (
                <span className="text-negative-70">
                  <CloudErrorIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-negative-60 inline align-top"
                  />
                  &nbsp;Declined
                  {/* TODO: display the failure reason here? */}
                </span>
              ) : importToCarrierStatus === 'declined_mno' ? (
                <span className="text-negative-70">
                  <CloudErrorIcon
                    fr={undefined}
                    height={16}
                    width={16}
                    className="text-negative-60 inline align-top"
                  />
                  &nbsp;Declined (MNO)
                  {/* TODO: display the failure reason here? */}
                </span>
              ) : null}
              {/* {canImportToCarrier ? (
                <>
                  <Button
                    color={'accent'}
                    size={'sm'}
                    variant={'outline'}
                    className={'h-min w-max inline-flex'}
                    onClick={handleImportToCarrier}
                  >
                    Import to Carrier (after MNOs all approved)
                  </Button>
                </>
              ) : null}
              {canImportToCarrierMessage === 'error' ? (
                <div className="">Error with carrier import</div>
              ) : canImportToCarrierMessage === 'pending_carrier_approval' ? (
                <div className="">Pending Carrier Approval</div>
              ) : canImportToCarrierMessage === 'failed_mno_qualify' ? (
                <div className="">
                  Failed an MNO qualification, contact Support
                </div>
              ) : canImportToCarrierMessage === 'loading_mno_data' ? (
                <div className="">Loading MNO data</div>
              ) : canImportToCarrierMessage === 'waiting_for_sharing' ? (
                <div className="">Please share upstream first</div>
              ) : canImportToCarrierMessage === 'sharing_pending' ? (
                <div className="">
                  Sharing is pending (this should be quick, please refresh)
                </div>
              ) : canImportToCarrierMessage === 'sharing_declined' ? (
                <div className="">
                  Sorry, sharing was declined, this is a problem, please contact
                  Support
                </div>
              ) : canImportToCarrierMessage === 'accepted' ? (
                <div className="">Successful!</div>
              ) : canImportToCarrierMessage === 'declined' ? (
                <div className="">
                  Sorry, sharing was declined by Secondary (?), this is a
                  problem, please contact Support
                </div>
              ) : null} */}
            </>
          )}
        </div>

        {devMode ? (
          <div className="text-sm mt-2">
            {campaignTnsQuery.isLoading ? (
              <>Loading numbers...</>
            ) : (
              <>
                <span className="mr-2">Assigned To:</span>
                <span className="text-neutral-70">
                  {campaignTnsQuery.data?.data?.total ?? '--'}
                </span>
              </>
            )}
          </div>
        ) : null}

        <div className="mt-4 text-md">
          {/* MNO metadata */}
          <div className="my-1">Carrier Registration:</div>
          {campaignMnoMetadataQuery.isLoading ? (
            <div className="italic text-sm">Loading...</div>
          ) : null}
          <table className="">
            {/* <thead className="text-left"> */}
            {/* <th>Name</th> */}
            {/* <th>Qualify</th>
              <th>Review</th> */}
            {/* <th>Status</th> */}
            {/* </thead> */}
            <tbody className="[&>tr>td]:p-1">
              {campaignMnoMetadataQuery.data
                ? Object.keys(campaignMnoMetadataQuery.data).map(mnoId => {
                    let mno = campaignMnoMetadataQuery.data[mnoId];
                    let status = campaignOperationStatusQuery.data
                      ? campaignOperationStatusQuery.data[mnoId]
                      : 'loading';
                    return (
                      <tr key={mnoId} className={``}>
                        <td>{mno.mno}</td>
                        {/* <td>{mno.qualify ? 'YES' : '-'}</td>
                        <td>{mno.review ? 'YES' : '-'}</td> */}
                        {/* [
                          "REVIEW",
                          "REJECTED",
                          "REGISTERED",
                          "SUSPENDED"
                        ] */}
                        <td>
                          {status === 'REGISTERED' ? (
                            <span className="text-positive-70">
                              <CheckCircleIcon
                                fr={undefined}
                                height={16}
                                width={16}
                                className="text-positive-60 inline align-top"
                              />
                              &nbsp;Registered
                              {/* ({campaignstatusQuery?.data?.sharedByMe?.upstreamCnpId}) */}
                            </span>
                          ) : status === 'REVIEW' ? (
                            <span className="text-attention-70">
                              <PlayIcon
                                fr={undefined}
                                height={16}
                                width={16}
                                className="text-attention-60 inline align-top"
                              />
                              &nbsp;In Review
                            </span>
                          ) : status === 'REJECTED' ? (
                            <span className="text-negative-70">
                              <CloudErrorIcon
                                fr={undefined}
                                height={16}
                                width={16}
                                className="text-negative-60 inline align-top"
                              />
                              &nbsp;Rejected
                            </span>
                          ) : status === 'SUSPENDED' ? (
                            <span className="text-negative-70">
                              <CloudErrorIcon
                                fr={undefined}
                                height={16}
                                width={16}
                                className="text-negative-60 inline align-top"
                              />
                              &nbsp;Suspended
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        <div className="">
          {campaignMmsQuery.data?.length ? (
            <div className="">
              {campaignMmsQuery.data.length} Sample Media File(s)
            </div>
          ) : null}
          <label style={{ cursor: 'pointer' }}>
            <input
              type="file"
              // accept=".txt"
              style={{ display: 'none' }}
              // @ts-ignore
              onChange={e => handleChangeFile(e.target.files[0], e)}
            />

            {/* {devMode ? (
              <Button
                color={'accent'}
                size={'sm'}
                variant={'outline'}
                className={'h-min w-max mt-2'}
                // onClick={toggleOpenCreateDialog}
                // startIcon={<GroupAddIcon />}
                component="div"
              >
                {uploadAttachment.isLoading ? 'Uploading...' : 'Upload Media'}
              </Button>
            ) : null} */}
          </label>
        </div>
      </div>
      {/* <div className="">
        <div className="text-2xl">{campaign.evpVettingScore ?? 'NoScore'}</div>
      </div> */}
      {/* <div className="">
        {campaignsQuery.isFetching ? (
          <div className="inline-block bg-neutral-20 text-content-neutral text-md font-semibold px-2.5 py-0.5 rounded">
            Checking...
          </div>
        ) : campaign.status === 'ACTIVE' ? (
          <div className="inline-block bg-positive-20 text-content-positive text-md font-semibold px-2.5 py-0.5 rounded">
            Active
          </div>
        ) : campaign.status === 'EXPIRED' ? (
          <div className="inline-block bg-negative-20 text-content-negative text-md font-semibold px-2.5 py-0.5 rounded">
            Expired
          </div>
        ) : (
          <div className="inline-block bg-accent-20 text-content-accent text-md font-semibold px-2.5 py-0.5 rounded">
            Pending
          </div>
        )}
      </div> */}
    </div>
  );
};

const getBase64 = async input => {
  return new Promise((resolve, reject) => {
    console.log('reading..');
    const reader = new FileReader();

    reader.onloadend = async function (evt) {
      console.log('reading3..');
      const file = evt.target?.result;
      console.log('output base64:', file);
      // remove "data:image/jpeg;base64," (everything before the comma)
      // @ts-ignore
      let [ignore, ...rest] = file?.split(',');
      // @ts-ignore
      console.log('output base64:', rest.join(','));
      resolve(rest.join(','));
    };

    reader.onerror = reject;
    console.log('2..');
    reader.readAsDataURL(input);
  });
};

export default CampaignsHome;

import {
  AnimationDialog,
  Checkbox,
  Chip,
  CircularProgress,
  DialogContent,
  Divider,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from 'app/design';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { AxiosError } from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { User } from 'types/user';
import { useImmer } from 'use-immer';
import { DialogTitle, Dialog, DialogHeader } from 'app/design-lib';

interface BulkProps {
  selectedRows?: User[];
  onComplete: () => void;
  onCancel: () => void;
}

const mutationDefault = () => ({
  status: null,
  ignoreDevices: [],
});

interface ReleaseDevicesBulkUsersWrapperProps extends BulkProps {
  open: boolean;
}
const ReleaseDevicesBulkUsersWrapper = ({
  open,
  ...props
}: ReleaseDevicesBulkUsersWrapperProps) => {
  return (
    <Dialog open={open} size={'xl'} onClose={props.onCancel}>
      <ReleaseDevicesBulkUsers {...props} />
    </Dialog>
  );
};

const ReleaseDevicesBulkUsers = ({
  selectedRows: selectedUsers,
  onComplete,
  onCancel,
}: BulkProps) => {
  const updateDevice = useUpdateDevicePartial();

  // const { data: authenticatedUser } = useAuthenticatedUserQuery();

  const releaseIsPending = updateDevice.isLoading;

  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
      ignoreDevices: string[];
    }[]
  >(() => selectedUsers?.map(user => mutationDefault()) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  useEffect(() => {
    setMutationArray(selectedUsers?.map(user => mutationDefault()) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  if (!selectedUsers)
    return <Typography variant={'error'}>No users selected!</Typography>;

  const handleRelease = async () => {
    let isError = false;
    for (let i = 0; i < selectedUsers.length; i++) {
      if (isError) break;

      const user = selectedUsers[i];

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      try {
        // delete / release to pool user devices if they exist
        if (user.Devices.length) {
          for (let index = 0; index < user.Devices.length; index++) {
            const device = user.Devices[index];

            // skip if not checked
            if (mutationArray[i].ignoreDevices.includes(device.id)) continue;

            // sip device?
            if (device.doc.device_type === 'sip_device') {
              // release to device pool
              await updateDevice.mutateAsync({ id: device.id, owner_id: null });
            } else {
              // delete device
              // await deleteDevice.mutateAsync(device.id);
            }
          }
        }

        // set success
        setMutationArray(prevState => {
          prevState[i].status = 'success';
        });
      } catch (e: any) {
        setMutationArray(prevState => {
          prevState[i].status = 'error';
        });
        setError(e);
        isError = true;
      }
    }
  };

  const handleDone = () => {
    onComplete();
    updateDevice.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  const isDone = mutationArray.every(m => m.status === 'success');

  return (
    <>
      {releaseIsPending ? null : (
        <>
          <DialogHeader
            title={'Release User(s) Devices to Pool'}
            onClose={onCancel}
          />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {isDone
            ? `Successfully released user(s) devices to pool:`
            : releaseIsPending
            ? `Releasing user(s) devices to pool...`
            : error
            ? `Error releasing user(s) devices:`
            : `Are you sure you want to release ${selectedUsers.length} user(s) devices?`}
        </Typography>
        <br />
        <div>
          <Table>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                Devices <br />
                {/*  <Typography variant={'caption'}>
                  (Physical devices will be released to device pool)
                </Typography>*/}
              </TableCell>
            </TableRow>
            {selectedUsers.map((user, index) => {
              const releaseDevices = user.Devices.filter(
                device => device.doc.device_type === 'sip_device',
              );

              return (
                <TableRow>
                  <TableCell>
                    {mutationArray[index].status === 'loading' ? (
                      <CircularProgress size={20} />
                    ) : mutationArray[index].status === 'success' ? (
                      <CheckIcon color={'success'} />
                    ) : mutationArray[index].status === 'error' ? (
                      <ClearIcon color={'error'} />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {`${user.extra.fullName}`}
                    </Typography>
                    {user.doc.priv_level === 'admin' ? (
                      <Chip label={'Admin'} color={'primary'} size={'small'} />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {releaseDevices.length ? (
                      releaseDevices.map(device => {
                        const checked = !mutationArray[
                          index
                        ].ignoreDevices.includes(device.id);

                        const handleClick = () => {
                          setMutationArray(prevValue => {
                            if (checked) {
                              prevValue[index].ignoreDevices = [
                                ...prevValue[index].ignoreDevices,
                                device.id,
                              ];
                            } else {
                              prevValue[index].ignoreDevices = prevValue[
                                index
                              ].ignoreDevices.filter(id => id !== device.id);
                            }
                          });
                        };

                        return (
                          <Grid container spacing={1} alignItems={'center'}>
                            <Grid item>
                              <Checkbox
                                disabled={releaseIsPending || isDone}
                                checked={checked}
                                onClick={handleClick}
                                sx={{ fontSize: 18 }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography>{`- ${device.doc.name}`}</Typography>
                            </Grid>
                          </Grid>
                        );
                      })
                    ) : (
                      <>
                        <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
                          None
                        </Typography>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </div>
      </DialogContent>
      {!releaseIsPending ? (
        <DefaultDialogActions
          saveLabel={isDone ? 'Done' : 'Confirm'}
          onCancel={error ? handleDone : isDone ? undefined : onCancel}
          cancelLabel={error ? 'Close' : undefined}
          onSave={error ? undefined : isDone ? handleDone : handleRelease}
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

export default ReleaseDevicesBulkUsersWrapper;

/* eslint-disable react-hooks/exhaustive-deps */

import { find, toInteger } from 'lodash';
import { getAtPath } from 'app/utilities';

import { nodeTypes, nodeTypeAllowAfter } from './nodes';

// BETTER NODE/TREE EDITOR??: https://github.com/Naihan/react-d3-tree-editor
// - also: https://microsoft.github.io/react-dag-editor/
// - also: https://www.npmjs.com/package/react-flow-editor
// - also: (node-red is prob overkill): https://nodered.org
// - problems with current node editor are:
//   - flicker when adding/removing/modifying nodes (recalculate AFTER render, cuz render gets actual dimensions for each node)

const position = { x: 0, y: 0 };
const edgeType = undefined; // smoothstep

let nextId = 0;
const getNextId = () => {
  nextId++;
  return `el${nextId.toString()}`;
};

export interface ModuleItem {
  type: string;
  data: any;
}

export interface RootData {
  elements: any; // nodes and edges
  callflowIds: any; // to prevent recursive displays
  referencedCallflowIds: any; // to keep track of related callflows that we need to load
}

// Schedules and Templates are similar
// - should share the same "building" functionality
// - because we need to be able to "reapply" Templates and Schedules server-side!!!
//   - ie: changing one affects MANY!

// with Schedules, if I add/remove/re-arrange times, then I need to re-save each (dependent) Callflow to include the new time
// - if I simply update the Days/Hours IN each box, then I DONT need to re-save each Callflow
// - NOTE: on ADD a new time, every dependent callflow wont know what branch to go to for that time! (maybe why should use a Template that uses a Schedule?)
//   - should provide an easy way to "apply branch to all callflows that use this schedule" (or something like that?)

// Templates reference a callflowId
// - and also store the actual Template data locally, which can be updated from the callfowId
// - so when we update a Template, we need to manually update EVERY other Callflow that uses the template!

// called with an eventEmitter that we attach to nodes/dialogs
// - used for "duplicating" and "moving" nodes
const RootConvertFunction = eventEmitter => {
  if (!eventEmitter) {
    debugger;
  }
  // recusive function for creating nodes/edges (and missing/needed callflows)
  const convertCallflowToFlowElements = ({
    isInlineCallflow = false,
    expandSimple,
    skipEditing, // ie "notes" options, adding to menus, etc. meant for QuickDisplay mode!
    callflow, // root callflow (use modifyPath to find "current")
    setCallflow,
    modifyPath,
    // for parentSourceNode:
    // - should be passing in the connectNode to parent, w/ everything EXCEPT target filled out (only added to elements if target exists!)
    // - todo: have a way of knowing if we've reached an "expected end" or if the call is just going to hangup or something! (they prob want something else to happen)
    parentSourceNode,
    nextOptionalEdge = null, // TODO: this is what i want to call the comment above...
    rootData, // RootData
    edgeData, // elements that only are added/exist if the child exists??
    templateParent, // plural??
    templateRef, // modify-by-reference
    relatedCallflows = {}, // use this to pass in schedules/etc that are needed
    _childEndNodeIds = [], // insertNodeIds (node ids of "Insert" elements, for _next handling)
    pipe,
  }) => {
    const isFinalInsert = parentSourceNode ? false : true;
    // parentSource used to create Edge to parent node!
    // let {
    //   elements,
    //   callflowIds
    //  } = rootData;

    // const { list: callflows } = store.getState().lists.callflows;

    let disallowedAddAfter = false;
    let currentCallflow = getAtPath(callflow, modifyPath);

    if (!currentCallflow) {
      // failed even loading callflow, show a "fix, replace w/ "
      // - this state should NOT happen! instead, each module should be making sure the necessary blank callflow is already created
      //   - or have an "autofix" ??
      const fixCreateBlankNode = {
        id: getNextId(),
        type: 'FixCreateBlankNode',
        data: { skipEditing, callflow, setCallflow, modifyPath },
        position,
      };
      rootData.elements.push(fixCreateBlankNode);
      if (parentSourceNode) {
        const iiedge = {
          id: getNextId(),
          source: parentSourceNode.id,
          target: fixCreateBlankNode.id,
          animated: true,
          type: edgeType,
        };
        rootData.elements.push(iiedge);
      }
      parentSourceNode = fixCreateBlankNode;
      return rootData;
    }

    // // generic strategy id only!!!
    switch (currentCallflow.message_strategy?.type) {
      case 'blank':
        // normal/expected
        break;

      default:
        console.error(
          'Invalid currentCallflow message_strategy',
          currentCallflow.message_strategy?.type,
          currentCallflow,
        );
        return rootData;
      // break;
    }

    // UNCOMMENT following two lines to fix a broken message_strategy
    // currentCallflow.message_strategy.type = 'blank';
    // setCallflow({ ...currentCallflow }, {}, true);

    //   const brokenNode = {
    //     id: getNextId(),
    //     type: 'MissingNode',
    //     data: {
    //       title: 'Unsupported',
    //       label: `Type: ${currentCallflow.message_strategy.id}`,
    //     },
    //     position,
    //   };
    //   rootData.elements.push(brokenNode);
    //   if (parentSourceNode) {
    //     const brokenEdge1 = {
    //       id: getNextId(),
    //       source: parentSourceNode.id,
    //       target: brokenNode.id,
    //       animated: true,
    //       type: edgeType,
    //     };
    //     rootData.elements.push(brokenEdge1);
    //   }
    //   parentSourceNode = brokenNode;
    //   return rootData;

    // // console.log('convertCallflowToFlowElements:', currentCallflow);

    // TODO: prevent recursive loops (search previous elements before going to an external callflow ["Extension IVR Menu" or "User Extension"])
    // - determine if callflow is already shown in elements!
    if (currentCallflow.id && currentCallflow.id !== 'inline') {
      if (rootData.callflowIds.includes(currentCallflow.id)) {
        console.info(
          'Already displaying this menu previously in flow! (preventing recursive)',
          currentCallflow,
          rootData.callflowIds,
        );
        return rootData;
      }
      rootData.callflowIds.push(currentCallflow.id);
    }

    if (!rootData.elements.length) {
      // first overall
      // - handles if a "template" or not
      const incomingNode = {
        id: getNextId(),
        type: 'IncomingNode',
        data: {
          isInlineCallflow:
            isInlineCallflow ||
            callflow?.id === 'inline' ||
            callflow?.id?.startsWith('builtin'),
          skipEditing,
          callflow,
          setCallflow,
          modifyPath: `${modifyPath}`,
        },
        position,
      };
      rootData.elements.push(incomingNode);
      parentSourceNode = incomingNode;
    }

    // Pass to strategy
    // - strategy returns elements array (MUST already exist!)
    // - TODO: handle ones besides Generic/blank
    let canContinue = true,
      prevBranchNodeIds = [],
      prevNode = parentSourceNode,
      currentIdx = -1;
    for (let infoIdxStr in currentCallflow.message_strategy.data?.modules ??
      []) {
      // console.log(
      //   'infoIdx:',
      //   typeof infoIdx,
      //   infoIdx,
      //   typeof currentCallflow.message_strategy.config?.modules,
      //   currentCallflow.message_strategy.config?.modules,
      //   modifyPath
      // );
      let infoIdx = toInteger(infoIdxStr);
      currentIdx = infoIdx;
      if (!canContinue) {
        continue;
      }
      const moduleItem: ModuleItem =
        currentCallflow.message_strategy.data.modules[infoIdx];
      // const moduleInfo = currentCallflow.message_strategy.config.modules[infoIdx];
      // const moduleData = currentCallflow.message_strategy.data.opts[infoIdx];

      const insertBefore = infoIdx === 0 ? true : false;
      const insertAfterData = {
        pipe,
        callflow,
        setCallflow,
        modifyPath,
        index: infoIdx + 1, // CURRENT index
        requireAllowBefore: false, // never true? only WAS used for "single item" generic callflows
        requireAllowAfter:
          infoIdx === currentCallflow.message_strategy.data?.modules.length - 1
            ? false
            : true,
      };

      // console.log('insertBefore:', insertBefore, infoIdx);
      // console.log('insertAfterData:', insertAfterData);

      // // if this is the 1st one in THIS list (but other lists exist, ie NOT the first callflow in IVR Menu), then allow "insert above" button
      // const showInsertBefore = infoIdx === 0 ? true : false;

      // if removing and LAST in list, then REMOVE from parent (if parent is menu?)

      // list of modules, display them!
      let el1, el2, el3, el4;
      let edge1, edge2, edge3, edge4;
      let nel1, nel2; // note element
      let nedge1, nedge2; // node edge;
      switch (moduleItem.type) {
        case 'LastCommunicated':
          // link from previous to this one
          // -
          el1 = {
            id: getNextId(),
            type: 'LastCommunicatedNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              currentCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          // match, no_match, (_next is handled automatically)
          let _targets = moduleItem.data?.targets ?? {};
          let prevBranchNodeIds4 = [];
          ['match', 'no_match']
            // eslint-disable-next-line no-loop-func
            .forEach(key => {
              const target = _targets[key];

              // callflow exists? dont show option if not exists
              if (skipEditing) {
                if (!target?.callflow) {
                  // continue/skip
                  return;
                }
              }

              // number in (edge/connect/line)
              const el = {
                id: getNextId(),
                type: 'OptionStaticNode',
                data: {
                  skipEditing,
                  text: key,
                  callflow,
                  currentCallflow,
                  setCallflow,
                  modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets`,
                  targetKey: key,
                  templateRef,
                  templateParent,
                  menuData: el1.data,
                },
                position,
              };
              const edge = {
                id: getNextId(),
                source: el1.id,
                target: el.id,
                animated: true,
                type: 'smoothstep',
              };
              rootData.elements.push(el);
              rootData.elements.push(edge);

              rootData = convertCallflowToFlowElements({
                expandSimple,
                skipEditing,
                callflow, //: target?.callflow,
                setCallflow,
                modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets.${key}.callflow`,
                parentSourceNode: el,
                edgeData: {},
                rootData,
                templateRef,
                templateParent,
                relatedCallflows,
                _childEndNodeIds: prevBranchNodeIds4,
                pipe,
              });
            });

          prevNode = addHiddenNodeAfter({
            id: el1.id,
            arr: prevBranchNodeIds4,
            rootData,
          });

          break;

        case 'GenericMatch':
          // link from previous to this one
          // -
          el1 = {
            id: getNextId(),
            type: 'GenericMatchNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              currentCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          // match, no_match, (_next is handled automatically)
          let targets = moduleItem.data?.targets ?? {};
          let prevBranchNodeIds = [];
          ['match', 'no_match']
            // eslint-disable-next-line no-loop-func
            .forEach(key => {
              const target = targets[key];

              // callflow exists? dont show option if not exists
              if (skipEditing) {
                if (!target?.callflow) {
                  // continue/skip
                  return;
                }
              }

              // number in (edge/connect/line)
              const el = {
                id: getNextId(),
                type: 'OptionStaticNode',
                data: {
                  skipEditing,
                  text: key,
                  callflow,
                  currentCallflow,
                  setCallflow,
                  modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets`,
                  targetKey: key,
                  templateRef,
                  templateParent,
                  menuData: el1.data,
                },
                position,
              };
              const edge = {
                id: getNextId(),
                source: el1.id,
                target: el.id,
                animated: true,
                type: edgeType,
              };
              rootData.elements.push(el);
              rootData.elements.push(edge);

              rootData = convertCallflowToFlowElements({
                expandSimple,
                skipEditing,
                callflow, //: target?.callflow,
                setCallflow,
                modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets.${key}.callflow`,
                parentSourceNode: el,
                edgeData: {},
                rootData,
                templateRef,
                templateParent,
                relatedCallflows,
                _childEndNodeIds: prevBranchNodeIds,
                pipe,
              });
            });

          prevNode = addHiddenNodeAfter({
            id: el1.id,
            arr: prevBranchNodeIds,
            rootData,
          });

          break;

        case 'MatchText':
          // link from previous to this one
          // -
          el1 = {
            id: getNextId(),
            type: 'MatchTextNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              currentCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          // targets are an array
          // - no_match is special (runs if others do not match)
          let matchTargets = moduleItem.data?.targets ?? [];
          let prevBranchNodeIds2 = [];
          matchTargets
            // eslint-disable-next-line no-loop-func
            .map((target, idx) => {
              // const target = targets[key];

              // callflow exists? dont show option if not exists
              if (skipEditing) {
                if (!target?.callflow) {
                  // continue/skip
                  return;
                }
              }

              // number in (edge/connect/line)
              const el = {
                id: getNextId(),
                type: 'OptionMatchTextNode',
                data: {
                  skipEditing,
                  targetIdx: idx,
                  callflow,
                  currentCallflow,
                  setCallflow,
                  modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets`,
                  templateRef,
                  templateParent,
                  target,
                  matchTextData: el1.data,
                },
                position,
              };
              const edge = {
                id: getNextId(),
                source: el1.id,
                target: el.id,
                animated: true,
                type: edgeType,
              };
              rootData.elements.push(el);
              rootData.elements.push(edge);

              rootData = convertCallflowToFlowElements({
                expandSimple,
                skipEditing,
                callflow, //: target?.callflow,
                setCallflow,
                modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets.${idx}.callflow`,
                parentSourceNode: el,
                edgeData: {},
                rootData,
                templateRef,
                templateParent,
                relatedCallflows,
                _childEndNodeIds: prevBranchNodeIds2,
                pipe,
              });
            });

          // for adding a match
          if (!skipEditing) {
            const insertMatchText = {
              id: getNextId(),
              type: 'InsertMatchTextOptionNode',
              data: {
                skipEditing,
                // insertAfterData: {
                currentCallflow,
                callflow,
                setCallflow,
                modifyPath,
                index: currentIdx + 1, // the "splice" point
                requireAllowBefore: false,
                requireAllowAfter: false,
                // },
                templateRef,
                templateParent,
                // scheduleData: el1.data,
                matchTextData: el1.data,
              },
              // height: 24,
              position,
            };
            const iiedgeMenu = {
              id: getNextId(),
              source: el1.id,
              target: insertMatchText.id,
              animated: true,
              type: edgeType,
            };
            // TODO: insert BEFORE "fallback" (better for UX)?
            rootData.elements.push(insertMatchText);
            rootData.elements.push(iiedgeMenu);
          }

          // static node for "no_match"
          // callflow exists? dont show option if not exists
          // if (skipEditing) {
          //   if (!target?.callflow) {
          //     // continue/skip
          //     return;
          //   }
          // }

          // number in (edge/connect/line)
          const el_static1 = {
            id: getNextId(),
            type: 'OptionStaticNode',
            data: {
              text: 'No Match',
              skipEditing,
              callflow,
              currentCallflow,
              setCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.no_match`,
              templateRef,
              templateParent,
              // target,
              matchTextData: el1.data,
            },
            position,
          };
          const edge_static1 = {
            id: getNextId(),
            source: el1.id,
            target: el_static1.id,
            animated: true,
            type: edgeType,
          };
          rootData.elements.push(el_static1);
          rootData.elements.push(edge_static1);

          rootData = convertCallflowToFlowElements({
            expandSimple,
            skipEditing,
            callflow, //: target?.callflow,
            setCallflow,
            modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.no_match.callflow`,
            parentSourceNode: el_static1,
            edgeData: {},
            rootData,
            templateRef,
            templateParent,
            relatedCallflows,
            _childEndNodeIds: prevBranchNodeIds2,
            pipe,
          });

          prevNode = addHiddenNodeAfter({
            id: el1.id,
            arr: prevBranchNodeIds2,
            rootData,
          });

          break;

        case 'Schedule':
          // link from previous to this one
          // -
          el1 = {
            id: getNextId(),
            type: 'ScheduleNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              currentCallflow,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          const schedule_id = moduleItem?.data?.schedule_id; // belongs to line (message_strategy.data.schedules[schedule_id])
          const schedule = callflow?.schedules?.[schedule_id];
          const categories = [...(schedule?.categories ?? [])];

          // add fallback time
          categories.push({
            id: 'fallback',
            name: 'Fallback',
            values: [],
            ...(schedule?.fallback ?? {}),
          });

          // // expecting this to be the last item in the modules array in the message_strategy (menu handles all the options and the timeout/fallback option?)
          // // - NOTE: there is NO "children._" for a schedule! only "timeout" that works!
          // // - TODO: allow ones to come after, but make sure to specify that it is a "fallback" type of Edge relationship/connection
          // let categories = scheduleCallflow?.doc?.message_strategy?.data?.categories ?? []; //moduleData.categories ?? {};
          // eslint-disable-next-line no-loop-func
          let prevBranchNodeIds3 = [];
          categories.forEach((category, i) => {
            // console.log('timeDetails:', timeDetails);
            // number in (edge/connect/line)
            const el = {
              id: getNextId(),
              type: 'OptionScheduleNode',
              data: {
                skipEditing,
                // scheduleCallflow: scheduleCallflow?.doc,
                schedule_id,
                moduleItem,
                category,
                currentCallflow,
                callflow,
                setCallflow,
                // modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.categories`, // unnecessary, always saving to root-ish
                // caetgoryIndex: i,
                templateRef,
                templateParent,
              },
              position,
            };
            const edge = {
              id: getNextId(),
              source: el1.id,
              target: el.id,
              animated: true,
              type: edgeType,
            };
            rootData.elements.push(el);
            rootData.elements.push(edge);

            // @ts-ignore
            rootData = convertCallflowToFlowElements({
              expandSimple,
              skipEditing,
              callflow, //: target?.callflow,
              setCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.routes.${category.id}.callflow`,
              parentSourceNode: el,
              edgeData: {},
              rootData,
              templateRef,
              templateParent,
              relatedCallflows,
              _childEndNodeIds: prevBranchNodeIds3,
              pipe,
            });
          });

          if (!skipEditing) {
            const insertNodeSchedule = {
              id: getNextId(),
              type: 'InsertScheduleOptionNode',
              data: {
                skipEditing,
                // insertAfterData: {
                currentCallflow,
                callflow,
                setCallflow,
                modifyPath,
                index: currentIdx + 1, // the "splice" point
                requireAllowBefore: false,
                requireAllowAfter: false,
                // },
                templateRef,
                templateParent,
                scheduleData: el1.data,
              },
              // height: 24,
              position,
            };
            const iiedgeMenu = {
              id: getNextId(),
              source: el1.id,
              target: insertNodeSchedule.id,
              animated: true,
              type: edgeType,
            };
            // TODO: insert BEFORE "fallback" (better for UX)?
            rootData.elements.push(insertNodeSchedule);
            rootData.elements.push(iiedgeMenu);
          }

          prevNode = addHiddenNodeAfter({
            id: el1.id,
            arr: prevBranchNodeIds3,
            rootData,
          });

          break;

        case 'Message':
          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'MessageNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              infoIdx,
              currentCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          // edge to previous node

          // Note/Comment tester
          // - if the previous one is NOT already a Note
          //   - then add the AddNote type!
          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        case 'PlayAudio':
          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'PlayAudioNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              infoIdx,
              currentCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          // edge to previous node

          // Note/Comment tester
          // - if the previous one is NOT already a Note
          //   - then add the AddNote type!
          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;
          break;

        case 'Note':
          // link to viewing that callflow (NOT showing here!)
          // - this is an EXTERNAL callflow! NOT an inline one!

          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'NoteNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };
          edge1 = {
            id: getNextId(),
            source: prevNode.id,
            target: el1.id,
            // type: edgeType,
            type: edgeType,
            animated: true,
            // arrowHeadType: 'arrow',
          };
          rootData.elements.push(el1);
          rootData.elements.push(edge1);
          prevNode = el1;

          break;

        default:
          el1 = {
            id: getNextId(),
            type: 'MissingNode',
            data: { skipEditing, label: moduleItem.type },
            key: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
            position,
          };
          edge1 = {
            id: getNextId(),
            source: prevNode.id,
            target: el1.id,
            // type: edgeType,
            type: edgeType,
            animated: true,
            arrowHeadType: 'arrow',
          };
          rootData.elements.push(el1);
          rootData.elements.push(edge1);
          prevNode = el1;
          break;
      }
    }

    // add a trailing "Insert" if allowed
    // if none in message_strategy, show a Plus button
    // console.log('currentCallflow:', currentCallflow);
    if (!disallowedAddAfter) {
      // TODO: have the "skipEditing" version display differently? (like an error??)
      const insertNode = {
        id: getNextId(),
        type: 'InsertNode',
        data: {
          skipEditing,
          // insertAfterData: {
          callflow,
          setCallflow,
          modifyPath,
          index: currentIdx + 1, // the "splice" point
          requireAllowBefore: false,
          requireAllowAfter: false,
          templateRef,
          templateParent,
          pipe,
          isFinalInsert,
          // },
        },
        // height: 24,
        position,
      };
      const iiedge = {
        id: getNextId(),
        source: prevNode.id,
        target: insertNode.id,
        animated: true,
        type: edgeType,
      };

      rootData.elements.push(insertNode);
      rootData.elements.push(iiedge);

      // @ts-ignore
      _childEndNodeIds.push(insertNode.id);
    }
    // console.log('isFinalInsert:', isFinalInsert);
    return rootData;
  };
  return convertCallflowToFlowElements;
};

const addNoteNodeBefore = (
  skipEditing, // skip "placeholder" notes
  rootData,
  prevNode,
  thisEl,
  modifyPath,
  insertAfterData,
  infoIdx,
) => {
  // cData = moduleData
  // - more prone to failure?
  let thisEdge1, thisEdge2, noteNode, noteEdge;
  if (prevNode.type === 'NoteNode') {
    // edge from existing Note
    thisEdge1 = {
      id: getNextId(),
      source: prevNode.id,
      target: thisEl.id,
      // type: edgeType,
      type: edgeType,
      animated: true,
      arrowHeadType: 'arrow',
    };
    rootData.elements.push(thisEl);
    rootData.elements.push(thisEdge1);
  } else {
    // AddNote node/edge
    // console.log('TYPE INSERT');
    if (!skipEditing) {
      noteNode = {
        id: getNextId(),
        type: 'NoteNode',
        data: {
          skipEditing,
          exists: false, // this will be semi-obvious to the NoteNode module because the moduleData won't exist!
          insertAfterData,
          // callflow,
          // setCallflow,
          // modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
        },
        key: `${modifyPath}.message_strategy.data.modules.${infoIdx}__notenode`, // for focus after modify
        // height: 100,
        position,
      };
      thisEdge1 = {
        id: getNextId(),
        source: prevNode.id,
        target: noteNode.id,
        // type: edgeType,
        type: edgeType,
        animated: true,
        // arrowHeadType: 'arrow',
      };
      thisEdge2 = {
        id: getNextId(),
        source: noteNode.id,
        target: thisEl.id,
        // type: edgeType,
        type: edgeType,
        animated: true,
        arrowHeadType: 'arrow',
      };
      rootData.elements.push(noteNode);
      rootData.elements.push(thisEl);
      rootData.elements.push(thisEdge1);
      rootData.elements.push(thisEdge2);
    } else {
      // QuickDisplay
      thisEdge1 = {
        id: getNextId(),
        source: prevNode.id,
        target: thisEl.id,
        // type: edgeType,
        type: edgeType,
        animated: true,
        // arrowHeadType: 'arrow',
      };
      rootData.elements.push(thisEl);
      rootData.elements.push(thisEdge1);
    }
  }
  return prevNode;
};

const addHiddenNodeAfter = ({ id, arr, rootData }) => {
  // add placeholder for connecting branches easily
  // - just testing if this works...
  const el = {
    id: getNextId(),
    type: 'OptionHiddenNode',
    data: {},
    position,
  };
  rootData.elements.push(el);
  for (let id of arr) {
    rootData.elements.push({
      id: getNextId(),
      source: id,
      target: el.id,
      animated: true,
      type: edgeType,
    });
  }
  return el;
};

export default RootConvertFunction;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from 'app/design';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import constants from 'app/constants';

// import { useSetupHook } from '../SetupHook';
import { useEffectOnce, useLocalStorage } from 'react-use';

let gAccessTokenUUID; // = localStorage.getItem('ms_auth_access_token');

interface SlackProps {
  onClose: (event?: any) => void;
}

export const Slack = ({ onClose }: SlackProps) => {
  const dispatch = useDispatch();

  const [importedUsers, setImportedUsers] = useLocalStorage(
    'imported-users',
    [],
  );

  const [localState, setLocalState] = useState({
    loading: true,
    loaded: false,
    error: null,
    users: [],
  });

  // const { list: callflows } = useSelector((state) => state.lists.callflows);

  // const {
  //   defaultAccount,
  //   createUser,
  //   allowExtensions,
  //   syncAll,
  // } = useSetupHook();

  const fetchFromSlackApi = async uuid => {
    if (!uuid) {
      return;
    }

    let resp = await fetch(
      `${constants.env.REACT_APP_CIO_HOME}/api/v1/slack/users_list?uuid=${uuid}`,
      {
        method: 'GET', // TODO: more than GET
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const respJson = await resp.json();

    // fail, return response as error for error message
    if (!resp.ok) {
      console.error('Failed fetching slack users');
      return;
    }

    console.log('respJson:', respJson);
    const members = respJson.members
      .filter(
        member =>
          !member.is_bot && !member.deleted && member.is_email_confirmed,
      )
      .map(member => {
        let [first_name = '', last_name = ''] = member.real_name.split(' ');
        return {
          id: member.id,
          displayName: member.real_name,
          first_name: member.profile.first_name ?? first_name,
          last_name: member.profile.last_name ?? last_name,
          email: member.profile.email,
        };
      });
    console.log('members:', members);
    setImportedUsers(
      members,
      // .map(member => {
      // const [first_name, last_name = ''] = member.displayName.split(' ');
      // return {
      //   first_name,
      //   last_name,
      //   email: member.email,
      // };
      // }
      // ),
    );
    setLocalState(s => ({
      ...s,
      loading: false,
      loaded: true,
      error: null,
      users: members,
    }));

    // // Create msClient for user
    // let clientOptions = {
    //   authProvider: cb => {
    //     cb(null, accessToken);
    //   },
    // };

    // console.log('Fetching from API');
    // const client = Client.init(clientOptions);

    // // Fetch count and list of users
    // const usersCount = await client
    //   .api('/users/$count')
    //   .headers({
    //     ConsistencyLevel: 'eventual',
    //   })
    //   .get();

    // const users = await client
    //   .api('/users')
    //   .select(
    //     // https://docs.microsoft.com/en-us/graph/api/resources/users?view=graph-rest-1.0
    //     'id',
    //     'displayName',
    //     'department',
    //     'businessPhones',
    //     'mobilePhone',
    //     'givenName', // first name
    //     'surName', // last name
    //     'mail', // email!
    //   )
    //   .get();

    // // TODO: load users into a UI to display them
    // // - remove "duplicates" from existing users, or somehow show only "new" employees
    // //   - MATCH!!!! on names/etc, or by checking the "id" field to see if the "user.custom_info_xyz.from_msauth_id=id"

    // console.log('API Response:', usersCount, users);
    // // alert('fetched,not doing anything with it yet, sorry');

    // setLocalState(s => ({
    //   ...s,
    //   loading: false,
    //   loaded: true,
    //   error: null,
    //   users: users.value,
    // }));
  };

  const handleSlack = () => {
    setLocalState(s => ({
      ...s,
      loading: true,
      loaded: true,
      error: null,
    }));

    if (gAccessTokenUUID?.length) {
      fetchFromSlackApi(gAccessTokenUUID);
      return;
    }

    // popup window to get accessToken
    let windowObjectReference: any = null;
    let previousUrl = null;

    const receiveMessage = event => {
      // // Do we trust the sender of this message? (might be
      // // different from what we originally opened, for example).
      // if (event.origin !== BASE_URL) {
      //   return;
      // }
      // alert('got message from child!');
      // console.log('event:', event);
      const { data } = event;
      // console.log('data from other window:', data);

      const urlParams = new URLSearchParams(data);
      const auth_uuid = urlParams.get('auth_uuid');
      gAccessTokenUUID = auth_uuid;
      // const accessToken = urlParams.get('accessToken');
      // console.log('accessToken:', accessToken);
      // gAccessTokenUUID = accessToken;
      // localStorage.setItem('ms_auth_access_token', accessToken);
      fetchFromSlackApi(auth_uuid);

      // const { data } = event;
      // // if we trust the sender and the source is our popup
      // if (data.source === 'lma-login-redirect') {
      //   // get the URL params and redirect to our server to use Passport to auth/login
      //   const { payload } = data;
      //   const redirectUrl = `/auth/google/login${payload}`;
      //   window.location.pathname = redirectUrl;
      // }
    };

    const openSignInWindow = (url, name) => {
      // remove any existing event listeners
      window.removeEventListener('message', receiveMessage);

      // window features
      const strWindowFeatures =
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

      if (windowObjectReference === null || windowObjectReference?.closed) {
        /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
        windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
        windowObjectReference.focus();
      }

      // add the listener for receiving a message from the popup
      // window.addEventListener('message', event => receiveMessage(event), false);
      window.addEventListener('message', receiveMessage, false);
      // assign the previous URL
      previousUrl = url;
    };

    const thisApp = `${window.location.protocol}//${window.location.host}`;

    // verify we are not using localhost
    // - slack doesnt allow it (use a localtunnel and set REACT_APP_CIO_HOME)
    const server = constants.env.REACT_APP_CIO_HOME;
    const serverUrl = new URL(server!);
    if (serverUrl.hostname === 'localhost') {
      alert(
        'cannot use localhost for slack, must be a public url for oauth redirect',
      );
      onClose();
      return;
    }
    openSignInWindow(
      `${server}/oauth/signin-popper?callbackUrl=${thisApp}`,
      'slacksignin',
    );
  };

  useEffectOnce(handleSlack);

  const importUsersArr = localState.users;
  //   .map(user => ({
  //   first_name: user.givenName,
  //   last_name: user.surname,
  //   email: user.mail,
  // }));
  console.log('importUsersArr:', importUsersArr, localState.users);

  return (
    <Dialog
      // fullWidth
      maxWidth="md"
      open
      // onClose={onClose}
    >
      {localState.loading ? (
        <DialogInProgress title={'Fetching users...'} />
      ) : (
        <>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Import Users from Slack</Typography>
              </Grid>
              {/* <Grid item>
            <Button color="success">Advanced Setup</Button>
          </Grid> */}
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ padding: '8px 12px' }}>
            <div
              // ref={contentRef}
              style={
                {
                  // width: '50vw',
                  // padding: '8px',
                }
              }
            >
              <div>
                {localState.users.map((user: any) => {
                  return (
                    <div key={user.id}>
                      <Typography variant="body1">
                        {user.first_name} {user.last_name}
                      </Typography>
                      <Typography variant="caption" component="div">
                        {user.email}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <div style={{ width: '100%' }}>
              <Button variant="outlined" onClick={e => onClose(importUsersArr)}>
                Add to Import List
              </Button>
              <br />
              <Typography variant="caption">
                This does not import your users yet!
              </Typography>
            </div>
          </DialogActions>
          {/* <DialogSaveActions
            errors={[error]}
            label={'After creating your user you can assign them devices, etc'}
            onSave={handleCreateUser}
          /> */}
        </>
      )}
    </Dialog>
  );
};

export default Slack;

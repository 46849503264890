import { IconButton } from 'app/design-lib/components/IconButton';
import { Cancel as CloseIcon } from 'iconoir-react';
import React, { ReactNode } from 'react';

interface DialogHeaderProps {
  children: ReactNode;
  className?: string;
}

export const DialogContent = ({
  children,
  className = 'overflow-y-auto',
}: DialogHeaderProps) => {
  return <div className={`${className} px-4 py-6 `}>{children}</div>;
};

import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    temporalRulesSets: (vars, fetchOptions) =>
      fetchFromGraphql('temporalRulesSets', vars, fetchOptions),

    // // Fetch a single menu by id
    // menuById: (vars, fetchOptions) =>
    //   fetchFromGraphql('menuById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    // create: buildPutDefault('menus', { buildUrlOpts: { skipId: true } }),
    // updatePartial: buildPatchDefault('menus'),
    // delete: buildDeleteDefault('menus'),
  },
};

export default exports;

import React, { useEffect, useState } from 'react';

import { Paper, Typography, Link } from 'app/design';

const RenderScreenpop = props => {
  const { screenpop, debug } = props;

  if (screenpop.error) {
    if (debug) {
      return (
        <Paper
          variant="outlined"
          padding={1}
          style={{ border: '1px solid red' }}
        >
          <Typography variant="body1" strong>
            Failed loading screenpop
          </Typography>
          <Typography variant="body2">{screenpop.error.message}</Typography>
        </Paper>
      );
    }
    // failed getting screenpop
    return (
      <Paper variant="outlined" padding={1} style={{ border: '1px solid red' }}>
        <Typography variant="body1" strong>
          Failed loading screenpop
        </Typography>
        <Typography variant="body2">
          Please contact your admin, or support
        </Typography>
      </Paper>
    );
  }

  const version = screenpop.response.version;
  const data = screenpop.response.data;

  if (screenpop.error !== false) {
    return null;
  }

  return (
    <Paper variant="outlined" padding={1}>
      <Typography variant="body1" strong>
        {data.title}
      </Typography>
      <Typography variant="body2">{data.body}</Typography>
      {data.link ? (
        <Link href={data.link.href} rel="noopener" target="_blank">
          {data.link.text}
        </Link>
      ) : (
        ''
      )}
    </Paper>
  );
};

export default RenderScreenpop;

import { Tooltip } from '@mui/material';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import { Badge } from 'app/design-lib';
import { usePNContext } from 'app/utilities/pubnub';
import * as React from 'react';
import { OwnerTableCell } from 'app/components/OwnerTableCell';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { Device } from 'types/device';
import { User } from 'types/user';
import { Chip, Grid, InfoTooltip } from 'app/design';

interface DeviceOwnerCellProps {
  owner: User;
  device: Device;
  className?: string;
}

const DeviceOwnerCell = ({
  owner,
  device,
  className = '',
}: DeviceOwnerCellProps) => {
  const { t } = useMarkdownTranslate();
  // @ts-ignore
  const { onlineUsers } = usePNContext();

  if (owner) {
    const isOnline = onlineUsers?.[owner?.id] ? true : false;
    return (
      <div className={'flex space-x-2 items-center'}>
        <UserAvatar key={owner.id} user={owner} size={'sm'} />
        <span className={'text-sm'}>{owner.extra.fullName}</span>
      </div>
    );
  }

  return (
    <div className={`${className} flex`}>
      {device.doc.owner_id ? (
        // if orphaned (owner ID set, user does not exists)
        <Tooltip arrow title={t('device.orphan.tooltip')}>
          <Badge color={'attention'} variant={'fill'} reverse>
            Orphaned
          </Badge>
        </Tooltip>
      ) : (
        <Tooltip arrow title={t('device.pool.tooltip')}>
          <Badge color={'accent'} variant={'fill'} reverse>
            In pool
          </Badge>
        </Tooltip>
      )}
    </div>
    // unowned
    /* <>
      <Grid item>
        <Chip
          color={'primary'}
          variant={'contained'}
          size={'small'}
          label={'In Pool'}
        />
      </Grid>
      <Grid item>
        <InfoTooltip title={t('device.pool.tooltip')} />
      </Grid>
    </>*/
  );
};

export default DeviceOwnerCell;

import setupUserCallflow from 'app/components/Onboarding/Vsb/Step3/fn/setupUserCallflow';
import setupUserVoicemail from 'app/components/Onboarding/Vsb/Step3/fn/setupUserVoicemail';
import constants from 'app/constants';
import { gql, request } from 'graphql-request';
import React, { Fragment, useEffect, useState } from 'react';
import { VerifiedBadge, VerifiedBadge as verifiedIcon } from 'iconoir-react';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useGlobalTour } from 'app/components/Tourguide/Helper';
import { useAuthSelector } from 'app/data/auth';
import { Clock as ClockIcon } from 'iconoir-react';
import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  Link,
  Slide,
  Tooltip,
  Typography,
  Card,
  CardContent,
} from 'app/design';
import { Button, Checkbox } from 'app/design-lib';
import { ChevronLeft as ChevronLeftIcon } from 'app/design/icons-material';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { sdk } from 'app/sdk';
import {
  useAccountQuery,
  useAccountStripeCustomerQuery,
} from 'app/hooks/queries/account';

import { useLocalStorage } from 'react-use';
import { sleep, useToggleReducer } from 'app/utilities';
import { store } from '../../../../../store';

import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';

import { useFormContext } from 'react-hook-form';
import setupAccountDetails from '../Step3/fn/setupAccountDetails';
import setupAccountCallerId from '../Step3/fn/setupAccountCallerId';
import setupCompanyVoicemail from '../Step3/fn/setupCompanyVoicemail';
import setupFeatureCodesAndNomatch from '../Step3/fn/setupFeatureCodesAndNomatch';
import setupMainCallflow from '../Step3/fn/setupMainCallflow';
import setupNumberDetails from '../Step3/fn/setupNumberDetails';
import setupRingGroup from '../Step3/fn/setupRingGroup';
import setupPipes from '../Step3/fn/setupPipes';

import { usePipeNew } from 'app/pages/v2/settings/Pipes/Pipes';
import { Transition } from '@headlessui/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import accountQueryKeys from 'app/hooks/queries/account/accountQueryKey';
import { useQueryClient } from 'react-query';

let ranSetupFeatureCodes = false,
  featureCodesPromiseResolver,
  featureCodesPromise = new Promise(
    resolve => (featureCodesPromiseResolver = resolve),
  );

const Step2 = ({}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isDisabled, setDisabled] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState('');
  const addCreditCard = useAccountStripeCustomerMutate();
  const { data: account } = useAccountQuery();
  const queryClient = useQueryClient();
  const [cancelSub, toggleCancelSub] = useToggleReducer(false);
  const {
    data: stripeData,
    isLoading,
    isFetching,
    refetch,
  } = useAccountStripeCustomerQuery({
    onSuccess: stripeData => {
      const hasCard = !!stripeData?.data?.cards?.data?.length;
      console.log('stripe data', stripeData);

      // if (hasCard) setValue('verified', true);
    },
  });

  const isTestUser =
    account?.internal?.stripe_customer_id === 'test_user' ||
    process.env.REACT_APP_STRIPE_USE_TEST_USER
      ? true
      : false;

  const handleVerify = async () => {
    if (isTestUser) {
      setValue('verified', true);
      return;
    }

    setIsVerifying(true);
    setError('');

    if (!stripe || !elements) {
      setError('Internal error. Please try again later.');
      setIsVerifying(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.error('stripe not loaded');
      return;
    }

    // get token for card (to apply to customer)
    const { token, error } = await stripe.createToken(
      // @ts-ignore
      elements.getElement(CardElement),
    );

    console.log('token:', token);
    if (error) {
      setIsVerifying(false);
      setError(
        'Unable to verify credit card. Please enter a valid credit card.',
      );
      console.error('card error:', error);
      return;
    }

    // const result = await sdk.stripe.mutate.newCustomer(
    //   {
    //     data: { token },
    //   },
    //   { authToken: store.getState().auth.auth_token },
    // );

    // console.log('Stripe Response:', result);
    // onCancel();

    // TODO: is adding a card enough of a verification
    // or do we need to do additional stripe checks to prevent fraud/verify id
    const addCreditCardPromise = addCreditCard.mutateAsync({
      data: {
        action: 'addCard',
        data: {
          name: token?.card?.brand ?? 'Default Card',
          source: token?.id,
          // @ts-ignore
          setDefault: true,
        },
      },
    });

    let resp;
    try {
      resp = await addCreditCardPromise;
      if (!resp?.success) {
        // @ts-ignore
        setError(
          // @ts-ignore
          resp?.data?.message ??
            'Unable to verify credit card. Please enter a valid credit card.',
        );
        setIsVerifying(false);
        return false;
      }
      setValue('verified', true);
      setIsVerifying(false);
    } catch (err) {
      // TODO: parse and set errors

      // @ts-ignore
      setError(
        'Unable to verify credit card. Please enter a valid credit card.',
      );
      setIsVerifying(false);
      throw err;
    }

    if (cancelSub) {
      try {
        const cancelResp = await request(
          `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
          gql`
            mutation stripeCustomerMutate($data: JSON) {
              stripeCustomerMutate(data: $data) {
                success
                message
                data
              }
            }
          `,
          {
            data: {
              action: 'cancelSubscription',
              data: {},
            },
          },
          // @ts-ignore
          { authorization: auth_token },
        );
      } catch (err) {
        console.error(err);
        setError(
          'Error canceling subscription. Try again from the billing page.',
        );
      }
    }

    await queryClient.refetchQueries(accountQueryKeys.stripe_customer);
  };

  const handleNext = () => {
    setValue('activeStep', 2);
  };
  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const { handleSubmit, watch, setValue, clearErrors } = useFormContext();

  // run feature code setup once here (should complete quickly)
  const { auth_token, account_id } = useAuthSelector();

  const isVerified = watch('verified');

  return (
    <>
      <div className={`relative h-full grid place-items-center`}>
        <div className={'flex flex-col w-full space-y-6'}>
          {isVerified ? (
            <div
              className={`flex space-x-2 w-full justify-center items-center text-3xl font-medium text-positive-60`}
            >
              <VerifiedBadge fr={undefined} width={32} height={32} />
              <span>Card Verified</span>
            </div>
          ) : (
            <>
              <div className={'text-xl font-medium'}>
                Next, we need to verify your billing information...
              </div>

              {/* <div
                className={`border flex flex-col space-y-2 p-4 text-attention-60 rounded-lg border-attention-50 bg-attention-10`}
              >
                <div className={'flex space-x-2 items-center'}>
                  <ClockIcon width={18} height={18} fr={undefined} />
                  <span className={`text-lg font-medium `}>
                    30 Day Free Trial
                  </span>
                </div>
                <p className={`text-sm`}>
                  You may cancel your subscription at any point during your
                  trial to prevent accruing any charges.
                </p>
                <p className={`text-sm font-medium`}>
                  Calling.IO will not charge your card until your trial has
                  expired.{' '}
                </p>
              </div> */}

              <div className={`space-y-1 flex flex-col`}>
                {/* <label className={'ml-1 text-md font-medium text-content'}>
                  {'Credit card'}
                </label> */}
                <CardElement
                  options={{
                    disabled: isTestUser,
                    disableLink: true, // disable Stripe's thing for remembering credit cards
                  }}
                  onChange={handleChange}
                  className={`form-input text-md focus:ring-content-accent focus:border-content-accent text-content-neutral ${
                    !!error
                      ? `border-border-negative-secondary`
                      : `border-border-neutral`
                  } rounded-lg p-3 border`}
                />
                <span
                  id={`credit-card-help-text `}
                  className={`ml-1 font-sans text-sm ${
                    !!error ? 'text-negative-60' : 'text-attention-60'
                  }`}
                >
                  {isTestUser
                    ? `As a test user we don't require this information. click below to continue`
                    : error || null}
                </span>
                {/* <div className={'pt-2'}>
                  <Checkbox
                    size={'sm'}
                    label={
                      <span>
                        <strong>Auto-cancel</strong> when my Trial ends (easily
                        change this anytime you want)
                      </span>
                    }
                    checked={cancelSub}
                    onClick={toggleCancelSub}
                  />
                </div> */}
              </div>
            </>
          )}
          <div className="mt-4 ">
            <Button
              disabled={
                isTestUser ? false : !isVerified && (isDisabled || isVerifying)
              }
              color={'accent'}
              size={'lg'}
              variant={'fill'}
              onClick={isVerified ? handleNext : handleVerify}
              className={`justify-self-end w-full self-end`}
            >
              {isLoading
                ? 'Loading account details...'
                : isVerifying
                ? 'Verifying..'
                : isVerified
                ? 'Next'
                : 'Verify'}
            </Button>
          </div>
          <p className={`mt-4 text-sm`}>
            You may cancel your subscription at any point during your trial to
            prevent accruing any charges.
          </p>
        </div>
      </div>
    </>
  );

  // return (
  //   <Slide in={inVal} timeout={timeout} direction={direction}>
  //     <div>
  //       <Fade in={inVal} timeout={timeout}>
  //         <div>
  //           {chooseNumberOrSkip ? (
  //             <TrunkingioNumber
  //               user={user}
  //               thisStep={thisStep}
  //               setInVal={setInVal}
  //               timeout={timeout}
  //               handlePrev={handlePrev}
  //             />
  //           ) : (
  //             <OptionsScreen
  //               user={user}
  //               thisStep={thisStep}
  //               setInVal={setInVal}
  //               timeout={timeout}
  //               handlePrev={handlePrev}
  //             />
  //           )}
  //         </div>
  //       </Fade>
  //     </div>
  //   </Slide>
  // );
};

const OptionsScreen = ({ user, thisStep, timeout, handlePrev, setInVal }) => {
  const pipeNew = usePipeNew();

  const { handleSubmit, watch, setValue, setError, clearErrors } =
    useFormContext();
  const { auth_token, account_id } = useAuthSelector();

  // const { data: account, refetch: refetchAccount } = useAccountQuery({});
  const accountBillingMutate = useAccountStripeCustomerMutate();

  const [{ openTour }] = useGlobalTour();

  const { data: usersResp, refetch: refetchUsers } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};

  const { data: phoneNumbersData } = useListPhoneNumbersQuery({
    skip: 0,
    take: 10, // take
    orderBy: [], // order by
    filters: {}, // filters
  });
  const { phoneNumbers } = phoneNumbersData ?? {};

  const [isSaving, setIsSaving] = useState(false);
  const [isFailedBuyingNumber, setIsFailedBuyingNumber] = useState(false);
  const [isFailedSaving, setIsFailedSaving] = useState(false);

  const companyName = watch('companyName');
  const address = watch('address');

  const handleSkip = async () => {
    if (process.env.NODE_ENV !== 'development') {
      alert('Sorry, skipping is currently disabled for beta testing');
      return false;
    }

    setIsSaving(true);
    setIsFailedBuyingNumber(false);
    setIsFailedSaving(false);

    // const { auth_token, account_id } = store.getState().auth;

    // Update account name/address (used for integrations, etc)
    await setupAccountDetails({
      auth_token,
      accountId: account_id,
      companyName,
      address,
    });

    // Setup trunkingio integration
    // - will be used for searching AND purchases
    // await accountBillingMutate.mutateAsync({
    //   data: {
    //     action: 'newCustomer',
    //     data: {},
    //   },
    // });

    // Buy number first
    // - it is OK if fails, we just ask them to buy a different number

    let ptn = null; // watch('ptn');

    try {
      await setupAccountCallerId({
        auth_token,
        ptn,
        accountId: account_id,
        companyName,
      });

      await setupPipes({ auth_token, pipeNew, ptn, phoneNumbers, user, users });
      // await setupNumberDetails({
      //   auth_token,
      //   user,
      //   ptn,
      //   companyName,
      //   address,
      // });
      // const { groupId } = await setupRingGroup({ auth_token, userId: user.id });
      // const { vmboxId } = await setupCompanyVoicemail({ auth_token });
      // const { vmboxId: userVmboxId } = await setupUserVoicemail({
      //   auth_token,
      //   user,
      // });

      // // const vmboxId = '1';
      // await setupMainCallflow({
      //   auth_token,
      //   ptn,
      //   accountId: account_id,
      //   vmboxId,
      //   groupId,
      // });

      // await setupUserCallflow({
      //   auth_token,
      //   presenceId: user?.doc?.presence_id?.toString(),
      //   userId: user?.doc?.id,
      //   vmboxId: userVmboxId,
      //   isUserVmboxId: true,
      // });

      await featureCodesPromise;
    } catch (err: any) {
      // TODO: "should never fail"
      console.error(err.message);
      setIsSaving(false);
      setIsFailedSaving(true);
      return;
    }

    console.log('-DONE-');
    // setIsSaving(false);
    // return;

    // transition to Final Screen
    setTimeout(() => {
      setValue('transitionToStep', thisStep + 1);
      setInVal(false);
      setTimeout(() => {
        // setValue('activeStep', thisStep + 1);
        openTour('setup2');
      }, timeout);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={'flex flex-col space-y-2'}>
        <div className={' font-medium text-3xl '}>Get a new number for $1.</div>
        <div className={'text-md'}>
          Buy a new line with our partner TrunkingIO. It's just that simple.
        </div>
      </div>
      <div className={`flex flex-col space-y-1`}>
        <Button
          size={'lg'}
          className={`w-full`}
          variant={'fill'}
          color={'accent'}
          onClick={() => setValue('chooseNumberOrSkip', true)}
        >
          Get a number with TrunkingIO
        </Button>
        <Button
          size={'lg'}
          className={`w-full`}
          variant={'ghost'}
          color={'neutral'}
          onClick={handleSkip}
        >
          Skip for now, get a number later
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Box
        sx={{
          borderRadius: '8px',
          background: 'white',
          boxShadow: '0px 4px 14px 4px rgba(27, 125, 144, 0.12)',
          p: 5,
          pt: 3,
          pb: 3,
          transition: 'height 2s linear',
          height: 'auto',
        }}
      >
        {isSaving ? (
          <div className="text-lg my-4 text-center">
            <CircularProgress size={44} />
            <br />
            <br />
            One moment while we get your account setup...
            <br />
            <br />
          </div>
        ) : isFailedSaving ? (
          <Box sx={{ mb: 0, textAlign: 'center' }}>
            <div className="text-lg mb-1">
              Sorry, we had a problem finishing setup for your account
            </div>
            <div className="text-md">
              Please contact{' '}
              <a href="mailto:support@calling.io">support@calling.io</a> and
              we'll walk you through the rest of setup.
            </div>
          </Box>
        ) : (
          <>
            <div className="mb-6">
              <div className="text-center text-lg mb-2 truncate w-full">
                Setup a new number for{' '}
                <Tooltip arrow title={watch('companyName')}>
                  <Box
                    component="span"
                    sx={{
                      color: theme => theme.palette.primary.main,
                    }}
                  >
                    {watch('companyName')}
                  </Box>
                </Tooltip>
              </div>
              <br />
              <div
                className="cursor-pointer rounded shadow-sm border border-neutral-100"
                onClick={() => setValue('chooseNumberOrSkip', true)}
              >
                <div className="p-4">
                  <div className="flex justify-between">
                    <div className="flex-auto">
                      <div className="text-lg mb-1">
                        Get a number with TrunkingIO
                      </div>
                      <div className="text-md text-neutral-500">
                        TrunkingIO provides numbers and minutes
                      </div>
                    </div>
                    <div className="flex-initial">
                      <Button
                        color={'positive'}
                        variant={'fill'}
                        className="ml-auto"
                      >
                        Select
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                className="cursor-pointer rounded shadow-sm border border-neutral-100"
                onClick={handleSkip}
              >
                <div className="p-4">
                  <div className="flex justify-between">
                    <div className="flex-auto">
                      <div className="text-lg">Skip getting a number</div>
                      <div className="text-md text-neutral-500">
                        You can always add a phone number integration later
                      </div>
                    </div>
                    <div className="flex-initial">
                      <Button
                        color={'neutral'}
                        variant={'outline'}
                        className="ml-auto"
                      >
                        Skip
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button
                  size="sm"
                  // size="small"
                  // variant="contained"
                  // color="primary"
                  onClick={handlePrev}
                  // onClick={() => setValue('chooseNumberOrSkip', false)}
                  startIcon={<ChevronLeftIcon />}
                >
                  Go back
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default Step2;

import AudioEditor from 'app/components/AudioEditor/AudioEditor';
import { Card, CardContent, CardHeader } from 'app/design';
import { DetailsDialogDevice } from 'app/components/DetailsDialogDevice';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { Setting } from 'app/design-lib';
import { useUpdateAccountPartial } from 'app/hooks/mutations/account';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useAccountQuery } from 'app/hooks/queries/account';
import { DeviceProvisionerSettings } from 'app/pages/admin/Devices/View/components/DeviceProvisionerSettings';
import { DeviceCodecs } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/DeviceCodecs';
import { HoldMusicDevice } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/HoldMusicDevice';
import { NameDevice } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/NameDevice';
import { RestrictionsDevice } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/RestrictionsDevice';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import { Cancel, EditPencil } from 'iconoir-react';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceSettingsCardProps {
  device: Device;
  onComplete: () => void;
}

const DeviceSettingsCard = ({
  device,
  onComplete,
}: DeviceSettingsCardProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);

  // const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
  //   name: 'settings',
  //   options: [
  //     {
  //       label: 'Settings',
  //       value: 'settings',
  //     },
  //   ],
  //   onChoose: 'matchAnyScrollToRef',
  // });

  // const handleOnComplete = () => {
  //   onComplete();
  //   toggleShowEditDialog();
  // };
  const mediaId = device.doc.music_on_hold.media_id;
  const handleOnComplete = () => {
    onComplete();

    toggleShowEditDialog();
  };
  return (
    <>
      <NameDevice device={device} onComplete={onComplete} />
      <SettingsPanel
        leftChild={
          <Setting
            label={'Hold Music'}
            body={'Music played while a caller is on hold.'}
            iconButtonProps={
              showEditDialog
                ? undefined
                : {
                    children: <EditPencil fr={undefined} />,
                    onClick: toggleShowEditDialog,
                  }
            }
            buttonProps={
              showEditDialog
                ? {
                    children: 'Cancel',
                    startIcon: <Cancel fr={undefined} />,
                    color: 'neutral',
                    onClick: toggleShowEditDialog,
                  }
                : undefined
            }
          />
        }
        rightChild={
          showEditDialog ? (
            <EditDeviceHoldMusic
              deviceId={device.id}
              mediaId={mediaId}
              onComplete={handleOnComplete}
              onCancel={toggleShowEditDialog}
            />
          ) : (
            <HoldMusicDevice device={device} onComplete={onComplete} />
          )
        }
      />
      {/* @ts-ignore */}
      {['sip_device'].indexOf(device?.doc?.device_type) > -1 ? (
        <DeviceCodecs device={device} onComplete={onComplete} />
      ) : null}
    </>
  );

  // return (
  //   <>
  //     {/*{showEditDialog ? (*/}
  //     {/*  <DetailsDialogDevice*/}
  //     {/*    deviceId={device.id}*/}
  //     {/*    onCancel={toggleShowEditDialog}*/}
  //     {/*    onComplete={handleOnComplete}*/}
  //     {/*  />*/}
  //     {/*) : null}*/}
  //     {/*@ts-ignore*/}
  //     <div ref={ref} sx={{ height: '100%' }}>
  //       <div className={'text-lg font-bold'}>Device Settings</div>
  //       <div className={'p-1 py-2'}>
  //         <HoldMusicDevice device={device} onComplete={onComplete} />
  //         <br />
  //         <DeviceCodecs device={device} onComplete={onComplete} />
  //         {/*  <br />
  //         <RestrictionsDevice device={device} onComplete={onComplete} />*/}
  //         <br />
  //         <NameDevice device={device} onComplete={onComplete} />
  //         {/* show combo keys / sidecar if physical device */}
  //         {/* {device.doc.provision?.endpoint_brand ? (
  //           <>
  //             <br />
  //             <DeviceProvisionerSettings
  //               device={device}
  //               onComplete={onComplete}
  //             />
  //           </>
  //         ) : null} */}
  //         <br />
  //       </div>
  //     </div>
  //   </>
  // );
};

const EditDeviceHoldMusic = ({ mediaId, deviceId, onComplete, onCancel }) => {
  const updateDevice = useUpdateDevicePartial();

  const onMutate = async (mediaId: string | null) => {
    // error display handled in editor
    const resp = await updateDevice.mutateAsync({
      id: deviceId,
      music_on_hold: {
        media_id: mediaId,
      },
    });

    onComplete();
  };

  return (
    <AudioEditor
      loadingLabel={'Updating hold music...'}
      isLoading={updateDevice.isLoading}
      mediaId={mediaId}
      onClose={onCancel}
      onComplete={onMutate}
      subType={'hold-music'}
    />
  );
};

export default DeviceSettingsCard;

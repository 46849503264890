import {
  ButtonProps,
  default as Button,
} from 'app/design-lib/components/Button/Button';
import { Color } from 'app/design-lib/types';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { NavArrowDown as ArrowIcon } from 'iconoir-react';
import { IconButton } from 'app/design-lib/components/IconButton';

export interface ButtonDropdownProps
  extends Omit<ButtonProps, 'endIcon' | 'type'> {
  hideArrow?: boolean;
  anchor?: 'top' | 'bottom';
  menuItems: (
    | { onClick: () => void; label: ReactNode; icon?: ReactElement }
    | string
  )[];
}

const ButtonDropdown = ({
  menuItems,
  size = 'sm',
  hideArrow = false,
  anchor = 'bottom',
  ...buttonProps
}: ButtonDropdownProps) => {
  return (
    <Menu as="div" className="relative inline-flex item-center">
      {/* @ts-ignore */}
      <Menu.Button
        {...buttonProps}
        as={Button}
        size={size}
        endIcon={hideArrow ? undefined : <ArrowIcon fr={undefined} />}
      />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-10 right-0 ${
            anchor === 'bottom'
              ? size === 'sm'
                ? 'top-8'
                : 'top-10'
              : size === 'sm'
              ? 'bottom-8'
              : 'bottom-10'
          } rounded-sm bg-white  min-w-full shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none `}
        >
          {menuItems.map((menuItem, index) => {
            if (menuItem === 'divider') {
              return <hr />;
            }
            // @ts-ignore
            return <ActualMenuItem key={index} {...menuItem} />;
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const ActualMenuItem = ({ onClick, label, icon, disabled = false }) => {
  return (
    <Menu.Item>
      <div>
        <button
          className={`cursor-pointer
         hover:bg-transparency-10
         active:bg-transparency-20
         whitespace-nowrap text-gray-90  flex space-x-2 items-center text-right 
         justify-start w-full font-medium text-sm font-sans  p-2 m-0
         ${disabled ? 'line-through' : ''}
         `}
          onClick={onClick}
          disabled={disabled}
        >
          {icon
            ? React.cloneElement(icon, {
                className: 'text-gray-90',
              })
            : null}
          <span>{label}</span>
        </button>
      </div>
    </Menu.Item>
  );
};

export default ButtonDropdown;

import { IncomingNode } from './Incoming';
import { FixCreateBlankNode } from './FixCreateBlank';
import { InsertNode } from './Insert';
import { InsertMatchTextOptionNode } from './InsertMatchTextOption';
// import { DirectoryNode } from './Directory';
// import { ConferenceRoomNode } from './ConferenceRoom';
// import { ContinueToCallflowNode } from './ContinueToCallflow';
import { MessageNode } from './Message';
// import { PlayAudioNode } from './PlayAudio';
import { NoteNode } from './Note';
// import { RingNode } from './Ring';
// import { MenuNode } from './Menu';
import { MatchTextNode } from './MatchText';
import { GenericMatchNode } from './GenericMatch';
import { OptionNode } from './Option';
import { OptionHiddenNode } from './OptionHidden';
import { OptionMatchTextNode } from './OptionMatchText';
import { OptionScheduleNode } from './OptionSchedule';
import { OptionStaticNode } from './OptionStatic';
import { MissingNode } from './Missing';
// import { TransferNode } from './Transfer';
import { ScheduleNode } from './Schedule';
// import { VoicemailNode } from './Voicemail';
// import { SimpleNode } from './Simple';
// import { SimpleMenuNode } from './SimpleMenu';
// import { SimpleCallflowNode } from './SimpleCallflow';
import { InsertScheduleOptionNode } from './InsertScheduleOption';
import LastCommunicatedNode from './LastCommunicated/LastCommunicated';

// import { TemplateNode } from './Template';
// import { TemplateEndpointNode } from './TemplateEndpoint';

const nodeTypes = {
  MissingNode,
  FixCreateBlankNode,
  // ContinueToCallflowNode,
  // DirectoryNode,
  // ConferenceRoomNode,
  IncomingNode,
  InsertNode,
  InsertMatchTextOptionNode,
  InsertScheduleOptionNode,
  MatchTextNode,
  GenericMatchNode,
  LastCommunicatedNode,
  MessageNode,
  // PlayAudioNode,
  NoteNode,
  // RingNode,
  OptionNode,
  OptionHiddenNode,
  OptionMatchTextNode,
  OptionScheduleNode,
  OptionStaticNode,
  // TransferNode,
  ScheduleNode,
  // SimpleNode,
  // SimpleMenuNode,
  // SimpleCallflowNode,
  // VoicemailNode,
  // TemplateNode,
  // TemplateEndpointNode,
};

const nodeTypeAllowAfter = {
  // IncomingNode: true,
  // PlayAudioNode: true,
  // MessageNode: true,
  // NoteNode: true,
  // RingNode: true,
};

export { nodeTypes, nodeTypeAllowAfter };

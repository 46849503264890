const temporalRulesSetQueryKeys = {
  all: ['temporalRulesSet'] as const,
  list: (
    orderBy: any,
    filters: any,
    queryPageSize: number,
    queryPageIndex: number,
  ) =>
    [
      ...temporalRulesSetQueryKeys.all,
      'list',
      orderBy,
      filters,
      queryPageSize,
      queryPageIndex,
    ] as const,
  byId: (id: string | undefined) =>
    [...temporalRulesSetQueryKeys.all, id] as const,
};

export default temporalRulesSetQueryKeys;

import {
  createPlateUI,
  createPlugins,
  createReactPlugin,
  createSingleLinePlugin,
  Plate,
} from '@udecode/plate';
import { KeywordTooltip } from 'app/components/KeywordTooltip';
import { Keywords } from 'app/components/KeywordTooltip/KeywordTooltip';
import { MarkKeyword } from 'app/components/SlateSearch/components/MarkKeyword';
import { MarkValue } from 'app/components/SlateSearch/components/MarkValue';
import {
  createKeywordPlugin,
  createValuePlugin,
} from 'app/components/SlateSearch/plugins';
import { MARK_KEYWORD } from 'app/components/SlateSearch/plugins/createKeywordPlugin';
import { MARK_VALUE } from 'app/components/SlateSearch/plugins/createValuePlugin';
import { useSearchDecorations } from 'app/components/SlateSearch/useSearchDecorations';
import { Button } from 'app/design-lib';
import { Search as SearchIcon } from 'app/design/icons-material';
import * as React from 'react';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { Node } from 'slate';

export const KeywordContext = createContext<Keywords>({});

interface SlateSearchProps {
  initialText?: string;
  placeholder?: string;
  loading: boolean;
  onChange?: (search: string) => void;
  onSearch: (search: string) => void;
  searchText?: string;
  searchComponents?: ReactNode;
  keywords?: Keywords;
}

const plugins = createPlugins(
  [
    createReactPlugin(),
    createSingleLinePlugin(),
    createKeywordPlugin(),
    createValuePlugin(),
  ],
  {
    // Plate components
    components: createPlateUI({
      [MARK_KEYWORD]: MarkKeyword,
      [MARK_VALUE]: MarkValue,
    }),
  },
);

const textToSlate = (text: string) => [{ children: [{ text }] }];

const SlateSearch = ({
  loading = false,
  initialText = '',
  placeholder = 'Search...',
  onChange,
  onSearch,
  searchText = '',
  searchComponents,
  keywords,
}: SlateSearchProps) => {
  const decorate = useSearchDecorations();
  // unique id for slate instance
  const [id] = useState(Date.now().toString());

  const [slateValue, setSlateValue] = useState<Node[]>(() =>
    textToSlate(searchText),
  );

  const handleSearch = () => {
    // deserialize slate value
    const text = slateValue.map(n => Node.string(n)).join(' ');
    onSearch(text);
  };

  useEffect(() => {
    setSlateValue(textToSlate(searchText));
  }, [searchText]);

  return (
    <div className={'flex items-center mt-6 px-2 py-2 max-w-[90%] w-[30rem]'}>
      <span className={'text-content-accent'}>
        <SearchIcon />
      </span>
      <div className={'flex-grow border-b mx-2 border-border-neutral'}>
        <KeywordContext.Provider value={keywords ?? {}}>
          <Plate
            id={id}
            value={slateValue}
            onChange={setSlateValue}
            plugins={plugins}
            editableProps={{
              placeholder,
              decorate,
              className: 'bg-background-neutral-active rounded p-1',
              // style: {
              //   // whiteSpace: 'nowrap',
              //   overflowX: 'auto',
              //   overflowY: 'hidden',
              //   width: '100%',
              // },
              onKeyDown: event => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
                // console.log('event', event);
              },
            }}
          />
        </KeywordContext.Provider>
      </div>
      {keywords ? (
        <div>
          <div style={{ height: 20, width: 20 }}>
            <KeywordTooltip keywords={keywords} />
          </div>
        </div>
      ) : null}
      <Button
        variant={'outline'}
        color={'accent'}
        onClick={handleSearch}
        // loading={loading}
        // sx={{
        //   borderRadius: 0,
        //   borderTopRightRadius: '2px',
        //   borderBottomRightRadius: '2px',
        //   mt: '-1px',
        //   mb: '-1px',
        //   // @ts-ignore
        //   // background: theme => theme.palette.card.border,
        //   background: '#6ec7de',
        // }}
      >
        Search
      </Button>
      {searchComponents ? <div>{searchComponents}</div> : null}
    </div>
  );

  // return (
  //   <div className={'whitespace-nowrap flex space-x-1 items-center w-96'}>
  //     <div className={'flex-1 w-full'}>
  //       <div
  //         className={'w-full'}
  //         // sx={{
  //         //   // @ts-ignore
  //         //   border: theme => `1px solid ${theme.palette.card.border}`,
  //         //   background: 'white',
  //         //   // height: 40,
  //         //   borderRadius: 1,
  //         //   // p: 1,
  //         //   // width: '100%',
  //         //   minWidth: 400,
  //         //   // width: 100,
  //         //   whiteSpace: 'nowrap',
  //         //   '& [data-slate-placeholder]': {
  //         //     whiteSpace: 'nowrap',
  //         //     textOverflow: 'ellipsis',
  //         //     overflowY: 'hidden',
  //         //     width: '100%',
  //         //   },
  //         // }}
  //       >
  //         <div className={'flex items-center w-full whitespace-nowrap'}>
  //           <div className={'-mb-2'}>
  //             {/* @ts-ignore */}
  //             <span className={'text-content-accent'}>
  //               <SearchIcon />
  //             </span>
  //           </div>
  //           <div className={'flex-grow bg-red-500'}>
  //             <KeywordContext.Provider value={keywords ?? {}}>
  //               <Plate
  //                 id={id}
  //                 value={slateValue}
  //                 onChange={setSlateValue}
  //                 plugins={plugins}
  //                 editableProps={{
  //                   placeholder,
  //                   decorate,
  //                   style: {
  //                     // whiteSpace: 'nowrap',
  //                     overflowX: 'auto',
  //                     overflowY: 'hidden',
  //                     width: '100%',
  //                   },
  //                   onKeyDown: event => {
  //                     if (event.key === 'Enter') {
  //                       handleSearch();
  //                     }
  //                     // console.log('event', event);
  //                   },
  //                 }}
  //               />
  //             </KeywordContext.Provider>
  //           </div>
  //           {keywords ? (
  //             <div>
  //               <div style={{ height: 20, width: 20 }}>
  //                 <KeywordTooltip keywords={keywords} />
  //               </div>
  //             </div>
  //           ) : null}
  //           {/* <Divider flexItem orientation="vertical" sx={{ ml: 1, mt: 1 }} /> */}
  //           <div>
  //             <Button
  //               variant={'outline'}
  //               color={'accent'}
  //               onClick={handleSearch}
  //               // loading={loading}
  //               // sx={{
  //               //   borderRadius: 0,
  //               //   borderTopRightRadius: '2px',
  //               //   borderBottomRightRadius: '2px',
  //               //   mt: '-1px',
  //               //   mb: '-1px',
  //               //   // @ts-ignore
  //               //   // background: theme => theme.palette.card.border,
  //               //   background: '#6ec7de',
  //               // }}
  //             >
  //               Search
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     {searchComponents ? <div>{searchComponents}</div> : null}
  //   </div>
  // );
};

export default SlateSearch;

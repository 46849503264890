import { gql } from 'graphql-request';

export default gql`
  query devices(
    #    $activeFilters: JSON
    #    $unregisteredFilters: JSON
    $disabledFilters: JSON
  ) {
    #    active: devices(filters: $activeFilters) {
    #      totalCount
    #    }
    #    unregistered: devices(filters: $unregisteredFilters) {
    #      totalCount
    #    }
    disabled: devices(filters: $disabledFilters) {
      totalCount
    }
    devices {
      totalCount
    }
  }
`;

import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { Dialog } from 'app/design-lib';

import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateGroupPartial } from 'app/hooks/mutations/group';
import { useGroupQuery } from 'app/hooks/queries/group';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { PhoneNumber } from 'types/phoneNumber';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface GroupDetailsFields {
  name: string;
}

// interface declaring which props are required/allowed
interface ContentProps {
  groupId: string;
  onCancel: () => void;
  onComplete: () => void;
}

interface GroupDetailsDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

const DetailsDialogGroup = ({
  ContentProps,
  DialogProps,
}: GroupDetailsDialogProps) => {
  return (
    <Dialog open={DialogProps.open} onClose={ContentProps.onCancel}>
      <DetailsDialogGroupContent {...ContentProps} />
    </Dialog>
  );
};

const DetailsDialogGroupContent = ({
  groupId,
  onCancel,
  onComplete,
}: ContentProps) => {
  const { data: group, isLoading: groupIsLoading } = useGroupQuery(groupId);

  const updateGroup = useUpdateGroupPartial();
  const formMethods = useForm<GroupDetailsFields>({
    defaultValues: {
      name: group?.doc.name,
    },
  });
  React.useEffect(() => {
    if (group) {
      formMethods.setValue('name', group.doc.name);
    }
  }, [group]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = formMethods;

  const onSubmit = async (groupForm: GroupDetailsFields) => {
    try {
      await updateGroup.mutateAsync(
        {
          id: groupId,
          ...groupForm,
        },
        // {
        //   onSuccess: () => {
        //     onComplete();
        //   },
        // },
      );
    } catch (e) {}
    // resolve promise with toast notifications
    // const resp = await toast.promise(updateGroupPromise, {
    //   pending: 'Updating group details...',
    //   error: 'Failed to update group details.',
    //   success: 'Group details updated!',
    // });
    //
    // if (resp.status === 'success') {
    //   onComplete();
    // }
  };

  return (
    <GenericMutationDialogContent
      onComplete={onComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'Ring group name updated'}
      isLoadingLabel={'Updating ring group name'}
      title={'Edit Ring Group Name'}
      mutation={updateGroup}
      formMethods={formMethods}
      queryIsLoading={groupIsLoading}
      queryLabel={'Loading ring group details'}
    >
      <HookFormTextField name={'name'} label={'Name'} />
    </GenericMutationDialogContent>
  );

  // return (
  //   <Dialog open={true} fullWidth maxWidth={'xs'}>
  //     {groupIsLoading ? (
  //       <DialogInProgress title={'Loading group details info...'} />
  //     ) : updateGroup.isLoading ? (
  //       <DialogInProgress title={'Updating Group details...'} />
  //     ) : (
  //       <>
  //         <DialogTitle>Edit Ring Group Details</DialogTitle>
  //         <Divider />
  //         {group ? (
  //           <GroupDetailsForm
  //             defaultFormValues={formDefaults}
  //             onCancel={onCancel}
  //             onSubmit={handleSubmit}
  //             errorMessage={
  //               updateGroup.error
  //                 ? `${updateGroup.error.message} ${
  //                     updateGroup.error.response
  //                       ? `: ${updateGroup.error.response?.statusText}`
  //                       : ''
  //                   }`
  //                 : undefined
  //             }
  //           />
  //         ) : (
  //           <>
  //             <DialogContent>
  //               <Typography color={'error'}>Invalid Group ID.</Typography>
  //             </DialogContent>
  //             <Divider />
  //             <DialogActions sx={{ justifyContent: 'left' }}>
  //               <Button variant={'outlined'} color={'error'} onClick={onCancel}>
  //                 Cancel
  //               </Button>
  //             </DialogActions>
  //           </>
  //         )}
  //       </>
  //     )}
  //   </Dialog>
  // );
};

interface GroupDetailsFormProps {
  defaultFormValues?: GroupDetailsFields;
  initialExternalNumber?: PhoneNumber;
  onCancel: () => void;
  onSubmit: (formValues: GroupDetailsFields) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

// const GroupDetailsForm = ({
//   defaultFormValues,
//   onCancel,
//   onSubmit,
//   errorMessage,
//   startDirty = false,
// }: GroupDetailsFormProps) => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors: formErrors, isDirty, dirtyFields },
//     setError,
//     watch,
//     control,
//     setValue,
//   } = useForm<GroupDetailsFields>({
//     defaultValues: defaultFormValues,
//   });
//   const formWatch = watch();
//
//   return (
//     <>
//       <DialogContent>
//         {/* group name */}
//         <TextField
//           label={'Name'}
//           {...register('name')}
//           error={!!formErrors.name}
//           helperText={formErrors.name?.message?.replace('"name"', 'Name')}
//         />
//       </DialogContent>
//       <Divider />
//       <DialogActions sx={{ justifyContent: 'space-between' }}>
//         <Button variant={'outlined'} color={'error'} onClick={onCancel}>
//           Cancel
//         </Button>
//         {errorMessage ? (
//           <Typography color={'error'}>{errorMessage}</Typography>
//         ) : null}
//         <Button
//           variant={'outlined'}
//           color={'success'}
//           disabled={startDirty ? !startDirty : !isDirty}
//           onClick={handleSubmit(onSubmit)}
//         >
//           Update
//         </Button>
//       </DialogActions>
//     </>
//   );
// };

export default DetailsDialogGroup;

import React from 'react';
import { Tooltip, Button, ButtonGroup, IconButton } from 'app/design';
import {
  CropFree as CropFreeIcon,
  PhoneIphone as PhoneIphoneIcon,
} from 'app/design/icons-material';
import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useGlobalTour } from 'app/components/Tourguide/Helper';

import { useMobileAppDialog } from './MobileAppDialog';

const MobileAppButton = () => {
  const dispatch = useDispatch();
  const { actions: localActions } = useLocalSlice();
  const [{ openTour, tourEvents }] = useGlobalTour();

  const {
    toggleOpen: toggleOpenMobileAppDialog,
    MobileAppDialog,
    MobileAppDialogProps,
  } = useMobileAppDialog({});

  const handleOpen = () => {
    toggleOpenMobileAppDialog();
    openTour(null);
    // tourEvents.emit('next');
  };

  return (
    <>
      <MobileAppDialog {...MobileAppDialogProps} />
      <Tooltip title="Mobile App (iOS/Android)" arrow>
        {/* @ts-ignore */}
        <IconButton
          onClick={handleOpen}
          data-tour-is-mobileapp
          color="primary"
          size="small"
        >
          <PhoneIphoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default MobileAppButton;

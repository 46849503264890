import React from 'react';
// import "./button.css";
import { Button as MuiButton } from '@mui/material';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
// @ts-ignore
// import { Link } from "react-router-dom";

export interface ButtonProps {}

const Button = React.forwardRef((props: any, ref) => {
  return (
    <MuiLoadingButton
      ref={ref}
      // component={props.to ? Link : undefined}
      {...props}
    />
  );
});

export default Button;

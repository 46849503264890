// link to theme explorer: https://mui.com/customization/default-theme/

// Figure out how to fix Typescript so we can add new color variations
// TODO: https://mui.com/customization/palette/#adding-new-colors

import lightPalette from './light';
import darkPalette from './dark';

export default function buildTheme(mode: any, node: any) {
  // @ts-ignore
  const palette = mode === 'dark' ? darkPalette : lightPalette;
  const typographyVariants = {
    number1: {
      display: 'block',
      fontFamily: 'DM Sans', // Bitter, Lato, Crimson Pro
      // color: palette.text.primary,
      fontWeight: 700,
      fontSize: '1.1rem',
      lineHeight: '1.5rem',
    },
    bodyBig: {
      display: 'block',
      // fontFamily: "DM Sans", // Bitter, Lato, Crimson Pro
      // color: palette.text.primary,
      // fontWeight: 700,
      fontSize: '1.0rem',
      // lineHeight: "1.5rem",
    },
  };

  const typography = {
    root: {
      fontFamily: ['DM Sans', 'Source Sans Pro', 'sans-serif'].join(','), // Montserrat
      fontSize: 14,
    },
    h1: {
      fontSize: '1.4375rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.1rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.0rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.0rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.0rem',
      lineHeight: '1.5rem',
      fontWeight: 100,
    },
    h6: {
      fontSize: '1.0rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    body1: {
      // color: customPalette.content.color,
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.8125rem', // 13px
      fontWeight: 400,
      color: '25273B', // unknown??
    },
    subtitle1: {
      fontSize: '0.95rem',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.6875rem', // 11px
      fontWeight: 400,
    },
    ...typographyVariants,
  };

  const theme = {
    name: 'CallingIO Theme',
    palette,
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: 'inherit',
          },
        },
      },
      // MuiPopover: {
      //   defaultProps: {
      //     container: node,
      //   },
      //   styleOverrides: {
      //     root: {
      //       zIndex: '1700 !important',
      //     },
      //   },
      // },
      // MuiPopper: {
      //   defaultProps: {
      //     container: node,
      //   },
      //   styleOverrides: {
      //     root: {
      //       zIndex: '1900 !important',
      //     },
      //   },
      // },
      // MuiDialog: {
      //   defaultProps: {
      //     container: node,
      //   },
      //   styleOverrides: {
      //     root: {
      //       zIndex: '1800 !important',
      //     },
      //   },
      // },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            position: 'relative',
            // borderRadius: "30px",
            // borderColor: palette.card?.border,
          },
        },
        variants: [
          {
            props: { variant: 'dashboard' },
            style: {
              borderRadius: '30px',
              border: '1px solid',
              borderColor: palette.card?.border,
            },
          },
          {
            props: { variant: 'list' },
            style: {
              borderRadius: '8px',
              border: '1px solid',
              borderColor: palette.card?.border,
            },
          },
        ],
      },
      MuiCard: {
        styleOverrides: {
          root: {
            position: 'relative',
            overflow: 'visible',
          },
        },
        variants: [
          {
            props: { variant: 'dashboard' },
            style: {
              // borderRadius: "30px",
              // border: "1px solid",
              // borderColor: palette.card?.border,
            },
          },
        ],
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            // fontFamily: "Roboto",
            paddingBottom: 0,
          },
          // sizeSmall: {
          //   padding: "4px 6px",
          // },
        },
        defaultProps: {
          variant: 'outlined',
          titleTypographyProps: {
            variant: 'h2',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            // fontFamily: "Roboto",
          },
          // sizeSmall: {
          //   padding: "4px 6px",
          // },
        },
        defaultProps: {
          disableElevation: true,
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            textTransform: 'none',
          },
          sizeSmall: {
            padding: '4px 6px',
          },
        },
        defaultProps: {
          disableElevation: true,
          disableRipple: true,
        },
        // variants: [
        //   {
        //     props: { variant: "dashed" },
        //     style: {
        //       textTransform: "none",
        //       border: `2px dashed ${palette.primary.main}`,
        //       color: palette.primary.main,
        //     },
        //   },
        // ],
      },
      MuiButtonGroup: {
        styleOverrides: {
          grouped: {
            minWidth: 'auto',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
        // defaultProps: {
        //   margin: "dense",
        // },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            '& .MuiInputBase-inputSizeSmall': {
              // padding: "6px 8px",
              fontSize: '0.9rem',
            },
          },
        },
        // defaultProps: {
        //   margin: "dense",
        // },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'white',
            fontSize: '13px',
            padding: '10px 15px',
            color: '#696969',
            boxShadow: '0 2px 6px rgb(0 0 0 / 20%)',
            border: `1px solid ${palette.card?.border}`,
            '& a': {
              color: palette.primary.main,
            },
            '& a:hover': {
              color: palette.primary.light,
            },
          },
          // tooltipArrow: {
          //   color: "red",
          //   backgroundColor: "blue",
          // },
          arrow: {
            // background: "white",
            // color: "black",
            // boxShadow: "0 5px 10px rgb(0 0 0 / 20%)",
            '&:before': {
              backgroundColor: 'white',
              border: `1px solid ${palette.card?.border}`,
            },
          },
        },
        // defaultProps: {
        //   margin: "dense",
        // },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            '& > .MuiSvgIcon-root': {
              // verticalAlign: "middle", // affects ALL icons inside typography (a bit too broad probably)
            },
          },
        },
        variants: Object.keys(typographyVariants).map(tVariant => ({
          props: {
            variant: tVariant,
          },
          style: typographyVariants[tVariant],
        })),
      },
    },
    typography: {
      ...typography.root,
      ...typography,
    },
  };

  // declare module "@mui/material/styles" {
  //   interface TypographyVariants {
  //     number1: React.CSSProperties;
  //   }

  //   // allow configuration using `createTheme`
  //   interface TypographyVariantsOptions {
  //     number1?: React.CSSProperties;
  //   }
  // }

  // // Update the Typography's variant prop options
  // declare module "@mui/material/Typography" {
  //   interface TypographyPropsVariantOverrides {
  //     number1: true;
  //   }
  // }

  // None of the following overrides are currently actually working
  // - "allow variant overrides in Card" -> https://github.com/mui/material-ui/issues/27267
  // - they SHOULD work, but dont for some reason Nick doesnt understand :(
  // declare module "@mui/material/Card" {
  //   interface CardPropsVariantOverrides {
  //     dashboard: true;
  //   }
  //   interface PaperPropsVariantOverrides {
  //     dashboard: true;
  //   }
  // }
  // declare module "@mui/material/Paper" {
  //   interface PaperPropsVariantOverrides {
  //     dashboard: true;
  //   }
  // }

  return theme;
}

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Button, Tooltip } from 'app/design-lib';
import { Portal } from 'app/design';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CelebrationIcon from '@mui/icons-material/Celebration';
// import { TourProvider, useTour } from '@reactour/tour';
import { useTour } from '@reactour/tour';
import { useGlobalTour } from 'app/components/Tourguide/Helper';

import {
  MonitorHeartOutlined as MonitorHeartOutlinedIcon,
  Star as StarIcon,
} from 'app/design/icons-material';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useLocalSelector } from 'app/data/local';
import Lottie from 'react-lottie-player';
import { usePipesQuery } from 'app/pages/v2/common/sdk';
import { store } from 'store';

// import '@reactour/popover/dist/index.css';

// // enable ALL body targets
// // - don't allow to get stuck
// const targets = [];
// const disableBody = target => {
//   targets.push(target);
//   disableBodyScroll(target);
// };
// const enableBody = () => {
//   for (let target of targets) {
//     enableBodyScroll(target);
//   }
//   enableBodyScroll(document.getElementsByTagName('body'));
// };

// window.disableBody = disableBody;
// window.enableBody = enableBody;

const Setup2Tour = props => {
  const { onClose, tourEvents } = props;

  const history = useHistory();
  // const dispatch = useDispatch();
  // const { callflows } = useSelector((state) => state.lists);

  const [stepNum, setStepNum] = useState(0); // TODO: use redux?
  const [update, setUpdate] = useState(0); // TODO: use redux?

  const [{ openTour, setTourSettingsOverwrite }] = useGlobalTour();
  const { isOpen, currentStep, steps, setSteps, setIsOpen, setCurrentStep } =
    useTour();

  // const runningRef = useRef(null);

  const handleNextStep = () => {
    setStepNum(stepNum + 1);
  };
  const handlePrevStep = () => {
    setStepNum(stepNum - 1);
  };
  const handleAnyStep = curr => {
    console.log('curr:', curr);
  };

  // const onAfterOpen = target => {
  //   // disableBody(target);
  //   console.log('onAfterOpen Setup2');
  //   if (window.location.pathname.indexOf('/v2') === -1) {
  //     history.push('/v2');
  //   }
  // };

  // const onRequestClose = e => {
  //   console.log('onRequestClose Setup2', e);
  //   // setIsTourOpen(false);
  //   // update account tour_complete
  //   onClose && onClose();
  //   enableBody(e.target);
  // };

  const handleUpdate = () =>
    setUpdate(v => {
      return v + 1;
    });

  // handle programatic "update" (usually triggered by a button)
  useEffect(() => {
    tourEvents.on('update', handleUpdate);
    return () => {
      tourEvents.off('update', handleUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hardClose = () => {
    setSteps([]);
    setCurrentStep(0);
    setIsOpen(false);
  };

  const showNormalOptions = (overwrite = {}) =>
    setTourSettingsOverwrite({
      showCloseButton: true,
      showPrevNextButtons: true,
      onClickMask: () => {
        // prevents close when mask is clicked
      },
      ...overwrite,
    });

  const skipTo = id_or_index => () =>
    setCurrentStep(v =>
      typeof id_or_index === 'number'
        ? id_or_index
        : tourSteps.findIndex(s => s.id === id_or_index),
    );
  const showNoActions = (overwrite = {}) =>
    setTourSettingsOverwrite({
      showCloseButton: false,
      showPrevNextButtons: false,
      onClickMask: null,
      ...overwrite,
    });

  const buildSelectors = selector => {
    return {
      highlightedSelectors: [selector],
      mutationObservables: ['.flex'],
      resizeObservables: ['.flex'],
    };
  };

  const tourSteps = [
    {
      selector: 'body',
      content: (
        <Step1
          onClose={hardClose} // () => openTour(null)}
          onCloseSkip={hardClose} // () => openTour(null)}
        />
      ),
      action: () => {
        showNoActions();
      },
      // stepInteraction: true,
      // disableActions: false, // TODO: change to true for prod
      disableActions: false,
    },
    // {
    //   selector: 'body',
    //   // selector: '[data-tour-settings]',
    //   // position: 'left',
    //   content: (
    //     <div className="text-base text-center -mb-[24px]">
    //       <div className="text-2xl">Welcome to CallingIO!</div>
    //       <div className="text-base py-4">Here's a quick highlight video:</div>
    //       <div className="py-4">[Video Showcase]</div>
    //       <div className="">
    //         <Button
    //           size="md"
    //           variant="fill"
    //           color="accent"
    //           className=" mx-auto"
    //           onClick={e => {
    //             setCurrentStep(v => v + 1);
    //           }}
    //           startIcon={
    //             <CelebrationIcon
    //               className={'text-white'}
    //               style={{ fontSize: '20' }}
    //             />
    //           }
    //         >
    //           Get Started!
    //         </Button>
    //       </div>
    //     </div>
    //   ),
    //   action: () => {
    //     showNoActions();
    //   },
    //   // stepInteraction: true,
    //   disableActions: false, // TODO: change to true for prod
    // },
    // {
    //   ...buildSelectors('[data-tour-start]'),
    //   position: 'top',
    //   content: (
    //     <div className="">
    //       First, if you ever want to see this tour again, simply click this
    //       button.
    //       <br />
    //       <br />
    //       When you're ready, go ahead and click the "Next" button below: <br />
    //       <br />
    //       or, you can skip ahead if you want:
    //       <br />
    //       <p>
    //         <a
    //           href="#"
    //           className="underline text-blue-500 hover:text-blue-400"
    //           onClick={skipTo('lines')}
    //         >
    //           Lines
    //         </a>
    //       </p>
    //       <p>
    //         <a
    //           href="#"
    //           className="underline text-blue-500 hover:text-blue-400"
    //           onClick={skipTo('settings')}
    //         >
    //           Settings
    //         </a>
    //       </p>
    //       {/* <p>
    //         <a
    //           href="#"
    //           className="underline text-blue-500 hover:text-blue-400"
    //           onClick={skipTo('rooms')}
    //         >
    //           Rooms
    //         </a>
    //       </p> */}
    //       <p>
    //         <a
    //           href="#"
    //           className="underline text-blue-500 hover:text-blue-400"
    //           onClick={skipTo('team')}
    //         >
    //           Team
    //         </a>
    //       </p>
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   // stepInteraction: true,
    //   disableActions: false, // TODO: change to true for prod
    // },

    // {
    //   selector: '[data-tour-help-mode]',
    //   content: (
    //     <div className="">
    //       Toggle this switch to turn <strong>Help Mode On/Off</strong>.
    //       <br />
    //       <br />
    //       When On, some areas of the app will show this flashing icon button (
    //       <div className={'relative inline-block mx-2 -bottom-[4px]'}>
    //         <button
    //           className={`flex h-5 w-5 m-0 p-0 cursor-pointer background-transparent rounded-full border-none z-10`}
    //         >
    //           <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-background-attention-hover opacity-75"></span>
    //           <span className="relative inline-flex rounded-full items-center justify-center h-5 w-5 bg-background-attention">
    //             <HelpOutlineIcon
    //               className={'text-white'}
    //               style={{ fontSize: '20' }}
    //             />
    //           </span>
    //         </button>
    //         {/* children: */}
    //         <span></span>
    //       </div>
    //       ) that you can click to learn more.
    //       <br />
    //       <br />
    //       Hint: try clicking it On, and see how your name in the the top-left of
    //       the sidebar changes
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: true,
    //   disableActions: false,
    // },

    // {
    //   id: 'lines',
    //   selector: '[data-tour-sidebar-line-external]',
    //   position: 'left',
    //   content: (
    //     <div className="-mb-[24px]">
    //       <div className="text-2xl mb-2">Welcome to CallingIO!</div>
    //       <div className="text-md">
    //         Here is your new number. Clicking it will show you all the
    //         conversations for that number.
    //       </div>
    //       {/* <div className="text-md">
    //         <Button
    //           size="md"
    //           variant="outlined"
    //           color="accent"
    //           className=" mx-auto"
    //           onClick={e => {
    //             setCurrentStep(v => v + 1);
    //           }}
    //         >
    //           Let's Get Started!
    //         </Button>
    //       </div> */}
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    //   // action: () => {
    //   //   showNoActions();
    //   // },
    //   // stepInteraction: true,
    //   // disableActions: true,
    //   // action: () => {
    //   //   showNoActions();
    //   // },
    //   // // stepInteraction: true,
    //   // disableActions: false, // TODO: change to true for prod
    // },
    // {
    //   // id: 'pipe_settings',
    //   selector: '[data-tour-is-pipe-settings-button]',
    //   position: 'left',
    //   content: (
    //     <div className="-mb-[24px]">
    //       {/* <div className="text-2xl mb-2">Welcome to CallingIO!</div> */}
    //       <div className="text-md">
    //         Change your Line Settings by clicking the Gear icon.
    //         <br />
    //         You can change how incoming calls are routed, your voicemail
    //         greeting, etc.
    //       </div>
    //     </div>
    //   ),
    //   // action: () => {
    //   //   showNoActions();
    //   // },
    //   // stepInteraction: true,
    //   // disableActions: true,
    //   // // action: () => {
    //   // //   showNoActions();
    //   // // },
    //   // // // stepInteraction: true,
    //   // // disableActions: false, // TODO: change to true for prod
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // {
    //   // id: 'pipe_settings',
    //   selector: '[data-tour-is-settings-button]',
    //   position: 'left',
    //   content: (
    //     <div className="-mb-[24px]">
    //       {/* <div className="text-2xl mb-2">Welcome to CallingIO!</div> */}
    //       <div className="text-md">More settings are available here.</div>
    //     </div>
    //   ),
    //   // action: () => {
    //   //   showNoActions();
    //   // },
    //   // stepInteraction: true,
    //   // disableActions: true,
    //   // // action: () => {
    //   // //   showNoActions();
    //   // // },
    //   // // // stepInteraction: true,
    //   // // disableActions: false, // TODO: change to true for prod
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },

    // {
    //   selector: '[data-tour-line-conversations]',
    //   position: 'left',
    //   highlightedSelectors: ['[data-tour-line-conversations]'],
    //   mutationObservables: ['.flex'],
    //   resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       When you send or receive a phone call or text message using a Line's
    //       number, those conversations will appear here.
    //       <br />
    //       <br />
    //       <span className="italic">
    //         Right now these are only visible to you but you can always add more
    //         team members.
    //       </span>
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-line-not-internal]',
    //   position: 'left',
    //   content: (
    //     <div className="">
    //       Here is an example conversation between your Primary Line and somebody
    //       else.
    //       <br />
    //       <br />
    //       <span className="italic">Please click the conversation</span>
    //     </div>
    //   ),
    //   action: () => {
    //     showNoActions();
    //   },
    //   stepInteraction: true,
    //   disableActions: true,
    // },
    // {
    //   selector: '[data-tour-line-conversation]',
    //   position: 'left',
    //   content: (
    //     <div className="">
    //       The conversation between you and the customer happens in this window.
    //       Calls, Voicemails, and SMS/MMS Messages will be shown here.{' '}
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-send-message-in-conversation]',
    //   // position: 'left',
    //   content: (
    //     <div className="">
    //       You can easily reply to customers using the textbox at the bottom.
    //       <br />
    //       <br />
    //       <span className="italic">
    //         Please note that SMS/MMS is currently not delivered!
    //       </span>
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-line-chat]',
    //   position: 'left',
    //   content: (
    //     <div className="">
    //       Contact information for conversation participants will be shown here.
    //       <br />
    //       <br />
    //       Click the{' '}
    //       <MonitorHeartOutlinedIcon style={{ width: 18, height: 18 }} /> icon in
    //       order to see Activity (emojis, resolved/unresolved status changes,
    //       etc) for a conversation.
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-line-internal]',
    //   position: 'left',
    //   content: (
    //     <div className="">
    //       Line Team Chat, for internal messages related to the Line (but not to
    //       a specific conversation with a customer in the Line!)
    //       <br />
    //       <br />
    //       <span className="italic">
    //         Until you have team members, this is more like a personal notepad
    //       </span>
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-sidebar-line-personal]',
    //   position: 'left',
    //   content: (
    //     <div className="-mb-[24px]">
    //       This is your Personal Extension.
    //       <br />
    //       <br />
    //       When you add team members or have more complex call routing needs, it
    //       will be useful.
    //     </div>
    //   ),
    //   action: () => {
    //     // showNoActions();
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    //   // stepInteraction: true,
    //   // disableActions: true,
    // },
    // {
    //   selector: '[data-tour-line-conversations]',
    //   position: 'left',
    //   highlightedSelectors: ['[data-tour-line-conversations]'],
    //   mutationObservables: ['[data-tour-line-conversations]'],
    //   resizeObservables: ['[data-tour-line-conversations]'],
    //   content: (
    //     <div className="">
    //       Until you have team members, your Personal Line is usually empty
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   id: 'settings',
    //   ...buildSelectors('[data-tour-settings]'),
    //   position: 'left',
    //   highlightedSelectors: ['[data-tour-settings]'],
    //   mutationObservables: ['.flex'],
    //   resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       Let's see what settings we can customize in Settings...
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   id: 'settings',
    //   selector: '[data-tour-settings-link-users]',
    //   position: 'left',
    //   highlightedSelectors: ['[data-tour-settings-link-users]'],
    //   mutationObservables: ['.flex'],
    //   resizeObservables: ['.flex'],
    //   content: <div className="">All your Users are available here.</div>,
    //   action: () => {
    //     history.push('/v2/settings');
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-settings-users]',
    //   position: 'left',
    //   highlightedSelectors: [
    //     '[data-tour-settings-users]',
    //     // '[data-tour-settings-users]',
    //   ],
    //   mutationObservables: ['.flex'],
    //   resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       Add Team Members easily, and give them access to Lines.
    //     </div>
    //   ),
    //   action: () => {
    //     history.push('/v2/settings/users');
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   ...buildSelectors('[data-tour-settings-link-lines]'),
    //   position: 'left',
    //   content: <div className="">Let's see how Lines are setup.</div>,
    //   action: () => {
    //     history.push('/v2/settings/pipes');
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-settings-pipes]',
    //   position: 'left',
    //   content: (
    //     <div className="">
    //       You can set up additional Lines (phone numbers or extensions) at any
    //       time.
    //     </div>
    //   ),
    //   action: () => {
    //     history.push('/v2/settings/pipes');
    //     showNormalOptions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-settings-pipes-external]',
    //   position: 'left',
    //   content: (
    //     <div className="">
    //       Click on your Primary Line to see how it is setup.
    //     </div>
    //   ),
    //   action: () => {
    //     showNoActions();
    //   },
    //   stepInteraction: true,
    //   disableActions: true,
    // },
    // {
    //   selector: '[data-tour-settings-lines-view-users]',
    //   position: 'left',
    //   highlightedSelectors: ['[data-tour-settings-lines-view-users]'],
    //   mutationObservables: ['.flex'],
    //   resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       Add/Remove users that can participate in a Line, including sending and
    //       viewing messages with customers.
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-settings-lines-view-voicemail]',
    //   position: 'left',
    //   highlightedSelectors: ['[data-tour-settings-lines-view-voicemail]'],
    //   mutationObservables: ['.flex'],
    //   resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       Voicemail can be configured to have a custom greeting for this Line.
    //       When a Voicemail is left, the Line will be notified as well.
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-settings-lines-view-callrouting]',
    //   position: 'left',
    //   highlightedSelectors: ['[data-tour-settings-lines-view-callrouting]'],
    //   mutationObservables: ['.flex'],
    //   resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       Be default, an incoming call to the Line will ring all members, then
    //       go to voicemail.
    //       <br />
    //       <br />
    //       But, you can change it to suite your needs! Including such use cases
    //       as adding a schedule (below), or complex menu systems.
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: false,
    //   disableActions: false,
    // },
    // // {
    // //   id: 'rooms',
    // //   selector: '[data-tour-sidebar-rooms]',
    // //   position: 'left',
    // //   content: (
    // //     <div className="">
    // //       Rooms can be used for notes and discussions not related to Lines
    // //     </div>
    // //   ),
    // //   action: () => {
    // //     showNormalOptions();
    // //   },
    // //   disableActions: false,
    // // },

    // {
    //   selector: '[data-tour-username]',
    //   // position: 'left',
    //   // highlightedSelectors: ['[data-tour-username]'],
    //   // mutationObservables: ['.flex'],
    //   // resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       The green dot lets team members know you're available.
    //       <br />
    //       <br />
    //       Click your username to change your status to Away (you will need to
    //       manually change it back).
    //       <br />
    //       <br />
    //       (You can change your Photo later, in "Settings" -&gt; "My Profile")
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: true,
    //   disableActions: false,
    // },

    // {
    //   selector: '[data-tour-dnd]',
    //   // position: 'left',
    //   // highlightedSelectors: ['[data-tour-username]'],
    //   // mutationObservables: ['.flex'],
    //   // resizeObservables: ['.flex'],
    //   content: (
    //     <div className="">
    //       Disable this setting if you want to avoid taking phone calls (they
    //       will skip ringing any of your devices)
    //     </div>
    //   ),
    //   action: () => {
    //     showNormalOptions();
    //   },
    //   stepInteraction: true,
    //   disableActions: false,
    // },
    // {
    //   id: 'team',
    //   selector: '[data-tour-sidebar-team]',
    //   position: 'left',
    //   content: (
    //     <>
    //       When your team expands, you can call and send messages to each other!
    //       <br />
    //       <br />
    //       <Button
    //         variant="outline"
    //         color="success"
    //         className="mx-auto"
    //         onClick={e => {
    //           setSteps([]);
    //         }}
    //       >
    //         Done with Tour!
    //       </Button>
    //     </>
    //   ),
    //   action: () => {
    //     history.push('/v2');
    //     showNoActions();
    //   },
    //   disableActions: false,
    // },
    // {
    //   selector: '[data-tour-sidebar-rooms]',
    //   position: 'left',
    //   content: ({ goTo, step }) => {
    //     return (
    //       <>
    //         <div className="h5">Rooms are where...</div>
    //         {/* <Typography variant="body1">Click here first</Typography> */}
    //       </>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    // if (window.location.pathname.indexOf('/v2') === -1) {
    history.push('/v2');
    // }
    setSteps(tourSteps);
    setCurrentStep(0);
    setIsOpen(true);
  }, []);

  return null;
  // (
  //   <>
  //     <TourProvider
  //       steps={tourSteps}
  //       styles={styles}
  //       padding={{ popover: popoverPadding + 5 }}
  //       // // isOpen={isTourOpen}
  //       // isOpen
  //       // onRequestClose={onRequestClose}
  //       // onAfterOpen={onAfterOpen}
  //       afterOpen={() => alert(1)}
  //       onAfterOpen={() => alert(4)}
  //       // update={`${window.location.pathname}-${update}`}
  //       // goToStep={stepNum}
  //       // nextStep={handleNextStep}
  //       // prevStep={handlePrevStep}
  //       // getCurrentStep={handleAnyStep}
  //       // showButtons={false}
  //       // showNumber={false}
  //       // showNavigation={true}
  //       // // disableInteraction={true}
  //       // showCloseButton={false}
  //       // closeWithMask
  //       // disableDotsNavigation={true}
  //       // badgeContent={(curr, tot) => `${curr} of ${tot}`}
  //       // // inViewThreshold={10000} // no real change it seemed like
  //       // scrollDuration={1000}
  //       // rounded={30}
  //       // maskSpace={0}
  //       // // className="tmp-tour-hide-content"
  //       // // maskClassName={'tmp-mask-class'}
  //     >

  //       </TourProvider>
  //   </>
  // );
};
const Step1 = ({ onClose, onCloseSkip }) => {
  const { channels } = useLocalSelector();

  const [showAnimationFireworks, setShowAnimationFireworks] = useState(false);
  const pipesQuery = usePipesQuery({
    filters: {
      users: {
        path: [store.getState().auth.owner_id, 'enabled'],
        equals: true,
      },
      type: 'external',
      NOT: {
        type: 'room',
      },
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];
  const number = pipes?.[0]?.key;

  useEffect(() => {
    if (channels.length) {
      setShowAnimationFireworks(true);
    }
  }, [channels]);

  return (
    <>
      <div className="text-center ">
        {/* {showAnimation ? ( */}
        <Portal>
          <div
            className=""
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              transform: 'scaleY(-1)',
              zIndex: 10000,
            }}
          >
            <Lottie
              path="https://assets3.lottiefiles.com/private_files/lf30_5aubt2fy.json"
              // background="transparent"
              speed={0.3}
              delay
              style={{
                width: '100%',
                height: '100%',
                // marginTop: -50,
                // marginLeft: -50,
                // animationFillMode: 'forwards',
              }}
              // hover
              play
              loop={false}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              // onComplete={hideAnimation}
            />
          </div>
        </Portal>
        {/* ) : null} */}
        {channels?.length ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // transform: 'scaleY(-1)',
              // zIndex: 10000,
            }}
          >
            <Lottie
              path="https://assets10.lottiefiles.com/packages/lf20_ea475x1k.json"
              // background="transparent"
              speed={1}
              delay
              style={{
                width: '100%',
                height: '100%',
                // marginTop: -50,
                // marginLeft: -50,
                // animationFillMode: 'forwards',
              }}
              // hover
              play
              // loop={false}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              // onComplete={hideAnimation}
            />
          </div>
        ) : null}
        <div className="text-2xl text-positive-70 mb-4">All Good to Go!</div>
        {number ? (
          <>
            <Tooltip
              open={channels?.length}
              title={
                <div>
                  {channels.map(ch => (
                    <div key={ch.id} className="text-content">
                      Incoming Call:{' '}
                      {ch.listing.custom_channel_vars['E164-Origination'] ? (
                        <PhoneNumberDisplay
                          ptn={
                            ch.listing.custom_channel_vars['E164-Origination']
                          }
                          allowExtension
                        />
                      ) : (
                        'Internal Caller'
                      )}
                    </div>
                  ))}
                </div>
              }
              arrow
              placement="top"
              componentsProps={{ popper: { disablePortal: true } }}
            >
              <div className="text-2xl mb-1">
                {number ? <PhoneNumberDisplay ptn={number} /> : '-'}
              </div>
            </Tooltip>
            <div className="mb-4">
              <div className="text-sm">
                (Try calling your new number using your personal phone!)
              </div>
            </div>
          </>
        ) : null}
        <div className="mx-auto max-w-[400px] text-left space-y-2">
          <div className="flex items-center">
            <div className="text-amber-500 pr-2">
              <StarIcon size={44} />
            </div>
            <div className="flex-auto">
              <div className="text-lg">
                {/* Make calls with your built-in Browser Phone */}
                Add team members, then call / text them
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-amber-500 pr-2">
              <StarIcon size={44} />
            </div>
            <div className="flex-auto">
              <div className="text-lg">
                {/* Personalize your incoming call routing */}
                Personalize your voicemail greetings
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-amber-500 pr-2">
              <StarIcon size={44} />
            </div>
            <div className="flex-auto">
              <div className="text-lg">
                {/* Make and receive calls on the mobile app */}
                Get the iOS app for on-the-go voice/text
              </div>
            </div>
          </div>
        </div>
        <br />
        <Button
          size="md"
          variant="fill"
          color="accent"
          className=" mx-auto"
          onClick={onCloseSkip}
          startIcon={
            <CelebrationIcon
              className={'text-white'}
              style={{ fontSize: '20' }}
            />
          }
        >
          Get Started!
        </Button>
        {/* <Button onClick={onClose} variant="contained">
          Start the Product Tour!
        </Button>
        <br />
        <br />
        or,&nbsp;
        <Link onClick={onCloseSkip}>
          skip the tour, let me get started immediately.
        </Link> */}
      </div>
    </>
  );
};

export default Setup2Tour;

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useMediaQuery } from '@mui/material';
import { UserListOptions } from 'app/components/ListUsers/components/UserListOptions';
import { Button } from 'app/design-lib';
import NavItem from 'app/design-lib/components/NavItem/NavItem';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { LogoIcon } from 'app/design-lib/LogoIcon';
import { Divider } from 'app/design-lib/mobile-components/Divider';
import { DropdownMenu } from 'app/design-lib/mobile-components/DropdownMenu';
import { CancelSubDialog } from 'app/pages/admin/Billing/Home/components/CancelSubDialog';
import useDevMode from 'app/utilities/useDevMode';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { AccountMini } from 'app/components/Sidebar/components/AccountMini';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import { ErrorBoundary } from 'app/components/ErrorBoundary';

import {
  User as UserIcon,
  Group as GroupIcon,
  SmallShopAlt as SmallShopAltIcon,
  Phone as PhoneIcon,
  CreditCards as CreditCardsIcon,
  Desk as DeskIcon,
  Message as MessageIcon,
  UserSquare as UserSquareIcon,
  LogOut as LogOutIcon,
  Menu,
  HeadsetHelp,
} from 'iconoir-react';

import { useGlobalTour } from 'app/components/Tourguide/Helper';
import { useHint, useHints } from 'app/utilities';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import SimpleBar from 'simplebar-react';
import constants from 'app/constants';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { SettingsCard } from 'app/pages/admin/Dashboard/components/SettingsCard';
import AdminBillingHome from 'app/pages/admin/Billing/Home/Home';
import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { ListDevices } from 'app/components/ListDevices';

import { useListUsersQuery } from 'app/hooks/queries/user';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { UserPrivLevel } from 'types/user';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';

import { usePubNub } from 'pubnub-react';

import { usePipeNew } from '../Pipes/Pipes';
import { Users as UsersSettings } from '../Users';
import { Devices as DevicesSettings } from '../Devices';
import { Schedules as SchedulesSettings } from '../Schedules';
import { Home as CampaignsSettingsHome } from '../Campaigns/Home';
import { BrandCreate as CampaignsSettingsBrandCreate } from '../Campaigns/BrandCreate';
import { CampaignCreate as CampaignsSettingsCampaignCreate } from '../Campaigns/CampaignCreate';
import { DeviceView as DeviceViewSettings } from '../Devices/View';
import { Groups as GroupsSettings } from '../Groups';
import { Pipes as PipesSettings } from '../Pipes';
import { PipeView as PipeViewSettings } from '../PipeView';
import { Rooms as RoomsSettings } from '../Rooms';
import { RoomView as RoomViewSettings } from '../RoomView';
import { PipeEditCallRouting as PipeEditCallRoutingSettings } from '../PipeEditCallRouting';
import { PipeEditMessageRouting as PipeEditMessageRoutingSettings } from '../PipeEditMessageRouting';

import setupPipes from 'app/components/Onboarding/Vsb/Step3/fn/setupPipes';

import { UserProfile as UserProfileSettings } from '../UserProfile';
import { UserDevices as UserDevicesSettings } from '../UserDevices';
import setupFeatureCodesAndNomatch from 'app/components/Onboarding/Vsb/Step3/fn/setupFeatureCodesAndNomatch';
import PipeEditCallRouting from 'app/pages/v2/settings/PipeEditCallRouting/PipeEditCallRouting';
import IconButton from 'app/design-lib/components/IconButton/IconButton';
import { MobileAppButton } from 'app/pages/v2/settings/UserDevices/MobileAppButton';

function Settings() {
  // useRouteMatch() // no argument, returns current match? (but that is the PARENT Route, not the Setting Route...)

  const { data: authenticatedUser } = useAuthenticatedUserQuery();

  const [{ openTour }] = useGlobalTour();
  const { devMode, toggleDevMode } = useDevMode();
  const router = useHistory();

  const navLinkClassName = isActive =>
    `inline-flex items-center space-x-2 font-medium text-md text-neutral-600 ${
      isActive ? 'no-underline' : 'no-underline'
    } hover:text-neutral-900 hover:underline hover:`;

  const { pathname } = useLocation();

  const isMobile = useIsMobile();

  if (isMobile) {
    const handleRoute = (path: string) => () => {
      router.push(path);
    };

    // @ts-ignore
    const routes = []
      .concat(
        // @ts-ignore
        authenticatedUser?.doc.priv_level === UserPrivLevel.Admin
          ? [
              {
                label: 'Workspace Settings',
                disabled: true,
                onClick: () => {},
                className: '!text-sm !py-1',
              },
              {
                label: 'Company',
                onClick: handleRoute('/v2/settings/account'),
              },
              { label: 'Team', onClick: handleRoute('/v2/settings/users') },
              {
                label: 'Lines',
                onClick: handleRoute('/v2/settings/pipes'),
                // badgeProps: {
                //   children: 'Add numbers',
                //   reverse: true,
                // },
              },
              {
                label: 'Billing',
                onClick: handleRoute('/v2/settings/billing'),
              },
              {
                label: 'Devices',
                onClick: handleRoute('/v2/settings/devices'),
              },
              { label: 'Groups', onClick: handleRoute('/v2/settings/groups') },
              {
                label: '10DLC2',
                onClick: handleRoute('/v2/settings/campaigns'),
              },
            ]
          : [],
      )
      .concat([
        {
          label: 'My Settings',
          disabled: true,
          onClick: () => {},
          className: `!text-sm !pb-1 ${
            authenticatedUser?.doc.priv_level === UserPrivLevel.Admin
              ? `!pt-8`
              : ''
          }`,
        },
        {
          label: 'My Profile',
          onClick: handleRoute('/v2/settings/my_profile'),
        },
        {
          label: 'My Devices',
          onClick: handleRoute('/v2/settings/my_devices'),
        },
        {
          label: 'Support',
          href: `https://support.calling.io/`,
          target: '_blank',
        },
        {
          label: 'Logout',
          onClick: handleRoute('/logout'),
        },
      ]);

    return (
      <div className={`fixed pt-2 flex flex-col h-screen inset-0`}>
        <div
          className={`flex px-2 pb-2 pl-3 w-full justify-between items-center`}
        >
          <div className={`pl-1`}>
            <LogoIcon width={110} />
          </div>
          <div className={`flex space-x-3 items-center`}>
            <MobileAppButton />
            {/*<IconButton
              component={'a'}
              href={`https://support.calling.io/`}
              target={`_blank`}
              variant={'ghost'}
              color={'accent'}
              size={'lg'}
            >
              <HeadsetHelp fr={undefined} />
            </IconButton>*/}
            <DropdownMenu
              buttonProps={{ variant: 'ghost', size: 'lg', color: 'accent' }}
              options={routes}
            >
              <Menu fr={undefined} />
            </DropdownMenu>
          </div>
        </div>
        <Divider />
        <div
          className={`px-2 sm:px-2 h-[calc(100svh-80px)] flex flex-col flex-1`}
        >
          <SettingRoutes authenticatedUser={authenticatedUser} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex bg-background-neutral">
      <div
        className={`w-[240px] flex-[0_0_auto] flex-none border-solid border-0 border-r border-neutral-20`}
      >
        <div className="relative h-screen flex flex-col pb-1 pt-[15px]">
          <div className={'overflow-hidden h-full'}>
            <SimpleBar style={{ height: '100%' }}>
              {authenticatedUser?.doc.priv_level === UserPrivLevel.Admin ? (
                <div className={'w-full border-b border-neutral-20'}>
                  <div
                    className={
                      'px-4 flex items-center space-x-1 mb-2 text-neutral-600'
                    }
                  >
                    <div className="text-sm text-neutral-60">
                      Workspace settings
                    </div>
                  </div>
                  <div className="">
                    <NavItem
                      label={'Company'}
                      pathName={pathname}
                      to={'/v2/settings/account'}
                    />
                    <NavItem
                      label={'Team'}
                      pathName={pathname}
                      to="/v2/settings/users"
                      badgeProps={{
                        children: 'Add teammates',
                        reverse: true,
                      }}
                      data-tour-settings-link-users
                    />
                    <NavItem
                      label={'Lines'}
                      pathName={pathname}
                      to="/v2/settings/pipes"
                      // badgeProps={{
                      //   children: 'Add numbers',
                      //   reverse: true,
                      // }}
                      data-tour-settings-link-lines
                    />

                    <NavItem
                      label={'Devices'}
                      pathName={pathname}
                      to="/v2/settings/devices"
                      data-tour-settings-link-devices
                    />

                    <NavItem
                      label={'Groups'}
                      pathName={pathname}
                      to="/v2/settings/groups"
                      data-tour-settings-link-groups
                    />

                    {devMode ? (
                      <NavItem
                        label={'10DLC'}
                        pathName={pathname}
                        to="/v2/settings/campaigns"
                        data-tour-settings-link-campaigns
                      />
                    ) : null}

                    <NavItem
                      label={'Billing'}
                      pathName={pathname}
                      to="/v2/settings/billing"
                    />

                    {/*  <div className="flex space-x-2">

                <NavLink
                  className={navLinkClassName} to="/v2/settings/media">Media</NavLink>
              </div> */}

                    {/* TODO: only show Schedules if you HAVE an existing schedule? */}
                    {/*  <div className="flex space-x-2">

                    <NavLink
                      className={navLinkClassName}
                      to="/v2/settings/schedules"
                    >
                      Schedules
                    </NavLink>
                  </div> */}
                  </div>
                </div>
              ) : null}

              <div
                className={
                  'px-4 mt-2 flex items-center space-x-1 mb-2 text-neutral-600'
                }
              >
                <div className="text-sm text-neutral-60">My settings</div>
              </div>
              <div className="">
                <NavItem
                  label={'Profile'}
                  pathName={pathname}
                  to={'/v2/settings/my_profile'}
                />
                <NavItem
                  label={'My Devices'}
                  pathName={pathname}
                  to={'/v2/settings/my_devices'}
                />
              </div>
              {/* <div className="py-2">
                <span
                  className="py-2 px-4 text-md text-blue-50 hover:text-blue-40 hover:underline cursor-pointer"
                  // startIcon={<CloseIcon />}
                  onClick={e => {
                    window.scrollTo(0, 0);
                    openTour('setup2');
                    // openTour('onboarding');
                  }}
                >
                  Onboarding Tour
                </span>
              </div> */}
            </SimpleBar>
          </div>
          <div
            className={
              'flex-1 justify-end flex-col flex border-t border-neutral-100'
            }
          >
            <NavItem label={'Logout'} pathName={pathname} to={'/logout'} />
          </div>
        </div>
      </div>
      <SettingRoutes authenticatedUser={authenticatedUser} />
    </div>
  );
}

const SettingRoutes = ({ authenticatedUser }) => {
  const isMobile = useIsMobile();
  return (
    <ErrorBoundary>
      <Switch>
        <Route
          exact
          path="/v2/settings"
          render={() => {
            if (isMobile) {
              return (
                <SimpleBar style={{ height: '100%' }}>
                  <div className="pt-4 pb-20">
                    {authenticatedUser?.doc.priv_level ===
                    UserPrivLevel.Admin ? (
                      <div className="mb-12 flex flex-col space-y-2 divide-y divide-neutral-20">
                        <div className="text-md text-neutral-60">
                          Workspace Settings
                        </div>
                        <div className={'pt-2'}>
                          <Link
                            className="text-lg  text-blue-500 px-2 p-1 inline-block"
                            to="/v2/settings/account"
                          >
                            <span className={`underline`}>Company</span>
                            <p
                              className={`pt-1  text-md !no-underline text-neutral-60`}
                            >
                              Configure account wide settings for your business.
                            </p>
                          </Link>
                        </div>
                        <div className={'pt-2'}>
                          <Link
                            className="text-lg text-blue-500 px-2 p-1 inline-block"
                            to="/v2/settings/users"
                          >
                            <span className={`underline`}>Team</span>
                            <p
                              className={`pt-1 text-md !no-underline text-neutral-60`}
                            >
                              Invite and manage team members.
                            </p>
                          </Link>
                        </div>
                        <div className={`pt-2`}>
                          <Link
                            className="text-lg text-blue-500 px-2 p-1 inline-block"
                            to="/v2/settings/pipes"
                          >
                            <span className={`underline`}>Lines</span>
                            <p
                              className={`pt-1 text-md !no-underline text-neutral-60`}
                            >
                              Customize existing lines and purchase new ones.
                            </p>
                          </Link>
                        </div>
                        <div className={`pt-2`}>
                          <Link
                            className="text-lg text-blue-500 px-2 p-1 inline-block"
                            to="/v2/settings/billing"
                          >
                            <span className={`underline`}>Billing</span>
                            <p
                              className={`pt-1 text-md !no-underline text-neutral-60`}
                            >
                              Manage payment methods and view invoices.
                            </p>
                          </Link>
                        </div>
                        <div className={`pt-2`}>
                          <Link
                            className="text-lg text-blue-500 px-2 p-1 inline-block"
                            to="/v2/settings/devices"
                          >
                            <span className={`underline`}>Devices</span>
                            <p
                              className={`pt-1 text-md !no-underline text-neutral-60`}
                            >
                              Add and configure team devices.
                            </p>
                          </Link>
                        </div>
                        <div className={`pt-2`}>
                          <Link
                            className="text-lg text-blue-500 px-2 p-1 inline-block"
                            to="/v2/settings/groups"
                          >
                            <span className={`underline`}>Groups</span>
                            <p
                              className={`pt-1 text-md !no-underline text-neutral-60`}
                            >
                              Create ring strategies to make sure you never miss
                              a call.
                            </p>
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    <div className="mb-12 flex flex-col space-y-2 divide-y divide-neutral-20">
                      <div className="text-md text-neutral-60">My Settings</div>
                      <div className={'pt-2'}>
                        <Link
                          className="text-lg text-blue-500 px-2 p-1 inline-block"
                          to="/v2/settings/my_profile"
                        >
                          <span className={`underline`}>My Profile</span>
                          <p
                            className={`pt-1 text-md !no-underline text-neutral-60`}
                          >
                            Edit your personal details and avatar.
                          </p>
                        </Link>
                      </div>
                      <div className={`pt-2`}>
                        <Link
                          className="text-lg text-blue-500 px-2 p-1 inline-block"
                          to="/v2/settings/my_devices"
                        >
                          <span className={`underline`}>My Devices</span>
                          <p
                            className={`pt-1 text-md !no-underline text-neutral-60`}
                          >
                            Manage your soft and physical devices.
                          </p>
                        </Link>
                      </div>
                      <div className="mt-8">
                        <Link
                          className="text-lg underline text-blue-500 px-2 p-1 inline-block"
                          to="/logout"
                        >
                          Logout
                        </Link>
                      </div>
                    </div>
                  </div>
                </SimpleBar>
              );
            }
            return <Redirect to="/v2/settings/my_profile" />;
          }}
        />
        <Route path="/v2/settings/account" component={AccountSettings} />
        <Route path="/v2/settings/users" component={UsersSettings} />
        <Route path="/v2/settings/groups" component={GroupsSettings} />
        <Route
          path="/v2/settings/pipes/:pipe_id/call_routing/:callflow_id"
          component={PipeEditCallRoutingSettings}
        />
        <Route
          path="/v2/settings/pipes/:pipe_id/message_routing/:callflow_id"
          component={PipeEditMessageRoutingSettings}
        />
        <Route
          path="/v2/settings/pipes/:pipe_id"
          component={PipeViewSettings}
        />
        <Route path="/v2/settings/pipes" component={PipesSettings} />
        <Route
          path="/v2/settings/rooms/:room_id"
          component={RoomViewSettings}
        />
        <Route path="/v2/settings/rooms" component={RoomsSettings} />
        <Route path="/v2/settings/billing" component={BillingSettings} />
        <Route
          path="/v2/settings/devices/:device_id"
          component={DeviceViewSettings}
        />
        <Route path="/v2/settings/devices" component={DevicesSettings} />
        <Route path="/v2/settings/media" component={MediaSettings} />
        <Route path="/v2/settings/schedules" component={SchedulesSettings} />

        <Route
          path="/v2/settings/campaigns/brand/create"
          component={CampaignsSettingsBrandCreate}
        />
        <Route
          path="/v2/settings/campaigns/create/:brand_id"
          component={CampaignsSettingsCampaignCreate}
        />
        <Route
          path="/v2/settings/campaigns"
          component={CampaignsSettingsHome}
        />

        <Route path="/v2/settings/my_profile" component={UserProfileSettings} />
        <Route path="/v2/settings/my_devices" component={UserDevicesSettings} />
      </Switch>
    </ErrorBoundary>
  );
};

const AccountSettings = () => {
  const history = useHistory();

  const {
    data: account,
    isLoading: accountIsLoading,
    isFetching,
    error: accountError,
    refetch: refetchAccount,
    queryKey: accountQueryKey,
  } = useAccountQuery();

  const [{ openTour }] = useGlobalTour();
  // const { hints } = useHints([]);
  const [isHiddenSetupTour, hide, refetch, setAdminSetupTourHintValue] =
    useHint('admin.setup.all');

  const [
    isHiddenChecklist,
    hideChecklist,
    refetchChecklist,
    setChecklistValue,
  ] = useHint('admin.setup.checklist');

  const { devMode, toggleDevMode } = useDevMode();

  return (
    <SettingsPageWrapper
      isLoading={accountIsLoading}
      loadingLabel={'Loading account...'}
      label={<div onDoubleClick={toggleDevMode}>Company</div>}
      subLabel={'Account settings'}
    >
      {account ? (
        <SettingsCard account={account} onComplete={refetchAccount} />
      ) : (
        <span>error loading account</span>
      )}
      {devMode ? (
        <>
          <div className="mt-8">
            <div className="flex space-x-2">
              <Button
                variant={'outline'}
                size={'sm'}
                onClick={e => {
                  window.scrollTo(0, 0);
                  // history.push('/v2');
                  setAdminSetupTourHintValue(false);
                  // openTour('onboarding');
                  openTour('');
                  setTimeout(() => {
                    openTour('setup2');
                  }, 50);
                }}
              >
                Onboarding Tour
              </Button>
              <Button
                variant={'outline'}
                size={'sm'}
                onClick={e => {
                  setChecklistValue(false);
                }}
              >
                Reset Checklist
              </Button>
            </div>
            <br />
            <br />
            <ResetOnboarding />
          </div>
        </>
      ) : null}
    </SettingsPageWrapper>
  );

  /*
  return (
    <>
      <div
        className={`max-w-[600px] flex-auto border-solid border-0 border-r border-neutral-20`}
      >
        <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
          <SimpleBar className={'h-full'} style={{ height: '100%' }}>
            <div className={'px-8 py-3  h-full flex-grow'}>
              <div
                onDoubleClick={toggleDevMode}
                className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline"
              >
                <div className={'flex  items-center space-x-2'}>
                  <div
                    className={
                      'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
                    }
                  >
                    <SettingsIcon
                      className={'fill-content-accent '}
                      sx={{ fontSize: 'inherit' }}
                    />
                  </div>
                  <div>
                    <div className="font-bold text-xl">Account Settings</div>
                    <div className="text-base text-neutral-500">
                      Global settings for your account and resources
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 h-full">
                {account ? (
                  <div className="py-4 max-w-lg">
                    <SettingsCard
                      account={account}
                      onComplete={refetchAccount}
                    />
                  </div>
                ) : (
                  <div>Loading account details...</div>
                )}
              </div>
              {devMode ? (
                <>
                  <br />
                  <br />
                  <Button
                    variant={'outline'}
                    size={'sm'}
                    onClick={e => {
                      window.scrollTo(0, 0);
                      // history.push('/v2');
                      setAdminSetupTourHintValue(false);
                      // openTour('onboarding');
                      openTour('');
                      setTimeout(() => {
                        openTour('setup2');
                      }, 50);
                    }}
                  >
                    Onboarding Tour
                  </Button>
                  <br />
                  <br />
                  <ResetOnboarding />
                </>
              ) : null}
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  );
*/

  // return (
  //   <div className="overflow-hidden relative h-screen w-full">
  //     <CustomBackdrop {...CustomBackdropProps} label="Resetting Account..." />
  //     <SimpleBar style={{ height: '100%' }}>
  //       <div className="">
  //         <div className="text-2xl">Account Settings</div>
  //         {account ? (
  //           <div className="p-4 max-w-lg">
  //             <SettingsCard account={account} onComplete={refetchAccount} />
  //           </div>
  //         ) : (
  //           <div>Loading account details...</div>
  //         )}
  //         <br />
  //         <br />
  //         <button
  //           className="btn"
  //           onClick={e => {
  //             window.scrollTo(0, 0);
  //             // history.push('/v2');
  //             setAdminSetupTourHintValue(false);
  //             // openTour('onboarding');
  //             openTour('');
  //             setTimeout(() => {
  //               openTour('setup2');
  //             }, 50);
  //           }}
  //         >
  //           Onboarding Tour
  //         </button>
  //         <br />
  //         <br />
  //         <div className="flex space-x-2">
  //           <button className="btn" onClick={handleResetAndOnboarding}>
  //             Reset &amp; Onboarding
  //           </button>
  //           <button className="btn" onClick={handleResetAndSetup}>
  //             Reset &amp; auto-setup (no onboarding)
  //           </button>
  //         </div>
  //       </div>
  //     </SimpleBar>
  //   </div>
  // );
};

export const ResetOnboarding = () => {
  const { refetch: refetchNumbers, data } = useListPhoneNumbersQuery({
    skip: 0,
    take: 100,
    // orderBy: queryOrderBy, // order by
    // filters: { searchInput: querySearchInput }, // filters
    options: {
      enabled: true,
    },
  });
  const { phoneNumbers, totalCount = 0 } = data ?? {};

  const { data: usersResp, refetch: refetchUsers } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};
  // console.log('Users:', users)

  const { data: user } = useAuthenticatedUserQuery();

  const pipeNew = usePipeNew();

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  const {
    toggleOpen: toggleBackdropSync,
    Dialog: CustomBackdropSync,
    DialogProps: CustomBackdropPropsSync,
  } = useCustomBackdrop({ initialOpen: false });

  const handleSyncStripe = async () => {
    toggleBackdropSync();
    try {
      const resetResponse = await request(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
        gql`
          mutation stripeCustomerMutate($data: JSON) {
            stripeCustomerMutate(data: $data) {
              success
              message
              data
            }
          }
        `,
        {
          data: {
            action: 'syncSubscriptionQuantities',
            data: {},
          },
        },
        // @ts-ignore
        { authorization: store.getState().auth.auth_token },
      );
    } catch (err) {}
    toggleBackdropSync();
  };

  const doReset = async onboarding_complete => {
    const resetResponse = await request(
      `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
      gql`
        mutation resetAccountToTrial($data: JSON) {
          resetAccountToTrial(data: $data) {
            success
            message
            data
          }
        }
      `,
      { data: { onboarding_complete } },
      // @ts-ignore
      { authorization: store.getState().auth.auth_token },
    );
    console.log('resetResponse:', resetResponse);
    return resetResponse?.resetAccountToTrial?.data?.completed;
  };
  const doSetup = async () => {
    // do default setup,
    // - add addional pipes for more numbers
    // - also add feature codes so outgoing calling works!

    await setupPipes({
      auth_token: store.getState().auth.auth_token,
      pipeNew,
      // @ts-ignore
      ptn: phoneNumbers?.length ? phoneNumbers[0].id : null,
      phoneNumbers,
      user,
      users,
    });

    // feature codes and no_match (outgoing calls)
    await setupFeatureCodesAndNomatch({
      auth_token: store.getState().auth.auth_token,
    });
  };

  const handleResetAndOnboarding = async () => {
    toggleBackdrop();

    const result = await doReset(null);
    if (!result) {
      toggleBackdrop();
      alert('Sorry, reset failed, contact Nick');
      return null;
    }
    toggleBackdrop();
    // @ts-ignore
    window.location.href = '/onboarding';
  };

  const handleResetAndSetup = async () => {
    toggleBackdrop();

    const result = await doReset(true);
    if (!result) {
      toggleBackdrop();
      alert('Sorry, reset failed, contact Nick');
      return null;
    }

    await doSetup();

    toggleBackdrop();
    // window.location.reload();
  };
  return (
    <>
      <CustomBackdrop {...CustomBackdropProps} label="Resetting Account..." />
      <CustomBackdropSync
        {...CustomBackdropPropsSync}
        label="Syncing Account..."
      />

      <div className="flex flex-wrap space-x-2">
        <Button
          size={'sm'}
          color={'negative'}
          variant={'outline'}
          onClick={handleResetAndOnboarding}
        >
          Reset &amp; Onboarding
        </Button>
        <Button
          size={'sm'}
          color={'negative'}
          variant={'outline'}
          onClick={handleResetAndSetup}
        >
          Reset &amp; auto-setup (no onboarding)
        </Button>
        <Button
          size={'sm'}
          color={'accent'}
          variant={'outline'}
          onClick={handleSyncStripe}
        >
          Sync Stripe Quantities
        </Button>
      </div>
    </>
  );
};

const BillingSettings = () => {
  return <AdminBillingHome />;

  /*
  return (
    <div
      className={`max-w-[600px] flex-auto border-solid border-0 border-r border-neutral-20`}
    >
      <SimpleBar style={{ height: '100%' }}>
        <div className={'px-8 py-3  h-full flex-grow'}>
          <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
            <div className={'flex  items-center space-x-2'}>
              <div
                className={
                  'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
                }
              >
                <CreditCardIcon
                  className={'fill-content-accent '}
                  sx={{ fontSize: 'inherit' }}
                />
              </div>
              <div>
                <div className="font-bold text-xl">Billing</div>
                <div className="text-base text-neutral-500">
                  Manage payment methods, integrations and view invoices
                </div>
              </div>
            </div>
          </div>
          <div>
            <AdminBillingHome />
          </div>
        </div>
      </SimpleBar>
    </div>
  );
*/
};

const MediaSettings = () => {
  return (
    <div className="overflow-hidden relative h-screen w-full">
      <SimpleBar style={{ height: '100%' }}>
        <div className="text-2xl">
          <div className="text-2xl">Media Settings</div>
        </div>
      </SimpleBar>
    </div>
  );
};

const MyProfileSettings = () => {
  return (
    <div className="overflow-hidden relative h-screen w-full">
      <SimpleBar style={{ height: '100%' }}>
        <div className="text-2xl">
          <div className="text-2xl">My Profile Settings</div>
        </div>
      </SimpleBar>
    </div>
  );
};

const MyPhonesAndDevicesSettings = () => {
  return (
    <div className="overflow-hidden relative h-screen w-full">
      <SimpleBar style={{ height: '100%' }}>
        <div className="text-2xl">
          <div className="text-2xl">My Phones and Devices Settings</div>
        </div>
      </SimpleBar>
    </div>
  );
};

const useIsMobile = () => {
  return useMediaQuery('(max-width:680px)', { noSsr: true });
};

export default Settings;

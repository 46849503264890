import React, { ForwardedRef, useEffect, useRef, useState } from 'react';
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

export interface AnimationDialogProps extends DialogProps {
  contentOnly?: boolean;
}

export const useAnimateOpen = (open: boolean) => {
  const [actuallyOpen, setActuallyOpen] = useState(open);
  const [show, setShow] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (open) {
      // open dialog, delay transition start
      setActuallyOpen(true);
      timerRef.current = setTimeout(() => {
        setShow(true);
      }, 50);
      return;
    }

    // trigger close animation, delay actual close
    setShow(false);
    timerRef.current = setTimeout(() => {
      setActuallyOpen(false);
    }, 500);
  }, [open]);

  return { actuallyOpen, show };
};

const AnimationDialog = React.forwardRef(
  (
    {
      open,
      maxWidth = 'sm',
      fullWidth = true,
      contentOnly,
      ...props
    }: AnimationDialogProps,
    ref: ForwardedRef<HTMLDivElement | null>,
  ) => {
    const { actuallyOpen, show } = useAnimateOpen(open);

    // console.log('ANIMATION DIALOG MOUNTED');
    if (contentOnly) {
      if (!open) return null;
      return <>{props.children}</>;
    }

    // render nothing
    if (!actuallyOpen) return null;

    // console.log('ANIMATION DIALOG MOUNTED - RENDERED');

    return (
      <MuiDialog
        {...props}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        PaperProps={{
          className: `transition-all duration-500 ${
            show ? '' : 'opacity-0 translate-x-96'
          } ${props.PaperProps?.className}`,
          ...props.PaperProps,
        }}
        open={actuallyOpen}
        ref={ref}
      />
    );
  },
);

export default AnimationDialog;

import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

export const useDeleteMedia = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, string, unknown>(
    (mediaId: string) =>
      sdk.media.mutate.delete(mediaId, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(mediaQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

import { IconButton } from 'app/design-lib/components/IconButton';
import { Cancel as CloseIcon } from 'iconoir-react';
import React, { ReactNode } from 'react';
import { SquareProgress } from 'app/design-lib/components/SquareProgress';

interface DialogHeaderProps {
  label?: string;
  secondaryText?: string;
}

export const DialogLoading = ({ label, secondaryText }: DialogHeaderProps) => {
  return (
    <div
      className={
        'w-full flex font-medium space-y-2 flex-col text-md items-center text-center justify-center pb-2'
      }
    >
      <SquareProgress />
      {label ? <p>{label}</p> : null}
      {secondaryText ? <p>{secondaryText}</p> : null}
    </div>
  );
};

import { Button } from 'app/design';
import { GroupCreateDialog } from 'app/components/GroupCreateDialog';
import { useCreateGroup } from 'app/hooks/mutations/group/useCreateGroup';
import * as React from 'react';
import { toast } from 'react-toastify';
import { useToggleReducer } from 'app/utilities';

interface CreateGroupButtonProps {
  onComplete?: (groupId?: any) => void;
  navigateOnComplete?: boolean;
}

const CreateGroupButton = ({
  onComplete,
  navigateOnComplete,
}: CreateGroupButtonProps) => {
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  return (
    <>
      <GroupCreateDialog
        ContentProps={{
          onComplete: () => {
            toggleShowDialog();
            onComplete && onComplete();
          },
          navigateOnComplete: navigateOnComplete,
          onCancel: toggleShowDialog,
        }}
        DialogProps={{
          open: showDialog,
        }}
      />
      <button className="btn" onClick={toggleShowDialog}>
        Create Ring Group
      </button>
    </>
  );
};

export default CreateGroupButton;

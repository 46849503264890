import * as Sentry from '@sentry/react';
import { CustomBackdrop } from 'app/components/CustomBackdrop';
import { Onboarding } from 'app/components/Onboarding';
import constants from 'app/constants';
import { actions } from 'app/data/local';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AdminLiveCalls } from 'app/pages/admin/LiveCalls';
import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { store } from 'store';
import { useMediaQuery } from 'app/design';
import { useCombinedContacts } from 'app/components/Webphone/UI/ContactsView/components/CombinedContacts/CombinedContacts';

import { Layout } from './components/Layout';
import { LayoutV2 } from './components/LayoutV2';
import { NotFoundPage } from './components/NotFoundPage/Loadable';

import { useAuthSelector } from './data/auth';

const setSidebarKey = (key: string) =>
  store.dispatch(actions.set_sidebar_key(key));

// V2

// Pipe
// - has additional sub-routing for individual Pipe Conversations
const V2_Pipe = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/v2/pipe/Pipe');
});

const V2_Room = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/v2/room/Room');
});

// Contacts
// - has additional sub-routing for individual Contacts
const V2_Contacts = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/v2/contacts/Contacts');
});

// Settings
// - has additional sub-routing for individual Settings
const V2_Settings = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/v2/settings/Settings/Settings');
});

// // V1

// // DASHBOARD / ACCOUNT Routes
// const AdminDashboard = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
//   return import('app/pages/admin/Dashboard/Dashboard');
// });

// const AdminAccountCalling = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import('app/pages/admin/AccountCalling3/AccountCalling');
// });

// const AdminOperatorExtension = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import('app/pages/admin/OperatorExtension/OperatorExtension');
// });

// const EditOperatorExtension = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import('app/pages/admin/EditOperatorExtension/EditOperatorExtension');
// });

// const EditCallHandlingAccount = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import(
//     'app/pages/admin/Dashboard/EditCallHandlingAccount/EditCallHandlingAccount'
//   );
// });

// const EditCallHandlingGeneral = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ALL_CALLFLOWS_LIST.sidebar_key);
//   return import(
//     'app/pages/admin/Dashboard/EditCallHandlingGeneral/EditCallHandlingGeneral'
//   );
// });

// // DEVICES / PHONES Routes
// const AdminPhonesCreate = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICES_LIST.sidebar_key);
//   return import('app/pages/admin/Phones/Create/Create');
// });

// const AdminPhonesBuy = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICES_LIST.sidebar_key);
//   return import('app/pages/admin/Phones/Buy/Buy');
// });

// const AdminDevicesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICES_LIST.sidebar_key);
//   return import('app/pages/admin/Devices/List/List');
// });

// const EditCallHandlingDevice = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICE_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Devices/View/EditCallHandlingDevice/EditCallHandlingDevice'
//   );
// });

// const AdminDeviceView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICE_VIEW.sidebar_key);
//   return import('./pages/admin/Devices/View/View');
// });

// // USER Routes
// const AdminUsersCreate = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USERS_LIST.sidebar_key);
//   return import('app/pages/admin/Users/Create/Create');
// });

// const AdminUsersList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USERS_LIST.sidebar_key);
//   return import('app/pages/admin/Users/List/List');
// });

// const AdminUserView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USER_VIEW.sidebar_key);
//   return import('app/pages/admin/Users/View/View');
// });

// const EditCallHandlingUser = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USER_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Users/View/EditCallHandlingUser/EditCallHandlingUser'
//   );
// });

// LOGIN / LOGOUT Routes

const Login = lazy(() => import('app/pages/auth/Login/Login'));
const Logout = lazy(() => import('app/pages/auth/Logout/Logout'));
const ForgotPassword = lazy(
  () => import('app/pages/auth/ForgotPassword/ForgotPassword'),
);

// // VMBOX Routes
// const AdminVmboxesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_VMBOXES_LIST.sidebar_key);
//   return import('app/pages/admin/Vmboxes/List/List');
// });

// const AdminVmboxView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_VMBOX_VIEW.sidebar_key);
//   return import('app/pages/admin/Vmboxes/View/View');
// });

// const EditCallHandlingVmbox = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_VMBOX_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Vmboxes/View/EditCallHandlingVmbox/EditCallHandlingVmbox'
//   );
// });

// // CONFERENCE Routes
// const AdminConferencesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CONFERENCES_LIST.sidebar_key);
//   return import('app/pages/admin/Conferences/List/List');
// });

// const AdminConferenceView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CONFERENCE_VIEW.sidebar_key);
//   return import('app/pages/admin/Conferences/View/View');
// });

// // DIRECTORY Routes
// const AdminDirectoryView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DIRECTORY_VIEW.sidebar_key);
//   return import('app/pages/admin/Directories/View/View');
// });

// const AdminDirectoriesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DIRECTORIES_LIST.sidebar_key);
//   return import('app/pages/admin/Directories/List/List');
// });

// // EXTENSION Routes
// const AdminExtensionsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_EXTENSIONS_LIST.sidebar_key);
//   return import('app/pages/admin/Extensions/List/List');
// });

// // GROUP Routes
// const AdminGroupView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_GROUP_VIEW.sidebar_key);
//   return import('app/pages/admin/Groups/View/View');
// });

// const EditCallHandlingGroup = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_GROUP_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Groups/View/EditCallHandlingGroup/EditCallHandlingGroup'
//   );
// });

// const AdminGroupsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_GROUPS_LIST.sidebar_key);
//   return import('app/pages/admin/Groups/List/List');
// });

// // IVR Routes
// // const AdminIvrExtensionsList = lazy(
// //   () => import('app/pages/admin/IvrExtensions/List/List'),
// // );
// // const AdminIvrExtensionView = lazy(
// //   () => import('app/pages/admin/IvrExtensions/View/View'),
// // );
// // const AdminIvrEdit = lazy(() => import('app/pages/admin/Ivr/Edit/Edit'));

// // SCREENPOP Routes
// const AdminScreenpopEdit = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_SCREENPOP_VIEW.sidebar_key);
//   return import('app/pages/admin/Screenpops/Edit/Edit');
// });
// const AdminScreenpopsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_SCREENPOPS_LIST.sidebar_key);
//   return import('app/pages/admin/Screenpops/List/List');
// });

// // TEMPLATE Routes
// const AdminTemplateEdit = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_TEMPLATE_EDIT.sidebar_key);
//   return import('app/pages/admin/Templates/Edit/Edit');
// });

// const AdminTemplatesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_TEMPLATES_LIST.sidebar_key);
//   return import('app/pages/admin/Templates/List/List');
// });

// // BILLING Routes
// const AdminBillingHome = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_BILLING.sidebar_key);
//   return import('app/pages/admin/Billing/Home/Home');
// });
// const AdminBillingSummary = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_BILLING.sidebar_key);
//   return import('app/pages/admin/Billing/Summary/Summary');
// });
// const AdminBillingIntegrations = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USER_CALL_HANDLING.sidebar_key);
//   return import('app/pages/admin/Billing/Integrations/Integrations');
// });

// // CONTACTS Route
// const AdminContactsHome = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CONTACTS.sidebar_key);
//   return import('app/pages/admin/Contacts/Home/Home');
// });

// // MESSAGING Route
// const AdminMessagingHome = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_MESSAGING.sidebar_key);
//   return import('app/pages/admin/Messaging/Home/Home');
// });

// // OFFICE Routes
// const OfficeVoicemailsView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_VOICEMAILS.sidebar_key);
//   return import('app/pages/office/Voicemails/View');
// });

// const OfficeCallingView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_CALLING.sidebar_key);
//   return import('app/pages/office/Calling/View');
// });

// const OfficeCallHistoryView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_CALL_HISTORY.sidebar_key);
//   return import('app/pages/office/CallHistory/View');
// });

// const OfficeDashboardView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_DASHBOARD.sidebar_key);
//   return import('app/pages/office/Dashboard/View');
// });

// // CALL ROUTE Routes
// const AdminAllCallflowsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ALL_CALLFLOWS_LIST.sidebar_key);
//   return import('app/pages/admin/AllCallflows/List/List');
// });

// // REPORTS Routes
// const AdminCallFinder = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CALL_FINDER.sidebar_key);
//   return import('app/pages/admin/CallLogsAndReports/CallFinder/CallFinder');
// });

// const AdminCallLogsAndReports = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CALL_LOGS.sidebar_key);
//   return import('app/pages/admin/CallLogsAndReports/CallLogsAndReports');
// });

// // MENU Routes
// const AdminMenusList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_MENUS_LIST.sidebar_key);
//   return import('app/pages/admin/Menus/List/List');
// });

// // NUMBERS Routes
// const AdminPhoneNumbersList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_NUMBERS_LIST.sidebar_key);
//   return import('app/pages/admin/PhoneNumbers/List/List');
// });

// // MEDIA Routes
// const AdminMediaList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_MEDIAS_LIST.sidebar_key);
//   return import('./pages/admin/Media/List/List');
// });

// // SCHEDULE Routes
// const AdminSchedulesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_SCHEDULES_LIST.sidebar_key);
//   return import('./pages/admin/Schedules/List/List');
// });

// DEV Routes
const Dev = lazy(() => import('app/pages/Dev/Dev'));

function Routing() {
  const { show_login_dialog, loggedIn, owner_id, account_id } =
    useAuthSelector();

  const { data: account } = useAccountQuery();
  const { isLoading, data: user } = useAuthenticatedUserQuery();

  useEffect(() => {
    if (loggedIn) {
      Sentry.setUser({
        ip_address: '{{auto}}',
        // @ts-ignore
        id: `${account_id}-${owner_id}`,
        owner_id,
        account_id,
        email: user?.doc?.email,
        account: account?.doc?.name,
      });
      Sentry.setTag('account_id', account_id);
      Sentry.setTag('owner_id', owner_id);
    }
  }, [account, user, loggedIn, owner_id, account_id]);

  return (
    <>
      {account?.internal?.is_canceled ? (
        <CustomBackdrop
          label={
            'Your account has been deactivated. Contact us at support@calling.io'
          }
          hideLoader
        />
      ) : null}
      {loggedIn && !account?.internal?.is_canceled ? (
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (isLoading) {
                console.log('Loading user information before redirecting');
                return null;
              }
              return <Redirect to="/v2" />;
              // return user?.doc?.priv_level === 'admin' ? (
              //   <Redirect to="/admin" />
              // ) : (
              //   <Redirect to="/office" />
              // );
            }}
          />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/office" component={OfficeRouting} />
          <Route path="/admin" component={AdminRouting} />
          <Route path="/v2" component={V2Routing} />
          <Route path="/dev" component={Dev} />
          <Route path="/logout" component={Logout} />
          {/* Redirecting login below (might not be necessary...) */}
          <Route exact path="/login" render={() => <Redirect to="/" />} />
          <Route component={NotFoundPage} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/logout" component={Logout} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      )}
    </>
  );
}

const V2Routing = () => {
  useCombinedContacts();

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  return (
    <LayoutV2>
      <Switch>
        <Route
          exact
          path="/v2"
          render={() => {
            if (isMobile) {
              return <Redirect to="/v2/settings" />;
            }
            return <Redirect to="/v2/pipe" />;
          }}
        />
        <Route path="/v2/settings" component={V2_Settings} />
        <Route path="/v2/contacts" component={V2_Contacts} />
        <Route path="/v2/pipe/:pipe_id" component={V2_Pipe} />
        <Route path="/v2/room/:pipe_id" component={V2_Room} />
        <Route path="/v2/pipe" component={V2_Pipe} />
        {/* <Route path="/v2/voicemails" component={OfficeVoicemailsView} />
        <Route path="/v2/call_history" component={OfficeCallHistoryView} />
        <Route path="/v2/calling" component={OfficeCallingView} /> */}
        <Route component={NotFoundPage} />
      </Switch>
    </LayoutV2>
  );
};

const OfficeRouting = () => {
  return (
    <Layout>
      <Switch>
        <Route render={() => <Redirect to="/v2" />} />
        {/* <Route exact path="/office" component={OfficeDashboardView} />
        <Route path="/office/dashboard" component={OfficeDashboardView} />
        <Route path="/office/voicemails" component={OfficeVoicemailsView} />
        <Route path="/office/call_history" component={OfficeCallHistoryView} />
        <Route path="/office/calling" component={OfficeCallingView} />
        <Route component={NotFoundPage} /> */}
      </Switch>
    </Layout>
  );
};

const AdminRouting = () => {
  // if not admin, disallow
  // - wait for USER info to exist, check if priv_role = 'admin' ?

  //   if (!userIsAdmin) {
  //     return <>Not authorized to view</>;
  //   }

  return (
    <Layout>
      <Switch>
        <Route render={() => <Redirect to="/v2" />} />
        {/* <Route exact path="/admin" component={AdminDashboard} />
        <Route
          exact
          path="/admin/call_routes"
          component={AdminAllCallflowsList}
        />
        <Route exact path="/admin/dashboard" component={AdminDashboard} />
        <Route
          exact
          path="/admin/operator_ext"
          component={AdminOperatorExtension}
        />
        <Route
          exact
          path="/admin/operator_ext/call_routing"
          component={EditOperatorExtension}
        />
        <Route exact path="/admin/calling2" component={AdminAccountCalling} />
        <Route exact path="/admin/calling" component={AdminAccountCalling} />
        // <Route exact path="/admin/calling" component={AdminAccountCalling2} />
        <Route exact path="/admin/live" component={AdminLiveCalls} />
        <Route
          exact
          path="/admin/dashboard/call_routing"
          component={EditCallHandlingAccount}
        />
        <Route
          exact
          path="/admin/general/call_routing/:callflow_id"
          component={EditCallHandlingGeneral}
        />
        <Route
          exact
          path="/admin/calllogs"
          component={AdminCallLogsAndReports}
        />
        <Route
          exact
          path="/admin/calllogs/callfinder"
          component={AdminCallFinder}
        />
        <Route exact path="/admin/users/create" component={AdminUsersCreate} />
        <Route exact path="/admin/users" component={AdminUsersList} />
        <Route
          path="/admin/users/view/:user_id/call_routing"
          component={EditCallHandlingUser}
        />
        <Route path="/admin/users/view/:user_id" component={AdminUserView} />
        <Route
          exact
          path="/admin/phones/create"
          component={AdminPhonesCreate}
        />
        <Route exact path="/admin/phones/buy" component={AdminPhonesBuy} />
        <Route exact path="/admin/devices" component={AdminDevicesList} />
        <Route
          path="/admin/devices/view/:device_id/call_routing"
          component={EditCallHandlingDevice}
        />
        <Route
          path="/admin/devices/view/:device_id"
          component={AdminDeviceView}
        />
        <Route
          exact
          path="/admin/conferences"
          component={AdminConferencesList}
        />
        <Route
          path="/admin/conferences/view/:conference_id"
          component={AdminConferenceView}
        />
        <Route
          exact
          path="/admin/directories"
          component={AdminDirectoriesList}
        />
        <Route
          path="/admin/directories/view/:directory_id"
          component={AdminDirectoryView}
        />
        <Route exact path="/admin/extensions" component={AdminExtensionsList} />
        <Route exact path="/admin/ring_groups" component={AdminGroupsList} />
        <Route
          path="/admin/ring_groups/view/:group_id/call_routing"
          component={EditCallHandlingGroup}
        />
        <Route
          exact
          path="/admin/ring_groups/view/:group_id"
          component={AdminGroupView}
        />
        // <Route
        //   exact
        //   path="/admin/ivr_extensions"
        //   component={AdminIvrExtensionsList}
        // />
        // <Route
        //   path="/admin/ivr_extensions/view/:ivrExtension_id"
        //   component={AdminIvrExtensionView}
        // />
        // <Route path="/admin/ivr/edit/:id" component={AdminIvrEdit} />
        <Route exact path="/admin/media" component={AdminMediaList} />
        <Route exact path="/admin/menus" component={AdminMenusList} />
        <Route exact path="/admin/numbers" component={AdminPhoneNumbersList} />
        <Route exact path="/admin/schedules" component={AdminSchedulesList} />
        <Route exact path="/admin/screenpops" component={AdminScreenpopsList} />
        <Route
          path="/admin/screenpop/edit/:id"
          component={AdminScreenpopEdit}
        />
        <Route
          exact
          path="/admin/callflow_templates"
          component={AdminTemplatesList}
        />
        <Route path="/admin/template/edit/:id" component={AdminTemplateEdit} />
        <Route exact path="/admin/vmboxes" component={AdminVmboxesList} />
        <Route
          path="/admin/vmboxes/view/:vmbox_id/call_routing"
          component={EditCallHandlingVmbox}
        />
        <Route
          path="/admin/vmboxes/view/:vmbox_id"
          component={AdminVmboxView}
        />
        <Route path="/admin/contacts" component={AdminContactsHome} />
        <Route path="/admin/messaging" component={AdminMessagingHome} />
        <Route
          path="/admin/billing/integrations"
          component={AdminBillingIntegrations}
        />
        <Route path="/admin/billing/summary" component={AdminBillingSummary} />
        <Route path="/admin/billing" component={AdminBillingHome} />
        // <Route path="/admin/billing" component={AdminVmboxView} />
        // <Route exact path="/users" component={AdminUsersList} />
        <Route component={NotFoundPage} />*/}
      </Switch>
    </Layout>
  );
};

export default Routing;

import { IconButton } from 'app/design-lib/components/IconButton';
import { Cancel as CloseIcon } from 'iconoir-react';
import React, { ReactNode } from 'react';

interface DialogHeaderProps {
  children: ReactNode;
  className?: string;
}

export const DialogActions = ({
  children,
  className = '',
}: DialogHeaderProps) => {
  return (
    <div
      className={`${className} border-t px-4 py-1 flex items-center justify-end border-neutral-20`}
    >
      {children}
    </div>
  );
};

import { getAtPath } from 'app/utilities';
import { cloneDeep, toInteger } from 'lodash';
import { GET_A_VAR_TYPES, SET_A_VAR_TYPES } from '../common/VariablesEditor';

interface buildOutputCallflowFromTemplateProps {
  rootCallflow: any; // for "owner_type" and other root-level values
  // rootCallflow: any; // path in rootCallflow to the inline callflow where simple variables are stored
  templateCallflow: any; // the callflow that is either the root, OR the inline
  checkSimple?: boolean;
}

export const buildOutputCallflowFromTemplate = async ({
  rootCallflow,
  templateCallflow,
  checkSimple,
}: buildOutputCallflowFromTemplateProps) => {
  // builds only the Template.strategy!!! (not the Template.flow)

  let outputCallflow = cloneDeep(templateCallflow);

  const setOutputCallflow = callflow => {
    outputCallflow = callflow;
  };

  const rootData = await convertCallflow({
    rootCallflow, // for variables!
    outputCallflow, // for editing AND variables (TODO: variables also included from parent templates???)
    setCallflow: setOutputCallflow,
    modifyPath: '', // root of OUTPUTCALLFLOW
    rootData: { callflowIds: [] },
    checkSimple,
    // templateCallflow,
    // optionalNodesEdges...
  });

  return outputCallflow;
};

const convertCallflow = async ({
  rootCallflow,
  outputCallflow,
  setCallflow,
  modifyPath,
  rootData,
  checkSimple,
}) => {
  let currentCallflow = getAtPath(outputCallflow, modifyPath);
  // console.log('currentCallflow:', {
  //   currentCallflow,
  //   modifyPath,
  //   rootCallflow,
  //   outputCallflow,
  // });
  if (!currentCallflow) {
    return rootData;
  }

  if (currentCallflow.strategy.type !== 'blank') {
    console.error(
      'invalid callflow type for convertCallflow for buildOutputCallflowFromTemplate',
    );
    return rootData;
  }

  let canContinue = true,
    currentIdx = -1;
  for (let infoIdx in currentCallflow.strategy.data.modules) {
    // @ts-ignore
    infoIdx = toInteger(infoIdx);
    // @ts-ignore
    currentIdx = infoIdx;
    if (!canContinue) {
      continue;
    }
    const moduleItem = currentCallflow.strategy.data.modules[infoIdx];

    switch (moduleItem.type) {
      case 'Menu':
        console.log('Applying variables to Menu');
        await applyVariables({
          rootCallflow,
          outputCallflow,
          moduleItem,
          checkSimple,
        });
        let targets = moduleItem.data.targets ?? {};
        for (let key of Object.keys(targets)) {
          const target = targets[key];
          console.log('Checking menu target', key);
          rootData = await convertCallflow({
            rootCallflow, //: target?.callflow,
            outputCallflow,
            setCallflow,
            modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.targets.{${key}}.callflow`,
            rootData,
            checkSimple,
          });
        }
        break;

      case 'Schedule':
        console.log('Applying variables to Schedule');
        await applyVariables({
          rootCallflow,
          outputCallflow,
          moduleItem,
          checkSimple,
        });

        // const { list: callflows1 } = state.lists.callflows;
        // const scheduleCallflow = callflows1.find(
        //   cf => cf.id === moduleItem?.time?.callflow?.id,
        // );

        // // TODO: use a copied/inline callflow! (similar to how Templates uses outputCallflow)!
        // let times = scheduleCallflow?.doc?.strategy?.data?.times ?? []; //moduleItem.data.times ?? {};
        // times.map((timeDetails, i) => {
        //   rootData = await convertCallflow({
        //     rootCallflow,
        //     outputCallflow,
        //     setCallflow,
        //     modifyPath: `${modifyPath}.strategy.data.opts[${infoIdx}].times.${timeDetails.id}.callflow`,
        //     rootData,
        //   });
        // });

        break;
      case 'ContinueToCallflow':
        // link to viewing that callflow (NOT showing here!)
        // - this is an EXTERNAL callflow! NOT an inline one!
        window.alert('fix ContinueToCallflow2');
        break;
      default:
        console.log(`Applying variables to ${moduleItem.type}`);
        await applyVariables({
          rootCallflow,
          outputCallflow,
          moduleItem,
          checkSimple,
        });
        break;
    }
  }
  // iterate through Strategy, replacing variables as we go
  // - modifying by reference

  return rootData;
};

const applyVariables = async ({
  rootCallflow,
  outputCallflow,
  moduleItem,
  checkSimple,
}) => {
  console.log('applyVariables:', moduleItem.data.variables);
  const vars = moduleItem.data.variables;
  if (vars?.enabled) {
    for (let i in Object.keys(vars.items)) {
      const id_of_var = Object.keys(vars.items)[i];
      const varItem = vars.items[id_of_var];
      // console.log('varItem:', varItem);

      // Get/Read variable value
      let varValue;
      try {
        if (checkSimple) {
          // debugger;
          // "simple" enabled for this varItem? (could just be a default variable value)
          if (!varItem.simple) {
            // skip if simple not enabled
            // - not needing to re-apply/calculate/replace variables at all (would have been done in previous build)
            continue;
          }

          // are we checking for simple?
          // - ALREADY have applied/built using "rootCallflow" variables
          //   - now we want to see if a "simple"/custom variable was set
          //   - either way we do NOT need to re-run the variable builder
          if (
            // callflow.strategy?.type === 'template' &&
            rootCallflow.strategy?.simple?.variables?.hasOwnProperty(
              varItem.key,
            )
          ) {
            varValue = rootCallflow.strategy?.simple?.variables[varItem.key];
          } else {
            // skipping setting AT ALL cuz the simple item doesnt seem to be in use at all for this
            continue;
          }
        } else {
          const varReadType = GET_A_VAR_TYPES.find(
            at =>
              at.types.includes('*') || at.types.includes(varItem.read.type),
          );

          if (!varReadType) {
            console.error('Missing varReadType for id:', id_of_var, vars.items);
            return;
          }

          // @ts-ignore
          varValue = await varReadType.build({
            callflow: rootCallflow,
            templateCallflow: outputCallflow,
            varId: id_of_var,
            varItem, // {id, data}
            // setCallflow,
            // modifyPath,
            // rootData,

            // callflow,
            // templateCallflow,
            // varId,
            // varType,
            // componentData,
            // path,
          });
        }
      } catch (err) {
        // TODO: tell the user about this error (that the Template failed applying)
        // - not sure if we should actually fail here, or just alert the user that the output isnt what they are expecting...
        console.error('Var applying error:', err);
      }

      console.log(
        'Found variable value for replacement:',
        varValue,
        'Writing:',
        varItem.write.type,
        'varITEM:',
        varItem,
      );

      // Set/Write variable value
      const varWriteType = SET_A_VAR_TYPES[varItem.write.type];
      if (!varWriteType) {
        console.error('Missing varWriteType for id:', id_of_var, vars.items);
        return;
      }

      // modifies by reference!
      await varWriteType({
        rootCallflow,
        varValue,
        moduleItem,
        writeTypeData: varItem.write.data,
        checkSimple,
      });

      // update the value in varItem too
      if (checkSimple) {
        varItem.output_value_simple = varValue ?? null;
      } else {
        varItem.output_value_default = varValue ?? null;
        console.log('output_value_defaultoutput_value_default:', varItem);
      }
      varItem.output_value = varValue ?? null;
    }
  } else {
    console.log('No vars to apply');
  }
};

export default buildOutputCallflowFromTemplate;

import { joiResolver } from '@hookform/resolvers/joi';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, AlertProps } from 'app/design-lib';
import { useHint, useToggleReducer } from 'app/utilities';
import React from 'react';

// TODO: AlertProps merged with hintKey

export const AlertWithHint = ({ hintKey, ...props }) => {
  const [isHidden, hide, refetchHint, setValue] = useHint(hintKey);

  if (isHidden) {
    return null;
  }

  // @ts-ignore
  return <Alert {...props} onClose={hide} />;
};

export default AlertWithHint;

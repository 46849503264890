import {
  Button,
  DialogActions,
  Dialog,
  DialogLoading,
  DialogHeader,
  DialogContent,
} from 'app/design-lib';
import useDialogBuilder from 'app/utilities/useDialog';

import React, { useEffect, useState } from 'react';

interface DefaultDialogActionsProps {
  onSave?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  saveLabel?: string;
  cancelLabel?: string;
  deleteLabel?: string;
  errorMessage?: string;
  saveDisabled?: boolean;
  skipConfirm?: boolean;
}

const DefaultDialogActions = ({
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  deleteLabel = 'Delete',
  saveDisabled = false,
  onSave,
  onCancel,
  onDelete,
  errorMessage,
  skipConfirm = false,
}: DefaultDialogActionsProps) => {
  const [showConfirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  const handleConfirm = () => {
    setConfirmDeleteDialog(false);
    onDelete && onDelete();
  };
  return (
    <>
      <ConfirmationDialog
        open={showConfirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
        onCancel={() => setConfirmDeleteDialog(false)}
        onConfirm={handleConfirm}
      />
      <DialogActions>
        <div className={'flex w-full space-x-1 items-center justify-center'}>
          {onCancel && (
            <div>
              <Button
                size={'md'}
                onClick={onCancel}
                color="neutral"
                variant="ghost"
              >
                {cancelLabel}
              </Button>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <span color={'error'}>{errorMessage}</span>
            </div>
          </div>
          {onDelete && (
            <div>
              <Button
                onClick={
                  skipConfirm ? onDelete : () => setConfirmDeleteDialog(true)
                }
                color="negative"
                size={'md'}
                variant="fill"
              >
                {deleteLabel}
              </Button>
            </div>
          )}
          {onSave && (
            <div>
              <Button
                onClick={onSave}
                disabled={saveDisabled}
                variant="fill"
                size={'md'}
                color="accent"
              >
                {saveLabel}
              </Button>
            </div>
          )}
        </div>
      </DialogActions>
    </>
  );
};

const ConfirmationDialogContent = ({
  onConfirm,
  onCancel,
  onClose,
  label = '',
  title = '',
  isSuccess = false,
  isLoading = false,
  successLabel = 'Item deleted!',
  loadingLabel = '',
}: Omit<ConfirmationDialogProps, 'open'>) => {
  return (
    <>
      {isLoading ? (
        <DialogLoading label={loadingLabel ?? 'Loading...'} />
      ) : (
        <>
          {isSuccess ? null : (
            <DialogHeader title={'Delete contact'} onClose={onCancel!} />
          )}
          <DialogContent>
            <div
              className={`p-8`}
              style={{ display: 'grid', placeItems: 'center' }}
            >
              <span className={'font-medium text-md'}>
                {isSuccess
                  ? successLabel
                  : label ??
                    `Are you sure you would like to delete this ${'item'}?`}
              </span>
            </div>
          </DialogContent>

          <DefaultDialogActions
            onSave={onConfirm}
            saveLabel={'Confirm'}
            onCancel={onCancel}
          />
        </>
      )}
    </>
  );
};

interface ConfirmationDialogProps {
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
  label?: string;
  title?: string;
  isLoading?: boolean;
  loadingLabel?: string;
  isSuccess?: boolean;
  successLabel?: string;
  open: boolean;
}

export const ConfirmationDialog = ({
  open,
  ...props
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={props.onCancel!}>
      <ConfirmationDialogContent {...props} />
    </Dialog>
  );

  // return (
  //   <Dialog open={open} fullWidth maxWidth={'sm'}>
  //     {isLoading ? (
  //       <DialogInProgress title={loadingLabel ?? 'Loading...'} />
  //     ) : (
  //       <>
  //         <DialogTitle>{title ?? `Confirm`}</DialogTitle>
  //
  //         <DialogContent dividers>
  //           <div style={{ display: 'grid', placeItems: 'center' }}>
  //             <Typography variant="h6">
  //               {label ??
  //                 `Are you sure you would like to delete this ${'item'}?`}
  //             </Typography>
  //           </div>
  //         </DialogContent>
  //
  //         <DefaultDialogActions
  //           onSave={onConfirm}
  //           saveLabel={'Confirm'}
  //           onCancel={onCancel}
  //         />
  //       </>
  //     )}
  //   </Dialog>
  // );
};

export const useConfirmationDialog = (initialOpen = false) => {
  const useDialog = useDialogBuilder(ConfirmationDialog);
  return useDialog({ initialOpen });
};

export default DefaultDialogActions;

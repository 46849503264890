import { Button, SquareProgress, TextField } from 'app/design-lib';
import {
  useCreateMedia,
  useUpdateMediaFull,
  useUploadMedia,
} from 'app/hooks/mutations/media';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MediaType } from 'types/media';
import { MediaSubType } from 'app/components/AudioEditor/AudioEditor';
import { AudioPlayer } from 'app/components/AudioPlayer';

interface UploadViewProps {
  onComplete: (mediaId: string | null) => Promise<void>;
  onCancel: () => void;
  subType: MediaSubType;
  mediaId?: string;
  isGreeting?: boolean;
  mediaName?: string;
  setIsMutating: React.Dispatch<React.SetStateAction<boolean>>;
  loadingLabel?: string;
}

const UploadView = ({
  onComplete,
  mediaId,
  onCancel,
  subType,
  isGreeting,
  mediaName = '',
  setIsMutating,
  loadingLabel,
}: UploadViewProps) => {
  const [filename, setFilename] = useState();
  const [name, setName] = useState(mediaName);
  const [error, setError] = useState('');
  const [file, setFile] = useState<any>();
  const uploadMedia = useUploadMedia();
  const updateMediaFull = useUpdateMediaFull();
  const [isUploading, setIsUploading] = useState(false);
  const [externalMutating, setExternalMutating] = useState(false);
  const [uploadError, setUploadError] = useState<any>(null);
  const createMedia = useCreateMedia();
  const [fileUrl, setFileUrl] = useState<any>();

  useEffect(() => {
    setIsMutating(isUploading || externalMutating);
  }, [isUploading, externalMutating]);

  const onUpload = async () => {
    setUploadError(null);
    setIsUploading(true);
    setExternalMutating(true);

    let newMedia: any;

    try {
      let mutation = isGreeting && mediaId ? updateMediaFull : createMedia;
      const resp = await mutation.mutateAsync({
        id: mediaId!,
        name,
        media_source: MediaType.Upload,
      });

      if (resp.status !== 'success') {
        throw new Error(
          'Error creating media file. Please try again later or contact support.',
        );
      }

      newMedia = resp.data;

      await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = async function (evt) {
          const file = evt.target?.result;

          const mediaToUpload = {
            id: newMedia.id,
            encodedFile: file as string,
          };

          const resp = await uploadMedia.mutateAsync(mediaToUpload);

          if (resp.status === 'success') {
            newMedia.updated_at = Date.now();
            newMedia.sub_type = subType;
            await updateMediaFull.mutateAsync(newMedia);
            resolve(reader.result);
          }
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);

      console.error(e);
      setUploadError(
        'Error uploading media. Please try again later or contact support.',
      );
      return;
    }

    try {
      await onComplete(newMedia.id);
    } catch (e) {
      console.error(e);
      setUploadError(
        'Media successfully uploaded but an error occurred when setting the media. Please try again from the library view.',
      );
    }
    setExternalMutating(false);
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setError('');

    // check if valid files
    fileRejections.forEach(file => {
      file.errors.forEach(err => {
        if (err.code === 'file-too-large') {
          setError(`Error: File too large - Cannot exceed 800kb`);
        } else {
          setError('Error: Invalid file type');
        }
      });
    });

    const file = acceptedFiles?.[0];
    setFile(file);
    setFileUrl(URL.createObjectURL(file));
    setFilename(file?.name ?? 'Unknown filename');
    setName((mediaName || file?.name) ?? 'Unknown filename');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: ['audio/wav', 'audio/mp3', 'audio/mp4', 'audio/mpeg'],
    maxSize: 790000, // bytes?
    // TODO max size for upload
  });

  return (
    <div className={'flex relative'}>
      {isUploading || externalMutating ? (
        <div className={'w-full h-full grid place-items-center'}>
          <div className={'flex flex-col justify-center items-center'}>
            <SquareProgress />
            <span className={'text-md font-medium'}>
              {isUploading ? 'Uploading media...' : loadingLabel}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={`${
            isUploading ? 'pointer-events-none opacity-50' : ''
          } flex flex-col space-y-2 w-full`}
        >
          <div
            {...getRootProps()}
            className={`w-full h-20 p-2 text-center cursor-pointer text-md font-medium text-neutral-60 rounded-lg items-center justify-center flex flex-col border-neutral-40 border-[1px] ${
              file ? 'text-accent-60' : 'border-dashed'
            } ${error ? 'border-negative-60' : ''}`}
          >
            {isDragActive ? (
              <p>Drop file here...</p>
            ) : filename ? (
              <>
                <p>Selected file:</p>
                <p className={'text-neutral-90'}>{filename}</p>
              </>
            ) : (
              <p>
                Drag and drop or click to browse media files (wav, mp3, mp4)
              </p>
            )}
            <input {...getInputProps()} />
            {error ? <span className={'text-negative-60'}>{error}</span> : null}
          </div>
          {fileUrl ? (
            <div className={'flex-1'}>
              <div className={'text-md ml-1.5 font-medium'}>Preview</div>
              <AudioPlayer mediaId={fileUrl} />
            </div>
          ) : null}
          {!isGreeting && file && !error ? (
            <TextField
              label={'Media name'}
              helperText="This will be available in your Library"
              value={name}
              className={'w-1/2'}
              placeholder={'File name...'}
              onChange={e => setName(e.target.value)}
            />
          ) : null}
          {file ? (
            <div className={`flex mt-2 space-x-2 items-center`}>
              <Button
                size={'md'}
                color={`accent`}
                variant={`fill`}
                onClick={onUpload}
                disabled={!!error || isUploading}
              >
                Upload & apply
              </Button>
              <Button
                size={'md'}
                color={`neutral`}
                variant={`ghost`}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          ) : null}
          {uploadError ? (
            <span className={`text-negative-60 font-medium text-md`}>
              {uploadError}
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default UploadView;

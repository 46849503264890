import { AnimationDialog, Dialog, Divider, Grid, Typography } from 'app/design';
import { DialogHeader, DialogLoading, DialogTitle } from 'app/design-lib';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import * as React from 'react';
import { ReactNode } from 'react';

interface QuickFinderWrapperProps {
  title: string;
  children: ReactNode;
  loading: boolean;
  loadingLabel: string;
  selectionTitle?: string | null;
  titleButton?: ReactNode;
  showComplete?: boolean;
  onCancel: () => void;
  contentOnly?: boolean;
}

const QuickFinderDialogShell = ({
  children,
  loading,
  loadingLabel,
  title,
  selectionTitle,
  titleButton,
  showComplete,
  onCancel,
}: QuickFinderWrapperProps) => {
  return (
    <>
      {loading ? (
        <DialogLoading label={loadingLabel} />
      ) : (
        <>
          {showComplete ? null : (
            <>
              <DialogHeader
                onClose={onCancel}
                title={
                  <>
                    <div className={'flex items-center space-x-1'}>
                      <div>{title}</div>
                      {selectionTitle !== undefined ? (
                        <div>
                          <span className={'text-sm'}>
                            Currently Selected: {selectionTitle ?? 'none'}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {titleButton ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                        }}
                      >
                        {titleButton}
                      </div>
                    ) : null}
                  </>
                }
              />
              {/*<Divider />*/}
            </>
          )}
          {children}
        </>
      )}
    </>
  );
};

export default QuickFinderDialogShell;

import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

// import useStyles from './styles';

import { getAtPath, setAtPath } from 'app/utilities';

export const MenuItem_Custom = props => {
  const {
    data,
    mode,
    onClick,
    customIcon,
    customText,
    customSecondaryText,
    onClose,
  } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    currentCallflow,
    componentData,
    componentInfo,
    // callflow,
    // setCallflow,
    // modifyPath,
  } = data;

  const { callflow, setCallflow, modifyPath } = insertAfterData;

  return (
    <MenuItem
      onClick={e => {
        onClose();
        onClick();
      }}
      component={'a'}
    >
      <ListItemIcon>{customIcon}</ListItemIcon>
      <ListItemText primary={customText} secondary={customSecondaryText} />
    </MenuItem>
  );
};

export default MenuItem_Custom;

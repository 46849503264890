import { useEmergencyAddressDialog } from 'app/components/ListPhoneNumbers/components/PhoneNumberListOptions/components/EmergencyAddressDialog';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { ScheduleWidget } from 'app/components/ScheduleWidget';
import constants from 'app/constants';

import { useAuthSelector } from 'app/data/auth';
import { useLocalSelector } from 'app/data/local';

import {
  Box,
  buildThemeWithMode,
  CircularProgress,
  createTheme,
  Divider,
  Grid,
  Link,
  Skeleton,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from 'app/design';

import {
  EventBusy as EventBusyIcon,
  MedicalServices as MedicalServicesIcon,
  Numbers as NumbersIcon,
} from 'app/design/icons-material';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';

// import { store } from '../../../store';
import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UserPrivLevel } from 'types/user';
import { AccountCallerIdCard } from '../Account/AccountCallerIdCard';

import { CallHandlingCard } from '../Account/CallHandlingCard';

const AccountMini = props => {
  const accountQuery = useAccountQuery();
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;

  // do not allow to be open if there is a dialog
  // - also need to "capture" the dialog creation/removal so we can hide the tooltip
  const [dialogShown, setDialogShownRaw] = useState(false);
  const setDialogShown = val => {
    setOpen(false);
    setDialogShownRaw(val);
  };
  const [open, setOpen] = useState(false);
  const isOpen = open && !dialogShown ? true : false;

  const theme = useTheme();
  // console.log('THEME:', theme);

  return (
    <Box
      sx={{
        // background: 'rgba(0, 188, 212,0.2)',
        paddingX: 1.6,
        mb: 2,
      }}
    >
      <Tooltip
        open={isOpen}
        // onOpen={e => setOpen(true)}
        onClose={e => setOpen(false)}
        title={
          <TooltipContent
            isOpen={isOpen}
            accountQuery={accountQuery}
            setDialogShown={setDialogShown}
          />
        }
        arrow
        placement="right-end"
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none',
              // background: 'red !important',
            },
          },
          popper: {
            keepMounted: true,
            // disablePortal: true
          },
        }}
      >
        <Box>
          <Name
            accountQuery={accountQuery}
            // handleOpenTooltip={e => setOpen(true)}
          />
          <Number
            accountQuery={accountQuery}
            handleOpenTooltip={e => setOpen(!isOpen)}
          />
          <E911 accountQuery={accountQuery} />
          <Schedule accountQuery={accountQuery} />
          <PersonalDetails accountQuery={accountQuery} />
        </Box>
      </Tooltip>
    </Box>
  );
};

const PersonalDetails = ({ accountQuery }) => {
  const { location } = useHistory();
  // const adminView = location.pathname.includes('admin');
  const {
    data: authenticatedUser,
    refetch: refetchUser,
    isLoading: userIsLoading,
    error: userError,
    isFetching: userIsFetching,
  } = useAuthenticatedUserQuery();
  const { sidebar_key: selectedSidebarKey } = useLocalSelector();

  // if (adminView) return null;

  const externalNumber =
    authenticatedUser?.doc.caller_id?.external?.number ??
    accountQuery.data?.doc.caller_id?.external.number;

  return (
    <>
      <Divider sx={{ pt: 1 }} />
      <div className="no-underline pt-2 -ml-1 text-xs opacity-80 italic">
        Office:
      </div>
      <div
        className={`flex w-full h-8 space-x-2 pt-6  justify-center items-center ${
          userIsLoading || accountQuery.isLoading ? 'opacity-50' : ''
        }`}
      >
        {userIsLoading || accountQuery.isLoading ? (
          <>
            <CircularProgress size={10} variant={'indeterminate'} />
          </>
        ) : (
          <Link component={RouterLink} to={'/office/calling'}>
            <Tooltip
              arrow
              placement="right"
              title={authenticatedUser?.extra.fullName ?? ''}
            >
              <div style={{ paddingLeft: '5px' }} className="font-medium ">
                {authenticatedUser?.extra.fullName?.[0]}
              </div>
            </Tooltip>
            <Tooltip
              arrow
              placement="right"
              title={externalNumber ?? 'Caller ID not set up'}
            >
              <div
                style={{
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  borderRadius: '4px',
                  background:
                    selectedSidebarKey ===
                    constants.nav.OFFICE_CALLING.sidebar_key
                      ? '#1F8DA4'
                      : undefined,
                }}
                className="font-medium text-white"
              >
                {externalNumber ? (
                  <PhoneNumberDisplay flagOnly ptn={externalNumber} />
                ) : (
                  <span className="italic font-normal opacity-70">?</span>
                )}
              </div>
            </Tooltip>
          </Link>
        )}
      </div>
    </>
  );
};

const TooltipContent = ({ isOpen, accountQuery, setDialogShown }) => {
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;

  if (!account) {
    return <>Loading...</>;
  }

  return (
    <>
      <ThemeProvider
        // @ts-ignore
        theme={outerTheme => outerTheme.outerTheme}
      >
        <Box sx={{ minWidth: '600px' }}>
          <AccountCallerIdCard
            account={account}
            setDialogShown={setDialogShown}
            onComplete={refetchAccount}
          />
          <br />
          <CallHandlingCard
            account={account}
            setDialogShown={setDialogShown}
            onComplete={refetchAccount}
          />
        </Box>
      </ThemeProvider>
    </>
  );
};

const Name = ({ accountQuery }) => {
  const { data: account, isLoading: accountIsLoading } = accountQuery;
  const { data: authenticatedUser } = useAuthenticatedUserQuery();

  return (
    <Tooltip placement={'right'} arrow title={account?.doc?.name}>
      <Typography sx={{ ml: 1 }} variant="h2" gutterBottom noWrap>
        <Link
          component={RouterLink}
          to="/"
          underline="hover"
          color="primary.main"
          sx={{
            pointerEvents:
              authenticatedUser?.doc.priv_level === UserPrivLevel.Admin
                ? undefined
                : 'none',
          }}
        >
          {account?.doc?.name[0] ?? <Skeleton />}
        </Link>
      </Typography>
    </Tooltip>
  );
};

const Number = ({ accountQuery, handleOpenTooltip }) => {
  const { account_id } = useAuthSelector();
  const { data: account, isLoading: accountIsLoading } = accountQuery;
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const adminView = authenticatedUser?.doc?.priv_level === UserPrivLevel.Admin;

  // const { location } = useHistory();
  // const adminView = location.pathname.includes('admin');
  // account external CID
  let externalCid = account?.doc?.caller_id?.external?.number;

  const {
    data: externalNumberData,
    isLoading: externalNumberIsLoading,
    refetch: refetchExternal,
  } = usePhoneNumberQuery({ id: externalCid });

  const externalNumber = externalNumberData?.doc.id;

  const {
    data: callflowsPageResponse,
    isLoading: callflowsAreLoading,
    isFetching: callflowsFetching,
    refetch,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['owner_id', '=', account_id],
              ['type', '=', 'main'],
            ],
          },
        },
      ],
    },
  });

  const mainCallflow = callflowsPageResponse?.callflows?.length
    ? callflowsPageResponse.callflows[0]
    : null;

  // show a single number (External Caller ID)
  // - icon/alert if this is NOT also an incoming number attached to the incoming callflow
  return (
    <Typography variant="h2" gutterBottom>
      <Grid container columnSpacing={1} wrap="nowrap">
        <Grid
          item
          sx={{
            flex: 1,
            ml: 1,
            cursor: adminView ? 'pointer' : 'default',
            color: 'white !important',
          }}
          // onClick={handleOpenTooltip}
        >
          {externalNumberIsLoading || accountIsLoading ? (
            <Skeleton />
          ) : externalNumber ? (
            <Tooltip
              placement={'right'}
              arrow
              title={<PhoneNumberDisplay ptn={externalNumber} />}
            >
              <div>
                <Link
                  component={RouterLink}
                  to="/admin/calling"
                  underline="hover"
                  color="#fff"
                  sx={{ pointerEvents: adminView ? undefined : 'none' }}
                >
                  <PhoneNumberDisplay flagOnly ptn={externalNumber} />
                </Link>
              </div>
            </Tooltip>
          ) : (
            <Tooltip arrow placement={'right'} title={'Setup Number'}>
              <Link
                component={RouterLink}
                to="/admin/calling"
                underline="hover"
                color="#fff"
                sx={{ pointerEvents: adminView ? undefined : 'none' }}
              >
                <NumbersIcon sx={{ ml: -0.5, opacity: '50%' }} />
              </Link>
            </Tooltip>
          )}
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Typography>
  );
};

const E911 = ({ accountQuery }) => {
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const router = useHistory();
  let emergencyCid = account?.doc?.caller_id?.emergency?.number;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const { location } = router;
  // const adminView = location.pathname.includes('admin');
  const adminView = authenticatedUser?.doc.priv_level === UserPrivLevel.Admin;
  // TODO: shouldn't have to do this, theme above is overriding it
  // @ts-ignore
  const usedTheme = React.useMemo(
    () =>
      // @ts-ignore
      createTheme(buildThemeWithMode(prefersDarkMode ? 'light' : 'light')), // dark
    [prefersDarkMode],
  );

  const {
    data: emergencyNumberData,
    isLoading: emergencyNumberIsLoading,
    refetch: refetchEmergency,
  } = usePhoneNumberQuery({ id: emergencyCid });

  // console.log('emergencyNumberData', emergencyNumberData);
  const number = emergencyNumberData?.doc?.id;
  const emergencyAddress = emergencyNumberData?.doc.e911?.street_address;
  const {
    toggleOpen,
    Dialog: EmergencyAddressDialog,
    DialogProps,
  } = useEmergencyAddressDialog();

  const handleUpdate = () => {
    refetchEmergency();
    refetchAccount();
    toggleOpen();
  };

  const handleClick = () => {
    router.push('/admin/calling');
  };

  return (
    <>
      <ThemeProvider theme={usedTheme}>
        <EmergencyAddressDialog
          {...DialogProps}
          onComplete={handleUpdate}
          number={emergencyCid}
        />
      </ThemeProvider>
      <Tooltip
        arrow
        placement={'left'}
        title={
          emergencyNumberData
            ? emergencyAddress ?? 'No Emergency Address'
            : 'No Emergency Address'
        }
      >
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            textOverflow: 'ellipsis',
            width: '100%',
            overflowX: 'hidden',
          }}
          noWrap
          paragraph
        >
          {emergencyNumberIsLoading || accountIsLoading ? (
            <Skeleton />
          ) : (
            <>
              <MedicalServicesIcon
                onClick={handleClick}
                sx={{
                  fontSize: 'large',
                  cursor: 'pointer',
                  pointerEvents: adminView ? undefined : 'none',
                }}
              />
            </>
          )}
        </Typography>
      </Tooltip>
    </>
  );
};

const Schedule = ({ accountQuery }) => {
  const { data: account, isLoading: accountIsLoading } = accountQuery;
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const adminView = authenticatedUser?.doc?.priv_level === UserPrivLevel.Admin;

  // get account schedule
  // - TODO: let them define the schedule

  // schedule list
  const { refetch, isLoading, isFetching, dataUpdatedAt, data } =
    useListSchedulesQuery({
      skip: 0, //skip
      take: 10, // take
      orderBy: undefined, // order by
      filters: {}, // filters
    });
  const { callflows: schedules, totalCount = 0 } = data ?? {};

  const schedule = schedules?.length ? schedules[0] : null;

  return (
    <Box
      sx={{
        mt: -1,
        mb: 1,
        ml: 0.5,
        pointerEvents: adminView ? undefined : 'none',
      }}
    >
      {
        isLoading ? (
          <Skeleton />
        ) : schedule ? (
          <ScheduleWidget noLabel callflow={schedule.doc} /> ?? (
            <Tooltip title={'No Account Schedule'}>
              <EventBusyIcon />
            </Tooltip>
          )
        ) : null
        // <Tooltip title={'No Account Schedule'}>
        //   <EventBusyIcon />
        // </Tooltip>
      }
    </Box>
  );
};

export default AccountMini;

import { gql } from 'graphql-request';

export default gql`
  query mediaById($id: String, $mask: String) {
    mediaById(id: $id, mask: $mask) {
      id
      account_id
      doc
      listing
      createdAt
      updatedAt
    }
  }
`;

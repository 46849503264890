import { TrialButton } from 'app/components/Header/Header';
import { useAuthSelector } from 'app/data/auth';
import { CancelSubDialog } from 'app/pages/admin/Billing/Home/components/CancelSubDialog';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import { gql, request } from 'graphql-request';
import React from 'react';
import UpgradeIcon from '@mui/icons-material/Upgrade';

import { Button, Setting, Alert } from 'app/design-lib';
// import { useStripeCustomerQuery } from 'app/hooks/queries/stripe';

import constants from 'app/constants';
import { useAccountQuery } from 'app/hooks/queries/account';
import { usePlanChangeDialog } from 'app/components/PlanChangeDialog';
import { usePlanUpgradeDialog } from 'app/components/PlanUpgradeDialog';

import moment from 'moment';
import { Check, WarningTriangle } from 'iconoir-react';
import { format } from 'date-fns';

const { PLANS } = constants;

function Plan({ account, customer, plan, primarySub, refetch }) {
  const {
    toggleOpen: toggleOpenPlanChangeDialog,
    Dialog: PlanChangeDialog,
    DialogProps: PlanChangeDialogProps,
  } = usePlanChangeDialog({ initialOpen: false });
  const {
    toggleOpen: toggleOpenPlanUpgradeDialog,
    Dialog: PlanUpgradeDialog,
    DialogProps: PlanUpgradeDialogProps,
  } = usePlanUpgradeDialog({ initialOpen: false });

  // console.log('Plan:', Plan);
  const handleComplete = () => {
    refetch();
    toggleOpenPlanChangeDialog();
  };
  const handleUpgrade = () => {
    refetch();
    toggleOpenPlanUpgradeDialog();
  };
  const { auth_token } = useAuthSelector();

  const nowUtcSeconds = moment.utc().unix();

  // const isExpired =
  //   moment()
  //     .utc()
  //     .diff(account?.doc?.trial_end * 1000, 'seconds') > 0;

  const {
    status,
    items,
    trial_end,
    quantity,
    cancel_at_period_end,
    current_period_end,
  } = primarySub ?? {};

  const isTrial = status === 'trialing' ? true : false;

  return (
    <>
      {/*<CancelSubDialog open={open} onClose={toggleOpen} />*/}
      <SettingsPanel
        leftChild={
          <Setting
            // label={isTrial ? '30 day free trial' : 'Basic plan'}
            label={'Basic plan'}
            // buttonProps={{
            //   children: 'Cancel subscription',
            //   onClick: toggleOpen,
            // }}
            body={
              // isTrial
              //   ? 'Cancel your subscription before your trial expires to avoid incurring charges.'
              //   : '$5/user per month.'
              account.internal?.iap
                ? 'Mobile Subscription'
                : '$5/user per month.'
            }
          />
        }
        rightChild={
          isTrial ? (
            <TrialButton billingView />
          ) : status === 'active' ? (
            <Alert
              label={`Active Subscription (Renews ${format(
                new Date(current_period_end * 1000),
                'MM/dd/yyyy',
              )})`}
              reverse
              color={'positive'}
              icon={<Check fr={undefined} />}
              body={
                <div className={`flex flex-col space-y-2`}>
                  <span>Your plan includes: </span>• {quantity} User Seats
                </div>
              }
            />
          ) : status === 'past_due' ? (
            <Alert
              label={`Payment Failure`}
              reverse
              color={'negative'}
              icon={<WarningTriangle fr={undefined} />}
              body={
                'There was an issue processing your payment. Please pay your invoice below to avoid any interruption in your service.'
              }
            />
          ) : (
            <Alert
              label={`Inactive Account`}
              reverse
              color={'negative'}
              icon={<WarningTriangle fr={undefined} />}
              body={
                'Your account will be disabled shortly. Contact support if you wish to reactivate your account.'
              }
            />
          )
        }
      />
    </>
  );

  // if (account.doc.trial_status === 'trialing') {
  //   return (
  //     <div className="font-sans">
  //       <PlanUpgradeDialog
  //         {...PlanUpgradeDialogProps}
  //         onComplete={handleUpgrade}
  //       />
  //       {/*<Alert severity="warning" className={'rounded'}>
  //         <div className={'text-lg font-bold -mt-1'}>
  //           Your trial ends on{' '}
  //           {moment
  //             .utc(account.doc.trial_end * 1000)
  //             .format('dddd, MMMM Do YYYY')}
  //         </div>
  //         <br />
  //         <div className={'font-medium mb-2'}>
  //           After your trial expires, CallingIO will be unavailable.
  //         </div>
  //         <div className={'mt-4'}>
  //           <Button
  //             variant="outline"
  //             color={'attention'}
  //             className={'inline-flex whitespace-nowrap'}
  //             onClick={toggleOpenPlanUpgradeDialog}
  //             startIcon={<UpgradeIcon />}
  //           >
  //             Upgrade Now
  //           </Button>{' '}
  //           to avoid any interruption in service. You will still get 30 total
  //           days free usage when you upgrade early!{' '}
  //         </div>
  //       </Alert>*/}
  //     </div>
  //   );
  // }

  // // old Plan info (with upgrade button)
  // return (
  //   <div>
  //     <PlanChangeDialog
  //       {...PlanChangeDialogProps}
  //       onComplete={handleComplete}
  //     />

  //     {/* <Typography variant="h4" gutterBottom>
  //       Plan
  //     </Typography> */}
  //     <Typography variant="body1" paragraph>
  //       Current Plan: <strong>{plan.name}</strong>
  //     </Typography>

  //     {plan.can_switch_from ? (
  //       <Button
  //         variant="contained"
  //         // size="small"
  //         onClick={toggleOpenPlanChangeDialog}
  //       >
  //         Change Plan
  //       </Button>
  //     ) : (
  //       <Link href="mailto:support@calling.io">
  //         Contact us to change your plan
  //       </Link>
  //     )}
  //   </div>
  // );
  return null;
}

export default Plan;

import { gql } from 'graphql-request';

export default gql`
  query Query {
    maybeContacts {
      id
      name
      number
      # totalCount
      # contacts {
      #   id
      #   source
      #   schema
      #   info
      # }
    }
  }
`;

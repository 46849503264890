import { useMutation } from 'react-query';
// import { patch } from 'app/sdk/utils/kazoo';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { UserCreateDoc } from 'types/user';

export const useCreateUser = () => {
  const authState = useAuthSelector();
  return useMutation(async (userCreateDoc: UserCreateDoc) =>
    sdk.user.mutate.create(userCreateDoc, authState?.auth_token),
  );
};

import { Box, Checkbox, MenuItem, Select, Typography } from 'app/design';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';
import {
  useWebphoneSelector,
  useWebphoneSlice,
} from '../../../../../data/webphone';
import { useWebphoneContext } from '../../../Store';
import useStyles from './styles';

import { IconButton, Switch, Slider } from '../../../../../design-lib';
import { SoundHigh, SoundOff } from 'iconoir-react';

const KeypadSetting = props => {
  const { filterText, setFilterMatching } = props;

  const SUB_ITEMS = [
    {
      component: ToggleKeyPadSounds,
    },
    {
      component: KeyPadVolume,
    },
  ];

  const [fullSubitemsList, setFullSubitemsList] = useState([...SUB_ITEMS]);

  const anyChildrenMatch = fullSubitemsList.find(setting => setting.matching)
    ? true
    : false;

  // useEffect(() => {
  //   setFilterMatching(anyChildrenMatch);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fullSubitemsList, anyChildrenMatch]);

  const handleChildrenFilterMatching = index => matching => {
    setFullSubitemsList(list => {
      list[index].matching = matching;
      return [...list];
    });
  };
  return (
    <>
      <ToggleKeyPadSounds />
      <KeyPadVolume />
    </>
  );

  // const classes = useStyles({ anyChildrenMatch });

  // return (
  //   <Box sx={classes.mainCategory}>
  //     <Typography variant="h6" gutterBottom>
  //       Key Pad Sounds
  //     </Typography>
  //     <div style={{ padding: '0px 8px' }}>
  //       {SUB_ITEMS.map((subitem, i) => {
  //         const Component = subitem.component;
  //         return (
  //           <Component
  //             key={i}
  //             filterText={filterText}
  //             setFilterMatching={handleChildrenFilterMatching(i)}
  //           />
  //         );
  //       })}
  //     </div>
  //   </Box>
  // );
};

const ToggleKeyPadSounds = props => {
  const { filterText, setFilterMatching } = props;
  const { settings } = useWebphoneSelector();
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();
  const [doesMatch, setDoesMatch] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState('default');

  // useEffect(() => {
  //   setFilterMatching(doesMatch);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [doesMatch]);

  useEffect(() => {
    const matches = ['keypad mute sound'];
    setDoesMatch(matchSorter(matches, filterText).length ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  const classes = useStyles({ doesMatch });

  const toggleSounds = () =>
    dispatch(actions.disableKeypadSounds(!settings.keypad?.disableSounds));

  return (
    <div className={`w-full flex justify-between items-center`}>
      <div className={`text-sm font-normal`}>Keypad sounds</div>
      <Switch
        size={'sm'}
        checked={!settings.keypad?.disableSounds}
        onClick={toggleSounds}
      />
    </div>
  );

  // return (
  //   <Box sx={classes.subitem}>
  //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //       <Checkbox
  //         sx={{ mb: 0.75, fontSize: 20 }}
  //         checked={settings.keypad?.disableSounds}
  //         onClick={toggleSounds}
  //       />
  //       <Typography variant="body1" gutterBottom>
  //         Mute Keypad Sounds
  //       </Typography>
  //     </Box>
  //   </Box>
  // );
};

const KeyPadVolume = props => {
  const { filterText, setFilterMatching } = props;
  const { settings } = useWebphoneSelector();
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();
  const [value, setValue] = useState(Math.round(settings.keypad?.volume * 100));

  const [doesMatch, setDoesMatch] = useState(true);

  // useEffect(() => {
  //   setFilterMatching(doesMatch);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [doesMatch]);
  //
  // useEffect(() => {
  //   const matches = ['keypad volume sound'];
  //   setDoesMatch(matchSorter(matches, filterText).length ? true : false);
  // }, [filterText]);

  useDebounce(
    () => {
      dispatch(actions.setKeypadVolume(Math.round(value) / 100));
    },
    250,
    [value],
  );

  const onVolumeChange = value => {
    setValue(value);
  };

  return (
    <div className={`w-full flex space-x-2 items-center`}>
      <IconButton
        size={'sm'}
        variant={'ghost'}
        color={'neutral'}
        onClick={() => onVolumeChange(0)}
        pill
      >
        <SoundOff fr={undefined} />
      </IconButton>
      <Slider value={value} onChange={onVolumeChange} min={0} max={10} />
      <IconButton
        size={'sm'}
        variant={'ghost'}
        color={'neutral'}
        pill
        onClick={() => onVolumeChange(10)}
      >
        <SoundHigh fr={undefined} />
      </IconButton>
    </div>
  );

  // const classes = useStyles({ doesMatch });
  //
  // return (
  //   <Box sx={classes.subitem}>
  //     <div style={{}}>
  //       <Typography variant="body1">Keypad Volume</Typography>
  //       <span>{value}%</span>
  //       <Slider value={value} onChange={onVolumeChange} />
  //     </div>
  //   </Box>
  // );
};

export default KeypadSetting;

import React from 'react';
// import "./chip.css";
import { Chip as MuiChip } from '@mui/material';

export interface ChipProps {}

const Chip = React.forwardRef((props: any, ref) => {
  return <MuiChip ref={ref} {...props} />;
});

export default Chip;

import { gql, request } from 'graphql-request';
import * as queries from './queries';
import * as mutations from './mutations';
import constants from 'app/constants';

// Add ALL the possible query options here that are EXPORTED from ./queries/index.ts
export const queryNames = [
  'omniSearch',
  'userById',
  'users',
  'account',
  'phoneNumbers',
  'phoneNumberById',
  'conferences',
  'conferenceById',
  'devices',
  'deviceById',
  'directories',
  'directoryById',
  'extensions',
  'medias',
  'mediaById',
  'menus',
  'menuById',
  'groups',
  'groupById',
  'callDetailRecords',
  'callDetailRecordsReport',
  'cdrInteractions', // old
  'interactions',
  'interactionsById',
  'callflows', // used by schedules as well; filter by type in vars
  'callflowById',
  'vmboxes',
  'vmboxById',
  'vmboxMessages',
  'callsParked',
  'usersQuick',
  'nextUserExtension',
  'vmboxesQuick',
  'devicesQuick',
  'statsNumbers',
  'statsUsers',
  'statsDevices',
  'statsVmboxes',
  'statsConferences',
  'statsMisc',
  'stripeCustomer',
  'keyValues',
  'statsVmboxMessages',
  'temporalRules',
  'temporalRulesSets',
  'contacts',
  'maybeContacts',
  'contactLists',
] as const;

export const mutationNames = [
  'callflowCreate',
  'callflowUpdateById',
  'updateVmboxMessageCustomFieldById',
  'contactCreate',
  'contactUpdateById',
  'contactDeleteById',
  'stripeNewCustomer',
  'stripeCustomerMutate',
  'phoneNumberDelete',
  'keyValueWrite',
] as const;

export type QueryName = typeof queryNames[number];
export type MutationName = typeof mutationNames[number];

interface FetchOptions {
  authToken?: any;
}

// re-export queries and mutations
export { queries, mutations };

export const fetchFromGraphql = async (
  queryName: QueryName,
  vars: any = {},
  fetchOptions: FetchOptions,
  opts: any = {
    full: false,
  },
) => {
  // console.log(
  //   'fetchFromGraphql authorization:',
  //   queryName,
  //   fetchOptions.authToken ? true : false,
  // );
  if (!queries[queryName]) {
    console.error(
      `Invalid queryName ("${queryName}") specified, not included in list of included queries that exist (or are included in lib)`,
    );
    return null;
  }
  const resp = await request(
    constants.env.REACT_APP_CIO_GRAPHQL_SERVER ?? '',
    queries[queryName],
    vars,
    { authorization: fetchOptions.authToken ?? undefined },
  );
  // console.log('graphql resp', resp);
  return opts?.full ? resp : resp[queryName] ?? null;
};

export const mutateGraphql = async (
  mutationName: MutationName,
  vars: any = {},
  fetchOptions: FetchOptions,
) => {
  // console.log(
  //   'mutateGraphql authorization:',
  //   mutationName,
  //   fetchOptions.authToken ? true : false,
  //   // mutations,
  // );
  const resp = await request(
    constants.env.REACT_APP_CIO_GRAPHQL_SERVER ?? '',
    mutations[mutationName],
    vars,
    { authorization: fetchOptions.authToken ?? undefined },
  );
  // console.log('resp', resp);
  return resp[mutationName] ?? null;
};

// export const fetchUser = async (id, authToken) => {
//   const resp = await request(
//     constants.env.REACT_APP_CIO_GRAPHQL_SERVER ?? '',
//     queries.userById,
//     {
//       id,
//       // maskCallflows: '*,_doc(id),_listing(id)',
//     },
//     { authorization: authToken ?? '' },
//   );

//   return resp.userById ?? null;
// };

// export const fetchAccount = async authToken => {
//   const resp = await request(
//     constants.env.REACT_APP_CIO_GRAPHQL_SERVER ?? '',
//     queries.account,
//     {},
//     { authorization: authToken ?? '' },
//   );

//   return resp.account ?? null;
// };

// export const fetchPhoneNumbers = async authToken => {
//   const resp = await request(
//     constants.env.REACT_APP_CIO_GRAPHQL_SERVER ?? '',
//     queries.phoneNumbers,
//     {},
//     { authorization: authToken ?? '' },
//   );

//   return resp.phoneNumbers ?? [];
// };

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogProps,
  IconButton,
} from 'app/design-lib';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import {
  ArrowLeft,
  ArrowRight,
  Cancel,
  Copy,
  NavArrowLeft,
  NavArrowRight,
  Plus,
  WarningTriangle,
} from 'iconoir-react';
import * as React from 'react';
import { useAccountQuery } from 'app/hooks/queries/account';
import { startCase } from 'lodash';

interface ScheduleNodeDialogProps extends Omit<DialogProps, 'children'> {
  timeframe: any;
  onComplete: (greeting_id: string) => void;
}

export const CustomLastCommunicationDialog = ({
  open,
  onClose,
  timeframe,
  onComplete,
}: ScheduleNodeDialogProps) => {
  return (
    <Dialog size={'md'} open={open} onClose={onClose}>
      <LastCommsContent
        timeframe={timeframe}
        onClose={onClose}
        onComplete={onComplete}
      />
    </Dialog>
  );
};

const LastCommsContent = ({ timeframe, onClose, onComplete }) => {
  const formMethods = useForm({
    defaultValues: {
      // @ts-ignore TODO: only support one timeframe at the moment
      timeframe: timeframe.length
        ? timeframe[0][1]
        : {
            seconds: '0',
            minutes: '0',
            hours: '0',
            days: '0',
            weeks: '0',
            months: '0',
            years: '0',
          },
    },
  });

  const onSubmit = ({ timeframe }: any) => {
    onComplete(timeframe);
  };

  return (
    <>
      <DialogHeader title={'Custom Communicated Within'} onClose={onClose} />
      <FormProvider {...formMethods}>
        <DialogContent className={`overflow-y-visible`}>
          <TimeframeDisplay />
        </DialogContent>
      </FormProvider>
      <DialogActions className={`w-full justify-between`}>
        <Button
          variant={'fill'}
          color={'negative'}
          size={'sm'}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant={'fill'}
          color={'accent'}
          size={'sm'}
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          Update communicated within
        </Button>
      </DialogActions>
    </>
  );
};

const TimeframeDisplay = () => {
  const { setValue, watch } = useFormContext();
  const values = watch('timeframe');
  // const {
  //   // fields: valueFields,
  //   update,
  //   append,
  //   remove,
  //   insert,
  // } = useFieldArray({ name: 'timeframe' });

  const label = ``;
  Object.entries(field => {});

  return (
    <>
      <div className="pb-2 text-md">
        The amount of time to subtract from the incoming messages time of
        receipt.
      </div>
      <div className={`grid grid-cols-2  gap-2`}>
        {[
          'days',
          'seconds',
          'weeks',
          'minutes',
          'months',
          'hours',
          'years',
        ].map(field => {
          const value = Number.parseInt(values[field]);

          return (
            <div className={`flex space-x-2  items-end`}>
              <IconButton
                onClick={() => {
                  setValue(`timeframe.${field}`, (value - 1).toString());
                }}
                disabled={value <= 0}
                variant={`ghost`}
                color={'accent'}
                className={`mb-2`}
              >
                <NavArrowLeft fr={undefined} />
              </IconButton>
              <div className={`w-16`}>
                <HookFormTextField
                  name={`timeframe.${field}`}
                  label={startCase(field)}
                  type={'number'}
                  className={`w-full`}
                />
              </div>
              <IconButton
                onClick={() => {
                  setValue(`timeframe.${field}`, (value + 1).toString());
                }}
                variant={`ghost`}
                color={'accent'}
                className={`mb-2`}
              >
                <NavArrowRight fr={undefined} />
              </IconButton>
            </div>
          );
        })}

        {/*{valueFields.length ? (*/}
      </div>
      {/*  <>*/}
      {/*    /!* <div*/}
      {/*      className={`grid w-full grid-cols-12 mt-4 -mb-2 gap-x-2 col-span-2 items-end`}*/}
      {/*    >*/}
      {/*      <div className={`col-span-3 flex space-x-2 items-end`}>*/}
      {/*        <span className={`ml-4`}>Type</span>*/}
      {/*      </div>*/}
      {/*      <div className={`col-span-2 ml-3`}>Day</div>*/}
      {/*      <div className={`col-span-3 ml-3`}>From</div>*/}
      {/*      <div className={`col-span-3 ml-3`}>To</div>*/}
      {/*      <div className={`flex flex-col col-span space-y-1`}></div>*/}
      {/*    </div> *!/*/}
      {/*    {valueFields.map((value, index) => (*/}
      {/*      <ValueField*/}
      {/*        key={index}*/}
      {/*        value={value}*/}
      {/*        index={index}*/}
      {/*        onRemove={() => {*/}
      {/*          remove(index);*/}
      {/*        }}*/}
      {/*        onDuplicate={() => {*/}
      {/*          insert(index + 1, { ...value, id: Date.now() + 104 });*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  <Alert*/}
      {/*    className={`w-full col-span-2`}*/}
      {/*    label={'No Time-ranges'}*/}
      {/*    reverse*/}
      {/*    body={'Add at least one rule for this route.'}*/}
      {/*    icon={<WarningTriangle fr={undefined} />}*/}
      {/*  />*/}
      {/*)}*/}
      {/*<div className={`w-full mt-4 mb-2 flex`}>*/}
      {/*  <Button*/}
      {/*    endIcon={<Plus fr={undefined} />}*/}
      {/*    size={'sm'}*/}
      {/*    // className={`mt-7`}*/}
      {/*    variant={'fill'}*/}
      {/*    color={`accent`}*/}
      {/*    onClick={() => {*/}
      {/*      append({ key: 'days', value: 1 });*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Add time-range*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </>
  );
};

const ValueField = ({ index, value, onRemove, onDuplicate }) => {
  const { register } = useFormContext();

  const showLabel = index === 0;
  const showHours = value.type === 'day_of_week_hours';
  return (
    <div
      className={`grid w-full grid-cols-12 gap-2 col-span-2 items-center my-2`}
    >
      <div className={`col-span-3`}>
        <HookFormTextField
          label={''}
          type={'number'}
          name={`timeframe.${index}.value`}
        />
      </div>
      <div className={`col-span-6 flex space-x-2 items-end`}>
        {/* <span className={` mb-2`}>{index + 1}.</span> */}
        <HookFormListbox
          className={`w-full`}
          name={`timeframe.${index}.key`}
          // label={showLabel ? 'Type' : undefined}
          options={[
            { label: 'Seconds', value: 'seconds' },
            { label: 'Minutes', value: 'minutes' },
            { label: 'Hours', value: 'hours' },
            { label: 'Days', value: 'days' },
            { label: 'Weeks', value: 'weeks' },
            { label: 'Months', value: 'months' },
            { label: 'Years', value: 'years' },
          ]}
        />
      </div>
      <span>ago</span>
      <div className={`flex col-span-2 space-x-2`}>
        <IconButton
          onClick={onDuplicate}
          variant={'outline'}
          className={``}
          color={'accent'}
          size={'sm'}
        >
          <Copy fr={undefined} />
        </IconButton>
        <IconButton
          onClick={onRemove}
          variant={'outline'}
          className={``}
          color={'negative'}
          size={'sm'}
        >
          <Cancel fr={undefined} />
        </IconButton>
      </div>
    </div>
  );
};

const generateHoursOfDayArray = () => {
  const hoursOfDay: any[] = [];
  for (let hour = 0; hour < 24; hour++) {
    let labelHour = hour % 12;
    let isAm = true;
    if (hour > 11) {
      isAm = false;
      labelHour = labelHour;
    }

    if (labelHour === 0) labelHour = 12;

    hoursOfDay.push({
      data: { hour, minute: 0 },
      label: `${labelHour}:00 ${isAm ? 'AM' : 'PM'}`,
      value: `${hour}:0`,
    });
    hoursOfDay.push({
      data: { hour, minute: 30 },
      label: `${labelHour}:30 ${isAm ? 'AM' : 'PM'}`,
      value: `${hour}:30`,
    });
  }

  return hoursOfDay;
};

const HoursOfWeekValues = generateHoursOfDayArray();

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Account } from 'types/account';
import { User } from 'types/user';
import keyvalueQueryKeys from './keyvalueQueryKeys';

export const useKeyValuesQuery = (
  subject?: string,
  options?: Omit<
    UseQueryOptions<Account, Error, Account, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  const authState = useAuthSelector();
  // const { account_id } = authState.auth_token_decoded.account_id;
  const queryKey = keyvalueQueryKeys.bySubject(subject);

  const query = useQuery<Account, Error>(
    queryKey,
    () =>
      sdk.keyvalue.query.read(
        { data: { subject } },
        { authToken: authState?.auth_token },
      ), // fetchAccount(authState.auth_token),
    {
      //
      enabled: authState?.auth_token_decoded?.owner_id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useKeyValuesQuery;

import { DoNotDisturbSettings } from 'app/components/Sidebar2/components/DoNotDisturbSettings';
import { Switch } from 'app/design-lib';
import * as React from 'react';
import {
  Flash,
  FlashOff,
  UserSquare,
  SoundHigh,
  SoundOff,
} from 'iconoir-react';
import { User } from 'types/user';
import { usePNContext } from '../../../../utilities/pubnub';
import { Link } from 'react-router-dom';
interface StatusSwitchesProps {
  user: User;
  onComplete: () => void;
}

const StatusSwitches = ({ user, onComplete }: StatusSwitchesProps) => {
  return (
    <div
      className={
        'border-b w-full text-md min-h-fit border-gray-20 w-full flex flex-col items-center '
      }
    >
      {/*<UserIsOnlineSwitch />*/}
      <div className={`py-2.5 px-4 w-full`}>
        <DoNotDisturbSettings userDoc={user?.doc} onComplete={onComplete} />
      </div>
      <Link
        className={`flex space-x-2 py-2 px-4 hover:bg-transparency-10 w-full items-center font-medium text-md`}
        to={`/v2/contacts`}
      >
        <UserSquare
          className={`text-gray-60`}
          fr={undefined}
          height={16}
          width={16}
        />
        <span>Contacts</span>
      </Link>
    </div>
  );
};

const UserIsOnlineSwitch = () => {
  // @ts-ignore
  const { userIsOnline, userIsOnlineSet, onlineUsers } = usePNContext();

  return (
    <div className={'flex w-full justify-between'}>
      <span className={'text-md font-medium'}>Set as away</span>
      <Switch
        uncheckedIcon={<Flash fr={undefined} />}
        checkedIcon={<FlashOff fr={undefined} />}
        checked={userIsOnline}
        onClick={() => userIsOnlineSet(!userIsOnline)}
      />
    </div>
  );
};

export default StatusSwitches;

const nav = {
  DRAWER_WIDTH: 240,
  DRAWER_MINI_WIDTH: 56,
  ADMIN: {
    sidebar_key: '',
    crumb: {
      name: 'Admin',
      link: '/admin',
    },
  },
  ADMIN_ALL_CALLFLOWS_LIST: {
    sidebar_key: 'admin_all_callflows_list',
    crumb: {
      name: 'Callflows',
      link: '/admin/call_routes',
    },
  },
  ADMIN_BILLING: {
    sidebar_key: 'admin_billing',
    crumb: {
      name: 'Billing',
      link: '/admin/billing',
    },
  },
  // ADMIN_BILLING_SUMMARY: {
  //   sidebar_key: 'admin_billing_summary',
  //   crumb: {
  //     name: 'Summary',
  //     link: '/admin/billing/summary',
  //   },
  // },
  ADMIN_ACCOUNT_CALLING: {
    sidebar_key: 'admin_account_calling',
    crumb: {
      name: 'Account Calling',
      link: '/admin/calling',
    },
  },
  ADMIN_OPERATOR_EXTENSION: {
    sidebar_key: 'admin_operator_extension',
    crumb: {
      name: 'Operator Extension',
      link: '/admin/operator_ext',
    },
  },
  ADMIN_CALL_LOGS: {
    sidebar_key: 'admin_call_logs',
    crumb: {
      name: 'Call Logs & Reports',
      link: '/admin/calllogs',
    },
  },
  ADMIN_CALL_FINDER: {
    sidebar_key: 'admin_call_finder',
    crumb: {
      name: 'Call Finder',
      link: '/admin/calllogs/callfinder',
    },
  },
  ADMIN_CONFERENCES_LIST: {
    sidebar_key: 'admin_conferences_list',
    crumb: {
      name: 'Conferences',
      link: '/admin/conferences',
    },
  },
  ADMIN_CONFERENCE_VIEW: {
    sidebar_key: 'admin_conferences_list',
    crumb: ({ id, conference, status }) => ({
      name: `Conference: ${
        conference
          ? conference?.doc?.name ?? 'Unnamed Conference'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/conferences/view/${id}`,
    }),
  },
  ADMIN_DASHBOARD: {
    sidebar_key: 'admin_dashboard',
    crumb: {
      name: 'Dashboard',
      link: '/admin/dashboard',
    },
  },
  ADMIN_DASHBOARD_CALL_HANDLING: {
    sidebar_key: 'admin_dashboard',
    crumb: {
      name: 'Call Routing',
      link: '/admin/dashboard/call_routing',
    },
  },
  ADMIN_DIRECTORIES_LIST: {
    sidebar_key: 'admin_directories_list',
    crumb: {
      name: 'Directories',
      link: '/admin/directories',
    },
  },
  ADMIN_DIRECTORY_VIEW: {
    sidebar_key: 'admin_directories_list',
    crumb: ({ id, directory, status }) => ({
      name: `Directory: ${
        directory
          ? directory?.doc?.name ?? 'Unnamed Directory'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/directories/view/${id}`,
    }),
  },
  ADMIN_DEVICES_LIST: {
    sidebar_key: 'admin_devices_list',
    crumb: {
      name: 'Devices',
      link: '/admin/devices',
    },
  },
  ADMIN_DEVICE_VIEW: {
    sidebar_key: 'admin_devices_list',
    crumb: ({ id, device, status }) => ({
      name: `Device: ${
        device
          ? device?.doc.name ?? 'Unnamed Device'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/devices/view/${id}`,
    }),
  },
  ADMIN_DEVICE_CALL_HANDLING: {
    sidebar_key: 'admin_devices_list',
    crumb: ({ id }) => ({
      name: `Call Routing`,
      link: `/admin/devices/view/${id}/call_routing`,
    }),
  },
  ADMIN_EXTENSIONS_LIST: {
    sidebar_key: 'admin_extensions_list',
    crumb: {
      name: 'Extensions',
      link: '/admin/extensions',
    },
  },
  ADMIN_GROUPS_LIST: {
    sidebar_key: 'admin_ring_groups_list',
    crumb: {
      name: 'Ring Groups',
      link: '/admin/ring_groups',
    },
  },
  ADMIN_GROUP_VIEW: {
    sidebar_key: 'admin_ring_groups_list',
    crumb: ({ id, group, status }) => ({
      name: `Ring Group: ${
        group
          ? group?.doc?.name ?? 'Unnamed Ring Group'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/ring_groups/view/${id}`,
    }),
  },
  ADMIN_GROUP_CALL_HANDLING: {
    sidebar_key: 'admin_ring_groups_list',
    crumb: ({ id }) => ({
      name: `Call Routing`,
      link: `/admin/ring_groups/view/${id}/call_routing`,
    }),
  },
  ADMIN_IVR_EXTENSIONS_LIST: {
    sidebar_key: 'admin_ivr_extensions_list',
    crumb: {
      name: 'IVR Extensions',
      link: '/admin/ivr_extensions',
    },
  },
  ADMIN_IVR_EXTENSION_EDIT: {
    sidebar_key: 'admin_ivr_extensions_list',
    crumb: ({ id, ivrExtension, status }) => ({
      name: `IVR Extension: ${
        ivrExtension
          ? ivrExtension?.doc?.name ?? 'Unnamed Callflow'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/ivr/edit/${id}`,
    }),
  },
  ADMIN_MEDIAS_LIST: {
    sidebar_key: 'admin_medias_list',
    crumb: {
      name: 'Medias',
      link: '/admin/media',
    },
  },
  ADMIN_MEDIA_VIEW: {
    sidebar_key: 'admin_medias_list',
    crumb: ({ id, media, status }) => ({
      name: `Media: ${
        media
          ? media?.doc?.name ?? 'Unnamed Media'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/media/view/${id}`,
    }),
  },
  ADMIN_MENUS_LIST: {
    sidebar_key: 'admin_menus_list',
    crumb: {
      name: 'Menus',
      link: '/admin/menus',
    },
  },
  ADMIN_MESSAGING: {
    sidebar_key: 'admin_messaging_list',
    crumb: {
      name: 'Messaging',
      link: '/admin/messaging',
    },
  },
  ADMIN_CONTACTS: {
    sidebar_key: 'admin_contacts_list',
    crumb: {
      name: 'Contact Lists',
      link: '/admin/contactlists',
    },
  },
  ADMIN_NUMBERS_LIST: {
    sidebar_key: 'admin_numbers_list',
    crumb: {
      name: 'Phone Numbers',
      link: '/admin/numbers',
    },
  },
  ADMIN_SCHEDULES_LIST: {
    sidebar_key: 'admin_schedules_list',
    crumb: {
      name: 'Schedules',
      link: '/admin/schedules',
    },
  },
  ADMIN_SCHEDULE_VIEW: {
    sidebar_key: 'admin_schedules_list',
    crumb: ({ id, schedule, status }) => ({
      name: `Schedule: ${
        schedule
          ? schedule?.doc?.name ?? 'Unnamed Schedule'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/schedules/edit/${id}`,
    }),
  },
  ADMIN_SCREENPOPS_LIST: {
    sidebar_key: 'admin_screenpops_list',
    crumb: {
      name: 'Screenpops',
      link: '/admin/screenpops',
    },
  },
  ADMIN_SCREENPOP_VIEW: {
    sidebar_key: 'admin_screenpops_list',
    crumb: ({ id, screenpop, status }) => ({
      name: `Screenpop: ${
        screenpop
          ? screenpop?.doc?.name ?? 'Unnamed Screenpop'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/screenpops/edit/${id}`,
    }),
  },
  ADMIN_SUPPORT: {
    sidebar_key: 'admin_support',
    crumb: {
      name: 'Support',
      link: '/admin/support',
    },
  },
  ADMIN_TEMPLATES_LIST: {
    sidebar_key: 'admin_templates_list',
    crumb: {
      name: 'Templates',
      link: '/admin/callflow_templates',
    },
  },
  ADMIN_TEMPLATE_EDIT: {
    sidebar_key: 'admin_templates_list',
    crumb: ({ id, template, status }) => ({
      name: `Template: ${
        template
          ? template?.doc?.name ?? 'Unnamed Template'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/template/edit/${id}`,
    }),
  },
  ADMIN_USERS_LIST: {
    sidebar_key: 'admin_users_list',
    crumb: {
      name: 'Users',
      link: '/admin/users',
    },
  },
  ADMIN_USER_VIEW: {
    sidebar_key: 'admin_users_list',
    crumb: ({ id, user, status }) => ({
      name: `User: ${
        user
          ? user?.extra?.fullName ?? 'Unnamed User'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/users/view/${id}`,
    }),
  },
  ADMIN_USER_CALL_HANDLING: {
    sidebar_key: 'admin_users_list',
    crumb: ({ id }) => ({
      name: `Call Routing`,
      link: `/admin/users/view/${id}/call_routing`,
    }),
  },
  ADMIN_VMBOXES_LIST: {
    sidebar_key: 'admin_vmboxes_list',
    crumb: {
      name: 'Voicemail Boxes',
      link: '/admin/vmboxes',
    },
  },
  ADMIN_VMBOX_VIEW: {
    sidebar_key: 'admin_vmboxes_list',
    crumb: ({ id, vmbox, status }) => ({
      name: `Voicemail Box: ${
        vmbox
          ? vmbox?.doc.name ?? 'Unnamed Voicemail Box'
          : status !== 'error'
          ? 'Loading...'
          : 'Error'
      }`,
      link: `/admin/vmboxes/view/${id}`,
    }),
  },
  ADMIN_VMBOX_CALL_HANDLING: {
    sidebar_key: 'admin_vmboxes_list',
    crumb: ({ id }) => ({
      name: `Call Routing`,
      link: `/admin/vmboxes/view/${id}/call_routing`,
    }),
  },
  OFFICE: {
    sidebar_key: '',
    crumb: {
      name: 'Office',
      link: '/office',
    },
  },
  OFFICE_CALLING: {
    sidebar_key: 'office_calling',
    crumb: {
      name: 'Calling',
      link: '/office/calling',
    },
  },
  OFFICE_CALL_HISTORY: {
    sidebar_key: 'office_call_history',
    crumb: {
      name: 'Call History',
      link: '/office/call_history',
    },
  },
  OFFICE_VOICEMAILS: {
    sidebar_key: 'office_voicemails',
    crumb: {
      name: 'Voicemails',
      link: '/office/voicemails',
    },
  },
  OFFICE_DASHBOARD: {
    sidebar_key: 'office_dashboard',
    crumb: {
      name: 'Dashboard',
      link: '/office/dashboard',
    },
  },
};

export default nav;

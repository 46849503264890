import {
  createPluginFactory,
  getPointBefore,
  onKeyDownToggleMark,
  someHtmlElement,
  ToggleMarkPlugin,
} from '@udecode/plate-core';
import { Range, Node, Editor } from 'slate';
import { MARK_KEYWORD } from './createKeywordPlugin';

export const MARK_VALUE = 'value';

/**
 * Enables support for value formatting
 */
export const createValuePlugin = createPluginFactory<ToggleMarkPlugin>({
  key: MARK_VALUE,
  isLeaf: true,
  // deserializeHtml: {
  //   rules: [
  //     { validNodeName: ["STRONG", "B"] },
  //     {
  //       validStyle: {
  //         fontWeight: ["600", "700", "bold"],
  //       },
  //     },
  //   ],
  //   query: (el) =>
  //     !someHtmlElement(el, (node) => node.style.fontWeight === "normal"),
  // },
  handlers: {
    // onKeyDown:
    //   (editor, { type, options: { hotkey, clear } }) =>
    //   (e) => {
    //     const selection = editor.selection as Range;
    //     const [{ text }] = Editor.leaf(editor, selection);
    //
    //     const pointBefore = Editor.before(editor, selection);
    //
    //     //
    //     const beforeString = Editor.string(editor, {
    //       focus: selection.focus!,
    //       anchor: pointBefore!,
    //     });
    //
    //     if (
    //       beforeString === '"' &&
    //       selection.focus.offset > 1 &&
    //       selection.focus.offset === text.length
    //     ) {
    //       editor.removeMark(MARK_VALUE);
    //       return;
    //     }
    //
    //     // console.log(
    //     //   "before string",
    //     //   pointBefore,
    //     //   selection.focus,
    //     //   beforeString
    //     // );
    //
    //     // check if at end of value (cursor after final quotation)
    //     // const pointBefore = getPointBefore(editor, selection, {
    //     //   matchString: '"',
    //     //   unit: "offset",
    //     //   distance: 2,
    //     // });
    //
    //     // if colon is point before
    //     // if (pointBefore) {
    //     //   console.log("selection", selection);
    //     //   // end value
    //     //   // editor.removeMark(MARK_VALUE);
    //     //   // return;
    //     // }
    //
    //     switch (e.key) {
    //       case " ":
    //         if (text.includes('"')) {
    //           return;
    //         }
    //
    //         editor.removeMark(MARK_VALUE);
    //         return;
    //       case `"`:
    //         if (text.includes('"')) {
    //           editor.insertText(`"`);
    //           editor.removeMark(MARK_VALUE);
    //           e.preventDefault();
    //           return;
    //         }
    //     }
    //   },
  },
  // options: {
  //   hotkey: "mod+b",
  // },
});

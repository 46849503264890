import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { CircularProgress } from '@mui/material';
import { DetailsDialogDevice } from 'app/components/DetailsDialogDevice';
import { Button, Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { HookFormUserSelect } from 'app/components/reactHookFormComponents/HookFormUserSelect';
import {
  Chip,
  Grid,
  IconButton,
  InfoTooltip,
  Link,
  Typography,
} from 'app/design';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { Edit as EditIcon } from 'app/design/icons-material';
import { useFindInPage } from 'app/components/FindInPage';
import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useUserQuery } from 'app/hooks/queries/user';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { Cancel, EditPencil } from 'iconoir-react';
import { startCase } from 'lodash';
import * as React from 'react';
import { useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Device } from 'types/device';
import { Link as RouterLink } from 'react-router-dom';
interface EditDeviceOwnerProps {
  device: Device;
  onComplete: () => void;
}

const DeviceOwner = ({ device, onComplete }: EditDeviceOwnerProps) => {
  const [showDialog, toggleShowDialog] = useReducer(show => !show, false);
  const updateDevice = useUpdateDevicePartial();
  const { data: user, isLoading: userIsLoading } = useUserQuery(
    device.doc.owner_id,
  );

  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'owner',
    options: [
      {
        label: 'Owner',
        value: 'owner',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleRelease = async () => {
    const updateDevicePromise = updateDevice.mutateAsync({
      id: device.doc.id,
      owner_id: null,
    });

    const resp = await toast.promise(updateDevicePromise, {
      pending: 'Releasing device to pool...',
      success: 'Device released to pool!',
      error: 'Error releasing device to pool.',
    });

    if (resp.status === 'success') onComplete();
  };

  const handleComplete = () => {
    onComplete();
    toggleShowDialog();
    updateDevice.reset();
  };

  const handleSelect = async (selected: string[]) => {
    const [userId] = selected;

    const resp = await updateDevice.mutateAsync({
      id: device.doc.id,
      owner_id: userId,
    });
  };

  const { t } = useMarkdownTranslate();

  return (
    <>
      <SettingsPanel
        leftChild={
          <>
            <QuickFinderUserDialog
              open={showDialog}
              mutationLoading={updateDevice.isLoading}
              mutationLoadingLabel={'Updating device owner...'}
              onCompleteLabel={'Device owner updated'}
              onClear={handleRelease}
              onComplete={() => {
                updateDevice.reset();
                toggleShowDialog();
                onComplete();
              }}
              onSelect={handleSelect}
              onCancel={() => {
                updateDevice.reset();
                toggleShowDialog();
              }}
              errorMessage={
                updateDevice.error &&
                startCase(updateDevice.error.response?.data.message)
              }
              initialSelected={user ? [user?.id] : []}
              selectionTitle={user ? user.extra.fullName : null}
            />
            <Setting
              label={'Owner'}
              body={'The user who owns this device.'}
              // AppPhone/WebPhone not editable
              iconButtonProps={
                device.doc?.subtype !== 'appphone' &&
                device.doc?.subtype !== 'webphone'
                  ? showDialog
                    ? undefined
                    : {
                        children: <EditPencil fr={undefined} />,
                        onClick: toggleShowDialog,
                      }
                  : undefined
              }
              buttonProps={
                showDialog
                  ? {
                      children: 'Cancel',
                      startIcon: <Cancel fr={undefined} />,
                      color: 'neutral',
                      onClick: toggleShowDialog,
                    }
                  : undefined
              }
            />
          </>
        }
        rightChild={
          <Table>
            <TableRow showBorder={false}>
              <TableCell className={`font-medium`}>
                <DeviceOwnerCell owner={device.Owner} device={device} />
              </TableCell>
            </TableRow>
          </Table>
        }
      />
    </>
  );

  // return (
  //   <>
  //     <QuickFinderUserDialog
  //       open={showDialog}
  //       mutationLoading={updateDevice.isLoading}
  //       mutationLoadingLabel={'Updating device owner...'}
  //       onCompleteLabel={'Device owner updated'}
  //       onClear={handleRelease}
  //       onComplete={() => {
  //         updateDevice.reset();
  //         toggleShowDialog();
  //         onComplete();
  //       }}
  //       onSelect={handleSelect}
  //       onCancel={() => {
  //         updateDevice.reset();
  //         toggleShowDialog();
  //       }}
  //       errorMessage={
  //         updateDevice.error &&
  //         startCase(updateDevice.error.response?.data.message)
  //       }
  //       initialSelected={user ? [user?.id] : []}
  //       selectionTitle={user ? user.extra.fullName : null}
  //     />
  //     <div className={'flex w-full items-center justify-between'}>
  //       <DeviceOwnerCell owner={device.Owner} device={device} />
  //       <Button
  //         color={'accent'}
  //         size={'sm'}
  //         variant={'outline'}
  //         className={'h-min'}
  //         onClick={toggleShowDialog}
  //         startIcon={<PersonAddIcon />}
  //       >
  //         {device.Owner ? 'Change Owner' : 'Add Owner'}
  //       </Button>
  //     </div>
  //     {/*<Grid container spacing={1} alignItems={'center'}>*/}
  //     {/*  <Grid item>*/}
  //     {/*    <Typography*/}
  //     {/*      // @ts-ignore*/}
  //     {/*      ref={ref}*/}
  //     {/*      sx={user ? {} : { fontStyle: 'italic', color: 'text.secondary' }}*/}
  //     {/*    >*/}
  //     {/*      <HighlightPulse {...highlightPulseProps} />*/}
  //     {/*      {user ? (*/}
  //     {/*        <Link*/}
  //     {/*          to={`/admin/users/view/${user.id}`}*/}
  //     {/*          component={RouterLink}*/}
  //     {/*          underline={'hover'}*/}
  //     {/*        >*/}
  //     {/*          Owner: {user.extra.fullName}*/}
  //     {/*        </Link>*/}
  //     {/*      ) : userIsLoading ? (*/}
  //     {/*        'Loading...'*/}
  //     {/*      ) : device.doc.owner_id ? (*/}
  //     {/*        // if orphaned (owner ID set, user does not exists)*/}
  //     {/*        <Grid container columnSpacing={1}>*/}
  //     {/*          <Grid item>*/}
  //     {/*            <Chip*/}
  //     {/*              color={'error'}*/}
  //     {/*              variant={'contained'}*/}
  //     {/*              size={'small'}*/}
  //     {/*              label={'Orphaned'}*/}
  //     {/*            />*/}
  //     {/*          </Grid>*/}
  //     {/*          <Grid item>*/}
  //     {/*            <InfoTooltip title={t('device.orphan.tooltip')} />*/}
  //     {/*          </Grid>*/}
  //     {/*        </Grid>*/}
  //     {/*      ) : (*/}
  //     {/*        <Grid container columnSpacing={1}>*/}
  //     {/*          <Grid item>*/}
  //     {/*            <Chip*/}
  //     {/*              color={'primary'}*/}
  //     {/*              variant={'contained'}*/}
  //     {/*              size={'small'}*/}
  //     {/*              label={'In Pool'}*/}
  //     {/*            />*/}
  //     {/*          </Grid>*/}
  //     {/*          <Grid item>*/}
  //     {/*            <InfoTooltip title={t('device.pool.tooltip')} />*/}
  //     {/*          </Grid>*/}
  //     {/*        </Grid>*/}
  //     {/*      )}*/}
  //     {/*    </Typography>*/}
  //     {/*  </Grid>*/}
  //
  //     {/*  <Grid item>*/}
  //     {/*    <IconButton size={'small'} onClick={toggleShowDialog}>*/}
  //     {/*      {updateDevice.isLoading ? (*/}
  //     {/*        <CircularProgress size={18} />*/}
  //     {/*      ) : (*/}
  //     {/*        <EditIcon fontSize={'small'} />*/}
  //     {/*      )}*/}
  //     {/*    </IconButton>*/}
  //     {/*  </Grid>*/}
  //     {/*  {device.doc.owner_id && device.doc.device_type === 'sip_device' ? (*/}
  //     {/*    <Grid item>*/}
  //     {/*      <Button onClick={handleRelease}>Release to Device Pool</Button>*/}
  //     {/*    </Grid>*/}
  //     {/*  ) : null}*/}
  //     {/*</Grid>*/}
  //   </>
  // );

  // return (
  //   <>
  //     <GenericMutationDialog
  //       open={showDialog}
  //       onClose={toggleShowDialog}
  //       onComplete={handleComplete}
  //       onCancel={toggleShowDialog}
  //       onSubmit={handleSubmit(onSubmit)}
  //       onSuccessLabel={'Device owner updated'}
  //       isLoadingLabel={'Updating device owner'}
  //       title={'Edit Device Owner'}
  //       mutation={updateDevice}
  //       formMethods={formMethods}
  //       size={'xs'}
  //     >
  //       <HookFormUserSelect
  //         fullWidth
  //         name={'owner_id'}
  //         label={'Device Owner'}
  //       />
  //     </GenericMutationDialog>
  //     {/* @ts-ignore */}
  //     <Grid container spacing={1} alignItems={'center'}>
  //       <Grid item></Grid>
  //       {updateDevice.isLoading || userIsLoading ? (
  //         <Grid item>{updateDevice.isLoading ? 'Updating...' : ''}</Grid>
  //       ) : (
  //         <>
  //           <Grid item>
  //             <IconButton size={'small'} onClick={toggleShowDialog}>
  //               <EditIcon fontSize={'small'} />
  //             </IconButton>
  //           </Grid>
  //           {device.doc.owner_id && device.doc.device_type === 'sip_device' ? (
  //             <Grid item>
  //               <Button onClick={handleRelease}>Release to Device Pool</Button>
  //             </Grid>
  //           ) : null}
  //         </>
  //       )}
  //     </Grid>
  //   </>
  // );
};

export default DeviceOwner;

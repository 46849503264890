import { useState, useEffect, useRef, useCallback } from 'react';

// first tone context
const audioCtx1 = new window.AudioContext();

// second tone context
const audioCtx2 = new window.AudioContext();

// map tones to key input
const FrequencyDictionary = {
  1: [1209, 697],
  2: [1336, 697],
  3: [1477, 697],
  4: [1209, 770],
  5: [1336, 770],
  6: [1477, 770],
  7: [1209, 852],
  8: [1336, 852],
  9: [1477, 852],
  0: [1209, 941],
  '*': [1336, 941],
  '#': [1477, 941],
};

const useKeypadSounds = function useSoundWaves() {
  const _useState = useState<any>(false),
    isPlaying = _useState[0],
    setIsPlaying = _useState[1];

  const _useState2 = useState<any>(),
    error = _useState2[0],
    setError = _useState2[1];

  const _useState3 = useState<any>(),
    currentSound = _useState3[0],
    setCurrentSound = _useState3[1];

  useEffect(function () {
    if (!audioCtx1 || !audioCtx2) {
      setError(new Error('No AudioContext is available.'));
    }
  }, []);
  const oscillatorRef1 = useRef<any>();
  const oscillatorRef2 = useRef<any>();
  const stop = useCallback(function () {
    setIsPlaying(false);
    setCurrentSound(undefined);

    // stop first tone
    if (oscillatorRef1.current !== undefined) {
      oscillatorRef1.current.stop();
      oscillatorRef1.current = undefined;
    }

    // stop second tone
    if (oscillatorRef2.current) {
      oscillatorRef2.current.stop();
      oscillatorRef2.current = undefined;
    }
  }, []);
  const play = useCallback(
    function (params) {
      if (!audioCtx1 || !audioCtx2) {
        return;
      }

      if (isPlaying) {
        stop();
      }

      let volume = params.volume ?? 0.1;
      if (volume > 1) {
        volume = 1;
      } else if (volume < 0) {
        volume = 0;
      }

      // get frequencies based off input
      const [freq1, freq2] = FrequencyDictionary[params.key];

      // play first tone
      const gainNode1 = audioCtx1.createGain();
      gainNode1.gain.value = volume;
      gainNode1.connect(audioCtx1.destination);
      oscillatorRef1.current = audioCtx1.createOscillator();
      oscillatorRef1.current.type = params.type || 'sine';
      oscillatorRef1.current.frequency.setValueAtTime(
        freq1,
        audioCtx1.currentTime,
      );
      oscillatorRef1.current.connect(gainNode1);
      oscillatorRef1.current.start();

      // play second tone
      const gainNode2 = audioCtx2.createGain();
      gainNode2.gain.value = volume; // 10 %
      gainNode2.connect(audioCtx2.destination);
      oscillatorRef2.current = audioCtx2.createOscillator();
      oscillatorRef2.current.type = params.type || 'sine';
      oscillatorRef2.current.frequency.setValueAtTime(
        freq2,
        audioCtx2.currentTime,
      );
      oscillatorRef2.current.connect(gainNode2);
      oscillatorRef2.current.start();

      setIsPlaying(true);
      setCurrentSound(params);
    },
    [stop, isPlaying],
  );
  return {
    isPlaying: isPlaying,
    currentSound: currentSound,
    oscillator: oscillatorRef1.current,
    play: play,
    stop: stop,
    error: error,
  };
};

export default useKeypadSounds;

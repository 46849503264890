import { Box, Grid, Typography } from 'app/design';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MediaType } from 'types/media';

const UploadAudio = ({ register, setValue }) => {
  const [filename, setFilename] = useState();
  const [error, setError] = useState('');
  useEffect(() => register(`${MediaType.Upload}.file`), [register]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    // check if valid files
    setError('');
    fileRejections.forEach(file => {
      console.log('file', file);
      file.errors.forEach(err => {
        if (err.code === 'file-too-large') {
          setError(`Error: file too large. cannot exceed 800kb`);
          return;
        }
      });
    });

    const file = acceptedFiles?.[0];
    setValue(`${MediaType.Upload}.file`, file);
    setFilename(file?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: ['audio/wav', 'audio/mp3', 'audio/mp4', 'audio/mpeg'],
    maxSize: 790000, // bytes?
    // TODO max size for upload
  });

  return (
    <div
      {...getRootProps()}
      className={
        'cursor-pointer text-content/50 text-md border border-border-neutral rounded p-4 w-96 text-center font-bold'
      }
    >
      {isDragActive ? (
        <p>Drop the file here ...</p>
      ) : (
        filename ?? (
          <p>Drag 'n' drop or click to select files (wav, mp3, mp4)</p>
        )
      )}
      <input {...getInputProps()} />
      {error ? <Typography color={'error'}>{error}</Typography> : null}
    </div>
  );
};

export default UploadAudio;

import React, { memo, useState, useEffect, useLayoutEffect } from 'react';
import {
  Handle,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';
// import useStyles from './styles';

// import { strToColorHex } from '@KazooSDK/utils';

import useComponentSize from '@rehooks/component-size';

import Color from 'color';
import randomcolor from 'randomcolor';

const InTemplate = props => {
  // console.log(('Template', props.template));
  let color = Color(
    randomcolor({
      seed: props.template?.templateId ?? 'unknown',
      luminosity: 'light',
      alpha: '0.1',
    }),
  ).lightness(90);
  return (
    <div
      style={{
        position: 'absolute',
        top: -4,
        left: -4,
        width: 'calc(100% + 8px)',
        height: 'calc(100% + 8px)',
        zIndex: -1,
        background: color,
        boxShadow: `0px 0px 15px 5px ${color}`,
      }}
    >
      &nbsp;
    </div>
  );
};

export default InTemplate;

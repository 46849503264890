import env from './env';
import nav from './nav';
import PLANS from './plans';
import findInPage from 'app/constants/findInPage';

const config = {
  env,
  nav,
  findInPage,
  PLANS,
};

export default config;

import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { ChangeOwnerBulkDevices } from 'app/components/ListDevices/components/BulkActionsDevices/components/ChangeOwnerBulkDevices';
import { DeleteBulkDevices } from './components/DeleteBulkDevices';
import * as React from 'react';
import { useState } from 'react';
import { Device } from 'types/device';
import { useImmer } from 'use-immer';

interface BulkActionsProps {
  selectedRows?: Device[];
  onComplete: () => void;
  toggleSelect?: (set?: boolean) => void;
  toggleRowSelected?: (id: string, set: boolean) => void;
  vertical?: boolean;
}

const BulkActionsDevices = ({
  selectedRows: selectedDevices,
  toggleSelect,
  toggleRowSelected,
  onComplete,
  vertical = false,
}: BulkActionsProps) => {
  const [showDialog, setShowDialog] = useImmer<null | string>(null);
  const [menuItems] = useState(() => [
    {
      text: 'Change Owner',

      onClick: async e => {
        setShowDialog('owner');
      },
    },
    {
      text: 'Release to Device Pool',

      onClick: async e => {
        setShowDialog('unassign');
      },
    },
    {
      text: 'Delete',

      onClick: async e => {
        setShowDialog('delete');
      },
    },
  ]);

  const handleCancel = () => {
    setShowDialog(null);
  };

  const handleOnComplete = () => {
    if (toggleSelect && showDialog === 'delete') toggleSelect(false);
    setShowDialog(null);
    onComplete();
  };

  return (
    <>
      <DeleteBulkDevices
        open={showDialog === 'delete'}
        selectedRows={selectedDevices}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
        toggleRowSelected={toggleRowSelected!}
      />
      <ChangeOwnerBulkDevices
        open={showDialog === 'unassign' || showDialog === 'owner'}
        selectedRows={selectedDevices}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
        unassign={showDialog === 'unassign'}
      />
      <ButtonDropdownMenu
        label="Actions"
        variant={vertical ? 'default' : 'button'}
        menuItems={menuItems}
        menuItemsDependencies={[menuItems]}
        vertical={vertical}
      />
    </>
  );
};

export default BulkActionsDevices;

import { gql } from 'graphql-request';

export default gql`
  query Extensions(
    $skip: Int
    $take: Int
    $orderBy: JSON
    $filters: JSON
    $mask: String
  ) {
    extensions(
      skip: $skip
      take: $take
      orderBy: $orderBy
      filters: $filters
      mask: $mask
    ) {
      skip
      take
      totalCount
      extensions {
        callflow {
          id
          doc
          Owner {
            id
            doc
            extra
          }
        }
        id
        number
      }
    }
  }
`;

import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import constants from 'app/constants';
import { useLocation } from 'react-router-dom';

const isHotjar = constants.env.REACT_APP_HOTJAR_SITE_ID ? true : false;

if (isHotjar) {
  hotjar.initialize(constants.env.REACT_APP_HOTJAR_SITE_ID, 6);
}

export const HotjarComponent = () => {
  const { loggedIn, auth_token_decoded } = useAuthSelector();

  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();

  useEffect(() => {
    if (isHotjar && auth_token_decoded?.owner_id && userData) {
      // console.log('Hotjar identify');
      hotjarw()?.identify(
        `${auth_token_decoded?.account_id}-${auth_token_decoded?.owner_id}`,
        {
          email: userData.doc.email || userData.doc.username,
          first_name: userData.doc.first_name,
          last_name: userData.doc.last_name,
          name: userData.extra?.fullName,
          account_id: auth_token_decoded?.account_id,
          owner_id: auth_token_decoded?.owner_id,
        },
      );
    } else {
      // console.log('Skip Hotjar identify');
    }
  }, [auth_token_decoded?.owner_id, userData]);

  let location = useLocation();
  useEffect(() => {
    // console.log('the current location is', location.pathname);
    hotjarw()?.stateChange(`${location.pathname}`);
  }, [location]);

  return null;
};

export const hotjarw = () => {
  if (hotjar.initialized()) {
    return hotjar;
  }
};

export default hotjar;

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteCallflow = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, string, unknown>(
    (callflowId: string) =>
      sdk.callflow.mutate.delete(callflowId, authState?.auth_token),
    {
      onSuccess: async () => {
        // callflows are everywhere... invalidate all the things!
        await queryClient.invalidateQueries();
      },
    },
  );
};

import { NameAndTypeCell } from 'app/components/ListAllCallflows/components/NameAndTypeCell';
import { useExtensionUsedByQuery } from 'app/hooks/general/useExtensionUseQuery';
import { orderBy } from 'lodash';
import { Box, CircularProgress, Typography } from 'app/design';

interface ExtensionInUseWarningsProps {
  // exts: string[];
  query: any;
  config?: {
    // tells whether to display as error (red) or warning (orange)
    userError?: boolean;
    vmboxError?: boolean;
    extError?: boolean;

    // append additional text to generic warning
    userAppend?: string;
    vmboxAppend?: string;
    extAppend?: string;
  };
}

const Validating = ({
  className,
  isLoading,
}: {
  className?: string;
  isLoading: boolean;
}) => {
  return (
    <div
      style={{
        paddingTop: 6,
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        color: 'gray',
        opacity: isLoading ? 1 : 0,
      }}
      // className={`italic pt-2 flex whitespace-nowrap space-x-2 items-center text-gray-400 ${className}`}
    >
      <CircularProgress size={15} variant="indeterminate" />
      <div
        // className="italic"
        style={{ fontStyle: 'italic', paddingLeft: 6 }}
      >
        checking for conflicts...
      </div>
    </div>
  );
};

const ExtensionInUseWarnings = ({
  config,
  query,
}: ExtensionInUseWarningsProps) => {
  const { isLoading, isFetching, match, extensions, vmboxes, users } = query;

  // console.log('isFetching', isFetching);

  if (!match)
    return (
      <Validating
        isLoading={isFetching}
        className={isFetching ? '' : 'opacity-0'}
      />
    );

  const {
    userError = false,
    vmboxError = false,
    extError = false,
    userAppend = '',
    vmboxAppend = '',
    extAppend = '',
  } = config ?? {};
  const error =
    (userError && !!users.length) ||
    (vmboxError && !!vmboxes.length) ||
    (extError && !!extensions.length);

  return (
    <>
      <Box
        className={'bg-background-attention-secondary'}
        sx={{
          borderWidth: 1,
          p: 2,
          mt: 2,
          borderRadius: 3,
          borderStyle: 'solid',
          borderColor: theme =>
            error ? theme.palette.error.main : theme.palette.warning.dark,
        }}
      >
        <Typography variant={'h6'} color={error ? 'error' : 'warning.dark'}>
          {(error ? 'Error' : 'Warning') + ': Extension In Use'}
        </Typography>
        {orderBy(users, 'doc.presence_id').map(user => (
          <Typography
            color={userError ? 'error' : 'warning.dark'}
          >{`${user.doc.presence_id} - used as primary extension by ${user.extra.fullName}${userAppend}`}</Typography>
        ))}
        {orderBy(extensions, 'number').map(ext => (
          <>
            <Typography
              color={extError ? 'error' : 'warning.dark'}
            >{`${ext.number} - used in existing Call Route ${extAppend}`}</Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                ml: 4,
              }}
            >
              <NameAndTypeCell
                row={{
                  original: ext.callflow,
                }}
                value={ext.callflow.doc.name}
                // onComplete={() => {}}
              />
              <span style={{ marginLeft: 2 }}>
                {` - ${
                  ext.callflow?.Owner?.extra?.fullName ??
                  ext.callflow?.Owner?.doc.name
                }`}
              </span>
            </Box>
          </>
        ))}
        {orderBy(vmboxes, 'doc.mailbox').map(vmbox => (
          <Typography
            color={vmboxError ? 'error' : 'warning.dark'}
          >{`${vmbox.doc.mailbox} - used by existing Voicemail Box${vmboxAppend}`}</Typography>
        ))}
      </Box>
      <Validating
        isLoading={isFetching}
        className={isFetching ? '' : 'opacity-0'}
      />
    </>
  );
};

export default ExtensionInUseWarnings;

import { Box } from 'app/design';
import React from 'react';
import { Transition } from 'react-transition-group';

const transitionStyles = {
  entering: { opacity: 0.2, outlineWidth: '40px' },
  entered: { opacity: 0.2, outlineWidth: '40px' },
  exiting: { opacity: 0, outlineWidth: '80px' },
  exited: { opacity: 0.2, outlineWidth: '0px' },
};

// Expected to be inside a `position: relative;` container!

const HighlightPulse = ({ in: doTransition, duration = 150 }) => {
  return (
    <Transition
      in={doTransition}
      timeout={{ appear: duration, enter: duration, exit: duration }}
      unmountOnExit
    >
      {state => (
        <Box
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '4px',
            opacity: 0,
            outline: '0px solid #0FA3B180',
            // cubic-bezier(.06,.96,.31,.85)
            transition: `
              opacity ${duration}ms linear,
              outline-width ${duration}ms linear`,
            zIndex: 10000,
            ...transitionStyles[state],
          }}
        ></Box>
      )}
    </Transition>
  );
};

export default HighlightPulse;

import { useLocalSlice } from 'app/data/local';
import {
  AnimationDialog,
  CircularProgress,
  DialogContent,
  Divider,
  Table,
  TableCell,
  TableRow,
  Typography,
} from 'app/design';
import { DialogTitle, Dialog, DialogHeader } from 'app/design-lib';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { useDeleteGroup } from 'app/hooks/mutations/group';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Group } from 'types/group';
import { useImmer } from 'use-immer';

interface DeleteProps {
  selectedRows?: Group[];
  onComplete: () => void;
  onCancel: () => void;
  toggleRowSelected: any;
}

interface DeleteBulkGroupsWrapperProps extends DeleteProps {
  open: boolean;
}
const DeleteBulkGroupsWrapper = ({
  open,
  ...props
}: DeleteBulkGroupsWrapperProps) => {
  return (
    <Dialog size={'lg'} open={open} onClose={props.onCancel}>
      <DeleteBulkGroups {...props} />
    </Dialog>
  );
};

const DeleteBulkGroups = ({
  selectedRows: selectedGroupsOrig,
  toggleRowSelected,
  onComplete,
  onCancel,
}: DeleteProps) => {
  const deleteGroup = useDeleteGroup();
  const { isLoading } = deleteGroup;
  const { actions } = useLocalSlice();
  const dispatch = useDispatch();
  const [selectedGroups] = useState(cloneDeep(selectedGroupsOrig));
  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
    }[]
  >(() => selectedGroups?.map(group => ({ status: null })) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  useEffect(() => {
    setMutationArray(selectedGroups?.map(group => ({ status: null })) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  if (!selectedGroups)
    return <Typography variant={'error'}>No group(s) selected!</Typography>;

  const handleDelete = async () => {
    // prevent invalidation from delete mutation from causing list display issues
    // this is re-enabled from onComplete of bulk actions
    dispatch(actions.queries_enabled(false));
    for (let i = 0; i < selectedGroups.length; i++) {
      if (error) break;

      const group = selectedGroups[i];

      // // group already suspended
      // if (!group.doc.enabled) {
      //   setMutationArray(prevState => {
      //     prevState[i].status = 'success';
      //   });
      //   continue;
      // }

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      try {
        const resp = await deleteGroup.mutateAsync(
          group.id,
          //   {
          //   onSuccess: () => {
          //     setMutationArray(prevState => {
          //       prevState[i].status = 'success';
          //     });
          //   },
          //   onError: error => {
          //     setMutationArray(prevState => {
          //       prevState[i].status = 'error';
          //     });
          //     setError(error);
          //   },
          // }
        );
        // toggleRowSelected(group.id, false);

        if (resp.status === 'success') {
          setMutationArray(prevState => {
            prevState[i].status = 'success';
          });
        }
      } catch (e: any) {
        setMutationArray(prevState => {
          prevState[i].status = 'error';
        });
        setError(e);
      }
    }
  };

  const handleDone = () => {
    onComplete();
    dispatch(actions.queries_enabled(true));
    deleteGroup.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <DialogHeader title={'Delete Group(s)'} onClose={onCancel} />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {deleteGroup.isSuccess
            ? `Successfully deleted group(s):`
            : deleteGroup.isLoading
            ? `Deleting group(s)...`
            : error
            ? `Error deleting group(s):`
            : `Are you sure you want to delete ${selectedGroups.length} group(s)?`}
        </Typography>
        <br />
        <div>
          <Table>
            {selectedGroups.map((group, index) => (
              <TableRow sx={{ height: 65 }}>
                <TableCell>
                  {mutationArray[index].status === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : mutationArray[index].status === 'success' ? (
                    <CheckIcon color={'success'} />
                  ) : mutationArray[index].status === 'error' ? (
                    <ClearIcon color={'error'} />
                  ) : null}
                </TableCell>
                <TableCell>
                  <Typography>{group.doc.name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </DialogContent>
      {!isLoading ? (
        <DefaultDialogActions
          saveLabel={deleteGroup.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : deleteGroup.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error
              ? undefined
              : deleteGroup.isSuccess
              ? handleDone
              : handleDelete
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

export default DeleteBulkGroupsWrapper;

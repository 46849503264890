import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogLoading,
  DialogTitle,
} from 'app/design-lib';
import { startCase } from 'lodash';
import React, { ReactNode } from 'react';
import { UseMutationResult } from 'react-query';
import CheckCircleIcon from '@mui/icons-material/Check';
import { FormProvider } from 'react-hook-form';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
export interface GenericMutationDialogProps {
  onComplete: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  onSuccessLabel: string;
  isLoadingLabel: string;
  formMethods: any;
  title: ReactNode;
  children: ReactNode;
  cancelButtonLabel?: string;
  submitButtonLabel?: string;
  submitButtonDisabled?: boolean;
  mutation: UseMutationResult<any, any, any, any>;

  queryIsLoading?: boolean;
  queryLabel?: string;
  forceSuccess?: boolean;
  forceLoading?: boolean;
  forceError?: any;
  startDirty?: boolean;
  additionalActions?: ReactNode;
  hideSubmitButton?: boolean;
  contentClassName?: string;
  successButtonLabel?: string;
}

const GenericMutationDialogContent = ({
  title,
  onComplete,
  onCancel,
  onSubmit,
  children,
  formMethods,
  onSuccessLabel,
  isLoadingLabel,
  mutation,
  submitButtonLabel = 'Update',
  submitButtonDisabled = false,
  queryIsLoading = false,
  queryLabel,
  forceSuccess = false,
  forceLoading = false,
  forceError,
  startDirty = false,
  additionalActions,
  hideSubmitButton = false,
  contentClassName = 'overflow-y-auto',
  successButtonLabel = 'Close',
}: GenericMutationDialogProps) => {
  const {
    formState: { isSubmitting, isSubmitSuccessful },
  } = formMethods;
  const isDirty = startDirty ? true : formMethods.formState.isDirty;
  const error = forceError ?? mutation.error;
  const isLoading = mutation.isLoading || forceLoading;
  const isSuccess =
    ((forceSuccess && !forceLoading) ||
      mutation.isSuccess ||
      isSubmitSuccessful) &&
    !error;

  return (
    <>
      {isLoading || isSubmitting ? (
        <DialogLoading label={isLoadingLabel} />
      ) : queryIsLoading ? (
        <DialogLoading label={queryLabel} />
      ) : (
        <>
          {isSuccess ? null : <DialogHeader onClose={onCancel} title={title} />}
          <FormProvider {...formMethods}>
            <DialogContent
              className={`${contentClassName} flex-grow pt-1.5 flex flex-col`}
            >
              {isSuccess ? (
                <div
                  className={
                    'flex items-center space-x-1 mt-6 justify-center text-lg font-medium'
                  }
                >
                  <CheckCircleIcon className={'text-green-400 text-2xl'} />
                  <div>{onSuccessLabel}</div>
                </div>
              ) : (
                <>{children}</>
              )}
            </DialogContent>
          </FormProvider>
          <DialogActions>
            <div
              className={
                'w-full min-h-16 border-border-neutral font-medium text-lg flex-row-reverse items-center flex justify-between'
              }
            >
              {hideSubmitButton ? null : (
                <Button
                  size={'md'}
                  variant={'fill'}
                  onClick={isSuccess ? onComplete : onSubmit}
                  color={isSuccess ? `accent` : 'accent'}
                  disabled={
                    forceSuccess
                      ? false
                      : isLoading || !isDirty || submitButtonDisabled
                  }
                >
                  {isSuccess
                    ? successButtonLabel
                    : !isDirty
                    ? 'No Changes'
                    : submitButtonLabel}
                </Button>
              )}
              {error ? (
                <div
                  className={'text-lg text-red-500 flex space-x-1 items-center'}
                >
                  <ErrorIcon />
                  <span>
                    {startCase(
                      error?.response?.data?.message ?? 'An Error Occurred',
                    )}
                  </span>
                </div>
              ) : null}
              {isSuccess ? null : additionalActions}

              {isSuccess ? null : (
                <Button
                  size={'md'}
                  variant={'ghost'}
                  onClick={onCancel}
                  color={'neutral'}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}
            </div>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default GenericMutationDialogContent;

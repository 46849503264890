import {
  AnimationDialog,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableCell,
  TableRow,
  Typography,
} from 'app/design';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { MediaDialog } from 'app/components/MediaDialog';
import { QuickFinderPhoneNumberDialog } from 'app/components/QuickFinderPhoneNumberDialog';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AxiosError } from 'axios';
import { startCase } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { User } from 'types/user';
import { useImmer } from 'use-immer';

interface CallerIdWrapperProps {
  open: boolean;
  type?: string;
  selectedRows?: User[];
  onComplete: () => void;
  onCancel: () => void;
}

interface CallerIdProps extends CallerIdWrapperProps {
  number?: string | null;
}

const CallerIdBulkUsersWrapper = (props: CallerIdProps) => {
  return (
    <AnimationDialog fullWidth maxWidth={'sm'} open={props.open}>
      <CallerIdBulkUsers {...props} />
    </AnimationDialog>
  );
};

const CallerIdBulkUsersContent = ({
  number,
  type = 'external',
  selectedRows: selectedUsers,
  onComplete,
  onCancel,
}: CallerIdProps) => {
  const updateUser = useUpdateUserPartial();
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const { isLoading } = updateUser;
  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
    }[]
  >(() => selectedUsers?.map(user => ({ status: null })) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  useEffect(() => {
    setMutationArray(selectedUsers?.map(user => ({ status: null })) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  if (!selectedUsers)
    return <Typography variant={'error'}>No users selected!</Typography>;

  const handleSuspend = async () => {
    for (let i = 0; i < selectedUsers.length; i++) {
      if (error) break;

      const user = selectedUsers[i];

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      await updateUser.mutateAsync(
        {
          id: user.id,
          caller_id: {
            [type]: {
              number,
            },
          },
        },
        {
          onSuccess: () => {
            setMutationArray(prevState => {
              prevState[i].status = 'success';
            });
          },
          onError: error => {
            setMutationArray(prevState => {
              prevState[i].status = 'error';
            });
            setError(error);
          },
        },
      );
    }
  };

  const handleDone = () => {
    onComplete();
    updateUser.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <DialogTitle>{`Update User(s) ${startCase(
            type,
          )} Caller ID:`}</DialogTitle>
          <Divider />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {updateUser.isSuccess
            ? `Successfully updated user(s) ${type} caller ID:`
            : updateUser.isLoading
            ? `Updating user(s)...`
            : error
            ? `Error updating user(s) ${type} caller ID:`
            : `Are you sure you want to update ${selectedUsers.length} user(s) ${type} caller ID?`}
        </Typography>
        <br />
        <div>
          <Table>
            {selectedUsers.map((user, index) => (
              <TableRow sx={{ height: 65 }}>
                <TableCell>
                  {mutationArray[index].status === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : mutationArray[index].status === 'success' ? (
                    <CheckIcon color={'success'} />
                  ) : mutationArray[index].status === 'error' ? (
                    <ClearIcon color={'error'} />
                  ) : null}
                </TableCell>
                <TableCell>
                  <Typography>
                    {`${user.doc.first_name} ${user.doc.last_name}`}
                  </Typography>
                </TableCell>
                <TableCell>
                  {user.doc.priv_level === 'admin' ? (
                    <Chip label={'Admin'} color={'primary'} size={'small'} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </DialogContent>
      <Divider />
      {!isLoading ? (
        <DefaultDialogActions
          saveLabel={updateUser.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : updateUser.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error
              ? undefined
              : updateUser.isSuccess
              ? handleDone
              : handleSuspend
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

const CallerIdBulkUsers = (props: CallerIdWrapperProps) => {
  const { selectedRows: selectedUsers, onCancel, open } = props;
  const [number, setNumber] = useImmer<string | null | undefined>(undefined);

  const handleSelect = (selected: string[]) => {
    const [number] = selected;
    setNumber(number);
  };

  return (
    <>
      <QuickFinderPhoneNumberDialog
        // allow null so users can unset
        open={number === undefined}
        onSelect={handleSelect}
        onCancel={onCancel}
        contentOnly
      />
      {!!number && (
        <CallerIdBulkUsersContent
          {...props}
          open={open}
          number={number!}
          onCancel={() => setNumber(undefined)}
        />
      )}
    </>
  );
};

export default CallerIdBulkUsersWrapper;

import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildDeleteDefault,
  buildPatchDefault,
  buildPutDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    contactLists: (vars, fetchOptions) =>
      fetchFromGraphql('contactLists', vars, fetchOptions),

    // // Fetch a single directory by id
    // groupById: (vars, fetchOptions) =>
    //   fetchFromGraphql('groupById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    // create: buildPutDefault('groups', { buildUrlOpts: { skipId: true } }),
    // updatePartial: buildPatchDefault('groups'),
    // delete: buildDeleteDefault('groups'),
    // createDev: buildPutDefault('groups', {
    //   buildUrlOpts: { skipId: true, skipInternalSync: true },
    // }),
  },
};

export default exports;

import React from 'react';
// import "./chip.css";
import { styled } from '@mui/material/styles';
import {
  Card as MuiCard,
  CardProps as MuiCardProps,
  Grow,
} from '@mui/material';

export interface CardProps extends MuiCardProps {}

const Card = React.forwardRef((props: any, ref) => {
  const { grow, ...rest } = props;
  if (grow) {
    const growProps = grow === true ? {} : grow;
    return (
      <Grow in {...growProps}>
        <MuiCard ref={ref} {...rest} />
      </Grow>
    );
  }
  return <MuiCard ref={ref} {...rest} />;
});

export default Card;

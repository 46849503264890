import { useAuthSelector } from 'app/data/auth';
import { randomString } from 'app/utilities';

import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';

import { useEffectOnce } from 'react-use';
import { RenderScreenpop } from './Render';

const Screenpops = props => {
  const { call, onUpdate, defaultScreenpops } = props;

  const { owner_id } = useAuthSelector();
  //   const { authUser } = useSetupHook();

  // TODO: get all screenpops
  const allScreenpops = [];

  // screenpops: [{
  //   id: '', // duplicates ignored
  //   loading: true | false,
  //   loaded: true | false,
  //   response: {}
  // }]
  const [screenpops, setScreenpops] = useState(defaultScreenpops || []);

  useEffect(() => {
    onUpdate && onUpdate([...screenpops]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenpops]);

  const allCallVars = {
    user_id: owner_id,
    caller_name: call.remoteName,
    caller_number: call.remoteUser,
  };

  const replaceCallVarsDefault = url => {
    Object.keys(allCallVars).forEach(key => {
      // eslint-disable-next-line no-useless-escape
      url = url.replace(new RegExp(`\{\{${key}\}\}`, 'g'), allCallVars[key]);
      // url = url.replaceAll(`{{${key}}}`, allCallVars[key]);
    });
    return url;
  };

  // fetch screenpop for call
  useEffectOnce(() => {
    // could use a useEffect along w/ a ref, instead of useEffectOnce?
    // - make sure the screenpop gets executed!
    if (!call?.isRinging()) {
      // TODO: check for "incoming" vs "outgoing" calls here
      return;
    }

    // determine if a screenpop is needed
    // - check my user, groups I belong to, and screenpop.all_users=true
    const screenpopsToUse = allScreenpops.filter(
      sp => sp.doc.screenpop?.active && sp.doc.screenpop?.all_users,
    );
    // console.log('screenpopsToUse:', screenpopsToUse, allScreenpops);

    // TODO: get user, group (only doing screenpop.all_users currently!)

    // for each screenpop, determine the type, do the thing
    for (let screenpop of screenpopsToUse) {
      processScreenpop(
        screenpop.doc,
        replaceCallVarsDefault,
        allCallVars,
        setScreenpops,
      );
    }
  });

  if (!screenpops.length) {
    return null;
  }

  // only showing "loaded" screenpops
  if (!screenpops.find(sp => sp.loaded)) {
    return null;
  }

  return (
    <div>
      {screenpops
        .filter(sp => sp.loaded && !sp.error)
        .map((sp, i) => {
          return (
            <div key={i} style={{ marginTop: 2 }}>
              <RenderScreenpop screenpop={sp} />
            </div>
          );
        })}
    </div>
  );
};

export const processScreenpop = async (
  screenpop, // this is the CALLFLOW doc! ...callflow.screenpop.type = 'xyz'
  replaceCallVars,
  allCallVars,
  setScreenpops,
) => {
  console.log('processing screenpop:', screenpop);
  switch (screenpop.screenpop.type) {
    case 'url':
      let url = screenpop.screenpop.data.url;
      url = replaceCallVars(url);
      const bodyData = allCallVars;
      setScreenpops &&
        setScreenpops(sps => [
          ...sps,
          {
            id: screenpop.id, // callflow id!
            loading: true,
            loaded: false,
            response: null,
          },
        ]);

      let response, data;
      try {
        response = await fetch(url, {
          method: screenpop.screenpop.data.method ?? 'GET', // TODO: more than GET
          headers: {
            'Content-Type': 'application/json',
          },
          body:
            screenpop.screenpop.data.method === 'POST'
              ? JSON.stringify(bodyData)
              : undefined, // TODO: use for POST
        });
        console.log('response:', response);
        data = await response.json();
      } catch (err) {
        setScreenpops([
          {
            id: randomString(6),
            loading: false,
            loaded: true,
            error: {
              message: `Failed loading screenpop: ${err.toString()} ${response}`,
            },
            response: null,
          },
        ]);
        return;
      }

      //  .then((data) => {
      // console.log('Success w/ screenpop data:', data);

      // expecting either an object or an array of objects to be returned
      // - modify "loading" screenpop in-place, and add additional screenpops if an array was returned
      const dataArr = isArray(data) ? [...data] : [data];
      // console.log('dataArr:', dataArr);
      for (let i in dataArr) {
        const spData = dataArr[i];

        // validate screenpop result
        const validated = validateScreenpop(spData);

        // console.log('i:', i);
        // modify existing screenpop data for 1st, add for additional
        setScreenpops &&
          setScreenpops(sps => {
            return i === 0
              ? [
                  ...sps.map(sp =>
                    sp.id === screenpop.id
                      ? {
                          id: sp.id,
                          loading: false,
                          loaded: true,
                          error: validated !== true ? validated : false,
                          response: spData,
                        }
                      : sp,
                  ),
                ]
              : [
                  ...sps,
                  {
                    id: `${screenpop.id}-${i}`,
                    loading: false,
                    loaded: true,
                    error: validated !== true ? validated : false,
                    response: spData,
                  },
                ];
          });
      }

      //  })
      //  .catch((error) => {
      //    console.error('Error:', error);
      //  });
      break;

    default:
      console.error('unknown screenpop type:', screenpop.screenpop.type);
      break;
  }
};

export const validateScreenpop = screenpopResponseData => {
  if (screenpopResponseData?.version !== '1.0') {
    return {
      message: 'Invalid Version',
    };
  }
  // return { message: 'Failure test' };
  // TODO: validate schema of response

  return true;
};

export default Screenpops;

import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    directories: (vars, fetchOptions) =>
      fetchFromGraphql('directories', vars, fetchOptions),

    // Fetch a single directory by id
    directoryById: (vars, fetchOptions) =>
      fetchFromGraphql('directoryById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    create: buildPutDefault('directories', { buildUrlOpts: { skipId: true } }),
    updatePartial: buildPatchDefault('directories'),
    delete: buildDeleteDefault('directories'),
  },
};

export default exports;

import * as React from 'react';
import { Chip, Link, Tooltip } from 'app/design';
import { FeatureCodesDrawer } from 'app/components/FeatureCodesDrawer';
import { useToggleReducer } from 'app/utilities';

// only feature codes without leading * are needed
const featureCodes = ['6683'];
const isFeatureCode = (number: string) => {
  return featureCodes.includes(number);
};
export const FeatureCodeBadge = ({ ext }) => {
  const [showFeatureCodes, toggleFeatureCodes] = useToggleReducer();

  if (!isFeatureCode(ext)) return null;

  return (
    <>
      {showFeatureCodes ? (
        <FeatureCodesDrawer onClose={() => toggleFeatureCodes()} />
      ) : null}
      <Tooltip
        arrow
        title={
          <>
            This extension is a feature code. See{' '}
            <Link onClick={toggleFeatureCodes}>feature codes</Link> for details.
          </>
        }
      >
        <Chip size={'small'} color={'primary'} label={'Feature Code'} />
      </Tooltip>
    </>
  );
};

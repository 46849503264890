import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogProps,
} from 'app/design-lib';
import AudioEditor from 'app/components/AudioEditor/AudioEditor';

interface GreetingDialogProps extends Omit<DialogProps, 'children'> {
  greeting_id?: string;
  onComplete: (greeting_id: string) => void;
}

export const GreetingDialog = ({
  open,
  onClose,
  greeting_id,
  onComplete,
}: GreetingDialogProps) => {
  return (
    <Dialog size={'2xl'} open={open} onClose={onClose}>
      <GreetingContent
        greeting_id={greeting_id}
        onClose={onClose}
        onComplete={onComplete}
      />
    </Dialog>
  );
};

const GreetingContent = ({ greeting_id, onComplete, onClose }) => {
  return (
    <>
      <DialogHeader title={'Menu Greeting'} onClose={onClose} />
      <DialogContent className={`overflow-y-visible`}>
        <AudioEditor
          hideCancel
          mediaName={`menu_greeting_${Date.now()}`}
          mediaId={greeting_id}
          onComplete={onComplete}
          subType={'menu-greeting'}
          onClose={onClose}
          hasPlatformDefault={false}
        />
      </DialogContent>
    </>
  );
};

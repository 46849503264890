import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogProps,
  TextField,
} from 'app/design-lib';
import AudioEditor from 'app/components/AudioEditor/AudioEditor';
import { useState } from 'react';

interface GoToInFlowDialogProps extends Omit<DialogProps, 'children'> {
  text?: string;
  onComplete: (text: string) => void;
}

export const GoToInFlowDialog = ({
  open,
  onClose,
  text,
  onComplete,
}: GoToInFlowDialogProps) => {
  return (
    <Dialog open={open} size={'md'} onClose={onClose}>
      <MessageContent text={text} onComplete={onComplete} onClose={onClose} />
    </Dialog>
  );
};

const MessageContent = ({ text = '', onComplete, onClose }) => {
  const [message, setMessage] = useState(text);
  const handleUpdate = () => {
    onComplete(message);
  };
  return (
    <>
      <DialogHeader title={'Send message'} onClose={onClose} />
      <DialogContent className={`overflow-y-visible`}>
        <TextField
          autoFocus
          value={message}
          onChange={e => setMessage(e.target.value)}
          // label={'message'}
          multiline
          placeholder={'text content...'}
        />
      </DialogContent>
      <DialogActions className={`w-full flex justify-between`}>
        <Button size={'md'} color={'negative'} onClick={onClose}>
          Cancel
        </Button>
        <Button size={'md'} color={'accent'} onClick={handleUpdate}>
          Update message
        </Button>
      </DialogActions>
    </>
  );
};

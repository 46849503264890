import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { MediasPageResponse } from 'types/media';
import { Vmbox } from 'types/vmbox';
import mediaQueryKeys from './mediaQueryKeys';

interface QueryParams {
  id?: string;
  vmboxId?: string;
  updatedAt?: string;
  type?: 'VMBOX_MESSAGE';
}

export const useMediaUrl = ({ id, updatedAt, type, vmboxId }: QueryParams) => {
  const authState = useAuthSelector();

  // if built in - id is link
  if (
    id?.indexOf('http') === 0 ||
    id?.indexOf('data') === 0 ||
    id?.indexOf('blob') === 0
  ) {
    return id;
  }

  if (type === 'VMBOX_MESSAGE') {
    return sdk.vmboxMessages.query.messageUrl(
      { id, vmboxId },
      { authToken: authState.auth_token },
    );
  }

  return sdk.media.query.url(
    { id, updatedAt },
    { authToken: authState.auth_token },
  );
};

export default useMediaUrl;

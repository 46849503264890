/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';

import { App } from 'app';
import constants from 'app/constants';

import { HelmetProvider } from 'react-helmet-async';

import { store } from 'store';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import 'styles/tailwind.generated.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

// TODO: setup Sentry for Redux and Router
// - https://docs.sentry.io/platforms/javascript/guides/react/
Sentry.init({
  dsn: constants.env.REACT_APP_SENTRY_DSN,
  integrations: [
    // new BrowserTracing() // cant use this until we fix the sentry-trace CORS errors in our API
    // @ts-ignore
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 10,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // debug: true,
  tracesSampleRate: 0.1,
  enabled: process.env.NODE_ENV !== 'development',
});

// console.log(
//   'SENTRY_DSN:',
//   constants.env.REACT_APP_SENTRY_DSN ?? 'not enabled, no DSN',
// );

// export const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

// let persistor = persistStore(store);

if (process.env.NODE_ENV === 'development') {
  ReactDOM.render(
    <Provider store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </HelmetProvider>
    </Provider>,
    MOUNT_NODE,
  );
} else {
  ReactDOM.render(
    <Sentry.ErrorBoundary
      fallback={
        <p>
          We're so sorry, but an error has occurred that we were not expecting.
          Please refresh the page and retry what you were doing. We've notified
          staff about this issue!
        </p>
      }
    >
      <Provider store={store}>
        <HelmetProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </HelmetProvider>
      </Provider>
    </Sentry.ErrorBoundary>,
    MOUNT_NODE,
  );
}

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Device } from 'types/device';
import { useMediaQuery } from 'app/design';

const QuickFinderDeviceDialog = ({
  queryFilters,
  ...props
}: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);
  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: (device: Device) => device.doc.name,
        },
        {
          Header: 'Owner',
          accessor: (device: Device) => device.Owner,
          Cell: ({ row, value: owner }) => {
            const { original: device }: { original: Device } = row;

            return <DeviceOwnerCell device={device} owner={owner} />;
          },
        },
        ,
      ].concat(
        isMobile
          ? []
          : [
              {
                Header: 'Type',
                accessor: (device: Device) =>
                  startCase(device.doc.device_type?.replace('_', ' ')),
              },
              {
                Header: 'Ext(s)',
                Cell: ({ row }) => {
                  const { original: device }: { original: Device } = row;

                  const mainCallflowNumbers = device.Callflows?.find(
                    cf => cf.doc.type === 'main',
                  )?.doc.numbers;

                  const { extensions } = mainCallflowNumbers
                    ? parseCallflowNumbers(mainCallflowNumbers)
                    : { extensions: [] };

                  return <ExtensionsTableCell extensions={extensions} />;
                },
                // @ts-ignore
                disableSortBy: true,
              },
            ],
      ),
    [],
  );

  // device list
  const deviceListQuery = useListDevicesQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['name', 'asc']],
    filters: { ...queryFilters, searchInput: querySearchInput },
  });
  const { devices, totalCount = 0 } = deviceListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      deviceListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  // const handleCreate = (id: string) => {
  // TODO: does not work with passing resource atm
  // onSelect([id]);
  // };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Devices' : 'Select Device'}
      // table
      getRowId={(row: Device) => row.id}
      searchPlaceholder={'Search Devices...'}
      totalCount={totalCount}
      columns={columns}
      data={devices ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={deviceListQuery.isFetching}
      queryIsLoading={deviceListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderDeviceDialog;

import { Card, CardContent, CardHeader, Typography } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { HoldMusicAccount } from 'app/pages/admin/Dashboard/components/HoldMusicAccount';
import { NameAccount } from 'app/pages/admin/Dashboard/components/NameAccount';
import { OperatorExtensionAccount } from 'app/pages/admin/Dashboard/components/OperatorExtensionAccount';
import { TimezoneAccount } from 'app/pages/admin/Dashboard/components/TimezoneAccount';
import * as React from 'react';
import { Account } from 'types/account';

interface SettingsCardProps {
  account: Account;
  onComplete: () => void;
}

const SettingsCard = ({ account, onComplete }: SettingsCardProps) => {
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'settings',
    options: [
      {
        label: 'Settings',
        value: 'settings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  return (
    <>
      {/* // @ts-ignore
    <Card ref={ref} variant="outlined" sx={{ height: '100%' }}> */}
      {/* <HighlightPulse {...highlightPulseProps} />
      <CardHeader title="Account Settings" /> */}
      {/* <CardContent> */}
      {/* Timezone */}
      {/* Name */}
      <NameAccount account={account} onComplete={onComplete} />

      <TimezoneAccount account={account} onComplete={onComplete} />

      {/* Hold Music */}
      <HoldMusicAccount account={account} onComplete={onComplete} />

      {/* Operator ext */}
      {/* <OperatorExtensionAccount account={account} onComplete={onComplete} />
      <br /> */}
      <span className={'text-sm text-neutral-60'}>ID: {account?.doc?.id}</span>
      {/* </CardContent> */}
      {/* </Card> */}
    </>
  );
};

export default SettingsCard;

import { useEditContactDialog } from 'app/components/EditContactDialog';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { RouterLink } from 'app/components/RouterLink';
import { NotificationBadge } from 'app/components/Webphone/UI/ContactsView/components/CombinedContacts/CombinedContacts';
import { Box, Grid, Link, Typography } from 'app/design';
import React, { useState } from 'react';

interface MaybeContactDisplayProps {
  maybeContact: { name: string; number: string };
  notification?: boolean;
}

const MaybeContactDisplay = ({
  maybeContact: { name, number },
  notification,
}: MaybeContactDisplayProps) => {
  const [showLink, setShowLink] = useState(false);
  const {
    Dialog: EditContactDialog,
    DialogProps: EditContactDialogProps,
    toggleOpen: toggleOpenEditContactDialog,
  } = useEditContactDialog();

  return (
    <div>
      <EditContactDialog
        {...EditContactDialogProps}
        initialNumber={number}
        initialName={name}
        onComplete={toggleOpenEditContactDialog}
      />
      <Grid
        container
        sx={{ minWidth: 0 }}
        alignItems="center"
        columnSpacing={2}
        wrap={'nowrap'}
      >
        <Grid item>
          <AvatarCell
            resource={{
              doc: {
                first_name: '?',
                // last_name: contact.info.lastName,
                // company: contact.info.company,
              },
            }}
            size={40}
          />
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <div className={'flex space-x-2 items-center'}>
            <Typography
              sx={{
                // color: theme => theme.palette.content.color,
                fontSize: '16px',
                display: 'inline-block',
                fontWeight: 700,
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </Typography>
            {/*<NotificationBadge />*/}
          </div>
          <Box
            onMouseEnter={() => setShowLink(true)}
            onMouseLeave={() => setShowLink(false)}
            sx={{ mt: -1 }}
          >
            {showLink ? (
              <Link onClick={toggleOpenEditContactDialog}>add contact</Link>
            ) : (
              <Typography sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                maybe
              </Typography>
            )}
          </Box>
          <PhoneNumberDisplay ptn={number} />
        </Grid>
        <Grid item sx={{ textAlign: 'center', width: '100%' }}></Grid>
      </Grid>
    </div>
  );
};

export default MaybeContactDisplay;

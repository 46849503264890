import { getResourceDefault } from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  query: {
    available: fetchOptions => {
      return getResourceDefault({ url: '/websockets' });
    },
  },
};

export default exports;

import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from 'app/design';
import { Search as SearchIcon } from 'app/design/icons-material';
import { cloneDeep } from 'lodash';
import React, { useState, useRef } from 'react';
import { ViewContainer } from '../ViewContainer';
import { MicSetting } from './MicSetting';
import { SpeakerSetting } from './SpeakerSetting';
import { KeypadSetting } from './KeypadSetting';
import { useWebphoneContext } from '../../Store';
import { useMeasure, useInterval } from 'react-use';
import { SquareProgress } from '../../../../design-lib';

// this list gets copied/modified to improve searchability/filterability

const SettingsView = props => {
  const { show } = props;
  const [{ sip }] = useWebphoneContext();

  const BASE_SETTINGS_LIST = [
    {
      component: MicSetting,
    },
    {
      component: SpeakerSetting,
    },
    {
      component: KeypadSetting,
    },
    // {
    //   component: CameraSetting,
    // },
    // {
    //   component: RingSetting,
    // },
    // {
    //   component: ConferenceSetting,
    // },
  ];

  // determine which settings match (and so will be displayed)
  // const anyMatch = () => {
  //   // run matchsorter against each subcomponent in array
  //   for (let item of BASE_SETTINGS_LIST) {
  //   }
  //   // run matchsorter against the output list
  //   // - for ones that we already want to include (childrenMatched) we make sure they are added/included
  //   const settingsList = matchSorter(fullSettingsList, filterText, {
  //     keys: ['baseSearch', 'addedSearch'],
  //   });
  // };

  const [fullSettingsList, setFullSettingsList] = useState(
    cloneDeep(BASE_SETTINGS_LIST),
  );
  const [filterText, setFilterText] = useState('');
  // const [showSettings, setShowSettings] = useState(SETTINGS_LIST);

  // useEffect(() => {
  //   const settingsList = matchSorter(fullSettingsList, filterText, {
  //     keys: ['baseSearch', 'addedSearch'],
  //   });
  //   setShowSettings([...settingsList]);
  // }, [filterText, fullSettingsList]);

  const handleChildrenFilterMatching = index => matching => {
    setFullSettingsList(list => {
      list[index].matching = matching;
      return [...list];
    });
  };

  // any children match?
  const anyChildrenMatch = fullSettingsList.find(setting => setting.matching);

  // const [ref, position] = useMeasure();
  const ref = useRef(null);
  const [maxHeight, setMaxHeight] = useState(1);
  // TODO: only run this when dragging, or when resizing (element, window)?
  useInterval(() => {
    // console.log('iii');
    if (ref.current && show) {
      let v = ref.current.getBoundingClientRect();
      // console.log('v:', window.innerHeight, v.top);
      const val = window.innerHeight - v.top - 8;
      if (maxHeight != val) {
        setMaxHeight(val);
      }
    }
  }, 16);

  // const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  // console.log('position', position);

  if (!show) return null;

  if (!sip)
    return (
      <div className={'h-full py-20 w-full grid place-items-center'}>
        <div
          className={`flex font-medium text-md flex-col space-y-2 items-center`}
        >
          <SquareProgress />
          <div>Loading settings...</div>
        </div>
      </div>
    );

  return (
    <div className={`flex flex-col p-4 space-y-3`}>
      <div className={`text-md font-medium`}>Audio</div>
      <MicSetting />
      <SpeakerSetting />
      <div className={`border-b-2 border-neutral-20 `} />
      <div className={`text-md font-medium`}>Keypad</div>
      <KeypadSetting />
    </div>
  );

  return (
    <Box
      sx={{
        display: show ? 'flex' : 'none',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: '0 1 auto' }}>
        <ViewContainer
          title={'Settings'}
          show={show}
          // searchComponent={
          //   <TextField
          //     value={filterText}
          //     onChange={e => setFilterText(e.target.value)}
          //     placeholder="Filter Settings"
          //     variant="outlined"
          //     fullWidth
          //     size="small"
          //     style={{ margin: 0 }}
          //     InputProps={{
          //       startAdornment: (
          //         <InputAdornment position="start">
          //           <SearchIcon style={{ transform: 'scaleX(-1)' }} />
          //         </InputAdornment>
          //       ),
          //     }}
          //   />
          // }
        />
      </Box>
      <Divider />
      <div className={''}>
        <div
          ref={ref}
          className={'overflow-y-auto'}
          style={{ maxHeight: maxHeight + 'px' }}
        >
          {!anyChildrenMatch ? (
            <div style={{ padding: 8, textAlign: 'center' }}>
              <Typography variant="body2">No Matching Settings</Typography>
            </div>
          ) : (
            ''
          )}
          <div style={{ padding: 8 }}>
            {/* Render every item, which handles whether it is displayed or not */}
            {sip
              ? BASE_SETTINGS_LIST.map((setting, i) => {
                  const Component = setting.component;
                  return (
                    <Component
                      key={i}
                      filterText={filterText}
                      setFilterMatching={handleChildrenFilterMatching(i)}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </Box>
  );

  // console.log('filterText:', filterText);
  // return (
  //   <div
  //     style={{
  //       height: '100%',
  //       display: show ? 'block' : 'none',
  //     }}
  //   >
  //     <div
  //       style={{
  //         height: '100%',
  //         display: 'flex',
  //         flexDirection: 'column',
  //       }}
  //     >
  //       <div>
  //         <div style={{ padding: '2px 2px' }}>
  //           <TextField
  //             value={filterText}
  //             onChange={e => setFilterText(e.target.value)}
  //             placeholder="Filter Settings"
  //             variant="outlined"
  //             fullWidth
  //             size="small"
  //             style={{ margin: 0 }}
  //             InputProps={{
  //               startAdornment: (
  //                 <InputAdornment position="start">
  //                   <SearchIcon style={{ transform: 'scaleX(-1)' }} />
  //                 </InputAdornment>
  //               ),
  //             }}
  //           />
  //         </div>
  //         <Divider />
  //       </div>
  //
  //       <div
  //         style={{
  //           flex: 1,
  //           overflowY: 'scroll',
  //         }}
  //       >
  //         {!anyChildrenMatch ? (
  //           <div style={{ padding: 8, textAlign: 'center' }}>
  //             <Typography variant="body2">No Matching Settings</Typography>
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //         <div style={{ padding: 8 }}>
  //           {/* Render every item, which handles whether it is displayed or not */}
  //           {BASE_SETTINGS_LIST.map((setting, i) => {
  //             const Component = setting.component;
  //             return (
  //               <Component
  //                 key={i}
  //                 filterText={filterText}
  //                 setFilterMatching={handleChildrenFilterMatching(i)}
  //               />
  //             );
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default SettingsView;

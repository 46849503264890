import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { setAtPath } from '../../../../../utilities';

export const useEnsureModuleItemId = data => {
  const { moduleItem, callflow, setCallflow, modifyPath } = data;
  useEffect(() => {
    if (!moduleItem?.id) {
      setAtPath(callflow, modifyPath, {
        id: uuidv4(),
        ...(moduleItem || {}),
      });
      setCallflow({ ...callflow });
    }
  }, [moduleItem?.id]);
  return null;
};

export default useEnsureModuleItemId;

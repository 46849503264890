import { TextField } from 'app/design';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const UserRingTime = ({ item, modifyPath }) => {
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const key = varItem.key;
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);

  // simple variable values
  const currentVarOutputValue = varItem.output_value;
  const defaultValueInFlow = varItem.output_value_default;
  const simpleValueInCallflow = variables[key];

  // get CURRENT value FROM MODULE
  // - for when saved by the Advanced Editor!
  //   - that doesnt update the varItem, it only updates the actual flow!
  const currentValue = moduleItem?.data?.data?.timeout;

  // console.log(
  //   'UserRingTime:',
  //   moduleItem,
  //   defaultValueInFlow,
  //   simpleValueInCallflow,
  //   varItem,
  // );

  return (
    <div>
      <Controller
        control={control}
        name={`${modifyPath}callflow.strategy.simple.variables.${key}`}
        defaultValue={defaultValueInFlow}
        render={({ field: { onChange, value, ...props } }) => (
          <TextField
            label={varItem.simple_title}
            value={value}
            type="number"
            onChange={e => {
              onChange(e.target.value);
            }}
            fullWidth
            helperText={
              currentValue !== simpleValueInCallflow
                ? `Current value: ${currentValue}`
                : undefined
            }
          />
        )}
      />
    </div>
  );
};

export default UserRingTime;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
  Controls,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';
import dagre from 'dagre';
// import initialElements from './initial-elements';
// import './layouting.css';

import { useLocalSelector } from 'app/data/local';
import { useSelector } from 'react-redux';

import { Close as CloseIcon } from 'app/design/icons-material';

import {
  Grid,
  Button,
  ButtonGroup,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from 'app/design';

import { find, toInteger, cloneDeep } from 'lodash';

import {
  createReducerContext,
  useEffectOnce,
  createStateContext,
  useHoverDirty,
} from 'react-use';

// import { useCallflowQuery } from 'app/hooks/queries/callflow';

import useComponentSize from '@rehooks/component-size';
// import ConvertCallflowToBaseElements from '../Flow/nodesonvertCallflowToBaseElements';

// import * as OptionComponents from '../../../Strategies/components';

// import { useSetupHook } from '../SetupHook';

// import store from '../../../../../store';

// import { Wizard } from './Wizard';
import { CustomInlineFlow } from './Flow';
import {
  useSharedFlow,
  SharedFlowProvider,
  IvrMenuEventEmitterContext,
} from './IvrBuilder';

import eventEmitter from './eventEmitter';

const IvrCustomInline = ({ onDone, callflow }) => {
  // this simply wraps the available space (required by react-flow)
  // const [contentRef, contentDimensions] = useDimensions(); // { x, y, width }

  const [{ top, left }, setSizeInfo] = useState({ top: 0, left: 0 });
  const contentRef = useCallback(contentNode => {
    setSizeInfo(contentNode?.getBoundingClientRect());
  }, []);

  const { topPadding, leftPadding } = {
    topPadding: 24,
    leftPadding: 24,
  };

  return (
    <div
      ref={contentRef}
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: `-${topPadding}px`,
          left: `-${leftPadding}px`,
          height: `calc(100vh - ${top}px + ${topPadding}px)`,
          width: `calc(100vw - ${left}px + ${leftPadding}px)`,
        }}
      >
        {/* wait for parent placement to exist before displaying */}
        {top ? (
          <ReactFlowProvider>
            <SharedFlowProvider>
              <IvrMenuEventEmitterContext.Provider value={eventEmitter}>
                <IvrViewFlowOrWizard onDone={onDone} callflow={callflow} />
              </IvrMenuEventEmitterContext.Provider>
            </SharedFlowProvider>
          </ReactFlowProvider>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

let chId = 1;
const IvrViewFlowOrWizard = ({ onDone, callflow }) => {
  const [sharedFlow, setSharedFlow] = useSharedFlow();

  useSharedFlowSetup({ callflow, sharedFlow, setSharedFlow });

  const [editingCallflow, setEditingCallflow] = useState(
    () => cloneDeep(callflow), // NOT .doc
  );

  const setEditingCallflowWrap = useSetEditingCallflowWrap({
    sharedFlow,
    setSharedFlow,
    setEditingCallflow,
  });

  // @ts-ignore
  if (!sharedFlow.setup) {
    return null;
  }

  return (
    <>
      {sharedFlow.view === 'flow' ? (
        <CustomInlineFlow
          onDone={onDone}
          editingCallflow={editingCallflow}
          setEditingCallflow={setEditingCallflow}
          setEditingCallflowWrap={setEditingCallflowWrap}
        />
      ) : (
        ''
      )}
      {/* {sharedFlow.view === 'wizard' ? (
        <Wizard
          editingCallflow={editingCallflow}
          setEditingCallflow={setEditingCallflow}
          setEditingCallflowWrap={setEditingCallflowWrap}
        />
      ) : (
        ''
      )} */}
    </>
  );
};

const useSetEditingCallflowWrap = ({
  sharedFlow,
  setSharedFlow,
  setEditingCallflow,
}) => {
  return (
    cf, // callflow
    actionDetails = {}, // .name, etc
    skipUndo = false,
    // replaceInsteadOfPush: used in "undo" for when doing an action that doesnt push a state, but instead replaces the current state
    // - ie, for after adding a new component and setting the "default" values for it (by the component)
    //   - we want the "undo" to go back to the correct place, without accidentally triggering the "default" value replacement again
    replaceInsteadOfPush = false,
  ) => {
    console.log('setEditingCallflowWrap called', actionDetails, skipUndo);
    const freshState = JSON.parse(JSON.stringify({ ...cf, changed: true }));
    if (!skipUndo) {
      if (actionDetails === true) {
        console.error('meant to do skipUndo=true!');
        debugger;
      }
      // @ts-ignore
      if (!actionDetails?.name) {
        actionDetails = {
          name: 'Unknown Change',
        };
      }
      // cache saved version

      let addAmount = replaceInsteadOfPush ? 0 : 1;

      const cacheHistory = sharedFlow.cacheHistory;
      cacheHistory.splice(
        sharedFlow.cachePosition + addAmount,
        cacheHistory.length,
      ); // clear any after this
      cacheHistory.push(
        JSON.parse(
          JSON.stringify({
            id: chId++, // randomString(5),
            actionDetails,
            state: freshState,
          }),
        ),
      );

      setSharedFlow({
        ...sharedFlow,
        cachePosition: sharedFlow.cachePosition + addAmount,
        cacheHistory,
      });
    }
    setEditingCallflow(freshState);
  };
};

const useSharedFlowSetup = ({ callflow, sharedFlow, setSharedFlow }) => {
  // setup sharedFlow on first run
  useEffect(() => {
    setSharedFlow({
      ...sharedFlow,
      cachePosition: 0,
      cacheHistory: [
        {
          id: chId++,
          actionDetails: { name: 'Loaded Callflow' },
          state: cloneDeep(callflow),
        },
      ],
      showJson: null,
      setup: true,
    });
  }, []);

  return [];
};

export default IvrCustomInline;

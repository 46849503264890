import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FastArrowLeft, FastArrowRight } from 'iconoir-react';

export interface TableProps {
  children: ReactNode;
}

export const Table = ({ children }: TableProps) => {
  const [scroll, setScroll] = useState({
    need: false,
    atStart: false,
    atEnd: false,
    clientWidth: 0,
  });
  const tableRef = useRef(null);
  useEffect(() => {
    // detect scroll and show if: needs-scroll, at-end (if needsScroll && !atEnd, show class)
    // - shows "row is scrollable left-right"
    handleSetClass(tableRef.current);
  }, [children]); // updateing when "children" update..same as updating on resize?...but this (might) have a re-render loop?

  const handleSetClass = target => {
    const { clientWidth, scrollLeft, scrollWidth } = target;
    if (clientWidth !== scrollWidth) {
      if (scrollLeft <= 0) {
        setScroll({
          need: true,
          atStart: true,
          atEnd: false,
          clientWidth,
        });
      } else if (clientWidth + scrollLeft >= scrollWidth) {
        setScroll({
          need: true,
          atStart: false,
          atEnd: true,
          clientWidth,
        });
      } else {
        setScroll({
          need: true,
          atStart: false,
          atEnd: false,
          clientWidth,
        });
      }
    } else {
      setScroll({
        need: false,
        atStart: false,
        atEnd: false,
        clientWidth,
      });
    }
  };

  const handleScroll = event => {
    handleSetClass(event.currentTarget);
  };

  return (
    <div>
      <div
        ref={tableRef}
        className={`border border-neutral-20 overflow-x-auto rounded-lg w-full`}
        onScroll={handleScroll}
      >
        <table
          className={
            ' table-auto w-full first:border-t-0 last:border-b-0 rounded-lg border-collapse '
          }
        >
          {children}
        </table>
      </div>
      {scroll.need ? (
        <>
          <div
            className={`w-full text-xs text-neutral-400 bold flex justify-center items-center`}
          >
            <FastArrowLeft
              fr={undefined}
              width={12}
              height={12}
              className={
                !scroll.atStart ? `-mt-0.5` : '-mt-0.5 text-neutral-100'
              }
            />
            <div className={`p-1`}>scrollable</div>
            <FastArrowRight
              fr={undefined}
              width={12}
              height={12}
              className={scroll.atEnd ? `-mt-0.5 text-neutral-100` : '-mt-0.5'}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Table;

import { AudioPlayer } from 'app/components/AudioPlayer';
import { EditHoldMusicDialogDevice } from 'app/components/EditHoldMusicDialogDevice';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { BUILT_IN_AUDIO } from 'app/components/MediaDialog/components/BuiltInAudio/BUILT_IN_AUDIO';
import { Alert } from 'app/design-lib';
import { useToggleReducer } from 'app/utilities';
import { startCase } from 'lodash';
import * as React from 'react';
import { Device } from 'types/device';
import { Chip } from 'app/design';

interface HoldMusicDeviceProps {
  device: Device;
  onComplete: () => void;
}

const HoldMusicDevice = ({ device, onComplete }: HoldMusicDeviceProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'holdMusic',
    options: [
      {
        label: 'Hold Music',
        value: 'holdMusic',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  const mediaId = device.HoldMusic?.id;

  return (
    <div className={`flex flex-col space-y-2`}>
      {device.HoldMusic?.inherited ? (
        <Alert
          reverse
          color={'neutral'}
          label={'Inheriting from account'}
          body={
            <div className={`flex flex-col space-y-2 pr-2`}>
              <span>Customize your device hold music.</span>
              <AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />
            </div>
          }
          // actionProps={{
          //   onClick: toggleShowEditDialog,
          //   children: 'Customize hold music',
          // }}
        />
      ) : mediaId ? (
        <AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />
      ) : (
        <Alert
          reverse
          color={'neutral'}
          label={'Using platform default'}
          body={
            <div className={`flex flex-col space-y-2 pr-2`}>
              <span>
                Customize your hold music to help your business stand out.
              </span>
              <AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />
            </div>
          }
          // actionProps={{
          //   onClick: toggleShowEditDialog,
          //   children: 'Customize hold music',
          // }}
        />
      )}
    </div>
  );

  // return (
  //   // @ts-ignore
  //   <div ref={ref}>
  //     <HighlightPulse {...highlightPulseProps} />
  //     <EditHoldMusicDialogDevice
  //       open={showEditDialog}
  //       deviceId={device.id}
  //       mediaId={device.doc.music_on_hold.media_id}
  //       onComplete={handleOnComplete}
  //       onCancel={toggleShowEditDialog}
  //     />
  //     <GenericSettingsCardField
  //       title={'Hold Music'}
  //       chip={
  //         device.HoldMusic?.inherited ? (
  //           <div className="w-min select-none bg-background-accent-secondary whitespace-nowrap text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
  //             {`INHERITED FROM ${device.HoldMusic.inherited.toUpperCase()}`}
  //           </div>
  //         ) : !mediaId ? (
  //           <div className="w-min select-none bg-background-accent-secondary whitespace-nowrap text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
  //             {'PLATFORM DEFAULT'}
  //           </div>
  //         ) : null
  //       }
  //       content={<AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />}
  //       editOnClick={toggleShowEditDialog}
  //       editLabel={
  //         device.doc.music_on_hold.media_id
  //           ? 'Change Hold Music'
  //           : 'Add Hold Music'
  //       }
  //     />
  //   </div>
  // );
};

export default HoldMusicDevice;

import React from 'react';

import { getAtPath, setAtPath } from 'app/utilities';

import { cloneDeep, toInteger } from 'lodash';

import { GET_A_VAR_TYPES, SET_A_VAR_TYPES } from '../common/VariablesEditor';

// Get the variables for the Simple Builder
// - for each item
// - conditional stuff must/should depend on the "simple" value, not the default value (NOT actually implementing conditionals yet)
//   - ie, needs access to callflow.strategy.simple.variables to determine which path to go down
export const getSimpleVariables = async ({
  rootCallflow,
  templateCallflow,
}) => {
  let simpleVariables = [];

  let outputCallflow = cloneDeep(templateCallflow);

  const setOutputCallflow = callflow => {
    outputCallflow = callflow;
  };

  const rootData = await getVars({
    rootCallflow, // for variables!
    outputCallflow, // for editing AND variables (TODO: variables also included from parent templates???)
    setCallflow: setOutputCallflow,
    modifyPath: '', // root of OUTPUTCALLFLOW
    rootData: { callflowIds: [] },
    simpleVariables,
    skipShowingInSimple: false,
    // templateCallflow,
    // optionalNodesEdges...
    templateCallflow,
  });

  return simpleVariables;
};

const getVars = async ({
  rootCallflow,
  outputCallflow,
  setCallflow,
  modifyPath,
  rootData,
  simpleVariables,
  skipShowingInSimple,
  templateCallflow,
}) => {
  let currentCallflow = getAtPath(outputCallflow, modifyPath);

  if (!currentCallflow) {
    console.log('missing currentCallflow for getVars');
    return rootData;
  }

  if (currentCallflow.strategy.type !== 'blank') {
    console.error(
      'invalid callflow type for convertCallflow in "getSimpleVariables"',
      { currentCallflow, outputCallflow, templateCallflow },
    );
    return rootData;
  }

  let canContinue = true,
    currentIdx = -1;
  for (let infoIdx in currentCallflow.strategy.data.modules) {
    infoIdx = toInteger(infoIdx);
    currentIdx = infoIdx;
    if (!canContinue) {
      continue;
    }
    const moduleItem = currentCallflow.strategy.data.modules[infoIdx];

    switch (moduleItem.type) {
      case 'Menu':
        console.log('Applying variables to Menu', moduleItem);
        await getVarsFromModule({
          rootCallflow,
          outputCallflow,
          moduleItem,
          moduleModifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
          simpleVariables,
          skipShowingInSimple,
        });
        let targets = moduleItem.data.targets ?? {};
        for (let key of Object.keys(targets)) {
          const target = targets[key];

          console.log(
            'GET VARS FOR MENU',
            outputCallflow,
            'mp',
            modifyPath ?? null,
            key,
          );

          // if (target?.callflow?.strategy?.simple?.enabled) {
          //   // simple enabled for this inline callflow, we are NOT extracting out the values
          //   // - TODO: unless we want to!
          //   continue;
          // }
          rootData = await getVars({
            rootCallflow, //: target?.callflow,
            outputCallflow,
            setCallflow,
            modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.targets.{${key}}.callflow`,
            rootData,
            simpleVariables,
            skipShowingInSimple: true,
          });
        }
        break;

      case 'Schedule':
        console.log('Applying variables to Schedule');
        await getVarsFromModule({
          rootCallflow,
          outputCallflow,
          moduleItem,
          moduleModifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
          simpleVariables,
          skipShowingInSimple,
        });

        // const { list: callflows1 } = state.lists.callflows;
        // const scheduleCallflow = callflows1.find(
        //   cf => cf.id === moduleItem?.time?.callflow?.id,
        // );

        // // TODO: use a copied/inline callflow! (similar to how Templates uses outputCallflow)!
        // let times = scheduleCallflow?.doc?.strategy?.data?.times ?? []; //moduleItem.data.times ?? {};
        // times.map((timeDetails, i) => {
        //   rootData = await getVars({
        //     rootCallflow,
        //     outputCallflow,
        //     setCallflow,
        //     modifyPath: `${modifyPath}.strategy.data.modules[${infoIdx}].data.times.${timeDetails.id}.callflow`,
        //     rootData,
        //     simpleVariables
        //   });
        // });

        break;

      // case 'ContinueToCallflow':
      //   // link to viewing that callflow (NOT showing here!)
      //   // - this is an EXTERNAL callflow! NOT an inline one!
      //   window.alert('fix ContinueToCallflow2');
      default:
        console.log(`Get variables from ${moduleItem.type}`);
        await getVarsFromModule({
          rootCallflow,
          outputCallflow,
          moduleItem,
          moduleModifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
          simpleVariables,
          skipShowingInSimple,
        });
        break;
    }
  }
  // iterate through Strategy, replacing variables as we go
  // - modifying by reference

  return rootData;
};

const getVarsFromModule = async ({
  rootCallflow,
  outputCallflow,
  moduleItem,
  moduleModifyPath,
  simpleVariables,
  skipShowingInSimple,
}) => {
  console.log('getVarsFromModule:', moduleItem.data.variables);
  const vars = moduleItem.data.variables;
  if (vars?.enabled) {
    // TODO: currently the "simple" sorting is all kinds of broken/wrong
    // - we are storing the variable "key" in an object, which has no priority
    // - the *root* issue is that we shouldnt directly create the UI based off of "simple" values...we should do something different...
    const ex = ['menu_greeting', 'menu_targets'];
    const sortedKeys = Object.keys(vars.items).sort((a, b) => {
      return ex.indexOf(a) > ex.indexOf(b) ? 1 : -1;
    });
    for (let i in sortedKeys) {
      const id_of_var = sortedKeys[i];
      const varItem = vars.items[id_of_var];

      if (!varItem.enabled) {
        continue;
      }

      if (!varItem.simple) {
        continue;
      }

      simpleVariables.push({
        varItem,
        moduleItem,
        moduleModifyPath,
        skipShowingInSimple,
      });
    }
  }
};

export default getSimpleVariables;

import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { CallflowCreateDoc } from 'types/callflow';

export const useCreateCallflow = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, CallflowCreateDoc, unknown>(
    (callflowDoc: CallflowCreateDoc) =>
      sdk.callflow.mutate.create(
        {
          data: callflowDoc,
          // other values such as "accept_charges" could go here
        },
        {
          authToken: authState?.auth_token,
        },
      ),
    {
      onSuccess: async () => {
        // callflows are everywhere... invalidate all the things!
        await queryClient.invalidateQueries();
      },
    },
  );
};

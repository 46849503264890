import { IconButton } from 'app/design-lib/components/IconButton';
import { Cancel as CloseIcon } from 'iconoir-react';
import React, { ReactNode } from 'react';

interface DialogHeaderProps {
  title: ReactNode;
  subTitle?: string;
  onClose: () => void;
  disableClose?: boolean;
}

export const DialogHeader = ({
  title,
  subTitle,
  onClose,
  disableClose,
}: DialogHeaderProps) => {
  return (
    <div
      className={`pl-4 pb-1 pr-[6px] border-b border-neutral-20 pt-2 flex space-x-2 items-start justify-between`}
    >
      <div
        className={`flex items-baseline mt-1 sm:flex-row flex-col flex-wrap`}
      >
        <span className={'text-lg font-medium mr-2 whitespace-nowrap'}>
          {title}
        </span>
        <div className={'text-sm text-neutral-60 flex-1 sm:whitespace-nowrap'}>
          {subTitle}
        </div>
      </div>
      <IconButton
        onClick={onClose}
        size={'md'}
        variant={'ghost'}
        color={'neutral'}
        disabled={disableClose}
      >
        <CloseIcon fr={undefined} />
      </IconButton>
    </div>
  );
};

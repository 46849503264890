import { ChipSwitch, StatusTooltip } from 'app/design';
import { Switch } from 'app/design-lib';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { Flash, FlashOff, SoundHigh, SoundOff } from 'iconoir-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DoNotDisturb } from 'types/generic';
import { UserDoc } from 'types/user';
import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
} from 'app/design/icons-material';

interface UserSettingProps {
  userDoc: UserDoc;
  onComplete?: any;
}

const defaultDoNotDisturb = { enabled: false };

const DoNotDisturbSettings = ({ userDoc, onComplete }: UserSettingProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const updateUser = useUpdateUserPartial();
  const {
    formState: { isDirty },
    watch,
    control,
    handleSubmit,
    reset,
  } = useForm<DoNotDisturb>({
    defaultValues: userDoc.do_not_disturb ?? defaultDoNotDisturb,
  });
  const watchEnabled = watch('enabled');
  const statusRef = useRef(updateUser.status);
  statusRef.current = updateUser.status;

  // reset form if user doc changes
  useEffect(() => {
    reset(userDoc.do_not_disturb ?? defaultDoNotDisturb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDoc]);

  // display switch tooltip based on status
  useEffect(() => {
    if (updateUser.status === 'loading') setShowTooltip(true);
    else if (showTooltip)
      setTimeout(
        // @ts-ignore
        () => statusRef.current !== 'loading' && setShowTooltip(false),
        1000,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUser.status]);

  // trigger submit on switch trigger
  useEffect(() => {
    if (isDirty) {
      // only submit if form is dirty (we reset form on success, fail)
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEnabled]);

  const onSubmit = async (doNotDisturbValues: DoNotDisturb) => {
    try {
      const resp = await updateUser.mutateAsync(
        {
          id: userDoc.id,
          do_not_disturb: doNotDisturbValues,
        },
        // {
        //   onSuccess: resp => {
        //     const { data } = resp;
        //
        //     // reset form data (needed since this form is initialized once, not
        //     // - every time like when a dialog opens). We need to reset the default
        //     // - values which are the source of truth for error reset/isDirty value
        //     reset(data.do_not_disturb);
        //   },
        //   onError: () => {
        //     reset(); // reset form to last valid state
        //     toast.error('Failed to update user settings.');
        //   },
        // },
      );

      if (resp.status === 'success') {
        const { data } = resp;

        // reset form data (needed since this form is initialized once, not
        // - every time like when a dialog opens). We need to reset the default
        // - values which are the source of truth for error reset/isDirty value
        reset(data.do_not_disturb);
        // alert('Updated donotdisturb');
        onComplete && onComplete();
      }
    } catch (e) {
      reset(); // reset form to last valid state
      toast.error('Failed to update user settings.');
    }
  };

  return (
    <Controller
      control={control}
      name={'enabled'}
      render={({ field: { onChange, value, ...props } }) => (
        <div className={'flex w-full justify-between'}>
          <span className={'text-md font-medium'}>Do not disturb</span>
          <Switch
            uncheckedIcon={<SoundHigh fr={undefined} />}
            checkedIcon={<SoundOff fr={undefined} />}
            // checkedColor={'accent'}
            checked={value}
            onClick={e => onChange(!value)}
          />
        </div>
        // <StatusTooltip
        //   status={updateUser.status}
        //   placement={'right'}
        //   open={showTooltip}
        // >
        /*<Switch
          checked={!value}
          onClick={e => onChange(!value)}
          icon={value ? <NotificationsOffIcon /> : <NotificationsActiveIcon />}
          label={value ? 'Ring Off' : 'Ring On'}
          checkedColor={'positive'}
          uncheckedColor={'negative'}
          title={
            value
              ? 'Your phone will NOT ring on incoming calls'
              : 'Your phone WILL ring on incoming calls'
          }
        />*/
        // {/*<div
        //     className="flex items-center cursor-pointer"
        //     onClick={e => onChange(!value)}
        //   >
        //     <button
        //       className={`relative w-12 cursor-pointer h-6 rounded-full p-0 border-0 flex items-center transition duration-300 focus:outline-none ring-1 ring-inset ${
        //         value
        //           ? ' bg-background-negative-secondary-hover ring-neutral-400'
        //           : ' bg-background-positive-secondary-hover ring-neutral-400'
        //       }`}
        //       type="button"
        //       title={
        //         value
        //           ? 'Your phone will NOT ring on incoming calls'
        //           : 'Your phone WILL ring on incoming calls'
        //       }
        //     >
        //       <div
        //         className={`w-6 h-6 relative
        //        rounded-full transition-all duration-500 p-1 text-white grid place-items-center ${
        //          !value
        //            ? ' bg-content-positive  ignore_translate-x-4 ml-[calc(100%-24px)]'
        //            : ' text-neutral-500   bg-content-negative ml-0 ignore_-translate-x-2'
        //        }`}
        //       >
        //         {value ? (
        //           <NotificationsOffIcon sx={{ fontSize: 16 }} />
        //         ) : (
        //           <NotificationsActiveIcon sx={{ fontSize: 16 }} />
        //         )}
        //       </div>
        //     </button>
        //     <span className={`ml-2 ${value ? 'font-bold' : 'font-medium'}`}>
        //       <span
        //         className={
        //           value ? 'text-content-negative' : 'text-content-positive'
        //         }
        //       >
        //         {value ? 'Ring Off' : 'Ring On'}
        //       </span>
        //     </span>
        //   </div>*/}

        // {/*
        // <ChipSwitch
        //   size="small"
        //   icon={<NightlightRoundIcon />}
        //   label="Do-Not-Disturb"
        //   offColor="default"
        //   onColor="info"
        //   checked={value}
        //   onChange={e => onChange(e.target.checked)}
        //   // @ts-ignore
        //   sx={{
        //     background: value ? theme => theme.palette.info.dark : undefined,
        //   }}
        // /> */}
        // </StatusTooltip>
      )}
    />
  );
};

export default DoNotDisturbSettings;

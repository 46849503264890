import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import { SchedulePlateEditor } from 'app/components/SchedulePlateEditor';

import {
  Box,
  Chip,
  Divider,
  Link,
  Menu,
  MenuItem,
  ThemeProvider,
  Tooltip,
  Typography,
} from 'app/design';
import { sdk } from 'app/sdk';
import { useListTemporalRulesSetsQuery } from 'app/hooks/queries/temporalRulesSet';
import { getAtPath } from 'app/utilities';
import React, { useState } from 'react';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { store } from 'store';
import constants from 'app/constants';

const ScheduleWidget = props => {
  const {
    callflow, // schedule callflow ({ name, strategy, ... })
    noLabel = false,
  } = props;

  const times = getAtPath(callflow, 'strategy.data.times', []);
  const [tabValue, setTabValue] = useState(times?.length ? 0 : null);

  // TODO: this is NOT saving yet!
  const [currentCallflow, setCurrentCallflow] = useState(callflow);

  // TODO: check FORCED status of temporal_sets

  const [overrideState, fetchOverrides] = useAsyncFn(async () => {
    const ids = times
      .filter(hourInfo => hourInfo.overrideRuleId)
      .map(hourInfo => hourInfo.overrideRuleId);

    const authToken = store.getState().auth.auth_token;
    const output = await sdk.temporalRule.query.temporalRules(
      {
        // skip: 0,
        // take: 1,
        // orderBy: [],
        filters: {
          // raw: [['owner_id', '=', callflow.owner_id]],
          temporal_rule_id: {
            in: ids,
          },
        },
      },
      { authToken },
    );
    // console.log('OUTPUT:', output);
    // const vmbox = vmboxes?.length ? vmboxes[0] : null;

    // let promises = times
    //   .filter(hourInfo => hourInfo.overrideRuleId)
    //   .map(hourInfo =>
    //     KazooSDK.resourceTemporalRulesGet(hourInfo.overrideRuleId),
    //   );
    // const outResponses = await Promise.all(promises);
    let overrides = {};
    for (let rules of output) {
      overrides[rules.id] = rules.doc;
    }
    // console.log('overrides:', overrides);
    // setOverrides(overrides);
    return overrides;
  }, [times]);
  useEffectOnce(() => {
    fetchOverrides();
  });

  const overrides = overrideState?.value ?? {};

  // get if one is enabled (first one!)
  let forcedTime = times.find(timeDetails => {
    let override = overrides[timeDetails.overrideRuleId];
    return override && override.enabled;
  });

  const forcedTimes = times.filter(
    timeDetails => overrides[timeDetails.overrideRuleId]?.enabled,
  );

  const options = times.map(timeDetails => {
    let override = overrides[timeDetails.overrideRuleId];
    let label = timeDetails.name;
    // console.log('timeDetails:', timeDetails);
    if (timeDetails.rules?.[0]?.outputRuleIds.length) {
      // valid rule ids
      // label = `${label}`;
    } else {
      // if (timeDetails.isElse) {
      //   // isElse
      // } else {
      //   label = `${label} (no rules set)`;
      // }
    }
    // if (override && override.enabled) {
    //   label = `${label} (ENABLED)`;
    // }
    // console.log('timeDetails', timeDetails);
    return {
      label, //defaultVideoList[codecId],
      value: timeDetails.id,
      override: override && override.enabled,
      timeDetails,
      isElse: timeDetails.isElse,
      isCommon: timeDetails.isCommon,
      hasRules: timeDetails.rule?.outputRuleIds ? true : false,
    };
  });
  // .filter((item) => item.isElse || item.hasRules); // uncomment this line to filter out "empty" ones

  const temporalRulesSetsQuery = useListTemporalRulesSetsQuery({
    // skip: 0,
    // take: 100,
    // orderBy: []
    filters: {
      raw: {
        temporal_rules_set_id: {
          in: times.map(t => t.ruleSetId).filter(v => !!v),
        },
      },
      options: {
        timezone: callflow?.strategy?.data?.timezone,
        // utcSeconds: 0
      },
    }, // filters
  });
  // console.log('temporalRulesSetsQuery:', temporalRulesSetsQuery);
  // console.log('TIMES:', times);

  const handleForceEnable = (id, isEnabled) => async () => {
    // de-enables others!!
    handleCloseMenu();
    toggleBackdrop();
    const timeDetails = times.find(t => t.id === id);
    const promises: Promise<any>[] = [];
    const { auth_token, account_id } = store.getState().auth;
    const headers = new Headers();
    // @ts-ignore
    headers.set('X-AUTH-TOKEN', auth_token);
    headers.set('Content-Type', 'application/json');
    Object.keys(overrides).forEach(ruleId => {
      // // TODO: only save if necessary (others usually will already be false)
      promises.push(
        // KazooSDK.resourceTemporalRulesPatch({
        //   id: ruleId,
        //   enabled: ruleId === timeDetails?.overrideRuleId ? isEnabled : false,
        // }),
        fetch(
          `${constants.env.REACT_APP_CIO_API_SERVER}/api/kproxy/accounts/${account_id}/temporal_rules/${ruleId}`,
          {
            method: 'PATCH',
            // mode: 'cors',
            headers,
            body: JSON.stringify({
              data: {
                id: ruleId,
                enabled:
                  ruleId === timeDetails?.overrideRuleId ? isEnabled : false,
              },
            }),
          },
        ),
      );
    });
    try {
      await Promise.all(promises);
    } catch (err) {
      console.error('Failed updating:', err);
    }

    fetchOverrides();
    toggleBackdrop();
  };

  // const handleChangeAuto = async (event) => {
  //   // if enabling, make sure to DISABLE "enabled" for all possible options
  //   // - if disabling, ask them to choose which to enable/force (and tell them how to do it via dial-in)
  //   if (event.target.checked) {
  //     // remove "forced" from each option
  //   } else {
  //     alert('Please select which option to enable by clicking it');
  //   }

  //   // const userData = {
  //   //   id: user.id,
  //   //   do_not_disturb: { enabled: event.target.checked },
  //   // };
  //   // const userResp1 = await KazooSDK.patchUserById(user.id, userData);
  //   // const userWithListingAndFull = await KazooSDK.getUserFull(user.id);
  //   // await dispatch({
  //   //   type: 'SET_USER',
  //   //   payload: { user: userWithListingAndFull },
  //   // });
  //   // onUserChanged();
  // };

  // console.log('OptionsRest:', optionsRest);
  function ruleSetForId(id) {
    // @ts-ignore
    return temporalRulesSetsQuery.data?.find(v => v.id === id);
  }
  const firstMatching =
    times.find(t => ruleSetForId(t.ruleSetId)?.matching) ??
    times[times.length - 1];
  const selectedOption = options.find((opt, i) =>
    forcedTime ? forcedTime.id === opt.value : opt.value === firstMatching?.id,
  );
  // console.log('firstMatching', {
  //   temporalRulesSetsQuery,
  //   selectedOption,
  //   firstMatching,
  //   forcedTime,
  // });
  const optionOpen = null; //options.find((opt) => opt.isCommon); // only the first one!
  const optionClosed = null; //options.find((opt) => opt.isElse);

  // More options to show in dropdown? Or just holidays/open/closed?
  // - which option to show?
  const optionsRest = options.filter(
    // @ts-ignore
    opt => ![optionOpen?.value, optionClosed?.value].includes(opt.value),
  );
  const optionRest = optionsRest.length
    ? optionsRest.find(opt => opt.value === selectedOption) || optionsRest[0]
    : null;

  // console.log('optionsRest:', optionsRest);

  const [showEdit, setShowEdit] = useState(false);
  const setShowEditWrap = prop => () => setShowEdit(prop);

  // auto = none forced
  const isAuto = selectedOption?.override ? false : true;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickMenu = event => {
    temporalRulesSetsQuery.refetch();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  // @ts-ignore
  const atLeastOneMatch = temporalRulesSetsQuery.data?.find(v => v.matching)
    ? true
    : false;
  return (
    <>
      <CustomBackdrop {...CustomBackdropProps} />
      {/* <Chip size="small" variant="outlined" label={<>Test</>} /> */}

      {optionsRest.length ? (
        <Chip
          size="small"
          sx={{ width: noLabel ? 29 : undefined }}
          // startIcon={
          //   forcedTime ? (
          //     <Tooltip
          //       title={<>Forced (click to undo)</>}
          //       placement="bottom"
          //       type="light"
          //       arrow
          //     >
          //       <WarningIcon sx={{ fontSize: '18px' }} />
          //     </Tooltip>
          //   ) : null
          // }
          // variant={
          //   optionRest?.value === selectedOption?.value
          //     ? 'contained'
          //     : undefined
          // }
          variant="outlined"
          onClick={
            handleClickMenu
            // selectedOption.override
            //   ? handleForceEnable(optionRest.value, false)
            //   : handleForceEnable(optionRest.value, true)
          }
          label={
            <Tooltip
              title={
                <>
                  {/* <Typography variant="subtitle1">
                    Click to force-enable this option
                  </Typography> */}
                  {selectedOption.hasRules ? (
                    <SchedulePlateEditor
                      readOnly
                      initialValue={selectedOption.timeDetails.rule.data.value}
                      id={`${selectedOption.id}-selected-${callflow.id}`}
                    />
                  ) : (
                    <Typography variant="body1">
                      Fallback if no other rules match
                    </Typography>
                  )}
                </>
              }
              placement="top"
              arrow
            >
              <Box sx={{ display: 'inline-block', whiteSpace: 'no-wrap' }}>
                <Box
                  sx={{
                    pl: '2px',
                    pr: '8px',
                    display: 'inline-block',
                  }}
                >
                  <Box
                    sx={{
                      content: '" "',
                      width: '8px',
                      height: '8px',
                      borderRadius: '4px',
                      backgroundColor: forcedTime ? 'blue' : '#3AE941',
                    }}
                  />
                </Box>
                {/* <AccessTimeIcon style={{ marginBottom: -2 }} />
                &nbsp; */}
                {noLabel ? null : selectedOption?.label}
              </Box>
            </Tooltip>
          }
        />
      ) : null}

      {forcedTime && selectedOption.override && !noLabel ? (
        <Box sx={{ display: 'inline-block', ml: 1 }}>
          <Link onClick={handleForceEnable(optionRest.value, false)}>
            Back to Auto
          </Link>
        </Box>
      ) : null}

      {/* whether to show arrow or not */}
      {/* {optionsRest.length > 1 || overrideState.loading ? (
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClickMenu}
          >
            {overrideState.loading ? (
              <CircularProgress size={14} />
            ) : (
              <ArrowDropDownIcon />
            )}
          </Button>
        ) : null} */}

      {/* Menu options */}
      {optionsRest.length ? (
        /* <ThemeProvider
          // @ts-ignore
          theme={outerTheme => outerTheme.outerTheme || outerTheme}
        >*/
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          MenuListProps={{ dense: true }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              // arrow
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          // style={{ marginTop: 44 }}
        >
          {optionsRest
            // .filter(opt => opt.value !== optionRest.value)
            .map((opt, index) => (
              <Tooltip
                key={opt.timeDetails?.id}
                title={
                  <>
                    {/* {JSON.stringify(Object.keys(opt.timeDetails.id))} */}
                    {opt.hasRules ? (
                      <Typography variant="h2">
                        <SchedulePlateEditor
                          readOnly
                          id={`${opt.id}-${index}-${callflow.id}`}
                          initialValue={opt.timeDetails.rule.data.value}
                        />
                      </Typography>
                    ) : (
                      <Typography variant="h2">
                        {/* -- Fallback if no other rules match -- */}
                      </Typography>
                    )}
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Typography variant="body1">
                      Click to force-enable {opt.label}
                    </Typography>
                  </>
                }
                placement="right"
                arrow
              >
                <MenuItem
                  onClick={
                    opt.override
                      ? handleForceEnable(opt.value, false)
                      : handleForceEnable(opt.value, true)
                  }
                >
                  <Box
                    sx={{
                      pl: '2px',
                      pr: '8px',
                      display: 'inline-block',
                    }}
                  >
                    <Box
                      sx={{
                        content: '" "',
                        width: '8px',
                        height: '8px',
                        borderRadius: '4px',
                        backgroundColor:
                          ruleSetForId(opt.timeDetails.ruleSetId)?.matching ||
                          (opt.timeDetails.isElse && !atLeastOneMatch)
                            ? '#3AE941'
                            : 'red',
                      }}
                    />
                  </Box>
                  {opt?.label}
                </MenuItem>
              </Tooltip>
            ))}
          {forcedTime && (
            <MenuItem onClick={handleForceEnable(optionRest.value, false)}>
              Back to Auto
            </MenuItem>
          )}
        </Menu>
      ) : // </ThemeProvider>
      null}
    </>
  );

  // return (
  //   <>
  //     <CustomBackdrop {...CustomBackdropProps} />
  //     {/* <Grid container spacing={1} justify="flex-end">
  //       <Grid item>
  //         <FormControlLabel
  //           control={
  //             <Switch
  //               checked={isAuto}
  //               onChange={handleChangeAuto}
  //               color="primary"
  //             />
  //           }
  //           label="Auto"
  //           labelPlacement="start"
  //         />
  //       </Grid>
  //       <Grid item>
  //         <InfoOutlinedIcon />
  //       </Grid>
  //     </Grid> */}

  //     <Grid container columnSpacing={1} wrap="nowrap">
  //       <Grid item style={{ flex: 1 }}>
  //         {/* <div>
  //           Schedule:&nbsp;&nbsp;<strong>{callflow?.name}</strong>
  //         </div> */}
  //         <div>
  //           <Grid container columnSpacing={1} alignItems="center">
  //             {/* <Grid item>Active Time:</Grid> */}
  //             <Grid item>
  //               <ButtonGroup
  //                 variant="link"
  //                 disableElevation
  //                 size="small"
  //                 variant="outlined"
  //               >
  //                 {/* {forcedTime ? (
  //                   <Button
  //                     color={'primary'}
  //                     variant="outlined"
  //                     onClick={handleForceEnable(null)}
  //                   >
  //                     <Tooltip
  //                       title={<>Forced (click to undo)</>}
  //                       placement="bottom"
  //                       type="light"
  //                       arrow
  //                     >
  //                       <span>
  //                         <WarningIcon />
  //                       </span>
  //                     </Tooltip>
  //                   </Button>
  //                 ) : null} */}

  //                 {/* we do NOT have the following inside a single <></> fragment because it breaks the ButtonGroup (Button needs to be a direct Child of ButtonGroup) */}
  //                 {optionsRest.length ? (
  //                   <Button
  //                     color={
  //                       'primary'
  //                       // selectedOption?.value === selectedOption?.value
  //                       //   ? 'primary'
  //                       //   : 'secondary'
  //                     }
  //                     startIcon={
  //                       forcedTime ? (
  //                         <Tooltip
  //                           title={<>Forced (click to undo)</>}
  //                           placement="bottom"
  //                           type="light"
  //                           arrow
  //                         >
  //                           <WarningIcon sx={{ fontSize: '18px' }} />
  //                         </Tooltip>
  //                       ) : null
  //                     }
  //                     // variant={
  //                     //   optionRest?.value === selectedOption?.value
  //                     //     ? 'contained'
  //                     //     : undefined
  //                     // }
  //                     variant="outlined"
  //                     onClick={
  //                       selectedOption.override
  //                         ? handleForceEnable(optionRest.value, false)
  //                         : handleForceEnable(optionRest.value, true)
  //                     }
  //                   >
  //                     <Tooltip
  //                       title={
  //                         <>
  //                           {/* <Typography variant="subtitle1">
  //                             Click to force-enable this option
  //                           </Typography> */}
  //                           {selectedOption.hasRules ? (
  //                             <SchedulePlateEditor
  //                               readOnly
  //                               initialValue={
  //                                 selectedOption.timeDetails.rule.data.value
  //                               }
  //                               id={`${selectedOption.id}-selected-${callflow.id}`}
  //                             />
  //                           ) : (
  //                             <Typography variant="body1">
  //                               Fallback if no other rules match
  //                             </Typography>
  //                           )}
  //                         </>
  //                       }
  //                       placement="top"
  //                       type="light"
  //                       arrow
  //                     >
  //                       <span>
  //                         {/* <AccessTimeIcon style={{ marginBottom: -2 }} />
  //                         &nbsp; */}
  //                         {selectedOption?.label}
  //                       </span>
  //                     </Tooltip>
  //                   </Button>
  //                 ) : (
  //                   ''
  //                 )}
  //                 {optionsRest.length > 1 || overrideState.loading ? (
  //                   <Button
  //                     color="primary"
  //                     variant="outlined"
  //                     onClick={handleClickMenu}
  //                   >
  //                     {overrideState.loading ? (
  //                       <CircularProgress size={14} />
  //                     ) : (
  //                       <ArrowDropDownIcon />
  //                     )}
  //                   </Button>
  //                 ) : (
  //                   ''
  //                 )}
  //                 {optionsRest.length ? (
  //                   <Menu
  //                     id="simple-menu"
  //                     anchorEl={anchorEl}
  //                     keepMounted
  //                     open={Boolean(anchorEl)}
  //                     onClose={handleCloseMenu}
  //                     // style={{ marginTop: 44 }}
  //                   >
  //                     {optionsRest
  //                       // .filter(opt => opt.value !== optionRest.value)
  //                       .map((opt, index) => (
  //                         <MenuItem
  //                           key={opt.id}
  //                           onClick={
  //                             opt.override
  //                               ? handleForceEnable(opt.value, false)
  //                               : handleForceEnable(opt.value, true)
  //                           }
  //                         >
  //                           <Tooltip
  //                             title={
  //                               <>
  //                                 <Typography variant="subtitle1">
  //                                   Click to force-enable this option
  //                                 </Typography>
  //                                 {opt.hasRules ? (
  //                                   <SchedulePlateEditor
  //                                     readOnly
  //                                     id={`${opt.id}-${index}-${callflow.id}`}
  //                                     initialValue={
  //                                       opt.timeDetails.rule.data.value
  //                                     }
  //                                   />
  //                                 ) : (
  //                                   <Typography variant="body1">
  //                                     Fallback if no other rules match
  //                                   </Typography>
  //                                 )}
  //                               </>
  //                             }
  //                             placement="right"
  //                             type="light"
  //                             arrow
  //                           >
  //                             <span>
  //                               {/* <AccessTimeIcon style={{ marginBottom: -2 }} /> */}
  //                               <span>
  //                                 {/* &nbsp; */}
  //                                 {opt?.label}
  //                               </span>
  //                             </span>
  //                           </Tooltip>
  //                         </MenuItem>
  //                       ))}
  //                   </Menu>
  //                 ) : (
  //                   ''
  //                 )}
  //               </ButtonGroup>
  //             </Grid>
  //           </Grid>
  //         </div>
  //       </Grid>
  //       <Grid item>
  //         {/* {showEdit && (
  //           <SetupDialog callflow={callflow} onClose={setShowEditWrap(null)} />
  //         )} */}
  //         {/* <IconButton
  //           variant="outlined"
  //           color="info"
  //           size="xsmall"
  //           onClick={setShowEditWrap(true)}
  //         >
  //           <EditIcon />
  //         </IconButton> */}
  //       </Grid>
  //     </Grid>

  //     {/* <Typography variant="body1" style={{ textAlign: 'right' }}>
  //       Active Schedule: <strong>{selectedOption?.label}</strong>
  //     </Typography>
  //     {forcedTime ? (
  //       <div style={{ textAlign: 'right' }}>
  //         Forced! (<Link onClick={handleForceEnable(null)}>un-force</Link>)
  //       </div>
  //     ) : (
  //       ''
  //     )} */}

  //     {/* <Grid container spacing={1} justify="flex-end">
  //       <Grid item>
  //         <ButtonGroup variant="outlined" disableElevation size="small">
  //           {optionOpen && (
  //             <Button
  //               color={
  //                 optionOpen?.value === selectedOption?.value
  //                   ? 'primary'
  //                   : 'default'
  //               }
  //               variant={
  //                 optionOpen?.value === selectedOption?.value
  //                   ? 'contained'
  //                   : undefined
  //               }
  //               onClick={
  //                 optionOpen.override
  //                   ? handleForceEnable(optionOpen.value, false)
  //                   : handleForceEnable(optionOpen.value, true)
  //               }
  //             >
  //               <Tooltip
  //                 title={
  //                   <>
  //                     <Typography variant="subtitle1">
  //                       Click to force-enable this option
  //                     </Typography>
  //                     {optionOpen.hasRules ? (
  //                       <TemporalRuleBuilder
  //                         timeDetails={optionOpen.timeDetails}
  //                         readOnly
  //                       />
  //                     ) : (
  //                         <Typography variant="body1">No rules</Typography>
  //                       )}
  //                   </>
  //                 }
  //                 placement="bottom"
  //                 type="light"
  //                 arrow
  //               >
  //                 <span>
  //                   <AdjustIcon style={{ marginBottom: -2 }} />
  //                   &nbsp;{optionOpen.label}
  //                 </span>
  //               </Tooltip>
  //             </Button>
  //           )}
  //           {optionClosed && (
  //             <Button
  //               color={
  //                 optionClosed?.value === selectedOption?.value
  //                   ? 'primary'
  //                   : 'default'
  //               }
  //               variant={
  //                 optionClosed?.value === selectedOption?.value
  //                   ? 'contained'
  //                   : undefined
  //               }
  //               onClick={
  //                 optionClosed.override
  //                   ? handleForceEnable(optionClosed.value, false)
  //                   : handleForceEnable(optionClosed.value, true)
  //               }
  //             >
  //               <Tooltip
  //                 title={
  //                   <Typography variant="subtitle1">
  //                     Click to force-enable this option
  //                   </Typography>
  //                 }
  //                 placement="bottom"
  //                 type="light"
  //                 arrow
  //               >
  //                 <span>
  //                   <BlockIcon style={{ marginBottom: -2 }} />
  //                   &nbsp;{optionClosed.label}
  //                 </span>
  //               </Tooltip>
  //             </Button>
  //           )}

  //           {optionsRest.length ? (
  //             <Button
  //               color={
  //                 optionRest?.value === selectedOption?.value
  //                   ? 'primary'
  //                   : 'default'
  //               }
  //               // variant={
  //               //   optionRest?.value === selectedOption?.value
  //               //     ? 'contained'
  //               //     : undefined
  //               // }
  //               onClick={
  //                 optionRest.override
  //                   ? handleForceEnable(optionRest.value, false)
  //                   : handleForceEnable(optionRest.value, true)
  //               }
  //             >
  //               <Tooltip
  //                 title={
  //                   <>
  //                     <Typography variant="subtitle1">
  //                       Click to force-enable this option
  //                     </Typography>
  //                     {optionRest.hasRules ? (
  //                       <TemporalRuleBuilder
  //                         timeDetails={optionRest.timeDetails}
  //                         readOnly
  //                       />
  //                     ) : (
  //                         <Typography variant="body1">No rules</Typography>
  //                       )}
  //                   </>
  //                 }
  //                 placement="top"
  //                 type="light"
  //                 arrow
  //               >
  //                 <span>
  //                   <AccessTimeIcon style={{ marginBottom: -2 }} />
  //                   &nbsp;{optionRest?.label}
  //                 </span>
  //               </Tooltip>
  //             </Button>
  //           ) : (
  //               ''
  //             )}
  //           {optionsRest.length > 1 ? (
  //             <Button color="primary" onClick={handleClickMenu}>
  //               <ArrowDropDownIcon />
  //             </Button>
  //           ) : (
  //               ''
  //             )}
  //           {optionsRest.length ? (
  //             <Menu
  //               id="simple-menu"
  //               anchorEl={anchorEl}
  //               keepMounted
  //               open={Boolean(anchorEl)}
  //               onClose={handleCloseMenu}
  //               style={{ marginTop: 44 }}
  //             >
  //               {optionsRest
  //                 .filter((opt) => opt.value !== optionRest.value)
  //                 .map((opt) => (
  //                   <MenuItem
  //                     key={opt.id}
  //                     onClick={
  //                       opt.override
  //                         ? handleForceEnable(opt.value, false)
  //                         : handleForceEnable(opt.value, true)
  //                     }
  //                   >
  //                     <Tooltip
  //                       title={
  //                         <>
  //                           <Typography variant="subtitle1">
  //                             Click to force-enable this option
  //                           </Typography>
  //                           {opt.hasRules ? (
  //                             <TemporalRuleBuilder
  //                               timeDetails={opt.timeDetails}
  //                               readOnly
  //                             />
  //                           ) : (
  //                               <Typography variant="body1">No rules</Typography>
  //                             )}
  //                         </>
  //                       }
  //                       placement="right"
  //                       type="light"
  //                       arrow
  //                     >
  //                       <span>
  //                         <AccessTimeIcon style={{ marginBottom: -2 }} />
  //                         <span>&nbsp;{opt?.label}</span>
  //                       </span>
  //                     </Tooltip>
  //                   </MenuItem>
  //                 ))}
  //             </Menu>
  //           ) : (
  //               ''
  //             )}
  //         </ButtonGroup>
  //       </Grid>
  //     </Grid> */}
  //   </>
  // );
};

const TemporalRuleBuilder = () => {
  return <div>Tmp TemporalRuleBuilder</div>;
};

export default ScheduleWidget;

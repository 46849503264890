import { Button, Typography } from 'app/design';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWebphoneSlice } from '../../../../data/webphone';
import { useWebphoneContext } from '../../Store';
import { WarningTriangle } from 'iconoir-react';
import { Alert } from '../../../../design-lib';

const noop = () => {};

const Permissions = props => {
  // const { show } = props;

  // const [num, setNum] = useState(1); // used for incrementing
  // const incrementNum = () => setNum(n => n + 1);
  const [state] = useWebphoneContext();
  const { mediaDevices, isLineConnected } = state;
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();

  const [micState, setMicState] = useState(null);
  const [noInputDevice, setNoInputDevice] = useState(null);
  const [noOutputDevice, setNoOutputDevice] = useState(null);
  // const [cameraState] = useState(null);

  useEffect(() => {
    // if (!navigator.permissions?.query) {
    //   return 'prompt';
    // }

    // check mic state
    const checkMic = async () => {
      try {
        const mic_permission = await navigator.permissions.query({
          name: 'microphone',
        });
        setMicState(mic_permission.state);
        const micFunc = () => {
          console.log('done changing mic permission!');
          // incrementNum();
          mic_permission.removeEventListener('change', micFunc);
          checkMic();
        };
        mic_permission.addEventListener('change', micFunc);
      } catch (err) {
        console.error('Unable to checkMic');
        console.error(err);
      }
    };
    checkMic();

    // // check camera state
    // const checkCamera = async () => {
    //   const camera_permission = await navigator.permissions.query({
    //     name: 'camera',
    //   });
    //   setCameraState(camera_permission.state);
    //   const cameraFunc = () => {
    //     console.log('done changing camera permission!');
    //     // incrementNum();
    //     camera_permission.removeEventListener('change', cameraFunc);
    //     checkCamera();
    //   };
    //   camera_permission.addEventListener('change', cameraFunc);
    // };
    // checkCamera();
  }, []);

  useEffect(() => {
    if (micState !== 'granted' || !mediaDevices) return;
    // console.log('media devices', mediaDevices);
    // warn user if no input/output device is present
    const hasNoInputDevice =
      mediaDevices?.filter(device => device.kind === 'audioinput').length === 0;
    const hasNoOutputDevice =
      mediaDevices?.filter(device => device.kind === 'audiooutput').length ===
      0;

    // check that user has atleast 1 input and output for calling
    setNoInputDevice(hasNoInputDevice);
    setNoOutputDevice(hasNoOutputDevice);

    // disable calling if either missing
    dispatch(actions.disableCalling(hasNoInputDevice || hasNoOutputDevice));
  }, [mediaDevices]);

  // TODO: request both at once!
  // console.log('mic state', micState);
  const handleClickMic = () => {
    switch (micState) {
      case 'prompt':
        navigator.getUserMedia({ audio: true }, noop, noop);
        break;

      case 'denied':
        // instructions on how to fix!
        break;

      case 'granted':
      default:
        break;
    }
  };

  // const handleClickCamera = () => {
  //   switch (cameraState) {
  //     case 'prompt':
  //       navigator.getUserMedia({ video: true }, noop, noop);
  //       break;
  //
  //     case 'denied':
  //       // instructions on how to fix!
  //       break;
  //
  //     case 'granted':
  //     default:
  //       break;
  //   }
  // };

  const handleClickBoth = () => {
    navigator.getUserMedia({ audio: true, video: true }, noop, noop);
  };

  // don't display if webphone error
  if (!isLineConnected || !isLineConnected()) {
    return null;
  }

  if (noInputDevice || noOutputDevice) {
    return (
      <Alert
        rounded={false}
        color={`attention`}
        reverse
        label={`Warning: No ${noInputDevice ? 'Input' : ''} 
              ${noInputDevice && noOutputDevice ? 'or' : ''} 
              ${noOutputDevice ? 'Output' : ''} 
              Device Found`}
        body={
          'Please make sure your device(s) are connected and permission has been granted.'
        }
        icon={<WarningTriangle />}
      />
    );
  }

  // dont show if either is in the "figureing out" stage, or if everything is golden
  if (
    // !micState ||
    // !cameraState ||
    micState === 'granted'
    // && cameraState === 'granted'
  ) {
    // all good!
    return null;
  }

  // not both?
  if (
    micState === 'prompt'
    // && cameraState === 'prompt'
  ) {
    return (
      <Alert
        rounded={false}
        color={`accent`}
        reverse
        label={`Microphone and speakers permissions required`}
        body={'CallingIO requires permission to make calls.'}
        actionProps={{ children: 'Grant permission', onClick: handleClickMic }}
        icon={<WarningTriangle />}
      />
    );
    // return (
    //   <div
    //     style={{
    //       borderBottom: '1px solid #ccc',
    //       background: 'rgba(250,250,250,0.8)',
    //       padding: 4,
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexWrap: 'nowrap',
    //       }}
    //     >
    //       <div style={{ flex: 1 }}>
    //         <Button
    //           onClick={handleClickBoth}
    //           variant="contained"
    //           color="success"
    //           fullWidth
    //           size="large"
    //         >
    //           Allow Mic and Speakers
    //         </Button>
    //       </div>
    //     </div>
    //   </div>
    // );
  }

  // denied
  return (
    <Alert
      rounded={false}
      color={`attention`}
      reverse
      label={`Microphone and speakers permissions required`}
      body={
        'CallingIO requires permission to make calls. Grant access when prompted by your browser or enable them manually in your browser settings'
      }
      // actionProps={{ children: 'Grant permission', onClick: handleClickMic }}
      icon={<WarningTriangle />}
    />
  );

  // return (
  //   <div
  //     style={{
  //       borderBottom: '1px solid #ccc',
  //       background: 'rgba(250,250,250,0.8)',
  //       padding: 4,
  //     }}
  //   >
  //     <div
  //       style={{
  //         display: 'flex',
  //         flexWrap: 'nowrap',
  //       }}
  //     >
  //       {/* Mic */}
  //       {micState === 'prompt' ? (
  //         <div style={{ flex: 1 }}>
  //           <Button
  //             variant="contained"
  //             color="success"
  //             fullWidth
  //             onClick={handleClickMic}
  //             size="large"
  //           >
  //             Allow Mic &amp; Speakers
  //           </Button>
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //       {micState === 'denied' ? (
  //         <div style={{ flex: 1 }}>
  //           <Button
  //             variant="outlined"
  //             color="warning"
  //             fullWidth
  //             onClick={handleClickMic}
  //           >
  //             Fix Mic &amp; Speakers
  //           </Button>
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //       {/* Camera */}
  //       {/* {cameraState === 'prompt' ? (
  //         <div style={{ flex: 1 }}>
  //           <Button
  //             variant="outlined"
  //             color="success"
  //             fullWidth
  //             onClick={handleClickCamera}
  //           >
  //             Allow Camera
  //           </Button>
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //       {cameraState === 'denied' ? (
  //         <div style={{ flex: 1 }}>
  //           <Button
  //             variant="outlined"
  //             color="warning"
  //             fullWidth
  //             onClick={handleClickCamera}
  //           >
  //             Fix Camera
  //           </Button>
  //         </div>
  //       ) : (
  //         ''
  //       )} */}
  //     </div>
  //   </div>
  // );
};

export default Permissions;

import { joiResolver } from '@hookform/resolvers/joi';
import {
  Alert,
  Box,
  DialogInProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Link,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import { useCreateDirectory } from 'app/hooks/mutations/directory';
import { useToggleReducer } from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect, useState } from 'react';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Directory } from 'types/directory';
import { useImmer } from 'use-immer';
import useDevMode from 'app/utilities/useDevMode';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  DialogHeader,
} from 'app/design-lib';

import constants from 'app/constants';
import { sdk } from 'app/sdk';

import copy from 'copy-to-clipboard';
import { useAuthSelector } from 'app/data/auth';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import QRCodeDisplay from 'react-qr-code';
import { isAndroid, isIOS } from 'react-device-detect';

// interface declaring which props are required/allowed
interface MobileAppDialogProps {
  // directoryId: string;
  open?: boolean;
  onCancel: () => void;
  // onComplete: () => void;
}

const MobileAppDialog = ({
  // directoryId,
  open,
  onCancel,
}: // onComplete,
MobileAppDialogProps) => {
  const { auth_token } = useAuthSelector();
  const { data: authUser } = useAuthenticatedUserQuery();

  const { devMode } = useDevMode();
  const qrcodeData = {
    auth_token,
    cio_api_url:
      constants.env.REACT_APP_CIO_QR_CODE_SERVER ||
      constants.env.REACT_APP_CIO_API_SERVER,
    kazoo_api_url: constants.env.REACT_APP_KAPI_URL_DIRECT, // REACT_APP_KAPI_URL (proxy)
    name: `${authUser?.extra?.fullName}`,
  };
  // console.log({ qrcodeData });
  const strForQrCode = JSON.stringify({
    type: 'v1',
    data: qrcodeData,
  });

  const mobileUrl = `com.callingio.app://login-via-mobile-browser?auth_token=${auth_token}&_t=${Date.now()}`;

  return (
    <Dialog size={'lg'} open={!!open} onClose={onCancel}>
      <DialogHeader
        onClose={onCancel}
        title={` Mobile App`}
        subTitle={`
        Make and receive business calls from your mobile phone!
      `}
      />
      {/* <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Mobile App QR Code</Typography>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined" onClick={onCancel}>
              Done
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider /> */}
      <DialogContent
        className={`flex flex-col items-center space-y-4 justify-center`}
      >
        {isIOS || isAndroid || false ? (
          <div className="">
            <div className="mb-4 text-neutral-800">
              Get CallingIO{' '}
              <a
                target="_blank"
                href={'https://apps.apple.com/us/app/callingio/id6444456109'}
                className="text-blue-400 hover:text-blue-500"
                rel="noreferrer"
              >
                from the App Store
              </a>
              , then
            </div>

            <div className="">
              <a
                href={mobileUrl}
                className="rounded border p-2 active:bg-blue-100 border-blue-400"
              >
                Launch Mobile App
              </a>
            </div>
          </div>
        ) : (
          <div className={`flex flex-col text-center space-y-6 items-center`}>
            <p className={`font-medium text-md`}>
              <a
                target="_blank"
                href={'https://apps.apple.com/us/app/callingio/id6444456109'}
                className="text-blue-400 hover:text-blue-500"
                rel="noreferrer"
              >
                Get CallingIO from the App Store
              </a>
            </p>
            {process.env.NODE_ENV === 'development' || devMode ? (
              <div
                onClick={() => {
                  // copy(strForQrCode);
                  // alert(`Copied: ${strForQrCode}`);
                }}
                className="py-4 pt-2 text-center [&>svg]:mx-auto"
              >
                {strForQrCode ? (
                  <QRCodeDisplay value={strForQrCode} size={240} />
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </DialogContent>
      {/*<DialogContent>
        <div style={{}}>
           <Alert severity="error">
            Sorry, the mobile app is not currently ready for QA, please skip it.
          </Alert>
          <br />

           <div className="text-md">
            Our mobile app integrates deeply into your phone, giving you access
            to your CallingIO Contacts on the go, and enabling you to make and
            receive calls as if you were in the office.
          </div>

          <div className="text-lg">Step 1: Request Testflight Invite</div>
          <div className="text-md">
            Our mobile app is almost ready! Email us at support@calling.io and
            we'll send you instructions on how to get TestFlight and download
            the CallingIO app.
          </div>
          <div className="text-md text-neutral-60 my-2">
            Android coming soon too!
          </div>
          <div className="mt-2 center">
             TestFlight
             <img
              alt={''}
              src="https://ucarecdn.com/79e85a6b-5b49-48b0-addc-07c54cb438fa/testflightbadge.png"
              style={{ height: 48, margin: '0 auto' }}
            />
             &nbsp;&nbsp;
            <img
              src="https://ucarecdn.com/36cdd446-a278-41e4-b19d-9ffc87b857f7/Available_on_the_App_Store_black.png"
              style={{ height: 48 }}
            />
            &nbsp;&nbsp;
            <img
              src="https://ucarecdn.com/22b588ac-e4a9-4220-86aa-05666289f730/getitongoogleplaybadge.png"
              style={{ height: 48 }}
            />
          </div>
          <br />
          <div className="text-lg">Step 2: Open the Mobile App</div>
          <div className="text-md">
            After downloading and opening the mobile app, it will ask you to
            point your phone at the QR code below:
          </div>
          <div
            onClick={() => {
              // copy(strForQrCode);
              // alert(`Copied: ${strForQrCode}`);
            }}
            className="py-4 text-center [&>svg]:mx-auto"
          >
            <QRCodeDisplay value={strForQrCode} size={240} />
          </div>
          <br />
          <div className="text-md">...and you are all done!</div>
          <div className="text-md mt-2">
            If you need to login to the mobile app again, you can repeat this
            process anytime.
          </div>

           <br />
            <Button onClick={callgithub} variant="outlined">
              Test Branding Build
            </Button>
        </div>
      </DialogContent>*/}

      {/* <DialogActions>
        <Button size="md" color={'accent'} variant="fill" onClick={onCancel}>
          Done
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export const useMobileAppDialog = ({ initialOpen = false }) => {
  const [open, toggleOpen] = useToggleReducer(initialOpen);

  const MobileAppDialogProps = {
    open,
    onCancel: toggleOpen,
  };

  return {
    toggleOpen,
    MobileAppDialog,
    MobileAppDialogProps,
  };
};

export default MobileAppDialog;

import { IconButton } from 'app/design-lib/components/IconButton';
import React, { ReactNode, useState } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import { IconButtonProps } from 'app/design-lib/components/IconButton/IconButton';
import { ButtonProps } from 'app/design-lib/components/Button/Button';
import { Button } from 'app/design-lib/components/Button';

export type PopoverProps = {
  iconButtonProps?: IconButtonProps;
  children: ReactNode;
  buttonProps?: ButtonProps;
  rounded?: boolean;
  arrow?: boolean;
};

export const Popover = ({
  iconButtonProps,
  buttonProps,
  children,
  arrow = false,
  rounded = true,
}: PopoverProps) => {
  return (
    <RadixPopover.Root modal={true}>
      <RadixPopover.Trigger asChild>
        {/*  @ts-ignore */}
        {iconButtonProps ? (
          <IconButton
            size={'sm'}
            color={'neutral'}
            variant={'ghost'}
            pill
            {...iconButtonProps}
          />
        ) : (
          // @ts-ignore
          <Button {...buttonProps} />
        )}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          className={`z-20 bg-white drop-shadow p-2 border border-neutral-20 rounded-full ${
            rounded ? 'rounded-full' : 'rounded-md'
          }`}
          sideOffset={14}
          side={'top'}
        >
          {children}
          {arrow ? (
            <RadixPopover.Arrow className="fill-white stroke-neutral-20" />
          ) : null}
        </RadixPopover.Content>
      </RadixPopover.Portal>
      {/* <RadixPopover.Close className="RadixPopoverClose" aria-label="Close">
        <Cross2Icon />
      </RadixPopover.Close>*/}
    </RadixPopover.Root>
  );
};

export default Popover;

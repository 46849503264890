import {
  getProvisionerBrandsAndModels,
  getProvisionerPhoneModel,
} from 'app/sdk/utils/kazoo/getProvisionerPhoneModel';

const exports = {
  // Queries
  query: {
    provisionerPhoneModel: (vars, fetchOptions) =>
      getProvisionerPhoneModel(vars, fetchOptions),
    provisionerBrandsAndModels: (vars, fetchOptions) =>
      getProvisionerBrandsAndModels(vars, fetchOptions),
  },
};

export default exports;

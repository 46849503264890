import {
  DeviceSubTypes,
  DeviceTypes,
} from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/AddPhonesBulkForm';
import { AddPhonesFormFields } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/types';
import { useCreateCallflow } from 'app/hooks/mutations/callflow';
import { useCreateDevice } from 'app/hooks/mutations/device';
import { useAccountQuery } from 'app/hooks/queries/account';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import { UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { useImmer } from 'use-immer';
import { v4 as uuidv4 } from 'uuid';

interface AddPhonesBulkParams {
  onComplete: () => void;
  onError: () => void;
  setValue: UseFormSetValue<AddPhonesFormFields>;
  setError: UseFormSetError<AddPhonesFormFields>;
}

export const useAddPhonesBulk = ({
  onComplete,
  onError,
  setValue,
  setError: setFormError,
}: AddPhonesBulkParams) => {
  const { data: accountData } = useAccountQuery();
  const createDevice = useCreateDevice();
  const createCallflow = useCreateCallflow();
  const [isLoading, setIsLoading] = useImmer(false);
  const [isSuccess, setIsSuccess] = useImmer(false);
  const [error, setError] = useImmer<Error | null>(null);

  const mutateAsync = async (form: AddPhonesFormFields) => {
    setIsLoading(true);
    setError(null);
    setIsSuccess(false);

    const { phones } = form;

    // @ts-ignore
    for (let [index, phoneFields] of phones.entries()) {
      let device, deviceMainCallflow;
      // skip if phone already saved
      if (
        phoneFields.save_status === 'saved' ||
        phoneFields.save_status === 'partial_error'
      ) {
        continue;
      }

      const fieldName = `phones.${index}`;

      // console.log(`user_${uuidv4()}`.substring(0, 32), 'test');

      try {
        // @ts-ignore
        setValue(`${fieldName}.save_status`, 'saving');

        const [type, subtype] =
          phoneFields.device_type === 'sip_device'
            ? ['sip_device', undefined]
            : phoneFields.device_type.split('_');

        try {
          const deviceResp = await createDevice.mutateAsync({
            device_type: type as DeviceTypes,
            subtype: subtype as DeviceSubTypes,
            enabled: true,
            mac_address: phoneFields.mac_address,
            name: phoneFields.name,
            owner_id: phoneFields.owner_id,
            // provision: {
            //   endpoint_brand: phoneFields.provision.brand, // 'yealink',
            //   endpoint_family: phoneFields.provision.family, //'t2x',
            //   endpoint_model: phoneFields.provision.model, //'t21pe2',
            // },
            sip: {
              // password: 'password123456',
              password: uuidv4().substring(0, 32),
              realm: accountData?.doc.realm!,
              // username: 'user38dks193',
              username: `user_${uuidv4()}`.substring(0, 32),
            },
            suppress_unregister_notifications: false,
          });
          if (deviceResp?.status !== 'success') {
            throw new Error(deviceResp);
          }
          device = deviceResp.data;
        } catch (err: any) {
          parseAndSetKazooMutationErrors({
            response: err.response,
            setError: setFormError,
            prefix: `phones.${index}`,
          });

          console.error('Failed saving device', err);
          throw new Error('Failed saving device');
        }

        // // Create Phone Main Callflow
        // // - build from template?
        // // console.log('user:', user);
        // try {
        //   const deviceMainCallflowResp = await createCallflow.mutateAsync({
        //     numbers: [],
        //     owner_id: device.id,
        //     owner_type: 'device',
        //     type: 'main',
        //     strategy: {
        //       type: 'blank',
        //       data: {
        //         modules: [],
        //       },
        //     },
        //   });
        //   // console.log('deviceMainCallflowResp', deviceMainCallflowResp);
        //   if (deviceMainCallflowResp?.success !== true) {
        //     throw new Error(deviceMainCallflowResp);
        //   }
        //   deviceMainCallflow = deviceMainCallflowResp.data;
        // } catch (err) {
        //   // TODO: since graphql mutation not alot of info to display
        //   // as to why failed

        //   // @ts-ignore
        //   // setValue(`${fieldName}.save_status`, 'partial_error');
        //   console.error('Failed saving deviceMainCallflow', err);
        //   throw new Error('Failed saving deviceMainCallflow');
        // }

        // all mutations succeeded
        // @ts-ignore
        setValue(`${fieldName}.save_status`, 'saved');
        // @ts-ignore
        setValue(`${fieldName}.final_id`, device.id);
      } catch (err: any) {
        onError();
        setError(err);
        setIsLoading(false);

        // set values separately to not overwite other error data

        // @ts-ignore
        setValue(`${fieldName}.save_error.msg`, err.message);
        setValue(
          // @ts-ignore
          `${fieldName}.save_error.callflow_saved`,
          !!deviceMainCallflow,
        );

        setValue(
          // @ts-ignore
          `${fieldName}.save_status`,
          device ? 'partial_error' : 'error',
        );
        // @ts-ignore

        return;
      }
    }
    onComplete();
    setIsLoading(false);
    setIsSuccess(true);
  };

  return {
    mutateAsync,
    isLoading,
    error,
    isSuccess,
  };
};

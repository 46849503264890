import { gql } from 'graphql-request';

export default gql`
  query StatsUsers(
    $adminFilters: JSON
    $userFilters: JSON
    $suspendedFilters: JSON
  ) {
    users(filters: $userFilters) {
      totalCount
    }
    admin: users(filters: $adminFilters) {
      totalCount
    }
    suspended: users(filters: $suspendedFilters) {
      totalCount
    }
    groups {
      totalCount
    }
  }
`;

import { ScheduleCreateForm } from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
import { SchedulePlateEditor } from 'app/components/SchedulePlateEditor';
import { TIMEZONE_LIST } from 'app/components/TimezoneDialogAccount/TimezoneDialogAccount';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from 'app/design';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// interface ScheduleTemplateSelectProps {}

// const getItemStyle = (isDragging, draggableStyle) => ({
//   // // some basic styles to make the items look a bit nicer
//   // userSelect: 'none',
//   // padding: grid * 2,
//   // margin: `0 0 ${grid}px 0`,
//   //
//   // // change background colour if dragging
//   // background: isDragging ? 'lightgreen' : 'grey',
//
//   // styles we need to apply on draggables
//   ...draggableStyle,
// });

const ScheduleTemplateEditor = () => {
  const { control, watch, register } = useFormContext<ScheduleCreateForm>();

  const [timesWatch, templateWatch, timezoneWatch] = watch([
    'times',
    'template',
    'timezone',
  ]);

  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item style={{ flex: 1 }}>
          <TextField label={'Name'} {...register('name', { required: true })} />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="timezone"
            control={control}
            // @ts-ignore
            // defaultValue={TIMEZONE_LIST.find(tz => tz.value === timezoneWatch)}
            // rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                options={TIMEZONE_LIST}
                getOptionLabel={option => option.label}
                // @ts-ignore
                value={TIMEZONE_LIST.find(tz => tz.value === timezoneWatch)}
                onChange={(event, tz) => {
                  onChange(tz?.value);
                }}
                renderInput={params => {
                  // @ts-ignore
                  params.InputLabelProps.shrink = true;
                  return (
                    <TextField
                      {...params}
                      label={'Timezone'}
                      placeholder={'Inherit from Account'}
                      fullWidth
                    />
                  );
                }}
              />
            )}
          />
          {/*{timezoneWatch*/}
          {/*  ? getTimezoneName(timezoneWatch).replace('_', ' ')*/}
          {/*  : 'Inherits Timezone from Account if not set.'}*/}
          {/*<Link onClick={toggle} color={'primary'}>*/}
          {/*  {timezoneWatch ? 'Change Timezone' : 'Set Timezone'}*/}
          {/*</Link>*/}
        </Grid>
      </Grid>
      <br />
      {/* <Typography
        color={'gray.main'}
        variant={'body1'}
      >
        Add, remove, and replace Temporal Rule hours.
      </Typography>
      <br />
      <br /> */}
      <Box>
        {timesWatch.map((time, index) =>
          time.isElse ? null : (
            <Controller
              control={control}
              // @ts-ignore
              name={`times.${index}.rule.data.value`}
              render={({ field: { onChange, value, ...props } }) => {
                return (
                  <Card
                    key={time.id}
                    variant={'outlined'}
                    sx={{
                      mb: index === timesWatch.length - 2 ? 0 : 1,
                    }}
                  >
                    <CardHeader title={time.name} />
                    <CardContent>
                      <div
                        style={{
                          padding: 10,
                          marginLeft: 35,
                          borderRadius: 5,
                          border: '1px solid rgba(0,0,0,0.2)',
                          background: '#fcfcfc',
                        }}
                      >
                        <SchedulePlateEditor
                          id={`edit_${time.id}_${templateWatch}_${index}`}
                          initialValue={time.rule?.data.value}
                          onChange={onChange}
                        />
                      </div>
                    </CardContent>
                  </Card>
                );
              }}
            />
          ),
        )}
      </Box>
      <Divider textAlign="center" sx={{ my: 1 }}>
        Otherwise
      </Divider>
      <div>
        <Card key={'closed'} variant={'outlined'}>
          <CardHeader title={timesWatch.find(time => time.isElse).name} />
          <CardContent>
            <Typography variant={'body1'}>
              This will match when none of the above categories do
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ScheduleTemplateEditor;

import { Listbox } from '@headlessui/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, MenuItem, Select } from 'app/design';
import { Button, TextField } from 'app/design-lib';
import React from 'react';
import { Controller } from 'react-hook-form';
import { MediaType, VoiceOptions } from 'types/media';

export const voices = [
  {
    value: VoiceOptions.femaleEnglishUS,
    label: 'Female - English US',
  },
  {
    value: VoiceOptions.maleEnglishUS,
    label: 'Male - English US',
  },
];

const TextToSpeech = ({ register, control }) => {
  return (
    <>
      <TextField
        label={'TTS'}
        defaultValue={''}
        fullWidth
        multiline
        /* EXPECTING REGISTER FROM REACT HOOK FORM */
        {...register(`${MediaType.Tts}.text`, {
          // required,
        })}
      />
      <br />
      <div className={'flex items-center w-full justify-center space-x-2'}>
        <span className={'text-sm'}>Voice:</span>
        <Controller
          control={control}
          name={`${MediaType.Tts}.voice`}
          render={({ field: { onChange, value } }) => (
            <div className={'relative w-min'}>
              <Listbox value={value} onChange={onChange}>
                <Listbox.Button
                  as={Button}
                  variant={'outline'}
                  endIcon={<ExpandMoreIcon />}
                >
                  {value}
                </Listbox.Button>
                <Listbox.Options className="absolute right-0 mt-2 p-1 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border-solid border border-border-neutral">
                  {voices.map(voice => (
                    <Listbox.Option
                      className={`cursor-pointer text-content-neutral flex space-x-2 items-center justify-start w-full font-medium bg-transparent hover:bg-background-neutral-active font-sans b p-2 px-3 m-0`}
                      key={voice.value}
                      value={voice.value}
                    >
                      {voice.label}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
            /*<Select
            value={value}
            onChange={onChange}
            // defaultValue={voices[0].value}
            {...register(`${MediaType.Tts}.voice`, {
              // required,
            })}
          >
            {voices.map(voice => (
              <MenuItem key={voice.value} value={voice.value}>
                {voice.label}
              </MenuItem>
            ))}
          </Select>*/
          )}
        />
      </div>
    </>
  );
};

export default TextToSpeech;

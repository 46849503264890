import { DeviceCodecsDialog } from 'app/components/DeviceCodecsDialog';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { Alert, Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import { Cancel, EditPencil, InfoEmpty } from 'iconoir-react';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceCodecsProps {
  device: Device;
  onComplete: () => void;
}

const DeviceCodecs = ({ device, onComplete }: DeviceCodecsProps) => {
  const [showEditDialog, toggleEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'codecs',
    options: [
      {
        label: 'Codecs',
        value: 'codecs',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });
  const handleComplete = () => {
    toggleEditDialog();
    onComplete();
  };

  return (
    <>
      <DeviceCodecsDialog
        DialogProps={{
          open: showEditDialog,
        }}
        ContentProps={{
          deviceId: device.id,
          onComplete: handleComplete,
          onCancel: toggleEditDialog,
        }}
      />
      <SettingsPanel
        leftChild={
          <Setting
            label={'Device Codecs'}
            body={'Specify device codecs for audio and video.'}
            iconButtonProps={
              showEditDialog
                ? undefined
                : {
                    children: <EditPencil fr={undefined} />,
                    onClick: toggleEditDialog,
                  }
            }
            buttonProps={
              showEditDialog
                ? {
                    children: 'Cancel',
                    startIcon: <Cancel fr={undefined} />,
                    color: 'neutral',
                    onClick: toggleEditDialog,
                  }
                : undefined
            }
          />
        }
        rightChild={
          <>
            <Table>
              <TableRow showBorder>
                <TableCell className={`font-medium`}>Video Codecs</TableCell>
                <TableCell className={``}>
                  {device?.doc?.media?.video?.codecs?.length
                    ? device.doc.media.video.codecs.join(', ')
                    : 'Default'}
                </TableCell>
              </TableRow>
              <TableRow showBorder={false}>
                <TableCell className={`font-medium`}>Audio Codecs</TableCell>
                <TableCell className={``}>
                  {device?.doc?.media?.audio?.codecs?.length
                    ? device.doc.media.audio.codecs.join(', ')
                    : 'Default'}
                </TableCell>
              </TableRow>
            </Table>
            {/*{device?.doc?.media?.video?.codecs?.length ? (
              device.doc.media.video.codecs.join(', ')
            ) : (
              <Alert
                reverse
                icon={<InfoEmpty fr={undefined} />}
                label={'Default Codecs'}
                body={`Using platform codecs.`}
              />
            )}*/}
          </>
        }
      />
    </>
  );

  // return (
  //   // @ts-ignore
  //   <div ref={ref}>
  //     <HighlightPulse {...highlightPulseProps} />
  //     <DeviceCodecsDialog
  //       DialogProps={{
  //         open: showEditDialog,
  //       }}
  //       ContentProps={{
  //         deviceId: device.id,
  //         onComplete: handleComplete,
  //         onCancel: toggleEditDialog,
  //       }}
  //     />
  //     <GenericSettingsCardField
  //       title={'Audio Codecs'}
  //       content={
  //         device?.doc?.media?.audio?.codecs?.length
  //           ? device.doc.media.audio.codecs.join(', ')
  //           : undefined
  //       }
  //       editLabel={'Edit Codecs'}
  //       editOnClick={toggleEditDialog}
  //     />
  //     <br />
  //     <GenericSettingsCardField
  //       title={'Video Codecs'}
  //       content={
  //         device?.doc?.media?.video?.codecs?.length
  //           ? device.doc.media.video.codecs.join(', ')
  //           : undefined
  //       }
  //     />
  //   </div>
  // );
};

export default DeviceCodecs;

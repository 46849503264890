import { Box, Grid } from 'app/design';
import {
  Phone as PhoneIcon,
  LeftRoundArrow as BackspaceIcon,
  HelpCircle,
} from 'iconoir-react';
import React from 'react';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import {
  useWebphoneSelector,
  useWebphoneSlice,
} from '../../../../data/webphone';
import { Button, IconButton } from '../../../../design-lib';
import { useKeypadSounds } from '../../../../hooks/general/useKeypadSounds';

const Numpad = props => {
  const { play, stop } = useKeypadSounds();
  const { settings, callingDisabled } = useWebphoneSelector();
  const {
    onClick,
    onCall,
    toggleFeatureCodes,
    showFeatureCodes,
    disableCall,
    light,
    onDelete,
    disableBackspace,
  } = props;

  const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'];
  const chars = [
    '',
    'abc',
    'def',
    'ghi',
    'jkl',
    'mno',
    'pqrs',
    'tuv',
    'wxyz',
    '',
    '',
    '',
  ];

  const handleClick = num => e => {
    onClick(num);

    if (settings.keypad?.disableSounds) return;

    play({ key: num, volume: settings.keypad?.volume });

    setTimeout(() => {
      stop();
    }, 200);
  };

  return (
    <div
      style={{
        padding: 16,
        width: '100%',
        // background: light ? undefined : '#285E70',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <div
        // container
        // spacing={1}
        // justifyContent={'space-between'}
        // sx={{ width: '100%' }}
        className="grid w-full grid-cols-3 gap-3"
      >
        {nums.map((num, i) => (
          <div
            key={num}
            // item
            // xs={4}
            // sx={{
            //   // background: 'green',
            //   display: 'grid',
            //   placeItems: 'center',
            // }}
            className="w-full grid place-items-center"
          >
            {/* <span style={numButtonStyle} onClick={handleClick(num)}> */}
            <Button
              variant={'ghost'}
              color={'neutral'}
              className="w-full h-[60px] grid place-items-center"
              onClick={handleClick(num)}
              // variant={'outlined'}
              // sx={{
              //   height: 75,
              //   width: '100%',
              //   // background: 'red',
              //   // borderRadius: 90,
              //   borderColor: '#285E70',
              //   display: 'grid',
              //   placeItems: 'center',
              // }}
            >
              <div
                className={
                  'flex flex-col justify-center items-center text-center h-[40px]'
                }
                // sx={{
                //   textAlign: 'center',
                //   // color: light ? '#285E70' : '#ffffff',
                //   height: 40,
                // }}
              >
                <div className={'text-lg text-neutral-700 font-medium'}>
                  {num}
                </div>
                <div className={'text-md text-neutral-300'}>
                  &nbsp;{chars[i]}&nbsp;
                </div>
              </div>
            </Button>
            {/* </span> */}
          </div>
        ))}
        <div className={`w-full grid place-items-center`}>
          {onDelete ? (
            <IconButton
              variant={'ghost'}
              // sx={{ borderRadius: 5 }}
              color={'neutral'}
              disabled={disableBackspace}
              size={'md'}
              className={'w-full'}
              onClick={onDelete}
            >
              <BackspaceIcon />
            </IconButton>
          ) : null}
        </div>
        <div className={`w-full grid place-items-center`}>
          {onCall ? (
            <IconButton
              variant={'fill'}
              // sx={{ borderRadius: 5 }}
              color={'accent'}
              disabled={callingDisabled || disableCall}
              size={'md'}
              className={'w-full'}
              onClick={onCall}
            >
              <PhoneIcon />
            </IconButton>
          ) : null}
        </div>
        <div className={`w-full grid place-items-center`}>
          {/* {toggleFeatureCodes ? (
            <IconButton
              variant={'ghost'}
              // sx={{ borderRadius: 5 }}
              color={'neutral'}
              size={'md'}
              className={'w-full'}
              onClick={toggleFeatureCodes}
            >
              <HelpCircle fr={undefined} />
            </IconButton>
          ) : null}*/}
        </div>
      </div>
      {/*<br />*/}
      {/*<Box sx={{ width: '80%' }}>
        <Grid container alignItems={'center'} columnSpacing={4}>
          <Grid item xs={3}>
             <Tooltip
              arrow
              placement="left"
              title={`${showFeatureCodes ? 'Hide' : 'View'} Feature Codes`}
            >
              <IconButton
                onClick={toggleFeatureCodes}
                sx={{
                  color: `rgba(255, 255, 255, ${
                    showFeatureCodes ? '1' : '0.3'
                  })`,
                  textDecorationColor: '#fff',
                }}
              >
                <SettingsPhoneIcon fontSize="20" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            {onCall ? (
              <Button
                variant={'fill'}
                // sx={{ borderRadius: 5 }}
                color={'positive'}
                disabled={callingDisabled || disableCall}
                size={'lg'}
                className={'w-full'}
                startIcon={<PhoneIcon />}
                onClick={onCall}
              >
                Call
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={3}>
            {onDelete ? (
              <Button
                variant={'ghost'}
                // sx={{ borderRadius: 5 }}
                color={'negative'}
                disabled={disableBackspace}
                size={'lg'}
                className={'w-full'}
                onClick={onDelete}
              >
                <BackspaceIcon />
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Box>*/}
    </div>
  );

  // return (
  //   <div style={{ padding: 4 }}>
  //     <Grid container spacing={1}>
  //       {nums.map((num, i) => (
  //         <Grid key={num} item xs={4} onClick={handleClick(num)}>
  //           {/* <span style={numButtonStyle} onClick={handleClick(num)}> */}
  //           <Box
  //             sx={{
  //               position: 'relative',
  //               borderRadius: 2,
  //               // background: 'rgb(236, 244, 254)',
  //               // background: theme.palette.info.light,
  //               background: 'rgb(227, 252, 255)', // e3fcff
  //               textAlign: 'center',
  //               fontSize: 18,
  //               fontWeight: 'bold',
  //               '&:hover': {
  //                 background: theme.palette.primary.main, //'rgb(200, 222, 254)',
  //               },
  //               '&:active': {
  //                 background: theme.palette.primary.dark, //'rgb(72, 148, 252)',
  //               },
  //               padding: '8px',
  //               cursor: 'pointer',
  //             }}
  //           >
  //             {num}
  //             <Box
  //               sx={{
  //                 position: 'absolute',
  //                 display: 'inline',
  //                 verticalAlign: 'super',
  //                 fontSize: 12,
  //                 fontWeight: 200,
  //                 paddingLeft: 1,
  //               }}
  //             >
  //               {chars[i] ?? ''}
  //             </Box>
  //           </Box>
  //           {/* </span> */}
  //         </Grid>
  //       ))}
  //     </Grid>
  //
  //   </div>
  // );
};

export default Numpad;

import { Box } from 'app/design';
import React from 'react';

const CommentMark = props => {
  const { children, attributes, nodeProps, element } = props;

  return (
    <Box
      component={'span'}
      sx={{ color: 'orange' }}
      {...attributes}
      {...nodeProps}
    >
      {children}
    </Box>
  );
};

export default CommentMark;

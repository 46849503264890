import { useAuthSelector } from 'app/data/auth';
import scheduleQueryKeys from 'app/hooks/queries/schedule/scheduleQueryKeys';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { PhoneNumbersPageResponse } from 'types/phoneNumber';
import { SchedulesPageResponse } from 'types/schedule';

interface QueryParams {
  skip?: number;
  take?: number;
  orderBy?: any;
  filters?: any;
  options?: Omit<
    UseQueryOptions<
      SchedulesPageResponse,
      Error,
      SchedulesPageResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >;
}

export const useListSchedulesQuery = ({
  skip = 0,
  take = 10000,
  orderBy = [['name', 'asc']],
  filters = {},
  options,
}: QueryParams) => {
  // returns list of schedules
  // - just get ALL schedules+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = scheduleQueryKeys.list(skip, take, orderBy, filters);

  const query = useQuery<SchedulesPageResponse, Error>(
    queryKey,
    () =>
      sdk.schedule.query.schedules(
        {
          skip,
          take,
          orderBy,
          filters,
        },
        { authToken: authState.auth_token },
      ),
    {
      // enabled: authState?.auth_token_decoded?.owner_id ? true : false,

      // prevents pagination from looking like a hard load;
      //  - keeps previous data displayed until new data arrives
      keepPreviousData: true,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useListSchedulesQuery;

import React, { memo, useState, useRef, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Grid,
  Typography,
  Button,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputTextField,
} from 'app/design';

import {
  NavigateNext as NavigateNextIcon,
  Today as TodayIcon,
} from 'app/design/icons-material';

import { Box } from '../common/Box';
import { MenuButton } from '../common/MenuButton';
import { InTemplate } from '../common/InTemplate';

import { getAtPath, setAtPath, randomString } from 'app/utilities';

import { useHoverDirty } from 'react-use';
import { snakeCase } from 'lodash';

import {
  InsertAfterButton,
  InsertBeforeButton,
  RemoveButton,
  ModifyButton,
} from '../Insert';

const TemplateEndpointNode = memo(({ data }) => {
  const {
    insertBefore,
    insertAfterData, // used for removal
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
  } = data;

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  useEffect(() => {
    // set defaults if not already set
    if (!moduleItem?.data?.id) {
      setAtPath(
        callflow,
        `${modifyPath}.data.id`,
        randomString(6).toUpperCase(),
      );
      setCallflow({ ...callflow });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleItem?.data?.id]);

  const handleChangeId = () => {
    let id = window.prompt('Endpoint ID:', moduleItem?.data?.id);
    if (!id) {
      return false;
    }
    id = snakeCase(id).toUpperCase();
    setAtPath(callflow, `${modifyPath}.data.id`, id);
    setCallflow({ ...callflow });
  };

  return (
    <>
      {/* {showModifyDialog && (
        <ModifySettingsDialog
          {...data}
          scheduleCallflow={scheduleCallflow}
          onClose={setShowModifyDialogWrap(null)}
        />
      )} */}
      <Box
        pref={boxRef}
        borderColor="red"
        position={data?.position}
        data={data}
      >
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div style={{ padding: 8, textAlign: 'center' }}>
          <div>
            Endpoint: <strong>{moduleItem?.data?.id}</strong>
          </div>
          <>
            <MenuButton
              data={data}
              mode={isHovering ? 'normal' : 'ignore'}
              menuItems={[
                {
                  type: 'modify',
                  props: { onClick: handleChangeId },
                },
                // 'add',
                'add-before',
                'duplicate',
                'remove',
              ]}
            />
            {/* <RemoveButton
              {...insertAfterData}
              mode={isHovering ? 'normal' : 'ignore'}
            />
            <ModifyButton
              onClick={setShowModifyDialogWrap(true)}
              mode={isHovering ? 'normal' : 'ignore'}
            />
            {insertBefore && (
              <InsertBeforeButton
                insertAfterData={insertAfterData}
                mode={isHovering ? 'normal' : 'ignore'}
              />
            )} */}
          </>
        </div>
        <Handle
          type="source"
          position="bottom"
          // id="b"
          style={{
            top: 'auto',
            bottom: 1,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </Box>
    </>
  );
});

export default TemplateEndpointNode;

import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  NoteAdd as NoteAddIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

// import { KazooSDK } from '@KazooSDK';
// import { getAtPath, setAtPath, randomString } from '@KazooSDK/utils';

// import { useSetupHook } from '../../../../../../SetupHook';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../../..';

import { findDeep } from 'deepdash-es/standalone';
// import { ToastSaving } from '@Util/toast';

export const MenuItem_CreateTemplateFrom = props => {
  const { data, mode, onClose } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    currentCallflow,
    componentData,
    componentInfo,
    // callflow,
    // setCallflow,
    modifyPath,
  } = data;

  const history = useHistory();

  // const { sync, lists, buildAndSaveCallflow } = useSetupHook();
  // const { callflow, setCallflow, modifyPath } = insertAfterData;

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const ee = useContext(IvrMenuEventEmitterContext);

  const handleCreateTemplateFrom = async () => {
    // // currently verifies that a Template is not part of what we are creating from!
    // const componentInfo =
    //   currentCallflow.strategy.config.components.slice(infoIdx);
    // const componentData = currentCallflow.strategy.data.opts.slice(infoIdx);
    // const foundTemplate =
    //   findDeep(componentInfo, (value, key, parentValue, ctx) => {
    //     if (key === 'type' && value === 'Template') {
    //       return true;
    //     }
    //   }) ||
    //   findDeep(componentData, (value, key, parentValue, ctx) => {
    //     if (key === 'type' && value === 'Template') {
    //       return true;
    //     }
    //   });
    // if (foundTemplate) {
    //   window.alert(
    //     'Sorry, cannot create a Template from a flow that already includes a Template',
    //   );
    //   return false;
    // }
    // let name = window.prompt('New Template Name:', `Template ${Date.now()}`);
    // if (!name) {
    //   return false;
    // }
    // let saveToast = ToastSaving({ title: 'Creating, Please Wait...' });
    // // TODO: include variables also!?
    // const newTemplateCallflow = {
    //   name,
    //   type: 'template',
    //   numbers: [`template:tmp:${Date.now()}:${randomString(4)}`],
    //   flow: { module: 'callflow', data: { id: 'invalid' } },
    //   strategy: {
    //     id: 'blank',
    //     config: {
    //       components: componentInfo,
    //     },
    //     data: {
    //       opts: componentData,
    //     },
    //   },
    // };
    // try {
    //   const callflowCreateResponse = await KazooSDK.resourceCallflowCreate(
    //     newTemplateCallflow,
    //   );
    //   await buildAndSaveCallflow(callflowCreateResponse.data.data);
    //   await sync(['callflows']);
    //   saveToast.saved({ title: 'Created, Redirecting' });
    //   history.push(
    //     `/user_portal/admin_strategy?strategyCallflowId=${callflowCreateResponse.data.data.id}`,
    //   );
    // } catch (err) {
    //   saveToast.error('Failed Creating');
    // }
  };

  return (
    <MenuItem
      onClick={e => {
        onClose();
        handleCreateTemplateFrom();
      }}
      component={'a'}
      disabled
    >
      <ListItemIcon>
        <NoteAddIcon />
      </ListItemIcon>
      <ListItemText primary="Create Template" />
    </MenuItem>
  );
};

export default MenuItem_CreateTemplateFrom;

import { BulkActionsUsers } from 'app/components/ListUsers/components/BulkActions';
import {
  Delete as DeleteIcon,
  PersonRemove as PersonRemoveIcon,
  Person as PersonIcon,
} from 'app/design/icons-material';
import { ChangeOwnerBulkDevices } from 'app/components/ListDevices/components/BulkActionsDevices/components/ChangeOwnerBulkDevices';
import { OptionsTableCell } from 'app/components/OptionsTableCell';
import {
  useDeleteDevice,
  useUpdateDevicePartial,
} from 'app/hooks/mutations/device';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Device } from 'types/device';
import { useImmer } from 'use-immer';
import { BulkActionsDevices } from '../BulkActionsDevices';

interface DeviceListOptionsProps {
  device: Device;
  onComplete: () => void;
  onDelete: any;
  toggleRowSelected: (id: string, set: boolean) => void;
}

const DeviceListOptions = ({
  toggleRowSelected,
  onComplete,
  device,
  onDelete,
}: DeviceListOptionsProps) => {
  return (
    <BulkActionsDevices
      vertical
      onComplete={() => {
        onComplete();
        onDelete();
      }}
      toggleRowSelected={toggleRowSelected}
      selectedRows={[device]}
    />
  );

  // const [showDialog, setShowDialog] = useImmer<null | string>(null);
  // const deleteDevice = useDeleteDevice();
  // const updateDevice = useUpdateDevicePartial();
  //
  // const handleDelete = () => {
  //   const createDevicePromise = deleteDevice.mutateAsync(device.id, {
  //     onSuccess: async resp => {
  //       onComplete();
  //       onDelete();
  //       console.log('delete device resp', resp);
  //     },
  //     onError: error => console.error(error),
  //   });
  //
  //   toast.promise(createDevicePromise, {
  //     pending: 'Deleting device...',
  //     success: 'Device deleted!',
  //     error: 'Error deleting device.',
  //   });
  // };
  //
  // const handleRelinquish = () => {
  //   const updateDevicePromise = updateDevice.mutateAsync(
  //     { id: device.id, owner_id: null },
  //     {
  //       onSuccess: async resp => {
  //         onComplete();
  //       },
  //       onError: error => console.error(error),
  //     },
  //   );
  //
  //   toast.promise(updateDevicePromise, {
  //     pending: 'Updating device...',
  //     success: 'Device updated!',
  //     error: 'Error updating device.',
  //   });
  // };
  //
  // const handleOnComplete = () => {
  //   onComplete();
  //   setShowDialog(null);
  // };
  //
  // const handleCancel = () => {
  //   setShowDialog(null);
  // };
  //
  // return (
  //   <>
  //     {showDialog === 'owner' ? (
  //       <ChangeOwnerBulkDevices
  //         selectedRows={[device]}
  //         onComplete={handleOnComplete}
  //         onCancel={handleCancel}
  //       />
  //     ) : null}
  //     <OptionsTableCell
  //       options={[
  //         ...(device.doc.device_type === 'sip_device' && device.doc.owner_id
  //           ? [
  //               {
  //                 label: 'Release To Device Pool',
  //                 icon: <PersonRemoveIcon />,
  //                 onClick: handleRelinquish,
  //               },
  //               {
  //                 label: 'Change Owner',
  //                 icon: <PersonIcon />,
  //                 onClick: () => setShowDialog('owner'),
  //               },
  //             ]
  //           : []),
  //         { label: 'Delete', icon: <DeleteIcon />, onClick: handleDelete },
  //       ]}
  //     />
  //   </>
  // );
};

export default DeviceListOptions;

import { account } from './resources/account';
import { calls } from './resources/calls';
import { callDetailRecord } from './resources/callDetailRecord';
import { callflow } from './resources/callflow';
import { contact } from './resources/contact';
import { contactList } from './resources/contactList';
import { conference } from './resources/conference';
import { device } from './resources/device';
import { directory } from './resources/directory';
import { extension } from './resources/extension';
import { group } from './resources/group';
import { ivrExtension } from './resources/ivrExtension';
import { keyvalue } from './resources/keyvalue';
import { ledger } from './resources/ledger';
import { media } from './resources/media';
import { menu } from './resources/menu';
import { omni } from './resources/omni';
import { phoneNumber } from './resources/phoneNumber';
import { schedule } from './resources/schedule';
import { screenpop } from './resources/screenpop';
import { stripe } from './resources/stripe';
import { template } from './resources/template';
import { temporalRule } from './resources/temporalRule';
import { temporalRulesSet } from './resources/temporalRulesSet';
import { user } from './resources/user';
import { vmbox } from './resources/vmbox';
import { vmboxMessages } from './resources/vmboxMessages';
import { provisioner } from './resources/provisioner';
import { websocket } from './resources/websocket';
import { interaction } from './resources/interaction';

// graphql: query, mutation
// kazoo:
// - [resource] . [create, updatePartial, updateFull, delete] with [data]
//   - turned into POST/PUT/PATCH according to resource mapping (prob via overloading?)
//     - (resourceName and action and data -> map to method and url and data)
// - ex: [user] . [updatePartial] . [{id: user.id, account_id: account.id, caller_id}]
//        -> PATCH / account / xyz / users / abc { id: abc, caller_id }
// - ex: [vmMessage] . [updatePartial] . [{id: vmMsg.media_id, vmboxId: 'abc', account_id: 'xyz', status: 'read'}]
//   -> PATCH PATCH /account/xyz/vmboxes/abc/messages/media_id {status: 'read'}

// cioSdk.graphql.nameOfQuery
// sdk.graphql.mutation.dslfj
// sdk.kazoo.[resource]
// sdk.[resource].[action](...args)
// - sdk.account.updatePartial | .get(graphql)
// - sdk.user.create | .updatePartial | .delete | .query(queryName)
// v2: (new sdk.User()).create() ?

const sdk = {
  account,
  calls,
  callDetailRecord,
  callflow,
  conference,
  contact,
  contactList,
  device,
  directory,
  extension,
  group,
  ivrExtension,
  ledger,
  keyvalue,
  media,
  menu,
  omni,
  phoneNumber,
  provisioner,
  schedule,
  screenpop,
  stripe,
  template,
  temporalRule,
  temporalRulesSet,
  user,
  vmbox,
  vmboxMessages,
  interaction,
  websocket,
};

export default sdk;

import { Tooltip, Typography } from 'app/design';
import { useCallflowQuery } from 'app/hooks/queries/callflow';
import React, { memo, useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import {
  ClockRotateRight,
  Edit,
  EditPencil,
  NavArrowLeft,
  NavArrowRight,
  Trash as DeleteIcon,
} from 'iconoir-react';
// import useStyles from './styles';
import { useHoverDirty } from 'react-use';
import { Button, IconButton } from '../../../../../design-lib';
import {
  getAtPath,
  setAtPath,
  useToggleReducer,
} from '../../../../../utilities';
import { ScheduleNodeDialog } from './ScheduleNodeDialog';

// import SlateEditor from '../../../../DialogEditSchedule/SlateEditor/slateEditor';
// import { default as TemporalRuleBuilder } from '../../../../Strategies/components/TemporalRoute/temporalRuleBuilder';
// import { InTemplate } from '../common/InTemplate';

const reservedMap = new Map([
  [
    'fallback',
    {
      icon: <ClockRotateRight />,
    },
  ],
]);

const OptionStaticNode = memo(({ data }) => {
  const {
    canDelete = false,
    icon = null,
    text = '',
    tooltipTitle = undefined,
    skipEditing,
    callflow,
    setCallflow,
    schedule_id,
    category,
    moduleItem,
    templateParent,
  } = data;

  const optionRef = useRef(null);
  const [open, toggleOpen] = useToggleReducer();
  const isHovering = useHoverDirty(optionRef);

  const handleRemove = () => {};

  const reserved = true;
  const isWide = false;

  const handleUpdate = updatedCategory => {};

  return (
    <>
      <div
        ref={optionRef}
        style={{
          display: 'flex',
          justifyContent: 'center',
          // border: '1px solid #ddd',
          // borderRadius: 4,
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ top: 4, background: '#555', visibility: 'hidden' }}
          onConnect={params => console.log('handle onConnect', params)}
        />
        <Button
          disabled={!!reserved || skipEditing}
          variant={'fill'}
          size={'sm'}
          color={reserved ? 'neutral' : 'accent'}
          startIcon={reserved?.icon}
          endIcon={reserved || skipEditing ? null : <Edit />}
          className={`min-w-[24px]`}
          // className={`border border-neutral-60 rounded text-white drop-shadow-lg bg-neutral-50 text-sm p-1 px-2`}
          // disabled={skipEditing}
          // onClick={toggleOpen}
        >
          <span>{text}</span>
        </Button>
        {skipEditing || !!reserved ? null : (
          <span
            className={`opacity-50 hover:opacity-100`}
            style={{ position: 'absolute', top: 0, right: -28 }}
          >
            <Tooltip
              title={<>Remove option: {text}</>}
              placement="bottom"
              type="light"
              arrow
            >
              <IconButton
                onClick={handleRemove}
                size="sm"
                variant="ghost"
                color="neutral"
                pill
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {/*<div
        style={{
          position: 'relative',
          // width: isWide ? 80 : 30,
          // height: isWide ? null : 30,
          // borderRadius: isWide ? 3 : 15,
          // border: '1px solid #ddd',
          // background: 'rgb(254, 255, 255)',
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
        className={`border border-neutral-60 rounded text-white drop-shadow-lg bg-neutral-50 text-sm p-1 px-2`}
        // className={`
        //     inline-block
        //     rounded ring-2
        //     px-2
        //     py-2
        //     ${
        //       isReserved
        //         ? `
        //     ring-purple-600 text-md text-purple-500 leading-normal shadow-md
        //     bg-purple-100/25
        //     `
        //         : `
        //         ring-emerald-600 text-lg text-emerald-500 leading-normal shadow-md
        //     bg-emerald-100/50
        //     `
        //     }
        //
        // `}
      >
        <Handle
          type="target"
          position="top"
          style={{ top: 4, background: '#555', visibility: 'hidden' }}
          onConnect={params => console.log('handle onConnect', params)}
        />
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <span>{isReserved ? reservedWords[text].text : text}</span>
          {skipEditing || isReserved ? null : (
            <span
              // className={classes.opacity}
              style={{ position: 'absolute', top: 0, right: -34 }}
            >
              <Tooltip
                title={<>Remove this option/route</>}
                placement="bottom"
                type="light"
                arrow
              >
                <IconButton
                  onClick={handleRemove}
                  size="small"
                  variant="outlined"
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </span>
          )}
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </div>*/}
      </div>
    </>
  );

  // return (
  //   <div
  //     ref={optionRef}
  //     style={{
  //       // width: 150,
  //       // height: 50,
  //       display: 'flex',
  //       justifyContent: 'center',
  //       // border: '1px solid #ddd',
  //       // borderRadius: 4,
  //     }}
  //   >
  //     <div
  //       style={{
  //         position: 'relative',
  //         width: isWide ? 80 : 30,
  //         height: 30,
  //         borderRadius: isWide ? 3 : 15,
  //         border: '1px solid #ddd',
  //         background: 'rgb(254, 255, 255)',
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <Handle
  //         type="target"
  //         position="top"
  //         style={{ top: 4, background: '#555', visibility: 'hidden' }}
  //         onConnect={params => console.log('handle onConnect', params)}
  //       />
  //       <div style={{ position: 'relative', textAlign: 'center' }}>
  //         {/* Text w/ Hover */}
  //
  //         <Tooltip
  //           title={
  //             <>
  //               {/* {hasRules ? (
  //                 timeDetails?.rule?.data.value
  //                   .filter(v => v.children[0].text)
  //                   .map(v => <div>{v.children[0].text}</div>)
  //               ) : (
  //                 // <TemporalRuleBuilder timeDetails={timeDetails} readOnly />
  //                 <Typography variant="body1">No rules</Typography>
  //               )} */}
  //               RULES
  //             </>
  //           }
  //           placement="bottom"
  //           type="light"
  //           arrow
  //         >
  //           <span>{text}</span>
  //         </Tooltip>
  //
  //         {/* Delete Icon */}
  //         {/* {skipEditing ? null : (
  //           <span
  //             // className={classes.opacity}
  //             style={{ position: 'absolute', top: -18, left: -25 }}
  //           >
  //             <Tooltip
  //               title={<>Remove this Time</>}
  //               placement="bottom"
  //               type="light"
  //               arrow
  //             >
  //               <IconButton
  //                 onClick={handleRemove}
  //                 size="small"
  //                 variant="outlined"
  //                 color="secondary"
  //               >
  //                 <DeleteIcon />
  //               </IconButton>
  //             </Tooltip>
  //           </span>
  //         )} */}
  //       </div>
  //       <Handle
  //         type="source"
  //         position="bottom"
  //         style={{
  //           top: 'auto',
  //           bottom: 4,
  //           background: '#555',
  //           visibility: 'hidden',
  //         }}
  //       />
  //       {/* {templateParent ? <InTemplate template={templateParent} /> : ''} */}
  //     </div>
  //   </div>
  // );
});

export default OptionStaticNode;

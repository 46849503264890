import { useCreateSchedule } from 'app/hooks/mutations/schedule';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import * as React from 'react';
import { toast } from 'react-toastify';

import builtinTemplates from 'app/components/IvrBuilder/Flow/nodes/common/TemplateList/builtinTemplates';
import { useToggleReducer } from 'app/utilities';

import {
  Grid,
  Typography,
  Button,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from 'app/design';

import {
  NavigateNext as NavigateNextIcon,
  Today as TodayIcon,
  Redo as RedoIcon,
  Edit as EditIcon,
  Eject as EjectIcon,
  Link as LinkIcon,
  ChevronRight as ChevronRightIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  AddToPhotos as AddToPhotosIcon,
} from 'app/design/icons-material';

import { useHistory } from 'react-router-dom';

interface CreateTemplateButtonProps {
  onComplete: () => void;
}

const CreateTemplateButton = ({ onComplete }: CreateTemplateButtonProps) => {
  const [showChooserDialog, toggleShowChooserDialog] = useToggleReducer(false);

  return (
    <>
      {showChooserDialog ? (
        <TemplateTypeChooser
          onComplete={onComplete}
          onClose={toggleShowChooserDialog}
        />
      ) : null}
      <Button variant={'contained'} onClick={toggleShowChooserDialog}>
        Create Template
      </Button>
    </>
  );
};

export const TemplateTypeChooser = ({ onComplete, onClose }) => {
  const history = useHistory();
  const createTemplate = useCreateSchedule();
  const { data: authenticatedUser } = useAuthenticatedUserQuery();

  const handleCreateTemplate = async templateData => {
    // dev test, TODO: dialog with required fields for Template creation
    const createTemplatePromise = createTemplate.mutateAsync(templateData);

    toast.promise(createTemplatePromise, {
      pending: 'Creating Template...',
      success: 'Template created!',
      error: 'Error creating Template.',
    });

    const templateResp = await createTemplatePromise;
    // console.log('template resp:', templateResp);
    history.push(`/admin/ivr/edit/${templateResp.data.id}`);
  };

  const handleBlankTemplate = () => {
    handleCreateTemplate({
      name: 'Blank Template',
      type: 'template',
      // is_account_default,
      numbers: [],
      flow: {
        module: 'callflow',
        data: { id: 'placeholder' },
      },
      metaflow: {},
      strategy: {
        type: 'blank', // TODO: switch to a dedicated "template" handler like GenericDefault?
        data: {
          modules: [],
        },
      },
    });
    onClose();
  };

  const handleChooseBuiltInTemplate = template => {
    handleCreateTemplate({
      ...template,
      // name: 'Template',
      type: 'template',
      // is_account_default,
      numbers: [],
      flow: {
        module: 'callflow',
        data: { id: 'placeholder' },
      },
      metaflow: {},
    });
    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Choose Type of New Template</DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={handleBlankTemplate}
          >
            Blank Template
          </Button>
          <br />
          <br />
          <Divider> or </Divider>
          <br />
          <Typography variant="h6">Copy from Built-In Templates</Typography>
          <List dense>
            {builtinTemplates.map(template => (
              <ListItem
                key={template.id}
                onClick={() => handleChooseBuiltInTemplate(template)}
              >
                <ListItemText
                  primary={template.name}
                  secondary={''}
                  sx={{ cursor: 'pointer' }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    size="small"
                    onClick={() => handleChooseBuiltInTemplate(template)}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button onClick={onClose} color="warning">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default CreateTemplateButton;

import { joiResolver } from '@hookform/resolvers/joi';
import { Tooltip } from '@mui/material';
import { ExtensionInUseWarnings } from 'app/components/ExtensionInUseWarnings';
import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import {
  Box,
  Paper,
  DialogInProgress,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Link,
  Autocomplete,
  AnimationDialog,
} from 'app/design';
import { Dialog, DialogContent, DialogProps } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { Warning as WarningIcon } from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import { useExtensionUsedByQuery } from 'app/hooks/general/useExtensionUseQuery';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useUserQuery } from 'app/hooks/queries/user';
import { sdk } from 'app/sdk';
import {
  DialogBuilder,
  parseAndSetKazooMutationErrors,
  useToggleReducer,
} from 'app/utilities';
import useDevMode from 'app/utilities/useDevMode';
import Joi from 'joi';
import { pick, startCase, flatten } from 'lodash';
import * as React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { User, UserDoc, UserPrivLevel } from 'types/user';
import { useImmer } from 'use-immer';
import { v4 as uuidv4 } from 'uuid';

import { findConflicts } from './findConflicts';
import * as CHECK from './validation';
import { useQueryClient } from 'react-query';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  title: Joi.string().max(128).allow(null, ''),
  first_name: Joi.string().max(128).required(),
  last_name: Joi.string().max(128).required(),
  username: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(null, ''),
  priv_level: Joi.string().required(),
  presence_id: Joi.number().min(0).max(9999).required(),
  // password: Joi.string().trim().allow(null, ''), // check password, check-password-strength @ npm, just display score
});

const WARNING = 'warning';
const INFO = 'info';

interface FormFields extends Partial<UserDoc> {}

// extends Pick<
//   UserDoc,
//   | 'first_name'
//   | 'last_name'
//   | 'email'
//   | 'password'
//   | 'priv_level'
//   | 'presence_id'
// > {}

// interface declaring which props are required/allowed
interface UserLoginDetailsDialogContentProps {
  userId: string;
  initData?: User;
  onCancel: () => void;
  onComplete: () => void;
}

export interface ValidationObject {
  [key: string]: {
    name: string;
    changes?: any[];
    conflicts?: any[];
    type: string;
    isUnexpected?: boolean;
  };
}

interface UserLoginDetailsDialogProps {
  ContentProps: UserLoginDetailsDialogContentProps;
  DialogProps: Omit<DialogProps, 'children'>;
}

const UserDetailsDialog = ({
  ContentProps,
  DialogProps,
}: UserLoginDetailsDialogProps) => {
  return (
    <Dialog size={'xl'} {...DialogProps}>
      <UserDetailsDialogContent {...ContentProps} />
    </Dialog>
  );
};

const UserDetailsDialogContent = ({
  userId,
  onCancel,
  onComplete,
  initData,
}: UserLoginDetailsDialogContentProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const authState = useAuthSelector();
  const { owner_id } = useAuthSelector();
  const updateUser = useUpdateUserPartial();
  const [validationStatus, setValidationStatus] = useImmer<
    'validating' | 'validated' | 'conflict' | null
  >(null);
  const [validationInfo, setValidationInfo] = useImmer<ValidationObject | null>(
    null,
  );
  const [conflictInfo, setConflictInfo] = useImmer<ValidationObject | null>(
    null,
  );

  const {
    data: user,
    isLoading: userIsLoading,
    isFetching,
    refetch: refetchUser,
    isError: isUserError,
  } = useUserQuery(userId, {
    initialData: initData,
    // onSuccess: user => {
    //   reset(
    //     pick(user.doc, [
    //       'title',
    //       'first_name',
    //       'last_name',
    //       'username',
    //       // 'password',
    //       'priv_level',
    //       'presence_id',
    //     ]),
    //   );
    // },
  });

  const formMethods = useForm<FormFields>({
    resolver: joiResolver(schema),
    defaultValues: pick(user?.doc, [
      'title',
      'first_name',
      'last_name',
      'username',
      // 'password',
      'priv_level',
      'presence_id',
    ]),
  });
  const { reset, register, handleSubmit, setError } = formMethods;

  const handleLogout = async () => {
    dispatch(actions.logout(null));
    history.push('/login');
    // window.location.href = '/login';
  };

  const handleValidate = async userForm => {
    setValidationStatus('validating');

    // check for conflicts
    // get chosen conflict values (update user AND conflicting User/Vmbox/CallRoute) and
    // - for each conflict, need to do renaming for them (after updating numbers)
    // show renaming changes we'll make for User AND conflicting items (oldNew)
    //
    // finally, save user AND conflicting items
    // - for a swap,

    // if there was a conflict
    // - for each type of possible conflict (user, mailbox, callflow)
    //   - for each conflict.option
    //     - get the value and execute the action
    // - if we are "swapping" then we need to have a temporary/3rd-wheel extension!
    //    - UserA 101 to 110, UserB 102 to 101, UserA 110 to 102

    // fields to check that have cascade effects / conflicts
    const checkFields: { [key: string]: boolean } = {},
      // mapping object with current value as key and new value as the value
      // ex: { '101': '103' } for presence ID change
      userKeyOldAndNew = {};

    // determine if fields have changed that necessitate conflict-checking
    ['presence_id', 'first_name', 'last_name'].forEach(key => {
      userKeyOldAndNew[key] = { old: user?.doc[key], new: userForm[key] };
      if (userForm[key] !== user?.doc[key]) {
        checkFields[key] = true;
        // userKeyOldAndNew[user?.doc[key]] = userForm[key];
      }
    });

    // if no checks required
    if (!Object.keys(checkFields).length) {
      setValidationStatus('validated');
      return;
    }

    // check for conflicts (if we haven't done it already)
    if (checkFields.presence_id && !conflictInfo) {
      // Check for conflicts to allow user to determine
      // how to resolve them (only check if presence ID as changed as
      // that is the only conflicting field
      const conflicts = await findConflicts({
        presenceId: userForm.presence_id,
        userId,
        user,
      });
      console.log('conflicts', conflicts);
      // are there conflicts?
      if (conflicts) {
        // set and show conflicts
        setConflictInfo(conflicts);
        setValidationStatus('conflict');
        return;
      }
    }

    // reach here after conflicts have been resolved in the form (or did not exist)

    // Object to track validation progress,
    // each key being a unique resource (ie user)
    // and it's validation info
    let validationObject: ValidationObject = {
      // user submitting form
      [`user:${userId}`]: {
        type: 'user',
        changes: [],
        name:
          user?.extra.fullName ===
          `${userForm.first_name} ${userForm.last_name}`
            ? user?.extra.fullName
            : `${userForm.first_name} ${userForm.last_name} (Previously: ${user?.extra.fullName})`,
      },
    };

    // iterate through each place where changes can occur or where a conflict can happen
    console.log('userKeyOldAndNew:', userKeyOldAndNew);

    // Internal Caller ID for User
    // - NAME and NUMBER
    // - will be changed to the new Presence ID and first/last name
    if (checkFields.first_name || checkFields.last_name) {
      await CHECK.changeUserFirstLastName({
        user,
        oldNew: userKeyOldAndNew,
        // changes: validationObject[`user:${userId}`].changes,
        validationObject,
      });
      // CHECK.checkUserInternalCallerIdName({
      //   currentValue: user?.doc.caller_id?.internal?.name,
      //   oldNew: userKeyOldAndNew,
      //   changes: validationObject[`user:${userId}`].changes,
      // });
    }
    if (checkFields.presence_id) {
      // // find conflicts (starting with User) for that Presence ID
      // // - show how we'll resolve those issues
      // await CHECK.findAndResolvePresenceIdConflicts({
      //   user,
      //   oldNew: userKeyOldAndNew,
      //   // changes: validationObject[`user:${userId}`].changes,
      //   validationObject,
      //   localExtensions: [],
      // });

      await CHECK.changeUserPresenceId({
        user,
        oldNew: userKeyOldAndNew,
        // changes: validationObject[`user:${userId}`].changes,
        validationObject,
      });
      // CHECK.checkUserInternalCallerIdNumber({
      //   currentValue: user?.doc.caller_id?.internal?.number,
      //   oldNew: userKeyOldAndNew,
      //   changes: validationObject[`user:${userId}`].changes,
      // });
    }

    setValidationInfo(validationObject);
    setValidationStatus('validated');
  };

  const handleSave = async (userForm: FormFields) => {
    // remove "password" if not set
    // const sanitizedFormData = { ...userForm } as any;
    // if (!sanitizedFormData.password?.length) {
    //   delete sanitizedFormData.password;
    // }

    // // "sanitize" and remove any unset values (empty strings)
    // // - so that they are not sent to server.
    // // - ex: An empty string would override the existing password(?)
    // // - no current way to unset password
    // const sanitizedFormData = pickBy(userForm, value => !!value?.length);

    // // if the email is any empty string or undefined, set to null
    // if (!sanitizedFormData.email) sanitizedFormData.email = null;

    // if updating this user's email, then logout immediately after saving!
    let logoutAfterUpdate;
    if (userId === owner_id && userForm.username !== user?.doc.username) {
      logoutAfterUpdate = true;
    }

    // debugger;
    // return;

    try {
      // cast presence id to string for kazoo validation
      userForm.presence_id = userForm.presence_id?.toString();

      const resp = await updateUser.mutateAsync(
        {
          id: userId,
          ...userForm,
          ...(userForm.username !== user?.doc.username
            ? { password: uuidv4(), email: userForm.username }
            : {}), // @ts-ignore
          // username: sanitizedFormData.email ?? undefined,
          // username: sanitizedFormData.email,
        },
        // {
        //   onSuccess: () => {
        //     if (logoutAfterUpdate) {
        //       alert(
        //         'Your email or password has changed, so you will need to relogin',
        //       );
        //       handleLogout();
        //     } else {
        //       refetchUser();
        //       onComplete();
        //     }
        //   },
        //   onError: error => {
        //     parseAndSetKazooMutationErrors({
        //       response: error.response,
        //       setError,
        //     });
        //   },
        // },
      );

      // resolve promise with toast changes
      // const resp = await toast.promise(updateUserPromise, {
      //   pending: 'Updating user login details...',
      //   error: 'Failed to update user login details.',
      //   success: 'User login details updated!',
      // });

      if (resp.status === 'success') {
        queryClient.invalidateQueries({
          queryKey: ['pipes'],
        });
        queryClient.invalidateQueries({
          queryKey: ['user'],
        });

        if (logoutAfterUpdate) {
          alert(
            'Your email or password has changed, so you will need to relogin',
          );
          handleLogout();
        } else {
          await refetchUser();
          // onComplete();
        }
      }
    } catch (e: any) {
      parseAndSetKazooMutationErrors({
        response: e.response,
        setError,
      });
    }
  };

  const error = isUserError || !user;
  const disabled =
    validationStatus === 'validated' || validationStatus === 'conflict';

  const ext = formMethods.watch('presence_id');

  const extUsedByQuery = useExtensionUsedByQuery({
    exts: ext && ext !== user?.doc.presence_id ? [ext] : [],
  });

  const { devMode } = useDevMode();

  return (
    <GenericMutationDialogContent
      // contentClassName={`!overflow-y-visible`}
      onComplete={onComplete}
      onCancel={
        validationStatus === 'validated'
          ? () => {
              setValidationStatus(conflictInfo ? 'conflict' : null);
              setValidationInfo(null);
            }
          : validationStatus === 'conflict'
          ? () => {
              setValidationStatus(null);
              setConflictInfo(null);
            }
          : onCancel
      }
      onSubmit={
        handleSubmit(handleSave) // save if validated
      }
      submitButtonLabel={
        !formMethods.formState.isDirty ? 'No Changes' : 'Save Changes'
      }
      submitButtonDisabled={extUsedByQuery.isLoading}
      onSuccessLabel={'User details have been updated'}
      isLoadingLabel={'Updating user details...'}
      title={'Edit User Details'}
      mutation={updateUser}
      formMethods={formMethods}
      queryIsLoading={userIsLoading}
      queryLabel={'Loading user details'}
    >
      <Grid container columnSpacing={1} rowSpacing={3}>
        <Grid item xs={12} sm={6}>
          <HookFormTextField
            disabled={disabled}
            name={'first_name'}
            label={'First name'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HookFormTextField
            disabled={disabled}
            name={'last_name'}
            label={'Last name'}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <HookFormTextField
            disabled={disabled}
            name={'presence_id'}
            label={'Extension'}
            type={'number'}
          />
        </Grid>*/}

        {/*{devMode ? (*/}

        {/*<Tooltip*/}
        {/*  arrow*/}
        {/*  placement={'right'}*/}
        {/*  title={*/}
        {/*    <Link target={'_blank'} href={'https://support.calling.io/'}>*/}
        {/*      Contact support if you need to change your email address*/}
        {/*    </Link>*/}
        {/*  }*/}
        {/*>*/}

        {/*</Tooltip>*/}

        {/*) : null}*/}
        <Grid item xs={12} sm={6}>
          <HookFormTextField
            disabled={disabled}
            // fullWidth
            name={'title'}
            label={'Title'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HookFormListbox
            disabled={disabled}
            className={'w-full'}
            name={'priv_level'}
            label={'Role'}
            options={Object.values(UserPrivLevel).map(val => ({
              label: startCase(val),
              value: val,
            }))}
          />
          {/*<TextField
            disabled={disabled || owner_id === userId}
            variant="outlined"
            select
            {...register('priv_level')}
            defaultValue={user?.doc.priv_level ?? UserPrivLevel.User}
            label={'Role'}
          >
            {Object.values(UserPrivLevel).map(role => (
              <MenuItem value={role} key={role}>
                {role.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>*/}
        </Grid>
        <Grid item xs={12} sm={8}>
          <HookFormTextField
            // disabled for pilot
            // fullWidth
            disabled={disabled || !devMode}
            helperText={devMode ? '' : 'Email cannot be changed'}
            name={'username'}
            label={'Email'}
          />
        </Grid>
        {/*<Grid item xs={12}>
          <ExtensionInUseWarnings
            query={extUsedByQuery}
            config={{
              // userError: true,
              userAppend: ': reassign before continuing',
              vmboxAppend: ': this may cause confusion',
              extAppend: ': this may cause confusion',
            }}
          />
        </Grid>*/}
      </Grid>
    </GenericMutationDialogContent>
  );

  // return (
  //   <Dialog
  //     open={true}
  //     onClose={onCancel} // remove after dev
  //     fullWidth
  //     maxWidth={'sm'}
  //     scroll="body"
  //   >
  //     {userIsLoading ? (
  //       <DialogInProgress title={'Loading user...'} />
  //     ) : updateUser.isLoading ? (
  //       <DialogInProgress title={'Updating user login details...'} />
  //     ) : validationStatus === 'validating' ? (
  //       <DialogInProgress title={'Validating changes...'} />
  //     ) : (
  //       <>
  //         <>
  //           <DialogTitle>Edit User Login Details</DialogTitle>
  //           <DialogContent sx={{ pb: 1 }} dividers>
  //             <FormProvider {...formMethods}>
  //               {error ? (
  //                 <Typography color={'error'}>Error loading user.</Typography>
  //               ) : (
  //                 <></>
  //               )}
  //             </FormProvider>
  //             {validationStatus === 'conflict' ? (
  //               <DisplayConflicts
  //                 conflictInfo={conflictInfo!}
  //                 setConflictInfo={setConflictInfo}
  //               />
  //             ) : validationInfo ? (
  //               <DisplayChanges conflictInfo={validationInfo} />
  //             ) : null}
  //           </DialogContent>
  //           <DefaultDialogActions
  //             onCancel={
  //               validationStatus === 'validated'
  //                 ? () => {
  //                     setValidationStatus(conflictInfo ? 'conflict' : null);
  //                     setValidationInfo(null);
  //                   }
  //                 : validationStatus === 'conflict'
  //                 ? () => {
  //                     setValidationStatus(null);
  //                     setConflictInfo(null);
  //                   }
  //                 : onCancel
  //             }
  //             onSave={
  //               error ? undefined : handleSubmit(handleSave) // save if validated
  //               // error
  //               //   ? undefined
  //               //   : validationStatus === 'validated'
  //               //   ? handleSubmit(handleSave) // save if validated
  //               //   : validationStatus === 'conflict'
  //               //   ? handleSubmit(handleValidate) // validate
  //               //   : handleSubmit(handleValidate) // validate
  //             }
  //             saveLabel={
  //               !formMethods.formState.isDirty ? 'No Changes' : 'Save Changes'
  //               // !formMethods.formState.isDirty
  //               //   ? 'No Changes'
  //               //   : validationStatus === 'validated'
  //               //   ? 'Save Changes (and update as selected)'
  //               //   : validationStatus === 'conflict'
  //               //   ? 'Next: Review Changes (after conflicts resolved)'
  //               //   : 'Next: Review Changes (check for conflicts)'
  //             }
  //             saveDisabled={
  //               !formMethods.formState.isDirty || extUsedByQuery.isLoading
  //             }
  //           />
  //         </>
  //       </>
  //     )}
  //   </Dialog>
  // );
};

interface DisplayChangesProps {
  conflictInfo: ValidationObject;
}

const DisplayChanges = ({ conflictInfo }: DisplayChangesProps) => {
  return (
    <Box
      sx={{
        borderRadius: 2,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'secondary.main',
        mt: 3,
        padding: 2,
      }}
    >
      <Typography color={'gray'}>The below changes will be made:</Typography>
      <br />
      {Object.values(conflictInfo).map(valObject => {
        return (
          <>
            <Typography variant={'h6'}>{`${startCase(valObject.type)}: ${
              valObject.name
            }`}</Typography>
            {valObject.conflicts?.map(conflict => (
              <Grid container spacing={1} wrap={'nowrap'}>
                <Grid item>
                  <WarningIcon color={'warning'} />
                </Grid>
                <Grid item>
                  <Typography>{`${conflict}`}</Typography>
                </Grid>
              </Grid>
            ))}

            {valObject.changes?.map(changeObj => {
              return (
                <Grid container spacing={1} wrap={'nowrap'}>
                  <Grid item>
                    <WarningIcon
                      color={changeObj.type === WARNING ? 'warning' : 'info'}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{`${changeObj.msg}`}</Typography>
                  </Grid>
                </Grid>
              );
            })}
            <br />
          </>
        );
      })}
    </Box>
  );
};

interface DisplayConflictsProps {
  conflictInfo: ValidationObject;
  setConflictInfo: (state: any) => void;
}

const DisplayConflicts = ({
  conflictInfo,
  setConflictInfo,
}: DisplayConflictsProps) => {
  const handleChange = test => {
    console.log('test');
  };
  return (
    <Box
      sx={{
        borderRadius: 2,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'secondary.main',
        mt: 3,
        padding: 2,
      }}
    >
      <Typography color={'gray'}>The below conflicts were found:</Typography>
      <br />
      {Object.values(conflictInfo).map(validationObj => {
        // depending on the Type, show different root options
        // - should only have 1 conflict per type! user, vmbox, callflow
        switch (validationObj.type) {
          case 'user':
            return <UserConflict validationObj={validationObj} />;
          case 'vmbox':
            return <VmboxConflict validationObj={validationObj} />;
          case 'call_route':
            return <CallRouteConflict validationObj={validationObj} />;
          default:
            return <div>Invalid conflict type ({validationObj.type})</div>;
        }
      })}
    </Box>
  );
};

const UserConflict = ({ validationObj }) => {
  const [showAll, toggleOpen] = useToggleReducer(false);

  // see if selected option for conflict is non-standard
  // - TODO: get actual selected option
  const specials = validationObj.conflicts.filter(
    conflict => conflict.isUnexpected,
  );

  return (
    <>
      <Typography variant={'h6'}>
        Extension Conflict with User: {validationObj.name}
      </Typography>{' '}
      <Typography variant={'body1'}>
        We will swap the extensions and mailbox numbers for each user (unless
        you specify otherwise)
      </Typography>
      {/* Non-standard/Special selected items */}
      {specials?.length ? (
        <Paper sx={{ p: 1 }} variant="outlined">
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Unexpected changes/notes:
          </Typography>
          {specials.map(specialConflict => {
            // show msg and our default option that we'll do
            console.log('specialConflict:', specialConflict, specials);
            return (
              <Box>
                {/* @ts-ignore */}
                <Typography variant="body1">- {specialConflict.msg}</Typography>
                {/* @ts-ignore */}
                {specialConflict.options.length ? (
                  <Typography variant="body1">
                    {/* @ts-ignore */}
                    &nbsp;&nbsp;- {specialConflict.options[0].label}
                  </Typography>
                ) : null}
              </Box>
            );
          })}
        </Paper>
      ) : null}
      {/* Show All */}
      <Typography variant="body1">
        <Link onClick={toggleOpen}>Show/Hide All Changes</Link>
      </Typography>
      {showAll ? (
        <Box>
          {validationObj.conflicts?.map(conflict => (
            <Grid
              container
              columnSpacing={2}
              sx={{ mt: 1, mb: 2 }}
              // alignItems={'center'}
              wrap={'nowrap'}
            >
              <Grid item>
                <WarningIcon color={'warning'} />
              </Grid>
              <Grid item sx={{ flex: 1 }} zeroMinWidth>
                <Typography>{`${conflict.msg}`}</Typography>

                <Box sx={{ pl: 1 }}>
                  <TextField
                    variant="outlined"
                    select
                    defaultValue={
                      conflict.options.length
                        ? conflict.options.filter(opt => !!opt)[0].id
                        : null
                    }
                    // label={'Resolve'}
                    fullWidth
                    // onChange={handleChange}
                    size="small"
                  >
                    {conflict.options
                      .filter(opt => !!opt)
                      .map(opt => (
                        <MenuItem value={opt.id} key={opt.id}>
                          {opt.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : null}
      <br />
    </>
  );
};

const VmboxConflict = ({ validationObj }) => {
  const [showAll, toggleOpen] = useToggleReducer(true);

  return (
    <>
      <Typography variant={'h6'}>
        Extension Conflict with Voicemail Box: "{validationObj.name}"
      </Typography>{' '}
      <Typography variant={'body1'}>
        This is unexpected. Please review the options to determine how you would
        like this to be handled.
      </Typography>
      {/* Show All */}
      <Typography variant="body1">
        <Link onClick={toggleOpen}>Show/Hide All Options</Link>
      </Typography>
      {showAll ? (
        <Box>
          {validationObj.conflicts?.map(conflict => (
            <Grid
              container
              columnSpacing={2}
              sx={{ mt: 1, mb: 2 }}
              // alignItems={'center'}
              wrap={'nowrap'}
            >
              <Grid item>
                <WarningIcon color={'warning'} />
              </Grid>
              <Grid item sx={{ flex: 1 }} zeroMinWidth>
                <Typography>{`${conflict.msg}`}</Typography>

                <Box sx={{ pl: 1 }}>
                  <TextField
                    variant="outlined"
                    select
                    defaultValue={
                      conflict.options.length
                        ? conflict.options.filter(opt => !!opt)[0].id
                        : null
                    }
                    // label={'Resolve'}
                    fullWidth
                    // onChange={handleChange}
                    size="small"
                  >
                    {conflict.options
                      .filter(opt => !!opt)
                      .map(opt => (
                        <MenuItem value={opt.id} key={opt.id}>
                          {opt.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : null}
      <br />
    </>
  );
};

const CallRouteConflict = ({ validationObj }) => {
  const [showAll, toggleOpen] = useToggleReducer(true);

  return (
    <>
      <Typography variant={'h6'}>
        Extension Conflict with Call Route: "{validationObj.name}"
      </Typography>{' '}
      <Typography variant={'body1'}>
        This is unexpected. Please review the options to determine how you would
        like this to be handled.
      </Typography>
      {/* Show All */}
      <Typography variant="body1">
        <Link onClick={toggleOpen}>Show/Hide All Options</Link>
      </Typography>
      {showAll ? (
        <Box>
          {validationObj.conflicts?.map(conflict => (
            <Grid
              container
              columnSpacing={2}
              sx={{ mt: 1, mb: 2 }}
              // alignItems={'center'}
              wrap={'nowrap'}
            >
              <Grid item>
                <WarningIcon color={'warning'} />
              </Grid>
              <Grid item sx={{ flex: 1 }} zeroMinWidth>
                <Typography>{`${conflict.msg}`}</Typography>

                <Box sx={{ pl: 1 }}>
                  <TextField
                    variant="outlined"
                    select
                    defaultValue={
                      conflict.options.length
                        ? conflict.options.filter(opt => !!opt)[0].id
                        : null
                    }
                    // label={'Resolve'}
                    fullWidth
                    // onChange={handleChange}
                    size="small"
                  >
                    {conflict.options
                      .filter(opt => !!opt)
                      .map(opt => (
                        <MenuItem value={opt.id} key={opt.id}>
                          {opt.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : null}
      <br />
    </>
  );
};

export const useUserDetailsDialog = DialogBuilder(UserDetailsDialog);

export default UserDetailsDialog;

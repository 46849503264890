import {
  EmergencyAddressDialog,
  useEmergencyAddressDialog,
} from 'app/components/ListPhoneNumbers/components/PhoneNumberListOptions/components/EmergencyAddressDialog';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { ScheduleWidget } from 'app/components/ScheduleWidget';

// import { store } from '../../../store';
import constants from 'app/constants';

import { useAuthSelector } from 'app/data/auth';

import { useLocalSelector } from 'app/data/local';

import {
  Box,
  buildThemeWithMode,
  CircularProgress,
  createTheme,
  Divider,
  Grid,
  Link,
  Skeleton,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from 'app/design';

import { MedicalServices as MedicalServicesIcon } from 'app/design/icons-material';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import {
  useAuthenticatedUserQuery,
  useUserQuery,
} from 'app/hooks/queries/user';
import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UserPrivLevel } from 'types/user';
import { AccountCallerIdCard } from './AccountCallerIdCard';

import { CallHandlingCard } from './CallHandlingCard';

const Account = props => {
  const accountQuery = useAccountQuery();

  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;

  // do not allow to be open if there is a dialog
  // - also need to "capture" the dialog creation/removal so we can hide the tooltip
  const [dialogShown, setDialogShownRaw] = useState(false);
  const setDialogShown = val => {
    setOpen(false);
    setDialogShownRaw(val);
  };
  const [open, setOpen] = useState(false);
  const isOpen = open && !dialogShown ? true : false;

  const theme = useTheme();
  // console.log('THEME:', theme);

  return (
    <Box
      sx={{
        // background: 'rgba(0, 188, 212,0.2)',
        padding: 2,
        pb: 0,
      }}
    >
      <Tooltip
        open={isOpen}
        // onOpen={e => setOpen(true)}
        onClose={e => setOpen(false)}
        title={
          <TooltipContent
            isOpen={isOpen}
            accountQuery={accountQuery}
            setDialogShown={setDialogShown}
          />
        }
        arrow
        placement="right-end"
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none',
              // background: 'red !important',
            },
          },
          popper: {
            keepMounted: true,
            // disablePortal: true
          },
        }}
      >
        <Box>
          <Name
            accountQuery={accountQuery}
            // handleOpenTooltip={e => setOpen(true)}
          />
          <Number
            accountQuery={accountQuery}
            handleOpenTooltip={e => setOpen(!isOpen)}
          />
          <E911 accountQuery={accountQuery} />
        </Box>
      </Tooltip>
      <Schedule accountQuery={accountQuery} />
      <PersonalDetails accountQuery={accountQuery} />
    </Box>
  );
};

const PersonalDetails = ({ accountQuery }) => {
  const { location } = useHistory();
  const { sidebar_key: selectedSidebarKey } = useLocalSelector();

  // const adminView = location.pathname.includes('admin');
  const {
    data: authenticatedUser,
    refetch: refetchUser,
    isLoading: userIsLoading,
    error: userError,
    isFetching: userIsFetching,
  } = useAuthenticatedUserQuery();

  // if (adminView) return null;

  const externalNumber =
    authenticatedUser?.doc.caller_id?.external?.number ??
    accountQuery.data?.doc.caller_id?.external.number;

  return (
    <>
      <Divider sx={{ pt: 1.5 }} />
      <div
        className={`flex h-16 pb-1 w-full space-x-2 pt-4  items-center ${
          userIsLoading || accountQuery.isLoading ? 'opacity-50' : ''
        }`}
      >
        {userIsLoading || accountQuery.isLoading ? (
          <>
            <CircularProgress size={10} variant={'indeterminate'} />
            <span>loading personal details...</span>
          </>
        ) : (
          <div style={{ width: '100%' }}>
            <div
              style={{ paddingLeft: '5px' }}
              className="no-underline text-xs opacity-80 italic"
            >
              Personal Office:
            </div>
            <Link component={RouterLink} to={'/office/calling'}>
              <div style={{ paddingLeft: '5px' }} className="font-medium">
                {authenticatedUser?.extra.fullName}
              </div>
              <div
                style={{
                  paddingLeft: '5px',
                  borderRadius: '4px',
                  background:
                    selectedSidebarKey ===
                    constants.nav.OFFICE_CALLING.sidebar_key
                      ? '#1F8DA4'
                      : undefined,
                }}
                className="font-medium text-white"
              >
                {externalNumber ? (
                  <PhoneNumberDisplay ptn={externalNumber} />
                ) : (
                  <span className="italic font-normal opacity-70">
                    Caller ID not set up
                  </span>
                )}
              </div>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

const TooltipContent = ({ isOpen, accountQuery, setDialogShown }) => {
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;

  if (!account) {
    return <>Loading...</>;
  }

  return (
    <>
      <ThemeProvider
        // @ts-ignore
        theme={outerTheme => outerTheme.outerTheme}
      >
        <Box sx={{ minWidth: '600px' }}>
          <AccountCallerIdCard
            account={account}
            setDialogShown={setDialogShown}
            onComplete={refetchAccount}
          />
          <br />
          <CallHandlingCard
            account={account}
            setDialogShown={setDialogShown}
            onComplete={refetchAccount}
          />
        </Box>
      </ThemeProvider>
    </>
  );
};

const Name = ({ accountQuery }) => {
  const { data: account, isLoading: accountIsLoading } = accountQuery;
  // const { location } = useHistory();
  // const adminView = location.pathname.includes('admin');
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const adminView = authenticatedUser?.doc.priv_level === UserPrivLevel.Admin;

  return (
    <Typography variant="h2" gutterBottom noWrap>
      <Link
        component={RouterLink}
        to="/"
        underline="hover"
        color="primary.main"
        sx={{ pointerEvents: adminView ? undefined : 'none' }}
      >
        {account?.doc?.name ?? <Skeleton />}
      </Link>
    </Typography>
  );
};

const Number = ({ accountQuery, handleOpenTooltip }) => {
  const { sidebar_key: selectedSidebarKey } = useLocalSelector();
  const { account_id } = useAuthSelector();
  const { data: account, isLoading: accountIsLoading } = accountQuery;

  // const { location } = useHistory();
  // const adminView = location.pathname.includes('admin');
  // const { data: authenticatedUser } = useAuthenticatedUserQuery();
  // const adminView = authenticatedUser?.doc.priv_level === UserPrivLevel.Admin;
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const adminView = authenticatedUser?.doc.priv_level === UserPrivLevel.Admin;

  // account external CID
  let externalCid = account?.doc?.caller_id?.external?.number;

  const {
    data: externalNumberData,
    isLoading: externalNumberIsLoading,
    refetch: refetchExternal,
  } = usePhoneNumberQuery({ id: externalCid });

  const externalNumber = externalNumberData?.doc.id;

  const {
    data: callflowsPageResponse,
    isLoading: callflowsAreLoading,
    isFetching: callflowsFetching,
    refetch,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['owner_id', '=', account_id],
              ['type', '=', 'main'],
            ],
          },
        },
      ],
    },
  });

  const mainCallflow = callflowsPageResponse?.callflows?.length
    ? callflowsPageResponse.callflows[0]
    : null;

  // show a single number (External Caller ID)
  // - icon/alert if this is NOT also an incoming number attached to the incoming callflow
  return (
    <Typography variant="h2" gutterBottom>
      <Grid container columnSpacing={1} wrap="nowrap">
        <Grid
          item
          sx={{
            flex: 1,
            cursor: adminView ? 'pointer' : 'default',
            color: 'white !important',
            borderRadius: '4px',
            background:
              selectedSidebarKey ===
              constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key
                ? '#1F8DA4'
                : undefined,
          }}
          // onClick={handleOpenTooltip}
        >
          <Link
            component={RouterLink}
            to="/admin/calling"
            underline="hover"
            color="#fff"
            sx={{
              pointerEvents: adminView ? undefined : 'none',
            }}
          >
            {externalNumberIsLoading || accountIsLoading ? (
              <Skeleton />
            ) : externalNumber ? (
              <PhoneNumberDisplay ptn={externalNumber} />
            ) : (
              'Setup Number'
            )}
          </Link>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Typography>
  );
};

const E911 = ({ accountQuery }) => {
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;
  // const { location } = useHistory();
  // const adminView = location.pathname.includes('admin');
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const adminView = authenticatedUser?.doc.priv_level === UserPrivLevel.Admin;

  let emergencyCid = account?.doc?.caller_id?.emergency?.number;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // TODO: shouldn't have to do this, theme above is overriding it
  // @ts-ignore
  const usedTheme = React.useMemo(
    () =>
      // @ts-ignore
      createTheme(buildThemeWithMode(prefersDarkMode ? 'light' : 'light')), // dark
    [prefersDarkMode],
  );

  const {
    data: emergencyNumberData,
    isLoading: emergencyNumberIsLoading,
    refetch: refetchEmergency,
  } = usePhoneNumberQuery({ id: emergencyCid });

  // console.log('emergencyNumberData', emergencyNumberData);
  const number = emergencyNumberData?.doc?.id;
  const emergencyAddress = emergencyNumberData?.doc.e911?.street_address;
  const {
    toggleOpen,
    Dialog: EmergencyAddressDialog,
    DialogProps,
  } = useEmergencyAddressDialog();

  const handleUpdate = () => {
    refetchEmergency();
    refetchAccount();
    toggleOpen();
  };

  return (
    <>
      <ThemeProvider theme={usedTheme}>
        <EmergencyAddressDialog
          {...DialogProps}
          onComplete={handleUpdate}
          number={emergencyCid}
        />
      </ThemeProvider>
      <Typography
        variant="body2"
        sx={{ textOverflow: 'ellipsis', width: '100%', overflowX: 'hidden' }}
        noWrap
        // paragraph
      >
        <Link
          // onClick={number ? toggleOpen : undefined}
          component={RouterLink}
          to={'/admin/calling'}
          color="inherit"
          underline="hover"
          sx={{ pointerEvents: adminView ? undefined : 'none' }}
        >
          {emergencyNumberIsLoading || accountIsLoading ? (
            <Skeleton />
          ) : (
            <>
              <MedicalServicesIcon sx={{ fontSize: 'inherit' }} />
              &nbsp;
              {emergencyNumberData
                ? emergencyAddress ?? 'No Emergency Address'
                : 'No Emergency Address'}
            </>
          )}
        </Link>
      </Typography>
    </>
  );
};

const Schedule = ({ accountQuery }) => {
  const { data: account, isLoading: accountIsLoading } = accountQuery;
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const adminView = authenticatedUser?.doc.priv_level === UserPrivLevel.Admin;
  // get account schedule
  // - TODO: let them define the schedule

  // schedule list
  const { refetch, isLoading, isFetching, dataUpdatedAt, data } =
    useListSchedulesQuery({
      skip: 0, //skip
      take: 10, // take
      orderBy: undefined, // order by
      filters: {}, // filters
    });
  const { callflows: schedules, totalCount = 0 } = data ?? {};

  const schedule = schedules?.length ? schedules[0] : null;

  return (
    <Typography
      variant="body2"
      sx={{ textOverflow: 'ellipsis', width: '100%', overflowX: 'hidden' }}
      noWrap
    >
      <Grid container columnSpacing={1} wrap="nowrap" alignItems="center">
        {/* <Grid item>
          <CalendarTodayIcon sx={{ fontSize: 'inherit' }} />
        </Grid> */}
        <Grid
          item
          sx={{ flex: 1, pointerEvents: adminView ? undefined : 'none' }}
        >
          {
            isLoading ? (
              <Skeleton />
            ) : schedule ? (
              <ScheduleWidget callflow={schedule.doc} /> ??
              'No Account Schedule'
            ) : null
            // 'No Account Schedule'
          }
        </Grid>
      </Grid>
    </Typography>
  );
};

export default Account;

import { Listbox as HeadlessListbox } from '@headlessui/react';
import { NavArrowDown as NavArrowDownIcon } from 'iconoir-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { voices } from 'app/components/MediaDialog/components/TextToSpeech/TextToSpeech';
import {
  default as Button,
  ButtonProps,
} from 'app/design-lib/components/Button/Button';
import React, { ReactElement, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { InputWrapper } from 'app/design-lib/components/InputWrapper';
import Checkbox from 'app/design-lib/components/Checkbox/Checkbox';
import { Size } from 'app/design-lib/types';
export interface ListItem<T> {
  id: string;
  label: string;
  disabled?: boolean;
  data?: T;
}

export interface ListboxProps {
  value?: any | any[];

  label?: string;

  helperText?: string;

  options: { value: any; label: string | ReactElement; icon?: ReactElement }[];

  multiple?: boolean;
  onChange: (newValue: any) => void;

  placeholder?: string;

  hasError?: boolean;

  className?: string;
  name?: string;
  disabled?: boolean;
  optional?: boolean;
  startIcon?: ReactElement;
  variant?: 'outline' | 'ghost';
  size?: Size;
  onOpenChanged?: (open: boolean) => void;
  // renderOption?: (option: ListItem<T>) => ReactElement;

  // buttonProps?: ButtonProps;
}

const Listbox = ({
  name,
  label,
  value,
  onChange,
  variant = 'outline',
  options,
  placeholder = 'Select...',
  hasError,
  helperText,
  className,
  disabled,
  startIcon,
  multiple,
  optional,
  size = 'md',
  onOpenChanged,
}: ListboxProps) => {
  const selectedOption = options.find(option => option.value === value);
  return (
    <InputWrapper
      helperText={helperText}
      disabled={disabled}
      hasError={hasError}
      label={label}
      name={name}
      startIcon={startIcon}
    >
      <HeadlessListbox
        as={'div'}
        className={`relative inline-block ${className ?? 'w-min'}`}
        value={value}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
      >
        {({ open }) => {
          return (
            <>
              {onOpenChanged ? (
                <ExternalState onOpenChanged={onOpenChanged} open={open} />
              ) : null}
              <HeadlessListbox.Button
                className={`${className ?? ''} ${
                  startIcon ? 'pl-8' : ''
                } form-input ${
                  size === 'sm' ? 'text-sm h-8 ' : 'text-lg'
                } flex ${
                  variant === 'outline' ? 'border' : 'border-none'
                } justify-between items-center w-full focus:ring-content-accent focus:border-content-accent text-content-neutral ${
                  hasError
                    ? `border-border-negative-secondary`
                    : `border-border-neutral`
                } rounded-lg `}
                // @ts-ignore needed for homepage only...
                type={'text'}
              >
                <div className={'whitespace-nowrap overflow-x-auto'}>
                  {multiple ? (
                    value?.length ? (
                      <div className={`flex space-x-1 overflow-x-auto w-full`}>
                        {options
                          .filter(option => value.includes(option.value))
                          .map(opt => (
                            <>{opt.label},</>
                          ))}
                      </div>
                    ) : (
                      placeholder
                    )
                  ) : value !== null && value !== undefined ? (
                    <div className={`flex min-w-0 space-x-2 items-center`}>
                      {selectedOption?.icon ? (
                        <div>
                          {React.cloneElement(selectedOption?.icon, {
                            className: `h-4 w-4 aspect-square`,
                          })}
                        </div>
                      ) : null}
                      <span className={`truncate`}>
                        {selectedOption?.label}
                      </span>
                    </div>
                  ) : (
                    placeholder
                  )}
                </div>
                <div>
                  <NavArrowDownIcon
                    fr={undefined}
                    strokeWidth={2}
                    className={`h-4 w-4`}
                  />
                </div>
              </HeadlessListbox.Button>
              <HeadlessListbox.Options
                className={`w-full max-h-[200px] overflow-y-auto h-max absolute right-0 z-10 p-1 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border-solid border border-border-neutral space-y-2`}
              >
                {optional ? (
                  <HeadlessListbox.Option
                    className={`cursor-pointer z-20 ${
                      size === 'sm' ? 'h-8 py-0.5 px-2 text-sm' : 'py-2 px-3'
                    } whitespace-nowrap text-content-neutral flex space-x-2 items-center justify-start w-full bg-transparent hover:bg-background-neutral-active font-sans b p-2 px-3 m-0`}
                    value={undefined}
                  >
                    <span className={`text-neutral-60`}>Leave blank...</span>
                  </HeadlessListbox.Option>
                ) : null}
                {options.map(option => (
                  <HeadlessListbox.Option
                    className={`cursor-pointer ${
                      size === 'sm' ? 'h-8 py-0.5 px-2 text-sm' : 'py-2 px-3'
                    } whitespace-nowrap text-content-neutral flex space-x-2 items-center justify-start w-full font-medium bg-transparent hover:bg-background-neutral-active font-sans b  m-0`}
                    key={option.value}
                    value={option.value}
                  >
                    {multiple ? (
                      <Checkbox
                        size={'sm'}
                        checked={!!value?.find(opt => opt === option.value)}
                        label={option.label}
                      />
                    ) : (
                      <div
                        className={`flex w-full min-w-0 space-x-2 items-center`}
                      >
                        {option?.icon ? (
                          <div>
                            {React.cloneElement(option?.icon, {
                              className: `h-4 w-4 aspect-square`,
                            })}
                          </div>
                        ) : null}
                        <span className={`truncate`}>{option?.label}</span>
                      </div>
                    )}
                  </HeadlessListbox.Option>
                ))}
              </HeadlessListbox.Options>
            </>
          );
        }}
      </HeadlessListbox>
    </InputWrapper>
  );

  // return <div className={'relative w-min'}></div>;

  // return (
  //   <HeadlessListbox
  //     as="div"
  //     className="relative inline-block"
  //     name={name}
  //     value={value}
  //     onChange={onChange}
  //   >
  //     <HeadlessListbox.Button as={Button} {...buttonProps} size={'md'}>
  //       {value?.label ?? placeholder}
  //     </HeadlessListbox.Button>
  //     <HeadlessListbox.Options className="absolute w-full right-0 mt-2 p-1 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border-solid border border-border-neutral">
  //       {options.map((option, index) => (
  //         <HeadlessListbox.Option
  //           className={({ active, disabled, selected }) =>
  //             `${
  //               disabled
  //                 ? 'opacity-50 pointer-events-none cursor-default'
  //                 : 'cursor-pointer'
  //             } text-content-neutral whitespace-nowrap  font-medium bg-transparent hover:bg-background-neutral-active font-sans p-2 px-3 m-0 ${
  //               index !== options.length - 1
  //                 ? 'border-solid border-0 border-b border-border-neutral'
  //                 : 'border-none'
  //             }`
  //           }
  //           key={option.id}
  //           value={option}
  //           disabled={option.disabled}
  //         >
  //           {({ selected }) => (
  //             <div className={'flex space-x-4 items-center'}>
  //               {renderOption ? (
  //                 renderOption(option)
  //               ) : (
  //                 <span>{option.label}</span>
  //               )}
  //               {selected ? <CheckIcon fontSize={'small'} /> : null}
  //             </div>
  //           )}
  //         </HeadlessListbox.Option>
  //       ))}
  //     </HeadlessListbox.Options>
  //   </HeadlessListbox>
  // );
};

const ExternalState = ({ onOpenChanged, open }) => {
  useEffect(() => {
    onOpenChanged(open);
  }, [open]);

  return null;
};

export default Listbox;

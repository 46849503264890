import { Chip, Grid, InfoTooltip } from 'app/design';
import { CreateVmboxButton } from 'app/components/CreateVmboxButton';
import VmboxOwnerCell from 'app/components/ListVmboxes/components/VmboxOwnerCell/VmboxOwnerCell';
import { OwnerTableCell } from 'app/components/OwnerTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListVmboxesQuery } from 'app/hooks/queries/vmbox';
import { useMarkdownTranslate } from 'app/utilities/translate';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { User } from 'types/user';
import { Vmbox } from 'types/vmbox';

const QuickFinderVmboxDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const { t } = useMarkdownTranslate();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (vmbox: Vmbox) => vmbox.doc.name,
      },
      {
        Header: 'Owner',
        id: 'owner',
        accessor: (vmbox: Vmbox) => vmbox.Owner,
        Cell: ({
          value: owner,
          row: { original: vmbox },
        }: {
          value: User;
          row: { original: Vmbox };
        }) => <VmboxOwnerCell owner={owner} vmbox={vmbox} />,
      },
    ],
    [],
  );

  // vmbox list
  const vmboxListQuery = useListVmboxesQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['name', 'asc']],
    filters: { searchInput: querySearchInput },
  });
  const { vmboxes, totalCount = 0 } = vmboxListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      vmboxListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  const handleCreate = (id: string) => {
    // TODO: does not work with passing resource atm
    props.onSelect([id]);
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Vmboxes' : 'Select Vmbox'}
      titleButton={
        props.allowCreate ? (
          <CreateVmboxButton
            onComplete={handleCreate}
            navigateOnComplete={false}
          />
        ) : undefined
      }
      // table
      getRowId={(row: Vmbox) => row.id}
      searchPlaceholder={'Search Vmboxes...'}
      totalCount={totalCount}
      columns={columns}
      data={vmboxes ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={vmboxListQuery.isFetching}
      queryIsLoading={vmboxListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderVmboxDialog;

import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
  buildResourceUrl,
  jwtDecode,
  getResourceDefault,
} from 'app/sdk/utils/kazoo';
import { buildPostVmboxMessage } from 'app/sdk/utils/kazoo/postVmboxMessage';
import constants from 'app/constants';

const exports = {
  // Queries
  // - graphql
  query: {
    vmboxMessages: (vars, fetchOptions) =>
      fetchFromGraphql('vmboxMessages', vars, fetchOptions),

    // Fetch a single vmbox by id
    // vmboxById: (vars, fetchOptions) =>
    //   fetchFromGraphql('vmboxById', vars, fetchOptions),
    messageUrl: ({ id, vmboxId }, { authToken }) => {
      const { account_id } = jwtDecode(authToken);
      return `${constants.env.REACT_APP_KAPI_URL}/accounts/${account_id}/vmboxes/${vmboxId}/messages/${id}/raw?auth_token=${authToken}`;
    },
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    // create: buildPutDefault('vmboxes', { buildUrlOpts: { skipId: true } }),
    updatePartial: buildPostVmboxMessage('vmboxes', 'messages'),
    updateCustomField: (vars, fetchOptions) =>
      mutateGraphql('updateVmboxMessageCustomFieldById', vars, fetchOptions),
    // delete: buildDeleteDefault('vmboxes'),
  },
};

export default exports;

const builtinTemplates = [
  {
    id: 'builtin:OLD',
    version: 1,
    name: 'Ring User, then go to their Voicemail',
    description: '',
    strategy: JSON.parse(
      '{"data":{"modules":[{"data":{"data":{},"ring_type":"user","variables":{"items":{"user_id":{"id":"callflow_owner_user","data":{},"read":{"type":"user_id"},"write":{"data":{"moduleItemModifyPath":"data.data.id"},"type":"set"},"enabled":true,"outputValue":null}},"enabled":true}},"type":"Ring","options":{"props":{"required":true,"defaultRingType":"user"}}},{"data":{"variables":{"items":{"vmbox_id":{"id":"callflow_owner_user_vmbox","data":{},"read":{"type":"vmbox_id"},"write":{"data":{"moduleItemModifyPath":"data.id"},"type":"set"},"enabled":true,"outputValue":null}},"enabled":true}},"type":"VoicemailBox","options":{"props":{}}}]},"type":"blank"}',
    ),
  },
  // {
  //   id: 'builtin:2',
  //   version: 1,
  //   name: 'Play Audio, then go straight to Voicemail',
  //   description: '',
  //   strategy: JSON.parse(
  //     '{"id":"blank","data":{"opts":[{},{}]},"config":{"components":[{"type":"PlayAudio","options":{"props":{}}},{"type":"VoicemailBox","options":{"props":{}}}]}}',
  //   ),
  // },
  // {
  //   id: 'builtin:3',
  //   version: 1,
  //   name: 'Menu with many options',
  //   description: '',
  //   strategy: JSON.parse(
  //     '{"id":"blank","data":{"opts":[{"targets":{"0":{"callflow":{"id":"inline","strategy":{"id":"blank","config":{"components":[{"type":"Note","options":{}},{"type":"Transfer","options":{"props":{}}}]},"data":{"opts":[{"text":"Go to Operator (0)"},{"target":"0"}]}}}},"1":{"callflow":{"id":"inline","strategy":{"id":"blank","config":{"components":[{"type":"RingStrategy","options":{"props":{"required":true,"defaultRingType":"user"}}}]},"data":{"opts":[{"ringType":"user","data":{}}]}}}},"2":{"callflow":{"id":"inline","strategy":{"id":"blank","config":{"components":[{"type":"RingStrategy","options":{"props":{"required":true,"defaultRingType":"user"}}}]},"data":{"opts":[{"ringType":"user","data":{}}]}}}},"3":{"callflow":{"id":"inline","strategy":{"id":"blank","config":{"components":[{"type":"PlayAudio","options":{"props":{}}}]},"data":{"opts":[{}]}}}}}}]},"config":{"components":[{"type":"MenuGreetingAndTargets","options":{"props":{"required":true}}}]}}',
  //   ),
  // },
  // {
  //   id: 'builtin:4',
  //   version: 1,
  //   name: 'Ring Device, then go to Voicemail of Device Owner',
  //   description: '',
  //   strategy: JSON.parse(
  //     '{"id":"blank","data":{"opts":[{"ringType":"device","data":{"timeout":20},"variables":{"enabled":true,"items":{"device_id":{"id":"callflow_owner_device","data":{},"read":{"type":"device_id"},"write":{"type":"set","data":{"moduleItemModifyPath":"data.deviceId"}},"outputValue":null,"enabled":true}}}},{"variables":{"enabled":true,"items":{"vmbox_id":{"id":"callflow_owner_user_vmbox","data":{},"read":{"type":"vmbox_id"},"write":{"type":"set","data":{"moduleItemModifyPath":"vmboxId"}},"outputValue":null,"enabled":true}}}}]},"config":{"components":[{"type":"RingStrategy","options":{"props":{"required":true,"defaultRingType":"device"}}},{"type":"VoicemailBox","options":{"props":{}}}]}}',
  //   ),
  // },
  // {
  //   id: 'builtin:5',
  //   version: 1,
  //   name: 'Ring Device, then go to Company Voicemail',
  //   description: '',
  //   strategy: JSON.parse(
  //     '{"id":"blank","data":{"opts":[{"ringType":"device","data":{"timeout":20},"variables":{"enabled":true,"items":{"device_id":{"id":"callflow_owner_device","data":{},"read":{"type":"device_id"},"write":{"type":"set","data":{"moduleItemModifyPath":"data.deviceId"}},"outputValue":null,"enabled":true}}}},{"variables":{"enabled":true,"items":{"vmbox_id":{"id":"callflow_owner_user_vmbox","data":{},"read":{"type":"vmbox_id"},"write":{"type":"set","data":{"moduleItemModifyPath":"vmboxId"}},"outputValue":null,"enabled":true}}}}]},"config":{"components":[{"type":"RingStrategy","options":{"props":{"required":true,"defaultRingType":"device"}}},{"type":"VoicemailBox","options":{"props":{}}}]}}',
  //   ),
  // },
];

export default builtinTemplates;

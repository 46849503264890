const callDetailRecordQueryKeys = {
  all: ['callDetailRecord'] as const,
  list: (skip: number, take: number, sortBy: any, filters: any) =>
    [
      ...callDetailRecordQueryKeys.all,
      'list',
      skip,
      take,
      sortBy,
      filters,
    ] as const,
  interactionsList: (skip: number, take: number, sortBy: any, filters: any) =>
    [
      ...callDetailRecordQueryKeys.all,
      'interaction',
      'list',
      skip,
      take,
      sortBy,
      filters,
    ] as const,
  byId: (id: string) => [...callDetailRecordQueryKeys.all, id] as const,
  report: (fnName: string, fnArgs: any) =>
    [...callDetailRecordQueryKeys.all, fnName, fnArgs] as const,
};

export default callDetailRecordQueryKeys;

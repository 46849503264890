import { PlateRenderLeafProps } from '@udecode/plate';
import { Typography } from 'app/design';
import { KeywordContext } from 'app/components/SlateSearch/SlateSearch';
import { useContext } from 'react';
import { Menu, Tooltip } from '@mui/material';
import { matchSorter } from 'match-sorter';

interface MarkKeywordProps extends PlateRenderLeafProps {}

const MarkKeyword = (props: MarkKeywordProps) => {
  const {
    attributes,
    children,
    nodeProps,
    element,
    prefix,
    as,
    onClick,
    renderLabel,
  } = props;

  const keywords = useContext(KeywordContext);
  // remove exclude if present for keyword check
  const keyword: string = children.props.leaf.text.slice(
    children.props.leaf.text.startsWith('-') ? 1 : 0,
    -1,
  );
  const validKeyword = !!keywords[keyword];
  const keywordValues = keywords[keyword]?.values;

  let keywordHelp: string[] = [];
  if (!validKeyword) {
    keywordHelp = matchSorter(Object.keys(keywords), keyword);
  }

  return (
    <Tooltip
      arrow
      title={
        validKeyword ? (
          ''
        ) : (
          <span>
            Invalid Keyword:
            <br />
            {keywordHelp?.length ? (
              <>
                Did you mean...
                <br />
                <br />
                {keywordHelp.map(kw => (
                  <div>{kw}</div>
                ))}
              </>
            ) : (
              <>
                No keywords match.
                <br />
                <br />
                Try:
                {Object.keys(keywords).map(kw => (
                  <div>{kw}</div>
                ))}
              </>
            )}
          </span>
        )
      }
      open={!validKeyword}
    >
      <span
        {...attributes}
        {...nodeProps}
        style={{
          color: validKeyword ? (!!keywordValues ? 'green' : 'green') : 'red',
          // background: 'lightgray',
          fontWeight: 400,
          borderBottomLeftRadius: 5,
          borderTopLeftRadius: 5,
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default MarkKeyword;

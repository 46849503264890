import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildDeleteDefault,
  buildGetDefault,
  buildPostBase64,
  buildPostDefault,
  buildPutDefault,
} from 'app/sdk/utils/kazoo';
import buildResourceUrl from 'app/sdk/utils/kazoo/buildResourceUrl';
import constants from 'app/constants';

const exports = {
  // Queries
  // - graphql
  query: {
    medias: (vars, fetchOptions) =>
      fetchFromGraphql('medias', vars, fetchOptions),

    // Fetch a single media by id
    mediaById: (vars, fetchOptions) =>
      fetchFromGraphql('mediaById', vars, fetchOptions),

    url: (vars, fetchOptions) => {
      return `${constants.env.REACT_APP_KAPI_URL}${buildResourceUrl(
        'media',
        vars.id,
        fetchOptions.authToken,
        {
          mediaUrl: true,
          mediaUpdatedAt: vars.updatedAt,
        },
      )}`;
    },
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    updateFull: buildPostDefault('media'),
    create: buildPutDefault('media', { buildUrlOpts: { skipId: true } }),
    upload: buildPostBase64('media'),
    delete: buildDeleteDefault('media'),
  },
};

export default exports;

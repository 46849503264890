import { DetailsDialogDevice } from 'app/components/DetailsDialogDevice';
import { DeviceCodecsDialog } from 'app/components/DeviceCodecsDialog';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import { Cancel, EditPencil } from 'iconoir-react';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceNameProps {
  device: Device;
  onComplete: () => void;
}

const NameDevice = ({ device, onComplete }: DeviceNameProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'name',
    options: [
      {
        label: 'Name',
        value: 'name',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    <>
      <DetailsDialogDevice
        DialogProps={{
          open: showEditDialog,
        }}
        ContentProps={{
          deviceId: device.id,
          onCancel: toggleShowEditDialog,
          onComplete: handleOnComplete,
        }}
      />
      <SettingsPanel
        leftChild={
          <Setting
            label={'Device Name'}
            body={'The display name of the device'}
            iconButtonProps={
              showEditDialog
                ? undefined
                : {
                    children: <EditPencil fr={undefined} />,
                    onClick: toggleShowEditDialog,
                  }
            }
            buttonProps={
              showEditDialog
                ? {
                    children: 'Cancel',
                    startIcon: <Cancel fr={undefined} />,
                    color: 'neutral',
                    onClick: toggleShowEditDialog,
                  }
                : undefined
            }
          />
        }
        rightChild={
          <>
            <Table>
              <TableRow showBorder={false}>
                <TableCell className={`font-medium`}>
                  {device.doc.name}
                </TableCell>
              </TableRow>
            </Table>
            {/*{device?.doc?.media?.video?.codecs?.length ? (
              device.doc.media.video.codecs.join(', ')
            ) : (
              <Alert
                reverse
                icon={<InfoEmpty fr={undefined} />}
                label={'Default Codecs'}
                body={`Using platform codecs.`}
              />
            )}*/}
          </>
        }
      />
    </>
  );

  // return (
  //   // @ts-ignore
  //   <div ref={ref}>
  //     <HighlightPulse {...highlightPulseProps} />
  //     <DetailsDialogDevice
  //       DialogProps={{
  //         open: showEditDialog,
  //       }}
  //       ContentProps={{
  //         deviceId: device.id,
  //         onCancel: toggleShowEditDialog,
  //         onComplete: handleOnComplete,
  //       }}
  //     />
  //     <GenericSettingsCardField
  //       title={'Device Name'}
  //       content={device.doc.name}
  //       editOnClick={toggleShowEditDialog}
  //     />
  //   </div>
  // );
};

export default NameDevice;

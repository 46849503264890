import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { CreateGroupButton } from 'app/components/ListGroups/components/CreateGroupButton';
import { AddMenuButton } from 'app/components/ListMenus/components/AddMenuButton';
import { PlayAudioTableCell } from 'app/components/PlayTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { TextToSpeechTableCell } from 'app/components/TextToSpeechTableCell';
import { useListMenusQuery } from 'app/hooks/queries/menu';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Media } from 'types/media';
import { Menu } from 'types/menu';

const QuickFinderMenuDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (menu: Menu) => menu.doc.name,
        Cell: ({ row, value: name }) => {
          const { original: menu }: { original: Menu } = row;

          return name;
        },
      },
      {
        Header: 'Audio',
        id: 'audio',
        accessor: (menu: Menu) => menu?.Greeting,
        Cell: ({ value: greeting }: { value: Media }) => {
          return <PlayAudioTableCell media={greeting} />;
        },
      },
      {
        Header: 'TTS',
        id: 'tts',
        accessor: (menu: Menu) => menu.Greeting,
        Cell: ({ value: greeting }: { value: Media }) => {
          return <TextToSpeechTableCell media={greeting} />;
        },
      },
    ],
    [],
  );

  // menu list
  const menuListQuery = useListMenusQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['name', 'asc']],
    filters: { searchInput: querySearchInput },
  });
  const { menus, totalCount = 0 } = menuListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      menuListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  const handleCreate = (menuId: string) => {
    // TODO: does not work with passResource
    props.onSelect([menuId]);
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Menus' : 'Select Menu'}
      titleButton={
        props.allowCreate ? <AddMenuButton onComplete={handleCreate} /> : null
      }
      // table
      getRowId={(row: Menu) => row.id}
      searchPlaceholder={'Search Menus...'}
      totalCount={totalCount}
      columns={columns}
      data={menus ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={menuListQuery.isFetching}
      queryIsLoading={menuListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderMenuDialog;

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Callflow } from 'types/callflow';
import callflowQueryKeys from './callflowQueryKeys';

interface QueryParams {
  id?: string;
  options?: Omit<
    UseQueryOptions<Callflow, Error, Callflow, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export const useCallflowQuery = ({ id, options }: QueryParams) => {
  // returns list of callflows
  // - just get ALL callflows+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = callflowQueryKeys.byId(id);

  const query = useQuery<Callflow, Error>(
    queryKey,
    () =>
      sdk.callflow.query.callflowById(
        { id },
        { authToken: authState.auth_token },
      ),
    {
      ...options,
      enabled: options?.enabled ?? !!id, //authState?.auth_token_decoded?.owner_id ? true : false,
      cacheTime: options?.cacheTime ?? 0,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useCallflowQuery;

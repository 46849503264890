import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import { buildPatchDefault } from 'app/sdk/utils/kazoo';

///////////////
// TODO: jsDoc!
///////////////

const exports = {
  // Queries
  // - graphql
  query: {
    // TODO: pagination
    extensions: (vars, fetchOptions) =>
      fetchFromGraphql('extensions', vars, fetchOptions),

    // Fetch a single extension by id
    // extensionById: (vars, fetchOptions) =>
    //   fetchFromGraphql('extensionById', vars, fetchOptions),
  },

  // Mutations
  // - via graphql, for transforming "strategy->flow"
  mutate: {},
};

export default exports;

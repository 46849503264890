import { gql } from 'graphql-request';

// export default gql`
//   query groups($filters: JSON, $mask: String) {
//     groups(filters: $filters, mask: $mask) {
//       id
//       account_id
//       doc
//       listing
//       createdAt
//       updatedAt
//     }
//   }
// `;

export default gql`
  query Query($skip: Int, $take: Int, $filters: JSON, $orderBy: JSON) {
    groups(skip: $skip, take: $take, filters: $filters, orderBy: $orderBy) {
      totalCount
      groups {
        id
        doc
        createdAt
        usedBy
        Callflows {
          id
          doc
        }

        HoldMusic {
          id
          doc
          inherited
        }
      }
    }
  }
`;

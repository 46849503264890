import { Switch as HeadlessSwitch } from '@headlessui/react';
import { Color } from 'app/design-lib/types';
import React, { ReactElement, ReactNode } from 'react';

export interface SwitchProps {
  checked: boolean;
  onClick: (enabled: boolean) => void;
  // checkedColor?: Color;
  // uncheckedColor?: Color;
  label?: ReactNode;
  checkedIcon?: ReactElement;
  uncheckedIcon?: ReactElement;
  condensedLabel?: boolean;
  title?: string;
  size?: 'md' | 'sm';
  isLoading?: Boolean;
}

const Switch = ({
  checked,
  onClick,
  label,
  condensedLabel,
  // checkedColor = 'accent',
  // uncheckedColor = 'neutral',
  checkedIcon,
  uncheckedIcon,
  size = 'md',
  title,
  isLoading = false,
}: SwitchProps) => {
  const iconSize = size === 'md' ? 16 : 12;
  return (
    <HeadlessSwitch.Group>
      <div className={`flex ${condensedLabel ? 'flex-col' : 'items-center'} `}>
        <HeadlessSwitch
          checked={checked}
          onChange={onClick}
          title={title}
          className={`relative  cursor-pointer ${
            size === 'md' ? 'h-5 w-10' : 'h-3 w-6'
          } rounded-full border-0 flex items-center transition duration-300 focus:outline-none  ${
            checked ? `bg-blue-60` : ` bg-gray-30`
          }`}
        >
          <span
            className={`${size === 'md' ? 'w-5 h-5 ' : 'w-3 h-3 '}
                 rounded-full ${
                   size === 'md' ? 'border-[2px]' : 'border-[1.5px]'
                 } transition-all bg-white duration-500 text-white grid place-items-center ${
              checked
                ? `${
                    size === 'md'
                      ? '] translate-x-[1.25rem]'
                      : ' translate-x-[0.75rem]'
                  } border-blue-60`
                : `border-gray-30`
            }`}
          ></span>
          {checked ? (
            isLoading ? (
              <div className={'absolute right-[2px]'}>
                <LoadingSpinner />
              </div>
            ) : checkedIcon ? (
              <div className={'absolute left-[4px]'}>
                {React.cloneElement(checkedIcon, {
                  color: 'white',
                  className: 'stroke-white',
                  height: iconSize,
                  width: iconSize,
                })}
              </div>
            ) : null
          ) : isLoading ? (
            <div className={'absolute left-[2px]'}>
              <LoadingSpinner />
            </div>
          ) : uncheckedIcon ? (
            <div className={'absolute right-[4px]'}>
              {React.cloneElement(uncheckedIcon, {
                color: 'white',
                className: 'stroke-white',
                height: iconSize,
                width: iconSize,
              })}
            </div>
          ) : null}
        </HeadlessSwitch>
        {label ? (
          <HeadlessSwitch.Label
            className={`${
              condensedLabel ? '' : 'ml-2'
            } select-none cursor-pointer ${checked ? `font-medium ` : ` `} ${
              size === 'sm' ? `text-sm` : ` text-md`
            }`}
          >
            {label}
          </HeadlessSwitch.Label>
        ) : null}
      </div>
    </HeadlessSwitch.Group>
  );
};

const LoadingSpinner = () => {
  return (
    <div aria-label="Loading..." role="status">
      <svg
        className="h-4 w-4 animate-spin stroke-gray-500"
        viewBox="0 0 256 256"
      >
        <line
          x1="128"
          y1="32"
          x2="128"
          y2="64"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
        <line
          x1="195.9"
          y1="60.1"
          x2="173.3"
          y2="82.7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
        <line
          x1="224"
          y1="128"
          x2="192"
          y2="128"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
        <line
          x1="195.9"
          y1="195.9"
          x2="173.3"
          y2="173.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
        <line
          x1="128"
          y1="224"
          x2="128"
          y2="192"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
        <line
          x1="60.1"
          y1="195.9"
          x2="82.7"
          y2="173.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
        <line
          x1="32"
          y1="128"
          x2="64"
          y2="128"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
        <line
          x1="60.1"
          y1="60.1"
          x2="82.7"
          y2="82.7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        ></line>
      </svg>
    </div>
  );
};

export default Switch;

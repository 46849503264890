import { Divider, Grid, Typography } from 'app/design';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogHeader,
  DialogActions,
  Button,
  DialogLoading,
} from 'app/design-lib';
import { useAccountQuery } from 'app/hooks/queries/account';
import {
  AddUser as AddUserIcon,
  Copy as CopyIcon,
  Restart as RestartIcon,
  Plus as AddIcon,
} from 'iconoir-react';
import {
  AddUsersFormFields,
  UserFields,
} from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/types';
import { useAddUsersBulk } from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/useAddUsersBulk';
import addBulkUsersFormResolver from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/utils/addBulkUsersFormResolver';
import { UserFieldsRow } from 'app/components/CreateUsersBulk/components/UserFieldsRow';
import { CustomBackdrop } from 'app/components/CustomBackdrop';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { DialogImportUsers } from 'app/components/DialogImportUsers';
import { useListVmboxesQuery } from 'app/hooks/queries/vmbox';
import { useToggleReducer } from 'app/utilities';
import { cloneDeep, flatten, isArray, merge, toInteger } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Extension } from 'types/extension';
import { User, UserPrivLevel } from 'types/user';
import { Vmbox } from 'types/vmbox';
import { StringParam, useQueryParams } from 'use-query-params';
import { v4 as uuidv4 } from 'uuid';

interface AddUsersBulkFormProps {
  extensions: Extension[];
  users: User[];
  lines: any[];
  vmboxes: Vmbox[];
  onRefetch: (silent?: boolean) => void;
  isRefetching: boolean;
  onComplete?: any;
}

export const getNextExtension = (
  starting,
  owner_type,
  additional = [],
  extensions,
) => {
  let extension = starting || 100;
  // console.log('extensions:', extensions, additional);
  let allExtensions = flatten(
    // @ts-ignore
    extensions.map(
      extension =>
        // cf.doc.numbers.filter(num => num.length < 12 && !num.startsWith('*')),
        extension.number,
    ),
  )
    .concat(additional)
    // @ts-ignore
    .map(e => e?.toString());

  let variantExtensions = flatten(
    // @ts-ignore
    extensions
      .map(ext =>
        ext.callflow.doc.owner_type === owner_type ? ext.number : null,
      )
      .filter(v => v !== null),
  )
    // @ts-ignore
    .map(e => e.toString());
  allExtensions.sort((a, b) => toInteger(a) - toInteger(b));
  variantExtensions.sort((a, b) => toInteger(a) - toInteger(b));
  additional.sort((a, b) => toInteger(a) - toInteger(b));
  // start at highest number in "additional"
  // - otherwise, see if this variant already has a "highest" number, use that
  if (additional?.length) {
    extension = toInteger(additional[additional.length - 1]) + 1;
  } else if (variantExtensions.length) {
    extension = toInteger(variantExtensions[variantExtensions.length - 1]) + 1;
  }
  // verify available
  while (true) {
    if (!allExtensions.includes(extension.toString())) {
      break;
    }
    extension++;
  }
  // always string for validation
  return extension.toString();
};

const createNewUserObj = (extensions, additional) => ({
  save_status: null, // none, saving, saved, save_error
  save_error: {
    // track progress of dependent mutations
    callflow_saved: false,
    vmbox_saved: false,
    msg: '',
  },
  external_id: {
    slack: null,
    azure: null,
  },
  id: uuidv4(),
  presence_id: getNextExtension(
    101,
    'user',
    additional.filter(e => !!e), // exclude undefined
    extensions,
  ), // todo: also include extensions from call handling, etc?
  info: {
    first_name: '',
    last_name: '',
    email: '',
    rand_password: true,
    password: '',
    priv_level: UserPrivLevel.User,
    timezone: undefined,
    title: '',
  },
  voicemail: {
    create: false,
  },
  devices: {
    appphone: true,
    webphone: true,
    desk: [], // array of details for each new device... {type, mac}
  },
  call_handling: {
    template_id: null,
  },
  caller_id: {
    basic: true,
  },
  emails: {
    welcome: false,
  },
});

const AddUsersBulkForm = ({
  extensions,
  vmboxes,
  lines,
  users,
  onRefetch,
  isRefetching,
  onComplete = null,
}: AddUsersBulkFormProps) => {
  const [{ via }] = useQueryParams({
    via: StringParam,
  });

  // used to issue warnings if conflict values in a unique field
  const [{ formPresenceIds, formEmails }, setFormConflicts] = useState<{
    formEmails: { [key: string]: number };
    formPresenceIds: { [key: string]: number };
  }>({
    formPresenceIds: {},
    formEmails: {},
  });
  const [showImportDialog, setShowImportDialog] = useState<
    string | undefined | null
  >(via);
  const createNewUserField = (fields: UserFields[] = []) => {
    return createNewUserObj(extensions, [
      ...fields.map(field => field.presence_id),
      // spread users in for possibility of presence id being used but not being
      // on a callflow (and therefore not being an extension)
      ...users.map(u => u.doc.presence_id),
    ]);
  };
  const formMethods = useForm<AddUsersFormFields>({
    defaultValues: {
      // leave empty if importing
      users: via ? [] : [createNewUserField()],
    },
    resolver: addBulkUsersFormResolver(
      users,
      extensions,
      vmboxes,
      setFormConflicts,
    ),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isSubmitted },
    watch,
    setValue,
    trigger,
    setError,
  } = formMethods;

  const handleError = async () => {
    await onRefetch(false);
  };

  const { data: account } = useAccountQuery();

  const {
    mutateAsync: addUsersBulkMutateAsync,
    isLoading,
    error,
    isSuccess,
  } = useAddUsersBulk({
    onComplete: () => {
      onRefetch();
      onComplete();
    },
    onError: handleError,
    setValue,
    setError,
    lines,
  });

  const usersFieldsWatch = watch('users');

  // console.log('formPresenceIds', formPresenceIds, 'formEmails', formEmails);

  const {
    // fields: usersFields, // using watch instead, expected behavior not seen using this
    append,
    remove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'users', // unique name for your Field Array
    shouldUnregister: true,
  });

  const handleAddRow = () => {
    append(createNewUserField(usersFieldsWatch));
  };

  const handleCopyLastRow = () => {
    // run create to get new id and next Ext
    const newRow = createNewUserField(usersFieldsWatch);

    // duplicate last row
    const lastRowCopy = cloneDeep(
      usersFieldsWatch[usersFieldsWatch.length - 1],
    );

    // clear pertinent fields and copy new field id/pres id
    lastRowCopy.id = newRow.id;
    lastRowCopy.presence_id = newRow.presence_id;
    lastRowCopy.info.first_name = '';
    lastRowCopy.info.last_name = '';
    lastRowCopy.info.timezone = undefined;
    lastRowCopy.info.title = '';
    lastRowCopy.info.email = '';
    lastRowCopy.save_status = null;
    lastRowCopy.save_error = {
      // track progress of dependent mutations
      callflow_saved: false,
      vmbox_saved: false,
      msg: '',
    };
    // nextRow.info.password = ''; // reset password??
    lastRowCopy.devices.desk = [];

    append(lastRowCopy);
  };

  const handleRemoveRow = index => () => {
    remove(index);
  };

  const handleSave = async (form: AddUsersFormFields) => {
    await addUsersBulkMutateAsync(form);
  };

  const handleLaunchImportWrap = type => () => setShowImportDialog(type);

  const handleCloseImport = selectedUserArr => {
    // returns empty, or an array of selected user
    // - normalized format:
    //   - info.first_name, etc.
    setShowImportDialog(null);

    if (!isArray(selectedUserArr)) {
      return;
    }

    const newRows: UserFields[] = [];
    for (let userInfo of selectedUserArr) {
      // merge imported info into new user fields
      const newRow = merge(
        createNewUserField(usersFieldsWatch.concat(newRows)),
        {
          info: userInfo,
        },
      );
      newRows.push(newRow);
    }
    append(newRows);
  };

  const handleSubmitForm = async e => {
    handleSubmit(handleSave)(e);
  };

  // console.log('formErrors', formErrors);

  // if (isSuccess) {
  //   return (
  //     <>
  //       <DialogHeader
  //         title={'Invite new teammate'}
  //         subTitle={'Expand your team'}
  //         onClose={onComplete}
  //       />
  //       <DialogContent>
  //         <div className={'w-full grid place-items-center'}>
  //           Teammates added!
  //         </div>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button
  //           size={'md'}
  //           color={'accent'}
  //           variant={'fill'}
  //           onClick={onComplete}
  //         >
  //           Dismiss
  //         </Button>
  //       </DialogActions>
  //     </>
  //   );
  // }

  return (
    <FormProvider {...formMethods}>
      {showImportDialog ? (
        <DialogImportUsers
          // @ts-ignore
          type={showImportDialog}
          onClose={handleCloseImport}
        />
      ) : (
        ''
      )}
      {isRefetching ? (
        <CustomBackdrop label={'Refreshing validation resources...'} />
      ) : null}
      {/*<SuccessDialog onComplete={onComplete} isSuccess={isSuccess} />*/}
      {isSuccess ? (
        <>
          <DialogContent>
            <div className={`w-full py-4 font-medium grid place-items-center`}>
              <p>Invite successfully sent!</p>
              <p>
                {usersFieldsWatch[0].info.first_name +
                  ' ' +
                  usersFieldsWatch[0].info.last_name}{' '}
                received an email invitation.
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <div className={'flex w-full items-center justify-end'}>
              <Button
                color="accent"
                variant="fill"
                size={'md'}
                onClick={onComplete}
              >
                Dismiss
              </Button>
            </div>
          </DialogActions>
        </>
      ) : isLoading ? (
        <DialogLoading
          label={`inviting ${
            usersFieldsWatch[0].info.first_name +
            ' ' +
            usersFieldsWatch[0].info.last_name
          }...`}
        />
      ) : (
        <>
          <DialogHeader
            title={'Invite new teammate'}
            subTitle={'Each teammate is $5/month'}
            onClose={onComplete}
          />
          <DialogContent className={`!overflow-y-auto sm:overflow-visible`}>
            {/*<div className={'w-full ml-12 grid place-items-center'}>*/}
            {/*    <Grid container spacing={1} alignItems="center">
            <Grid item>
              <span className={'text-lg'}></span>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {localState.status === 'validating' ? (
                <Typography variant="h5">Validating...</Typography>
              ) : null}
            </Grid>
            <Grid item style={{}}>
          <ButtonDropdownMenu
            // @ts-ignore
            label="Import Users Via:"
            variant="button"
            disabled={isLoading}
            buttonVariant="contained"
            menuItems={[
              // {
              //   text: 'CSV / Google Sheets',
              //   onClick: handleLaunchImportWrap('csv'),
              // },
              {
                text: 'Slack',
                onClick: handleLaunchImportWrap('slack'),
              },
              {
                text: 'MS Azure Active Directory',
                onClick: handleLaunchImportWrap('azure'),
              },
            ]}
            // menuItemsDependencies={[dispatchRedux, actions]}
          />
        </Grid>
          </Grid>
          <br />
          <br />*/}

            {usersFieldsWatch.map((row, index) => (
              <UserFieldsRow
                key={row.id}
                index={index}
                userFields={row}
                register={register}
                onRemove={handleRemoveRow(index)}
                formErrors={formErrors}
                formSaving={isLoading}
                control={control}
                formPresenceIds={formPresenceIds}
                formEmails={formEmails}
                lines={lines}
                account={account}
              />
            ))}
            {/*</div>*/}
          </DialogContent>
          <DialogActions>
            <div className={'flex w-full items-center justify-between'}>
              {/*<div className={'flex items-center space-x-2'}>
            <Button
              size={'md'}
              variant="outline"
              startIcon={<AddIcon fr={undefined} />}
              onClick={handleAddRow}
              disabled={isLoading}
              color={'accent'}
            >
              Add Another User
            </Button>
            <Button
              size={'md'}
              variant="outline"
              color={'neutral'}
              startIcon={<CopyIcon fr={undefined} />}
              onClick={handleCopyLastRow}
              disabled={isLoading || !usersFieldsWatch.length}
            >
              Copy Last Row
            </Button>
          </div>*/}
              {/* <Grid item>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddRow(null)}
            >
              Export this list as CSV (so you can edit that list in your own
              editor, then import)
            </Button>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddRow(null)}
            >
              Edit list in Google Sheets and Re-import
            </Button>
          </Grid> */}
              {/* <Grid item style={{ flex: 1 }}>
          {error ? (
            <Typography color={'error'}>{error?.message}</Typography>
          ) : null}
        </Grid>*/}
              <Button
                color="neutral"
                variant="ghost"
                size={'md'}
                onClick={onComplete}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                color="accent"
                variant="fill"
                size={'md'}
                /*startIcon={
              error ? (
                <RestartIcon fr={undefined} />
              ) : (
                <AddUserIcon fr={undefined} />
              )
            }*/
                onClick={handleSubmitForm}
                disabled={!usersFieldsWatch.length || isLoading}
              >
                {error ? 'Retry' : 'Invite New Team Member'}
              </Button>
            </div>
          </DialogActions>
        </>
      )}
    </FormProvider>
  );
};

const SuccessDialog = ({ onComplete, isSuccess }) => {
  const router = useHistory();
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  useEffect(() => {
    if (isSuccess) toggleShowDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleView = () => {
    onComplete ? onComplete() : router.push('/admin/users');
  };

  // if (!showDialog) return null;

  return (
    <Dialog open={showDialog} onClose={toggleShowDialog}>
      <DialogTitle>Add Users</DialogTitle>
      <Divider />
      <DialogContent>
        <br />
        Users Successfully Added!
        <br />
        <br />
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        saveLabel={'View All Users'}
        onSave={handleView}
        cancelLabel={'Close'}
        onCancel={toggleShowDialog}
      />
    </Dialog>
  );
};

export default AddUsersBulkForm;

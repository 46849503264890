import { EditMediaNameDialog } from 'app/components/EditMediaNameDialog';
import { Attention } from 'app/design-lib/color/color.stories';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { RadioGroup } from 'app/design-lib/components/RadioGroup';
import useMediaQuery from 'app/hooks/queries/media/useMediaQuery';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogAlert,
  DialogContent,
  DialogHeader,
  DialogLoading,
  IconButton,
  SquareProgress,
} from 'app/design-lib';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { Cancel, Copy, MoreVert, WarningTriangle } from 'iconoir-react';
import { useDeleteMedia } from 'app/hooks/mutations/media';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

interface LibraryViewProps {
  onComplete: (mediaId: string | null) => Promise<void>;
  onCancel: () => void;
  media: MediaOption[];
  refetch: () => void;
  loadingLabel?: string;
  setIsMutating: React.Dispatch<boolean>;
}

type MediaOption = { name: string; id: string; type: string };

const LibraryView = ({
  onComplete,
  onCancel,
  media,
  refetch,
  loadingLabel,
  setIsMutating,
}: LibraryViewProps) => {
  const [value, setValue] = useState<any | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteOption, setDeleteOption] = useState<MediaOption | null>(null);
  const [editOption, setEditOption] = useState<MediaOption | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSave = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      await onComplete(value?.id);
    } catch (e) {
      console.error(e);
      setErrorMessage(
        'Error updating media. Please try again later and contact support if this issue persists.',
      );
    }
    setIsLoading(false);
  };
  const onClose = () => {
    setDeleteOption(null);
  };

  useEffect(() => {
    setIsMutating(isLoading);
  }, [isLoading]);

  return (
    <>
      <DeleteMediaDialog
        open={!!deleteOption}
        media={deleteOption}
        onClose={onClose}
        onComplete={refetch}
      />
      <EditMediaNameDialog
        ContentProps={{
          mediaId: editOption?.id!,
          mediaName: editOption?.name!,
          onCancel: () => setEditOption(null),
          onComplete: () => setEditOption(null),
        }}
        DialogProps={{ open: !!editOption }}
      />
      {isLoading ? (
        <div className={`w-full h-full grid place-items-center`}>
          <div className={`flex flex-col items-center justify-center`}>
            <SquareProgress />
            <span className={`font-medium text-md`}>
              {loadingLabel || 'Updating media...'}
            </span>
          </div>
        </div>
      ) : (
        <div className={`flex flex-col space-y-2 w-full`}>
          {!isLoading && !media?.length ? (
            <Alert
              reverse
              color={'accent'}
              label={'Add media'}
              body={`Media that you've uploaded, recorded, or added as Text-to-Speech will be available in your Library`}
            />
          ) : null}
          <RadioGroup
            className={`w-full`}
            value={value}
            onChange={setValue}
            options={media}
            render={option => {
              return (
                <div className={`grid grid-cols-12 w-full`}>
                  <div
                    className={`flex items-center space-x-1 col-span-5 justify-between`}
                  >
                    <p className={`truncate`}>{option.name}</p>
                    <div>
                      {option.type === 'built-in' ? (
                        <Badge color={'accent'} variant={'fill'} reverse>
                          Built-in
                        </Badge>
                      ) : (
                        <Badge
                          color={'positive'}
                          variant={'fill'}
                          className={`whitespace-nowrap`}
                          reverse
                        >
                          {option.type}
                        </Badge>
                      )}
                    </div>
                    {option.data ? (
                      <IconButton
                        color={'accent'}
                        size={'sm'}
                        variant={'outline'}
                        title={'Copy Text-to-Speech'}
                        onClick={e => {
                          copy(option.data);
                          e.stopPropagation();
                          toast.success('Text-to-Speech copied to clipboard!');
                        }}
                      >
                        <Copy fr={undefined} />
                      </IconButton>
                    ) : null}
                  </div>
                  <div className={`col-span-6`}>
                    <AudioPlayer mini mediaId={option.id} variant={'ghost'} />
                  </div>
                  <div className={`col-span-1 pt-1`}>
                    {option.type !== 'built-in' ? (
                      <IconButtonDropdown
                        menuItems={[
                          {
                            label: 'Edit',
                            onClick: () => setEditOption(option),
                          },
                          {
                            label: 'Delete',
                            onClick: () => setDeleteOption(option),
                          },
                        ]}
                        variant={'ghost'}
                        size={'sm'}
                        hideArrow
                      >
                        <MoreVert fr={undefined} />
                      </IconButtonDropdown>
                    ) : null}
                  </div>
                </div>
              );
            }}
          />
          {value ? (
            <div className={`flex mt-2 space-x-2 items-center`}>
              <Button
                size={'md'}
                color={`accent`}
                variant={`fill`}
                onClick={onSave}
              >
                Save & apply
              </Button>
              <Button
                size={'md'}
                color={`neutral`}
                variant={`ghost`}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          ) : null}
          {errorMessage ? (
            <span className={'mt-2 font-medium text-md text-negative-60'}>
              {errorMessage}
            </span>
          ) : null}
        </div>
      )}
    </>
  );
};

const DeleteMediaDialog = props => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DeleteMediaContent {...props} />
    </Dialog>
  );
};

const DeleteMediaContent = ({ open, media: option, onClose, onComplete }) => {
  const { data: media, isLoading } = useMediaQuery({ id: option?.id });
  const deleteMedia = useDeleteMedia();
  const onDelete = async () => {
    await deleteMedia.mutateAsync(option?.id);

    onComplete();
  };

  return (
    <>
      {deleteMedia.isLoading || isLoading ? (
        <DialogLoading
          label={isLoading ? 'Checking media usage...' : 'Deleting media...'}
        />
      ) : (
        <>
          {deleteMedia.isSuccess ? null : (
            <DialogHeader title={'Delete media'} onClose={onClose} />
          )}
          {deleteMedia.isError ? (
            <DialogAlert
              errorMessage={'Error deleting media. Please try again later'}
              onClose={deleteMedia.reset}
            />
          ) : media?.usedBy?.length ?? 0 > 1 ? (
            <div className={'p-2'}>
              <Alert
                icon={<WarningTriangle fr={undefined} />}
                body={`Deleting this media will remove its usage in all locations`}
                label={'Warning: media used in multiple locations'}
                color={'negative'}
                reverse
              />
            </div>
          ) : null}
          <DialogContent>
            <div className={'w-full text-md font-medium text-center'}>
              {deleteMedia.isSuccess ? (
                <p>Media deleted.</p>
              ) : (
                <p>Are you sure you want to delete {option?.name}?</p>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className={`w-full flex justify-between`}>
              <Button
                color="neutral"
                variant="ghost"
                size={'md'}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color={deleteMedia.isSuccess ? 'accent' : 'negative'}
                variant="fill"
                size={'md'}
                onClick={deleteMedia.isSuccess ? onClose : onDelete}
              >
                {deleteMedia.isSuccess ? 'Dismiss' : 'Permanently delete media'}
              </Button>
            </div>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default LibraryView;

import { IconButton } from '@mui/material';
import {
  AddUsersFormFields,
  UserFields,
} from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/types';
import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import { TIMEZONE_LIST } from 'app/components/TimezoneDialogAccount/TimezoneDialogAccount';
import { TextField } from 'app/design-lib';
import { Listbox } from 'app/design-lib/components/Listbox';
import { startCase } from 'lodash';
import React from 'react';
import {
  Control,
  Controller,
  useFormContext,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InfoTooltip,
  TextField as InputTextField,
  Typography,
  MenuItem,
  Autocomplete,
} from 'app/design';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
} from 'app/design/icons-material';
import { Account } from 'types/account';
import { UserDoc, UserPrivLevel } from 'types/user';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { TextField as MuiTextField } from 'app/design';

interface UserFieldsRowProps {
  index: number;
  userFields: UserFields;
  register: UseFormRegister<AddUsersFormFields>;
  onRemove: () => void;
  formErrors: any;
  control: Control<AddUsersFormFields, object>;
  formSaving: boolean;
  formEmails: { [key: string]: number };
  formPresenceIds: { [key: string]: number };
  lines: any[];
  account?: Account;
}

const UserFieldsRow = ({
  index,
  userFields,
  account,
  register,
  onRemove,
  formErrors,
  control,
  formSaving,
  formEmails,
  formPresenceIds,
  lines,
}: UserFieldsRowProps) => {
  const { watch } = useFormContext();
  const fieldName = `users.${index}`;
  const status = userFields.save_status;

  const disabled =
    formSaving || status === 'saved' || status === 'partial_error';

  const conflictingEmail = formEmails[userFields.info.email] > 1;
  const conflictingPresenceId = formPresenceIds[userFields.presence_id] > 1;

  // console.log('userField', userFields);

  return (
    <div className={`grid grid-cols-4 w-full gap-2`}>
      <div className={`col-span-4 sm:col-span-2`}>
        <TextField
          label="First Name"
          // @ts-ignore
          {...register(`${fieldName}.info.first_name`)}
          // size="small"
          // fullWidth
          hasError={!!formErrors.users?.[index]?.info?.first_name}
          helperText={formErrors.users?.[index]?.info?.first_name?.message}
          disabled={disabled}
        />
      </div>

      <div className={`col-span-4 sm:col-span-2`}>
        <TextField
          label="Last Name"
          // @ts-ignore
          {...register(`${fieldName}.info.last_name`)}
          // size="small"
          // fullWidth
          hasError={!!formErrors.users?.[index]?.info?.last_name}
          helperText={formErrors.users?.[index]?.info?.last_name?.message}
          disabled={disabled}
        />
      </div>

      <div className={`col-span-4 sm:col-span-2`}>
        <TextField
          label="Email"
          // size="small"
          // @ts-ignore
          {...register(`${fieldName}.info.email`)}
          // fullWidth
          type="email"
          color={conflictingEmail ? 'warning' : undefined}
          // colorAlways
          hasError={!!formErrors.users?.[index]?.info?.email}
          helperText={
            formErrors.users?.[index]?.info?.email?.message ??
            (conflictingEmail ? 'Email is used in another field' : undefined)
          }
          disabled={disabled}
        />
      </div>
      <div className={`col-span-2 sm:col-span-1`}>
        <HookFormListbox
          options={Object.values(UserPrivLevel).map(role => ({
            value: role,
            label: startCase(role),
          }))}
          name={`${fieldName}.info.priv_level`}
          label={'Role'}
          disabled={disabled}
          className={`w-full h-full`}
        />
      </div>
      <div className={`col-span-2 sm:col-span-1`}>
        <TextField
          label="Extension"
          // @ts-ignore
          {...register(`${fieldName}.presence_id`)}
          type={'number'}
          color={conflictingPresenceId ? 'warning' : undefined}
          hasError={!!formErrors.users?.[index]?.presence_id?.message}
          helperText={
            formErrors.users?.[index]?.presence_id?.message ??
            (conflictingPresenceId
              ? 'Presence ID is used in another field'
              : undefined)
          }
          disabled={disabled}
        />
      </div>
      <div className={`col-span-4 sm:col-span-2`}>
        <TextField
          label="Title"
          // size="small"
          // @ts-ignore
          {...register(`${fieldName}.info.title`)}
          // fullWidth
          type="title"
          // colorAlways
          hasError={!!formErrors.users?.[index]?.info?.title}
          helperText={formErrors.users?.[index]?.info?.title?.message}
          disabled={disabled}
        />
      </div>
      <div className={`col-span-4 sm:col-span-2`}>
        <HookFormListbox
          // @ts-ignore
          name={`${fieldName}.info.timezone`}
          label={`Timezone`}
          placeholder={`Inherit from account`}
          size={'md'}
          className={`w-full h-[2.65rem]`}
          options={[
            {
              label: (
                <span className={`text-neutral-60`}>Inherit from account</span>
              ),
              value: undefined,
            },
            ...TIMEZONE_LIST,
          ]}
          helperText={
            watch(`${fieldName}.timezone`)
              ? ''
              : TIMEZONE_LIST.find(tz => tz.value === account?.doc.timezone)
                  ?.label
          }
        />
      </div>
      <div className={`col-span-4 h-full`}>
        <HookFormListbox
          options={lines
            .filter(line => line.type !== 'personal')
            .map(line => ({
              value: line.id,
              label: (
                <div className={`flex space-x-2 items-center`}>
                  <span>{line.metadata.name}</span>
                  <div className={`text-neutral-60`}>
                    <PhoneNumberDisplay ptn={line.key} />
                  </div>
                </div>
              ),
            }))}
          name={`${fieldName}.lines`}
          label={'Add to line'}
          disabled={disabled}
          className={`w-full`}
          multiple
          placeholder={`Choose lines`}
        />
      </div>
    </div>
  );

  // return (
  //   <Grid
  //     container
  //     spacing={3}
  //     className={'items-center'}
  //     // sx={{ borderBottom: '1px solid #ccc' }}
  //   >
  //     <Grid item md={1}>
  //       <div className={'flex space-x-1 mt-6 items-center font-medium text-lg'}>
  //         <IconButton onClick={onRemove} size="small" disabled={disabled}>
  //           <CloseIcon />
  //         </IconButton>
  //         <span>{index + 1}.</span>
  //       </div>
  //       <div className={'flex items-center space-x-1'}>
  //         <span className={'text-sm font-medium'}>
  //           {status ? startCase(status.replace('_', ' ')) : ''}
  //         </span>
  //         {status === 'saving' ? (
  //           <CircularProgress size={20} />
  //         ) : status === 'saved' ? (
  //           <CheckIcon color={'success'} />
  //         ) : status === 'partial_error' ? (
  //           <>
  //             <ErrorIcon color={'warning'} />
  //             <br />
  //             <Grid container spacing={1} wrap={'nowrap'}>
  //               <Grid item>
  //                 <Typography variant={'caption'}>Failed to save:</Typography>
  //               </Grid>
  //               <Grid item>
  //                 <InfoTooltip
  //                   size={'small'}
  //                   title={
  //                     'User creation was successful - see user page to resolve below error'
  //                   }
  //                 />
  //               </Grid>
  //             </Grid>
  //             {userFields.save_error.callflow_saved ? null : (
  //               <>
  //                 <Typography variant={'caption'} color={'error'}>
  //                   - Call Routing
  //                 </Typography>
  //                 <br />
  //               </>
  //             )}
  //             {userFields.voicemail.create &&
  //             !userFields.save_error.vmbox_saved ? (
  //               <Typography variant={'caption'} color={'error'}>
  //                 - Voicemail Box
  //               </Typography>
  //             ) : null}
  //           </>
  //         ) : status === 'error' ? (
  //           <ClearIcon color={'error'} />
  //         ) : null}
  //       </div>
  //     </Grid>
  //     <Grid item md={11} sx={{ flex: 1 }}>
  //       <Grid container spacing={1} sx={{}}>
  //         <Grid item md={2}>
  //           <TextField
  //             label="Extension"
  //             // @ts-ignore
  //             {...register(`${fieldName}.presence_id`)}
  //             type={'number'}
  //             color={conflictingPresenceId ? 'warning' : undefined}
  //             hasError={!!formErrors.users?.[index]?.presence_id?.message}
  //             helperText={
  //               formErrors.users?.[index]?.presence_id?.message ??
  //               (conflictingPresenceId
  //                 ? 'Presence ID is used in another field'
  //                 : undefined)
  //             }
  //             disabled={disabled}
  //           />
  //         </Grid>
  //         <Grid item md={2}>
  //           <TextField
  //             label="First Name"
  //             // @ts-ignore
  //             {...register(`${fieldName}.info.first_name`)}
  //             // size="small"
  //             // fullWidth
  //             hasError={!!formErrors.users?.[index]?.info?.first_name}
  //             helperText={formErrors.users?.[index]?.info?.first_name?.message}
  //             disabled={disabled}
  //           />
  //         </Grid>
  //         <Grid item md={2}>
  //           <TextField
  //             label="Last Name"
  //             // @ts-ignore
  //             {...register(`${fieldName}.info.last_name`)}
  //             // size="small"
  //             // fullWidth
  //             hasError={!!formErrors.users?.[index]?.info?.last_name}
  //             helperText={formErrors.users?.[index]?.info?.last_name?.message}
  //             disabled={disabled}
  //           />
  //         </Grid>
  //
  //         <Grid item md={3}>
  //           <TextField
  //             label="Email"
  //             // size="small"
  //             // @ts-ignore
  //             {...register(`${fieldName}.info.email`)}
  //             // fullWidth
  //             type="email"
  //             color={conflictingEmail ? 'warning' : undefined}
  //             // colorAlways
  //             hasError={!!formErrors.users?.[index]?.info?.email}
  //             helperText={
  //               formErrors.users?.[index]?.info?.email?.message ??
  //               (conflictingEmail
  //                 ? 'Email is used in another field'
  //                 : undefined)
  //             }
  //             disabled={disabled}
  //           />
  //         </Grid>
  //         <Grid item md={2}>
  //           <HookFormListbox
  //             options={Object.values(UserPrivLevel).map(role => ({
  //               value: role,
  //               label: role.toUpperCase(),
  //             }))}
  //             name={`${fieldName}.info.priv_level`}
  //             label={'Role'}
  //             disabled={disabled}
  //           />
  //
  //           {/*/>*/}
  //           {/*<InputTextField*/}
  //           {/*  variant="outlined"*/}
  //           {/*  size="small"*/}
  //           {/*  fullWidth*/}
  //           {/*  select*/}
  //           {/*  // @ts-ignore*/}
  //           {/*  {...register(`${fieldName}.info.priv_level`)}*/}
  //           {/*  defaultValue={UserPrivLevel.User}*/}
  //           {/*  label={'Role'}*/}
  //           {/*  disabled={disabled}*/}
  //           {/*>*/}
  //           {/*  {Object.values(UserPrivLevel).map(role => (*/}
  //           {/*    <MenuItem value={role} key={role}>*/}
  //           {/*      {role.toUpperCase()}*/}
  //           {/*    </MenuItem>*/}
  //           {/*  ))}*/}
  //           {/*</InputTextField>*/}
  //         </Grid>
  //
  //         {/* <Grid item md={7}> */}
  //         {/* {userFields.info.rand_password ? null : (
  //             <InputTextField
  //               label="Password"
  //               placeholder="Password"
  //               // @ts-ignore
  //               {...register(`${fieldName}.info.password`)}
  //               size="small"
  //               fullWidth
  //               error={!!formErrors.users?.[index]?.info?.password}
  //               helperText={formErrors.users?.[index]?.info?.password?.message}
  //               disabled={disabled}
  //             />
  //           )} */}
  //         {/* <div style={{ whiteSpace: 'nowrap' }}>
  //             <Controller
  //               control={control}
  //               // @ts-ignore
  //               name={`${fieldName}.info.rand_password`}
  //               render={({ field: { onChange, value, ...props } }) => {
  //                 return (
  //                   <FormControlLabel
  //                     control={
  //                       <Checkbox
  //                         color="primary"
  //                         // @ts-ignore
  //                         checked={value}
  //                         onChange={e => onChange(e.target.checked)}
  //                       />
  //                     }
  //                     label={`Generate Random Password`}
  //                     disabled={disabled}
  //                   />
  //                 );
  //               }}
  //             />
  //           </div> */}
  //         {/* </Grid> */}
  //       </Grid>
  //     </Grid>
  //     <Grid item md={5}>
  //       <Grid container spacing={1} sx={{}}>
  //         <Grid item sx={{ display: 'none' }}>
  //           {/*Voicemail*/}
  //           <div style={{ whiteSpace: 'nowrap' }}>
  //             <Controller
  //               control={control}
  //               // @ts-ignore
  //               name={`${fieldName}.voicemail.create`}
  //               render={({ field: { onChange, value, ...props } }) => {
  //                 return (
  //                   <>
  //                     <FormControlLabel
  //                       control={
  //                         <Checkbox
  //                           color={
  //                             !!formErrors.users?.[index]?.presence_id
  //                               ?.voicemail
  //                               ? 'error'
  //                               : 'primary'
  //                           }
  //                           // @ts-ignore
  //                           checked={value}
  //                           onChange={e => onChange(e.target.checked)}
  //                           disabled={disabled}
  //                         />
  //                       }
  //                       label={`Create Voicemail Box #${userFields.presence_id}`}
  //                     />
  //                     <br />
  //                     {/* NOTE: path is not a mistake, piggy backing off presence_id due to the way
  //                      react-hook-form clears errors */}
  //                     {!!formErrors.users?.[index]?.presence_id?.voicemail ? (
  //                       <Typography color={'error'} variant={'caption'}>
  //                         {
  //                           formErrors.users[index].presence_id.voicemail
  //                             .message
  //                         }
  //                       </Typography>
  //                     ) : null}
  //                   </>
  //                 );
  //               }}
  //             />
  //           </div>
  //           {/*Devices*/}
  //           {/* <div style={{ whiteSpace: 'nowrap' }}>
  //             <Controller
  //               control={control}
  //               // @ts-ignore
  //               name={`${fieldName}.devices.appphone`}
  //               render={({ field: { onChange, value, ...props } }) => {
  //                 return (
  //                   <FormControlLabel
  //                     control={
  //                       <Checkbox
  //                         color="primary"
  //                         // @ts-ignore
  //                         checked={value}
  //                         onChange={e => onChange(e.target.checked)}
  //                         disabled={disabled}
  //                       />
  //                     }
  //                     label={`Allow iOS/Android Apps`}
  //                   />
  //                 );
  //               }}
  //             />
  //           </div>
  //           <div style={{ whiteSpace: 'nowrap' }}>
  //             <Controller
  //               control={control}
  //               // @ts-ignore
  //               name={`${fieldName}.devices.webphone`}
  //               render={({ field: { onChange, value, ...props } }) => {
  //                 return (
  //                   <FormControlLabel
  //                     control={
  //                       <Checkbox
  //                         color="primary"
  //                         // @ts-ignore
  //                         checked={value}
  //                         onChange={e => onChange(e.target.checked)}
  //                         disabled={disabled}
  //                       />
  //                     }
  //                     label={`Allow Webphone`}
  //                   />
  //                 );
  //               }}
  //             />
  //           </div> */}
  //           {/* <div style={{ whiteSpace: 'nowrap' }}>
  //             <FormControlLabel
  //               control={
  //                 <Checkbox
  //                   color="primary"
  //                   checked={getAtPath(row, 'emails.welcome')}
  //                   onChange={handleUpdateCheckbox(idx, 'emails.welcome')}
  //                   disabled={row.save_status === 'saved'}
  //                 />
  //               }
  //               label={`Send Welcome Email`}
  //             />
  //           </div> */}
  //           {/* <div style={{ whiteSpace: 'nowrap' }}>
  //             <Button size="xsmall" variant="outlined" color="info">
  //               Add MAC Device
  //             </Button>
  //           </div> */}
  //           {/* Call Routing */}
  //           {/* <AutocompleteTemplate
  //         label=""
  //         defaultValue={getAtPath(row, 'call_handling.template_id')}
  //         onChange={handleSelectTemplate(idx, 'call_handling.template_id')}
  //       /> */}
  //         </Grid>
  //         {/* <Grid item>[no extensions]</Grid>
  //         <Grid item>[no direct numbers]</Grid> */}
  //         {/* <Grid item>
  //
  //           <div style={{ whiteSpace_IGNORE: 'nowrap' }}>
  //             <FormControlLabel
  //               control={
  //                 <Checkbox
  //                   color="primary"
  //                   checked={getAtPath(row, 'caller_id.basic')}
  //                   onChange={handleUpdateCheckbox(idx, 'caller_id.basic')}
  //                 />
  //               }
  //               label={`Basic Caller ID (external use account number, internal use Presence and Name)`}
  //             />
  //           </div>
  //         </Grid> */}
  //       </Grid>
  //     </Grid>
  //     {/* vmbox save errors */}
  //     {userFields.save_error?.vmbox ? (
  //       <Grid container sx={{ flex: 1 }} spacing={1} justifyContent={'center'}>
  //         <Grid item>
  //           <Typography color={'error'}>Voicemail Errors:</Typography>
  //         </Grid>
  //         <Grid item>
  //           {Object.values(userFields.save_error?.vmbox).map(key => (
  //             // @ts-ignore
  //             <Typography color={'error'}>{key.message}</Typography>
  //           ))}
  //         </Grid>
  //       </Grid>
  //     ) : null}
  //   </Grid>
  // );
};

export default UserFieldsRow;

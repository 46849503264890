import {
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogLoading,
} from 'app/design-lib';
import { AddPhonesFormFields } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/types';
import { useAddPhonesBulk } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/useAddPhonesBulk';
import { addBulkPhonesFormResolver } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/utils';
import { PhoneFieldsRow } from 'app/components/CreatePhonesBulk/components/PhoneFieldsRow';
import { CustomBackdrop } from 'app/components/CustomBackdrop';
import { cloneDeep } from 'lodash';
import React from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Device } from 'types/device';
import { Extension } from 'types/extension';
import { useImmer } from 'use-immer';
import { StringParam, useQueryParams } from 'use-query-params';
import { v4 as uuidv4 } from 'uuid';
import { User } from 'types/user';
import { useHistory } from 'react-router-dom';

interface AddDevicesBulkFormProps {
  extensions: Extension[];
  devices: Device[];
  onRefetch: (silent?: boolean) => void;
  isRefetching: boolean;
  onComplete?: any;
  users: User[];
}

export type DeviceTypes =
  | 'sip_device'
  | 'application'
  | 'softphone'
  | 'webphone'
  | 'appphone';
export type DeviceSubTypes = 'bria' | 'zoiper' | 'webphone' | 'appphone';

const createNewDeviceObj = (owner_id?) => ({
  save_status: null, // none, saving, saved, save_error
  save_error: {
    // track progress of dependent mutations
    callflow_saved: false,
    msg: '',
  },
  id: uuidv4(),
  final_id: null,
  name: '',
  mac_address: '',
  device_type: 'application_bria' as DeviceTypes,
  // device_subtype: 'bria' as DeviceSubTypes,
  owner_id,
  call_handling: {
    template_id: null,
  },
  caller_id: {
    basic: true,
  },
});

const AddPhonesBulkForm = ({
  extensions,
  devices,
  onRefetch,
  isRefetching,
  users,
  onComplete = null,
}: AddDevicesBulkFormProps) => {
  const router = useHistory();
  const [{ via, owner_id }] = useQueryParams({
    via: StringParam,
    owner_id: StringParam,
  });

  // used to issue warnings if conflict values in a unique field
  const [{ formMacAddresses }, setFormConflicts] = useImmer<{
    formMacAddresses: { [key: string]: number };
  }>({
    formMacAddresses: {},
  });
  const [showImportDialog, setShowImportDialog] = useImmer<
    string | undefined | null
  >(via);
  const createNewPhoneField = (owner_id?) => {
    return createNewDeviceObj(owner_id);
  };
  const formMethods = useForm<AddPhonesFormFields>({
    defaultValues: {
      // leave empty if importing
      phones: via ? [] : [createNewPhoneField(owner_id)],
    },
    resolver: addBulkPhonesFormResolver(devices, setFormConflicts),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isSubmitted },
    watch,
    setValue,
    trigger,
    setError,
  } = formMethods;
  // console.log('form errors', formErrors);

  const handleError = async () => {
    await onRefetch(false);
  };
  const {
    mutateAsync: addDevicesBulkMutateAsync,
    isLoading,
    error,
    isSuccess,
  } = useAddPhonesBulk({
    onComplete: onRefetch,
    onError: handleError,
    setValue,
    setError,
  });
  const phonesFieldsWatch = watch('phones');

  const { append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'phones', // unique name for your Field Array
    shouldUnregister: true,
  });

  const handleAddRow = () => {
    append(createNewPhoneField());
  };

  const handleCopyLastRow = () => {
    const newRow = createNewPhoneField();

    // duplicate last row
    const lastRowCopy = cloneDeep(
      phonesFieldsWatch[phonesFieldsWatch.length - 1],
    );

    // clear pertinent fields and copy new field id/pres id
    lastRowCopy.id = newRow.id;
    lastRowCopy.name = '';
    lastRowCopy.mac_address = '';
    lastRowCopy.save_status = null;
    lastRowCopy.final_id = null;
    lastRowCopy.save_error = {
      msg: '',
      callflow_saved: false,
    };

    append(lastRowCopy);
  };

  const handleRemoveRow = index => () => {
    remove(index);
  };

  const handleSave = async (form: AddPhonesFormFields) => {
    await addDevicesBulkMutateAsync(form);
  };

  const handleSubmitForm = async e => {
    handleSubmit(handleSave)(e);
  };

  // if (isSuccess) {
  //   return (
  //     <>
  //       <DialogHeader
  //         title={'Add New Phones'}
  //         subTitle={`
  //             Easily bring your existing phones over to CallingIO.
  //           `}
  //         onClose={onComplete}
  //       />
  //       <DialogContent>
  //         <div className={'w-full grid place-items-center'}>Phones added!</div>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button
  //           size={'md'}
  //           color={'accent'}
  //           variant={'fill'}
  //           onClick={onComplete}
  //         >
  //           Dismiss
  //         </Button>
  //       </DialogActions>
  //     </>
  //   );
  // }

  return (
    <FormProvider {...formMethods}>
      {isRefetching ? (
        <CustomBackdrop label={'Refreshing validation resources...'} />
      ) : null}
      {/*<SuccessDialog onComplete={onComplete} isSuccess={isSuccess} />*/}

      {isSuccess ? (
        <>
          <DialogContent>
            <div className={`w-full py-4 font-medium grid place-items-center`}>
              <p>Phone successfully added!</p>
              <p>{phonesFieldsWatch[0].name} has been added.</p>
            </div>
          </DialogContent>
          <DialogActions>
            <div className={'flex w-full items-center justify-between'}>
              {phonesFieldsWatch?.length === 1 ? (
                <Button
                  color="accent"
                  variant="outline"
                  size={'md'}
                  onClick={() => {
                    router.push(
                      `/v2/settings/devices/${phonesFieldsWatch[0].final_id}`,
                    );
                  }}
                >
                  View Device
                </Button>
              ) : null}
              <Button
                color="accent"
                variant="fill"
                size={'md'}
                onClick={onComplete}
                className="ml-auto"
              >
                Dismiss
              </Button>
            </div>
          </DialogActions>
        </>
      ) : isLoading ? (
        <DialogLoading label={`Adding ${phonesFieldsWatch[0].name}...`} />
      ) : (
        <>
          <DialogHeader
            onClose={onComplete}
            title={'Add phone'}
            subTitle={` Transfer an existing phone or add a new one`}
          />
          <DialogContent className={`overflow-visible`}>
            {phonesFieldsWatch.map((row, index) => (
              <PhoneFieldsRow
                key={row.id}
                index={index}
                phoneFields={row}
                register={register}
                onRemove={handleRemoveRow(index)}
                formErrors={formErrors}
                formSaving={isLoading}
                setValue={setValue}
                formMacAddresses={formMacAddresses}
                control={control}
                users={users}
                // formPresenceIds={formPresenceIds}
                // formEmails={formEmails}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <div className={'flex w-full items-center justify-between'}>
              <Button
                color="neutral"
                variant="ghost"
                size={'md'}
                onClick={onComplete}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                color="accent"
                variant="fill"
                size={'md'}
                onClick={handleSubmitForm}
                disabled={!phonesFieldsWatch.length || isLoading}
                className="ml-auto"
              >
                {error ? 'Retry' : 'Add new'}
              </Button>
            </div>
          </DialogActions>
        </>
      )}
    </FormProvider>
  );
};

// const SuccessDialog = ({ onComplete, isSuccess }) => {
//   const router = useHistory();
//   const [showDialog, toggleShowDialog] = useToggleReducer(false);
//
//   useEffect(() => {
//     if (isSuccess) toggleShowDialog();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isSuccess]);
//
//   const handleView = () => {
//     onComplete ? onComplete() : router.push('/admin/devices');
//   };
//
//   if (!showDialog) return null;
//
//   return (
//     <Dialog open>
//       <DialogTitle>Add Phones</DialogTitle>
//       <Divider />
//       <DialogContent>
//         <br />
//         Phones Successfully Added!
//         <br />
//         <br />
//       </DialogContent>
//       <Divider />
//       <DefaultDialogActions
//         saveLabel={'View All Phones'}
//         onSave={handleView}
//         cancelLabel={'Close'}
//         onCancel={toggleShowDialog}
//       />
//     </Dialog>
//   );
// };

export default AddPhonesBulkForm;

import { gql } from 'graphql-request';

export default gql`
  query deviceById($id: String, $mask: String) {
    deviceById(id: $id, mask: $mask) {
      id
      account_id
      doc
      listing
      createdAt
      updatedAt

      Owner {
        id
        doc
        extra
      }

      Callflows {
        id
        _doc
        _listing
        createdAt
        updatedAt
      }

      Groups {
        id
        _doc
        _listing
        createdAt
        updatedAt
      }

      ExternalCallerIdNumber {
        id
        _doc
        _listing
        createdAt
      }

      InternalCallerIdNumber {
        id
        _doc
        _listing
        createdAt
      }

      HoldMusic {
        id
        inherited
      }
    }
  }
`;

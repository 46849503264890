const menuQueryKeys = {
  all: ['menu'] as const,
  list: (
    orderBy: any,
    filters: any,
    queryPageSize: number,
    queryPageIndex: number,
  ) =>
    [
      ...menuQueryKeys.all,
      'list',
      orderBy,
      filters,
      queryPageSize,
      queryPageIndex,
    ] as const,
  byId: (id: string | undefined) => [...menuQueryKeys.all, id] as const,
};

export default menuQueryKeys;

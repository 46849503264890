import { ExtensionInUseWarnings } from 'app/components/ExtensionInUseWarnings';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Autocomplete,
  Divider,
  TextField,
  Typography,
  Chip,
  AnimationDialog,
} from 'app/design';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

// interface declaring which props are required/allowed
interface NotificationEmailsDialogContentProps {
  vmboxId: string;
  onCancel: () => void;
  onComplete: () => void;
}

interface NotificationEmailsDialogProps {
  ContentProps: NotificationEmailsDialogContentProps;
  DialogProps: AnimationDialogProps;
}

const NotificationEmailsDialog = ({
  ContentProps,
  DialogProps,
}: NotificationEmailsDialogProps) => {
  return (
    <AnimationDialog {...DialogProps}>
      <NotificationEmailsDialogContent {...ContentProps} />
    </AnimationDialog>
  );
};

const NotificationEmailsDialogContent = ({
  vmboxId,
  onCancel,
  onComplete,
}: NotificationEmailsDialogContentProps) => {
  const { data: vmbox, isLoading: vmboxIsLoading } = useVmboxQuery({
    id: vmboxId,
  });

  const updateVmbox = useUpdateVmboxPartial();
  const [value, setValue] = useState(vmbox?.doc?.notify_email_addresses ?? []);
  const [emailsValid, setEmailsValid] = useState(true);

  const handleChange = (_e, value) => {
    setValue(value);
    setEmailsValid(true); // hide error display once typing
  };

  const handleSubmit = () => {
    // check if every email is valid
    value.every(email => validateEmail(email.trim()) || email.trim() === '')
      ? onSubmit(value)
      : setEmailsValid(false);
  };

  const onSubmit = async (emails: string[]) => {
    const resp = await updateVmbox.mutateAsync(
      {
        id: vmboxId,
        notify_email_addresses: emails.filter(email => !!email), // remove empty strings
      },
      // {
      //   onSuccess: () => {
      //     onComplete();
      //   },
      // },
    );

    // resolve promise with toast notifications
    // const resp = await toast.promise(updateVmboxPromise, {
    //   pending: 'Updating Voicemail Box notification email list...',
    //   error: 'Failed to update Voicemail Box notification email list.',
    //   success: 'Voicemail Box notification email list updated!',
    // });

    // if (resp.status === 'success') {
    //   onComplete();
    // }
  };

  return (
    <GenericMutationDialogContent
      onComplete={onComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onSuccessLabel={'Voicemail box notification email list updated'}
      isLoadingLabel={'Updating Voicemail Box notification email list...'}
      title={'Edit Mailbox Number'}
      mutation={updateVmbox}
      formMethods={{
        formState: {
          isDirty: !isEqual(value, vmbox?.doc.notify_email_addresses ?? []),
        },
      }}
      forceSuccess={updateVmbox.isSuccess}
      submitButtonLabel={'Edit Notification Email List'}
      submitButtonDisabled={!emailsValid}
    >
      <Autocomplete
        freeSolo
        multiple
        defaultValue={value}
        options={[]}
        onChange={handleChange}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              color={
                emailsValid
                  ? undefined
                  : validateEmail(option)
                  ? 'success'
                  : 'error'
              }
              size={'large'}
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Notification Emails"
            placeholder="Emails"
          />
        )}
      />
      {emailsValid ? (
        <Typography variant={'caption'} color={'textSecondary'}>
          * type each email address and press enter separately
        </Typography>
      ) : (
        <Typography variant={'caption'} color={'error'}>
          One or more emails are invalid. Please make sure each email is
          separated by a new line and correctly entered.
        </Typography>
      )}
    </GenericMutationDialogContent>
  );

  // return (
  //   <Dialog open={true} fullWidth maxWidth={'xs'}>
  //     {vmboxIsLoading ? (
  //       <DialogInProgress
  //         title={'Loading Voicemail Box notification email list...'}
  //       />
  //     ) : updateVmbox.isLoading ? (
  //       <DialogInProgress
  //         title={'Updating Voicemail Box notification email list...'}
  //       />
  //     ) : (
  //       <>
  //         <DialogTitle>Edit Notification Email List</DialogTitle>
  //         <Divider />
  //         {vmbox ? (
  //           <NotificationEmailsForm
  //             initialEmails={defaultEmails}
  //             onCancel={onCancel}
  //             onSubmit={handleSubmit}
  //             errorMessage={
  //               updateVmbox.error
  //                 ? `${updateVmbox.error.message} ${
  //                     updateVmbox.error.response
  //                       ? `: ${updateVmbox.error.response?.statusText}`
  //                       : ''
  //                   }`
  //                 : undefined
  //             }
  //           />
  //         ) : (
  //           <>
  //             <DialogContent>
  //               <Typography color={'error'}>
  //                 Invalid Voicemail Box ID
  //               </Typography>
  //             </DialogContent>
  //             <Divider />
  //             <DialogActions sx={{ justifyContent: 'left' }}>
  //               <Button variant={'outlined'} color={'error'} onClick={onCancel}>
  //                 Cancel
  //               </Button>
  //             </DialogActions>
  //           </>
  //         )}
  //       </>
  //     )}
  //   </Dialog>
  // );
};

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// interface NotificationEmailsFormProps {
//   initialEmails: string[];
//   onCancel: () => void;
//   onSubmit: (formValues: string[]) => void;
//   errorMessage?: string;
//   startDirty?: boolean;
// }
//
// const NotificationEmailsForm = ({
//   initialEmails,
//   onCancel,
//   onSubmit,
//   errorMessage,
// }: NotificationEmailsFormProps) => {
//   return (
//     <>
//       <DialogContent>
//         <>
//           <Autocomplete
//             freeSolo
//             multiple
//             defaultValue={initialEmails}
//             options={[]}
//             onChange={handleChange}
//             renderTags={(value: readonly string[], getTagProps) =>
//               value.map((option: string, index: number) => (
//                 <Chip
//                   variant="outlined"
//                   color={
//                     emailsValid
//                       ? undefined
//                       : validateEmail(option)
//                       ? 'success'
//                       : 'error'
//                   }
//                   size={'large'}
//                   label={option}
//                   {...getTagProps({ index })}
//                 />
//               ))
//             }
//             renderInput={params => (
//               <TextField
//                 {...params}
//                 variant="outlined"
//                 label="Notification Emails"
//                 placeholder="Emails"
//               />
//             )}
//           />
//           {emailsValid ? (
//             <Typography variant={'caption'} color={'textSecondary'}>
//               * Separate each email by a new line
//             </Typography>
//           ) : (
//             <Typography variant={'caption'} color={'error'}>
//               One or more emails are invalid. Please make sure each email is
//               separated by a new line and correctly entered.
//             </Typography>
//           )}
//         </>
//       </DialogContent>
//       <Divider />
//       <DialogActions sx={{ justifyContent: 'space-between' }}>
//         <Button variant={'outlined'} color={'error'} onClick={onCancel}>
//           Cancel
//         </Button>
//         {errorMessage ? (
//           <Typography color={'error'}>{errorMessage}</Typography>
//         ) : null}
//         <Button
//           variant={'outlined'}
//           color={'success'}
//           disabled={
//             emailsValid &&
//             isEqual(
//               value.filter(email => email),
//               initialEmails,
//             )
//           }
//           onClick={handleSubmit}
//         >
//           Update
//         </Button>
//       </DialogActions>
//     </>
//   );
// };

export default NotificationEmailsDialog;

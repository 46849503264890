import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { User, UserPrivLevel } from 'types/user';
import { DialogBuilder } from 'app/utilities';
import { user } from 'app/sdk/resources/user';
import { Chip } from 'app/design';
import { useMediaQuery } from '@mui/material';
import { Badge } from 'app/design-lib';

const QuickFinderUserDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const isMobile = useMediaQuery('(max-width:360px)', { noSsr: true });

  const columns = useMemo(
    () =>
      [
        {
          Header: '',
          id: 'status',
          accessor: (user: User) => user,
          Cell: ({ value: user }) => {
            if (!user.doc.enabled) {
              return (
                <Badge variant={'fill'} reverse color={'negative'} size={'sm'}>
                  Suspended
                </Badge>
              );
            }

            if (user.doc.priv_level === UserPrivLevel.Admin) {
              return (
                <Badge variant={'fill'} reverse color={'accent'} size={'sm'}>
                  Admin
                </Badge>
              );
            }

            return null;
          },
        },
        {
          Header: 'Name',
          accessor: (user: User) => user.extra.fullName,
        },
        // {
        //   Header: 'Last Name',
        //   accessor: (user: User) => user.doc.last_name,
        // },
      ].concat(
        isMobile
          ? []
          : [
              {
                Header: 'Ext(s)',
                accessor: (user: User) => user.doc.presence_id,
                // Cell: ({ row }) => {
                //   const { original: user }: { original: User } = row;
                //
                //   const mainCallflowNumbers = user.Callflows?.find(
                //     cf => cf.doc.type === 'main',
                //   )?.doc.numbers;
                //
                //   const { extensions } = mainCallflowNumbers
                //     ? parseCallflowNumbers(mainCallflowNumbers)
                //     : { extensions: [] };
                //
                //   return <ExtensionsTableCell extensions={extensions} />;
                // },
                // @ts-ignore
                disableSortBy: true,
              },
            ],
      ),
    [isMobile],
  );

  // user list
  const userListQuery = useListUsersQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['lastName', 'asc']],
    filters: {
      searchInput: querySearchInput,
    },
  });
  const { users, totalCount = 0 } = userListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      userListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Users' : 'Select User'}
      // table
      onSearch={handleSearch}
      getRowId={(row: User) => row.id}
      searchPlaceholder={'Search Users...'}
      totalCount={totalCount}
      columns={columns}
      data={users ?? []}
      // query
      queryIsFetching={userListQuery.isFetching}
      queryIsLoading={userListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export const useQuickFinderUserDialog = DialogBuilder(QuickFinderUserDialog);

export default QuickFinderUserDialog;

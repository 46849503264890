import axios from 'axios';
import constants from 'app/constants';

const buildUrl = url => {
  return `${constants.env.REACT_APP_PROVISIONER_URL}${url}`;
};

const buildHeaders = config => {
  return {
    'Content-Type': 'application/json',
    'X-AUTH-TOKEN': config.authToken, // Uncomment or modify to test 401
    'X-Kazoo-Cluster-ID': constants.env.REACT_APP_KCLUSTER_ID ?? '',
  };
};

// TODO: make more generic if any other resources have similar url scheme
export const getProvisionerPhoneModel = async (
  { brand, family, model },
  config,
) => {
  return axios.get(buildUrl(`/ui/${brand}/${family}/${model}`), {
    headers: buildHeaders(config),
  });
};

// TODO: make more generic if any other resources have similar url scheme
export const getProvisionerBrandsAndModels = async (vars, config) => {
  return axios.get(buildUrl(`/phones`), {
    headers: buildHeaders(config),
  });
};

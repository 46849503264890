import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { IconButton } from 'app/design-lib/components';
import Badge, { BadgeProps } from 'app/design-lib/components/Badge/Badge';
import { IconButtonProps } from 'app/design-lib/components/IconButton/IconButton';
import { Menu } from 'iconoir-react';
import React, { ReactElement, ReactNode } from 'react';

interface DropdownMenuProps {
  options: MenuItem[];
  children: ReactNode;
  title?: string;
  align?: 'start' | 'center' | 'end' | undefined;
  side?: 'bottom' | 'top' | 'right' | 'left' | undefined;
  className?: string;
  disabled?: boolean;
  buttonProps?: Partial<IconButtonProps>;
}

interface MenuItem {
  onClick?: (e: any) => void;
  label: string;
  className?: string;

  href?: string;
  target?: string;
  icon?: ReactElement;
  disabled?: boolean;
  badgeProps?: Partial<BadgeProps>;
}

export const DropdownMenu = ({
  options,
  children,
  title,
  side = 'bottom',
  align = 'end',
  disabled,
  className = '',
  buttonProps,
}: DropdownMenuProps) => {
  return (
    <RadixDropdownMenu.Root modal={false}>
      <RadixDropdownMenu.Trigger asChild>
        <IconButton {...buttonProps} className={`${className}`} title={title}>
          {children as ReactElement}
        </IconButton>
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content
          className={`rounded p-1 border divide-y divide-neutral-30 border-neutral-20 shadow bg-white`}
          align={align}
          side={side}
          sideOffset={8}
        >
          {/* {menuTitle ? (
            <div className={`px-1`}>
              <span className={`text-sm text-neutral-60`}>{menuTitle}</span>
            </div>
          ) : null}*/}
          {options.map(
            (
              {
                label,
                badgeProps,
                className = '',
                disabled,
                onClick,
                icon,
                href,
                target,
              },
              index,
            ) => {
              if (href) {
                return (
                  <RadixDropdownMenu.Item
                    className={` ${className} ${
                      disabled ? 'pointer-events-none text-neutral-60' : ''
                    } px-2 py-2 text-md flex space-x-2 items-center cursor-pointer font-medium focus:outline-0 data-[highlighted]:bg-neutral-10`}
                    asChild
                    key={index}
                    disabled={disabled}
                  >
                    {/*{icon ? (
                  <div>
                    {React.cloneElement(icon, {
                      className: `h-4 w-4 text-secondary ${
                        icon.props?.className ?? ''
                      }`,
                    })}
                  </div>
                ) : null}*/}
                    <a href={href} target={target} className={``}>
                      {label}
                    </a>
                  </RadixDropdownMenu.Item>
                );
              }

              return (
                <RadixDropdownMenu.Item
                  className={` ${className} ${
                    disabled ? 'pointer-events-none text-neutral-60' : ''
                  } px-2 py-2 text-md flex space-x-2 items-center cursor-pointer font-medium focus:outline-0 data-[highlighted]:bg-neutral-10`}
                  onClick={onClick}
                  key={index}
                  disabled={disabled}
                >
                  {/*{icon ? (
                  <div>
                    {React.cloneElement(icon, {
                      className: `h-4 w-4 text-secondary ${
                        icon.props?.className ?? ''
                      }`,
                    })}
                  </div>
                ) : null}*/}
                  <span className={``}>{label}</span>
                  {badgeProps ? (
                    <Badge
                      size={'sm'}
                      variant={'fill'}
                      reverse
                      color={'positive'}
                      {...badgeProps}
                    >
                      {badgeProps.children}
                    </Badge>
                  ) : null}
                </RadixDropdownMenu.Item>
              );
            },
          )}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

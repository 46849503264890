import { gql } from 'graphql-request';

export default gql`
  query Query($sharedFilters: JSON, $fullFilters: JSON) {
    vmboxes {
      totalCount
    }
    shared: vmboxes(filters: $sharedFilters) {
      totalCount
    }
    full: vmboxes(filters: $fullFilters) {
      totalCount
    }
  }
`;

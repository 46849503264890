import { useAccountStripeCustomerQuery } from 'app/hooks/queries/account';
import accountQueryKeys from 'app/hooks/queries/account/accountQueryKey';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { createStateContext } from 'react-use';

// these defaults are overridden by the fetched settings for an account/user
// - key/value, NOT a JS object
// - (ie can have "x = true" AND "x.y = {test:'1'}" AND "x.y.test = 'not 1'")
const FEATURES = {
  buy_numbers: {
    value: false,
  },
  has_payment_methods: {
    value: false,
  },
};

export const [useFeatureFlags, FeatureFlagProviderRaw] =
  createStateContext(FEATURES);

export const useFeature = name => {
  const queryClient = useQueryClient();
  const [flags] = useFeatureFlags();
  const flag = flags[name]; // "." delimiter (NOT a "/" slash) // TODO: memoize

  const refetch = async () => {
    await queryClient.refetchQueries(accountQueryKeys.account);
    await queryClient.refetchQueries(accountQueryKeys.stripe_customer);
  };

  console.log('featureflag:', name, flag, flags);
  return [flag ? flag.value : undefined, refetch];
};

export const Feature = ({
  name,
  render = undefined,
  NotTrueRender,
  children,
}) => {
  // const {
  //   data: stripeData,
  //   isLoading,
  //   isFetching,
  //   refetch,
  // } = useAccountStripeCustomerQuery(); // probably fetching too often here...

  const [result, updateFeatures] = useFeature(name);
  if (render) {
    return render({ val: result });
  }
  if (NotTrueRender && result !== true) {
    if (React.isValidElement(NotTrueRender)) {
      return NotTrueRender;
    } else {
      return NotTrueRender({
        updateFeatures,
      });
    }
  }
  if (result) {
    return children;
  }
  return null;
};

const FeatureFlagBuilder = ({ children }) => {
  const [, setFlags] = useFeatureFlags();

  // fetch account for available features
  // - integrations enable features?
  // const { data: account, isLoading: accountIsLoading } = useAccountQuery();
  const { data: stripeData } = useAccountStripeCustomerQuery();

  useEffect(() => {
    if (!stripeData?.data) {
      return;
    }
    setFlags(flags => {
      // console.log('stripeData:', stripeData);
      // buy_numbers
      if (
        stripeData.data.account?.internal?.integrations?.trunkingio?.account_id
      ) {
        // // if (account?.doc) {
        // setAtPath(flags, 'buy_numbers', true);
        updateFlag('buy_numbers', true, flags);
      }
      // has_payment_methods
      if (stripeData.data.cards.data.filter(c => c.object === 'card').length) {
        // // if (account?.doc) {
        // setAtPath(flags, 'buy_numbers', true);
        updateFlag('has_payment_methods', true, flags);
      }
      return { ...flags };
    });
  }, [stripeData, setFlags]);

  return children;
};

const updateFlag = (key, value, obj) => {
  const flag = obj[key];
  if (flag) {
    flag.value = value;
  } else {
    // MUST exist?
    obj[key] = {
      value: value,
    };
  }
};

export const FeatureFlagProvider = ({ children }) => (
  <FeatureFlagProviderRaw>
    <FeatureFlagBuilder>{children}</FeatureFlagBuilder>
  </FeatureFlagProviderRaw>
);

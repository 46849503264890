import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { useUpdateMenuPartial } from 'app/hooks/mutations/menu';
import { useMenuQuery } from 'app/hooks/queries/menu';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { useFormContext } from 'react-hook-form';

// interface declaring which props are required/allowed
// interface MenuNameProps {
//   // directoryId: string;
//   callflow?: any;
//   open?: boolean;
//   onCancel: () => void;
//   // onComplete: () => void;
// }

const MenuName = ({ item, modifyPath, onChangeSimple }) => {
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showFinderDialog, toggleShowFindVmboxDialog] = useToggleReducer(false);

  const key = varItem.key;
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);

  // simple variable values
  const currentVarOutputValue = varItem.output_value;
  const defaultValueInFlow = varItem.output_value_default;
  const simpleValueInCallflow = variables[key];

  // get CURRENT value FROM MODULE
  // - for when saved by the Advanced Editor!
  //   - that doesnt update the varItem, it only updates the actual flow!

  // get current menu
  // - load greeting from current menu
  // - if no current menu, then we'll build one
  //    - when?
  const currentMenuId = moduleItem?.data?.id;
  // `callflow.strategy.simple.variables.${key}`;

  const updateMenu = useUpdateMenuPartial();

  const {
    data: menuCurrent,
    isLoading: menuCurrentIsLoading,
    isFetched: menuCurrentIsFetched,
  } = useMenuQuery({ id: currentMenuId });

  // const {
  //   data: vmboxSimple,
  //   isLoading: vmboxSimpleIsLoading,
  //   isFetched: vmboxSimpleIsFetched,
  // } = useMenuQuery(simpleValueInCallflow);

  // const onChangeVmboxSelect = selected => {
  //   const [vmboxId] = selected;
  //   setValue(`callflow.strategy.simple.variables.${key}`, vmboxId);
  //   // setAtPath(tmpModuleItem, `data.id`, vmboxId); // newValue?.value === id
  //   // setModifyModuleItem({ ...tmpModuleItem });
  //   toggleShowFindVmboxDialog();
  // };

  const handleClear = () => {
    // setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    // toggleShowFindVmboxDialog();
  };
  const greeting_id = menuCurrent?.doc?.media?.greeting;

  // // const initial = useRef(greeting_id)
  // useEffect(() => {
  //   if (!menuCurrent) {
  //     return;
  //   }
  //   handleChangeName();
  // }, [greeting_id]);

  const handleChangeName = newMediaId => {
    // TODO!
    // - should NOT immediately update name of menu...
    //
    //
    // // Update the existing Menu's greeting
    // // - TODO: handle failure better?
    // // const newMenu = cloneDeep(menuCurrent.doc)
    // // console.log('newMediaId:', newMediaId);
    // const newMedia = cloneDeep(menuCurrent.doc.media);
    // newMedia.greeting = newMediaId;
    // const updateMenuPromise = updateMenu.mutateAsync(
    //   {
    //     id: menuCurrent.id,
    //     media: newMedia,
    //   },
    //   {
    //     onSuccess: () => {
    //       // onComplete();
    //       // alert('saved OK');
    //     },
    //   },
    // );
    // // save callflow (required, otherwise the Menu Greeting is out-of-sync)
    // // - TODO: fix this differently...
    // //   - instead of updating the Menu above immediately, we should update only when the whole callflow is saved?
    // //   -
    // document
    //   .getElementById('routing-form')
    //   .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  // console.log('menuCurrent:', menuCurrent, moduleItem);

  // console.log('varitem MenuName:', varItem, {
  //   defaultValueInFlow,
  //   simpleValueInCallflow,
  // });

  //   setValue(`${modifyPath}callflow${moduleModifyPath}.data.data.id`, userId, {
  //     shouldDirty: true,
  //   });
  // onChangeSimple && onChangeSimple();

  return (
    <div>
      <HookFormTextField
        name={`${modifyPath}callflow.strategy.simple.variables.${key}.name`}
        // name={`${modifyPath}callflow${moduleModifyPath}.data.menu_id`}
        label={'Name'}
        // editLabel={mediaId ? 'Change Greeting' : 'Add Greeting'}
        onChange={handleChangeName}
      />
    </div>
  );
};

export default MenuName;

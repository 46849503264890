import * as React from 'react';
import { Box, CircularProgress, Typography } from 'app/design';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthSlice, selectAuth, useAuthSelector } from 'app/data/auth';
import { useEffectOnce } from 'react-use';
import { SquareProgress } from 'app/design-lib';

function AppLoadingScreen() {
  return (
    // <Box
    //   sx={{
    //     display: 'flex',
    //     width: '100vw',
    //     height: '100vh',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   }}
    // >
    //   <Box sx={{ textAlign: 'center' }}>
    //     <CircularProgress size={30} />
    //     <Typography variant="body2">CallingIO App Initializing...</Typography>
    //   </Box>
    // </Box>
    <div className="h-screen grid place-items-center">
      {/* <LoginForm credentials={defaultValues} /> */}
      <div className={`flex flex-col items-center justify-center`}>
        <SquareProgress />
        <span className={`text-md font-medium`}>
          CallingIO app initializing...
        </span>
      </div>
    </div>
  );
}

export default AppLoadingScreen;

import React, { Fragment, useCallback, useState, useEffect } from 'react';
import * as SmartySDK from 'smartystreets-javascript-sdk';

import { Grid, TextField, Autocomplete, Typography, Tooltip } from 'app/design';
import {
  LocationOn as LocationOnIcon,
  PersonOutline as PersonOutlineIcon,
  VideocamOutlined as VideocamOutlinedIcon,
  Language as LanguageIcon,
  AirlineStops as AirlineStopsIcon,
  Tag as TagIcon,
  // Check as CheckIcon,
} from 'app/design/icons-material';
import {
  InfoEmpty as InfoIcon,
  RefreshCircular as RefreshCircularIcon,
} from 'iconoir-react';
// import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Combobox, Transition } from '@headlessui/react';

import _ from 'lodash';
import { useThrottle, useThrottleFn } from 'react-use';

export default class AddressAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    const SmartyCore = SmartySDK.core;
    const websiteKey = props.websiteKey;
    const smartySharedCredentials = new SmartyCore.SharedCredentials(
      websiteKey,
    );
    const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(
      smartySharedCredentials,
    ).withLicenses(['us-autocomplete-pro-cloud']);
    const usStreetClientBuilder = new SmartyCore.ClientBuilder(
      smartySharedCredentials,
    );

    // @ts-ignore
    this.SmartyCore = SmartyCore;
    // @ts-ignore
    this.autoCompleteClient =
      autoCompleteClientBuilder.buildUsAutocompleteProClient();
    // @ts-ignore
    this.usStreetClient = usStreetClientBuilder.buildUsStreetApiClient();
  }

  render() {
    return (
      <AutocompleteBox
        // @ts-ignore
        SmartyCore={this.SmartyCore}
        // @ts-ignore
        autoCompleteClient={this.autoCompleteClient}
        // @ts-ignore
        usStreetClient={this.usStreetClient}
        {...this.props}
      />
    );
  }
}

export const AutocompleteBox = props => {
  const {
    SmartyCore,
    autoCompleteClient,
    usStreetClient,
    autocompleteProps = {},
    textfieldProps = {},
    onChange = () => {},
    startIcon,
    hasError,
    label,
    infoText,
    disabled,
  } = props;

  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, isLoadingSet] = useState(false);

  const getAndSetSuggestions = (query, entries) => {
    const lookup = new SmartySDK.usAutocompletePro.Lookup(query);
    if (entries) {
      lookup.selected = query;
    }
    // console.log('lookup:', lookup);
    isLoadingSet(true);
    autoCompleteClient
      .send(lookup)
      .then(results => {
        // this.setState({ suggestions: results });
        // console.log('RESULTS:', results);
        const opts = results.result.map(result => ({
          label: formatAutocompleteSuggestionDisplay(result),
          searchval: formatAutocompleteSuggestion(result),
          value: result,
        }));
        setOptions(opts);
        isLoadingSet(false);
      })
      .catch(console.warn);
  };

  const throttledValue = useThrottle(inputValue, 350);
  useEffect(() => {
    if (!throttledValue.length) {
      return;
    }
    // @ts-ignore
    getAndSetSuggestions(throttledValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttledValue]);

  return (
    <div>
      <Combobox
        value={value}
        onChange={v => {
          setValue(v);
          onChange && onChange(v);
        }}
      >
        <div className="relative">
          <div
            className={
              'relative flex space-y-1 relative font-sans flex-col w-full ' +
              (props.disabled ? 'opacity-50' : '')
            }
          >
            {startIcon
              ? React.cloneElement(startIcon, {
                  className: ` absolute h-4 w-4 top-[2.55rem] left-2 stroke-gray-60 text-gray-60`,
                })
              : null}
            {isLoading ? (
              <RefreshCircularIcon
                fr={undefined}
                className="animate-spin absolute h-4 w-4 top-[2.3rem] right-4 stroke-gray-60 text-gray-60"
              />
            ) : null}

            {label ? (
              <div className={`flex space-x-1 items-center`}>
                <label
                  className={'ml-1 text-md font-medium text-content'}
                  // htmlFor={name}
                >
                  {label}
                </label>
                {infoText ? (
                  <InfoIcon
                    fr={undefined}
                    width={12}
                    height={12}
                    className={`stroke-gray-60 text-gray-60`}
                  />
                ) : null}
              </div>
            ) : null}

            {/*<div className="relative w-full cursor-default overflow-hidden rounded-lg border border-border-neutral bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">*/}
            <Combobox.Input
              className={`${props.className} ${
                startIcon ? 'pl-8' : ''
              } form-input focus:ring-content-accent focus:border-content-accent text-content-neutral ${
                hasError
                  ? `border-border-negative-secondary`
                  : `border-border-neutral`
              } rounded-lg border`}
              // className="w-full border-none py-2 pl-3 pr-10 text-base leading-5 text-gray-900 focus:ring-0"
              // @ts-ignore
              displayValue={address => address?.label ?? ''}
              onChange={event => setInputValue(event.target.value)}
            />
            {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button> */}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setInputValue('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.length === 0 && inputValue !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                options.map(address => (
                  <Combobox.Option
                    key={address.id}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-accent-60 text-white' : 'text-gray-900'
                      }`
                    }
                    value={address}
                    onClick={e => {
                      console.log('Option:', address);
                      if (address?.value?.entries > 1) {
                        // disable close on select
                        getAndSetSuggestions(address.searchval, true);
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      } else {
                        // @ts-ignore
                        // props.onClick(...args);
                        setValue(address);
                      }
                    }}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {address.label}
                        </span>
                        {/* {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null} */}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {/*       
      <Autocomplete
        disabled={disabled}
        autoComplete
        // open={null}
        // includeInputInList
        filterSelectedOptions
        // disableCloseOnSelect
        openOnFocus={false}
        selectOnFocus={false}
        filterOptions={x => x}
        options={options}
        getOptionLabel={(option: any) =>
          typeof option === 'string' ? option : option.label
        }
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'reset') {
            console.log(
              'skipping inputchange trigger on reset cuz selecting more (i think)',
            );
            return;
          }
          setInputValue(newInputValue);
        }}
        value={value}
        onChange={(event, newValue) => {
          console.log('newValue:', newValue);
          // if (newValue?.value?.entries > 1) {
          //   // disable close on select
          //   getAndSetSuggestions(newValue.searchval, true);
          //   return false;
          // }
          // setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onChange && onChange(newValue);
          // close on select
        }}
        renderInput={params => (
          <TextField
            {...params}
            // inputRef={inputRef}
            variant="outlined"
            // // label={props.hasOwnProperty('label') ? props.label : 'Conference'}
            // placeholder={
            //   props.hasOwnProperty('placeholder')
            //     ? props.placeholder
            //     : 'Type conference name'
            // }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              // div container
              className: '!py-0',
            }}
            // inputProps={{
            //   // input element
            //   autocomplete: 'off',
            //   className: '!py-1 !border-0',
            // }}
            // onFocus={setFocusWrap(true)}
            // onBlur={setFocusWrap(false)}
            {...textfieldProps}
          />
        )}
        renderOption={(props, option: any, x) => {
          return (
            <li
              {...props}
              key={option.label}
              onClick={(...args) => {
                if (option?.value?.entries > 1) {
                  // disable close on select
                  getAndSetSuggestions(option.searchval, true);
                  return false;
                } else {
                  // @ts-ignore
                  props.onClick(...args);
                }
              }}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  {option.label}
                </Grid>
              </Grid>
            </li>
          );
        }}
        // freeSolo
        {...autocompleteProps}
      /> */}
    </div>
  );
};

const formatAutocompleteSuggestion = suggestion => {
  const street = suggestion.streetLine ? `${suggestion.streetLine} ` : '';
  const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : '';
  const entries = suggestion?.entries > 1 ? `(${suggestion.entries}) ` : '';
  const city = suggestion?.city ? `${suggestion.city} ` : '';
  const state = suggestion?.state ? `${suggestion.state}, ` : '';
  const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : '';

  return street + secondary + entries + city + state + zip;
};

const formatAutocompleteSuggestionDisplay = suggestion => {
  const street = suggestion.streetLine ? `${suggestion.streetLine} ` : '';
  const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : '';
  const entries =
    suggestion?.entries > 1 ? `(${suggestion.entries} entries) ` : '';
  const city = suggestion?.city ? `${suggestion.city} ` : '';
  const state = suggestion?.state ? `${suggestion.state}, ` : '';
  const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : '';

  return street + secondary + entries + city + state + zip;
};
// export default AutocompleteBox;

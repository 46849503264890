export enum TimeRange {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'this_week',
  LastWeek = 'last_week',
  ThisDayLastWeek = 'this_day_last_week',
  ThisMonth = 'this_month',
  LastMonth = 'last_month',
  TwoMonthsAgo = 'two_months_ago',
  Last7Days = 'last_7_days',
  Last30Days = 'last_30_days',
  Last90Days = 'last_90_days',
  Last3Months = 'last_3_months',
  Last6Months = 'last_6_months',
  ThisYear = 'this_year',
  LastYear = 'last_year',
  Last3Years = 'last_3_years',
  Last365Days = 'last_365_days',
  AllTime = 'all_time',
}

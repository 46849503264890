import constants from 'app/constants';

import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { Autocomplete, Paper, TextField } from 'app/design';

import EventEmitter from 'eventemitter3';

import { isFunction } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { animateScroll } from 'react-scroll';
import { isArray } from 'util';
import { v4 as uuidv4 } from 'uuid';

import { HighlightPulse, useHighlightPulse } from './HighlightPulse';

import { OverlayMask } from './OverlayMask';
import useMeasureDirty from './useMeasureDirty';

const eventEmitter = new EventEmitter();

export { useLocalSelector, useLocalSlice };

const forceShow = false;

export const FindInPage = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  const {
    search_in_page_focus: focus,
    search_in_page_options: options,
    search_in_page_option_highlighted: option_highlighted,
    poke_holes,
  } = useLocalSelector();
  const { actions: localActions } = useLocalSlice();

  const [ref, holes] = useGetHolesForRef({
    padding: 0,
    blur: false,
    alwaysHighlight: true,
  });
  usePokeHoles({ holes, name: 'autocomplete' });

  // console.log('registered options:', options);
  // console.log('filtered options:', filteredOptions);

  // // get components on page registered for searching
  // const options = useRegisteredPageOptions();

  // filter components for displaying in search
  useEffect(() => {
    // filter options
    const filteredOptions = matchSorter(options, inputValue, {
      keys: ['label'],
    });
    setFilteredOptions([...filteredOptions]);

    // add "filteredOptions" to the global "is-in-list" for displaying
    dispatch(
      localActions.set_search_in_page_options_currently_shown_in_list([
        ...filteredOptions,
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, inputValue]);

  const handleFocus = () => {
    // setFocus(true);
    dispatch(localActions.set_search_in_page_focus(true));
  };
  const handleBlur = () => {
    // setFocus(false);
    // console.log('BLUR');
    dispatch(localActions.set_search_in_page_focus(false));
  };

  const handleInputChange = (ev, value, reason) => {
    setInputValue(value);
  };

  const handleSelect = (ev, value) => {
    // trigger event for selected action
    eventEmitter.emit('selected', value);

    // change input back to prevous input text, NOT the selected value
    // - just a better ux to see what you previously typed, instead of the filled-out thing
    setInputValue(inputValue);
  };

  const handleHighlightInList = (ev, value) => {
    // debugger;
    // console.log('Highlight:', value); // value?.value
    if (value) {
      // debugger;
    }
    // add "filteredOptions" to the global "is-in-list" for displaying
    dispatch(localActions.set_search_in_page_option_highlighted(value));
  };

  // console.log('Holes to ACTUALLY poke:', poke_holes);
  // console.log('Options:', filteredOptions);

  let [ref2, holes2] = useGetHolesForRef({
    // @ts-ignore
    padding: [0, 0, 0, 0],
    blur: false,
    alwaysHighlight: true,
    highlightHoverLevel: constants.findInPage.searchHoverLevel,
    // exclusiveHover: true,
  });
  const isHovering = useHoverOverBoundingBox(ref2);
  if (isHovering) {
    // @ts-ignore
    holes2 = holes2.map(hole => ({ ...hole, hoverHighlight: true }));
  }
  usePokeHoles({ holes: holes2, name: 'autocomplete options' });

  return (
    <>
      <Autocomplete
        // disablePortal
        // @ts-ignore
        PaperComponent={PaperCustom}
        // ListboxComponent={ListBoxCustom}
        // ListboxProps={{ ref: ref2 }}
        fullWidth
        // @ts-ignore
        ref={ref}
        open={focus ? true : false}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={null} // never allow an actual value to be set, just text
        blurOnSelect
        clearOnBlur={false}
        disableClearable={false}
        // openOnFocus
        options={filteredOptions}
        onChange={handleSelect}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        // filterOptions={} // unnecessary?
        onHighlightChange={handleHighlightInList}
        renderInput={params => (
          <TextField {...params} placeholder="Find In Page" size="small" />
        )}
        // getOptionLabel={opt => {
        //   opt.label;
        // }}
        // renderOption={(props, option, { inputValue, selected }) => {
        //   return (
        //     <AutocompleteOption
        //       key={option.id}
        //       props={props}
        //       option={option}
        //       selected={selected}
        //     />
        //   );
        // }}
      />
      {/* && inputValue?.length */}
      {forceShow || (focus && (inputValue?.length || option_highlighted)) ? (
        <OverlayMask
          holesToPoke={poke_holes}
          // onClick={...}
        />
      ) : null}
      {/* <OverlayMask
        holesToPoke={poke_holes}
        // onClick={...}
      /> */}
    </>
  );
};

const PaperCustom = ({ children, ...rest }) => {
  let [ref, holes] = useGetHolesForRef({
    // @ts-ignore
    padding: [0, 0, 0, 0],
    blur: false,
    alwaysHighlight: true,
    highlightHoverLevel: constants.findInPage.searchHoverLevel,
    // exclusiveHover: true,
  });
  const isHovering = useHoverOverBoundingBox(ref);
  if (isHovering) {
    // @ts-ignore
    holes = holes.map(hole => ({ ...hole, hoverHighlight: true }));
  }
  usePokeHoles({ holes, name: 'autocomplete paper' });

  return (
    // @ts-ignore
    <Paper ref={ref} {...rest}>
      {children}
    </Paper>
  );
};

// const AutocompleteOption = ({ props, option, selected }) => {
//   // const [ref, holes] = useGetHolesForRef({
//   //   padding: [0, 0, 8, 0],
//   //   blur: false,
//   //   highlight: true,
//   // });
//   // usePokeHoles({ holes, name: 'autocomplete option - ' + option.label });
//   if (selected) {
//     debugger;
//   }
//   return <li {...props}>{option.label}</li>;
// };

export const useRegisterOptions = ({ options: optionsArr, onChoose }) => {
  const dispatch = useDispatch();
  const { actions: localActions } = useLocalSlice();
  useEffect(() => {
    const uuid = uuidv4();
    dispatch(
      localActions.add_search_in_page_options(
        optionsArr.map(opt => ({ ...opt, id: uuid })),
      ),
    );
    if (onChoose) eventEmitter.on('selected', onChoose);
    return () => {
      dispatch(localActions.remove_search_in_page_options(uuid));
      if (onChoose) eventEmitter.off('selected', onChoose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(optionsArr), onChoose]);
  return null;
};

export const useGetHolesForRef = ({
  padding = 0,
  blur = true,
  showPreview = false,
  // highlight = undefined,
  selectHighlight = false,
  hoverHighlight = false,
  alwaysHighlight = false,
  thrashWithRaf = false,
  highlightHoverLevel = 0, // for being an exclusive hover (highest number gets shown)
  // exclusiveHover = false, // false = not exclusivea high number is better
}) => {
  // [top, right, bottom, left]
  if (!isArray(padding)) {
    padding = padding > 0 ? padding : 0;
    // @ts-ignore
    padding = [padding, padding, padding, padding];
  }
  // padding = [0, 0, 0, 0];
  // @ts-ignore
  const [padTop = 0, padRight = 0, padBottom = 0, padLeft = 0] = padding;

  const ref = useRef(null);
  // const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  const { x, y, width, height, top, right, bottom, left } =
    useMeasureDirty(ref);
  // const ref = useRef(null);
  // const { x, y, width, height, top, right, bottom, left } =
  //   useMeasureDirty(ref);
  let hole = {
    x: x - padLeft,
    y: y - padTop,
    w: width + padLeft + padRight,
    h: height + padTop + padBottom,
    blur,
    showPreview,
    // highlight,
    selectHighlight,
    hoverHighlight,
    alwaysHighlight,
    highlightHoverLevel,
    // exclusiveHover,
  };
  // console.log('HOLE:', hole);

  const holes = [hole];
  return [ref, holes];
};

export const usePokeHoles = ({ holes, name }) => {
  const dispatch = useDispatch();
  const { actions: localActions } = useLocalSlice();
  useEffect(() => {
    const uuid = uuidv4();
    dispatch(
      localActions.add_poke_holes(
        holes.map(hole => ({ ...hole, id: uuid, name })),
      ),
    );
    return () => {
      dispatch(localActions.remove_poke_holes(uuid));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(holes)]);
  return null;
};

export const useFindInPage = ({
  options: tmpOptions,
  optionsDeps = [],
  onChoose: tmpOnChoose,
  name,
  showPreview = false,
  highlightHoverLevel = 0,
  holeOpts = { padding: 0 },
}) => {
  const options = useInputSetter(tmpOptions, optionsDeps);

  const { doPulse, startAnimation, duration } = useHighlightPulse({});

  const fnMatchAnyScrollToRef = useCallback(opt => {
    if (!options.find(o => o.value === opt.value)) {
      return;
    }
    // @ts-ignore
    animateScroll.scrollTo(ref.current.getBoundingClientRect().top - 120, {
      duration: 750,
    });
    setTimeout(startAnimation, 850);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let onChoose;
  switch (tmpOnChoose) {
    case 'matchAnyScrollToRef':
      onChoose = fnMatchAnyScrollToRef;
      break;
    default:
      onChoose = tmpOnChoose;
      break;
  }

  // register options for searching ({label, value: string})
  // - handle when one is chosen (passed in a function)
  useRegisterOptions({ options, onChoose });

  // get options that DID match existing search
  const {
    search_in_page_options_currently_shown_in_list: currently_shown_in_list,
    search_in_page_option_highlighted: option_highlighted,
  } = useLocalSelector();

  // highlight if selected currently
  // - TODO: or if hovered over? do that via svg "hover" on rect?
  let isMineHighlighted =
    option_highlighted &&
    options.find(opt => opt.value === option_highlighted?.value)
      ? true
      : false;

  let [ref, holes] = useGetHolesForRef({
    ...holeOpts,
    // highlight: isMineHighlighted,
    showPreview,
    selectHighlight: isMineHighlighted,
    highlightHoverLevel,
  });

  const isHovering = useHoverOverBoundingBox(ref);
  if (isHovering) {
    // @ts-ignore
    holes = holes.map(hole => ({ ...hole, hoverHighlight: true }));
  }

  // hide poking holes if
  // - something highlighted that is NOT mine
  // - not in list
  if (
    // // highlighted and not mine
    // (option_highlighted && !isMineHighlighted) ||
    // not in list currently
    !options.find(opt =>
      currently_shown_in_list.find(opt2 => opt.value === opt2.value),
    )
  ) {
    holes = [];
  }

  usePokeHoles({ holes, name });

  return {
    ref,
    currently_shown_in_list,
    option_highlighted,
    HighlightPulse,
    highlightPulseProps: {
      in: doPulse,
      duration,
    },
  };
};

const useMousePosition = watch => {
  const [{ docX, docY }, setState] = useState({ docX: 0, docY: 0 });
  useEffect(() => {
    if (!watch) {
      return;
    }
    const moveHandler = event => {
      setState({
        docX: event.pageX,
        docY: event.pageY,
      });
    };
    window.addEventListener('mousemove', moveHandler);
    return () => {
      window.removeEventListener('mousemove', moveHandler);
    };
  }, [watch]);

  return {
    docX,
    docY,
  };
};

const useHoverOverBoundingBox = ref => {
  const [isHovering, setIsHovering] = useState(false);

  const { search_in_page_focus: focus } = useLocalSelector();

  const { x, y, width, height } = useMeasureDirty(ref);
  const { docX, docY } = useMousePosition(focus);
  const pointInBoundingBox = ({ x, y }, { x: boxX, y: boxY, w, h }) => {
    if (x >= boxX && x <= boxX + w && y >= boxY && y <= boxY + h) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      pointInBoundingBox({ x: docX, y: docY }, { x, y, w: width, h: height })
    ) {
      setIsHovering(true);
    } else {
      setIsHovering(false);
    }
  }, [docX, docY, x, y, width, height]);

  return isHovering;
};

const useInputSetter = (input, deps = []) => {
  let fn = input;
  if (!isFunction(input)) {
    fn = () => input;
  }
  const [arr, setArr] = useState(() => fn(deps));
  useEffect(() => {
    setArr(fn(deps));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return arr;
};

export default FindInPage;

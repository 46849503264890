import EditMenuDialog from 'app/components/EditMenuDialog/EditMenuDialog';
import { Button } from 'app/design';
import { CreateMenuDialog } from 'app/components/EditMenuDialog';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';

interface CreateGroupButtonProps {
  onComplete?: (menuId?: any) => void;
}

const AddMenuButton = ({ onComplete }: CreateGroupButtonProps) => {
  const [open, toggleOpen] = useToggleReducer();
  const handleComplete = (mediaId?: string | null) => {
    toggleOpen();
    onComplete && onComplete(mediaId);
  };
  return (
    <>
      <EditMenuDialog
        DialogProps={{
          open,
        }}
        ContentProps={{
          onComplete: handleComplete,
          onCancel: toggleOpen,
        }}
      />
      <Button variant={'contained'} onClick={toggleOpen}>
        Add Menu
      </Button>
    </>
  );
};

export default AddMenuButton;

import React, { useState, useCallback } from 'react';
import { createStateContext } from 'react-use';

// import { Setup1 as TourSetup1 } from '../Tours/Setup1';
import { Setup2 as TourSetup2 } from '../Tours/Setup2';
import { VisualBuilder as TourVisualBuilder } from '../Tours/VisualBuilder';
// import { Webphone as TourWebphone } from '../Tours/Webphone';
// import { Onboarding as TourOnboarding } from '../Tours/Onboarding';
// import { MobileApp as TourMobileApp } from '../Tours/MobileApp';
// import { First as TourFirst } from '../Tours/First';
// import { User as TourUser } from '../Tours/User';
// import { ScheduleSetup as TourScheduleSetup } from '../Tours/ScheduleSetup';

import EventEmitter from 'eventemitter3';

import { TourProvider, useTour } from '@reactour/tour';
import '@reactour/popover/dist/index.css';

const ee = new EventEmitter();
ee.on('next', () => console.log('trying next!'));

const radius = 5;
const styles = {
  popover: (base, state) => {
    return {
      ...base,
      borderRadius: 10,
      ...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
    };
  },
  maskArea: base => ({ ...base, rx: radius }),
};

const opositeSide = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

const popoverPadding = 10;

function doArrow(position, verticalAlign, horizontalAlign) {
  if (!position || position === 'custom') {
    return {};
  }

  const width = 16;
  const height = 12;
  const color = 'white';
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = 10;

  const obj = {
    [`--rtp-arrow-${
      isVertical ? opositeSide[horizontalAlign] : verticalAlign
    }`]: height + spaceFromSide + 'px',
    [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + 'px',
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  };
  return obj;
}

export const POSSIBLE_TOURS = [
  // {
  //   id: 'setup1',
  //   component: TourSetup1,
  // },
  {
    id: 'setup2',
    component: TourSetup2,
  },
  {
    id: 'visual_builder',
    component: TourVisualBuilder,
  },
  // {
  //   id: 'webphone',
  //   component: TourWebphone,
  // },
  // {
  //   id: 'mobileapp',
  //   component: TourMobileApp,
  // },
  // {
  //   id: 'onboarding',
  //   component: TourOnboarding,
  // },
  // // {
  // //   id: 'first',
  // //   component: TourFirst,
  // // },
  // // {
  // //   id: 'user',
  // //   component: TourUser,
  // // },
  // // {
  // //   id: 'schedule-setup',
  // //   component: TourScheduleSetup,
  // // },
];

export const [useGlobalTour, GlobalTourStateProvider] = createStateContext();

// export const useTourHelper = props => {
//   const defaults = {
//     startOpen: null,
//   };
//   const { id, startOpen } = { ...defaults, ...props };

//   let [isOpen, setIsOpen] = useState(startOpen);
//   let openTour = useCallback(() => {
//     setIsOpen(true);
//   }, [setIsOpen]);

//   let closeTour = useCallback(() => {
//     console.log('closing!');
//     setIsOpen(null);
//   }, [setIsOpen]);

//   let tourProps = {
//     onClose: closeTour,
//   };

//   const TourComponent = POSSIBLE_TOURS.find(t => t.id === id)?.component ?? (
//     <></>
//   );

//   return {
//     tourIsOpen: isOpen,
//     tourEvents: ee,
//     openTour,
//     closeTour,
//     TourComponent,
//     tourProps,
//   };
// };

export const GlobalTourProvider = ({ children }) => {
  let [tourId, setTourIdFinal] = useState(null);
  let [tourSettingsOverwrite, setTourSettingsOverwrite] = useState({}); // used by individual steps to customize the appearance

  const setTourId = value => {
    // resets, then sets
    setTourIdFinal('');
    setTimeout(() => {
      setTourIdFinal(value);
    }, 150);
  };

  let closeTour = () => setTourId(null);

  let tourProps = {
    onClose: closeTour,
    tourEvents: ee,
  };

  const TourComponent =
    POSSIBLE_TOURS.find(t => t.id === tourId)?.component ?? null;

  // console.log('tourId:', { tourId, TourComponent });

  return (
    <GlobalTourStateProvider
      initialValue={{
        openTour: setTourId,
        tourEvents: ee,
        setTourSettingsOverwrite,
      }}
    >
      <TourProvider
        steps={[]}
        styles={styles}
        scrollSmooth
        padding={{ mask: 1, popover: 10 }}
        className={'tmp-tour-class'}
        maskClassName={'tmp-mask-class'}
        showBadge={false}
        showDots={false}
        disableInteraction
        nextButton={({
          Button,
          currentStep,
          stepsLength,
          setIsOpen,
          setCurrentStep,
          steps,
        }) => {
          const last = currentStep === stepsLength - 1;
          if (last) {
            return null;
          }
          return (
            // <Button
            //   onClick={() => {
            //     if (last) {
            //       setIsOpen(false);
            //     } else {
            //       setCurrentStep(s => (s === steps?.length - 1 ? 0 : s + 1));
            //     }
            //   }}
            // >
            //   {last ? 'Close!' : null}
            // </Button>
            <button
              className="btn"
              onClick={() => {
                if (last) {
                  setIsOpen(false);
                } else {
                  setCurrentStep(s => (s === steps?.length - 1 ? 0 : s + 1));
                }
              }}
            >
              Next
            </button>
          );
        }}
        {...tourSettingsOverwrite}
      >
        {TourComponent ? <TourComponent {...tourProps} /> : null}
        {children}
      </TourProvider>
    </GlobalTourStateProvider>
  );
};

import React from 'react';
import useParticipant from 'app/pages/v2/pipe/hooks/useParticipant';
import { Avatar } from 'app/design-lib';
import { useNumber } from 'app/components/PhoneNumberDisplay';
import { Emoji } from 'emoji-picker-react';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';

export const ParticipantName = ({
  value,
  size = 'md',
  justName = false,
  justAvatar = false,
}) => {
  // TODO - get array of participants to optimize creating participants UI
  const { result } = useParticipant(value);
  let name = '...';
  let avatar = <Avatar size={size} />;

  const { number, code, isExtension, national, international } = useNumber({
    ptn: value,
  });

  switch (result?.type) {
    case 'pipe':
      name = result.pipe.metadata?.name ?? '';
      avatar = (
        <Emoji unified={result.pipe.metadata.emoji || '1f937'} size={25} />
      );
      // <UserAvatar size={size} user={result.user} />;
      // <Emoji unified={pipe.metadata.emoji || '1f937'} size={25} />;
      break;
    case 'user':
      name = result.user.extra.fullName;
      // @ts-ignore
      avatar = <UserAvatar size={size} user={result.user} />;
      break;
    case 'contact':
      name = [result.contact.info?.firstName, result.contact.info?.lastName]
        .filter(v => !!v?.length)
        .join(' ');
      const initials = name
        .split(' ')
        .map(n => n[0])
        .join('');
      avatar = <Avatar size={size} initials={initials} />;
      break;
    case 'maybeContact':
      name = result.maybeContact.name;
      const inits = name
        .split(' ')
        .map(n => n[0])
        .join('');
      avatar = <Avatar size={size} initials={inits} />;
      break;
    default:
      // TODO: handle international users
      name = isExtension ? value : national ?? value;
      avatar = (
        <Avatar
          size={size}
          initials={
            national?.substring(national.length - 2, national.length) ?? ''
          }
        />
      );
      break;
  }

  if (justName) return <>{name}</>;
  if (justAvatar) return <>{avatar}</>;

  return (
    <div className="inline-flex items-center space-x-2 text-md font-medium">
      {avatar}
      <span>{name}</span>
    </div>
  );
};
export default ParticipantName;

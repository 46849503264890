import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import vmboxQueryKeys from 'app/hooks/queries/vmbox/vmboxQueryKeys';
import { sdk } from 'app/sdk';
import { useMutation, useQueryClient } from 'react-query';
import { VmboxCreateDoc } from 'types/vmbox';

export const useCreateVmbox = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation(
    (vmboxCreateDoc: VmboxCreateDoc) =>
      sdk.vmbox.mutate.create(vmboxCreateDoc, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(vmboxQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

import setupUserCallflow from 'app/components/Onboarding/Vsb/Step3/fn/setupUserCallflow';
import setupUserVoicemail from 'app/components/Onboarding/Vsb/Step3/fn/setupUserVoicemail';
import { WarningTriangle } from 'iconoir-react';
import React, { Fragment, useEffect, useState } from 'react';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useGlobalTour } from 'app/components/Tourguide/Helper';
import { useAuthSelector } from 'app/data/auth';
import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  Link,
  Slide,
  Tooltip,
  Typography,
  Card,
  CardContent,
} from 'app/design';
import { Alert, Button } from 'app/design-lib';
import { ChevronLeft as ChevronLeftIcon } from 'app/design/icons-material';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { sdk } from 'app/sdk';
import { useAccountQuery } from 'app/hooks/queries/account';

import { useLocalStorage } from 'react-use';
import { parseAndSetKazooMutationErrors, sleep } from 'app/utilities';
import { store } from '../../../../../store';

import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';

import { useFormContext } from 'react-hook-form';
import { PhoneNumber } from './components/PhoneNumber';
import setupAccountDetails from './fn/setupAccountDetails';
import setupAccountCallerId from './fn/setupAccountCallerId';
import setupCompanyVoicemail from './fn/setupCompanyVoicemail';
import setupFeatureCodesAndNomatch from './fn/setupFeatureCodesAndNomatch';
import setupMainCallflow from './fn/setupMainCallflow';
import setupNumberDetails from './fn/setupNumberDetails';
import setupRingGroup from './fn/setupRingGroup';
import setupPipes from './fn/setupPipes';

import { usePipeNew } from 'app/pages/v2/settings/Pipes/Pipes';
import { Transition } from '@headlessui/react';
import messaging from 'app/pages/v2/settings/PipeView/components/Messaging/Messaging';
import { WarningTriangle as WarningIcon } from 'iconoir-react';
let ranSetupFeatureCodes = false,
  featureCodesPromiseResolver,
  featureCodesPromise = new Promise(
    resolve => (featureCodesPromiseResolver = resolve),
  );

const Step3 = ({ user }) => {
  const { handleSubmit, watch, setValue, setError, clearErrors } =
    useFormContext();
  const chooseNumberOrSkip = watch('chooseNumberOrSkip');
  const [inVal, setInVal] = useState(true);
  const prevStep = watch('prevStep');
  const transitionToStep = watch('transitionToStep');
  const thisStep = 1;
  const timeout = 350;
  const handlePrev = () => {
    setValue('transitionToStep', thisStep - 1);
    setInVal(false);
    setTimeout(() => {
      // setValue('prevStep', thisStep);
      setValue('activeStep', thisStep - 1);
    }, timeout);
  };

  // run feature code setup once here (should complete quickly)
  const { auth_token, account_id } = useAuthSelector();
  useEffect(() => {
    if (!ranSetupFeatureCodes) {
      ranSetupFeatureCodes = true;
      setupFeatureCodesAndNomatch({
        auth_token,
      }).then(async () => {
        console.log('Feature codes ready');
        featureCodesPromiseResolver();
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const direction = inVal
    ? prevStep > thisStep
      ? 'right'
      : 'left'
    : transitionToStep > thisStep
    ? 'right'
    : 'left';

  return (
    <>
      <div className={`relative h-full grid place-items-center`}>
        <Transition
          as={Fragment}
          enter="ease-in transition-all delay-500 duration-[1000ms]"
          enterFrom="opacity-0 absolute"
          enterTo="opacity-100 absolute"
          leave="ease-in duration-500"
          leaveFrom="opacity-100 absolute"
          leaveTo="opacity-0 absolute"
          appear
          show={!!chooseNumberOrSkip}
        >
          <div className={'flex flex-col w-full space-y-6'}>
            <TrunkingioNumber
              user={user}
              thisStep={thisStep}
              setInVal={setInVal}
              timeout={timeout}
              handlePrev={handlePrev}
            />
          </div>
        </Transition>
        <Transition
          as={Fragment}
          enter="ease-in transition-all delay-500 duration-[1000ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-500"
          leaveFrom="opacity-100 absolute"
          leaveTo="opacity-0 absolute"
          appear
          show={!chooseNumberOrSkip}
        >
          <div className={'flex flex-col w-full space-y-6'}>
            <OptionsScreen
              user={user}
              thisStep={thisStep}
              setInVal={setInVal}
              timeout={timeout}
              handlePrev={handlePrev}
            />
          </div>
        </Transition>
      </div>
    </>
  );

  // return (
  //   <Slide in={inVal} timeout={timeout} direction={direction}>
  //     <div>
  //       <Fade in={inVal} timeout={timeout}>
  //         <div>
  //           {chooseNumberOrSkip ? (
  //             <TrunkingioNumber
  //               user={user}
  //               thisStep={thisStep}
  //               setInVal={setInVal}
  //               timeout={timeout}
  //               handlePrev={handlePrev}
  //             />
  //           ) : (
  //             <OptionsScreen
  //               user={user}
  //               thisStep={thisStep}
  //               setInVal={setInVal}
  //               timeout={timeout}
  //               handlePrev={handlePrev}
  //             />
  //           )}
  //         </div>
  //       </Fade>
  //     </div>
  //   </Slide>
  // );
};

const OptionsScreen = ({ user, thisStep, timeout, handlePrev, setInVal }) => {
  const { handleSubmit, watch, setValue, setError, clearErrors } =
    useFormContext();
  const { auth_token, account_id } = useAuthSelector();
  const pipeNew = usePipeNew();

  // const { data: account, refetch: refetchAccount } = useAccountQuery({});
  const accountBillingMutate = useAccountStripeCustomerMutate();

  const [{ openTour }] = useGlobalTour();

  const { data: phoneNumbersData } = useListPhoneNumbersQuery({
    skip: 0,
    take: 10, // take
    orderBy: [], // order by
    filters: {}, // filters
  });
  const { phoneNumbers } = phoneNumbersData ?? {};

  const [isSaving, setIsSaving] = useState(false);
  const [isFailedBuyingNumber, setIsFailedBuyingNumber] = useState(false);
  const [isFailedSaving, setIsFailedSaving] = useState(false);

  const companyName = watch('companyName');
  const address = watch('address');

  const handleSkip = async () => {
    // alert('Sorry, skipping is currently disabled for beta testing');

    await setupPipes({
      auth_token,
      pipeNew,
      ptn: null,
      phoneNumbers,
      user,
      users: [],
    });
    await featureCodesPromise;

    console.log('-DONE- (skipped)');

    // transition to Final Screen
    setTimeout(() => {
      setValue('activeStep', 3);
      setInVal(false);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={'flex flex-col space-y-2'}>
        <Alert
          icon={<WarningIcon fr={undefined} />}
          label={'No calling or messaging charges during beta'}
          body={`You will not be billed for minutes, messages or numbers during CallingIO's beta period. Note you will still be billed per user after your trial expires.`}
          color={'attention'}
          reverse
        />
        <div className={' font-medium text-3xl '}>Get a new number for $1.</div>
        <div className={'text-md'}>
          Buy a new line with our partner TrunkingIO. It's just that simple.
        </div>
      </div>
      <div className={`flex flex-col space-y-1`}>
        <Button
          size={'lg'}
          className={`w-full`}
          variant={'fill'}
          color={'accent'}
          onClick={() => setValue('chooseNumberOrSkip', true)}
        >
          Get a Phone Number
        </Button>
        {process.env.NODE_ENV === 'development' ? (
          <Button
            size={'lg'}
            className={`w-full`}
            variant={'ghost'}
            color={'neutral'}
            onClick={handleSkip}
            // onClick={() => setValue('chooseNumberOrSkip', false)}
          >
            Skip for now, get a number later
          </Button>
        ) : null}
      </div>
      <div className={`mt-4 text-neutral-600`}>
        {/* <div className={`text-sm font-medium`}>Why TrunkingIO?</div> */}
        <div className={`text-sm`}>
          Fees for carrier services like phone numbers, minutes, SMS, etc are
          charged directly by the carrier you choose to integrate. You can
          purchase a phone number through TrunkingIO (or in the future,
          integrate a different carrier of your choosing.)
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <Box
  //       sx={{
  //         borderRadius: '8px',
  //         background: 'white',
  //         boxShadow: '0px 4px 14px 4px rgba(27, 125, 144, 0.12)',
  //         p: 5,
  //         pt: 3,
  //         pb: 3,
  //         transition: 'height 2s linear',
  //         height: 'auto',
  //       }}
  //     >
  //       {isSaving ? (
  //         <div className="text-lg my-4 text-center">
  //           <CircularProgress size={44} />
  //           <br />
  //           <br />
  //           One moment while we get your account setup...
  //           <br />
  //           <br />
  //         </div>
  //       ) : isFailedSaving ? (
  //         <Box sx={{ mb: 0, textAlign: 'center' }}>
  //           <div className="text-lg mb-1">
  //             Sorry, we had a problem finishing setup for your account
  //           </div>
  //           <div className="text-md">
  //             Please contact{' '}
  //             <a href="mailto:support@calling.io">support@calling.io</a> and
  //             we'll walk you through the rest of setup.
  //           </div>
  //         </Box>
  //       ) : (
  //         <>
  //           <div className="mb-6">
  //             <div className="text-center text-lg mb-2 truncate w-full">
  //               Setup a new number for{' '}
  //               <Tooltip arrow title={watch('companyName')}>
  //                 <Box
  //                   component="span"
  //                   sx={{
  //                     color: theme => theme.palette.primary.main,
  //                   }}
  //                 >
  //                   {watch('companyName')}
  //                 </Box>
  //               </Tooltip>
  //             </div>
  //             <br />
  //             <div
  //               className="cursor-pointer rounded shadow-sm border border-neutral-100"
  //               onClick={() => setValue('chooseNumberOrSkip', true)}
  //             >
  //               <div className="p-4">
  //                 <div className="flex justify-between">
  //                   <div className="flex-auto">
  //                     <div className="text-lg mb-1">
  //                       Get a number with TrunkingIO
  //                     </div>
  //                     <div className="text-md text-neutral-500">
  //                       TrunkingIO provides numbers and minutes
  //                     </div>
  //                   </div>
  //                   <div className="flex-initial">
  //                     <Button
  //                       color={'positive'}
  //                       variant={'fill'}
  //                       className="ml-auto"
  //                     >
  //                       Select
  //                     </Button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <br />
  //             <div
  //               className="cursor-pointer rounded shadow-sm border border-neutral-100"
  //               onClick={handleSkip}
  //             >
  //               <div className="p-4">
  //                 <div className="flex justify-between">
  //                   <div className="flex-auto">
  //                     <div className="text-lg">Skip getting a number</div>
  //                     <div className="text-md text-neutral-500">
  //                       You can always add a phone number integration later
  //                     </div>
  //                   </div>
  //                   <div className="flex-initial">
  //                     <Button
  //                       color={'neutral'}
  //                       variant={'outline'}
  //                       className="ml-auto"
  //                     >
  //                       Skip
  //                     </Button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <Grid
  //             container
  //             alignItems="center"
  //             justifyContent="space-between"
  //             sx={{ mt: 2 }}
  //           >
  //             <Grid item>
  //               <Button
  //                 size="sm"
  //                 // size="small"
  //                 // variant="contained"
  //                 // color="primary"
  //                 onClick={handlePrev}
  //                 // onClick={() => setValue('chooseNumberOrSkip', false)}
  //                 startIcon={<ChevronLeftIcon />}
  //               >
  //                 Go back
  //               </Button>
  //             </Grid>
  //           </Grid>
  //         </>
  //       )}
  //     </Box>
  //   </>
  // );
};

const TrunkingioNumber = ({
  user,
  thisStep,
  timeout,
  handlePrev,
  setInVal,
}) => {
  const pipeNew = usePipeNew();

  const { handleSubmit, watch, setValue, setError, clearErrors } =
    useFormContext();
  const { auth_token, account_id } = useAuthSelector();

  // const { data: account, refetch: refetchAccount } = useAccountQuery({});
  const accountBillingMutate = useAccountStripeCustomerMutate();

  const [{ openTour }] = useGlobalTour();

  const { data: usersResp, refetch: refetchUsers } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};

  const { data: phoneNumbersData } = useListPhoneNumbersQuery({
    skip: 0,
    take: 10, // take
    orderBy: [], // order by
    filters: {}, // filters
  });
  const { phoneNumbers } = phoneNumbersData ?? {};

  const [isSaving, setIsSaving] = useState(false);
  const [isFailedBuyingNumber, setIsFailedBuyingNumber] = useState(false);
  const [isFailedSaving, setIsFailedSaving] = useState(false);

  const companyName = watch('companyName');
  const address = watch('address');

  const handleNext = async () => {
    setIsSaving(true);
    setIsFailedBuyingNumber(false);
    setIsFailedSaving(false);

    // const { auth_token, account_id } = store.getState().auth;

    // try {
    //   // Update account name/address (used for integrations, etc)
    //   await setupAccountDetails({
    //     auth_token,
    //     accountId: account_id,
    //     companyName,
    //     address,
    //   });
    // } catch (e: any) {
    //   const response = e?.response;
    //   setIsSaving(false);
    //   setIsFailedSaving(true);
    //   const errors = parseAndSetKazooMutationErrors({
    //     response,
    //     setError: () => {},
    //   });
    //
    //   console.log('kazoo errors', errors);
    //
    //   const nameError = errors.find(error => error.field === 'name');
    //
    //   if (nameError) {
    //     // assuming
    //     setValue('activeStep', 0);
    //     setError('companyName', {
    //       message: nameError.message,
    //     });
    //   } else {
    //     alert(`Sorry, there was an error creating your account`);
    //   }
    //   return;
    // }

    // Setup trunkingio integration
    // - will be used for searching AND purchases
    // await accountBillingMutate.mutateAsync({
    //   data: {
    //     action: 'newCustomer',
    //     data: {},
    //   },
    // });

    // Buy number first
    // - it is OK if fails, we just ask them to buy a different number

    let ptn = watch('ptn');

    // get a number from the account if it exists...
    // console.log('phoneNumbers:', phoneNumbers);
    if (phoneNumbers?.length) {
      ptn = phoneNumbers[0].id;
    } else {
      // alert('not buying a number right now');
      // setIsSaving(false);
      // setIsFailedSaving(true);
      // return;
      try {
        const purchaseResult = await sdk.phoneNumber.mutate.buyTrunkingio(
          { numbers: [ptn] },
          { auth_token },
        );
        console.log('Purchase result:', purchaseResult);
        const order_id = purchaseResult.data.data.id;

        let order_results;
        let tryNum = 1;
        while (true) {
          await sleep(2000);
          console.log('Checking order results again...');
          const order_results =
            await sdk.phoneNumber.query.orderResultsTrunkingio(
              { order_id },
              { auth_token },
            );

          if (order_results.status === 'complete') {
            break;
          }

          if (order_results.status === 'failed')
            throw new Error('Unable to purchase number');

          tryNum += 1;
        }

        console.log(
          'Number purchased, waiting a few seconds for sync to complete trunkingio<>callingio',
        );
        await sleep(3000);

        // enable messaging for number
        sdk.phoneNumber.mutate
          .messagingSet({
            number: ptn,
            data: { enable: true, campaign_id: 'unchanged' },
          })
          .then(() => {
            sdk.phoneNumber.mutate.setMessagingCampaignDefaultLowVolume({
              number: ptn,
            });
          });

        // return; // REMOVE AFTER TESTING!
      } catch (err) {
        // if failed here, let them try to get another number (failure is expected/ok here, especially if there are alot of people trying to signup at once?)
        // alert('Sorry, failed to buy number, please try again');
        setIsSaving(false);
        setIsFailedBuyingNumber(true);
        setValue('ptn', null);
        return;
      }
    }

    // console.log('PTN:', ptn);
    // setIsSaving(false);
    // setIsFailedSaving(true);
    // return;

    // sdk.phoneNumber.query.phoneNumbers(
    //     {
    //       skip,
    //       take,
    //       orderBy,
    //       filters,
    //     },
    //     { authToken: authState.auth_token },
    //   ),

    // Bought number, finish setting up account
    // ---> treat this as "should never fail" -> if it does, immediately involve Support
    // - update account name
    // - save e911 address for number
    // - update caller ID for number (use account name)
    // - create All Users ring group
    // - company voicemail box (and set it up correctly)
    // - set up account call handling (Ring Group, Ring Company Shared Voicemail)
    // - Feature codes
    // - nomatch callflow
    try {
      await setupAccountCallerId({
        auth_token,
        ptn,
        accountId: account_id,
        companyName,
      });

      await setupPipes({ auth_token, pipeNew, ptn, phoneNumbers, user, users });
      // await setupNumberDetails({
      //   auth_token,
      //   user,
      //   ptn,
      //   companyName,
      //   address,
      // });
      // const { groupId } = await setupRingGroup({ auth_token, userId: user.id });
      // const { vmboxId } = await setupCompanyVoicemail({ auth_token });
      // const { vmboxId: userVmboxId } = await setupUserVoicemail({
      //   auth_token,
      //   user,
      // });

      // // const vmboxId = '1';
      // await setupMainCallflow({
      //   auth_token,
      //   ptn,
      //   accountId: account_id,
      //   vmboxId,
      //   groupId,
      // });

      // await setupUserCallflow({
      //   auth_token,
      //   presenceId: user?.doc?.presence_id?.toString(),
      //   userId: user?.doc?.id,
      //   vmboxId: userVmboxId,
      //   isUserVmboxId: true,
      // });

      await featureCodesPromise;
    } catch (err: any) {
      // TODO: "should never fail"
      console.error(err.message);
      setIsSaving(false);
      setIsFailedSaving(true);
      return;
    }

    console.log('-DONE-');
    // setIsSaving(false);
    // return;

    // transition to Final Screen
    setTimeout(() => {
      setValue('activeStep', 3);
      // setValue('transitionToStep', thisStep + 1);
      setInVal(false);
      // setTimeout(() => {
      //   // setValue('activeStep', thisStep + 1);
      //    openTour('setup2');
      // }, timeout);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        {isSaving ? (
          <div className="text-lg my-4 text-center">
            <CircularProgress size={44} />
            <br />
            <br />
            One moment while we get your number setup...
            <br />
            <br />
          </div>
        ) : isFailedSaving ? (
          <Box sx={{ mb: 0, textAlign: 'center' }}>
            <div className="text-lg mb-1">
              Sorry, we had a problem finishing setup for your account
            </div>
            <div className="text-md">
              Please contact{' '}
              <a href="mailto:support@calling.io">support@calling.io</a> and
              we'll walk you through the rest of setup.
            </div>
          </Box>
        ) : (
          <>
            {phoneNumbers?.length ? (
              <Box
                sx={{
                  p: 2,
                  mb: 4,
                  br: 4,
                  // border: theme =>
                  //   // @ts-ignore
                  //   `1px solid ${theme.palette.card.border}`,
                }}
              >
                <div className="text-md" color="info.main">
                  Existing Number (will actually use):
                  <br />
                  <strong>
                    <PhoneNumberDisplay ptn={phoneNumbers[0].id} />
                  </strong>
                </div>
              </Box>
            ) : null}
            <Box
              sx={{
                mb: 6,
                textAlign: 'center',
              }}
            >
              {watch('ptn') ? (
                <div className="text-center text-lg mb-4 truncate w-full">
                  Your new number for{' '}
                  <Tooltip arrow title={watch('companyName')}>
                    <Box
                      component="span"
                      sx={{
                        color: theme => theme.palette.primary.main,
                      }}
                    >
                      {watch('companyName')}
                    </Box>
                  </Tooltip>
                </div>
              ) : null}
              {isFailedBuyingNumber ? (
                <div className="text-md" color="error">
                  Sorry, we failed getting that number.
                  <br />
                  Let's try another one:
                  <br />
                </div>
              ) : null}
              <div className="text-2xl text-center">
                {/* <PhoneNumberDisplay ptn={'+18315555555'} /> */}
                <PhoneNumber />
              </div>
              {/* <Typography variant="caption">
                <Link>Change my number</Link>
              </div> */}
            </Box>

            <div className={`flex flex-col space-y-1`}>
              <Button
                className={`w-full`}
                variant={`fill`}
                size={'lg'}
                color={`accent`}
                onClick={handleNext}
                disabled={!watch('ptn')}
              >
                Finish setup
              </Button>
              <Button
                className={`w-full`}
                variant={`ghost`}
                size={'lg'}
                color={`neutral`}
                onClick={() => setValue('chooseNumberOrSkip', false)}
              >
                Back
              </Button>
            </div>
            {/*<Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button
                  size="sm"
                  // variant="contained"
                  // color="primary"
                  // onClick={handlePrev}
                  onClick={() => setValue('chooseNumberOrSkip', false)}
                  startIcon={<ChevronLeftIcon />}
                >
                  Go back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color={!watch('ptn') ? 'neutral' : 'positive'}
                  variant={'fill'}
                  className="ml-auto"
                  onClick={handleNext}
                  disabled={!watch('ptn')}
                >
                  Finish Setup
                </Button>
              </Grid>
            </Grid>*/}
          </>
        )}
      </div>
      {/*<Box sx={{ mt: 3, textAlign: 'center' }}>
        <div className="text-md">
          Want to port your number? <br />
          You can do so after finishing setup
        </div>
      </Box>*/}
    </>
  );
};

export default Step3;

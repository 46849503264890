import { gql } from 'graphql-request';

export default gql`
  query TemporalRulesSets(
    # $skip: Int
    # $take: Int
    # $orderBy: JSON
    $filters: JSON # $mask: String
  ) {
    temporalRulesSets(
      # skip: $skip
      # take: $take
      # orderBy: $orderBy
      filters: $filters # mask: $mask
    ) {
      id
      account_id
      doc
      matching
      createdAt
      updatedAt
      # totalCount
      # skip
      # take
      # temporalRulesSets {
      #   id
      #   account_id
      #   doc
      #   createdAt
      #   updatedAt
      # }
    }
  }
`;

const useStyles = (props = {}) => ({
  row: {
    background: props.rowColor
      ? props.rowFull
        ? props.rowColor
        : `linear-gradient(to bottom, ${props.rowColor} 0%,rgba(255,255,255,0) 8px),
        linear-gradient(to bottom, rgba(255,255,255,0) calc(100% - 8px),${props.rowColor} 100%),
            linear-gradient(to right, ${props.rowColor} 0%,rgba(255,255,255,0) 8px),
            linear-gradient(to right, rgba(255,255,255,0) calc(100% - 8px),${props.rowColor} 100%)`
      : 'none',
    padding: 12,
  },
  cell: {
    cursor: 'pointer',
    borderRadius: 2,
    background: props?.bgcolor || 'rgba(0,0,0,0.05)',
    padding: '2px 5px',
    margin: '0 2px',
    '> svg': {
      verticalAlign: 'middle',
    },
  },
});

export default useStyles;

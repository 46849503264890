import { CircularProgress } from '@mui/material';
import * as React from 'react';
import SimpleBar from 'simplebar-react';

interface LoadingProps {
  label?: string;
  size?: number;
}

const Loading = ({ label = 'Loading...', size = 20 }: LoadingProps) => {
  return (
    <div className={'h-full grid place-items-center'}>
      <div className={'font-medium text-sm flex flex-col items-center'}>
        <CircularProgress size={size} />
        <span>{label}</span>
      </div>
    </div>
  );
};

export default Loading;

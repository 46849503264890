import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';

export const MenuItem_Modify = props => {
  const { data, mode, onClick, onClose } = props;
  const {
    callflow: rootCallflow,
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    currentCallflow,
    componentData,
    componentInfo,
    templateParent,
    // callflow,
    // setCallflow,
    // modifyPath,
  } = data;

  // const { callflow, setCallflow, modifyPath } = insertAfterData;

  return (
    <MenuItem
      onClick={e => {
        // if (rootCallflow.type != 'template' && templateParent) {
        //   alert(
        //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
        //   );
        // }
        onClose();
        // handleRemove();
        onClick();
      }}
      component={'a'}
    >
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText primary="Modify" />
    </MenuItem>
  );
};

export default MenuItem_Modify;

import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { MediaDoc } from 'types/media';
import { UserUpdatePartialDoc } from 'types/user';

export const useUpdateMediaFull = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, MediaDoc, unknown>(
    async (mediaFullDoc: MediaDoc) =>
      sdk.media.mutate.updateFull(mediaFullDoc, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(mediaQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

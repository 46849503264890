import { QuickFinderDeviceDialog } from 'app/components/QuickFinderDeviceDialog';
import { Button, Typography } from 'app/design';
import { useDeviceQuery } from 'app/hooks/queries/device';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { useFormContext } from 'react-hook-form';

// interface declaring which props are required/allowed
// interface DeviceProps {
//   // directoryId: string;
//   callflow?: any;
//   open?: boolean;
//   onCancel: () => void;
//   // onComplete: () => void;
// }

const Device = ({ item, modifyPath, onChangeSimple, ...rest }) => {
  console.log('Device:', { item, modifyPath, rest });
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showFinderDialog, toggleShowFindDialog] = useToggleReducer(false);

  const key = varItem.key;
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);

  // simple variable values
  const currentVarOutputValue = varItem.output_value;
  const defaultValueInFlow = varItem.output_value_default;
  const simpleValueInCallflow = variables[key];

  // get CURRENT value FROM MODULE
  // - for when saved by the Advanced Editor!
  //   - that doesnt update the varItem, it only updates the actual flow!
  const currentValue = moduleItem?.data?.data?.id;

  const {
    data: deviceCurrent,
    isLoading: deviceCurrentIsLoading,
    isFetched: deviceCurrentIsFetched,
  } = useDeviceQuery(currentValue);

  const {
    data: deviceSimple,
    isLoading: deviceSimpleIsLoading,
    isFetched: deviceSimpleIsFetched,
  } = useDeviceQuery(simpleValueInCallflow);

  const onChangeSelect = selected => {
    const [deviceId] = selected;
    setValue(
      `${modifyPath}callflow.strategy.simple.variables.${key}`,
      deviceId,
      {
        shouldDirty: true,
      },
    );
    // TODO: this may work for "account no_schedule" but may fail at others, need to verify
    // - we're doing this so that the "simple" value is applied immediately!
    //   - maybe should emit an event that rebuilds, instead?
    setValue(
      `${modifyPath}callflow${moduleModifyPath}.data.data.id`,
      deviceId,
      {
        shouldDirty: true,
      },
    );
    onChangeSimple && onChangeSimple();

    // setAtPath(tmpModuleItem, `data.id`, deviceId); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindDialog();
  };

  const handleClear = () => {
    setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, null, {
      shouldDirty: true,
    });
    // TODO: this may work for "account no_schedule" but may fail at others, need to verify
    // - we're doing this so that the "simple" value is applied immediately!
    //   - maybe should emit an event that rebuilds, instead?
    setValue(`${modifyPath}callflow${moduleModifyPath}.data.data.id`, null, {
      shouldDirty: true,
    });
    onChangeSimple && onChangeSimple();

    // setAtPath(tmpModuleItem, `data.id`, deviceId); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindDialog();
    // setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    // toggleShowFindDialog();
  };

  console.log('varitem Device:', varItem, {
    currentValue,
    simpleValueInCallflow,
    callflow: watch(`${modifyPath}callflow`),
  });

  return (
    <div>
      <QuickFinderDeviceDialog
        open={showFinderDialog}
        onSelect={onChangeSelect}
        onCancel={toggleShowFindDialog}
        onClear={handleClear}
        initialSelected={simpleValueInCallflow ? [simpleValueInCallflow] : []}
        selectionTitle={deviceSimple ? `${deviceSimple.doc.name}` : null}
        allowSelectNone
      />
      <Typography variant="h3">Device:</Typography>

      <Typography variant="bodyBig" sx={{ fontWeight: 'bold' }} paragraph>
        {deviceCurrentIsLoading
          ? 'Loading...'
          : deviceCurrent
          ? deviceCurrent.doc?.name ?? 'Invalid device'
          : 'None set'}
      </Typography>

      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={toggleShowFindDialog}
      >
        Change Device
      </Button>
      {/* <Typography variant="body1">
        Current:{' '}
        {deviceCurrentIsLoading
          ? 'Loading...'
          : deviceCurrent
          ? deviceCurrent.extra?.fullName ?? 'Invalid device'
          : 'None set'}
      </Typography>
      <Typography variant="body1" onClick={toggleShowFindDialog}>
        Custom:
        {deviceSimpleIsLoading
          ? 'Loading...'
          : deviceSimple
          ? deviceSimple.extra?.fullName ?? 'Invalid device'
          : 'None set'}
      </Typography> */}
    </div>
  );
};

export default Device;

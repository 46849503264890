import React from 'react';
// import "./button.css";
import { Link as MuiLink } from '@mui/material';
// @ts-ignore
// import { Link as RouterLink } from "react-router-dom";

export interface LinkProps {}

const Link = React.forwardRef((props: any, ref) => {
  return (
    <MuiLink
      ref={ref}
      // component={props.to ? RouterLink : undefined}
      {...props}
    />
  );
});

export default Link;

import { FormatListNumbered as FormatListNumberedIcon } from '@mui/icons-material';
import { CollectionListHeader } from 'app/components/CollectionListHeader';
import { Box, Chip, Divider, Grid, Tooltip, Typography } from 'app/design';

import {
  AccessTime as AccessTimeIcon,
  ClearAll as ClearAllIcon,
  DragHandle as DragHandleIcon,
  Edit as EditIcon,
  Group as GroupIcon,
  Layers as LayersIcon,
  Person as PersonIcon,
  PhoneAndroid as PhoneAndroidIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from 'app/design/icons-material';
import {
  getAtPath,
  randomString,
  setAtPath,
  useToggleReducer,
} from 'app/utilities';
import {
  preEndpointsToCallflowModuleFormat,
  preEndpointsToGroupFormat,
} from 'app/utilities/utils';
// import { useTranslation } from 'react-i18next';
// import { set } from 'lodash';
import { toInteger, sum } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// import { fetchAllVmboxes } from '../../../../../Actions/voicemails';
import { AddComponent } from '../AddComponent';
import { RingConfig as RingConfigCustom } from '../Custom/Custom';
import {
  Alert,
  DialogActions,
  DialogContent,
  DialogHeader,
  Button,
  IconButton,
  TextField,
} from 'app/design-lib';
import {
  ArrowLeft,
  Cancel,
  EditPencil,
  KeyframesCouple,
  Menu,
  NavArrowLeft,
  Phone,
  Strategy,
  User,
  WarningTriangle,
  Wrench,
} from 'iconoir-react';
import { StepTab } from 'app/components/Onboarding/Vsb/Vsb';
import { useMediaQuery } from '@mui/material';

// Select users from list of users
export const FallbackComponent = props => {
  const {
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    // users,
    // devices,
    defaultToFirst,
    ownerUser,
    // fetchUsers,
    errorMessage,
    allow,
    useGroupFormat, // vs. "ringgroup" Format (requires conversion! has restrictions!)
    onSave,
    onReset,
    startEditing,
    users,
    devices,
    onClose,
  } = props;

  const [isEditing, setIsEditing] = useState(startEditing ? true : false);

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  useEffect(() => {
    if (errorMessage) setIsEditing(true);
  }, [errorMessage]);

  const ringGroup = getAtPath(strategyCallflow, `${modifyPath}`, {
    // endpoints: useGroupFormat ? {} : [],
    // preEndpoints: [], // gets built into "endpoints" depending on the useGroupFormat
    // timeout: 20,
    // strategy: 'simultaneous',
  });

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${modifyPath}.preEndpoints`,
    [],
  );

  const handleSave = () => {
    const data = getAtPath(strategyCallflow, `${modifyPath}`);

    let { preEndpoints = [] } = data;

    if (!data.timeout) {
      data.timeout = ringTimeout;
    }
    // rebuild preEndpoints timeout and delay
    // - assigns for Tiers
    let delayAmount = 0;
    preEndpoints = preEndpoints.map((endpoint, i) => {
      if (i === 0) {
        endpoint.delay = 0;
        endpoint.fallbackDelay = 0;
      } else {
        endpoint.delay = delayAmount + endpoint.fallbackDelay;
        delayAmount += endpoint.fallbackDelay;
      }

      // dont NEED to do the math to have the correct timeout here (parent timeout will end all of them)
      endpoint.timeout = data.timeout - endpoint.delay;
      return endpoint;
    });

    const endpoints = useGroupFormat
      ? preEndpointsToGroupFormat(preEndpoints)
      : preEndpointsToCallflowModuleFormat(preEndpoints);
    setAtPath(strategyCallflow, `${modifyPath}.endpoints`, endpoints);
    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, preEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
    console.log('Fallback (backoff) Endpoints Build:', endpoints);
    onSave && onSave();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const endpoints = reorder(
        selectedEndpoints,
        result.source.index,
        result.destination.index,
      );
      setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, endpoints);

      // TODO: convert according to useGroupFormat

      setStrategyCallflow({ ...strategyCallflow });

      // convert according to useGroupFormat
    },
    [selectedEndpoints, modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleAdd = new_endpoints_arr => {
    let currentOpts = {
      fallbackDelay: 0,
      timeout: 10,
    };
    let newArr = new_endpoints_arr.map(t => ({ ...t, ...currentOpts }));

    const updatedEndpoints = [...selectedEndpoints, ...newArr];

    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleSetRingTimeout = timeoutInt => {
    setAtPath(strategyCallflow, `${modifyPath}.timeout`, timeoutInt);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleResetToChooser = () => {
    setAtPath(strategyCallflow, `${modifyPath}.type`, 'none');
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const ringTimeout = getAtPath(strategyCallflow, `${modifyPath}.timeout`, 20);
  // console.log('ringTimeout:', ringTimeout);
  return (
    <>
      <DialogHeader
        title={
          isMobile
            ? 'Ring Strategy: Fallback '
            : 'Ring Strategy: Fallback (backoff)'
        }
        subTitle={`Ring users and devices for a specified duration with customized delay periods before ringing each.`}
        onClose={onClose}
      />
      <DialogContent className={`overflow-y-auto`}>
        {errorMessage ? (
          <div className={'pt-0 px-2 pb-3'}>
            <Alert
              label={'Error Saving Ring Group'}
              reverse
              color={'negative'}
              icon={<WarningTriangle fr={undefined} />}
              body={errorMessage}
            />
          </div>
        ) : null}
        {isEditing ? (
          <div
            className={`flex pb-4 flex-1 justify-center mx-4 sm:mx-44 items-center space-x-4`}
          >
            <StepTab
              link
              label={'1. Select Strategy'}
              icon={<Strategy width={12} height={12} fr={undefined} />}
              currentStep={1}
              step={0}
              onClick={handleResetToChooser}
            />
            <div
              className={`flex-grow h-[1px] bg-neutral-20 rounded-full`}
            ></div>
            <div className={`w-`}></div>
            <StepTab
              label={'2. Customize Group'}
              icon={<Wrench width={12} height={12} fr={undefined} />}
              currentStep={1}
              step={1}
            />
          </div>
        ) : null}
        <div className={`bg-neutral-10 rounded p-4`}>
          <div className={`flex space-x-4 items-start`}>
            <div className={`flex-1`}>
              <div
                className={`flex sm:flex-row flex-col w-full justify-between`}
              >
                <span className={`text-md font-medium sm:mb-0 mb-1 underline`}>
                  Members & Ring Order
                </span>
                <div>
                  {isEditing ? (
                    <TextField
                      label="Total Ring Duration (seconds)"
                      value={ringTimeout}
                      type="number"
                      onChange={e =>
                        // setDefaultRingTotalTime(toInteger(e.target.value))
                        handleSetRingTimeout(toInteger(e.target.value))
                      }
                    />
                  ) : (
                    <div
                      className={`font-medium mb-2 w-full text-right text-md`}
                    >
                      Total Ring Duration: {ringTimeout}s
                    </div>
                  )}
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedEndpoints.length ? (
                        selectedEndpoints.map((endpoint, i) =>
                          isEditing ? (
                            <RingConfig
                              key={endpoint.id}
                              index={i}
                              endpoint={endpoint}
                              users={users}
                              devices={devices}
                              timeout={ringTimeout}
                              endpointsModifyPath={`${modifyPath}.preEndpoints`}
                              modifyPath={`${modifyPath}.preEndpoints.${i}`}
                              strategyCallflow={strategyCallflow}
                              setStrategyCallflow={setStrategyCallflow}
                              provided={provided}
                              isEditing={isEditing}
                              allow={allow}
                            />
                          ) : (
                            <RingConfigCustom
                              key={endpoint.id}
                              index={i}
                              endpoint={endpoint}
                              users={users}
                              devices={devices}
                              timeout={ringTimeout}
                              endpointsModifyPath={`${modifyPath}.preEndpoints`}
                              modifyPath={`${modifyPath}.preEndpoints.${i}`}
                              strategyCallflow={strategyCallflow}
                              setStrategyCallflow={setStrategyCallflow}
                              provided={provided}
                              isEditing={isEditing}
                              allow={allow}
                            />
                          ),
                        )
                      ) : (
                        <Alert
                          className={'mt-2'}
                          icon={<WarningTriangle fr={undefined} />}
                          label={'No users or devices'}
                          color={'attention'}
                          reverse
                          body={
                            'Add at least two users or devices to this ring group'
                          }
                        />
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div>
              {/*<TextField
              startIcon={<Clock fr={undefined} />}
              label="Ring duration"
              value={ringTimeout}
              disabled={!isEditing}
              type="number"
              min={0}
              onChange={e =>
                // setDefaultRingTotalTime(toInteger(e.target.value))
                handleSetRingTimeout(toInteger(e.target.value))
              }
            />*/}
            </div>
          </div>
          {isEditing && !ringGroup.managed ? (
            <div className={`w-full pt-2 items-end flex justify-between`}>
              <AddComponent
                // label={'Add to Group:'}
                {...props}
                selectedEndpoints={selectedEndpoints}
                allow={[...props.allow, 'tier']}
                onAdd={handleAdd}
                tierCount={
                  selectedEndpoints.filter(ep => {
                    return ep.endpoint_type === 'tier';
                  }).length
                }
              />
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions
        className={`flex ${isEditing ? 'justify-between' : 'justify-end'}`}
      >
        {isEditing ? (
          <>
            <Button
              color="negative"
              variant="fill"
              size={'md'}
              onClick={e => {
                setIsEditing(false);
                onReset();
              }}
            >
              Cancel Editing
            </Button>
            {/*  {ringGroup.managed ? null : (
              <Button
                color="attention"
                variant="outline"
                size={'md'}
                onClick={handleResetToChooser}
              >
                Change strategy
              </Button>
            )}*/}
            <Button
              color="accent"
              variant="fill"
              size={'md'}
              onClick={e => {
                handleSave();
                setIsEditing(false);
              }}
            >
              Save Ring Group
            </Button>
          </>
        ) : (
          <Button
            variant={'fill'}
            color={'accent'}
            size={'md'}
            onClick={e => setIsEditing(true)}
          >
            Edit Ring Group
          </Button>
        )}
      </DialogActions>
    </>
  );

  // return (
  //   <div>
  //     <CollectionListHeader
  //       icon={<ClearAllIcon color={'primary'} style={{ fontSize: 20 }} />}
  //       title="Ring Strategy: Fallback (backoff)"
  //       subtitle="Ring users and devices for a specified duration with customized delay periods before ringing each."
  //       actionButton={
  //         isEditing ? (
  //           <>
  //             <Grid container spacing={1}>
  //               {ringGroup.managed ? null : (
  //                 <Grid item>
  //                   <Button
  //                     color="primary"
  //                     variant="contained"
  //                     size="small"
  //                     // startIcon={<EditIcon />}
  //                     disableRipple
  //                     onClick={handleResetToChooser}
  //                   >
  //                     Change Ring Strategy
  //                   </Button>
  //                 </Grid>
  //               )}
  //
  //               <Grid item>
  //                 <Button
  //                   color="error"
  //                   variant="outlined"
  //                   size="small"
  //                   // startIcon={<EditIcon />}
  //                   disableRipple
  //                   onClick={e => {
  //                     setIsEditing(false);
  //                     onReset();
  //                   }}
  //                 >
  //                   Cancel
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </>
  //         ) : (
  //           <Button variant={'contained'} onClick={e => setIsEditing(true)}>
  //             Edit
  //           </Button>
  //         )
  //       }
  //     />
  //     <br />
  //
  //     <Box sx={{ width: '100%', pl: 3, display: 'grid', placeItems: 'center' }}>
  //       <Grid container columnSpacing={2}>
  //         <Grid item xs={9}>
  //           <Box sx={{ width: '90%' }}>
  //             <DragDropContext onDragEnd={onDragEnd}>
  //               <Droppable droppableId="droppable">
  //                 {provided => (
  //                   <div ref={provided.innerRef} {...provided.droppableProps}>
  //                     {selectedEndpoints.length ? (
  //                       selectedEndpoints.map((endpoint, i) => (
  //                         <RingConfig
  //                           key={endpoint.id}
  //                           index={i}
  //                           endpoint={endpoint}
  //                           users={users}
  //                           devices={devices}
  //                           timeout={ringGroup.timeout}
  //                           endpointsModifyPath={`${modifyPath}.preEndpoints`}
  //                           modifyPath={`${modifyPath}.preEndpoints.${i}`}
  //                           strategyCallflow={strategyCallflow}
  //                           setStrategyCallflow={setStrategyCallflow}
  //                           provided={provided}
  //                           isEditing={isEditing}
  //                           allow={allow}
  //                         />
  //                       ))
  //                     ) : (
  //                       <Typography
  //                         variant="body1"
  //                         style={{
  //                           fontStyle: 'italic',
  //                           opacity: 0.8,
  //                           textAlign: 'left',
  //                           py: 5,
  //                         }}
  //                       >
  //                         No users or devices added to ring group
  //                       </Typography>
  //                     )}
  //                     {provided.placeholder}
  //                   </div>
  //                 )}
  //               </Droppable>
  //             </DragDropContext>
  //             {isEditing ? (
  //               <>
  //                 <br />
  //                 <AddComponent
  //                   // label={'Add to Group:'}
  //                   {...props}
  //                   selectedEndpoints={selectedEndpoints}
  //                   allow={[...props.allow, 'tier']}
  //                   onAdd={handleAdd}
  //                   tierCount={
  //                     selectedEndpoints.filter(ep => {
  //                       return ep.endpoint_type === 'tier';
  //                     }).length
  //                   }
  //                 />
  //               </>
  //             ) : null}
  //           </Box>
  //         </Grid>
  //         <Grid item xs={3}>
  //           <Box
  //             sx={{
  //               width: '100%',
  //
  //               justifyContent: 'left',
  //               display: 'grid',
  //               textAlign: 'left',
  //             }}
  //           >
  //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //               <AccessTimeIcon color={'primary'} />
  //               <Typography
  //                 variant={'h6'}
  //                 sx={{ fontStyle: 'italic', color: 'text.secondary', ml: 0.5 }}
  //               >
  //                 Total Ring Duration:
  //               </Typography>
  //             </Box>
  //             {isEditing ? (
  //               <TextField
  //                 label=""
  //                 value={ringTimeout}
  //                 type="number"
  //                 inputProps={{
  //                   min: 0,
  //                 }}
  //                 onChange={e =>
  //                   // setDefaultRingTotalTime(toInteger(e.target.value))
  //                   handleSetRingTimeout(toInteger(e.target.value))
  //                 }
  //                 InputLabelProps={{
  //                   shrink: true,
  //                 }}
  //                 margin="dense"
  //                 variant="outlined"
  //                 size="small"
  //                 style={{ textAlign: 'right', maxWidth: 100 }}
  //               />
  //             ) : (
  //               <Typography variant="h6" sx={{ ml: 2.5 }}>
  //                 {ringTimeout}s
  //               </Typography>
  //             )}
  //           </Box>
  //         </Grid>
  //       </Grid>
  //       <br />
  //       {isEditing ? (
  //         <>
  //           <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
  //             <Button
  //               variant="contained"
  //               color="success"
  //               disableRipple
  //               onClick={e => {
  //                 handleSave();
  //                 setIsEditing(false);
  //               }}
  //             >
  //               Save
  //             </Button>
  //           </Box>
  //         </>
  //       ) : null}
  //     </Box>
  //   </div>
  // );
};

const RingConfig = props => {
  const {
    index,
    endpoint,
    timeout,
    users,
    devices,
    endpointsModifyPath,
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    isEditing,
    allow,
  } = props;
  const [showEditTierName, toggleEditTierName] = useToggleReducer();

  const ringDelay = getAtPath(
    strategyCallflow,
    `${modifyPath}.fallbackDelay`,
    0,
  );

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${endpointsModifyPath}`,
    [],
  );
  const totalDelay = sum(
    selectedEndpoints.map((e, i) =>
      i === 0 ? 0 : i <= index ? e.fallbackDelay : 0,
    ),
  );
  let totalRingTime = timeout - totalDelay;
  if (totalRingTime < 0) {
    totalRingTime = 0;
  }
  // console.log({ timeout, ringDelay, totalDelay, totalRingTime });

  const handleChangeTierName = tierName => {
    setAtPath(strategyCallflow, `${modifyPath}.tier_name`, tierName);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleAddToTier = new_endpoints_arr => {
    let new_arr = [...tiers, ...new_endpoints_arr];
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, new_arr);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleChange = useCallback(
    (e, value) => {
      // console.log('modifyPath:', modifyPath, value);
      setAtPath(strategyCallflow, `${modifyPath}.fallbackDelay`, value[0]);
      setAtPath(strategyCallflow, `${modifyPath}.timeout`, value[1] - value[0]);
      setStrategyCallflow({ ...strategyCallflow });
    },
    [modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleRemove = useCallback(() => {
    const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
    endpointsArr.splice(index, 1);
    setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
    setStrategyCallflow({ ...strategyCallflow });
  }, [index, endpointsModifyPath, strategyCallflow, setStrategyCallflow]);

  const handleSetRingDelay = delayInt => {
    setAtPath(strategyCallflow, `${modifyPath}.fallbackDelay`, delayInt);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleBreakTier = () => {
    let currentOpts = {
      delay: endpoint.delay,
      timeout: endpoint.timeout,
    };
    let newArr = endpoint.tiers.map(t => ({ ...t, ...currentOpts }));
    const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
    endpointsArr.splice.apply(endpointsArr, [index, 1].concat(newArr));
    setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleConvertToTier = () => {
    // move the endpoint_type to the list of endpoints in tiers
    const tiers = [
      JSON.parse(JSON.stringify(endpoint)), // id, endpoint_type
    ];
    // set a default tier name

    setAtPath(strategyCallflow, `${modifyPath}.tier_name`, 'Tier');
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, tiers);
    setAtPath(strategyCallflow, `${modifyPath}.endpoint_type`, 'tier');
    setAtPath(strategyCallflow, `${modifyPath}.id`, randomString(5));
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const user = users.find(d => d.id === endpoint.id);
  const device = devices.find(d => d.id === endpoint.id);

  const isTier = endpoint.endpoint_type === 'tier' ? true : false;
  const tierName = endpoint.tier_name;

  const tiers = endpoint.tiers || [];

  const getTotalSeconds = delay => {
    let total = timeout - delay;
    return total > 0 ? total : 0;
  };

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  return (
    <Draggable draggableId={endpoint.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={`relative`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <br />
          <Grid
            sx={{ px: 2, pb: endpoint.endpoint_type === 'tier' ? 1 : 0 }}
            container
            spacing={1}
            wrap={isMobile ? undefined : 'nowrap'}
            alignItems={'center'}
          >
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid container wrap={'nowrap'} alignItems="center">
                <Grid
                  item
                  style={{ width: 34, display: isEditing ? undefined : 'none' }}
                >
                  <IconButton
                    size={'sm'}
                    variant={'ghost'}
                    color={'neutral'}
                    {...provided.dragHandleProps}
                  >
                    <Menu width={16} height={16} fr={undefined} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <span className={`text-md`}>{index + 1}.&nbsp;</span>
                </Grid>
                <Grid>
                  <div
                    style={{
                      // marginTop: 2,
                      width: '100%',
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {endpoint.endpoint_type === 'user' ? (
                      <div
                        className={`flex space-x-2 sm:max-w-full max-w-[12rem] items-center`}
                      >
                        <div>
                          <User
                            className={`text-neutral-60`}
                            width={16}
                            height={16}
                            fr={undefined}
                          />
                        </div>
                        <span className={'font-medium truncate text-md'}>
                          {user?.extra?.fullName ?? 'Unknown User'}
                        </span>
                      </div>
                    ) : (
                      // <>{user?.extra?.fullName ?? 'Unknown User'}</>
                      ''
                    )}
                    {endpoint.endpoint_type === 'device' ? (
                      <div
                        className={`flex sm:max-w-full max-w-[12rem] space-x-2 items-center`}
                      >
                        <div>
                          <Phone
                            className={`text-neutral-60`}
                            width={16}
                            height={16}
                            fr={undefined}
                          />
                        </div>
                        <span className={'font-medium truncate text-md'}>
                          {device?.doc?.name}
                        </span>
                      </div>
                    ) : (
                      // <>{device?.doc?.name}</>
                      ''
                    )}
                    {endpoint.endpoint_type === 'tier' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isEditing && showEditTierName ? (
                          <TextField
                            label="Tier Name"
                            value={endpoint.tier_name ?? ''}
                            onChange={e => handleChangeTierName(e.target.value)}
                          />
                        ) : (
                          <div className={`flex space-x-2 items-center`}>
                            <div>
                              <KeyframesCouple
                                className={`text-neutral-60`}
                                width={16}
                                height={16}
                                fr={undefined}
                              />
                            </div>
                            <span className={'font-medium text-md'}>
                              {endpoint.tier_name}
                            </span>
                            {!isEditing && (
                              <Grid item>
                                <Tooltip
                                  placement="right"
                                  arrow
                                  title={
                                    <>
                                      {endpoint.tiers.map(
                                        (tierEndpoint, tierEndpointIdx) => (
                                          <TierComponent
                                            users={users}
                                            devices={devices}
                                            key={tierEndpoint.id}
                                            {...props}
                                            tierEndpointIdx={tierEndpointIdx}
                                            tierEndpoint={tierEndpoint}
                                          />
                                        ),
                                      )}
                                    </>
                                  }
                                >
                                  <div>
                                    <Chip
                                      sx={{ height: 20 }}
                                      label={endpoint.tiers?.length}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>
                            )}
                          </div>
                        )}
                        {isEditing ? (
                          <Box
                            sx={{
                              ml: 0.5,
                              display: 'grid',
                              placeItems: 'center',
                            }}
                          >
                            <IconButton
                              size={'sm'}
                              color={'accent'}
                              variant={'ghost'}
                              onClick={toggleEditTierName}
                            >
                              <EditPencil fr={undefined} />
                            </IconButton>
                          </Box>
                        ) : null}
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {isEditing ? (
              <>
                <Grid xs={12} item style={{ maxWidth: 240 }}>
                  {index > 0 ? (
                    <TextField
                      label="Delay (seconds)"
                      value={ringDelay}
                      type="number"
                      min={0}
                      onChange={e =>
                        handleSetRingDelay(toInteger(e.target.value))
                      }
                      helperText={
                        <>
                          Total Delay: {totalDelay}s<br />
                          Total Ring: {totalRingTime}s
                        </>
                      }
                    />
                  ) : (
                    <span
                      className={`italic whitespace-nowrap text-md text-neutral-60`}
                    >
                      {`Ring full ${totalRingTime}s duration (no delay)`}
                    </span>
                  )}
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      height: '100%',
                      ml: 2,
                      display: 'grid',
                      placeItems: 'center',
                    }}
                  >
                    {/* @ts-ignore */}
                    <IconButton
                      onClick={handleRemove}
                      color={'negative'}
                      size={'sm'}
                      variant={'ghost'}
                      className={`sm:relative absolute top-6 sm:top-0 right-0`}

                      // color="secondary"
                      // variant="outlined"
                      // sx={{ padding: 0, fontSize: 20 }}
                    >
                      <Cancel fr={undefined} />
                    </IconButton>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  style={{
                    width: 150,
                    maxWidth: 150,
                    paddingLeft: 25,
                    textAlign: 'center',
                  }}
                >
                  <span
                    className={`whitespace-nowrap text-md ${
                      index > 0 ? '' : 'text-neutral-60 italic'
                    }`}
                  >
                    {index > 0
                      ? `${getTotalSeconds(ringDelay)}s`
                      : `Full duration (${getTotalSeconds(ringDelay)}s)`}
                  </span>
                  {index > 0 ? (
                    <span className={`ml-2 text-md text-neutral-60`}>
                      ({(ringDelay ? `${ringDelay}s` : 'no') + ' delay'})
                    </span>
                  ) : null}
                </Grid>
              </>
            )}
          </Grid>
          {endpoint.endpoint_type === 'tier' && isEditing ? (
            <Box className={`sm:ml-9 ml-4 -mt-1 sm:-mt-11`}>
              {endpoint.tiers.map((tierEndpoint, tierEndpointIdx) => (
                <TierComponent
                  users={users}
                  devices={devices}
                  key={tierEndpoint.id}
                  {...props}
                  tierEndpointIdx={tierEndpointIdx}
                  tierEndpoint={tierEndpoint}
                />
              ))}
              <div className={'mb-1'}></div>
              <AddComponent
                {...props}
                label={'Add to sub-group'}
                selectedEndpoints={selectedEndpoints}
                modifyPath={props.parentModifyPath}
                onAdd={handleAddToTier}
              />
              <br />
              <br />
            </Box>
          ) : (
            ''
          )}
          {endpoint.endpoint_type === 'tier' ? null : <br />}
          <Divider />
        </div>
      )}
    </Draggable>
  );
};

const TierComponent = props => {
  const {
    strategyCallflow,
    setStrategyCallflow,
    modifyPath,
    tierEndpoint,
    tierEndpointIdx,
    isEditing,
    users,
    devices,
  } = props;

  const user = users.find(d => d.id === tierEndpoint.id);
  const device = devices.find(d => d.id === tierEndpoint.id);

  const handleRemove = () => {
    const tiers = getAtPath(strategyCallflow, `${modifyPath}.tiers`);
    tiers.splice(tierEndpointIdx, 1);
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, tiers);
    setStrategyCallflow({ ...strategyCallflow });
  };

  return (
    <div className={`my-1`} style={{ width: '100%' }}>
      <Grid
        container
        spacing={1}
        wrap={'nowrap'}
        alignItems={'center'}
        style={{ width: '100%' }}
      >
        <Grid item>
          {tierEndpoint.endpoint_type === 'user' ? (
            <div className={`flex space-x-1 items-center`}>
              <User
                fr={undefined}
                className={`text-neutral-60`}
                width={16}
                height={16}
              />
              <span className={'font-medium text-md whitespace-nowrap'}>
                {user?.extra?.fullName ?? 'Unknown User'}
              </span>
            </div>
          ) : (
            // <>{user?.extra?.fullName ?? 'Unknown User'}</>
            ''
          )}
          {tierEndpoint.endpoint_type === 'device' ? (
            <div className={`flex space-x-1 items-center`}>
              <Phone
                fr={undefined}
                className={`text-neutral-60`}
                width={16}
                height={16}
              />
              <span className={'font-medium text-md'}>{device?.doc?.name}</span>
            </div>
          ) : (
            // <>{device?.doc?.name}</>
            ''
          )}
        </Grid>
        {isEditing && (
          <Grid item style={{ width: 34, textAlign: 'left' }}>
            <IconButton
              onClick={handleRemove}
              color={'negative'}
              variant={'ghost'}
            >
              <Cancel fr={undefined} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const DividerWithText = ({ children }) => {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Divider style={{ flex: '1 1 auto' }} />
      <span style={{ padding: '0 10px' }}>{children}</span>
      <Divider style={{ flex: '1 1 auto' }} />
    </Grid>
  );
};

export default FallbackComponent; //connect(mapStateToProps, { fetchUsers })(RingGroup);

import { mutateGraphql } from 'app/sdk/utils/graphql';
import { buildGetDefault, buildPatchDefault } from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {},

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    newCustomer: (vars, fetchOptions) =>
      mutateGraphql('stripeNewCustomer', vars, fetchOptions),
    customer: (vars, fetchOptions) =>
      mutateGraphql('stripeCustomerMutate', vars, fetchOptions),
  },
};

export default exports;

import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { UserListOptions } from 'app/components/ListUsers/components/UserListOptions';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { usePNContext } from 'app/utilities/pubnub';
import { AddUser, WarningTriangle } from 'iconoir-react';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useListUsersQuery } from 'app/hooks/queries/user';

import { usePipeUpdate } from 'app/pages/v2/common/sdk';
import { User } from 'types/user';
import {
  Alert,
  Badge,
  Button,
  Setting,
  SquareProgress,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';
import { SettingsPanelLoader } from 'app/design-lib/components/SettingsPanelLoader.tsx';

function Users({ pipe, refetch }) {
  // shows the users for the user_ids of the pipe

  // console.log('PIPE (Users):', pipe);
  const pipeUpdate = usePipeUpdate();

  const [selectUser, setSelectUser] = React.useState(false);

  const openSelectUser = () => {
    setSelectUser(true);
  };

  const handleRemove = async (id: string) => {
    const updateUsers = cloneDeep(pipe.users);

    console.log(updateUsers, id);
    delete updateUsers[id];

    await pipeUpdate.mutateAsync({
      id: pipe.id,
      data: {
        users: {
          ...updateUsers,
        },
      },
    });
  };

  const handleSelect = async newUsers => {
    // console.log('newUsers:', newUsers);
    const newUsersObj = {};
    for (let newUser of newUsers) {
      // if user not already in pipe (avoids overwriting other settings)
      if (!pipe.users[newUser])
        newUsersObj[newUser] = {
          enabled: true,
        };
    }
    await pipeUpdate.mutateAsync({
      id: pipe.id,
      data: {
        users: {
          ...pipe.users,
          ...newUsersObj,
        },
      },
    });
    console.log('Updated users!');
    refetch();
    setSelectUser(false);
  };

  const user_ids = Object.keys(pipe.users);

  const {
    data: usersPage,
    isLoading: usersLoading,
    refetch: usersRefetch,
  } = useListUsersQuery({
    take: 500,
    filters: {
      context: {
        ids: user_ids,
      },
    },
    options: {},
  });
  const users = usersPage?.users ?? [];

  return (
    <SettingsPanel
      leftChild={
        <>
          <QuickFinderUserDialog
            open={selectUser}
            onSelect={handleSelect}
            onCancel={() => setSelectUser(false)}
            exclude={user_ids.map(id => ({ id, checked: true }))}
            multiple
          />
          <Setting
            label={'Team'}
            body={'Teammates with access to line.'}
            iconButtonProps={
              pipe.type === 'personal'
                ? undefined
                : {
                    color: 'accent',
                    children: <AddUser fr={undefined} />,
                    // children: 'Add user',
                    onClick: openSelectUser,
                  }
            }
          />
        </>
      }
      rightClassName={`overflow-hidden`}
      rightChild={
        usersLoading ? (
          <SettingsPanelLoader label={'Loading line users...'} />
        ) : users.length ? (
          <Table>
            {users.map((user, index) => {
              return (
                <UserRow
                  key={user.id}
                  user={user}
                  single={pipe?.type === 'personal' ? true : false}
                  refetch={usersRefetch}
                  onRemove={handleRemove}
                  showBorder={users.length > 1 && index < users.length - 1}
                />
              );

              // return (
              //   <div key={user.id} className="flex justify-between ">
              //     <div>
              //       <div className="font-semibold">{user.extra.fullName}</div>
              //       <div className="text-neutral-400">{user.doc.username}</div>
              //     </div>
              //     <div>
              //       <button>Actions</button>
              //     </div>
              //   </div>
              // );
            })}
          </Table>
        ) : (
          <Alert
            reverse
            icon={<WarningTriangle fr={undefined} />}
            label={'No users'}
            color={'attention'}
            body={'No users are currently assigned to this line'}
            actionProps={{
              children: 'Assign user',
              onClick: openSelectUser,
            }}
          />
        )
      }
    />
  );

  // if (pipe.type === 'personal') {
  //   return (
  //     <PersonalPipe {...{ pipe, refetch, usersLoading, users, usersRefetch }} />
  //   );
  // }
  //
  // return (
  //   <>
  //     <QuickFinderUserDialog
  //       open={selectUser}
  //       onSelect={handleSelect}
  //       onCancel={() => setSelectUser(false)}
  //       exclude={user_ids.map(id => ({ id }))}
  //       passResourceOnSelect
  //     />
  //     <div data-tour-settings-lines-view-users>
  //       <div className="mb-2">
  //         <div className="text-lg font-bold" onClick={e => usersRefetch()}>
  //           Users
  //         </div>
  //         <div className="text-md text-neutral-400">
  //           This determines who can see the Line on the left sidebar. Individual
  //           permissions can be set per-user. When using Default Ringing, all
  //           these users will be rung as well.
  //         </div>
  //         <div className="mt-1 w-full flex justify-end">
  //           <Button
  //             variant={'outline'}
  //             color={'accent'}
  //             onClick={openSelectUser}
  //           >
  //             Add User
  //           </Button>
  //         </div>
  //       </div>
  //       <div className="p-4 space-y-4">
  //         {users.map(user => {
  //           return (
  //             <UserRow
  //               key={user.id}
  //               user={user}
  //               refetch={usersRefetch}
  //               onRemove={handleRemove}
  //             />
  //           );
  //
  //           // return (
  //           //   <div key={user.id} className="flex justify-between ">
  //           //     <div>
  //           //       <div className="font-semibold">{user.extra.fullName}</div>
  //           //       <div className="text-neutral-400">{user.doc.username}</div>
  //           //     </div>
  //           //     <div>
  //           //       <button>Actions</button>
  //           //     </div>
  //           //   </div>
  //           // );
  //         })}
  //         {usersLoading ? <div>Loading members...</div> : null}
  //         {!usersLoading && !users?.length ? (
  //           <div>This line has no members!</div>
  //         ) : null}
  //       </div>
  //     </div>
  //   </>
  // );
}

// const PersonalPipe = ({ pipe, refetch, usersLoading, usersRefetch, users }) => {
//   return (
//     <div data-tour-settings-lines-view-users>
//       <div className="mb-2">
//         <div className="text-lg font-bold" onClick={e => usersRefetch()}>
//           User
//         </div>
//       </div>
//       <div className="p-4 space-y-4">
//         {users.map(user => {
//           return <UserRow single user={user} refetch={usersRefetch} />;
//
//           // return (
//           //   <div key={user.id} className="flex justify-between ">
//           //     <div>
//           //       <div className="font-semibold">{user.extra.fullName}</div>
//           //       <div className="text-neutral-400">{user.doc.username}</div>
//           //     </div>
//           //   </div>
//           // );
//         })}
//         {usersLoading ? <div>Loading members...</div> : null}
//         {!usersLoading && !users?.length ? (
//           <div>This line has no members!</div>
//         ) : null}
//       </div>
//     </div>
//   );
// };

const UserRow = ({
  user,
  refetch,
  single,
  onRemove,
  showBorder,
}: {
  user: User;
  onRemove?: (id: string) => void;
  refetch: () => void;
  single?: boolean;
  showBorder: boolean;
}) => {
  // @ts-ignore
  const { onlineUsers } = usePNContext();
  const isOnline = onlineUsers?.[user.id] ? true : false;

  return (
    <TableRow
      showBorder={showBorder}
      key={user.id}
      // className={`flex justify-between items-center
      //  ${
      //   single
      //     ? ''
      //     : 'border-0  border-solid border-b pb-4 border-border-neutral/50'
      // }
      // `}
    >
      <TableCell flex>
        <div className="flex font-medium truncate items-center space-x-2">
          <div>
            <UserAvatar user={user} enabled={isOnline} size={'sm'} />
          </div>
          <p className="text-ellipsis">{user.extra.fullName}</p>
          {/*  <div className="text-neutral-400">{user.doc.username}</div>
            {devMode ? (
              <div className="text-neutral-300 text-xs">{user?.id}</div>
            ) : null}*/}
        </div>
      </TableCell>
      <TableCell className={`hidden sm:flex`} flex>
        {user.doc.title}
      </TableCell>
      <TableCell className={`hidden sm:flex`} flex>
        {user.doc.username}
      </TableCell>
      <TableCell className={'w-14'}>{user.doc.presence_id}</TableCell>
      <TableCell className={'w-20'}>
        {user.doc.priv_level === 'admin' ? (
          <Badge color={'accent'} variant={'fill'} reverse>
            Admin
          </Badge>
        ) : (
          <div></div>
        )}
      </TableCell>
      <TableCell>
        {single ? null : (
          <div className="h-full flex items-center">
            {/* <button>Actions</button> */}
            <ButtonDropdownMenu
              label="Actions"
              variant={'default'}
              vertical={true}
              menuItems={[
                {
                  text: 'Remove from Line',
                  onClick: () => onRemove && onRemove(user.id),
                },
              ]}
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Users;

import { gql } from 'graphql-request';

export default gql`
  query users(
    $skip: Int
    $take: Int
    $orderBy: JSON
    $filters: JSON
    $mask: String
  ) {
    users(
      skip: $skip
      take: $take
      orderBy: $orderBy
      filters: $filters
      mask: $mask
    ) {
      totalCount
      skip
      take
      users {
        id
        account_id
        doc
        listing
        createdAt
        updatedAt
        hasPhoto
        extra

        Callflows {
          id
          doc
          listing
          createdAt
          updatedAt
        }

        Pipes {
          id
          account_id
          key
          type
          metadata
          users
          settings
          updatedAt
          createdAt
        }

        Directories {
          directory {
            id
            doc
            listing
            createdAt
            updatedAt
          }
          callflow {
            id
            doc
            listing
            createdAt
            updatedAt
          }
        }

        Devices {
          id
          doc
          listing
          createdAt
          updatedAt
        }

        Groups {
          id
          doc
          listing
          createdAt
          updatedAt
        }

        Vmboxes {
          id
          doc
          listing
          createdAt
          updatedAt
          folders {
            new
            saved
            deleted
          }
        }

        ExternalCallerIdNumber {
          id
          doc
          listing
          createdAt
          inherited
        }

        InternalCallerIdNumber {
          id
          doc
          listing
          createdAt
          inherited
        }

        EmergencyCallerIdNumber {
          id
          doc
          listing
          createdAt
          inherited
        }

        HoldMusic {
          id
          doc
          listing
          createdAt
          inherited
        }
      }
    }
  }
`;

import {
  Button,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  FormControlLabel,
  InfoTooltip,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography,
  AnimationDialog,
} from 'app/design';

import { Dialog } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { Check as CheckIcon } from 'app/design/icons-material';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useDeviceQuery } from 'app/hooks/queries/device';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import * as React from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DeviceMedia } from 'types/device';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

// interface declaring which props are required/allowed
interface ContentProps {
  deviceId: string;
  onCancel: () => void;
  onComplete: () => void;
}

interface DeviceCodecsDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

const DeviceCodecsDialog = ({
  ContentProps,
  DialogProps,
}: DeviceCodecsDialogProps) => {
  return (
    <Dialog size={'lg'} open={DialogProps.open} onClose={ContentProps.onCancel}>
      <DeviceCodecsDialogContent {...ContentProps} />
    </Dialog>
  );
};

const DeviceCodecsDialogContent = ({
  deviceId,
  onCancel,
  onComplete,
}: ContentProps) => {
  const { data: device, isLoading: deviceIsLoading } = useDeviceQuery(deviceId);
  const formMethods = useForm<DeviceMedia>({
    defaultValues: {
      video: {
        codecs: [],
        ...device?.doc?.media?.video,
      },
      audio: { codecs: [], ...device?.doc?.media?.audio },
    },
  });

  const updateDevice = useUpdateDevicePartial();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = formMethods;

  const onSubmit = async (deviceForm: DeviceMedia) => {
    try {
      const resp = await updateDevice.mutateAsync({
        id: deviceId,
        media: deviceForm,
      });
    } catch (e: any) {
      parseAndSetKazooMutationErrors({ response: e.response, setError });
    }
  };

  return (
    <GenericMutationDialogContent
      onComplete={onComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'Device codecs has been updated'}
      isLoadingLabel={'Updating device codecs...'}
      title={'Edit Device Codecs'}
      mutation={updateDevice}
      formMethods={formMethods}
      queryIsLoading={deviceIsLoading}
      queryLabel={'Loading device codecs'}
    >
      <DeviceCodecsForm />
    </GenericMutationDialogContent>
  );
};

// TODO: codec values?
const defaultAudioList = [
  { value: 'CELT@32000h' }, //codecsI18n.audio['CELT@64000h'],
  { value: 'G722' }, //codecsI18n.audio.G722,
  { value: 'G729' }, //codecsI18n.audio.G729,
  { value: 'G7221@16000h' }, //codecsI18n.audio['G7221@16000h'],
  { value: 'G7221@32000h' }, //codecsI18n.audio['G7221@32000h'],
  { value: 'GSM' }, //codecsI18n.audio.GSM,
  { value: 'OPUS' }, //codecsI18n.audio.OPUS,
  { value: 'PCMA' }, //codecsI18n.audio.PCMA,
  { value: 'PCMU' }, //codecsI18n.audio.PCMU,
  { value: 'speex@16000h' }, //codecsI18n.audio['speex@16000h'],
  { value: 'speex@32000h' }, //codecsI18n.audio['speex@32000h'],
];

const defaultVideoList = [
  { value: 'H261' }, //codecsI18n.video.H261,
  { value: 'H263' }, //codecsI18n.video.H263,
  { value: 'H264' }, //codecsI18n.video.H264,
  { value: 'VP8' }, //codecsI18n.video.VP8,
];

const DeviceCodecsForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  // @ts-ignore
  const audioCodecs = watch('audio.codecs');
  // @ts-ignore
  const videoCodecs = watch('video.codecs');

  if (!audioCodecs || !videoCodecs) return null;

  return (
    <>
      {/* audio codecs */}
      <TextField
        fullWidth
        variant="outlined"
        select
        SelectProps={{
          multiple: true,
          dense: true,
          renderValue: value => {
            return value?.join(', ');
          },
        }}
        {...register('audio.codecs')}
        defaultValue={audioCodecs ?? []}
        label={'Audio Codecs'}
      >
        {defaultAudioList.map(option => (
          <MenuItem value={option.value} key={option.value}>
            <ListItemIcon>
              {audioCodecs.includes(option.value) ? (
                <CheckIcon fontSize="small" />
              ) : null}
            </ListItemIcon>
            <ListItemText>{option.value}</ListItemText>
          </MenuItem>
        ))}
      </TextField>
      <br />
      <br />
      {/* video codecs */}
      <TextField
        fullWidth
        variant="outlined"
        select
        SelectProps={{
          multiple: true,
          dense: true,
          renderValue: value => {
            return value?.join(', ');
          },
        }}
        {...register('video.codecs')}
        defaultValue={videoCodecs ?? []}
        label={'Video Codecs'}
      >
        {defaultVideoList.map(option => (
          <MenuItem value={option.value} key={option.value}>
            <ListItemIcon>
              {videoCodecs.includes(option.value) ? (
                <CheckIcon fontSize="small" />
              ) : null}
            </ListItemIcon>
            <ListItemText>{option.value}</ListItemText>
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default DeviceCodecsDialog;

import React from 'react';
import { AsYouType } from 'libphonenumber-js';
import Flag from 'react-flags';

export const PhoneNumberFlag = ({ ptn }) => {
  const asYouType = new AsYouType();
  asYouType.input(ptn ?? '');
  const code = asYouType.getNumber()?.country ?? 'unknown';
  // const classes = useStyles();
  // className={classes.flag}
  return (
    <div className="inline-block [&>img]:h-[0.75em] [&>img]:w-[1em]">
      <Flag
        name={code}
        format="svg"
        basePath={process.env.PUBLIC_URL + '/flags'}
      />
    </div>
  );
};

export default PhoneNumberFlag;

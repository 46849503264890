import {
  Delete as DeleteIcon,
  PhonelinkErase as PhoneLinkEraseIcon,
} from 'app/design/icons-material';
import { BulkActionsUsers } from 'app/components/ListUsers/components/BulkActions';
import { DeleteBulkUsers } from 'app/components/ListUsers/components/BulkActions/components/DeleteBulkUsers';
import { ReleaseDevicesBulkUsers } from 'app/components/ListUsers/components/BulkActions/components/ReleaseDevicesBulkUsers';
import { OptionsTableCell } from 'app/components/OptionsTableCell';
import { useDeleteUser } from 'app/hooks/mutations/user';
import * as React from 'react';
import { toast } from 'react-toastify';
import { User } from 'types/user';
import { useImmer } from 'use-immer';

interface UserListOptionsProps {
  user: User;
  onComplete: () => void;
  onDelete: () => void;
  toggleRowSelected: any;
  vertical?: boolean;
}

const UserListOptions = ({
  toggleRowSelected,
  onComplete,
  onDelete,
  user,
  vertical = true,
}: UserListOptionsProps) => {
  // const [showDialog, setShowDialog] = useImmer<string | null>(null);
  // const deleteUser = useDeleteUser();

  // const handleDelete = () => {
  //   const createUserPromise = deleteUser.mutateAsync(user.id, {
  //     onSuccess: async resp => {
  //       onComplete();
  //       onDelete();
  //       console.log('delete user resp', resp);
  //     },
  //     onError: error => console.error(error),
  //   });
  //
  //   toast.promise(createUserPromise, {
  //     pending: 'Deleting user...',
  //     success: 'User deleted!',
  //     error: 'Error deleting user.',
  //   });
  // };

  // const handleCancel = () => {
  //   setShowDialog(null);
  // };

  return (
    <BulkActionsUsers
      vertical={vertical}
      onComplete={() => {
        onComplete();
        onDelete();
      }}
      toggleRowSelected={toggleRowSelected}
      selectedRows={[user]}
    />
  );

  // return (
  //   <>
  //     {showDialog === 'delete' ? (
  //       <DeleteBulkUsers
  //         selectedRows={[user]}
  //         onComplete={() => {
  //           handleCancel();
  //           onComplete();
  //           onDelete();
  //         }}
  //         onCancel={handleCancel}
  //       />
  //     ) : null}
  //     {showDialog === 'release' ? (
  //       <ReleaseDevicesBulkUsers
  //         selectedRows={[user]}
  //         onComplete={() => {
  //           onComplete();
  //           handleCancel();
  //         }}
  //         onCancel={handleCancel}
  //       />
  //     ) : null}
  //     {showDialog === 'release' ? (
  //       <ReleaseDevicesBulkUsers
  //         selectedRows={[user]}
  //         onComplete={() => {
  //           onComplete();
  //           handleCancel();
  //         }}
  //         onCancel={handleCancel}
  //       />
  //     ) : null}
  //     <OptionsTableCell
  //       options={[
  //         // only show if sip devices
  //         ...(user.Devices.some(
  //           device => device.doc.device_type === 'sip_device',
  //         )
  //           ? [
  //               {
  //                 label: 'Release Devices to Pool',
  //                 icon: <PhoneLinkEraseIcon />,
  //                 onClick: () => setShowDialog('release'),
  //               },
  //             ]
  //           : []),
  //         {
  //           label: 'Delete',
  //           icon: <DeleteIcon />,
  //           onClick: () => setShowDialog('delete'),
  //         },
  //       ]}
  //     />
  //   </>
  // );
};

export default UserListOptions;

import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useListUsersQuery } from 'app/hooks/queries/user';

import { usePipeUpdate } from 'app/pages/v2/common/sdk';

function Users({ pipe, refetch }) {
  // shows the users for the user_ids of the pipe

  // console.log('PIPE (Users):', pipe);
  const pipeUpdate = usePipeUpdate();

  const [selectUser, setSelectUser] = React.useState(false);

  const openSelectUser = () => {
    setSelectUser(true);
  };

  const handleSelect = async newUsers => {
    console.log('newUsers:', newUsers);
    const newUsersObj = {};
    for (let newUser of newUsers) {
      newUsersObj[newUser.id] = {
        enabled: true,
      };
    }
    await pipeUpdate.mutateAsync({
      id: pipe.id,
      data: {
        users: {
          ...pipe.users,
          ...newUsersObj,
        },
      },
    });
    console.log('Updated users!');
    refetch();
    setSelectUser(false);
  };

  const user_ids = Object.keys(pipe.users);

  const {
    data: usersPage,
    isLoading: usersLoading,
    refetch: usersRefetch,
  } = useListUsersQuery({
    take: 500,
    filters: {
      context: {
        ids: user_ids,
      },
    },
    options: {},
  });
  const users = usersPage?.users ?? [];

  return (
    <>
      <QuickFinderUserDialog
        open={selectUser}
        onSelect={handleSelect}
        onCancel={() => setSelectUser(false)}
        exclude={user_ids.map(id => ({ id }))}
        passResourceOnSelect
      />
      <div>
        <div className="mb-2">
          <div className="text-lg" onClick={e => usersRefetch()}>
            Users
          </div>
          <div className="text-md text-neutral-400">
            This determines who can see the Room on the left sidebar. Individual
            permissions can be set per-user.
          </div>
          <div className="mt-1">
            <button onClick={openSelectUser}>Add User</button>
          </div>
        </div>
        <div className="p-4 space-y-4">
          {users.map(user => {
            return (
              <div key={user.id} className="flex justify-between ">
                <div>
                  <div className="font-semibold">{user.extra.fullName}</div>
                  <div className="text-neutral-400">{user.doc.username}</div>
                </div>
                <div>
                  <button>Actions</button>
                </div>
              </div>
            );
          })}
          {usersLoading ? <div>Loading members...</div> : null}
          {!usersLoading && !users?.length ? (
            <div>This line has no members!</div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Users;

import account_shared_vm from './account_shared_vm.json';
import account_vm from './account_vm.json';
import account_ring_user from './account_ring_user.json';
import account_ring_group from './account_ring_group.json';
import account_ring_device from './account_ring_device.json';
import account_audio from './account_audio.json';
import account_audio_transfer_to_ext from './account_audio_transfer_to_ext.json';
import account_audio_go_to_vm from './account_audio_go_to_vm.json';
import account_audio_ring_user_go_to_vm from './account_audio_ring_user_go_to_vm.json';
import account_audio_ring_group_go_to_vm from './account_audio_ring_group_go_to_vm.json';
import account_audio_ring_device_go_to_vm from './account_audio_ring_device_go_to_vm.json';
import account_ring_admin_go_to_vm from './account_ring_admin_go_to_vm.json';
import account_ring_user_go_to_vm from './account_ring_user_go_to_vm.json';
import account_ring_group_go_to_vm from './account_ring_group_go_to_vm.json';
import account_ring_device_go_to_vm from './account_ring_device_go_to_vm.json';
import account_transfer_to_admin_ext from './account_transfer_to_admin_ext.json';
import account_transfer_to_ext from './account_transfer_to_ext.json';
import account_menu_basic from './account_menu_basic.json';
import account_menu_blank from './account_menu_blank.json';
import blank from './blank.json';
import audio_transfer from './audio_transfer.json';
import ring_user_go_to_vm from './ring_user_go_to_vm.json';
import play_audio_ring_user_go_to_vm from './play_audio_ring_user_go_to_vm.json';
import play_audio_ring_group_go_to_vm from './play_audio_ring_group_go_to_vm.json';
import ring_device_go_to_vm from './ring_device_go_to_vm.json';
import vm from './vm.json';

export const TEMPLATES_BY_ID = {
  account_ring_user,
  account_ring_group,
  account_ring_device,
  account_ring_user_go_to_vm,
  account_ring_group_go_to_vm,
  account_ring_device_go_to_vm,
  account_audio,
  account_audio_transfer_to_ext,
  account_audio_go_to_vm,
  account_audio_ring_user_go_to_vm,
  account_audio_ring_group_go_to_vm,
  account_audio_ring_device_go_to_vm,

  account_menu_blank,
  account_transfer_to_ext,
  account_vm,
};

// OLD MAPPING (ignore)
const TEMPLATES = [
  // account_ring_admin_go_to_vm,
  account_ring_user,
  account_ring_group,
  account_ring_device,
  account_ring_user_go_to_vm,
  account_ring_group_go_to_vm,
  account_audio,
  account_audio_transfer_to_ext,
  account_audio_go_to_vm,
  account_audio_ring_user_go_to_vm,
  account_audio_ring_group_go_to_vm,
  account_audio_ring_device_go_to_vm,

  // account_transfer_to_admin_ext,
  account_transfer_to_ext,
  account_vm,
  // account_shared_vm,
  // account_menu_basic,
  account_menu_blank,
  // blank,
  audio_transfer,
  ring_user_go_to_vm,
  // user_go_to_vm,
  // group_go_to_vm,
  play_audio_ring_user_go_to_vm,
  play_audio_ring_group_go_to_vm,
  ring_device_go_to_vm,
  vm,
];

export default TEMPLATES;

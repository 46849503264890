import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { TextField as MuiTextField } from '@mui/material';

export interface TextFieldProps {}

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: prop => prop !== 'colorAlways',
})(({ theme, ...props }) => {
  return {
    // // "& label.Mui-focused": {
    '& label': {
      // @ts-ignore
      color: props.colorAlways ? theme.palette[props.color]?.main : undefined,
    },
    // "& .MuiInput-underline:after": {
    //   // borderBottomColor: "green",
    // },
    // '& .MuiOutlinedInput-root': {
    //   '& fieldset': {
    //     // @ts-ignore
    //     borderColor: theme.palette[props.color]?.main ?? undefined,
    //   },
    //   '&:hover fieldset': {
    //     // @ts-ignore
    //     borderColor: theme.palette[props.color]?.main
    //       ? // @ts-ignore
    //         alpha(theme.palette[props.color].main, 0.8)
    //       : undefined,
    //   },
    //   // "&.Mui-focused fieldset": {
    //   //   // borderColor: "green",
    //   // },
    // },
    // '& .MuiFormHelperText-root': {
    //   // @ts-ignore
    //   color: props.colorAlways ? theme.palette[props.color]?.main : undefined,
    // },
    // '& .MuiFormHelperText-root.Mui-focused': {
    //   // @ts-ignore
    //   color: theme.palette[props.color]?.main ?? undefined,
    // },
  };
});

const TextField = React.forwardRef((props: any, ref) => {
  return <StyledTextField ref={ref} {...props} />;
});

export default TextField;

import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Box,
  Grid,
  Divider,
  CardActions,
  Button,
} from 'app/design';
import { ScheduleCreateForm } from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
import { generateEmptyRule } from 'app/components/ScheduleCreateDialog/scheduleTemplates';
import { SchedulePlateEditor } from 'app/components/SchedulePlateEditor';
import { textToSlateTemporalRows } from 'app/utilities';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  DragIndicator as DragIndicatorIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  DeleteOutline as DeleteIcon,
} from 'app/design/icons-material';
// interface ScheduleTemplateSelectProps {}

const getItemStyle = (isDragging, draggableStyle) => ({
  // // some basic styles to make the items look a bit nicer
  // userSelect: 'none',
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  //
  // // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ScheduleStructureEditor = () => {
  const { control, watch, register } = useFormContext<ScheduleCreateForm>();
  const { remove, move, insert, swap } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'times', // unique name for your Field Array
    // shouldUnregister: true,
  });

  const [timesWatch] = watch(['times']);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  const handleRemove = index => () => {
    remove(index);
  };

  const handleInsert = index => () => {
    insert(index + 1, generateEmptyRule());
  };

  const handleMove = (index, up) => () => {
    up ? swap(index, index - 1) : swap(index, index + 1);
  };

  return (
    <>
      {/* <div style={{ width: '50%' }}>
        <TextField label={'Name'} {...register('name', { required: true })} />
      </div>
      <br /> */}
      {/* <Typography
        color={'gray.main'}
        sx={{ fontStyle: 'italic', mb: 1 }}
        variant={'caption'}
      >
        Add, reorder and remove Temporal Rules and customize their name.
      </Typography>
      <br />
      <br /> */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                borderRadius: 2,
                // @ts-ignore
                // background: 'lightgray',
                p: 1,
                mb: 1,
              }}
            >
              {timesWatch.map((time, index) =>
                time.isElse ? null : (
                  <Draggable key={time.id} draggableId={time.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <Controller
                          control={control}
                          // @ts-ignore
                          name={`times.${index}.rule.data.value`}
                          render={({
                            field: { onChange, value, ...props },
                          }) => {
                            return (
                              <Card
                                key={time.id}
                                variant={'outlined'}
                                sx={{
                                  border: 0,
                                  mb: index === timesWatch.length - 2 ? 0 : 1,
                                  // p: 1,
                                  p: 0,
                                }}
                              >
                                <Box sx={{ p: 0, pb: 0 }}>
                                  <Grid container alignItems={'center'}>
                                    <Grid item>
                                      <div
                                        style={{
                                          display: 'grid',
                                          placeItems: 'center',
                                          height: '100%',
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          onClick={handleMove(index, true)}
                                          // color={'gray'}
                                          disabled={index === 0}
                                        >
                                          <ExpandLessIcon />
                                        </Button>
                                        <div {...provided.dragHandleProps}>
                                          <DragIndicatorIcon />
                                        </div>

                                        <Button
                                          size="small"
                                          onClick={handleMove(index, false)}
                                          // color={'gray'}
                                          disabled={
                                            index === timesWatch.length - 2
                                          }
                                        >
                                          <ExpandMoreIcon />
                                        </Button>
                                      </div>
                                    </Grid>
                                    <Grid item>
                                      <TextField
                                        // @ts-ignore
                                        {...register(`times.${index}.name`, {
                                          required: true,
                                        })}
                                        size={'small'}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        size="small"
                                        color={'success'}
                                        onClick={handleInsert(index)}
                                      >
                                        <AddIcon /> Insert Category
                                      </Button>
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{ flex: 1, textAlign: 'right' }}
                                    >
                                      <Button
                                        size="small"
                                        onClick={handleRemove(index)}
                                        color={'error'}
                                        disabled={timesWatch.length <= 2}
                                      >
                                        <DeleteIcon /> Delete
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Card>
                            );
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ),
              )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Divider textAlign="center" sx={{ mb: 1 }}>
        Otherwise
      </Divider>
      <Card key={'closed'} variant={'outlined'}>
        <CardContent sx={{ display: 'grid', placeItems: 'center' }}>
          <TextField
            {...register(
              // @ts-ignore
              `times.${timesWatch.indexOf(
                timesWatch.find(time => time.isElse),
              )}.name`,
              {
                required: true,
              },
            )}
            size={'small'}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default ScheduleStructureEditor;

import CheckCircleIcon from '@mui/icons-material/Check';
import {
  AnimationDialog,
  Box,
  Checkbox,
  CircularProgress,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from 'app/design';
import { Dialog, Button } from 'app/design-lib';
import DefaultTable from 'app/components/DefaultTable/DefaultTable';
import DefaultTablePagination from 'app/components/DefaultTablePagination/DefaultTablePagination';
import { QuickFinderDialogActions } from 'app/components/QuickFinderDialogActions';
import QuickFinderDialogShell from 'app/components/QuickFinderDialogShell/QuickFinderDialogShell';
import { SlateSearch } from 'app/components/SlateSearch';
import { useToggleReducer } from 'app/utilities';
import { isEqual, pickBy } from 'lodash';
import * as React from 'react';
import { Dispatch, ReactNode, useEffect } from 'react';
import { usePagination, useRowSelect, useTable } from 'react-table';
import { QuickFinderDialogProps } from 'types/components';

interface QuickFinderGenericDialogProps extends QuickFinderDialogProps {
  // open: boolean;
  empty?: string;
  columns: any[];
  data: any[];
  totalCount: number;
  queryPageSize: number;
  queryPageIndex: number;
  queryPaginationDispatch: Dispatch<{ type: any; payload: any }>;
  queryIsFetching: boolean;
  queryIsLoading: boolean;
  searchPlaceholder: string;
  title: string;
  getRowId: (row: any) => string;
  onSearch: (search: string) => void;
  titleButton?: ReactNode;
}

const removeExcludes = (selected, exclude) => {
  if (exclude) {
    const excludeIds = exclude.map(e => e.id);
    return selected.filter(select => !excludeIds.includes(select));
  }

  return selected;
};

interface QuickFinderDialogShellProps extends QuickFinderGenericDialogProps {
  open: boolean;
}

const QuickFinderDialogWrapper = ({
  open,
  contentOnly,
  ...props
}: QuickFinderDialogShellProps) => {
  if (contentOnly) {
    if (!open) return null;

    return <QuickFinderGenericDialog open={open} {...props} />;
  }

  return (
    <Dialog size={'2xl'} open={open} onClose={props.onCancel}>
      <QuickFinderGenericDialog open={open} {...props} />
    </Dialog>
  );
};

const QuickFinderGenericDialog = ({
  onSelect,
  onSearch,
  mutationLoading = false,
  mutationLoadingLabel = 'Loading..',
  empty,
  errorMessage,
  onCancel,
  onClear,
  multiple = false,
  allowSelectNone = false,
  columns,
  data,
  totalCount,
  queryPageSize,
  queryPageIndex,
  queryPaginationDispatch,
  queryIsFetching,
  queryIsLoading,
  searchPlaceholder,
  title,
  titleButton,
  getRowId,
  initialSelected = [],
  selectionTitle,
  exclude,
  passResourceOnSelect = false,
  onComplete,
  onCompleteLabel,
  open,
  contentOnly,
}: QuickFinderGenericDialogProps) => {
  // used to display on complete message if passed
  const [showComplete, toggleShowComplete] = useToggleReducer();

  // custom table state reducer to handle exclusions from toggle all rows
  // - default behavior
  const tableReducer = (newState, action, prevState) => {
    switch (action.type) {
      case 'toggleAllPageRowsSelected':
        const excludeIds = exclude?.map(excluded => excluded.id) ?? [];

        // remove excluded selected rows
        let selectedRowIds = pickBy(
          newState.selectedRowIds,
          (value, key) => !excludeIds.includes(key),
        );

        // if the state is the same as the previous, we
        //  - know it was a deselect toggle and clear the remaining ids
        //  - from this page (this check to toggle false is normally based on
        //  - if all rows are selected, which is never true with excludes,
        //  - so we need to manually remove them)
        if (isEqual(selectedRowIds, prevState.selectedRowIds)) {
          const pageIds = data.map(row => row.id);
          selectedRowIds = pickBy(
            selectedRowIds,
            (value, key) => !pageIds.includes(key),
          );
        }

        return { ...newState, selectedRowIds };
    }

    return newState;
  };

  // only triggered if multiple is false
  const handleSelect = selected => async () => {
    await onSelect([selected]);
    if (onComplete && !errorMessage) toggleShowComplete();
  };

  // table state
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      // we need to pass a custom state reducer to handle excludes in row toggle all row selection
      stateReducer: tableReducer,
      columns: columns,
      data: data,
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        // add initially selected rows
        selectedRowIds: Object.assign(
          {},
          ...removeExcludes(initialSelected, exclude).map(selected => ({
            [selected]: true,
          })),
        ),
      },
      getRowId,
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalCount ? Math.ceil(totalCount / queryPageSize) : null,
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        multiple // add multiple select column
          ? {
              id: 'selection',
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <Checkbox
                  sx={{ fontSize: 20 }}
                  {...getToggleAllPageRowsSelectedProps()}
                />
              ),
              Cell: ({ row }) => {
                // override react-table props if id is excluded
                // to handle checked and indeterminate state
                const excluded = exclude?.find(
                  excluded => excluded.id === row.id,
                );
                const props = excluded
                  ? {
                      checked: excluded.checked,
                      indeterminate: excluded.indeterminate,
                    }
                  : row.getToggleRowSelectedProps();

                return (
                  <Tooltip
                    arrow
                    title={excluded?.reason ?? ''}
                    placement={'left'}
                  >
                    <Box>
                      <Checkbox
                        sx={{ fontSize: 20 }}
                        {...props}
                        disabled={!!excluded}
                      />
                    </Box>
                  </Tooltip>
                );
              },
            }
          : // add single select column
            {
              id: 'selection',
              Cell: ({ row }) => {
                // disable select if row is currently selected
                const { checked } = row.getToggleRowSelectedProps();
                return (
                  <Button
                    variant={'outline'}
                    size={'sm'}
                    onClick={handleSelect(
                      passResourceOnSelect ? row.original : row.id,
                    )}
                    disabled={checked}
                  >
                    Select{checked ? 'ed' : ''}
                  </Button>
                );
              },
            },
        ...columns,
      ]);
    },
  );

  // select when multiple is true
  const handleMultiSelect = async () => {
    await onSelect(Object.keys(selectedRowIds));
    if (onComplete && !errorMessage) toggleShowComplete();
  };

  // only for multi select
  const selectedIds = Object.keys(selectedRowIds);

  const handleClear = async () => {
    if (onClear) await onClear();
    if (onComplete && !errorMessage) toggleShowComplete();
  };

  return (
    <QuickFinderDialogShell
      // open={open}
      onCancel={onCancel}
      title={title}
      titleButton={titleButton}
      selectionTitle={selectionTitle}
      loading={mutationLoading}
      loadingLabel={mutationLoadingLabel}
      showComplete={showComplete}
      contentOnly={contentOnly}
    >
      <div className={'flex flex-col overflow-y-auto items-center space-y-1'}>
        {showComplete ? (
          <div
            className={
              'flex items-center space-x-1 my-4 justify-center text-lg font-medium'
            }
          >
            <CheckCircleIcon className={'text-green-400 text-2xl'} />
            <div>{onCompleteLabel}</div>
          </div>
        ) : (
          <>
            <SlateSearch
              loading={queryIsFetching}
              onSearch={onSearch}
              placeholder={searchPlaceholder}
            />

            {queryIsLoading ? (
              <div
                style={{ display: 'grid', placeItems: 'center', padding: 4 }}
              >
                <CircularProgress variant={'indeterminate'} />
                <span>Loading results...</span>
              </div>
            ) : (
              <div className={'p-2 flex flex-col items-center'}>
                <DefaultTable
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  emptyRowCount={pageSize - page.length}
                  queryPaginationDispatch={queryPaginationDispatch}
                  exclude={exclude}
                />

                {!totalCount ? (
                  <div
                    style={{
                      padding: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#fcfcfc',
                      // border: '0.5px solid #e6e6e6',
                      borderTop: 0,
                    }}
                  >
                    {empty ? empty : 'No Results'}
                  </div>
                ) : null}
                {/* only show pagination if more than one page exists */}
                {totalCount > queryPageSize ? (
                  <DefaultTablePagination
                    totalRowCount={totalCount}
                    rowsPerPage={queryPageSize}
                    pageIndex={queryPageIndex}
                    queryPaginationDispatch={queryPaginationDispatch}
                    gotoPage={gotoPage}
                    setPageSize={setPageSize}
                  />
                ) : null}
              </div>
            )}
          </>
        )}
      </div>
      <QuickFinderDialogActions
        errorMessage={errorMessage}
        multiple={multiple}
        allowSelectNone={allowSelectNone}
        showComplete={showComplete}
        onSelect={showComplete ? onComplete! : handleMultiSelect}
        onCancel={onCancel}
        onClear={
          initialSelected.length
            ? onClear
              ? handleClear
              : undefined
            : undefined
        }
        selectedIds={selectedIds}
      />
    </QuickFinderDialogShell>
  );
};

export default QuickFinderDialogWrapper;

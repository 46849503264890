import {
  createHistoryPlugin,
  createPlateUI,
  createPlugins,
  createReactPlugin,
  Plate,
  TNode,
} from '@udecode/plate';
import { ELEMENT_TEMPORAL_RULE } from 'app/components/SchedulePlateEditor/components/TemporalRuleElement/createTemporalRulePlugin';
import React from 'react';
import CommentMark from './components/CommentMark/CommentMark';
import {
  createTemporalRuleElement,
  TemporalRuleElement,
} from './components/TemporalRuleElement';
import { createCommentPlugin, MARK_COMMENT } from './plugins/commentDecoration';

interface SchedulePlateEditorProps {
  id: string;
  initialValue: TNode[] | undefined;
  onChange?: (value: TNode[]) => void;
  readOnly?: boolean;
}

// const components = createPlateComponents({
//   [ELEMENT_TEMPORAL_RULE]: TemporalRuleElement,
//   [MARK_COMMENT]: CommentMark,
// });
//
// const options = createPlateOptions();
//
// const plugins = [
//   createReactPlugin(),
//   createHistoryPlugin(),
//   createTemporalRuleElement(),
//   createCommentPlugin(),
// ];

const plugins = createPlugins(
  [
    createReactPlugin(),
    createHistoryPlugin(),
    createTemporalRuleElement(),
    createCommentPlugin(),
  ],
  {
    // Plate components
    components: createPlateUI({
      [ELEMENT_TEMPORAL_RULE]: TemporalRuleElement,
      [MARK_COMMENT]: CommentMark,
    }),
  },
);

const SchedulePlateEditor = ({
  id,
  initialValue,
  onChange,
  readOnly = false,
}: SchedulePlateEditorProps) => {
  return (
    <Plate
      id={id}
      initialValue={initialValue}
      onChange={onChange}
      plugins={plugins}
      editableProps={{ readOnly }}
      key={id}
    />
  );
};

export default SchedulePlateEditor;

const useStyles = props => ({
  segment: {
    position: 'relative',
    marginTop: 1,
  },

  buyNumber: {
    color: theme => theme.palette.primary.main,
    // fontSize: 16,
    textDecoration: 'none',
    fontWeight: 400,
  },
  chipContainer: {
    margin: '8px 0',
  },
  chip: {
    margin: theme => theme.spacing(0.5),
  },
  formControl: {
    margin: theme => theme.spacing(1),
    minWidth: 120,
  },
});

export default useStyles;

const useStyles = props => ({
  mainCategory: {
    marginBottom: 2,
    display: props.anyChildrenMatch ? 'block' : 'none',
  },
  subitem: {
    width: '98%', // prevents overflow from triggering if set to 100% volume
    display: props.doesMatch ? 'block' : 'none',
    marginBottom: 2,
    '&:not(:last-child)': {},
  },
});

export default useStyles;

// stateless component
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { Box, CircularProgress, Tooltip, TooltipProps } from '@mui/material';

export interface StatusTooltipProps {
  status: 'loading' | 'success' | 'error' | 'idle';
  open: boolean;
  children: ReactElement<any, any>;
  placement: TooltipProps['placement'];
}

const StatusTooltip = ({
  status,
  open,
  children,
  placement,
}: StatusTooltipProps) => {
  return (
    <Tooltip
      title={
        status === 'loading' ? (
          <CircularProgress size={24} variant={'indeterminate'} />
        ) : status === 'success' ? (
          <CheckIcon sx={{ fontSize: 22 }} color={'success'} />
        ) : (
          <ClearIcon sx={{ fontSize: 22 }} color={'error'} />
        )
      }
      arrow
      placement={placement}
      open={open}
    >
      {children}
    </Tooltip>
  );
};

export default StatusTooltip;

import {
  AnimationDialog,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { getTimeZones } from '@vvo/tzdb';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { userQueryKeys, useUserQuery } from 'app/hooks/queries/user';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

const TIMEZONE_LIST = getTimeZones().map(tz => ({
  label: `${tz.name.replace('_', ' ')} (${tz.abbreviation})`,
  value: tz.name,
}));

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface UserTimezoneForm {
  timezone?: { label: string; value: string };
}

// interface declaring which props are required/allowed
interface UserTimezoneDialogContentProps {
  userId: string;
  onCancel: () => void;
  onComplete: () => void;
}

interface UserTimezoneDialogProps {
  ContentProps: UserTimezoneDialogContentProps;
  DialogProps: AnimationDialogProps;
}

const UserTimezoneDialog = ({
  ContentProps,
  DialogProps,
}: UserTimezoneDialogProps) => {
  return (
    <AnimationDialog maxWidth={'xs'} {...DialogProps}>
      <UserTimezoneDialogContent {...ContentProps} />
    </AnimationDialog>
  );
};

const UserTimezoneDialogContent = ({
  userId,
  onCancel,
  onComplete,
}: UserTimezoneDialogContentProps) => {
  const {
    data: user,
    isLoading: userIsLoading,
    refetch: refetchUser,
  } = useUserQuery(userId);
  const updateUser = useUpdateUserPartial();
  const queryClient = useQueryClient();

  const formMethods = useForm<UserTimezoneForm>();
  const { handleSubmit, control, reset, setError } = formMethods;

  const onSubmit = async (userForm: UserTimezoneForm) => {
    const { timezone } = userForm;

    try {
      const resp = await updateUser.mutateAsync(
        {
          id: userId,
          timezone: timezone?.value ?? null,
        },
        // {
        //   onSuccess: () => {
        //     refetchUser();
        //     onComplete();
        //   },
        // },
      );

      // const resp = await toast.promise(updateUserPromise, {
      //   pending: 'Updating user timezone...',
      //   error: 'Failed to update user timezone.',
      //   success: 'User timezone updated!',
      // });

      if (resp.status === 'success') {
        refetchUser();
      }
    } catch (e: any) {
      parseAndSetKazooMutationErrors({ response: e.response, setError });
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <GenericMutationDialogContent
      onCancel={onCancel}
      onComplete={onComplete}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'User timezone has been updated'}
      isLoadingLabel={'Updating user timezone...'}
      title={'Edit User Timezone'}
      mutation={updateUser}
      formMethods={formMethods}
      queryIsLoading={userIsLoading}
      queryLabel={'Loading user details'}
    >
      <Controller
        name="timezone"
        control={control}
        defaultValue={TIMEZONE_LIST.find(tz => tz.value === user?.doc.timezone)}
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            options={TIMEZONE_LIST}
            getOptionLabel={option => option.label}
            value={value}
            onChange={(event, value) => {
              onChange(value);
            }}
            renderInput={params => {
              // @ts-ignore
              params.InputLabelProps.shrink = true;
              return (
                <TextField
                  {...params}
                  label={'Timezone'}
                  placeholder={'select'}
                />
              );
            }}
          />
        )}
      />
    </GenericMutationDialogContent>
  );

  // return (
  //   <Dialog open={true} fullWidth maxWidth={'sm'}>
  //     {userIsLoading ? (
  //       <DialogInProgress title={'Loading user...'} />
  //     ) : updateUser.isLoading ? (
  //       <DialogInProgress title={'Updating user timezone...'} />
  //     ) : (
  //       <>
  //         <DialogTitle>Edit User Timezone</DialogTitle>
  //         <Divider />
  //         <>
  //           <DialogContent>
  //             {user ? (
  //               <Controller
  //                 name="timezone"
  //                 control={control}
  //                 defaultValue={TIMEZONE_LIST.find(
  //                   tz => tz.value === user.doc.timezone,
  //                 )}
  //                 // rules={{ required: true }}
  //                 render={({ field: { value, onChange } }) => (
  //                   <Autocomplete
  //                     options={TIMEZONE_LIST}
  //                     getOptionLabel={option => option.label}
  //                     value={value}
  //                     onChange={(event, value) => {
  //                       onChange(value);
  //                     }}
  //                     renderInput={params => {
  //                       // @ts-ignore
  //                       params.InputLabelProps.shrink = true;
  //                       return (
  //                         <TextField
  //                           {...params}
  //                           label={'Timezone'}
  //                           placeholder={'select'}
  //                         />
  //                       );
  //                     }}
  //                   />
  //                 )}
  //               />
  //             ) : (
  //               <Typography color={'error'}>Invalid User ID.</Typography>
  //             )}
  //           </DialogContent>
  //           <Divider />
  //           <DialogActions sx={{ justifyContent: 'space-between' }}>
  //             <Button
  //               variant={'outlined'}
  //               color={'error'}
  //               onClick={handleCancel}
  //             >
  //               Cancel
  //             </Button>
  //             {updateUser.error ? (
  //               <Typography color={'error'}>
  //                 {`${updateUser.error.message} ${
  //                   updateUser.error.response
  //                     ? `: ${updateUser.error.response?.statusText}`
  //                     : ''
  //                 }`}
  //               </Typography>
  //             ) : null}
  //             <Button
  //               variant={'outlined'}
  //               color={'success'}
  //               disabled={!isDirty}
  //               onClick={handleSubmit(onSubmit)}
  //             >
  //               Update
  //             </Button>
  //           </DialogActions>
  //         </>
  //       </>
  //     )}
  //   </Dialog>
  // );
};

export default UserTimezoneDialog;

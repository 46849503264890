const scheduleQueryKeys = {
  all: ['schedule'] as const,
  list: (
    orderBy: any,
    filters: any,
    queryPageSize: number,
    queryPageIndex: number,
  ) =>
    [
      ...scheduleQueryKeys.all,
      'list',
      orderBy,
      filters,
      queryPageSize,
      queryPageIndex,
    ] as const,
  // byId: (id: string) => [...scheduleQueryKeys.all, id] as const,
};

export default scheduleQueryKeys;

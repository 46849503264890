import { useCreditCardAddDialog } from 'app/components/CreditCardAddDialog';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { CancelSubDialog } from 'app/pages/admin/Billing/Home/components/CancelSubDialog';
import { CancelNowSubDialog } from 'app/pages/admin/Billing/Home/components/CancelNowSubDialog';
import { UndoCancelSubDialog } from 'app/pages/admin/Billing/Home/components/UndoCancelSubDialog';
import { useToggleReducer } from 'app/utilities';
import moment from 'moment/moment';
import React, { useCallback } from 'react';
import constants from 'app/constants';
import { useNavDetails } from 'app/hooks/general/useNavDetails';
import { Button } from 'app/design-lib';
import { Plus as AddIcon } from 'iconoir-react';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import {
  useAccountQuery,
  useAccountStripeCustomerQuery,
} from 'app/hooks/queries/account';
import SimpleBar from 'simplebar-react';

import { Plan } from './components/Plan';
import { Cards } from './components/Cards';
import { Invoices } from './components/Invoices';
import { Integrations } from './components/Integrations';
import { sub } from 'date-fns';

function AdminBillingHome() {
  // useNavDetails({
  //   sidebar_key: constants.nav.ADMIN_BILLING.sidebar_key,
  //   crumbs: [
  //     constants.nav.ADMIN.crumb,
  //     constants.nav.ADMIN_BILLING.crumb,
  //     // constants.nav.ADMIN_CALL_FINDER.crumb,
  //   ],
  // });

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });
  const { data: account, refetch: refetchAccount } = useAccountQuery();
  const {
    data: stripeData,
    isLoading,
    isFetching,
    refetch,
  } = useAccountStripeCustomerQuery();
  const {
    toggleOpen: toggleOpenCreditCardAddDialog,
    Dialog: CreditCardAddDialog,
    DialogProps: CreditCardAddDialogProps,
  } = useCreditCardAddDialog({ initialOpen: false });

  const handleCardAdded = () => {
    refetch();
    toggleOpenCreditCardAddDialog();
  };
  // console.log('customer data:', isLoading, stripeData);
  const [open, toggleOpenWrapped] = useToggleReducer();
  const [openImmediate, toggleOpenImmediateWrapped] = useToggleReducer();
  const toggleOpen = useCallback(() => {
    // refetch info when closed (this is not ideal...we aren't updating the UI until after they close the dialog...meaning it shows the wrong data for a few seconds)
    if (open) {
      refetch();
      refetchAccount();
    }
    toggleOpenWrapped();
  }, [open]);
  const toggleOpenImmediate = useCallback(() => {
    // refetch info when closed (this is not ideal...we aren't updating the UI until after they close the dialog...meaning it shows the wrong data for a few seconds)
    if (openImmediate) {
      refetch();
      refetchAccount();
    }
    toggleOpenImmediateWrapped();
  }, [openImmediate]);

  // const subscriptions = stripeData?.data?.subscriptions;
  // const primarySub = subscriptions?.data?.find(
  //     s => s.metadata.subscription_type === 'primary',
  // );

  // const isExpired =primarySub?.trial_end
  //   ? moment()
  //       .utc()
  //       .diff(primarySub?.trial_end * 1000, 'seconds') > 0
  //   : true;

  // const isTrial = primarySub?.status === 'trialing' && !isExpired;

  return (
    <SettingsPageWrapper
      isLoading={isLoading}
      loadingLabel={'Loading Invoices...'}
      label={'Billing'}
    >
      {isLoading ? null : (
        <>
          <Home stripeData={stripeData} refetch={refetch} />
          {/* TODO: this is temporary for iOS! */}
          {/*
          {(!isTrial ||
            account?.doc?.iap ||
            account?.doc?.stripe_customer_id === 'test_user') &&
          !account?.doc?.to_delete ? (
            <>
              <CancelSubDialog open={open} onClose={toggleOpen} />
              <div>
                <Button
                  size={'sm'}
                  onClick={toggleOpen}
                  variant={'ghost'}
                  color={'negative'}
                  className="mt-12"
                >
                  Delete account at end of billing period
                </Button>
              </div>

              <CancelNowSubDialog
                open={openImmediate}
                onClose={toggleOpenImmediate}
              />
              <div>
                <Button
                  size={'sm'}
                  onClick={toggleOpenImmediate}
                  variant={'ghost'}
                  color={'negative'}
                  // className="mt-2"
                >
                  Delete account immediately
                </Button>
              </div>
            </>
          ) : null}
*/}
          {account?.internal?.to_delete ? (
            <>
              <UndoCancelSubDialog open={open} onClose={toggleOpen} />
              <div>
                <Button
                  size={'sm'}
                  onClick={toggleOpen}
                  variant={'ghost'}
                  color={'negative'}
                  className="mt-12"
                >
                  {/*Undo Account Deletion*/}
                  Reactivate Account
                </Button>
              </div>
            </>
          ) : null}
        </>
      )}
    </SettingsPageWrapper>
  );
}

const Home = ({ stripeData, refetch }) => {
  const { data: accountData } = useAccountQuery();
  const account = stripeData?.data?.account ?? accountData;
  const customer = stripeData?.data?.customer;
  const cards = stripeData?.data?.cards;
  const invoices = stripeData?.data?.invoices;
  const subscriptions = stripeData?.data?.subscriptions;
  const nextInvoice = stripeData?.data?.nextInvoice;
  const plan = stripeData?.data?.plan;

  const primarySub = subscriptions?.data?.find(
    s => s.metadata.subscription_type === 'primary',
  );

  return (
    <>
      <Plan
        account={account}
        customer={customer}
        primarySub={primarySub}
        plan={plan}
        refetch={refetch}
      />
      <Cards customer={customer} cards={cards} refetch={refetch} />
      <Invoices
        customer={customer}
        nextInvoice={nextInvoice}
        invoices={invoices}
        refetch={refetch}
      />
      {account?.internal ? (
        <Integrations account={account} customer={customer} refetch={refetch} />
      ) : null}

      {/* <br />
      <div>
        <pre>
          <code>{JSON.stringify(customer, null, 2)}</code>
        </pre>
      </div> */}
    </>
  );
};

export default AdminBillingHome;

import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import { buildDeleteDefault } from 'app/sdk/utils/kazoo';

///////////////
// TODO: jsDoc!
///////////////

const exports = {
  // Queries
  // - graphql
  query: {
    // TODO: pagination
    parked: (vars, fetchOptions) =>
      fetchFromGraphql('callsParked', vars, fetchOptions),
  },

  // Mutations
  mutate: {},
};

export default exports;

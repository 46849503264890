import { Link as RouterLink, useHistory } from 'react-router-dom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'warning' | 'primary' | 'error' | 'success';
  to?: string;
}

const Button = ({
  to,
  variant = 'primary',
  className,
  children,
  ...buttonProps
}: ButtonProps) => {
  // when adding variants make sure to add them to purge safelist in
  // tailwind.config.js
  // classes defined in tailwind.css
  const _className = `button-old-${variant} ${className}`;

  // button link
  if (to) {
    return (
      <RouterLink to={to} className={`no-underline ${_className}`}>
        {children}
      </RouterLink>
    );
  }

  return (
    <button className={_className} {...buttonProps}>
      {children}
    </button>
  );
};

export default Button;

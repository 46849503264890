import { Tooltip } from '@mui/material';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { Link, Typography } from 'app/design';
import { Alert, Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { useAccountQuery } from 'app/hooks/queries/account';
import { HoldMusicDevice } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/HoldMusicDevice';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import { Cancel, EditPencil, InfoEmpty } from 'iconoir-react';
import * as React from 'react';
import { Device } from 'types/device';
import copy from 'copy-to-clipboard';

interface DeviceSipSetupBriaProps {
  device: Device;
}

const DeviceSipSetupBria = ({ device }: DeviceSipSetupBriaProps) => {
  const { data: account } = useAccountQuery();
  const [showPassword, togglePassword] = useToggleReducer();

  if (device.doc.subtype !== 'bria') return null;

  return (
    <SettingsPanel
      leftChild={
        <Setting label={'Setup Instructions'} body={'Get started easily'} />
      }
      rightChild={
        <div className={'flex flex-col space-y-2'}>
          <Table>
            <TableRow showBorder>
              <TableCell className={`font-medium w-32`}>Username</TableCell>
              <TableCell>
                <CopyField value={device.doc?.sip?.username} />
              </TableCell>
            </TableRow>
            <TableRow showBorder>
              <TableCell className={`font-medium w-32`}>Password</TableCell>
              <TableCell className={`flex space-x-2 items-center`}>
                {showPassword ? (
                  <CopyField
                    value={showPassword ? device.doc.sip?.password : ''}
                  />
                ) : null}
                <button
                  className={
                    'no-underline border-none bg-transparent p-0 cursor-pointer text-content-accent'
                  }
                  onClick={togglePassword}
                >
                  {showPassword ? 'hide' : 'show'}
                </button>
              </TableCell>
            </TableRow>
            <TableRow showBorder={false}>
              <TableCell className={`font-medium w-32`}>Realm</TableCell>
              <TableCell>
                <CopyField value={account?.doc.realm} />
              </TableCell>
            </TableRow>
          </Table>
          <Alert
            icon={<InfoEmpty fr={undefined} />}
            label={'Need help setting up your device?'}
            body={`Contact support and we'll get you squared away`}
            color={'accent'}
            reverse
          />
        </div>
      }
    />
  );

  // return (
  //   <div className="whitespace-nowrap font-medium font-sans">
  //     <div className="text-lg font-bold">SIP Credentials</div>
  //     <div className={'pl-1 mt-1'}>
  //       <div>
  //         <span className="font-bold text-neutral-500">username:</span>{' '}
  //         <CopyField value={device.doc?.sip?.username} />
  //       </div>
  //       <div className="flex space-x-1 w-96">
  //         <span className="font-bold text-neutral-500">password:</span>
  //         <CopyField value={showPassword ? device.doc.sip?.password : ''} />
  //         <button
  //           className={
  //             'no-underline border-none bg-transparent p-0 font-semibold cursor-pointer text-content-accent'
  //           }
  //           onClick={togglePassword}
  //         >
  //           {showPassword ? 'hide' : 'show'}
  //         </button>
  //       </div>
  //       <div>
  //         <span className="font-bold text-neutral-500">realm:</span>{' '}
  //         <CopyField value={account?.doc.realm} />
  //       </div>
  //     </div>
  //     <Alert
  //       label={'Need help setting up your device?'}
  //       body={`Contact support and we'll get you squared away`}
  //       color={'accent'}
  //       reverse
  //     />
  //   </div>
  // );
};

const CopyField = ({ value }) => {
  return (
    <Tooltip arrow placement={'left'} title={'Click to copy to clipboard'}>
      <span
        className={'cursor-pointer'}
        onClick={() => {
          copy(value);
        }}
      >
        {value}
      </span>
    </Tooltip>
  );
};

export default DeviceSipSetupBria;

const extensionQueryKeys = {
  all: ['extension'] as const,
  list: (skip: number, take: number, sortBy: any, filters: any, mask: any) =>
    [
      ...extensionQueryKeys.all,
      'list',
      skip,
      take,
      sortBy,
      filters,
      mask,
    ] as const,
  // byId: (id: string) => [...extensionQueryKeys.all, id] as const,
};

export default extensionQueryKeys;

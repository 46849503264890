import * as React from 'react';
import { Suspense } from 'react';
import { Sidebar2 } from 'app/components/Sidebar2';
import { useMediaQuery } from '@mui/material';

function LayoutV2({ children }) {
  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  return (
    <div className="flex text-content font-sans">
      {isMobile ? null : <Sidebar2 />}
      <div
        // component="main"
        // sx={{
        //   flexGrow: 1,
        //   p: 3,
        //   minWidth: 0,
        // }}
        className="flex-1"
      >
        <Suspense
          fallback={
            <div className="grid h-screen place-items-center">
              <span>Loading...</span>
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600">
                <span className="hidden">Loading...</span>
              </div>
              {/* <CircularProgress size={30} /> */}
            </div>
          }
        >
          {children}
        </Suspense>
      </div>
    </div>
  );
}

export default LayoutV2;

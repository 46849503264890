import { useCallback } from 'react';
import { Text } from 'slate';

export const useSearchDecorations = () =>
  useCallback(([node, path]) => {
    const ranges: any[] = [];

    if (Text.isText(node)) {
      const { text }: { text: string } = node;

      // split text into "statements" ie - search values seperated by spaces
      const statements = text.split(' ');

      const fixedStatements: string[] = [];

      // iterate over statements to fix splits within quotations -
      //  - ex 'owner:"Adrian Admin"
      for (let i = 0; i < statements.length; i++) {
        let statement = statements[i];

        // check if statement is split/incomplete (only has 1 '"")
        if ((statement.match(/"/g) || []).length === 1) {
          // incomplete statement, append succeeding statements until complete
          for (let j = i + 1; j < statements.length; j++) {
            statement = statement + ' ' + statements[j];
            i++;
            if (statements[j].includes('"')) {
              break;
            }
          }
        }

        // add completed statement
        fixedStatements.push(statement);
      }

      // iterate over each statement to set relevant marks on
      // - keywords and values if present
      let previousOffset = 0;
      fixedStatements.forEach(sp => {
        // is keyword/value statement?
        if (sp.includes(':')) {
          // keyword/value statement

          // split keyword and value
          const split = sp.split(':');

          // offsets of keyword in text
          const keywordStart = sp.indexOf(split[0]) + previousOffset;
          const keywordEnd = keywordStart + split[0].length + 1; // +1 to account for ':'

          // set keyword mark
          ranges.push({
            anchor: { path, offset: keywordStart },
            focus: { path, offset: keywordEnd },
            keyword: true,
          });

          // offsets of value in text
          const valueStart = keywordEnd;
          const valueEnd = valueStart + split[1].length;

          // set value mark to keyword (see MarkValue.tsx for use)
          ranges.push({
            anchor: { path, offset: valueStart },
            focus: { path, offset: valueEnd },
            value: split[0],
          });

          // increment text offset
          previousOffset = valueEnd + 1; // +1 to account for space
        } else {
          // not a keyword/value statement

          // increment text offset
          previousOffset += sp.length + 1; // +1 to account for space
        }
      });
    }

    return ranges;
  }, []);

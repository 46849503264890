import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import { TextField } from 'app/design-lib';
import { UserDetailsDialog } from 'app/pages/admin/Users/View/components/UserSettingsCard/components/UserDetailsDialog';
import { startCase } from 'lodash';
import * as React from 'react';
import {
  AnimationDialog,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
} from 'app/design';
import useDevMode from 'app/utilities/useDevMode';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { CallerIdBulkUsers } from 'app/components/ListUsers/components/BulkActions/components/CallerIdBulkUsers';
import { DeleteBulkUsers } from 'app/components/ListUsers/components/BulkActions/components/DeleteBulkUsers';
import { HoldMusicBulkUsers } from 'app/components/ListUsers/components/BulkActions/components/HoldMusicBulkUsers';
import { ReleaseDevicesBulkUsers } from 'app/components/ListUsers/components/BulkActions/components/ReleaseDevicesBulkUsers';
import SuspendBulkUsers from 'app/components/ListUsers/components/BulkActions/components/SuspendBulkUsers/SuspendBulkUsers';
import { TimezoneBulkUsers } from 'app/components/ListUsers/components/BulkActions/components/TimezoneBulkUsers';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { usePipesQuery } from 'app/pages/v2/common/sdk';
import { Emoji } from 'emoji-picker-react';
import { useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';
import { store } from 'store';
import { User, UserPrivLevel } from 'types/user';
import { useImmer } from 'use-immer';
import { useHistory } from 'react-router-dom';

interface BulkActionsProps {
  selectedRows?: User[];
  onComplete: () => void;
  toggleSelect?: (set?: boolean) => void;
  toggleRowSelected?: (id: string, set: boolean) => void;
  vertical?: boolean;
}

const BulkActionsUsers = ({
  selectedRows: selectedUsers,
  toggleSelect,
  vertical,
  onComplete,
  toggleRowSelected,
}: BulkActionsProps) => {
  const [showDialog, setShowDialog] = useImmer<null | string>(null);
  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();
  const { devMode } = useDevMode();
  const router = useHistory();
  const menuItems = useMemo(() => {
    // check if only a single user ADMIN is selected, which removes options
    // available to user (to prevent admin from suspending/deleting self)
    const selectedUserDoc = selectedUsers?.[0].doc;
    const authenticatedSelect =
      selectedUsers?.length === 1 &&
      selectedUserDoc?.priv_level === UserPrivLevel.Admin &&
      selectedUserDoc.id === userData?.id;

    return [
      // {
      //   text: 'Set External Caller ID Number',

      //   onClick: () => {
      //     setShowDialog('external');
      //   },
      // },
      // {
      //   text: 'Set e911 Caller ID Number',

      //   onClick: () => {
      //     setShowDialog('emergency');
      //   },
      // },
      // {
      //   text: 'Set Hold Music',

      //   onClick: () => {
      //     setShowDialog('hold_music');
      //   },
      // },
      {
        text: 'User Details',
        onClick: () => {
          setShowDialog('details');
        },
      },
      {
        text: 'View Lines',
        tooltip: <ShowLines user={selectedUsers![0]} />,
        onClick: () => {
          setShowDialog('lines');
        },
      },
      {
        text: 'View Personal Line',
        onClick: () => {
          router.push(
            `/v2/settings/pipes/${
              selectedUsers![0].Pipes.find(
                p => p.key === selectedUsers![0].doc.presence_id,
              ).id
            }`,
          );
        },
      },

      {
        text: 'Set Timezone',

        onClick: () => {
          setShowDialog('timezone');
        },
      },
      {
        text: 'Release Devices to Pool',

        onClick: () => {
          setShowDialog('release');
        },
      },
      {
        tooltip: authenticatedSelect
          ? 'you cannot suspend yourself'
          : undefined,
        text: 'Suspend',
        disabled: authenticatedSelect,
        onClick: async e => {
          setShowDialog('suspend');
        },
      },
      {
        tooltip: authenticatedSelect
          ? 'you cannot unsuspend yourself'
          : undefined,
        text: 'Unsuspend',
        disabled: authenticatedSelect,
        onClick: () => {
          setShowDialog('unsuspend');
        },
      },
      {
        tooltip: authenticatedSelect ? 'you cannot delete yourself' : undefined,
        text: 'Delete',
        disabled: authenticatedSelect, // : true, // authenticatedSelect,
        onClick: async e => {
          setShowDialog('delete');
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleCancel = () => {
    setShowDialog(null);
  };

  const handleOnComplete = () => {
    setShowDialog(null);
    if (toggleSelect && showDialog === 'delete') toggleSelect(false);
    onComplete();
  };

  return (
    <>
      <LinesDialogWrapper
        open={showDialog === 'lines'}
        user={selectedUsers![0]}
        onComplete={handleOnComplete}
        // onCancel={handleCancel}
      />

      <CallerIdBulkUsers
        open={showDialog === 'external' || showDialog === 'emergency'}
        type={showDialog!}
        selectedRows={selectedUsers}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
      />

      <HoldMusicBulkUsers
        open={showDialog === 'hold_music'}
        selectedRows={selectedUsers}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
      />

      <TimezoneBulkUsers
        open={showDialog === 'timezone'}
        selectedRows={selectedUsers}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
      />

      <DeleteBulkUsers
        open={showDialog === 'delete'}
        selectedRows={selectedUsers}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
        toggleRowSelected={toggleRowSelected!}
      />
      <ReleaseDevicesBulkUsers
        open={showDialog === 'release'}
        selectedRows={selectedUsers}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
      />
      <UserDetailsDialog
        ContentProps={{
          userId: selectedUsers?.[0]?.id!,
          onCancel: handleCancel,
          onComplete: handleOnComplete,
          initData: selectedUsers?.[0],
        }}
        DialogProps={{ open: showDialog === 'details', onClose: handleCancel }}
      />
      <SuspendBulkUsers
        open={!!showDialog?.includes('suspend')}
        selectedRows={selectedUsers}
        onComplete={handleOnComplete}
        onCancel={handleCancel}
        suspend={showDialog === 'suspend'} // unsuspend otherwise
      />

      <ButtonDropdownMenu
        label="Actions"
        variant={vertical ? 'default' : 'button'}
        vertical={vertical}
        menuItems={menuItems}
        menuItemsDependencies={[menuItems]}
      />
    </>
  );
};

const ShowLines = ({ user }: { user: User }) => {
  const pipes = user.Pipes.filter(pipe => pipe.type !== 'personal');

  return (
    <div>
      {pipes.length
        ? pipes.map((pipe, index) => (
            <div
              className={`whitespace-nowrap items-center flex space-x-2 border-solid border-0 ${
                index < pipes.length - 1 ? 'border-b' : ''
              } py-2 border-border-neutral`}
            >
              <div className="px-1">
                <Emoji unified={pipe.metadata?.emoji || '1f937'} size={25} />
              </div>
              <div className="flex-auto min-w-0 pr-1">
                <div className="truncate">
                  <span className="font-bold">{pipe.metadata?.name}</span>
                </div>
                <div>
                  <PhoneNumberDisplay ptn={pipe.key} />
                </div>
              </div>
            </div>
          ))
        : 'User does not have access to any lines'}
    </div>
  );
};

const LinesDialogWrapper = (props: any) => {
  return (
    <AnimationDialog fullWidth maxWidth={'sm'} open={props.open}>
      <LinesDialogContent {...props} />
    </AnimationDialog>
  );
};

const LinesDialogContent = ({
  user,
  selectedRows: selectedUsers,
  onComplete,
  onCancel,
}: any) => {
  return (
    <>
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <ShowLines user={user} />
        <br />
        <div className="text-base text-center">
          Add Users to Lines by visiting:
          <br />
          <strong>Settings &gt; Lines &gt; (Choose Line) &gt; Add User</strong>
        </div>
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        saveLabel={'Done'}
        // onCancel={
        //   error ? handleDone : updateUser.isSuccess ? undefined : onCancel
        // }
        // cancelLabel={error ? 'Close' : undefined}
        onSave={onComplete}
        // onSave={
        //   error
        //     ? undefined
        //     : updateUser.isSuccess
        //     ? handleDone
        //     : handleSuspend
        // }
        // errorMessage={error?.message}
      />
    </>
  );
};

export default BulkActionsUsers;

import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import SimpleBar from 'simplebar-react';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import {
  Box,
  Button,
  Typography,
  Grid,
  AnimationDialog,
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
} from 'app/design';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import useToggleReducer from 'app/utilities/useToggleReducer';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { CreateScheduleButton } from 'app/components/ListSchedules/components/CreateScheduleButton';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import { EditScheduleLink } from 'app/components/ListSchedules/components/EditScheduleLink';

function Schedules() {
  // useRouteMatch() // no argument, returns current match? (but that is the PARENT Route, not the Setting Route...)

  const {
    data,
    isLoading: schedulesLoading,
    refetch,
  } = useListSchedulesQuery({
    take: 500,
    filters: {},
    options: {},
  });
  const { callflows: schedules, totalCount = 0 } = data ?? {};

  const [openCreateDialog, toggleOpenCreateDialog] = useToggleReducer(false);
  const [editSchedule, editScheduleSet] = React.useState(null);

  return (
    <>
      {editSchedule ? (
        <EditScheduleDialog
          // pipe={pipe}
          schedule={editSchedule}
          onCancel={() => {
            editScheduleSet(null);
            refetch();
          }}
          // onSend={toggleOpenSendDialog}
        />
      ) : null}
      <div
        className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
      >
        <div className="overflow-hidden relative h-screen">
          <SimpleBar style={{ height: '100%' }}>
            <div>
              <div className="">
                <div className="text-2xl">Schedules</div>
                <div className="flex justify-between">
                  <div className="text-md mb-2 text-neutral-500">
                    Automatically route incoming calls depending on a schedule
                    you define.
                  </div>
                  <div className="">
                    <CreateScheduleButton onComplete={refetch} />
                  </div>
                </div>
              </div>
              <div className="p-4 space-y-4">
                {schedules?.map(schedule => {
                  return (
                    <div
                      key={schedule.id}
                      className="flex justify-between items-center"
                    >
                      <div className="flex">
                        <div className="">
                          <div className="font-semibold">
                            {schedule.doc.name}
                          </div>
                          <div className="text-neutral-400">
                            {/* <RingStrategyCell schedule={schedule} /> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            // value=""
                            className="sr-only peer"
                            // checked
                            defaultChecked
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Pin to Sidebar
                          </span>
                        </label>
                      </div>
                      <div>
                        {/* <UsersAndDevicesCell schedule={schedule} /> */}
                        <EditScheduleLink
                          // @ts-ignore
                          schedule={schedule}
                          onComplete={refetch}
                          label="Customize"
                        />
                      </div>
                      {/* <div>
                        <button
                          // @ts-ignore
                          onClick={e => editScheduleSet(schedule)}
                          type="button"
                        >
                          Customize
                        </button>
                      </div> */}
                    </div>
                  );
                })}
                {schedulesLoading ? <div>Loading Schedules...</div> : null}
                {!schedulesLoading && !schedules?.length ? (
                  <div>Schedules have not been created yet</div>
                ) : null}
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  );
}

const EditScheduleDialog = ({ schedule, onCancel }) => {
  return (
    <Drawer
      anchor="right"
      open={schedule}
      onClose={onCancel}
      sx={{ zIndex: 1300 }}
      PaperProps={{
        sx: { width: '1000px' },
      }}
    >
      <div className="p-4">
        {/* {schedule ? (
          <ScheduleRingStrategyCard schedule={schedule} onComplete={onCancel} />
        ) : null} */}
      </div>
    </Drawer>
  );
};

export default Schedules;

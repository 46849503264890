import React from 'react';
// import { useRemark } from 'react-remark';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

// markdown translation
export const useMarkdownTranslate = () => {
  const { t } = useTranslation();

  const mdt = key => {
    const md = t(key);
    return <ReactMarkdown children={md} linkTarget="_blank" />;
  };

  return { t: mdt };
};

import { Header } from 'app/components/Header';
import { Sidebar } from 'app/components/Sidebar';

import { Box, CircularProgress, Toolbar } from 'app/design';
import * as React from 'react';
import { Suspense } from 'react';

function Layout({ children }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          minWidth: 0,
        }}
      >
        <Toolbar variant="dense" />
        <Suspense
          fallback={
            <Box
              sx={{
                display: 'grid',
                height: '100%',
                width: '100%',
                placeItems: 'center',
              }}
            >
              <span>Loading content...</span>
              <CircularProgress size={30} />
            </Box>
          }
        >
          {children}
        </Suspense>
      </Box>
    </Box>
  );
}

export default Layout;

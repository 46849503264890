import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from 'app/design-lib';
// import { TourProvider, useTour } from '@reactour/tour';
import { useTour } from '@reactour/tour';
import { useGlobalTour } from 'app/components/Tourguide/Helper';
// import '@reactour/popover/dist/index.css';

// // enable ALL body targets
// // - don't allow to get stuck
// const targets = [];
// const disableBody = target => {
//   targets.push(target);
//   disableBodyScroll(target);
// };
// const enableBody = () => {
//   for (let target of targets) {
//     enableBodyScroll(target);
//   }
//   enableBodyScroll(document.getElementsByTagName('body'));
// };

// window.disableBody = disableBody;
// window.enableBody = enableBody;

const VisualBuilderTour = props => {
  const { onClose, tourEvents } = props;

  const history = useHistory();
  // const dispatch = useDispatch();
  // const { callflows } = useSelector((state) => state.lists);

  const [stepNum, setStepNum] = useState(0); // TODO: use redux?
  const [update, setUpdate] = useState(0); // TODO: use redux?

  const [{ openTour, setTourSettingsOverwrite }] = useGlobalTour();
  const { isOpen, currentStep, steps, setSteps, setIsOpen, setCurrentStep } =
    useTour();

  // const runningRef = useRef(null);

  const handleNextStep = () => {
    setStepNum(stepNum + 1);
  };
  const handlePrevStep = () => {
    setStepNum(stepNum - 1);
  };
  const handleAnyStep = curr => {
    // console.log('curr:', curr);
  };

  // const onAfterOpen = target => {
  //   // disableBody(target);
  //   console.log('onAfterOpen Setup2');
  //   if (window.location.pathname.indexOf('/v2') === -1) {
  //     history.push('/v2');
  //   }
  // };

  // const onRequestClose = e => {
  //   console.log('onRequestClose Setup2', e);
  //   // setIsTourOpen(false);
  //   // update account tour_complete
  //   onClose && onClose();
  //   enableBody(e.target);
  // };

  const handleUpdate = () =>
    setUpdate(v => {
      return v + 1;
    });

  // handle programatic "update" (usually triggered by a button)
  useEffect(() => {
    tourEvents.on('update', handleUpdate);
    return () => {
      tourEvents.off('update', handleUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showNormalOptions = (overwrite = {}) =>
    setTourSettingsOverwrite({
      showCloseButton: true,
      showPrevNextButtons: true,
      onClickMask: () => {
        // prevents close when mask is clicked
      },
      ...overwrite,
    });

  const skipTo = id_or_index => () =>
    setCurrentStep(v =>
      typeof id_or_index === 'number'
        ? id_or_index
        : tourSteps.findIndex(s => s.id === id_or_index),
    );
  const showNoActions = (overwrite = {}) =>
    setTourSettingsOverwrite({
      showCloseButton: false,
      showPrevNextButtons: false,
      onClickMask: null,
      ...overwrite,
    });

  const tourSteps = [
    {
      selector: 'body',
      // selector: '[data-tour-settings]',
      // position: 'left',
      content: (
        <div className="text-center -mb-[24px]">
          <div className="min-w-[640px] min-h-[400px]">
            <iframe
              src="https://www.loom.com/embed/0a5696a0d6d24028a8d1e1b3f8e9be51?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              width="640"
              height="400"
            ></iframe>
          </div>
          <Button
            variant="outline"
            color="accent"
            className="mx-auto mt-2"
            onClick={e => {
              setCurrentStep(v => v + 1);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Done Watching
          </Button>
        </div>
      ),
      action: () => {
        showNoActions({
          className: 'reacttour_popover !max-w-[800px]',
        });
      },
      // stepInteraction: true,
      disableActions: false, // TODO: change to true for prod
    },
  ];

  useEffect(() => {
    setSteps(tourSteps);
    setCurrentStep(0);
    setIsOpen(true);
  }, []);

  return null;
  // (
  //   <>
  //     <TourProvider
  //       steps={tourSteps}
  //       styles={styles}
  //       padding={{ popover: popoverPadding + 5 }}
  //       // // isOpen={isTourOpen}
  //       // isOpen
  //       // onRequestClose={onRequestClose}
  //       // onAfterOpen={onAfterOpen}
  //       afterOpen={() => alert(1)}
  //       onAfterOpen={() => alert(4)}
  //       // update={`${window.location.pathname}-${update}`}
  //       // goToStep={stepNum}
  //       // nextStep={handleNextStep}
  //       // prevStep={handlePrevStep}
  //       // getCurrentStep={handleAnyStep}
  //       // showButtons={false}
  //       // showNumber={false}
  //       // showNavigation={true}
  //       // // disableInteraction={true}
  //       // showCloseButton={false}
  //       // closeWithMask
  //       // disableDotsNavigation={true}
  //       // badgeContent={(curr, tot) => `${curr} of ${tot}`}
  //       // // inViewThreshold={10000} // no real change it seemed like
  //       // scrollDuration={1000}
  //       // rounded={30}
  //       // maskSpace={0}
  //       // // className="tmp-tour-hide-content"
  //       // // maskClassName={'tmp-mask-class'}
  //     >

  //       </TourProvider>
  //   </>
  // );
};

export default VisualBuilderTour;

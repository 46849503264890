import { gql } from 'graphql-request';

export default gql`
  query phoneNumberById($id: String, $mask: String) {
    phoneNumberById(id: $id, mask: $mask) {
      id
      doc
      createdAt
    }
  }
`;

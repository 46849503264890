import { gql } from 'graphql-request';

export default gql`
  query Query($newFilters: JSON, $savedFilters: JSON) {
    newMessages: vmboxMessages(filters: $newFilters) {
      totalCount
    }
    savedMessages: vmboxMessages(filters: $savedFilters) {
      totalCount
    }
  }
`;

import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { Dialog } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useCreateGroup } from 'app/hooks/mutations/group';
import { useUpdateMediaFull } from 'app/hooks/mutations/media';
import useMediaQuery from 'app/hooks/queries/media/useMediaQuery';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  name: Joi.string().max(128).required(),
});

interface UpdateMediaForm {
  name: string;
}

// interface declaring which props are required/allowed
interface ContentProps {
  mediaId: string;
  onCancel: () => void;
  mediaName: string;
  onComplete?: () => void;
}

interface GroupCreateDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

const EditMediaNameDialog = ({
  ContentProps,
  DialogProps,
}: GroupCreateDialogProps) => {
  return (
    <Dialog open={DialogProps.open} onClose={ContentProps.onCancel}>
      <EditMediaDialogContent {...ContentProps} />
    </Dialog>
  );
};

const EditMediaDialogContent = ({
  onCancel,
  onComplete,
  mediaId,
  mediaName,
}: ContentProps) => {
  const updateMediaFull = useUpdateMediaFull();
  const { data: media } = useMediaQuery({ id: mediaId });
  const formMethods = useForm<UpdateMediaForm>({
    defaultValues: {
      name: mediaName,
    },
    resolver: joiResolver(schema), // pass in custom validation
  });
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
    setError,
    watch,
  } = formMethods;

  const onSubmit = async ({ name }: UpdateMediaForm) => {
    if (!media) {
      alert('error loading media');
      return;
    }

    try {
      const resp = await updateMediaFull.mutateAsync({
        ...media?.doc,
        name,
      });
    } catch (e: any) {
      parseAndSetKazooMutationErrors({ response: e.response, setError });
    }
  };

  const handleComplete = () => {
    if (onComplete) onComplete();
  };

  return (
    <GenericMutationDialogContent
      onComplete={handleComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'Media updated!'}
      isLoadingLabel={'Updating media...'}
      title={'Edit media'}
      mutation={updateMediaFull}
      formMethods={formMethods}
      submitButtonLabel={'Update media'}
    >
      <HookFormTextField
        autoComplete={'off'}
        // fullWidth
        autoFocus
        name={'name'}
        label={'Name'}
      />
    </GenericMutationDialogContent>
  );
};

export default EditMediaNameDialog;

import React, { ReactNode } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from 'app/design';
import { Dialog } from 'app/design-lib';

import { DialogCircularProgress } from 'app/components/DialogCircularProgress';

interface NodeEditDialogProps {
  children: any;
  title: ReactNode;
  titleButton?: ReactNode;
  isLoading?: boolean;
  loadingLabel?: string;
  errorMessage?: string;
  onCancel: () => void;
  hideCancel?: boolean;
  cancelLabel?: string;
  saveLabel?: string;
  maxWidth?: any;
  onSave: () => void;
  onClose: () => void;
}

const NodeEditDialog = ({
  children,
  title,
  titleButton,
  isLoading,
  loadingLabel,
  errorMessage,
  onCancel,
  onSave,
  onClose,
  maxWidth = 'lg',
  saveLabel,
  cancelLabel,
  hideCancel,
}: NodeEditDialogProps) => {
  return (
    <Dialog open size={maxWidth} onClose={onClose}>
      {isLoading ? (
        <DialogCircularProgress label={loadingLabel} />
      ) : (
        <>
          <DialogTitle>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>{title}</Grid>
              <Grid item>{titleButton}</Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>{children}</div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Grid container justifyContent={'flex-end'} alignItems="center">
              {hideCancel || (
                <Grid item>
                  <Button color="secondary" onClick={onCancel} size={'small'}>
                    {cancelLabel ?? 'Cancel'}
                  </Button>
                </Grid>
              )}
              {errorMessage && (
                <>
                  <Grid item style={{ flexGrow: 1 }} />
                  <Typography variant={'caption'} color={'error'}>
                    {`Error: ${errorMessage}`}
                  </Typography>
                </>
              )}
              <Grid item style={{ flexGrow: 1 }} />
              {onSave && (
                <>
                  <Grid item>
                    <Button
                      color="primary"
                      variant={saveLabel === 'Done' ? 'outlined' : 'contained'}
                      onClick={onSave}
                      size={'small'}
                    >
                      {saveLabel || 'Add'}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default NodeEditDialog;

import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { useListVmboxesQuery } from 'app/hooks/queries/vmbox';
import { Extension } from 'types/extension';
import { User } from 'types/user';
import { Vmbox } from 'types/vmbox';

/*
 * Check extensions use location
 * */

interface UseExtensionUsedByProps {
  exts?: string[] | null;
}

const useExtensionUsedByQuery = ({
  exts,
}: UseExtensionUsedByProps): {
  users: User[];
  vmboxes: Vmbox[];
  extensions: Extension[];
  match: boolean;
  isLoading: boolean;
  isFetching: boolean;
} => {
  const enabled = !!exts?.length;

  const {
    data: conflictingUsersResp,
    isLoading: usersLoading,
    isFetching: usersFetching,
  } = useListUsersQuery({
    filters: {
      raw: [
        {
          or: exts?.map(ext => ({
            variables: {
              docFilters: [['presence_id', '=', ext]],
            },
          })),
        },
      ],
    },
    options: {
      enabled,
      // keepPreviousData: true,
    },
  });

  const {
    data: conflictingExtensionsResp,
    isLoading: extensionsLoading,
    isFetching: extensionsFetching,
  } = useListExtensionsQuery({
    filters: {
      raw: [
        {
          or: exts?.map(ext => ({
            variables: {
              numbersQuoted: [ext],
            },
          })),
        },
      ],
    },
    options: {
      enabled,
      // keepPreviousData: true,
    },
  });

  const {
    data: conflictingVmboxesResp,
    isLoading: vmboxesLoading,
    isFetching: vmboxesFetching,
  } = useListVmboxesQuery({
    filters: {
      context: {
        mailboxesQuoted: exts ? [...exts] : undefined,
      },
      // raw: [
      //   [
      //     {
      //       or: exts?.map(ext => ({
      //         variables: {
      //           docFilters: [['mailbox', '=', ext]],
      //         },
      //       })),
      //     },
      //   ],
      // ],
    },
    options: {
      enabled,
      // keepPreviousData: true,
    },
  });

  const isLoading = vmboxesLoading || usersLoading || extensionsLoading;
  const isFetching = vmboxesFetching || usersFetching || extensionsFetching;
  const { users = [] } = conflictingUsersResp ?? {};
  const { extensions = [] } = conflictingExtensionsResp ?? {};
  const { vmboxes = [] } = conflictingVmboxesResp ?? {};

  if (isLoading || !enabled) {
    return {
      users,
      extensions,
      vmboxes,
      isLoading,
      isFetching,
      match: false,
    };
  }

  return {
    users,
    extensions,
    vmboxes,
    isLoading,
    isFetching,
    match: !!(users.length || extensions.length || vmboxes.length),
  };
};

export default useExtensionUsedByQuery;

import { useAuthSelector } from 'app/data/auth';
import callDetailRecordQueryKeys from 'app/hooks/queries/callDetailRecord/callDetailRecordQueryKeys';
import { sdk } from 'app/sdk';
import { useQuery } from 'react-query';
import { CallDetailRecordsReportResponse } from 'types/callDetailRecord';

export interface Params {
  fnName: string;
  fnArgs: any;
}

export const useCallDetailRecordsReportQuery = ({ fnName, fnArgs }) => {
  // returns list of callDetailRecords
  // - just get ALL callDetailRecords+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = callDetailRecordQueryKeys.report(fnName, fnArgs);

  const query = useQuery<CallDetailRecordsReportResponse, Error>(
    queryKey,
    () =>
      sdk.callDetailRecord.query.callDetailRecordsReport(
        {
          fnName,
          fnArgs,
        },
        { authToken: authState.auth_token },
      ),
    {
      // enabled: authState?.auth_token_decoded?.owner_id ? true : false,
      // prevents pagination from looking like a hard load;
      //  - keeps previous data displayed until new data arrives
      keepPreviousData: true,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useCallDetailRecordsReportQuery;

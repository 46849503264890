import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import vmboxQueryKeys from 'app/hooks/queries/vmbox/vmboxQueryKeys';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

export const useDeleteVmbox = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, string, unknown>(
    (vmboxId: string) =>
      sdk.vmbox.mutate.delete(vmboxId, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(vmboxQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

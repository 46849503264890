import React, { useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Paper,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import {
  useFeature,
  Feature,
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';

import { useQueryClient } from 'react-query';
import accountQueryKeys from 'app/hooks/queries/account/accountQueryKey';
import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import { useCreditCardAddDialog } from 'app/components/CreditCardAddDialog';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
// const schema = Joi.object({
//   owner_id: Joi.string(),
//   name: Joi.string().max(128).required(),
//   mailbox: Joi.string().max(5).required(),
// });

interface PlanChangeForm {
  owner_id?: string;
  owner_type?: string;
  name?: string;
  mailbox?: string;
}

interface PlanChangeDialogProps {
  formDefaults: PlanChangeForm;
  onCancel: () => void;
  onComplete: () => void;
  startDirty?: boolean;
}

const PlanChangeDialog = ({
  formDefaults,
  onCancel,
  onComplete,
  // errorMessage,
  startDirty = false,
}: PlanChangeDialogProps) => {
  const [saveError, setSaveError] = useImmer(null);

  const [has_payment_methods, updateFeatures] = useFeature(
    'has_payment_methods',
  );
  const [planAfterCC, setPlanAfterCC] = useState(null);

  const {
    toggleOpen: toggleOpenCreditCardAddDialog,
    Dialog: CreditCardAddDialog,
    DialogProps: CreditCardAddDialogProps,
  } = useCreditCardAddDialog({ initialOpen: false });

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isSubmitting },
    setError,
    watch,
  } = useForm<PlanChangeForm>({
    defaultValues: formDefaults,
    // resolver: joiResolver(schema), // pass in custom validation
  });

  const router = useHistory();
  const changePlan = useAccountStripeCustomerMutate();

  const onSubmit = async (planForm: PlanChangeForm) => {
    // update form defaults if mutation fails
    // setSaveError(null);
    // // try {
    // // @ts-ignore
    // const changePlanPromise = changePlan.mutateAsync(sanitizedDoc);
    // toast.promise(changePlanPromise, {
    //   pending: 'Updating Plan...',
    //   success: 'Plan updated!',
    //   error: 'Error updating Plan.',
    // });
    // let resp;
    // try {
    //   resp = await changePlanPromise;
    // } catch (err) {
    //   parseAndSetKazooMutationErrors({ response: err.response, setError });
    //   // @ts-ignore
    //   setSaveError({
    //     // @ts-ignore
    //     message:
    //       'Unable to savePlan. Please correct errors and try again.',
    //   });
    //   throw err;
    // }
  };

  const handleUpgrade = async () => {
    const new_plan_id = 'basics';
    if (!has_payment_methods) {
      // @ts-ignore
      setPlanAfterCC(new_plan_id); // todo: not harcode?
      toggleOpenCreditCardAddDialog();
      return;
    }
    toggleBackdrop();
    try {
      const result = await changePlan.mutateAsync({
        data: {
          action: 'changePlan',
          data: {
            plan_id: new_plan_id, // todo: not harcode?
          },
        },
      });
      if (!result.success) {
        alert(
          result.message || 'Unable to change plan, please contact support',
        );
        toggleBackdrop();
        return;
      }
    } catch (err: any) {
      console.log('error:', err);
      console.log('response:', err.response);
      alert('Unable to change plan, please contact support');
      toggleBackdrop();
      return;
    }
    // window.location.reload();

    // await queryClient.refetchQueries(accountQueryKeys.stripe_customer);
    await updateFeatures();
    onComplete && onComplete();
    toggleBackdrop();
  };

  // if (isSubmitting) {
  //   return <DialogInProgress title={'Creating Plan...'} />;
  // }

  return (
    <>
      <CreditCardAddDialog
        {...CreditCardAddDialogProps}
        onCancel={toggleOpenCreditCardAddDialog}
        onComplete={handleUpgrade}
      />
      <Dialog open fullWidth maxWidth={'sm'}>
        <CustomBackdrop
          {...CustomBackdropProps}
          open={isSubmitting || CustomBackdropProps.open}
        />
        <DialogContent>
          <Grid container spacing={3} justifyContent="center">
            <Grid item md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h1" paragraph>
                  Free Plan
                </Typography>
                <ul>
                  <li>Max 15 users</li>
                  <li>Max 25 devices</li>
                  <li>...</li>
                </ul>
                <br />
                <Chip label="Your Current Plan" />
              </Paper>
            </Grid>
            <Grid item md={6}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h1" paragraph>
                  Paid Plan
                </Typography>
                <ul>
                  <li>$1 / user</li>
                  <li>$0.25 / device</li>
                  <li>...</li>
                </ul>
                <br />
                <Button variant="contained" fullWidth onClick={handleUpgrade}>
                  Upgrade
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <div>
            <Button variant={'outlined'} color={'error'} onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>{' '}
    </>
  );
};

export const usePlanChangeDialog = DialogBuilder(PlanChangeDialog);

export default PlanChangeDialog;

// @ts-nocheck
import { Combobox, Transition } from '@headlessui/react';
import React, { useCallback, useState, useEffect, Fragment } from 'react';

import constants from 'app/constants';

import {
  TextField,
  Grid,
  Autocomplete,
  Typography,
  Tooltip,
  Box,
} from 'app/design';
import {} from 'app/design-lib';
import {
  InfoEmpty as InfoIcon,
  PinAlt as LocationIcon,
  RefreshCircular as RefreshCircularIcon,
} from 'iconoir-react';
// import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import parse from 'autosuggest-highlight/parse';
import _, { throttle } from 'lodash';
import { useThrottle, useThrottleFn } from 'react-use';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export default function CityStateAutocomplete({
  onChange,
  startIcon,
  label,
  infoText = undefined,
  hasError = false,
  ...props
}) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback,
        );
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;
    const searchOptions = {
      types: ['(cities)'],
      componentRestrictions: { country: 'us' },
    };

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    setIsLoading(true);

    (async () => {
      fetch(
        { ...searchOptions, input: inputValue },
        (results?: readonly PlaceType[]) => {
          if (active) {
            let newOptions: readonly PlaceType[] = [];

            // if (value) {
            //   newOptions = [value];
            // }

            if (results) {
              newOptions = [...newOptions, ...results];
            }

            setOptions(newOptions);
          }
        },
      );
      setIsLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, setIsLoading]);

  return (
    <Combobox
      id="google-map-demo"
      value={value}
      onChange={v => {
        console.log('set value', v);
        // setOptions(v ? [v, ...options] : options);
        setValue(v);
        onChange(v);
      }}
    >
      <div className="relative">
        <div
          className={
            'relative flex space-y-1 relative font-sans flex-col w-full ' +
            (props.disabled ? 'opacity-50' : '')
          }
        >
          {label ? (
            <div className={`flex space-x-1 items-center`}>
              <label
                className={'ml-1 text-sm font-medium text-content'}
                // htmlFor={name}
              >
                {label}
              </label>
              {infoText ? (
                <InfoIcon
                  fr={undefined}
                  width={12}
                  height={12}
                  className={`stroke-gray-60 text-gray-60`}
                />
              ) : null}
            </div>
          ) : null}
          {startIcon
            ? React.cloneElement(startIcon, {
                className: ` absolute h-4 w-4 top-[2rem] left-2 stroke-gray-60 text-gray-60`,
              })
            : null}
          {isLoading ? (
            <RefreshCircularIcon
              fr={undefined}
              className="animate-spin absolute h-4 w-4 top-[2.3rem] right-4 stroke-gray-60 text-gray-60"
            />
          ) : null}

          {/*<div className="relative w-full cursor-default overflow-hidden rounded-lg border border-border-neutral bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">*/}
          <Combobox.Input
            className={`${props.className} ${
              startIcon ? 'pl-8' : ''
            } form-input focus:ring-content-accent focus:border-content-accent text-content-neutral ${
              hasError
                ? `border-border-negative-secondary`
                : `border-border-neutral`
            } rounded-lg border`}
            // className="w-full border-none py-2 pl-3 pr-10 text-base leading-5 text-gray-900 focus:ring-0"
            // @ts-ignore
            displayValue={option => {
              if (option) {
                return option.description;
              }

              return '';
            }}
            onChange={event => {
              const value = event.target.value;
              setInputValue(value);

              if (!value) setValue(null);
            }}
          />
          {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button> */}
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setInputValue('')}
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.length === 0 && inputValue !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              options.map(option => {
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match: any) => [
                    match.offset,
                    match.offset + match.length,
                  ]),
                );

                console.log('option', option);

                return (
                  <Combobox.Option
                    key={option.place_id}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                        active ? 'bg-accent-60 text-white' : 'text-gray-900'
                      }`
                    }
                    value={option}
                    onClick={e => {
                      console.log('Option:', option);

                      setValue(option);
                    }}
                  >
                    {({ selected, active }) => (
                      <>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                        <span className={'block text-gray-30'}>
                          {option.structured_formatting.secondary_text}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                );
              })
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );

  return (
    <Autocomplete
      id="google-map-demo"
      // sx={{ width: 300 }}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      // open={inputValue?.length && isFocused ? true : false}
      openOnFocus={false}
      noOptionsText={
        inputValue?.length
          ? 'No cities found'
          : 'Search results will appear here '
      }
      value={value}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Location"
          placeholder={'Search by city or zip'}
          startIcon={<LocationIcon fr={undefined} />}
        />
      )}
      renderOption={(props, option) => {
        // console.log('props:', props, 'option:', option);
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  // component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

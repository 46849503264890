import constants from 'app/constants';
import { useAuthSelector } from 'app/data/auth';
import {
  Button,
  Dialog,
  DialogAlert,
  DialogLoading,
  DialogProps,
} from 'app/design-lib';
import { useMutation, useQueryClient } from 'react-query';
import { DialogActions } from 'app/design-lib/components/DialogActions';
import { DialogContent } from 'app/design-lib/components/DialogContent';
import { DialogHeader } from 'app/design-lib/components/DialogHeader';
import { useAccountStripeCustomerQuery } from 'app/hooks/queries/account';
import { gql, request } from 'graphql-request';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { Emoji } from 'emoji-picker-react';

interface DeletePipeDialogProps extends Omit<DialogProps, 'children'> {
  pipe?: any;
}

export const DeletePipeDialog = (props: DeletePipeDialogProps) => {
  return (
    <Dialog {...props}>
      {' '}
      <DeletePipeDialogContent {...props} />
    </Dialog>
  );
};

const DeletePipeDialogContent = (props: DeletePipeDialogProps) => {
  const queryClient = useQueryClient();
  const { data } = useAccountStripeCustomerQuery();

  const { pipe } = props;

  const sub = data?.data?.subscriptions?.data?.[0];
  const { status, current_period_end, trial_end } = sub ?? {};

  const { auth_token } = useAuthSelector();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const router = useHistory();
  // though this is undoubtedly not a success that we are losing a customer,
  // the authors of this code agreed that for the sake consistency
  // across the app, the terminology should remain the same.
  const [isSuccess, setIsSuccess] = useState(false);
  const archivePipe = async () => {
    setIsSubmitting(true);
    try {
      const archiveResp = await request(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
        gql`
          mutation pipeArchiveById($id: ID) {
            pipeArchiveById(id: $id) {
              success
              message
              data
            }
          }
        `,
        {
          id: pipe.id,
        },
        // @ts-ignore
        { authorization: auth_token },
      );
      console.log('archive response', archiveResp);
      setIsSuccess(true);

      // console.log('pipeUpdateById mutationResponse:', mutationResponse);
      queryClient.invalidateQueries({
        queryKey: ['pipes'],
      });
      // setTimeout(() => {
      //   router.push(`/logout`);
      // }, 5000);
    } catch (err) {
      console.error(err);
      setErrorMessage('Error in archiving pipe');
    }
    setIsSubmitting(false);
  };

  const cancelDate = new Date(current_period_end * 1000);
  const isTrial = status === 'trialing';
  return (
    <>
      <DialogHeader
        title={
          isSuccess
            ? 'Line Deleted'
            : isSubmitting
            ? 'Deleting Line'
            : `Delete Line`
        }
        onClose={props.onClose}
        subTitle={isSubmitting ? 'Please wait...' : ''}
      />
      {!isSubmitting && errorMessage ? (
        <DialogAlert
          onClose={() => setErrorMessage('')}
          errorMessage={errorMessage}
        />
      ) : null}
      <DialogContent>
        {isSuccess ? (
          <div className={'w-full text-md '}>
            <p>Line removed!</p>
          </div>
        ) : isSubmitting ? (
          <DialogLoading
            label={'Deleting Line.'}
            secondaryText={'This may take a moment.'}
          />
        ) : (
          <div className={' space-y-4'}>
            <p className={'text-md'}>
              Are you sure you would like to delete this Line?
            </p>
            <LineDisplay pipe={pipe} />
            <p className={'text-md text-negative-60'}>
              You will lose all conversations and messages, and be unable to
              call or send messages from this number!
            </p>
          </div>
        )}
      </DialogContent>
      {isSubmitting ? null : (
        <DialogActions>
          {isSuccess ? (
            <Button
              variant={'fill'}
              color={'accent'}
              size={'md'}
              onClick={props.onClose}
            >
              Dismiss
            </Button>
          ) : (
            <>
              <Button
                variant={'fill'}
                color={'attention'}
                size={'md'}
                onClick={props.onClose}
                className="mr-auto"
              >
                Cancel
              </Button>
              <Button
                variant={'fill'}
                color={'negative'}
                size={'md'}
                onClick={archivePipe}
              >
                Delete Line
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </>
  );
};

export const LineDisplay = ({ pipe }) => {
  if (!pipe) {
    return null;
  }
  return (
    <div className={'flex items-center space-x-2'}>
      <Emoji unified={pipe.metadata?.emoji || '1f937'} size={24} />
      <div className={'flex flex-col space-y-0.5'}>
        <span className={'text-lg font-medium'}>
          {pipe.type === 'personal'
            ? 'Direct Messages'
            : pipe.metadata.name?.length
            ? pipe.metadata.name
            : 'Unnamed'}
        </span>
        <span className={'text-md'}>
          <PhoneNumberDisplay ptn={pipe.key} hideFlag />
        </span>
      </div>
    </div>
  );
};

export default DeletePipeDialog;

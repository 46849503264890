import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
} from 'app/sdk/utils/kazoo';
import { merge } from 'lodash';

// NOTE: IVR Extensions are callflows of type:'ivrMain';
//   - hence 'callflows' mutation resource API name

const exports = {
  // Queries
  // - graphql
  query: {
    ivrExtensions: (vars, fetchOptions) =>
      fetchFromGraphql(
        'callflows',
        merge(
          // filter callflows for schedule types only and merge with user vars
          // { filters: { doc: { path: ['type'], equals: 'ivrMain' } } },
          {
            filters: {
              raw: [
                {
                  variables: {
                    docFilters: [['type', '=', 'ivrMain']],
                  },
                },
              ],
            },
          },
          vars,
        ),
        fetchOptions,
      ),
    // Fetch a single ivrExtension by id
    ivrExtensionById: (vars, fetchOptions) =>
      fetchFromGraphql('callflowById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    create: buildPutDefault('callflows', { buildUrlOpts: { skipId: true } }),
    updatePartial: buildPatchDefault('callflows'),
    delete: buildDeleteDefault('callflows'),
  },
};

export default exports;

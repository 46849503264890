import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import {
  buildGetDefault,
  buildPutDefault,
  buildPatchDefault,
  buildDeleteDefault,
} from 'app/sdk/utils/kazoo';
import axios from 'axios';
import constants from 'app/constants';

import { baseTioApiAccountRequest } from 'app/pages/v2/common/sdk';

const exports = {
  // Queries
  // - graphql
  query: {
    phoneNumbers: (vars, fetchOptions) =>
      fetchFromGraphql('phoneNumbers', vars, fetchOptions),

    searchTrunkingio: async (vars, fetchOptions) => {
      // Get Area Codes for City/State
      // Get results for Area Codes

      // Get Area Codes for City/State
      let area_codes = [];
      try {
        if (vars.city) {
          // alert('city1');
          console.log(
            'Searching for area codes in city,state:',
            vars.city,
            vars.state,
          );
          const body1 = {
            data: {
              region: vars.state,
              locality: vars.city,
            },
          };
          const result = await axios.post(
            `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/phone_numbers/US/start_patterns/lookup`,
            body1,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
              },
            },
          );
          console.log('city lookup results', result);
          area_codes = result.data.data ?? []; // TODO: get actual area_codes
        }
      } catch (e) {
        console.log('city lookup error:');
        console.error(e);
      }
      console.log('area_codes:', area_codes);

      // if no area_codes found, use the lat/lon to return the nearest city/state and area_codes
      // - if no city/state supplied, use the IP address (request from server)
      if (!vars.city) {
        console.log('Getting IP from cio server');
        const resultIp = await axios.post(
          `${constants.env.REACT_APP_CIO_API_SERVER}/api/v1/ip`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
            },
          },
        );
        console.log('resultIp:', resultIp);
        if (resultIp.data.ip) {
          const bodyIp = {
            data: {
              ip: resultIp.data.ip,
            },
          };
          console.log(
            'Searching for area codes in ip:',
            resultIp.data.ip,
            bodyIp,
          );
          const resultAreaCodesIp = await axios.post(
            `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/phone_numbers/US/start_patterns/lookup`,
            bodyIp,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
              },
            },
          );
          console.log('resultAreaCodesIp:', resultAreaCodesIp);
          // @ts-ignore
          area_codes = resultAreaCodesIp.data.data ?? [];
          if (!area_codes.length) {
            console.error('Unable to find ANY area codes for IP');
          }
        }
      }
      // return false;

      if (!area_codes.length) {
        console.log('No area codes, geocoding using google maps');
        // Get lat/lon
        // @ts-ignore
        const geocoder = new window.google.maps.Geocoder();
        let georesults;
        try {
          georesults = await geocoder.geocode({
            address: `${vars.city}, ${vars.state}`,
          });
        } catch (err) {
          console.error('Google maps geocoder error', err);
        }

        // map.setCenter(georesults[0].geometry.location);
        console.log('georesults:', georesults);
        if (!georesults?.results?.length) {
          console.error('Invalid georesults for city/state');
        } else {
          const bodyLatLon = {
            data: {
              latitude: georesults.results[0].geometry.location.lat(),
              longitude: georesults.results[0].geometry.location.lng(),
            },
          };
          console.log(
            'Searching for area codes in city,state:',
            vars.city,
            vars.state,
            bodyLatLon,
          );
          const resultLatLon = await axios.post(
            `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/phone_numbers/US/start_patterns/lookup`,
            bodyLatLon,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
              },
            },
          );
          // @ts-ignore
          area_codes = resultLatLon.data.data ?? [];
          if (!area_codes.length) {
            console.error('Unable to find ANY area codes!');
          }
        }
      }

      // Get results for Area Codes
      console.log('Getting results for area codes');
      const body2 = {
        data: {
          start_patterns: [...area_codes, ...(vars.additionalAreaCodes || [])],
        },
      };
      const result = await axios.post(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/phone_numbers/US/lookup`,
        body2,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
          },
        },
      );
      console.log('number looked for area codes:', result);
      return result.data;
    },

    orderResultsTrunkingio: async (vars, fetchOptions) => {
      const result = await axios.get(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/accounts/____account_id____/carrier_orders/${vars.order_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
          },
        },
      );
      return result.data.data;
    },

    // Fetch a single phoneNumber by id
    phoneNumberById: (vars, fetchOptions) =>
      fetchFromGraphql('phoneNumberById', vars, fetchOptions),

    // search for phone numbers to buy (simple)
    available: (vars, fetchOptions) => {
      const fn = buildGetDefault('phone_numbers', {
        buildUrlOpts: { skipId: true },
      });
      return fn(null, fetchOptions.auth_token, vars);
    },
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    delete: (vars, fetchOptions) =>
      mutateGraphql('phoneNumberDelete', vars, fetchOptions),
    // delete: buildDeleteDefault('phone_numbers', {
    //   buildUrlOpts: { encodeId: true },
    // }),
    deleteTrunkingio: (vars, fetchOptions) => {
      return axios.post(
        `${
          constants.env.REACT_APP_CIO_API_SERVER
        }/api/tproxy/api/accounts/____account_id____/phone_numbers/${encodeURIComponent(
          vars.number,
        )}/deactivate`,
        JSON.stringify({}),
        {
          // withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
          },
        },
      );
    },
    updatePartial: buildPatchDefault('phone_numbers', {
      buildUrlOpts: { encodeId: true },
    }),
    buy: (vars, fetchOptions) => {
      const fn = buildPutDefault('phone_numbers/collection/activate', {
        buildUrlOpts: { skipId: true },
      });
      console.log('fetchOptions123:', fetchOptions);
      return fn(vars, fetchOptions.auth_token);
    },
    buyTrunkingio: (vars, fetchOptions) => {
      console.log('buying', vars);
      return axios.put(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/accounts/____account_id____/phone_numbers/US/purchase`,
        JSON.stringify({
          data: {
            numbers: vars.numbers,
          },
        }),
        {
          // withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
          },
        },
      );
    },
    syncTrunkingio: (vars, fetchOptions) => {
      return axios.post(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/accounts/____account_id____/phone_numbers/sync`,
        JSON.stringify({}),
        {
          // withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${fetchOptions.auth_token}`, // will be converted to correct trunkingio token
          },
        },
      );
    },
    messagingSet: ({ number, data }) => {
      // return phoneNumberMessagingSet({ number, data });
      return baseTioApiAccountRequest(
        'POST',
        `/phone_numbers/${encodeURIComponent(number)}/messaging`,
        { data }, // note we are passing "data" as the key in an object!
      );
    },
    setMessagingCampaignDefaultLowVolume: ({ number }) => {
      // return phoneNumberMessagingSet({ number, data });
      return baseTioApiAccountRequest(
        'POST',
        `/phone_numbers/${encodeURIComponent(number)}/default_low_volume`,
        {},
      );
    },
  },
};

export default exports;

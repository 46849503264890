import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import { buildDeleteDefault } from 'app/sdk/utils/kazoo';

///////////////
// TODO: jsDoc!
///////////////

const exports = {
  // Queries
  // - graphql
  query: {
    // TODO: pagination
    callflows: (vars, fetchOptions) =>
      fetchFromGraphql('callflows', vars, fetchOptions),

    // Fetch a single callflow by id
    callflowById: (vars, fetchOptions) =>
      fetchFromGraphql('callflowById', vars, fetchOptions),
  },

  // Mutations
  // - via graphql, for transforming "strategy->flow"
  mutate: {
    create: (vars, fetchOptions) =>
      mutateGraphql('callflowCreate', vars, fetchOptions),

    updatePartial: (vars, fetchOptions) =>
      mutateGraphql('callflowUpdateById', vars, fetchOptions),

    delete: buildDeleteDefault('callflows'),
  },
};

export default exports;

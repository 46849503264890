import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import {
  buildGetDefault,
  buildPutDefault,
  buildPatchDefault,
  buildDeleteDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    // summarize by yearMonth
    summary: (vars, fetchOptions) => {
      // console.log('summary:', vars, fetchOptions);
      const fn = buildGetDefault(`ledgers/summary/${vars?.yearMonth}`, {
        buildUrlOpts: { skipId: true },
      });
      return fn(null, fetchOptions.authToken, {});
    },
  },

  // Mutations
  mutate: {},
};

export default exports;

import { useListUsersQuery } from 'app/hooks/queries/user';
import { usePipesQuery } from 'app/pages/v2/common/sdk';
import { useLookupContact } from 'app/data/webphone/webphoneSlice';
import useMaybeContactsQuery from 'app/hooks/queries/contact/useMaybeContactsQuery';

export const useParticipant = value => {
  // Turns the number into the name
  // - key: 101, 500, +15553334444
  // - resource order: user (presence_id), pipe (key), contact

  // user
  const usersQuery = useListUsersQuery({
    take: 500,
    filters: {
      raw: [
        {
          variables: {
            docFilters: [['presence_id', '=', value]],
          },
        },
      ],
    },
    quick: true, // changes the response to "full" !!
    options: {},
  });
  // console.log('usersQuery:', usersQuery.data);
  // @ts-ignore
  const users = usersQuery.data?.users?.users ?? [];
  const user = users?.length ? users[0] : null;

  // pipes/lines
  const pipesQuery = usePipesQuery({
    filters: {
      key: value,
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];
  const pipe = pipes?.length ? pipes[0] : null;

  // contacts
  const [contact] = useLookupContact(value);
  const { data: maybeContacts } = useMaybeContactsQuery({});
  const maybeContact = maybeContacts?.find(c => c.number === value);

  const output = {
    result:
      (user && { type: 'user', user }) ||
      (pipe && { type: 'pipe', pipe }) ||
      (contact && { type: 'contact', contact }) ||
      (maybeContact && { type: 'maybeContact', maybeContact }),
    user,
    users,
    pipe,
    contact,
    maybeContact,
  };

  // console.log('useParticipant output:', value, output);
  return output;
};
export default useParticipant;

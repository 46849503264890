import { omit } from 'lodash';
import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  Tooltip,
  Icon,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';
import { LongArrowUpLeft, Trash } from 'iconoir-react';
import { IconButton } from '../../../../../../../design-lib';

export const MenuItem_Remove = props => {
  const { data, mode, onClose } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    callflow: rootCallflow,
    // componentData,
    // componentInfo,
    moduleItem,
    templateParent,
    // callflow,
    // setCallflow,
    // modifyPath,
  } = data;

  const { callflow, setCallflow, modifyPath } = insertAfterData;

  // console.log('props:', props);

  // const { callflow, setCallflow, modifyPath, index, mode } = props;

  // console.log('removeButton:', props);

  const handleRemove = async () => {
    // const data = {
    //   type: 'MenuGreetingAndTargets',
    // };
    const modules = getAtPath(callflow, `${modifyPath}.strategy.data.modules`);

    // console.log('modules:', modules, callflow, modifyPath);
    // index is from the insertAfterData!! (ie, we need to -1 to remove from the correct place)
    const comp = modules.splice(insertAfterData.index - 1, 1);

    setAtPath(callflow, `${modifyPath}.strategy.data.modules`, modules);

    setCallflow({ ...callflow }, { name: `Removed ${comp[0].type}` });
  };

  return (
    <Tooltip placement={'right'} title={'Remove'} arrow>
      <IconButton
        pill
        onClick={e => {
          handleRemove();
        }}
        size={'sm'}
        variant={'ghost'}
      >
        <Trash />
      </IconButton>
    </Tooltip>
  );

  // return (
  //   <MenuItem
  //     onClick={e => {
  //       // if (rootCallflow.type != 'template' && templateParent) {
  //       //   alert(
  //       //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
  //       //   );
  //       // }
  //       onClose();
  //       handleRemove();
  //     }}
  //     component={'a'}
  //   >
  //     <ListItemIcon>
  //       <DeleteIcon />
  //     </ListItemIcon>
  //     <ListItemText primary="Remove" />
  //   </MenuItem>
  // );
};

// export const RemoveButton = props => {
//   const { callflow, setCallflow, modifyPath, index, mode } = props;

//   // console.log('removeButton:', props);

//   const handleRemove = async () => {
//     // const data = {
//     //   type: 'MenuGreetingAndTargets',
//     // };
//     const components = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.config.components`,
//     );
//     const stratDataOpts = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.data.opts`,
//     );

//     // index is from the insertAfterData!! (ie, we need to -1 to remove from the correct place)
//     const comp = components.splice(index - 1, 1);
//     stratDataOpts.splice(index - 1, 1);

//     setAtPath(callflow, `${modifyPath}.strategy.config.components`, components);
//     setAtPath(callflow, `${modifyPath}.strategy.data.opts`, stratDataOpts);

//     setCallflow({ ...callflow }, { name: `Removed ${comp[0].type}` });
//   };

//   return (
//     <span style={{ position: 'absolute', bottom: -18, left: -25 }}>
//       <Tooltip
//         title={<>Remove this option (NOT ones below - if they exist!)</>}
//         placement="bottom"
//         type="light"
//         arrow
//       >
//         <IconButton
//           onClick={handleRemove}
//           size="small"
//           variant="outlined"
//           color="secondary"
//         >
//           <DeleteIcon />
//         </IconButton>
//       </Tooltip>
//     </span>
//   );
// };

export default MenuItem_Remove;

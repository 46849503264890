import React, { ReactElement, ReactNode } from 'react';
import { InfoEmpty as InfoIcon } from 'iconoir-react';

export interface InputWrapperProps {
  label?: string;
  helperText?: ReactNode | string;
  hasError?: boolean;
  infoText?: string;
  children: ReactNode;

  disabled?: boolean;

  name?: string;
  startIcon?: ReactElement;
}

const InputWrapper = ({
  helperText,
  label,
  hasError,
  infoText,
  name,
  disabled,
  startIcon,
  children,
}: InputWrapperProps) => {
  return (
    <div
      className={
        `flex space-y-1 relative font-sans flex-col w-full`
        // (disabled ? 'opacity-50' : '')
      }
    >
      {startIcon
        ? React.cloneElement(startIcon, {
            className: ` absolute h-4 w-4 z-10 ${
              label ? 'top-[2.575rem]' : 'top-4'
            } left-2 stroke-gray-60 text-gray-60`,
          })
        : null}
      {label ? (
        <div className={`flex space-x-1 items-center`}>
          <label
            className={'text-md font-medium text-content dark:text-neutral-400'}
            htmlFor={name}
          >
            {label}
          </label>
          {infoText ? (
            <InfoIcon
              fr={undefined}
              width={12}
              height={12}
              className={`stroke-gray-60 text-gray-60`}
            />
          ) : null}
        </div>
      ) : null}
      {children}
      {helperText ? (
        <span
          id={`${name}-help-text `}
          className={`ml-1 font-sans text-sm ${
            hasError
              ? `bg-negative-10 p-2 rounded text-content-negative`
              : `text-content/50`
          }`}
        >
          {helperText}
        </span>
      ) : null}
    </div>
  );
};
export default InputWrapper;

const vmboxQueryKeys = {
  all: ['vmbox'] as const,
  list: (skip: number, take: number, orderBy: any, filters: any, mask: any) =>
    [
      ...vmboxQueryKeys.all,
      'list',
      skip,
      take,
      orderBy,
      filters,
      mask,
    ] as const,
  listQuick: (
    skip: number,
    take: number,
    orderBy: any,
    filters: any,
    mask: any,
  ) =>
    [
      ...vmboxQueryKeys.all,
      'list-quick',
      skip,
      take,
      orderBy,
      filters,
      mask,
    ] as const,
  byId: (id: string | undefined) => [...vmboxQueryKeys.all, id] as const,
};

export default vmboxQueryKeys;

import { QuickFinderExtensionDialog } from 'app/components/QuickFinderExtensionDialog';
import { Button, Typography } from 'app/design';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { useFormContext } from 'react-hook-form';

// interface declaring which props are required/allowed
// interface VoicemailBoxProps {
//   // directoryId: string;
//   callflow?: any;
//   open?: boolean;
//   onCancel: () => void;
//   // onComplete: () => void;
// }

const TransferExt = ({ item, modifyPath, onChangeSimple }) => {
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showFinderDialog, toggleShowFindExtensionDialog] =
    useToggleReducer(false);

  const key = varItem.key;
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);

  // simple variable values
  const currentVarOutputValue = varItem.output_value;
  const defaultValueInFlow = varItem.output_value_default;
  const simpleValueInCallflow = variables[key];

  // get CURRENT value FROM MODULE
  // - for when saved by the Advanced Editor!
  //   - that doesnt update the varItem, it only updates the actual flow!
  const currentValue = moduleItem?.data?.target;
  const extensionId = simpleValueInCallflow ?? currentValue; // SHOULD be using currentValue???

  const extensionsPageCurrent = useListExtensionsQuery({
    filters: {
      context: {
        numbersQuoted: [extensionId],
      },
    },
    options: {
      enabled: !!extensionId,
    },
  });
  const extensionCurrent = extensionsPageCurrent?.data?.extensions?.[0];
  const extCallflowCurrent = extensionCurrent?.callflow;

  // const {
  //   data: extensionCurrent,
  //   isLoading: extensionIsLoadingCurrent,
  //   isFetched: extensionCurrentIsFetched,
  // } = useVmboxQuery({ id: currentValue });

  // const {
  //   data: extensionSimple,
  //   isLoading: extensionSimpleIsLoading,
  //   isFetched: extensionSimpleIsFetched,
  // } = useVmboxQuery({ id: simpleValueInCallflow });

  const onChangeExtensionSelect = selected => {
    const [extensionId] = selected;
    setValue(
      `${modifyPath}callflow.strategy.simple.variables.${key}`,
      extensionId,
      { shouldDirty: true },
    );
    setValue(
      `${modifyPath}callflow${moduleModifyPath}.data.target`,
      extensionId,
      {
        shouldDirty: true,
      },
    );
    onChangeSimple && onChangeSimple();
    // setAtPath(tmpModuleItem, `data.id`, extensionId); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindExtensionDialog();
  };

  const handleClear = () => {
    // setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, null, {
      shouldDirty: true,
    });
    setValue(`${modifyPath}callflow${moduleModifyPath}.data.target`, null, {
      shouldDirty: true,
    });
    onChangeSimple && onChangeSimple();

    toggleShowFindExtensionDialog();
  };

  // console.log('varitem Extension:', varItem, {
  //   defaultValueInFlow,
  //   simpleValueInCallflow,
  // });
  // console.log('extensionCurrent:', extensionCurrent);

  return (
    <div>
      <QuickFinderExtensionDialog
        open={showFinderDialog}
        onSelect={onChangeExtensionSelect}
        onCancel={toggleShowFindExtensionDialog}
        onClear={handleClear}
        initialSelected={simpleValueInCallflow ? [simpleValueInCallflow] : []}
        selectionTitle={
          // extensionSimple ? `${extensionSimple.doc.name}` : null
          currentValue ?? ''
        }
        allowSelectNone
      />
      <Typography variant="h3">Transfer to Extension: </Typography>

      <TargetAndName
        currentValue={currentValue}
        extensionsPageCurrent={extensionsPageCurrent}
        extensionCurrent={extensionCurrent}
      />
      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={toggleShowFindExtensionDialog}
      >
        Choose Extension
      </Button>
      {/* <Typography variant="body1">
        Current:{' '}
        {extensionIsLoadingCurrent
          ? 'Loading...'
          : extensionCurrent
          ? extensionCurrent.doc?.name ?? 'Invalid voicemail box'
          : 'None set'}
      </Typography>
      <Typography variant="body1" onClick={toggleShowFindExtensionDialog}>
        Custom:
        {extensionSimpleIsLoading
          ? 'Loading...'
          : extensionSimple
          ? extensionSimple.doc?.name ?? 'Invalid voicemail box'
          : 'None set'}
      </Typography> */}
    </div>
  );
};

const TargetAndName = ({
  currentValue,
  extensionsPageCurrent,
  extensionCurrent,
}) => {
  if (!currentValue) {
    return (
      <Typography variant="bodyBig" sx={{ fontWeight: 'bold' }} paragraph>
        None chosen
      </Typography>
    );
  }

  if (!extensionCurrent && extensionsPageCurrent.isLoading) {
    return (
      <Typography variant="bodyBig" sx={{ fontWeight: 'bold' }} paragraph>
        {currentValue}: Loading...
      </Typography>
    );
  }

  // // owner specified? (TODO)
  // if (extensionCurrent?.callflow?.Owner) {
  //   return (
  //     <Typography variant="bodyBig" sx={{ fontWeight: 'bold' }} paragraph>
  //       {currentValue}:{' '}
  //       {extensionCurrent
  //         ? extensionCurrent?.callflow?.doc?.name ?? 'Unknown Extension'
  //         : 'Unknown Extension'}
  //     </Typography>
  //   );
  // }

  return (
    <Typography variant="bodyBig" sx={{ fontWeight: 'bold' }} paragraph>
      {currentValue}:{' '}
      {extensionCurrent
        ? extensionCurrent?.callflow?.doc?.name ?? 'Unknown Extension'
        : 'Unknown Extension'}
    </Typography>
  );
};

export default TransferExt;

import {
  add,
  addHours,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  set,
  setHours,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  sub,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { TimeRange } from 'types/generic';

export const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export type TimeRangeModifier = '3_hour_window';

export const getTimeRange = (
  timeRange: TimeRange,
  modifier?: TimeRangeModifier,
) => {
  let start, end;
  switch (timeRange) {
    case TimeRange.Today:
      start = startOfDay(Date.now()).getTime();
      // needs to be end of day for concurrent chart, not just current time
      end = endOfDay(Date.now()).getTime();
      // end = Date.now();

      break;
    case TimeRange.Yesterday:
      start = startOfYesterday().getTime();
      end = endOfYesterday().getTime();
      break;
    case TimeRange.ThisWeek:
      start = startOfWeek(Date.now()).getTime();
      end = endOfWeek(Date.now()).getTime();

      break;
    case TimeRange.LastWeek:
      start = startOfWeek(subWeeks(Date.now(), 1)).getTime();
      end = endOfWeek(subWeeks(Date.now(), 1)).getTime();

      break;
    case TimeRange.ThisDayLastWeek:
      start = startOfDay(subWeeks(Date.now(), 1)).getTime();
      end = endOfDay(subWeeks(Date.now(), 1)).getTime();

      break;
    case TimeRange.ThisMonth:
      start = startOfMonth(Date.now()).getTime();
      end = endOfMonth(Date.now()).getTime();

      break;
    case TimeRange.LastMonth:
      start = startOfMonth(subMonths(Date.now(), 1)).getTime();
      end = endOfMonth(subMonths(Date.now(), 1)).getTime();

      break;
    case TimeRange.TwoMonthsAgo:
      start = startOfMonth(subMonths(Date.now(), 2)).getTime();
      end = endOfMonth(subMonths(Date.now(), 2)).getTime();

      break;
    case TimeRange.Last7Days:
      start = startOfDay(subDays(Date.now(), 7)).getTime();
      end = endOfDay(Date.now()).getTime();

      break;

    case TimeRange.Last30Days:
      start = startOfDay(subDays(Date.now(), 30)).getTime();
      end = endOfDay(Date.now()).getTime();

      break;

    case TimeRange.Last90Days:
      start = startOfDay(subDays(Date.now(), 90)).getTime();
      end = endOfDay(Date.now()).getTime();

      break;

    case TimeRange.Last3Months:
      start = startOfDay(subMonths(startOfMonth(Date.now()), 3)).getTime();
      end = endOfDay(Date.now()).getTime();

      break;

    case TimeRange.Last6Months:
      start = startOfDay(subMonths(startOfMonth(Date.now()), 6)).getTime();
      end = endOfDay(Date.now()).getTime();

      break;

    case TimeRange.ThisYear:
      start = startOfDay(startOfYear(Date.now())).getTime();
      end = endOfDay(Date.now()).getTime();

      break;

    case TimeRange.LastYear:
      start = startOfDay(startOfYear(subYears(Date.now(), 1))).getTime();
      end = startOfDay(endOfYear(subYears(Date.now(), 1))).getTime();

      break;

    case TimeRange.Last365Days:
      start = startOfDay(subYears(Date.now(), 1)).getTime();
      end = endOfDay(Date.now()).getTime();
      break;

    case TimeRange.Last3Years:
      start = startOfDay(startOfYear(subYears(Date.now(), 3))).getTime();
      end = endOfDay(Date.now()).getTime();

      break;

    default:
      throw new Error('Invalid time range specified');
  }

  switch (modifier) {
    case '3_hour_window':
      const now = new Date();
      const setVal = {
        hours: now.getHours(),
        minutes: now.getMinutes(),
        seconds: now.getSeconds(),
      };
      const duration = { hours: 1, minutes: 30 };
      // console.log(setHours(start, now.getHours() - 3));
      return {
        start: sub(set(start, setVal), duration).getTime(),
        end: add(set(start, setVal), duration).getTime(),
      };
    default:
  }

  return {
    start,
    end,
  };
};

export const formatDigits = number => `0${number}`.slice(-2);

export const durationFormat = totalSeconds => {
  if (!totalSeconds) {
    return '00:00:00';
  }
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  return `${Math.floor(hours)}:${formatDigits(
    Math.floor(minutes),
  )}:${formatDigits(Math.floor(seconds))}`;
};

import { AddPhonesFormFields } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/types';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import { FormControl, InputLabel, MenuItem, Select } from 'app/design';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

interface PhoneTypeFieldProps {
  index: number;
  formErrors: any;
  disabled: boolean;
  control: Control<AddPhonesFormFields, object>;
  setValue: UseFormSetValue<AddPhonesFormFields>;
}

const options = [
  { value: 'application_bria', label: 'Bria App', subType: 'bria' },
  { value: 'application_zoiper', label: 'Zoiper App', subType: 'zoiper' },
  { value: 'sip_device', label: 'Advanced: SIP Device' },
];

const PhoneTypeField = ({
  index,
  control,
  disabled,
  formErrors,
  setValue,
}: PhoneTypeFieldProps) => {
  const fieldName = `phones.${index}`;

  return (
    <HookFormListbox
      name={`${fieldName}.device_type`}
      options={options}
      label={'Phone type'}
      className={'w-full'}
      disabled={disabled}
    />
  );

  // return (
  //   <Controller
  //     // @ts-ignore
  //     name={`${fieldName}.device_type`}
  //     control={control}
  //     render={({ field: { value, onChange, onBlur, ...props } }) => (
  //       <FormControl fullWidth {...props}>
  //         <InputLabel id="demo-simple-select-label">
  //           {'Device Type:'}
  //         </InputLabel>
  //         <Select
  //           fullWidth
  //           disabled={disabled}
  //           labelId="demo-simple-select-label"
  //           id="demo-simple-select"
  //           value={value}
  //           label={'Device Type'}
  //           onChange={(e, element) => {
  //             // console.log('value', e.target.value);
  //             onChange(e);
  //           }}
  //           size={'small'}
  //         >
  //           {options.map((opt, index) => (
  //             // @ts-ignore
  //             <MenuItem value={opt.value}>{opt.label}</MenuItem>
  //           ))}
  //         </Select>
  //       </FormControl>
  //     )}
  //   />
  // );
};

export default PhoneTypeField;

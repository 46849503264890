import { gql } from 'graphql-request';

export default gql`
  query CallsParked(
    $skip: Int
    $take: Int
    $orderBy: JSON
    $filters: JSON
    $mask: String
  ) {
    callsParked(
      skip: $skip
      take: $take
      orderBy: $orderBy
      filters: $filters
      mask: $mask
    ) {
      totalCount
      skip
      take
      callsParked {
        id
        num
        account_id
        doc
      }
    }
  }
`;

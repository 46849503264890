import * as React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Paper,
  Link,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from 'app/design';
import { Button, Dialog } from 'app/design-lib';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import {
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

import copy from 'copy-to-clipboard';

import { useCreditCardAddDialog } from 'app/components/CreditCardAddDialog';

interface CreditCardRequiredDialogProps {
  onCancel?: () => void;
  onComplete?: () => void;
  startDirty?: boolean;
}

const CreditCardRequiredDialog = ({
  onCancel,
  onComplete,
  // onSubmit,
  // errorMessage,
  startDirty = false,
}: CreditCardRequiredDialogProps) => {
  const {
    toggleOpen: toggleOpenCreditCardAddDialog,
    Dialog: CreditCardAddDialog,
    DialogProps: CreditCardAddDialogProps,
  } = useCreditCardAddDialog({ initialOpen: false });

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <>
      <CreditCardAddDialog
        {...CreditCardAddDialogProps}
        onCancel={onCancel}
        onComplete={onComplete}
      />
      <Dialog open={true} onClose={handleCancel}>
        <div className={'flex space-y-4 items-center flex-col'}>
          <span className={'font-medium text-lg'}>
            A credit card is required to activate your account
          </span>
          <Button
            variant="fill"
            size={'md'}
            color={'accent'}
            onClick={toggleOpenCreditCardAddDialog}
          >
            Add a Credit Card
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export const useCreditCardRequiredDialog = DialogBuilder(
  CreditCardRequiredDialog,
);

export default CreditCardRequiredDialog;

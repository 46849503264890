import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import { buildDeleteDefault } from 'app/sdk/utils/kazoo';
import { merge } from 'lodash';

///////////////
// TODO: jsDoc!
///////////////

// NOTE: Schedules are callflows of type:'screenpop';
//   - hence 'callflows' mutation resource API name

const exports = {
  // Queries
  // - graphql
  query: {
    // fetch all screenpops TODO: pagination
    screenpops: (vars, fetchOptions) =>
      fetchFromGraphql(
        'callflows',
        merge(
          // filter callflows for screenpop types only and merge with user vars
          // { filters: { doc: { path: ['type'], equals: 'screenpop' } } },
          { filters: { context: { types: ['screenpop'] } } },
          vars,
        ),
        fetchOptions,
      ),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    // screenpop uses callflow patch
    // create: buildPutDefault('callflows', { buildUrlOpts: { skipId: true } }),
    // updatePartial: buildPatchDefault('callflows'),

    create: (vars, fetchOptions) =>
      mutateGraphql('callflowCreate', vars, fetchOptions),

    updatePartial: (vars, fetchOptions) =>
      mutateGraphql('callflowUpdateById', vars, fetchOptions),

    delete: buildDeleteDefault('callflows'),
  },
};

export default exports;

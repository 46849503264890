import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';
import { IconButton } from 'app/design-lib';
import { DoubleCheck } from 'iconoir-react';
import { Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../../..';
import { walkStrategyModules } from '../../../../walkStrategyModules';

// LOCATED IN addBefore now!!!!
export const MenuItem_Duplicate = props => {
  // const { data, mode, onClose } = props;
  // const {
  //   insertBefore, // bool
  //   insertAfterData,
  //   infoIdx,
  //   callflow: rootCallflow,
  //   currentCallflow,
  //   componentData,
  //   componentInfo,
  //   templateParent,
  //   // callflow,
  //   // setCallflow,
  //   modifyPath,
  // } = data;

  // // const { callflow, setCallflow, modifyPath } = insertAfterData;

  // const [sharedFlow, setSharedFlow] = useSharedFlow();

  // const ee = useContext(IvrMenuEventEmitterContext);
  // const handleDuplicateTo = () => {
  //   // alert('duplicate to');
  //   // if (rootCallflow.type != 'template' && templateParent) {
  //   //   alert(
  //   //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!'
  //   //   );
  //   // }
  //   let modules = JSON.parse(
  //     JSON.stringify(currentCallflow.strategy.data.modules.slice(infoIdx)),
  //   );
  //   // const componentInfo =
  //   //   currentCallflow.strategy.config.components.slice(infoIdx);
  //   // const componentData = currentCallflow.strategy.data.opts.slice(infoIdx);

  //   console.log('modules:', modules);

  //   // walk and create new IDs
  //   // - for schedules: new scheduleID
  //   const newSchedules = {};
  //   const newCallflowExample = {
  //     id: 'inline',
  //     schedules: rootCallflow.schedules,
  //     strategy: {
  //       type: 'blank',
  //       data: {
  //         modules,
  //       },
  //     },
  //   };

  //   // modify by reference works
  //   walkStrategyModules(
  //     newCallflowExample,
  //     ({ module, callflow: rootCallflow }) => {
  //       module.id = uuidv4();
  //       console.log('MODULE:', module);
  //       if (module.type === 'Schedule') {
  //         // TODO: do not recreate scheduleId if schedule is "shared" (by default, each is entirely separate)
  //         let newScheduleId = uuidv4();
  //         newSchedules[newScheduleId] = JSON.parse(
  //           JSON.stringify(rootCallflow.schedules[module.data.schedule_id]),
  //         );
  //         module.data.schedule_id = newScheduleId;
  //       }
  //     },
  //   );
  //   modules = newCallflowExample.strategy.data.modules;

  //   setSharedFlow(s => ({
  //     ...s,
  //     state: 'duplicate-to',
  //     data: {
  //       // array to concat to wherever it is being duplicated
  //       // - TODO: different way of doing this? create a new strategy/callflow each time?
  //       path: modifyPath,
  //       duplicateJson: JSON.parse(
  //         JSON.stringify({
  //           newSchedules,
  //           modules,
  //           // componentInfo,
  //           // componentData,
  //         }),
  //       ),
  //     },
  //   }));
  // };

  // return (
  //   <Tooltip placement={'right'} title={'Duplicate'} arrow>
  //     <IconButton
  //       pill
  //       onClick={e => {
  //         // if (rootCallflow.type != 'template' && templateParent) {
  //         //   alert(
  //         //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
  //         //   );
  //         // }
  //         // onClose();
  //         handleDuplicateTo();
  //       }}
  //       size={'sm'}
  //       variant={'ghost'}
  //     >
  //       <DoubleCheck />
  //     </IconButton>
  //   </Tooltip>
  // );

  // return (
  //   <MenuItem
  //     onClick={e => {
  //       onClose();
  //       handleDuplicateTo();
  //     }}
  //     component={'a'}
  //     disabled
  //   >
  //     <ListItemIcon>
  //       <FileCopyIcon />
  //     </ListItemIcon>
  //     <ListItemText primary="Duplicate" />
  //   </MenuItem>
  // );
  return null;
};

export default MenuItem_Duplicate;

import {
  AnimationDialog,
  Chip,
  CircularProgress,
  DialogTitle,
  Divider,
  DialogContent,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'app/design';
import { Dialog, DialogHeader } from 'app/design-lib';
import {
  Block as BlockIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { TimezoneDialogContent } from 'app/components/ListUsers/components/BulkActions/components/TimezoneBulkUsers/components/TimezoneDialog';
import { TIMEZONE_LIST } from 'app/components/TimezoneDialogAccount/TimezoneDialogAccount';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AxiosError } from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { User } from 'types/user';
import { useImmer } from 'use-immer';

interface TimezoneWrapperProps {
  selectedRows?: User[];
  onComplete: () => void;
  onCancel: () => void;
  open: boolean;
}

interface TimezoneProps extends TimezoneWrapperProps {
  timezone: string | null;
}

const TimezoneBulkUsersWrapper = (props: TimezoneWrapperProps) => {
  return (
    <Dialog size={'xl'} open={props.open} onClose={props.onCancel}>
      <TimezoneBulkUsers {...props} />
    </Dialog>
  );
};

const TimezoneBulkUsersContent = ({
  timezone,
  selectedRows: selectedUsers,
  onComplete,
  onCancel,
}: TimezoneProps) => {
  const updateUser = useUpdateUserPartial();
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const { isLoading } = updateUser;
  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
    }[]
  >(() => selectedUsers?.map(user => ({ status: null })) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  useEffect(() => {
    setMutationArray(selectedUsers?.map(user => ({ status: null })) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  if (!selectedUsers)
    return <Typography variant={'error'}>No users selected!</Typography>;

  const handleSuspend = async () => {
    for (let i = 0; i < selectedUsers.length; i++) {
      if (error) break;

      const user = selectedUsers[i];

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      await updateUser.mutateAsync(
        { id: user.id, timezone: timezone },
        {
          onSuccess: () => {
            setMutationArray(prevState => {
              prevState[i].status = 'success';
            });
          },
          onError: error => {
            setMutationArray(prevState => {
              prevState[i].status = 'error';
            });
            setError(error);
          },
        },
      );
    }
  };

  const handleDone = () => {
    onComplete();
    updateUser.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <DialogHeader
            title={
              <div>
                Update User(s) Timezone:{' '}
                {TIMEZONE_LIST.find(tz => tz.value === timezone)?.label ??
                  'Unset'}
              </div>
            }
            onClose={onCancel}
          />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {updateUser.isSuccess
            ? `Successfully updated user(s) timezone:`
            : updateUser.isLoading
            ? `Updating user(s)...`
            : error
            ? `Error updating user(s) timezone:`
            : `Are you sure you want to update ${selectedUsers.length} user(s) timezone?`}
        </Typography>
        <br />
        <div>
          <Table>
            {selectedUsers.map((user, index) => (
              <TableRow sx={{ height: 65 }}>
                <TableCell>
                  {mutationArray[index].status === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : mutationArray[index].status === 'success' ? (
                    <CheckIcon color={'success'} />
                  ) : mutationArray[index].status === 'error' ? (
                    <ClearIcon color={'error'} />
                  ) : null}
                </TableCell>
                <TableCell>
                  <Typography>
                    {`${user.doc.first_name} ${user.doc.last_name}`}
                  </Typography>
                </TableCell>
                <TableCell>
                  {user.doc.priv_level === 'admin' ? (
                    <Chip label={'Admin'} color={'primary'} size={'small'} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </DialogContent>
      {!isLoading ? (
        <DefaultDialogActions
          saveLabel={updateUser.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : updateUser.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error
              ? undefined
              : updateUser.isSuccess
              ? handleDone
              : handleSuspend
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

const TimezoneBulkUsers = (props: TimezoneWrapperProps) => {
  const { selectedRows: selectedUsers, onCancel, open } = props;
  const [timezone, setTimezone] = useImmer<string | null | undefined>(
    undefined,
  );
  const [initialTimezone] = useImmer(() => {
    // check if all user timezones are the same
    const defaultTimezone = selectedUsers?.[0].doc.timezone;
    if (selectedUsers?.every(user => user.doc.timezone === defaultTimezone)) {
      return defaultTimezone ?? undefined;
    }

    return undefined;
  });

  return (
    <>
      {timezone === undefined ? (
        <TimezoneDialogContent
          // allow null so users can unset
          open={open}
          initialTimezone={initialTimezone}
          onCancel={onCancel}
          onComplete={setTimezone}
        />
      ) : null}
      {timezone !== undefined ? (
        <TimezoneBulkUsersContent
          {...props}
          open={open}
          timezone={timezone!}
          onCancel={() => setTimezone(undefined)}
        />
      ) : null}
    </>
  );
};

export default TimezoneBulkUsersWrapper;

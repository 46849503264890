import { Box, MenuItem, Select, Typography } from 'app/design';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';
import {
  useWebphoneSelector,
  useWebphoneSlice,
} from '../../../../../data/webphone';
import { useWebphoneContext } from '../../../Store';
import useStyles from './styles';
import { IconButton, Listbox, Slider } from '../../../../../design-lib';
import { span } from 'vega';
import { MicMute, MicSpeaking, SoundHigh, SoundOff } from 'iconoir-react';

const SpeakerSetting = props => {
  const { filterText, setFilterMatching } = props;

  const SUB_ITEMS = [
    {
      component: SpeakerDevice,
    },
    {
      component: SpeakerVolume,
    },
  ];

  const [fullSubitemsList, setFullSubitemsList] = useState([...SUB_ITEMS]);

  const anyChildrenMatch = fullSubitemsList.find(setting => setting.matching)
    ? true
    : false;

  return (
    <>
      <SpeakerDevice />
      <SpeakerVolume />
    </>
  );
  // useEffect(() => {
  //   setFilterMatching(anyChildrenMatch);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fullSubitemsList, anyChildrenMatch]);
  //
  // const handleChildrenFilterMatching = index => matching => {
  //   setFullSubitemsList(list => {
  //     list[index].matching = matching;
  //     return [...list];
  //   });
  // };

  // const classes = useStyles({ anyChildrenMatch });

  // return (
  //   <Box sx={classes.mainCategory}>
  //     <Typography variant="h6" gutterBottom>
  //       Speakers
  //     </Typography>
  //     <div style={{ padding: '0px 8px' }}>
  //       {SUB_ITEMS.map((subitem, i) => {
  //         const Component = subitem.component;
  //         return (
  //           <Component
  //             key={i}
  //             filterText={filterText}
  //             setFilterMatching={handleChildrenFilterMatching(i)}
  //           />
  //         );
  //       })}
  //     </div>
  //   </Box>
  // );
};

const SpeakerDevice = props => {
  const { settings } = useWebphoneSelector();
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();
  const { filterText, setFilterMatching } = props;
  const [state] = useWebphoneContext();
  const { mediaDevices, changeAudioOutput, getPreferredDevice } = state;

  const [doesMatch, setDoesMatch] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState('default');

  // useEffect(() => {
  //   setFilterMatching(doesMatch);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [doesMatch]);
  //
  // useEffect(() => {
  //   const matches = ['speaker output device'];
  //   setDoesMatch(matchSorter(matches, filterText).length ? true : false);
  // }, [filterText]);

  let audioOutDevices = [];
  if (mediaDevices !== undefined) {
    audioOutDevices = mediaDevices.filter(item => item.kind === 'audiooutput');
    if (audioOutDevices.length === 1) {
      if (selectedDevice !== audioOutDevices[0].deviceId) {
        setSelectedDevice(audioOutDevices[0].deviceId);
      }
    } else {
      // check if previous selection is available and select,
      // otherwise use default
      if (
        settings.output?.device &&
        audioOutDevices.find(
          device => device.deviceId === settings.output?.device.deviceId,
        )
      ) {
        if (settings.output.device.deviceId !== selectedDevice) {
          setSelectedDevice(settings.output?.device.deviceId);
          changeAudioOutput(settings.output?.device.deviceId);
        }
      } else {
        const device = getPreferredDevice('audiooutput');
        if (device !== selectedDevice) {
          setSelectedDevice(device);
        }
      }
    }
  }
  const onDeviceChange = id => {
    changeAudioOutput(id);
    setSelectedDevice(id);

    // save selected device
    if (id)
      dispatch(
        actions.set_setting({
          path: 'output.device',
          value: audioOutDevices.find(device => device.deviceId === id),
        }),
      );
  };

  // const classes = useStyles({ doesMatch });

  return (
    <>
      <div className={`w-full`}>
        <Listbox
          label={'Output device'}
          className={`w-full`}
          value={selectedDevice}
          options={audioOutDevices.map((deviceInfo, i) => ({
            label: (
              <>
                {' '}
                <span>{deviceInfo.label}</span>
                {deviceInfo.deviceId === 'default' ? (
                  <span
                    className={'text-gray-500 ml-1'}
                  >{`[System Default]`}</span>
                ) : deviceInfo.deviceId ===
                  settings.output?.device?.deviceId ? (
                  <span className={'text-teal-500 ml-1'}>{`[Preferred]`}</span>
                ) : null}
              </>
            ),
            value: deviceInfo.deviceId,
          }))}
          onChange={onDeviceChange}
        />
      </div>
    </>
  );

  // return (
  //   <Box sx={classes.subitem}>
  //     <div style={{}}>
  //       <Typography variant="body1" gutterBottom>
  //         Output Device
  //       </Typography>
  //       <Select
  //         size="small"
  //         style={{ width: '100%' }}
  //         value={selectedDevice}
  //         onChange={e => onDeviceChange(e.target.value)}
  //       >
  //         {audioOutDevices.map((deviceInfo, i) => (
  //           <MenuItem key={i} value={deviceInfo.deviceId}>
  //             <span>{deviceInfo.label}</span>
  //             {deviceInfo.deviceId === 'default' ? (
  //               <span
  //                 className={'text-gray-500 ml-1'}
  //               >{`[System Default]`}</span>
  //             ) : deviceInfo.deviceId === settings.output?.device?.deviceId ? (
  //               <span className={'text-teal-500 ml-1'}>{`[Preferred]`}</span>
  //             ) : null}
  //           </MenuItem>
  //         ))}
  //         {settings.output?.device &&
  //         !audioOutDevices.find(
  //           device => device.deviceId === settings.output?.device.deviceId,
  //         ) ? (
  //           <MenuItem disabled key={settings.output?.device.deviceId}>
  //             {settings.output?.device.label}
  //             <span className={'text-teal-500 ml-1'}>
  //               [Preferred - Disconnected]
  //             </span>
  //           </MenuItem>
  //         ) : null}
  //       </Select>
  //     </div>
  //   </Box>
  // );
};

const SpeakerVolume = props => {
  const { settings } = useWebphoneSelector();
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();
  const { filterText, setFilterMatching } = props;
  const [state] = useWebphoneContext();
  const { setSpeakerVolume, getSpeakerVolume } = state;

  const [doesMatch, setDoesMatch] = useState(true);
  const [volume, setVolume] = useState(settings?.output.volume ?? 80);

  // useEffect(() => {
  //   setFilterMatching(doesMatch);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [doesMatch]);
  //
  // useEffect(() => {
  //   const matches = ['speaker output volume'];
  //   setDoesMatch(matchSorter(matches, filterText).length ? true : false);
  // }, [filterText]);

  let spkVol = getSpeakerVolume() * 100;
  spkVol = Math.round(spkVol);

  // check if user has customized volume in app, otherwise use system volume for device
  if (!settings?.output.volume && spkVol !== volume) {
    setVolume(spkVol);
  }
  const onVolumeChange = value => {
    const vol = Math.round(value) / 100;
    setSpeakerVolume(vol);
    setVolume(Math.round(value));
  };

  // store volume after user adjusts
  useDebounce(
    () => {
      dispatch(actions.set_setting({ path: 'output.volume', value: volume }));
    },
    500,
    [volume],
  );

  return (
    <div className={`w-full flex space-x-2 items-center`}>
      <IconButton
        size={'sm'}
        variant={'ghost'}
        color={'neutral'}
        pill
        onClick={() => onVolumeChange(0)}
      >
        <MicMute fr={undefined} />
      </IconButton>
      <Slider value={volume} onChange={onVolumeChange} min={0} max={100} />
      <IconButton
        size={'sm'}
        pill
        variant={'ghost'}
        color={'neutral'}
        onClick={() => onVolumeChange(100)}
      >
        <MicSpeaking fr={undefined} />
      </IconButton>
    </div>
  );

  // const classes = useStyles({ doesMatch });
  //
  // return (
  //   <Box sx={classes.subitem}>
  //     <div style={{}}>
  //       <Typography variant="body1">Output Volume</Typography>
  //       <Typography variant="caption" component="div" gutterBottom>
  //         (what you hear during a call)
  //       </Typography>
  //       <span>{volume}%</span>
  //       <Slider value={volume} onChange={onVolumeChange} />
  //     </div>
  //   </Box>
  // );
};

export default SpeakerSetting;

import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { Dialog, TextField } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useDeviceQuery } from 'app/hooks/queries/device';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface DeviceMacAddressFields {
  mac_address: string;
}

// interface declaring which props are required/allowed
interface ContentProps {
  deviceId: string;
  onCancel: () => void;
  onComplete: () => void;
}

interface DeviceMacAddressDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

const EditMacAddressDialog = ({
  DialogProps,
  ContentProps,
}: DeviceMacAddressDialogProps) => {
  return (
    <Dialog size={'lg'} open={DialogProps.open} onClose={ContentProps.onCancel}>
      <MacAddressDialogDeviceContent {...ContentProps} />
    </Dialog>
  );
};

const MacAddressDialogDeviceContent = ({
  deviceId,
  onCancel,
  onComplete,
}: ContentProps) => {
  const { data: device, isLoading: deviceIsLoading } = useDeviceQuery(deviceId);
  const formMethods = useForm<DeviceMacAddressFields>({
    defaultValues: {
      mac_address: device?.doc.mac_address,
    },
  });
  const { setError, handleSubmit } = formMethods;

  const updateDevice = useUpdateDevicePartial();

  const onSubmit = async (deviceForm: DeviceMacAddressFields) => {
    try {
      const resp = await updateDevice.mutateAsync({
        id: deviceId,
        ...deviceForm,
      });
    } catch (e: any) {
      parseAndSetKazooMutationErrors({ response: e.response, setError });
    }
  };

  return (
    <GenericMutationDialogContent
      onComplete={onComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'MAC address has been updated'}
      isLoadingLabel={'Updating MAC address...'}
      title={'Edit MAC Address'}
      mutation={updateDevice}
      formMethods={formMethods}
      queryIsLoading={deviceIsLoading}
      queryLabel={'Loading device'}
    >
      <Controller
        // @ts-ignore
        name={`mac_address`}
        control={formMethods.control}
        render={({ field: { value, onChange, onBlur, ...props } }) => (
          <InputMask
            mask="**:**:**:**:**:**"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          >
            <TextField
              // @ts-ignore
              {...props}
              // @ts-ignore
              label="MAC address"
              // size="small"
              // fullWidth
            />
          </InputMask>
        )}
      />
    </GenericMutationDialogContent>
  );

  // return (
  //   <Dialog open={true} fullWidth maxWidth={'xs'}>
  //     {deviceIsLoading ? (
  //       <DialogInProgress title={'Loading device details info...'} />
  //     ) : updateDevice.isLoading ? (
  //       <DialogInProgress title={'Updating Device details...'} />
  //     ) : (
  //       <>
  //         <DialogTitle>Edit Device MacAddress</DialogTitle>
  //         <Divider />
  //         {device ? (
  //           <DeviceMacAddressForm
  //             defaultFormValues={formDefaults}
  //             onCancel={onCancel}
  //             onSubmit={handleSubmit}
  //             errorMessage={
  //               updateDevice.error
  //                 ? `${updateDevice.error.message} ${
  //                     updateDevice.error.response
  //                       ? `: ${updateDevice.error.response?.statusText}`
  //                       : ''
  //                   }`
  //                 : undefined
  //             }
  //           />
  //         ) : (
  //           <>
  //             <DialogContent>
  //               <Typography color={'error'}>Invalid Device ID.</Typography>
  //             </DialogContent>
  //             <Divider />
  //             <DialogActions sx={{ justifyContent: 'left' }}>
  //               <Button variant={'outlined'} color={'error'} onClick={onCancel}>
  //                 Cancel
  //               </Button>
  //             </DialogActions>
  //           </>
  //         )}
  //       </>
  //     )}
  //   </Dialog>
  // );
};

export default EditMacAddressDialog;

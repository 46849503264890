import { gql } from 'graphql-request';
import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPatchDefault,
  patchResourceDefault,
  jwtDecode,
  md5,
  buildPutDefault,
  buildDeleteDefault,
} from 'app/sdk/utils/kazoo';
import constants from 'app/constants';

///////////////
// TODO: jsDoc!
///////////////

const exports = {
  auth: {
    authenticateUserWithCredentials: async (
      username,
      account_name,
      password,
    ) => {
      const body = {
        data: {
          credentials: md5(`${username}:${password}`),
          account_name,
        },
      };

      const resp = await fetch(
        `${constants.env.REACT_APP_KAPI_URL}/user_auth`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
      );

      const respJson = await resp.json();

      // fail, return response as error for error message
      if (!resp.ok) {
        return { userAuth: null, error: respJson };
      }

      // success, return user info
      return { userAuth: respJson, error: null };
    },
    authenticateUserWithAuthToken: async authToken => {
      const { account_id } = jwtDecode(authToken);
      const requestHeaders = new Headers();
      requestHeaders.set('X-AUTH-TOKEN', authToken);

      const resp = await fetch(
        `${constants.env.REACT_APP_KAPI_URL}/accounts/${account_id}/user_auth/${authToken}`,
        {
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached,
          mode: 'cors', // no-cors, *cors, same-origin
          headers: requestHeaders,
        },
      );

      const respJson = await resp.json();

      // fail, return response as error for error message
      if (!resp.ok) {
        return { userAuth: null, error: respJson };
      }

      // success, return user info
      return { userAuth: respJson, error: null };
    },
  },

  // Queries
  // - graphql
  query: {
    // Test the user's auth_token against the server
    userByAuthTokenFromKazoo: async auth_token => {
      const { account_id, owner_id } = jwtDecode(auth_token);

      const requestHeaders = new Headers();
      requestHeaders.set('X-AUTH-TOKEN', auth_token);

      const resp = await fetch(
        `${constants.env.REACT_APP_KAPI_URL}/accounts/${account_id}/users/${owner_id}`,
        {
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached,
          headers: requestHeaders,
        },
      );

      const respJson = await resp.json();

      // fail, return response as error for error message
      if (!resp.ok) {
        return { userAuth: null, error: respJson };
      }

      // success, return user info
      return { userAuth: respJson, error: null };
    },

    // Fetch a single user by id
    userById: (vars, fetchOptions) =>
      fetchFromGraphql('userById', vars, fetchOptions),

    // fetch all users TODO: pagination
    users: (vars, fetchOptions) =>
      fetchFromGraphql('users', vars, fetchOptions),

    // only the users doc
    usersQuick: (vars, fetchOptions) =>
      fetchFromGraphql('usersQuick', vars, fetchOptions, { full: true }),

    // only the users doc
    nextUserExtension: (vars, fetchOptions) =>
      fetchFromGraphql('nextUserExtension', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    create: buildPutDefault('users', { buildUrlOpts: { skipId: true } }),
    delete: buildDeleteDefault('users'),
    updatePartial: buildPatchDefault('users'),
  },
};

export default exports;

import { gql } from 'graphql-request';

export default gql`
  query userById($id: String, $mask: String) {
    userById(id: $id, mask: $mask) {
      id
      account_id
      doc
      listing
      createdAt
      updatedAt
      hasPhoto
      extra

      #      Callflows {
      #        id
      #        doc
      #        listing
      #        createdAt
      #        updatedAt
      #      }
      #
      #      Directories {
      #        directory {
      #          id
      #          _doc
      #          _listing
      #          createdAt
      #          updatedAt
      #        }
      #        callflow {
      #          id
      #          _doc
      #          _listing
      #          createdAt
      #          updatedAt
      #        }
      #      }
      #
      #      Devices {
      #        id
      #        doc
      #        listing
      #        createdAt
      #        updatedAt
      #      }
      #
      #      Groups {
      #        id
      #        doc
      #        listing
      #        createdAt
      #        updatedAt
      #      }
      #
      #      Vmboxes {
      #        id
      #        doc
      #        listing
      #        createdAt
      #        updatedAt
      #      }
      #
      #      ExternalCallerIdNumber {
      #        id
      #        doc
      #        listing
      #        createdAt
      #        inherited
      #      }
      #
      #      InternalCallerIdNumber {
      #        id
      #        doc
      #        listing
      #        createdAt
      #        inherited
      #      }
      #
      HoldMusic {
        id
        inherited
      }
    }
  }
`;

import { gql } from 'graphql-request';

export default gql`
  query conferenceById($mask: String, $id: String) {
    conferenceById(mask: $mask, id: $id) {
      id
      account_id
      doc
      listing
    }
  }
`;

import { useAuthSelector } from 'app/data/auth';
import { useQuery } from 'react-query';
import { sdk } from 'app/sdk';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';

export const useMediaByIdQuery = (id: string | undefined) => {
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = mediaQueryKeys.byId(id);

  // if built in - id is link

  // force "enabled" to be false if ID is invalid!
  let enabled =
    !id || id?.indexOf('http') === 0 || id?.indexOf('data') === 0
      ? false
      : true;

  const query = useQuery(
    queryKey,
    () =>
      sdk.media.query.mediaById({ id }, { authToken: authState.auth_token }),
    {
      enabled,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useMediaByIdQuery;

import { Avatar } from 'app/design-lib';
import React, { useEffect, useState } from 'react';

import {
  useAuthenticatedUserQuery,
  useListUsersQuery,
  useUserQuery,
} from 'app/hooks/queries/user';
import { useAuthSelector } from 'app/data/auth';

import { useAccountQuery } from 'app/hooks/queries/account';

import { store } from 'store';

export const UserName = ({
  user,
  user_id,
  user_ext,
  children,
}: {
  user?: any;
  user_id?: string;
  user_ext?: string;
  children?: any;
}) => {
  const { account_id, auth_token } = useAuthSelector();

  const { data: loadedUser, isLoading: userIsLoading } = useUserQuery(user_id);
  const {
    data: usersPage,
    isLoading: usersLoading,
    // refetch,
  } = useListUsersQuery({
    take: 1,
    filters: {
      context: {
        presenceIds: [user_ext],
      },
    },
    // orderBy: [['presence_id', 'asc']],
    options: {
      enabled: user_ext?.length ? true : false,
    },
    quick: true,
  });
  // console.log('usersPage2:', user_ext, usersPage);
  // @ts-ignore
  const loadedUser2 = usersPage?.users?.users?.[0];

  let resource = user || loadedUser || loadedUser2;

  let name = resource?.extra?.fullName ?? null;
  // console.log('CHILDREN:', { name, children });
  return children ? children({ name, user: resource, children: name }) : name;
};

export default UserName;

import {
  createPluginFactory,
  getPointBefore,
  onKeyDownToggleMark,
  someHtmlElement,
  ToggleMarkPlugin,
} from '@udecode/plate-core';
import { Editor, Range } from 'slate';
import { MARK_VALUE } from './createValuePlugin';

export const MARK_KEYWORD = 'keyword';

/**
 * Enables support for keyword formatting
 */
export const createKeywordPlugin = createPluginFactory<ToggleMarkPlugin>({
  key: MARK_KEYWORD,
  isLeaf: true,
  // deserializeHtml: {
  //   rules: [
  //     { validNodeName: ["STRONG", "B"] },
  //     {
  //       validStyle: {
  //         fontWeight: ["600", "700", "bold"],
  //       },
  //     },
  //   ],
  //   query: (el) =>
  //     !someHtmlElement(el, (node) => node.style.fontWeight === "normal"),
  // },
  handlers: {
    // onKeyDown:
    //   (editor, { type, options: { hotkey, clear } }) =>
    //   (e) => {
    //     // console.log("type", type);
    //     const selection = editor.selection as Range;
    //
    //     const pointBefore = Editor.before(editor, selection);
    //     //
    //     // //
    //     const beforeString = Editor.string(editor, {
    //       focus: selection.focus!,
    //       anchor: pointBefore!,
    //     });
    //     // console.log(type, beforeString, selection);
    //     //
    //     // // console.log("colon", pointBefore);
    //     //
    //     // // if colon is point before
    //     if (beforeString === ":") {
    //       const after = Editor.after(editor, selection);
    //       Editor.marks(editor);
    //       // end keyword
    //       editor.removeMark(MARK_KEYWORD);
    //       // start value
    //       editor.addMark(MARK_VALUE, true);
    //     }
    //     //
    //     if (beforeString === "") {
    //       editor.addMark(MARK_VALUE, true);
    //     }
    //   },
  },
  // options: {
  //   hotkey: "mod+b",
  // },
});

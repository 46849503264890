import { Box, Divider, Typography } from 'app/design';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useWebphoneContext } from '../../Store';
import { ParkingView } from '../ParkingView';
import { CallInfo } from './CallInfo';
import { useCallsParkedQuery } from 'app/hooks/queries/calls';
import { useInterval, useScroll } from 'react-use';
import SimpleBar from 'simplebar-react';
import { groupBy } from 'lodash';
import { useLocalSelector } from '../../../../data/local';
import { CALL_DIRECTION_OUTGOING } from 'app/lib/react-sip';
import { PhoneNumberDisplay } from '../../../PhoneNumberDisplay';
import { usePipesQuery } from '../../../../pages/v2/common/sdk';
import { store } from '../../../../../store';
import { Emoji } from 'emoji-picker-react';
const getUserNumber = (call, channels) => {
  if (call._direction === CALL_DIRECTION_OUTGOING) {
    return (
      channels[0]?.listing.custom_channel_vars['E164-Origination'] ??
      channels[1]?.listing.custom_channel_vars['E164-Origination'] ??
      '_'
    );
  }

  return (
    channels[0]?.listing.custom_channel_vars['E164-Destination'] ??
    channels[1]?.listing.custom_channel_vars['E164-Destination'] ??
    '_'
  );
};
const CallsView = (props, context) => {
  const { show, show_only_ringing = false } = props;

  const pipesQuery = usePipesQuery({
    filters: {
      type: 'external',
    },
  });

  const externalPipes = pipesQuery.data?.pipes ?? [];

  const [state, dispatchWebphone] = useWebphoneContext();
  const { calls } = state;
  const { channels } = useLocalSelector();

  const { data, refetch, isFetching } = useCallsParkedQuery();

  // if a call ends and no other active calls/parked calls, select dialer view
  useEffect(() => {
    if (
      state.mainTab === 'calls' &&
      !data?.totalCount &&
      !calls?.length &&
      !isFetching
    ) {
      dispatchWebphone({
        type: 'sip:update',
        payload: {
          mainTab: 'dialer',
        },
      });
    }
  }, [data?.totalCount, calls, isFetching]);

  // map calls to their respective lines
  const lines = useMemo(() => {
    const lineMap = new Map();
    const channelsGrouped = groupBy(channels, 'listing.interaction_id');

    calls
      ?.filter(c => (show_only_ringing ? c.isRinging() : !c.isRinging()))
      ?.forEach(call => {
        let added = false;
        const interactions = Object.values(channelsGrouped);

        for (const legs of interactions) {
          // find interaction for call
          if (legs?.find(leg => call?._request?.call_id === leg.id)) {
            const userNumber = getUserNumber(call, legs);

            lineMap.set(userNumber, [
              ...(lineMap.has(userNumber) ? lineMap.get(userNumber) : []),
              call,
            ]);

            added = true;
            break;
          }
        }

        // no match, list as generic (should never be case?)
        if (!added)
          if (lineMap.has('_')) {
            lineMap.set('_', [...lineMap.get('_'), call]);
          } else {
            lineMap.set('_', [call]);
          }
      });

    return Array.from(lineMap).map(([line, calls]) => ({ line, calls }));

    // console.log('grouped channels', interactions);
  }, [calls, channels, channels?.length, calls?.length]);
  // console.log('state', state);
  // const calls = [];
  // const [filterText, setFilterText] = useState('');

  const ref = useRef(null);
  const scrollRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(1);
  const [topVal, topValSet] = useState([0, 0]);
  // TODO: only run this when dragging, or when resizing (element, window)?
  useInterval(() => {
    if (ref.current && show) {
      // console.log('ref.current.getBoundingClientRect:', ref.current);
      let v = ref.current.getBoundingClientRect();
      let val = window.innerHeight - v.top - 8;
      if (maxHeight != val) {
        setMaxHeight(val);
        topValSet([ref.current.offsetHeight, ref.current.scrollHeight]);
      }
    }
  }, 16);
  const { y } = useScroll(ref);
  let atEnd = y + topVal[0] >= topVal[1] ? true : false;

  return (
    <Box
      sx={{
        display: show ? 'flex' : 'none',
        flexDirection: 'column',
      }}
    >
      <SimpleBar
        scrollableNodeProps={{ ref: ref }}
        className={[
          'flex flex-col items-center h-full',
          y > 0 ? 'shadow-inner' : '',
        ].join(' ')}
        style={{ maxHeight: maxHeight + 'px' }}
      >
        <div className="flex-1">
          {
            lines.length
              ? lines.map(({ line, calls }) => {
                  const pipe = externalPipes.find(pipe => pipe.key === line);

                  return (
                    <div
                    // sx={{
                    //   '& > div': {
                    //     borderBottom: '1px solid #fdfdfd',
                    //   },
                    // }}
                    >
                      <div
                        className={`${
                          show_only_ringing ? 'bg-black text-white' : ''
                        } px-2 pt-2 text-opacity-70 whitespace-nowrap space-x-1 flex text-sm`}
                      >
                        <span>
                          {`${calls.length} call${
                            calls.length > 1 ? 's' : ''
                          } ${line !== '_' ? 'on ' : ''}`}{' '}
                        </span>
                        {line === '_' ? null : (
                          <div className={`flex items-center space-x-1`}>
                            <Emoji size={14} unified={pipe.metadata.emoji} />

                            <span className={`font-medium`}>
                              {pipe.metadata.name}
                            </span>
                            <PhoneNumberDisplay hideFlag ptn={line} />
                          </div>
                        )}
                      </div>
                      {calls.map((call, i) => (
                        <React.Fragment key={call.getId()}>
                          <CallInfo
                            call={call}
                            localVideoRef={props.localVideoRef}
                            remoteVideoRef={props.remoteVideoRef}
                            // externalPipes={externalPipes}
                          />
                        </React.Fragment>
                      ))}
                      <Divider />
                    </div>
                  );
                })
              : null
            // <div className="h-full p-2 grid place-items-center">
            //   <div className="text-lg">No Calls In Progress</div>
            // </div>
          }
        </div>
        {/* <div>
          <ParkingView {...props} />
        </div> */}
      </SimpleBar>
      <div
        className={[
          'fixed bottom-0 left-0 w-full h-[8px] bg-gradient-to-t from-neutral-300/50',
          atEnd ? 'hidden' : '',
        ].join(' ')}
      ></div>
    </Box>
  );

  // return (
  //   <Box
  //     sx={{
  //       height: '100%',
  //       overflowY: 'scroll',
  //       display: show ? 'block' : 'none',
  //     }}
  //   ></Box>
  // );
};

export default CallsView;

import * as React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from 'app/design';

import {
  NavigateNext as NavigateNextIcon,
  Today as TodayIcon,
  Redo as RedoIcon,
  Edit as EditIcon,
  Eject as EjectIcon,
  Link as LinkIcon,
  ChevronRight as ChevronRightIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  AddToPhotos as AddToPhotosIcon,
  Person as PersonIcon,
  Groups as GroupsIcon,
  Voicemail as VoicemailIcon,
  Business as BusinessIcon,
  DevicesOther as DevicesOtherIcon,
  CalendarMonth as CalendarMonthIcon,
} from 'app/design/icons-material';

import { useCreateCallflow } from 'app/hooks/mutations/callflow';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { toast } from 'react-toastify';

import { cloneDeep } from 'lodash';

import { useCallRoutingDialog } from 'app/components/CallRoutingDialog';
import { useToggleReducer } from 'app/utilities';

import { useHistory } from 'react-router-dom';

// interface NameAndTypeCellProps {
//   onComplete: () => void;
// }

const NameAndTypeCell = ({ row, value: nameOld }) => {
  const {
    toggleOpen: toggleOpenCallRoutingDialog,
    CallRoutingDialog,
    CallRoutingDialogProps,
  } = useCallRoutingDialog({});

  const { original: callflow } = row;
  let link: string;
  let textInsteadLink: string; // we dont actually show this anymore...
  let name: any = callflow.doc.name;

  switch (callflow.doc.type) {
    case 'main':
      // redirect to the correct place for handling this for an account, user, group, etc
      switch (callflow.doc.owner_type) {
        case 'account':
          switch (callflow.doc.type) {
            case 'main':
              link = `/admin/dashboard/call_routing`;
              name = (
                <Box
                  className={'text-teal-500'}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <BusinessIcon sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                  <span>Account Incoming Calls</span>
                </Box>
              );
              break;
            default:
              textInsteadLink = 'Unknown type of call route';
              break;
          }
          break;
        case 'user':
          switch (callflow.doc.type) {
            case 'main':
              link = `/admin/${callflow.doc.owner_type}s/view/${callflow.doc.owner_id}/call_routing`;
              // name = `${callflow.Owner?.extra?.fullName} Direct Calls`;
              name = (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                  <span>Direct Calls</span>
                </Box>
              );
              break;
            default:
              textInsteadLink = 'Unknown type of call route';
              break;
          }
          break;
        case 'group':
          switch (callflow.doc.type) {
            case 'main':
              link = `/admin/${callflow.doc.owner_type}s/view/${callflow.doc.owner_id}/call_routing`;
              name = (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <GroupsIcon sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                  <span>Direct Calls</span>
                </Box>
              );
              break;
            default:
              textInsteadLink = 'Unknown type of call route';
              break;
          }
          break;
        case 'device':
          switch (callflow.doc.type) {
            case 'main':
              link = `/admin/${callflow.doc.owner_type}s/view/${callflow.doc.owner_id}/call_routing`;
              name = (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DevicesOtherIcon sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                  <span>Direct Calls</span>
                </Box>
              );
              break;
            default:
              textInsteadLink = 'Unknown type of call route';
              break;
          }
          link = `/admin/${callflow.doc.owner_type}s/view/${callflow.doc.owner_id}/call_routing`;
          break;
        case 'vmbox':
          switch (callflow.doc.type) {
            case 'main':
              link = `/admin/${callflow.doc.owner_type}es/view/${callflow.doc.owner_id}/call_routing`;
              name = (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <VoicemailIcon sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                  <span>Direct Calls</span>
                </Box>
              );
              break;
            default:
              textInsteadLink = 'Unknown type of call route';
              break;
          }
          break;
        default:
          // link = `/admin/ivr/unknown/edit/${callflow.doc.id}`;
          textInsteadLink = 'Invalid owner type for this callflow';
          break;
      }
      break;
    case 'schedule':
      link = `/admin/schedule/edit/${callflow.doc.id}`;
      name = (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CalendarMonthIcon sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
          <span>{callflow.doc.name}</span>
        </Box>
      );
      break;
    case 'general':
      link = `/admin/general/call_routing/${callflow.doc.id}`;
      break;
    case 'template':
      link = `/admin/template/edit/${callflow.doc.id}`;
      break;
    case 'nomatch':
      // link = null; //`/admin/template/edit/${callflow.doc.id}`;
      name = 'Outgoing Calls';
      textInsteadLink =
        'Sorry, this callflow type is locked and not available for editing';
      break;
    default:
      textInsteadLink = 'Unable to modify this callflow';
      break;
  }

  return (
    <>
      {/* <CallRoutingDialog
        {...CallRoutingDialogProps}
        // name_type="general"
        owner_type={callflow.doc.owner_type}
        type={callflow.doc.type}
        // resourceId={device.id}
        // defaultNumbers={[]}
        callflow={callflow}
        onComplete={() => {
          toggleOpenCallRoutingDialog();
          // refetch();
          onComplete();
        }}
      /> */}
      <div className={'text-lg font-medium'}>
        {/* @ts-ignore */}
        {textInsteadLink ? (
          <Box sx={{ display: 'flex', alignItems: 'top' }}>
            {name ?? 'Unnamed Callflow'}
          </Box>
        ) : (
          <Link
            // component={RouterLink}
            // @ts-ignore
            // to={link ? link : undefined}
            // @ts-ignore
            // onClick={link ? undefined : () => alert(textInsteadLink)}
            // disabled
            // onClick={
            //   // @ts-ignore
            //   textInsteadLink
            //     ? () => alert(textInsteadLink)
            //     : toggleOpenCallRoutingDialog
            // }
            href={link}
            underline="hover"
          >
            {name ?? 'Unnamed Callflow'}
          </Link>
        )}
      </div>
      {/* <Typography variant="caption">
        ({callflow.doc.type ?? 'unknown'})
      </Typography> */}
    </>
  );
};

export default NameAndTypeCell;

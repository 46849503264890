import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import Button, { ButtonProps } from 'app/design-lib/components/Button/Button';
import { IconButton } from 'app/design-lib/components/IconButton';
import { Color } from 'app/design-lib/types';
import { Emoji } from 'emoji-picker-react';
import { Cancel as CloseIcon } from 'iconoir-react';
import React, { ReactElement, ReactNode } from 'react';

export interface AlertProps {
  icon?: ReactElement;
  emoji?: ReactElement;
  label: string;
  labelSize?: 'md' | 'lg' | 'xl' | '2xl';
  body?: ReactNode;
  actionProps?: ButtonProps;
  onClose?: () => void;
  className?: string;
  color?: Color;
  reverse?: boolean;
  rounded?: boolean;
}

export const Alert = ({
  icon,
  label,
  labelSize = 'md',
  body,
  actionProps,
  onClose,
  className,
  color = 'neutral',
  reverse,
  emoji,
  rounded = true,
}: AlertProps) => {
  return (
    <div
      className={`${
        reverse ? `bg-${color}-10 text-black` : `bg-${color}-60 text-white`
      } ${className ?? ''} ${rounded ? 'rounded-lg' : ''} pb-2 pt-1 pl-3 pr-3 ${
        icon ? '' : 'pl-3'
      } flex items-start`}
    >
      {icon ? (
        <div>
          {React.cloneElement(icon, {
            className: `${
              reverse ? `text-${color}-60` : ``
            } h-6 w-6 mt-1 mr-2.5 aspect-square`,
          })}
        </div>
      ) : emoji ? (
        <div>
          {React.cloneElement(emoji, {
            className: `${
              reverse ? `text-${color}-60` : ``
            } h-6 w-6 mt-[7px]  aspect-square`,
          })}
        </div>
      ) : null}
      <div className={`${icon ? 'pt-1.5' : 'pt-1.5'} flex flex-1 flex-col`}>
        <span className={`text-${labelSize} font-medium`}>{label}</span>
        {body ? <div className={'text-sm'}>{body}</div> : null}
        {actionProps ? (
          <Button
            variant={'ghost'}
            size={'sm'}
            color={reverse ? color : 'neutral-reverse'}
            {...(actionProps as any)}
            className={`-ml-1.5 mt-2`}
          >
            {actionProps.children}
          </Button>
        ) : null}
      </div>
      {onClose ? (
        <IconButton
          onClick={onClose}
          size={'md'}
          variant={'ghost'}
          color={reverse ? color : 'neutral-reverse'}
        >
          <CloseIcon fr={undefined} />
        </IconButton>
      ) : null}
    </div>
  );
};

export default Alert;

import { useAuthSelector } from 'app/data/auth';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { sdk } from 'app/sdk';
import { Contact } from 'types/contact';
import { DevicesPageResponse } from 'types/device';
// import { ContactsPageResponse } from 'types/contact';
import contactQueryKeys from './contactQueryKeys';

interface QueryParams {
  skip?: number;
  take?: number;
  orderBy?: any;
  filters?: any;
  // mask?: any;
  options?: Omit<
    UseQueryOptions<Contact[], Error, Contact[], QueryKey>,
    'queryKey' | 'queryFn'
  >;
  // quick?: boolean;
}

export const useContactsQuery = ({
  skip = 0,
  take = 10000,
  orderBy,
  filters = {},
  options,
}: QueryParams) => {
  // returns list of contacts
  // - just get ALL contacts+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = contactQueryKeys.list(skip, take, orderBy, filters);

  const query = useQuery(
    queryKey,
    () =>
      sdk.contact.query.contacts(
        {
          skip,
          take,
          orderBy,
          filters,
        },
        { authToken: authState.auth_token },
      ),
    {
      ...options,
      // enabled: authState?.auth_token_decoded?.owner_id ? true : false,
      // prevents pagination from looking like a hard load;
      //  - keeps previous data displayed until new data arrives
      keepPreviousData: true,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useContactsQuery;

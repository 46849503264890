import { useFindInPage } from 'app/components/FindInPage';
import { Card, CardContent, CircularProgress } from 'app/design';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogLoading,
  SquareProgress,
} from 'app/design-lib';
import { useUpdateGroupPartial } from 'app/hooks/mutations/group';
import { BannerAlert } from 'app/design/components/tailwind/AlertBanner';
import { RingGroup } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard/RingGroup';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Group } from 'types/group';
import CheckCircleIcon from '@mui/icons-material/Check';

interface GroupRingStrategyCardProps {
  group: Group;
  onComplete: () => void;
}

const GroupRingStrategyCard = ({
  group,
  onComplete,
}: GroupRingStrategyCardProps) => {
  const [error, setError] = useState<string | null>(null);
  // const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
  //   name: 'ringStrategy',
  //   options: [
  //     {
  //       label: 'Ring Strategy',
  //       value: 'ringStrategy',
  //     },
  //   ],
  //   onChoose: 'matchAnyScrollToRef',
  // });
  const updateGroup = useUpdateGroupPartial();

  const [tmpGroup, setTmpGroup] = useState(() =>
    JSON.parse(JSON.stringify(group)),
  );

  const handleSave = async () => {
    setError(null);
    try {
      const resp = await updateGroup.mutateAsync(
        tmpGroup.doc,
        //   {
        //   onSuccess: () => {
        //     onComplete();
        //   },
        //   onError: () => {
        //     handleReset();
        //   },
        // }
      );

      // resolve promise with toast notifications
      // const resp = await toast.promise(updateGroupPromise, {
      //   pending: 'Updating group ring strategy...',
      //   error: 'Failed to update group ring strategy.',
      //   success: 'Group ring strategy updated!',
      // });

      if (resp.status !== 'success') {
        console.error(resp);
        setError('Please try again later.');
      }
    } catch (e: any) {
      setError(
        `${e.message}. Please Try again later.` ?? 'Please try again later.',
      );
      // handleReset();
    }
    // const returnedGroup = await KazooSDK.resourceGroupPatch(tmpGroup.doc);
    // dispatchSyncSingleItem({ collectionKey: 'groups', id: group.id });
  };

  const handleReset = async () => {
    setTmpGroup(JSON.parse(JSON.stringify(group)));
  };

  const [formDisable, toggleDisabled] = useToggleReducer(true);
  const disabled = formDisable;
  const variant = disabled ? 'standard' : 'outlined';

  const methods = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: group.doc.name,
    },
    // resolver: joiResolver(schema),
  });

  const {
    formState: { isDirty },
  } = methods;

  return (
    <>
      {updateGroup.isLoading ? (
        <DialogLoading label={'Updating ring group...'} />
      ) : updateGroup.isSuccess ? (
        <>
          <DialogContent>
            <div
              className={
                'flex items-center space-x-1 mt-6 justify-center text-lg font-medium'
              }
            >
              <CheckCircleIcon className={'text-green-400 text-2xl'} />
              <div>{'Ring Group Saved!'}</div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              size={'md'}
              onClick={onComplete}
              variant={'fill'}
              color={'accent'}
            >
              Dismiss
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          {/* {!!group.doc.type ? null : (
        <BannerAlert
          type={'warning'}
          title={'Warning: Ring Group has not been setup'}
          subTitle={
            'Select a ring strategy below to begin and add users and/or devices'
          }
        />
      )}*/}
          {/* @ts-ignore */}
          {/* <Card ref={ref} variant="dashboard"> */}
          {/* @ts-ignore */}

          {/*<div ref={ref}>*/}
          {/*<HighlightPulse {...highlightPulseProps} />*/}
          {/*<CardHeader title="Group Ring Strategy" />*/}
          {/* <CardContent sx={{ p: 4, px: 5 }}> */}
          <RingGroup
            allow={['user', 'device']}
            modifyPath={`doc`} // root-level??
            strategyCallflow={tmpGroup}
            setStrategyCallflow={setTmpGroup} // SHOULD BE UNCOMMENTED!
            customizeOptions={{}} // TODO: set defaults for timeout, strategy, limits to types of devices, etc
            required
            startEditing={!disabled}
            useGroupFormat
            onSave={handleSave}
            onReset={handleReset}
            onClose={onComplete}
            errorMessage={error}
          />
          {/* </CardContent> */}
          {/* </Card> */}
          {/*</div>*/}
        </>
      )}
    </>
  );
};

export default GroupRingStrategyCard;

import { AddPhonesBulkForm } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm';
import { DialogLoading } from 'app/design-lib';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import React from 'react';
import { useImmer } from 'use-immer';
import { useListUsersQuery } from 'app/hooks/queries/user';

export const CreatePhonesBulkv2 = props => {
  // Load all Users and Extensions and VmBoxes (anything where there can be a conflict)
  const [isRefetching, setIsRefetching] = useImmer(false);

  // Extensions
  const {
    data: extensionsResult = {},
    isLoading: extensionsIsLoading,
    refetch: refetchExtensions,
  } = useListExtensionsQuery({
    mask: 'number,callflow(id,doc(type,owner_type))', // mask
  });
  // @ts-ignore
  const { extensions = [] } = extensionsResult ?? {};

  // Devices
  const {
    data: devicesResp,
    isLoading: devicesIsLoading,
    refetch: refetchDevices,
  } = useListDevicesQuery({
    mask: 'id,doc(mac_address)', // mask
    quick: true, // quick
  });
  // @ts-ignore
  const { devices = [] } = devicesResp?.devices ?? {};

  // Users
  const {
    data: usersResp,
    isLoading: usersIsLoading,
    refetch: refetchUsers,
  } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};

  const isLoading = devicesIsLoading || extensionsIsLoading || usersIsLoading;

  const handleRefetch = async (silent = true) => {
    // silent to not show backdrop unless it is an error
    if (silent) {
      await Promise.all([refetchDevices(), refetchExtensions()]);
    } else {
      setIsRefetching(true);
      await Promise.all([refetchDevices(), refetchExtensions()]);

      setIsRefetching(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <DialogLoading
          label={'Loading existing phone data...'}
          secondaryText={'This may take a moment.'}
        />
      ) : (
        <AddPhonesBulkForm
          extensions={extensions}
          devices={devices}
          onRefetch={handleRefetch}
          isRefetching={isRefetching}
          onComplete={props.onComplete}
          users={users}
        />
      )}
    </>
  );
};

export default CreatePhonesBulkv2;

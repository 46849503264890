import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from 'app/design';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import { buildOutputCallflowFromTemplate } from 'app/components/IvrBuilder/Flow/nodes/Template';
import { QuickFinderTemplateDialog } from 'app/components/QuickFinderTemplateDialog';
import {
  useCreateCallflow,
  useUpdateCallflowPartial,
} from 'app/hooks/mutations/callflow';
import { useToggleReducer } from 'app/utilities';
import { cloneDeep, startCase } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

interface FixCallHandlingDialogProps {
  resourceId: string;
  type: 'account' | 'group' | 'device' | 'user' | 'vmbox';
  onCancel: () => void;
}

const FixCallHandlingDialog = ({
  resourceId,
  type,
  onCancel,
}: FixCallHandlingDialogProps) => {
  const router = useHistory();
  const updateCallflow = useUpdateCallflowPartial();
  const createCallflow = useCreateCallflow();
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const link =
    type === 'account'
      ? '/admin/dashboard/call_routing'
      : type === 'vmbox'
      ? `/admin/vmboxes/view/${resourceId}/call_routing`
      : `/admin/${type}s/view/${resourceId}/call_routing`;

  const handleSelect = async (selected: any[]) => {
    const [template] = selected;
    const templateDoc = cloneDeep(template.doc);

    const newCallflow = {
      name: `${startCase(type)} Main`,
      type: 'main',
      owner_id: resourceId,
      owner_type: type,
      numbers: [`strategy:temp:${Date.now()}`],
      flow: {
        module: 'callflow',
        data: { id: 'placeholder' },
      },
      metaflow: {},
      strategy: {
        type: 'blank',
        data: {
          modules: [
            {
              type: 'Template',
              data: {
                templateId: template.id,
                continueTo: [],
              },
            },
          ],
        },
      },
    };

    await applyTemplate(newCallflow, templateDoc);

    const resp = await createCallflow.mutateAsync(newCallflow);

    if (resp.success) {
      router.push(link);
    }
  };

  const applyTemplate = async (callflow, template) => {
    // modifies "callflow" by reference

    const moduleItem = callflow.strategy.data.modules[0];

    const newData = {
      ...moduleItem.data,
      outputCallflow: cloneDeep(template),
    };
    newData.outputCallflow.id = 'inline';
    newData.outputCallflow.changed = true; // to force a rebuild the first time

    // TODO: build outputCallflow now?
    // - apply variables, etc.
    // for each strategy element, see if there are Variables, process them and build as we go...
    newData.outputCallflow = await buildOutputCallflowFromTemplate({
      rootCallflow: callflow, // root variables
      templateCallflow: newData.outputCallflow, // template-level variables
    });
    moduleItem.data = newData;
  };

  const handleNew = async () => {
    const resp = await createCallflow.mutateAsync(
      {
        name: `${startCase(type)} Main`,
        numbers: [],
        owner_id: resourceId,
        owner_type: type,
        type: 'main',
        strategy: {
          type: 'blank',
          data: {
            modules: [],
          },
        },
      },
      // {
      //   onSuccess: resp => {
      //     // const { data }: { data: CallflowDoc } = resp;
      //     router.push(link);
      //   },
      // },
    );

    if (resp.success) {
      router.push(link);
    }
  };

  const errorMessage = updateCallflow.error
    ? `${updateCallflow.error.message} ${
        updateCallflow.error.response
          ? `: ${updateCallflow.error.response?.statusText}`
          : ''
      }`
    : createCallflow.error
    ? `${createCallflow.error.message} ${
        createCallflow.error.response
          ? `: ${createCallflow.error.response?.statusText}`
          : ''
      }`
    : undefined;

  return (
    <>
      <QuickFinderTemplateDialog
        open={showEditDialog}
        passResourceOnSelect
        onSelect={handleSelect}
        onCancel={toggleShowEditDialog}
        mutationLoadingLabel={'Updating call routing...'}
        mutationLoading={updateCallflow.isLoading || createCallflow.isLoading}
      />
      <Dialog open={true} fullWidth maxWidth={'xs'}>
        {updateCallflow.isLoading || createCallflow.isLoading ? (
          <DialogCircularProgress label={'Updating call routing'} />
        ) : (
          <>
            <DialogTitle>Add Call Routing</DialogTitle>
            <Divider />
            <DialogContent
              sx={{
                display: 'grid',
                placeItems: 'center',
                width: '100%',
              }}
            >
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={toggleShowEditDialog}
              >
                Select from Template
              </Button>
              <br />
              <Divider sx={{ width: '100%' }}>or</Divider>
              <br />
              <Button
                color={'primary'}
                onClick={handleNew}
                variant={'contained'}
              >
                Start with Blank
              </Button>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                Cancel
              </Button>
              {errorMessage ? (
                <Typography color={'error'}>{errorMessage}</Typography>
              ) : null}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default FixCallHandlingDialog;

import { gql } from 'graphql-request';

export default gql`
  query groupById($id: String, $mask: String) {
    groupById(id: $id, mask: $mask) {
      id
      account_id
      doc
      listing
      createdAt
      updatedAt

      Callflows {
        id
        _doc
        _listing
        createdAt
        updatedAt
      }

      HoldMusic {
        id
        inherited
      }
    }
  }
`;

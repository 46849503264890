import { Drawer } from 'app/design';
import { startCase } from 'lodash';
import React from 'react';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useDispatch } from 'react-redux';
import { Webphone, useWebphoneContext } from 'app/components/Webphone';

const WebphoneContainer = props => {
  const dispatch = useDispatch();
  const { webphone_visible } = useLocalSelector();
  const { actions: localActions } = useLocalSlice();
  const [webphoneState] = useWebphoneContext();

  const { calls } = webphoneState;

  let open = webphone_visible;

  let allowClose = true;
  if (calls && calls !== undefined) {
    const hasCalls = calls.length > 0;
    if (hasCalls) {
      // open = true;
      // allowClose = false;
    }
  }
  const handleClose = () => {
    if (!allowClose) {
      return false;
    }
    dispatch(localActions.set_webphone_visible(false));
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { width: '480px' },
        }}
      >
        <Webphone />
      </Drawer>
    </>
  );
};

export default WebphoneContainer;

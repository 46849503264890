import {
  AnimationDialog,
  Chip,
  CircularProgress,
  DialogContent,
  Divider,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'app/design';
import { Dialog, DialogHeader, DialogTitle } from 'app/design-lib';
import {
  Block as BlockIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
} from 'app/design/icons-material';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AxiosError } from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { User } from 'types/user';
import { useImmer } from 'use-immer';

interface SuspendProps {
  suspend?: boolean;
  selectedRows?: User[];
  onComplete: () => void;
  onCancel: () => void;
}

interface SuspendBulkUsersWrapperProps extends SuspendProps {
  open: boolean;
}
const SuspendBulkUsersWrapper = ({
  open,
  ...props
}: SuspendBulkUsersWrapperProps) => {
  return (
    <Dialog size={'lg'} open={open} onClose={props.onCancel}>
      <SuspendBulkUsers {...props} />
    </Dialog>
  );
};

const SuspendBulkUsers = ({
  suspend = true,
  selectedRows: selectedUsers,
  onComplete,
  onCancel,
}: SuspendProps) => {
  const updateUser = useUpdateUserPartial();
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const { isLoading } = updateUser;
  // array to track mutation progress by index
  const [mutationArray, setMutationArray] = useImmer<
    {
      status: string | null;
    }[]
  >(() => selectedUsers?.map(user => ({ status: null })) ?? []);
  const [error, setError] = useImmer<AxiosError | null>(null);

  useEffect(() => {
    setMutationArray(selectedUsers?.map(user => ({ status: null })) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  if (!selectedUsers)
    return <Typography variant={'error'}>No users selected!</Typography>;

  const handleSuspend = async () => {
    for (let i = 0; i < selectedUsers.length; i++) {
      if (error) break;

      const user = selectedUsers[i];

      // skip suspending current user if selected
      if (user.id === authenticatedUser?.id) {
        continue;
      }

      // // user already suspended
      // if (!user.doc.enabled) {
      //   setMutationArray(prevState => {
      //     prevState[i].status = 'success';
      //   });
      //   continue;
      // }

      setMutationArray(prevState => {
        prevState[i].status = 'loading';
      });

      await updateUser.mutateAsync(
        { id: user.id, enabled: !suspend },
        {
          onSuccess: () => {
            setMutationArray(prevState => {
              prevState[i].status = 'success';
            });
          },
          onError: error => {
            setMutationArray(prevState => {
              prevState[i].status = 'error';
            });
            setError(error);
          },
        },
      );
    }
  };

  const handleDone = () => {
    onComplete();
    updateUser.reset();
    setMutationArray(prevState => {
      return prevState.map(row => ({ ...row, status: null }));
    });
    setError(null);
  };

  const label = suspend ? 'Suspend' : 'Unsuspend';

  return (
    <>
      {isLoading ? null : (
        <>
          <DialogHeader onClose={onCancel} title={`${label} User(s)`} />
        </>
      )}
      <DialogContent
        sx={{ display: 'grid', placeItems: 'center', width: '100%' }}
      >
        <Typography variant={'h6'}>
          {updateUser.isSuccess
            ? `Successfully ${label.toLowerCase()}ed user(s):`
            : updateUser.isLoading
            ? `${label}ing user(s)...`
            : error
            ? `Error ${label.toLowerCase()}ing user(s):`
            : `Are you sure you want to ${label.toLowerCase()} ${
                selectedUsers.filter(user => user.id !== authenticatedUser?.id)
                  .length
              } user(s)?`}
        </Typography>
        <br />
        <div>
          <Table>
            {selectedUsers.map((user, index) => (
              <TableRow sx={{ height: 65 }}>
                {user.id === authenticatedUser?.id ? (
                  <>
                    <Tooltip
                      arrow
                      placement={'left-end'}
                      title={
                        suspend
                          ? `You cannot suspend yourself. This action will be skipped.`
                          : 'This action has no effect and will be skipped'
                      }
                    >
                      <TableCell>
                        <BlockIcon sx={{ color: 'text.secondary' }} />
                      </TableCell>
                    </Tooltip>
                    <TableCell>
                      <Typography
                        sx={{
                          color: 'text.secondary',
                        }}
                      >
                        {`${user.doc.first_name} ${user.doc.last_name}`}
                      </Typography>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      {mutationArray[index].status === 'loading' ? (
                        <CircularProgress size={20} />
                      ) : mutationArray[index].status === 'success' ? (
                        <CheckIcon color={'success'} />
                      ) : mutationArray[index].status === 'error' ? (
                        <ClearIcon color={'error'} />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {`${user.doc.first_name} ${user.doc.last_name}`}
                      </Typography>
                    </TableCell>
                  </>
                )}
                <TableCell>
                  {user.doc.priv_level === 'admin' ? (
                    <Chip label={'Admin'} color={'primary'} size={'small'} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </div>
      </DialogContent>
      {!isLoading ? (
        <DefaultDialogActions
          saveLabel={updateUser.isSuccess ? 'Done' : 'Confirm'}
          onCancel={
            error ? handleDone : updateUser.isSuccess ? undefined : onCancel
          }
          cancelLabel={error ? 'Close' : undefined}
          onSave={
            error
              ? undefined
              : updateUser.isSuccess
              ? handleDone
              : handleSuspend
          }
          errorMessage={error?.message}
        />
      ) : null}
    </>
  );
};

export default SuspendBulkUsersWrapper;

import { Box, Button, Grid, Link, Typography } from 'app/design';
import React from 'react';

const BuyGreeting = () => {
  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      <span className={'text-lg font-medium'}>Visit:</span>
      <br />
      <div>
        <div>
          <a
            className={'text-content-accent underline'}
            href={'https://www.snaprecordings.com/'}
            target={'_blank'}
            rel="noreferrer"
          >
            Snap Recordings
          </a>
        </div>
        <div>
          <a
            className={'text-content-accent underline'}
            href={'https://voicestudiopro.com/'}
            target={'_blank'}
            rel="noreferrer"
          >
            VoiceStudio Pro
          </a>
        </div>
      </div>
      <br />
      <a
        className={'text-content-accent underline'}
        target={'_blank'}
        href={
          'https://fitsmallbusiness.com/best-professional-voicemail-greeting-providers'
        }
        rel="noreferrer"
      >
        View comparison
      </a>
    </div>
  );
};

export default BuyGreeting;

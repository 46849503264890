import { useAuthSelector } from 'app/data/auth';
import temporalRulesSetQueryKeys from 'app/hooks/queries/temporalRulesSet/temporalRulesSetQueryKeys';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { TemporalRulesSetsPageResponse } from 'types/temporalRulesSet';

interface QueryParams {
  skip?: number;
  take?: number;
  orderBy?: any;
  filters?: any;
  options?: Omit<
    UseQueryOptions<
      TemporalRulesSetsPageResponse,
      Error,
      TemporalRulesSetsPageResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >;
}

export const useListTemporalRulesSetsQuery = ({
  skip = 0,
  take = 10000,
  orderBy = [['name', 'asc']],
  filters = {},
  options,
}: QueryParams) => {
  // returns list of temporalRulesSets
  // - just get ALL temporalRulesSets+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = temporalRulesSetQueryKeys.list(skip, take, orderBy, filters);

  const query = useQuery<TemporalRulesSetsPageResponse, Error>(
    queryKey,
    () =>
      sdk.temporalRulesSet.query.temporalRulesSets(
        {
          skip,
          take,
          orderBy,
          filters,
        },
        { authToken: authState.auth_token },
      ),
    {
      // enabled: authState?.auth_token_decoded?.owner_id ? true : false,

      // prevents pagination from looking like a hard load;
      //  - keeps previous data displayed until new data arrives
      keepPreviousData: true,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useListTemporalRulesSetsQuery;

import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
  InfoTooltip,
  Chip,
  CircularProgress,
  Portal,
} from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useAccountQuery } from 'app/hooks/queries/account';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { CallerIdDialogAccount } from 'app/components/CallerIdDialogAccount';
import { useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { startCase } from 'lodash';

import { useCallback, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Account } from 'types/account';

interface AccountCallerIdCardProps {
  onComplete: () => void;
  setDialogShown: any;
  account: Account;
}

const AccountCallerIdCard = ({
  account,
  setDialogShown,
  onComplete,
}: AccountCallerIdCardProps) => {
  const [showEditDialog, toggleEditShowDialog] = useToggleReducer(false);

  useEffect(() => {
    setDialogShown(showEditDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditDialog]);
  // account external CID
  let externalCid = account?.doc.caller_id?.external?.number;

  // account emergency CID
  let emergencyCid = account?.doc.caller_id?.emergency?.number;

  const {
    data: externalNumberData,
    isLoading: externalNumberIsLoading,
    refetch: refetchExternal,
  } = usePhoneNumberQuery({ id: externalCid });

  const {
    data: emergencyNumberData,
    isLoading: emergencyNumberIsLoading,
    refetch: refetchEmergency,
  } = usePhoneNumberQuery({ id: emergencyCid });

  const externalName = externalNumberData?.doc.cnam?.display_name;
  const externalNumber = externalNumberData?.doc.id;

  const emergencyName = emergencyNumberData?.doc.cnam?.display_name;
  const emergencyAddress = emergencyNumberData?.doc.e911?.street_address;

  const handleComplete = () => {
    onComplete();
    refetchExternal();
    refetchEmergency();
    toggleEditShowDialog();
  };

  const { t } = useMarkdownTranslate();

  return (
    <>
      {showEditDialog ? (
        <CallerIdDialogAccount
          onCancel={toggleEditShowDialog}
          onComplete={handleComplete}
        />
      ) : null}
      {/* @ts-ignore */}
      <Card variant="outlined">
        {/* <HighlightPulse {...highlightPulseProps} /> */}
        <CardHeader
          disableTypography
          title={
            <Grid container>
              <Grid item sx={{ flex: 1 }}>
                <Typography variant="h2">Caller ID</Typography>
              </Grid>
              {emergencyNumberIsLoading || externalNumberIsLoading ? (
                <Grid item>
                  <CircularProgress size={15} />
                </Grid>
              ) : null}
              <Grid item>
                <Link
                  onClick={toggleEditShowDialog}
                  underline="hover"
                  variant="body2"
                >
                  Edit Caller ID
                </Link>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Grid container>
            {/* @ts-ignore */}
            <Grid item xs={6} sx={{ position: 'relative' }}>
              {/* <HighlightPulseExt {...highlightPulsePropsExt} /> */}
              <Typography variant="h3">
                External
                <InfoTooltip
                  title={t('caller_id.external.tooltip')}
                  size={'small'}
                />
              </Typography>
              <Typography
                variant="number1"
                sx={
                  externalName
                    ? {}
                    : { color: 'text.secondary', fontStyle: 'italic' }
                }
              >
                {externalNumberIsLoading ? (
                  <>&nbsp;</>
                ) : (
                  externalName ?? 'name not set'
                )}
              </Typography>
              <Typography variant="number1">
                {externalNumberIsLoading ? (
                  <>&nbsp;</>
                ) : externalNumber ? (
                  // @ts-ignore
                  <PhoneNumberDisplay ptn={externalNumber} />
                ) : (
                  <Typography
                    variant="number1"
                    sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                  >
                    number not set
                  </Typography>
                )}
              </Typography>
            </Grid>
            {/* @ts-ignore */}
            <Grid item xs={6} sx={{ position: 'relative' }}>
              {/* <HighlightPulseE911 {...highlightPulsePropsE911} /> */}
              <Typography variant="h3">
                Emergency (E911)
                <InfoTooltip
                  title={t('caller_id.emergency.tooltip')}
                  size={'small'}
                />
              </Typography>

              <Typography
                variant="number1"
                sx={
                  emergencyName
                    ? {}
                    : { color: 'text.secondary', fontStyle: 'italic' }
                }
              >
                {emergencyNumberIsLoading ? (
                  <>&nbsp;</>
                ) : (
                  emergencyName ?? 'name not set'
                )}
              </Typography>
              <Typography
                variant="number1"
                sx={
                  emergencyAddress
                    ? {}
                    : { color: 'text.secondary', fontStyle: 'italic' }
                }
              >
                {emergencyNumberIsLoading ? (
                  <>&nbsp;</>
                ) : (
                  emergencyAddress ?? 'address not set'
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AccountCallerIdCard;

import { Color, Size, Variant } from 'app/design-lib/types';
import React, { ReactElement, ReactNode } from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useMediaQuery } from '@mui/material';
export interface DialogProps {
  title?: ReactNode;
  children: ReactNode;
  open: boolean;
  className?: string;
  onClose: () => void;
  size?:
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl';
}

const Dialog = ({
  title,
  className = '',
  children,
  onClose,
  open,
  size = 'md',
}: DialogProps) => {
  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  // console.log('size', size);
  return (
    <Transition appear show={open} as={Fragment}>
      <HeadlessDialog as="div" className="relative z-auto" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-[6] backdrop-blur-sm bg-black/25" />
        </Transition.Child>
        <div className="fixed z-[6] inset-0 overflow-y-auto">
          <div
            className="flex w-screen overflow-hidden p-4 text-center md:pb-4
                      h-screen supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh]"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={`opacity-0 scale-95 ${
                isMobile ? 'translate-y-40' : 'translate-x-40'
              }`}
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo={`opacity-0 scale-95 ${
                isMobile ? 'translate-y-40' : 'translate-x-40'
              }`}
            >
              <HeadlessDialog.Panel
                className={`w-full max-w-${size} max-h-full mt-auto flex flex-col m-auto transform rounded-lg bg-white text-left  shadow-xl transition-all ${className}`}
              >
                {children}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
};

export const DialogTitle = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <HeadlessDialog.Title
    className={`border-b border-border-neutral font-medium text-lg pb-3 pt-3 px-3 ${className}`}
  >
    {children}
  </HeadlessDialog.Title>
);

export default Dialog;

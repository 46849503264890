import {
  get,
  set,
  isObject,
  isNumber,
  isString,
  isNaN,
  isUndefined,
  chain,
  sample,
  random,
  toNumber,
} from 'lodash';

import { ELEMENT_TEMPORAL_RULE } from 'app/components/SchedulePlateEditor/components/TemporalRuleElement';

import isIp from 'is-ip';

export const sleep = timeout => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
};

export const validIpAddress = val => {
  return isIp(val) ? true : false;
};

export const validateFormRequired = (value: string) => {
  return !value.trim();
};

export const randomString = (length = 10, pPreset?) => {
  if (!isNumber(length)) {
    throw new TypeError('"length" is not a string');
  }
  if (isNaN(length)) {
    throw new TypeError('"length" is NaN');
  }
  if (!isUndefined(pPreset) && !isString(pPreset)) {
    throw new TypeError('"preset" is not a string');
  }
  if (!isUndefined(pPreset) && pPreset.length === 0) {
    throw new TypeError('"preset" is an empty string');
  }
  var input = isUndefined(pPreset) ? 'safe' : pPreset;
  var presets = {
    alpha: '1234567890abcdefghijklmnopqrstuvwxyz',
    hex: '1234567890abcdef',
    letters: 'abcdefghijklmnopqrstuvwxyz',
    numerals: '1234567890',
    safe: '23456789abcdefghjkmnpqrstuxyz',
  };
  // @ts-ignore
  var preset = chain(presets).get(input, input).shuffle().value();
  var upper = preset.length - 1;
  var getRandomItem = function () {
    var isUpper = sample([true, false]);
    var item = preset[random(upper)];
    // @ts-ignore
    return _[isUpper ? 'toUpper' : 'toLower'](item);
  };
  return length === 0
    ? ''
    : chain(0).range(length).map(getRandomItem).join('').value();
};

// NOTE: NO DOTS "." in keys allowed when using getAtPath/setAtPath!!
export const getAtPath = (o, p, d?: any) => {
  let path = p;
  if (path?.length && path[0] === '.') {
    // remove leading dot (.)
    path = path.slice(1);
  }
  if (path?.includes('.')) {
    return get(o, pathWithoutBrackets(path), d);
  }
  if (!path || !path.length) {
    // root
    return o;
  }
  return get(o, pathWithoutBrackets(path), d);
};

// this gets "getAtPath" companitible with "setAtPath" for "{}" brackets
// - we use "{}" to denote object vs. array paths
const pathWithoutBrackets = path => {
  return path
    .split('.')
    .map(key => {
      if (key.charAt(0) === '{' && key.charAt(key.length - 1) === '}') {
        key = key.slice(1, key.length - 1);
      }
      return key;
    })
    .join('.');
};

// @ts-ignore
export const setAtPath = (object, path, value, customizer?: any) => {
  if (path?.length && path[0] === '.') {
    // remove leading dot (.)
    path = path.slice(1);
  }
  path = path.split('.');

  var index = -1,
    length = path.length,
    lastIndex = length - 1,
    nested = object;

  while (nested != null && ++index < length) {
    var key = path[index];
    // remove surrounding brackets for object "{}" (works with the "isIndex" check below (on the parent) to see if we should be doing an Object or )
    if (key.charAt(0) === '{' && key.charAt(key.length - 1) === '}') {
      key = key.slice(1, key.length - 1);
    }
    if (isObject(nested)) {
      var newValue = value;
      if (index !== lastIndex) {
        var objValue = nested[key];
        newValue = customizer ? customizer(objValue, key, nested) : undefined;
        if (newValue === undefined) {
          // see if following value is an integer (index), or object key
          // -  "{6}" would be converted to be an object with key "6"
          // - ex: "6" would be for an array item, where indexes 0-5 would be created!
          // @ts-ignore
          newValue =
            // @ts-ignore
            objValue == null ? (isIndex(path[index + 1]) ? [] : {}) : objValue;
          // console.log('newValue:', newValue, key, path[index + 1]);
        }
      }
      assignValue(nested, key, newValue);
    }
    nested = nested[key];
  }

  return object;
};

/** Used to detect unsigned integer values. */
var reIsUint = /^(?:0|[1-9]\d*)$/;
var // INFINITY = 1 / 0,
  MAX_SAFE_INTEGER = 9007199254740991;
// MAX_INTEGER = 1.7976931348623157e308,
// NAN = 0 / 0;

/** Used for built-in method references. */
var arrayProto = Array.prototype,
  objectProto = Object.prototype;

// @ts-ignore
function isIndex(value, length) {
  value = typeof value == 'number' || reIsUint.test(value) ? +value : -1;
  length = length == null ? MAX_SAFE_INTEGER : length;
  return value > -1 && value % 1 === 0 && value < length;
}
function eq(value, other) {
  // eslint-disable-next-line no-self-compare
  return value === other || (value !== value && other !== other);
}
function assignValue(object, key, value) {
  var objValue = object[key];
  if (
    !eq(objValue, value) ||
    // @ts-ignore
    (eq(objValue, objectProto[key]) && !hasOwnProperty.call(object, key)) ||
    (value === undefined && !(key in object))
  ) {
    object[key] = value;
  }
}

export const directorySearchFieldsOptions = [
  { label: 'Last Name Only', value: 'last_name' },
  { label: 'First Name Only', value: 'first_name' },
  { label: 'First and Last Name', value: 'both' },
];
// export const setAtPath = (o, p, v) => {
//   // allow forcing of Objects

//   return setWith(o, p, v, (nsVal, key, nsObj) => {
//     // customize to use an Object if surrounded with quotes
//     console.log('setWith:', nsVal, key, nsObj);
//     return undefined;
//   });

//   // let path = p;
//   // if (path?.length && path[0] === '.') {
//   //   // remove leading dot (.)
//   //   path = path.slice(1);
//   // }
//   // if (path?.includes('.')) {
//   //   // return lodashWithSetAtPath.set(o, path, v);
//   //   return set(o, path, v);
//   // }
//   // // setting at root level (slightly special)
//   // if (!path && !path?.length) {
//   //   for (let k of Object.keys(o)) {
//   //     delete o[k];
//   //   }
//   //   for (let k of Object.keys(v)) {
//   //     o[k] = v[k];
//   //   }
//   //   // console.log('o:', o);
//   //   return o;
//   // }
//   // return set(o, path, v);
// };

export const textToSlateTemporalRows = (text, notEmpty = true) => {
  const val = text
    .split('\n')
    .filter(v => v?.trim().length)
    .map(row => ({
      type: ELEMENT_TEMPORAL_RULE,
      children: [{ text: row.trim() }],
    }));
  if (!val.length && notEmpty) {
    return [
      {
        type: ELEMENT_TEMPORAL_RULE,
        children: [{ text: '' }],
      },
    ];
  }
  return val;
};

// NOTE: do NOT simply add 1970 to a year to get this offset (breaks leap years!)
export const epochOffset = 62167219200;

export const preEndpointsToGroupFormat = endpoints => {
  // group format is an object
  // - no duplicate ids allowed!!!
  // - guessing to use "weight" as "order" (TODO: verify this)
  let obj = {};
  for (let idx in endpoints) {
    const endpoint = endpoints[idx];
    if (endpoint.endpoint_type === 'tier') {
      for (let idx2 in endpoint.tiers) {
        const tierEndpoint = endpoint.tiers[idx2];
        obj[tierEndpoint.id] = {
          delay: endpoint.delay,
          timeout: endpoint.timeout,
          weight: toNumber(idx2) + 1000, // bad if more than 1000 phones? just chose a random number here
        };
      }
    } else {
      obj[endpoint.id] = {
        delay: endpoint.delay,
        timeout: endpoint.timeout,
        weight: toNumber(idx) + 1,
      };
    }
  }
  return obj;
};

export const preEndpointsToCallflowModuleFormat = endpoints => {
  // uses normal array of items (i guess?)
  let arr: any[] = [];
  for (let idx in endpoints) {
    const endpoint = endpoints[idx];
    if (endpoint.endpoint_type === 'tier') {
      for (let idx2 in endpoint.tiers) {
        const tierEndpoint = endpoint.tiers[idx2];
        arr.push({
          ...tierEndpoint,
          delay: endpoint.delay,
          timeout: endpoint.timeout,
          weight: toNumber(idx2) + 1000,
        });
      }
    } else {
      arr.push({ ...endpoint, weight: toNumber(idx) + 1 });
    }
  }
  return arr;
};

export const parseAndSetKazooMutationErrors = ({
  response,
  setError,
  prefix: passedPrefix = '',
}) => {
  let prefix = passedPrefix;
  // append trailing period if missing
  if (passedPrefix && !passedPrefix.endsWith('.')) {
    prefix = `${passedPrefix}.`;
  }

  const errors: any = [];
  for (let field of Object.keys(response?.data?.data ?? {})) {
    for (let type of Object.keys(response?.data?.data[field] ?? {})) {
      errors.push({
        field,
        type, // 'unique', etc
        message: response?.data?.data[field][type].message,
      });
    }
  }

  for (let error of errors) {
    // console.log('setError,', `${prefix}${error.field}`);
    setError(`${prefix}${error.field}`, {
      type: 'manual',
      message: error.message,
    });
  }
  return errors;
};

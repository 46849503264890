import { Chip, Grid, InfoTooltip } from 'app/design';
import { OwnerTableCell } from 'app/components/OwnerTableCell';
import { useMarkdownTranslate } from 'app/utilities/translate';
import React from 'react';
import { User } from 'types/user';
import { Vmbox } from 'types/vmbox';

interface CodecsCellProps {
  vmbox: Vmbox;
  owner: User;
}

const VmboxOwnerCell = ({ vmbox, owner }: CodecsCellProps) => {
  const { t } = useMarkdownTranslate();
  // vmbox has existing owner?
  if (owner) {
    return <OwnerTableCell owner={owner} type={'user'} />;
  }

  return (
    <Grid container columnSpacing={1}>
      {vmbox.doc.owner_id ? (
        // if orphaned (owner ID set, user does not exists)
        <>
          <Grid item>
            <Chip
              color={'error'}
              variant={'contained'}
              size={'small'}
              label={'Orphaned'}
            />
          </Grid>
          <Grid item>
            <InfoTooltip title={t('vmbox.orphan.tooltip')} />
          </Grid>
        </>
      ) : (
        // shared vmbox
        <>
          <Grid item>
            <Chip
              color={'primary'}
              variant={'contained'}
              size={'small'}
              label={'Shared'}
            />
          </Grid>
          <Grid item>
            <InfoTooltip title={t('vmbox.shared.tooltip')} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default VmboxOwnerCell;

const directoryQueryKeys = {
  all: ['directory'] as const,
  list: (
    orderBy: any,
    filters: any,
    queryPageSize: number,
    queryPageIndex: number,
  ) =>
    [
      ...directoryQueryKeys.all,
      'list',
      orderBy,
      filters,
      queryPageSize,
      queryPageIndex,
    ] as const,
  byId: (id: string | undefined) => [...directoryQueryKeys.all, id] as const,
};

export default directoryQueryKeys;

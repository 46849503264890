import constants from 'app/constants';
import { useAuthSelector } from 'app/data/auth';
import {
  Button,
  Dialog,
  DialogAlert,
  DialogLoading,
  DialogProps,
} from 'app/design-lib';
import { DialogActions } from 'app/design-lib/components/DialogActions';
import { DialogContent } from 'app/design-lib/components/DialogContent';
import { DialogHeader } from 'app/design-lib/components/DialogHeader';
import { useAccountStripeCustomerQuery } from 'app/hooks/queries/account';
import { gql, request } from 'graphql-request';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface UndoCancelSubDialogProps extends Omit<DialogProps, 'children'> {}

export const UndoCancelSubDialog = (props: UndoCancelSubDialogProps) => {
  return (
    <Dialog {...props}>
      {' '}
      <UndoCancelSubDialogContent {...props} />
    </Dialog>
  );
};

const UndoCancelSubDialogContent = (props: UndoCancelSubDialogProps) => {
  const { data } = useAccountStripeCustomerQuery();

  const sub = data?.data?.subscriptions?.data?.[0];
  const { status, current_period_end, trial_end } = sub ?? {};

  const { auth_token } = useAuthSelector();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const router = useHistory();
  // though this is undoubtedly not a success that we are losing a customer,
  // the authors of this code agreed that for the sake consistency
  // across the app, the terminology should remain the same.
  const [isSuccess, setIsSuccess] = useState(false);
  const cancelSub = async () => {
    setIsSubmitting(true);
    try {
      const cancelResp = await request(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
        gql`
          mutation mutateAccount($data: JSON) {
            mutateAccount(data: $data) {
              success
              message
              data
            }
          }
        `,
        {
          data: {
            action: 'undoDeleteAccount',
            data: {},
          },
        },
        // @ts-ignore
        { authorization: auth_token },
      );
      console.log('cancel response', cancelResp);
      setIsSuccess(true);

      // setTimeout(() => {
      //   router.push(`/logout`);
      // }, 5000);
    } catch (err) {
      console.error(err);
      setErrorMessage('Error in canceling subscription');
    }
    setIsSubmitting(false);
  };

  const cancelDate = new Date(current_period_end * 1000);
  const isTrial = status === 'trialing';
  return (
    <>
      <DialogHeader
        title={
          isSuccess
            ? 'Account not scheduled for deletion'
            : isSubmitting
            ? 'Undo account deletion'
            : `Undo delete account`
        }
        onClose={props.onClose}
        subTitle={isSubmitting ? 'Please wait...' : ''}
      />
      {!isSubmitting && errorMessage ? (
        <DialogAlert
          onClose={() => setErrorMessage('')}
          errorMessage={errorMessage}
        />
      ) : null}
      <DialogContent>
        {isSuccess ? (
          <div className={'w-full text-md text-center'}>
            <p>You're ready to go!</p>
          </div>
        ) : isSubmitting ? (
          <DialogLoading
            label={'Undoing deletion.'}
            secondaryText={'This may take a moment.'}
          />
        ) : (
          <div className={'text-center '}>
            <p className={'text-md'}>
              Click the button below to undo the scheduled deletion of your
              account.
            </p>
          </div>
        )}
      </DialogContent>
      {isSubmitting ? null : (
        <DialogActions>
          {isSuccess ? (
            <Button
              variant={'fill'}
              color={'accent'}
              size={'md'}
              onClick={props.onClose}
            >
              Dismiss
            </Button>
          ) : (
            <Button
              variant={'fill'}
              color={'negative'}
              size={'md'}
              onClick={cancelSub}
            >
              Undo Account Delete
            </Button>
          )}
        </DialogActions>
      )}
    </>
  );
};

export default UndoCancelSubDialog;

import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import groupQueryKeys from 'app/hooks/queries/group/groupQueryKeys';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteGroup = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, string, unknown>(
    (groupId: string) =>
      sdk.group.mutate.delete(groupId, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(groupQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

// Default PUT-building function
import buildResourceUrl from './buildResourceUrl';
import { put } from './methods';

// Default PUT-building function
// - uses buildResourceUrl and passes thru options from opts.buildUrlOpts
export const buildPutDefault =
  (resourceApiPathname, opts) => async (doc, auth_token) => {
    // uses a PUT request to create user/device/etc.

    // TODO: any checks on creation?

    return putResourceDefault({
      url: buildResourceUrl(
        resourceApiPathname,
        doc.id,
        auth_token,
        opts?.buildUrlOpts,
      ),
      doc,
      auth_token,
      config: opts?.config,
    });
  };

export const putResourceDefault = async ({ url, doc, auth_token, config }) => {
  const resp = await put(url, doc, { ...config, auth_token });
  return resp.data;
};

import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Sentry from '@sentry/react';
import { UserAvatar } from 'app/components/Sidebar2/Sidebar2';
import React, { useState, useEffect, useRef } from 'react';

import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { getAtPath } from 'app/utilities';
import { startCase } from 'lodash';

import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import {
  useAccountQuery,
  useAccountStripeCustomerQuery,
} from 'app/hooks/queries/account';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Setting,
  TextField,
} from 'app/design-lib';
import PhoneInput from 'react-phone-number-input';
import PhoneInputInput from 'react-phone-number-input/input';
import { InputWrapper } from 'app/design-lib/components/InputWrapper';
import { Autocomplete, TextField as TextFieldOld } from 'app/design';
import { useQueryClient, useMutation } from 'react-query';
import { userQueryKeys } from 'app/hooks/queries/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useUserQuery } from 'app/hooks/queries/user';
import PersonIcon from '@mui/icons-material/Person';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { UserPrivLevel } from 'types/user';
import { MfaDialog } from 'app/components/MfaDialog';
import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useHistory,
} from 'react-router-dom';
import {
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
} from 'app/design';
import {
  AddUser,
  ArrowLeft,
  EditPencil,
  Plus as PlusIcon,
} from 'iconoir-react';
import { toast } from 'react-toastify';
import {
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
  LocalPolice as LocalPoliceIcon,
  Build as BuildIcon,
} from 'app/design/icons-material';

import { store } from 'store';

import SimpleBar from 'simplebar-react';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';

import { useAuthSelector } from 'app/data/auth';
import ImgReducer from 'image-blob-reduce';
import constants from 'app/constants';

import {
  useBrandCreate,
  useTioMutation,
  useTioQuery,
} from 'app/pages/v2/common/sdk';

import COUNTRIES_OBJ from './countries.json';
import STATES_OBJ from './states.json';
import VERTICALS_OBJ from './verticals.json';
import STOCK_EXCHANGES_ARR from './stock_exchanges.json';

let COUNTRIES: any[] = [];
let US_STATES: any[] = [];
let VERTICALS: any[] = [];
const STOCK_EXCHANGES = STOCK_EXCHANGES_ARR.map(v => ({ label: v, value: v }));
Object.keys(COUNTRIES_OBJ).map(code =>
  COUNTRIES.push({ label: COUNTRIES_OBJ[code], value: code }),
);
COUNTRIES = COUNTRIES.sort((a, b) => a.label.localeCompare(b.label));
Object.keys(STATES_OBJ).map(code =>
  US_STATES.push({ label: STATES_OBJ[code], value: code }),
);
US_STATES = US_STATES.sort((a, b) => a.label.localeCompare(b.label));
Object.keys(VERTICALS_OBJ).map(code =>
  VERTICALS.push({
    label: VERTICALS_OBJ[code].displayName,
    description: VERTICALS_OBJ[code].description,
    value: code, //VERTICALS_OBJ[code].industryId,
  }),
);
VERTICALS = VERTICALS.sort((a, b) => a.label.localeCompare(b.label));

const schema = Joi.object({
  stockSymbol: Joi.alternatives().conditional('entityType', {
    is: 'PUBLIC_PROFIT',
    then: Joi.number().required(),
  }),
});

const BrandCreate = ({}) => {
  const { account_id, owner_id, auth_token } = useAuthSelector();
  // const createBrand = useBrandCreate();
  const [showMfa, showMfaSet] = React.useState(false);
  const createBrand = useTioMutation();
  const queryClient = useQueryClient();
  const two_factor_numbers = useTioQuery(`/mfa/phone_numbers`);
  const { data: account, refetch: refetchAccount } = useAccountQuery();
  // console.log('messaging_setup_ledger:', messaging_setup_ledger?.data);

  const methods = useForm({
    defaultValues: {
      entityType: '',
    },
    // resolver: joiResolver(schema),
  });
  const {
    watch,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  return (
    <>
      <SettingsPageWrapper
        // isLoading={pipesQuery.isLoading}
        // loadingLabel={'Loading line...'}
        label={
          <div className={'flex items-center space-x-2'}>
            <IconButton
              component={Link}
              variant={'ghost'}
              size={'sm'}
              color={'neutral'}
              className={'no-underline text-content-accent'}
              to="/v2/settings/campaigns"
            >
              <ArrowLeft fr={undefined} />
            </IconButton>
            <span>10DLC Brand Registration</span>
            {/* <span className={'text-neutral-60 text-sm'}>
              <PhoneNumberDisplay ptn={pipe.key} hideFlag />
            </span> */}
          </div>
        }
      >
        <div>
          {watch('entityType') === '' ? (
            <div className="mt-12">
              <div className={``}>
                <div className={`mb-12`}>
                  Are you registering a Private Company (have an EIN), or as a
                  Sole Proprietor (no EIN)?
                </div>

                <div className={`space-y-4`}>
                  <Button
                    color={'accent'}
                    // variant={'outline'}
                    size="lg"
                    onClick={() => setValue('entityType', 'PRIVATE_PROFIT')}
                    className="mb-2"
                  >
                    I have a private company that is registered (I have an EIN)
                  </Button>
                  <div>or:</div>
                  <Button
                    color={'accent'}
                    // variant={'outline'}
                    size="lg"
                    onClick={() => setValue('entityType', 'SOLE_PROPRIETOR')}
                    className="mb-2"
                  >
                    I am a sole proprietor (without an EIN)
                  </Button>
                </div>
              </div>
              <FormProvider {...methods}>
                {/* @ts-ignore */}
                {/* <Select
                  name="entityType"
                  label="Private Company with an EIN, or Sole Proprietor?"
                  options={[
                    { value: 'PRIVATE_PROFIT', label: 'Private Company' },
                    {
                      value: 'SOLE_PROPRIETOR',
                      label: 'Sole Proprietor',
                      // disabled: true,
                    },
                    // {
                    //   value: 'NON_PROFIT',
                    //   label: 'Non-Profit Organization',
                    // },
                    // {
                    //   value: 'PUBLIC_PROFIT',
                    //   label: 'Publicly Traded Company',
                    // },
                    // { value: 'GOVERNMENT', label: 'Government' },
                  ]}
                /> */}
              </FormProvider>
              <br />
              <br />
              <br />
              <Alert
                label={``}
                reverse
                color={'attention'}
                className="mb-4"
                body={
                  <>
                    Note: Public Companies, Non-Profits, and Governments, please
                    contact us.
                  </>
                }
              />
            </div>
          ) : null}
          {watch('entityType') === 'PRIVATE_PROFIT' ? (
            <EntityPrivateProfit
              changeType={() => setValue('entityType', '')}
            />
          ) : null}
          {watch('entityType') === 'SOLE_PROPRIETOR' ? (
            <EntitySoleProprietor
              changeType={() => setValue('entityType', '')}
            />
          ) : null}
          {/* {watch('entityType') === 'NON_PROFIT' ? (
            <Entity...
              changeType={() => setValue('entityType', '')}
            />
          ) : null}
          {watch('entityType') === 'PUBLIC_PROFIT' ? (
            <Entity...
              changeType={() => setValue('entityType', '')}
            />
          ) : null}
          {watch('entityType') === 'GOVERNMENT' ? (
            <Entity...
              changeType={() => setValue('entityType', '')}
            />
          ) : null} */}
        </div>
      </SettingsPageWrapper>
    </>
  );
};

const EntityPrivateProfit = ({ changeType }) => {
  const { account_id, owner_id, auth_token } = useAuthSelector();
  // const createBrand = useBrandCreate();
  const [showMfa, showMfaSet] = React.useState(false);
  const createBrand = useTioMutation();
  const queryClient = useQueryClient();
  const two_factor_numbers = useTioQuery(`/mfa/phone_numbers`);
  // console.log('messaging_setup_ledger:', messaging_setup_ledger?.data);

  const {
    data: account,
    refetch: refetchAccount,
    isFetched: accountFetched,
  } = useAccountQuery();

  const methods = useForm({
    defaultValues: {
      success: false,

      // entityType: '',
      country: 'US',
      altBusinessIdType: 'NONE',
      // altBusinessId: '',
      einIssuingCountry: 'US',

      // TESTING
      displayName: '',
      entityType: 'PRIVATE_PROFIT',
      companyName: ``,
      ein: '',
      altBusinessId: '',
      // altBusinessIdType: '',
      street: '',
      city: '',
      state: '',
      postalCode: '',
      website: '',
      email: '',
      phone: '',
      vertical: '',
    },
    // resolver: joiResolver(schema),
  });
  const {
    watch,
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    // only runs when accountFetched is updated (once!)
    // - uses the value of "account" (but isnt dependent on it)
    if (accountFetched && account) {
      // @ts-ignore
      if (account.doc.address.street_address) {
        if (account.doc.address.extended_address) {
          setValue(
            'street',
            `${account.doc.address.street_address} ${account.doc.address.extended_address}`,
          );
        } else {
          setValue('street', account.doc.address.street_address);
        }
      }
      if (account.doc.email) {
        setValue('email', account.doc.email);
      }
      if (account.doc.address.locality) {
        setValue('city', account.doc.address.locality);
      }
      if (account.doc.address.region) {
        setValue('state', account.doc.address.region);
      }
      if (account.doc.address.postal_code) {
        setValue('postalCode', account.doc.address.postal_code);
      }
    }
  }, [accountFetched]);

  const onSubmit = async () => {
    console.log('submitting');
    const data = getValues();
    console.log('data:', data);

    console.log('Current data:', JSON.parse(JSON.stringify(data)));

    // // // clear values if not used
    // const nullFields = ['displayName'];
    // for (let field of nullFields) {
    //   if (!data[field]?.length) {
    //     delete data[field];
    //   }
    // }

    if (!data.displayName?.length) {
      data.displayName = data.companyName;
    }

    console.log('To-save data:', JSON.parse(JSON.stringify(data)));

    // @ts-ignore
    const resp = await createBrand.mutateAsync({
      method: 'PUT',
      url: `/messaging/brands`,
      data,
    });

    console.log('brand create resp:', resp);

    // TODO: handle "generic" error codes, such as 553 (unsupported email address)
    if (resp?.brandId) {
      // success!
      // alert('Succeeded creating Brand!');
      setValue('success', true);
    } else {
      // expecting an array of failed values
      console.error('Failed creating brand');
      try {
        let generalErrors: any[] = [];
        for (let item of resp) {
          switch (item.code) {
            case 501:
              for (let field of item.fields) {
                setError(field, {
                  type: 'custom',
                  message: item.description,
                });
              }
              break;

            default:
              console.error('Invalid item error code:', item);
              generalErrors.push({ description: item.description });
              break;
          }
        }
        if (generalErrors.length) {
          // @ts-ignore
          setError(`generalErrors`, { type: 'custom', arr: generalErrors });
        }
      } catch (err) {
        // unknown error
        console.error('Failed Brand Create validation:', err);
      }
    }
    // console.log('resp:', resp);
  };

  if (Object.keys(errors).length) {
    console.log('ERRORS:', errors);
  }
  // useEffect(() => {
  //   // clearErrors();
  // }, []);

  return (
    <>
      <div>
        <FormProvider {...methods}>
          <SuccessDialog />
          <div className="p-3 flex flex-col mt-4 h-full max-w-lg">
            {/* <Alert
              label={`Steps in 10DLC Registration`}
              reverse
              color={'attention'}
              className="mb-4"
              body={
                <div className={`text-md`}>
                  <ol>
                    <li>1. Register Brand</li>
                    <li>2. Test</li>
                  </ol>
                </div>
              }
            /> */}

            {/* <div className="mb-2 text-lg font-bold">Company Details</div> */}
            <div className={`mb-8 text-md`}>
              Please fill out all the below information. When complete, this
              will be submitted directly to The Campaign Registry for
              verification.
            </div>

            <div className="space-y-8 md:space-y-16 ">
              {/* {!isSoleProprietor ? (
                <TextField field="companyName" title="Legal Company Name" />
              ) : null} */}

              <Alert
                label={``}
                reverse
                color={'accent'}
                className="mb-4"
                body={
                  <div className={`text-md`}>
                    Note: only US-registered companies are supported.
                    International companies, please contact{' '}
                    <a
                      href="https://support.calling.io"
                      className="text-accent-50 hover:text-accent-70 underline hover:no-underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      support
                    </a>
                    .
                  </div>
                }
              />

              {/* @ts-ignore */}
              {errors?.generalErrors?.arr ? (
                <div className="p-4 mb-4 rounded bg-negative-10">
                  <div className="font-bold text-lg">Errors</div>
                  {/* @ts-ignore */}
                  {errors?.generalErrors?.arr?.map((err, i) => (
                    <div key={i} className="text-base">
                      {err.description}
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="space-y-4">
                <div className="md:flex space-x-2 space-y-4 md:space-y-0">
                  <div className="md:w-1/2">
                    <HookFormTextField
                      name={`companyName`}
                      label={'Company Legal Name'}
                    />
                  </div>
                  <div className="md:w-1/2">
                    <HookFormTextField
                      name={`displayName`}
                      label={`DBA (optional)`}
                      helperText="if different from Legal Name"
                    />
                  </div>
                </div>

                {/* <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2"> */}
                {/* </div>
                </div> */}
                <HookFormTextField
                  name={`website`}
                  label={`Website (optional)`}
                />
                {/* @ts-ignore */}
                <Select
                  name="vertical"
                  label="What industry are you in?"
                  options={VERTICALS}
                />
              </div>

              <div className="space-y-4">
                <HookFormTextField
                  name={`ein`}
                  label={`EIN`}
                  helperText="US companies only"
                />

                <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2">
                    {/* @ts-ignore */}
                    <Select
                      name="altBusinessIdType"
                      label="Additional Business ID Type"
                      options={[
                        {
                          label: '',
                          value: 'NONE',
                        },
                        {
                          label: 'DUNS',
                          value: 'DUNS',
                        },
                        {
                          label: 'GIIN',
                          value: 'GIIN',
                        },
                        ,
                        {
                          label: 'LEI',
                          value: 'LEI',
                        },
                      ]}
                    />
                  </div>
                  <div className="md:w-1/2">
                    <HookFormTextField
                      name={`altBusinessId`}
                      label={`${
                        watch('altBusinessIdType') !== 'NONE'
                          ? watch('altBusinessIdType')
                          : 'DUNS or GIIN or LEI'
                      } Number`}
                      disabled={!watch('altBusinessIdType')?.length}
                    />
                  </div>
                </div>
              </div>

              {/* <Alert
                label={``}
                reverse
                color={'accent'}
                className="mb-4"
                body={
                  <div className={`text-md`}>
                    When providing your company address (below), please use a
                    physical address (PO Boxes
                  </div>
                }
              /> */}

              <div className="space-y-4">
                <div>
                  <HookFormTextField
                    name={`street`}
                    label={`Company Street Address`}
                  />
                </div>

                <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2">
                    {/* @ts-ignore */}
                    <Select name="state" label="State" options={US_STATES} />
                  </div>

                  <div className="md:w-1/2">
                    <Autocomplete
                      options={COUNTRIES}
                      getOptionLabel={option => option.label}
                      {...register(`country`)}
                      // value={value}
                      // onChange={(event, value) => {
                      //   onChange(value);
                      // }}
                      disabled
                      renderInput={params => {
                        // @ts-ignore
                        params.InputLabelProps.shrink = true;
                        console.log('params:', params);
                        return (
                          <TextField
                            // {...params}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   className:
                            //     'focus:border-0 p-0 m-0 h-max max-h-[0.2rem] focus:ring-0 focus:outline-none',
                            // }}
                            label={'Country of Registration'}
                            placeholder={'select'}
                            disabled
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2">
                    <HookFormTextField name={`city`} label={`City`} />
                  </div>
                  <div className="md:w-1/2">
                    <HookFormTextField name={`postalCode`} label={`Zipcode`} />
                  </div>
                </div>
              </div>

              <div>
                <Alert
                  label={``}
                  reverse
                  color={'accent'}
                  className="mb-4"
                  body={
                    <div className={`text-md`}>
                      The following "Support" contact phone/email is only used
                      if there is an issue with your registration:
                    </div>
                  }
                />
                <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2">
                    {/* @ts-ignore */}
                    <PhoneNumberInput
                      name="phone"
                      label={`Support Phone Number`}
                    />
                  </div>
                  <div className="md:w-1/2">
                    <HookFormTextField
                      name={`email`}
                      label={`Support Email Address`}
                    />
                  </div>
                </div>
              </div>

              <SubmitArea onSubmit={onSubmit} />
              {/* <Select field="vertical" title="Vertical" options={VERTICALS} /> */}
            </div>
          </div>
        </FormProvider>
      </div>
    </>
  );
};

const EntitySoleProprietor = ({ changeType }) => {
  const { account_id, owner_id, auth_token } = useAuthSelector();
  // const createBrand = useBrandCreate();
  const [showMfa, showMfaSet] = React.useState(false);
  const createBrand = useTioMutation();
  const create_payment = useTioMutation();
  const queryClient = useQueryClient();
  const two_factor_numbers = useTioQuery(`/mfa/phone_numbers`);
  const {
    data: account,
    refetch: refetchAccount,
    isFetched: accountFetched,
  } = useAccountQuery();
  const messaging_setup_ledger = useTioQuery(
    `/messaging/payment_setup`,
    account?.internal?.integrations?.trunkingio?.account_id ? true : false,
  );
  // console.log('messaging_setup_ledger:', messaging_setup_ledger?.data);

  const methods = useForm({
    defaultValues: {
      success: false,

      // entityType: '',
      country: 'US',
      // altBusinessIdType: 'NONE',
      // altBusinessId: '',
      einIssuingCountry: 'US',

      // TESTING
      displayName: ``,
      entityType: 'SOLE_PROPRIETOR',
      // companyName: `Testing 2600hz ${new Date().toLocaleDateString('en-us', {
      //   // weekday: 'long',
      //   // year: 'numeric',
      //   month: 'short',
      //   day: 'numeric',
      //   hour: 'numeric',
      //   minute: 'numeric',
      // })}`,
      // ein: '123456789',
      // altBusinessId: '223334444',
      // altBusinessIdType: 'DUNS',
      street: '',
      city: '',
      state: '',
      postalCode: '',
      website: '',
      email: '',
      phone: '',
      vertical: '', // NOT REQUIRED (for SP)
      mobilePhone: '',
      firstName: '',
      lastName: '',
    },
    // resolver: joiResolver(schema),
  });
  const {
    watch,
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  useEffect(() => {
    // only runs when accountFetched is updated (once!)
    // - uses the value of "account" (but isnt dependent on it)
    if (accountFetched && account) {
      // @ts-ignore
      if (account.doc.address.street_address) {
        if (account.doc.address.extended_address) {
          setValue(
            'street',
            `${account.doc.address.street_address} ${account.doc.address.extended_address}`,
          );
        } else {
          setValue('street', account.doc.address.street_address);
        }
      }
      if (account.doc.email) {
        setValue('email', account.doc.email);
      }
      if (account.doc.address.locality) {
        setValue('city', account.doc.address.locality);
      }
      if (account.doc.address.region) {
        setValue('state', account.doc.address.region);
      }
      if (account.doc.address.postal_code) {
        setValue('postalCode', account.doc.address.postal_code);
      }
    }
  }, [accountFetched]);

  const onSubmit = async () => {
    console.log('submitting');
    const data = getValues();
    console.log('data:', data);

    console.log('Current data:', JSON.parse(JSON.stringify(data)));

    // // // clear values if not used
    // const nullFields = ['displayName'];
    // for (let field of nullFields) {
    //   if (!data[field]?.length) {
    //     delete data[field];
    //   }
    // }

    // if (!data.displayName?.length) {
    //   data.displayName = data.companyName;
    // }

    console.log('To-save data:', JSON.parse(JSON.stringify(data)));

    // @ts-ignore
    const resp = await createBrand.mutateAsync({
      method: 'PUT',
      url: `/messaging/brands`,
      data,
    });

    console.log('brand create resp:', resp);

    // TODO: handle "generic" error codes, such as 553 (unsupported email address)
    if (resp?.brandId) {
      // success!
      // alert('Succeeded creating Brand!');
      setValue('success', true);
    } else {
      // expecting an array of failed values
      console.error('Failed creating brand');
      try {
        let generalErrors: any[] = [];
        for (let item of resp) {
          switch (item.code) {
            case 501:
              for (let field of item.fields) {
                setError(field, {
                  type: 'custom',
                  message: item.description,
                });
              }
              break;

            default:
              console.error('Invalid item error code:', item);
              generalErrors.push({ description: item.description });
              break;
          }
        }
        if (generalErrors.length) {
          // @ts-ignore
          setError(`generalErrors`, { type: 'custom', arr: generalErrors });
        }
      } catch (err) {
        // unknown error
        console.error('Failed Brand Create validation:', err);
      }
    }
    // console.log('resp:', resp);
  };

  if (Object.keys(errors).length) {
    console.log('ERRORS:', errors);
  }
  // useEffect(() => {
  //   // clearErrors();
  // }, []);

  return (
    <>
      <div>
        <FormProvider {...methods}>
          <SuccessDialog />
          {/* <MfaDialog
            onComplete={() => {
              showMfaSet(false);
              two_factor_numbers.refetch();
            }}
            onCancel={() => showMfaSet(false)}
            // number={pipe.key}
            open={showMfa}
          />
          <Button
            color={'accent'}
            variant={'outline'}
            onClick={() => showMfaSet(true)}
            className="mb-2"
          >
            MFA Phone Numbers
          </Button> */}

          <div className="p-3 mt-4 max-w-lg">
            {/* <Alert
              label={`Sole Proprietor Details`}
              reverse
              color={'accent'}
              body={
                <div className={``}>
                  "10DLC" is a requirement for sending SMS/MMS messages to
                  customers. These are required by mobile carrier operators,
                  otherwise your messages will fail to be delivered.
                </div>
              }
            /> */}
            <div className={`mb-8 text-md`}>
              Please fill out all the below information. When complete, this
              will be submitted directly to The Campaign Registry for
              verification.
            </div>
            <div className="space-y-8 md:space-y-16">
              {/* {!isSoleProprietor ? (
                <TextField field="companyName" title="Legal Company Name" />
              ) : null} */}

              {/* @ts-ignore */}
              {errors?.generalErrors?.arr ? (
                <div className="p-4 mb-4 rounded bg-negative-10">
                  <div className="font-bold text-lg">Errors</div>
                  {/* @ts-ignore */}
                  {errors?.generalErrors?.arr?.map((err, i) => (
                    <div key={i} className="text-base">
                      {err.description}
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="md:flex space-x-2 space-y-4 md:space-y-0">
                <div className="md:w-1/2">
                  <HookFormTextField
                    name={`firstName`}
                    // name={`${modifyPath}callflow${moduleModifyPath}.data.menu_id`}
                    label={'First Name'}
                    // editLabel={mediaId ? 'Change Greeting' : 'Add Greeting'}
                    // onChange={handleChangeName}
                  />
                </div>
                <div className="md:w-1/2">
                  <HookFormTextField name={`lastName`} label={`Last Name`} />
                </div>
              </div>

              <div>
                {/* <HookFormTextField
                  name={`mobilePhone`}
                  label={`Mobile Phone Number`}
                  helperText="US-only! This number will received an SMS verification code"
                /> */}
                {/* <InputWrapper
                  helperText={helperText}
                  hasError={hasError}
                  infoText={infoText}
                  label={label}
                  name={name}
                  disabled={disabled}
                  startIcon={startIcon}
                ></InputWrapper> */}
                {/* @ts-ignore */}
                <PhoneNumberInput
                  name="mobilePhone"
                  label={`Mobile Phone Number`}
                  helperText="US-only! After registration, you will need to verify this phone number via an SMS code"
                />
              </div>

              <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                <div className="md:w-1/2">
                  <HookFormTextField
                    name={`displayName`}
                    label={`Company Name`}
                    helperText="What are you doing business as?"
                  />
                </div>
                <div className="md:w-1/2">
                  <HookFormTextField
                    name={`website`}
                    label={`Website (optional)`}
                  />
                </div>
              </div>

              <div className="space-y-4">
                <Alert
                  label={``}
                  reverse
                  color={'accent'}
                  className="mb-4"
                  body={
                    <div className={`text-md`}>
                      Note: You must have a physical US address! International
                      sole proprietors, please contact{' '}
                      <a
                        href="https://support.calling.io"
                        className="text-accent-50 hover:text-accent-70 underline hover:no-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support
                      </a>
                      .
                    </div>
                  }
                />

                <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2">
                    {/* @ts-ignore */}
                    <Select name="state" label="State" options={US_STATES} />
                  </div>

                  <div className="md:w-1/2">
                    <Autocomplete
                      options={COUNTRIES}
                      getOptionLabel={option => option.label}
                      {...register(`country`)}
                      // value={value}
                      // onChange={(event, value) => {
                      //   onChange(value);
                      // }}
                      disabled
                      renderInput={params => {
                        // @ts-ignore
                        params.InputLabelProps.shrink = true;
                        console.log('params:', params);
                        return (
                          <TextField
                            // {...params}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   className:
                            //     'focus:border-0 p-0 m-0 h-max max-h-[0.2rem] focus:ring-0 focus:outline-none',
                            // }}
                            label={'Country of Registration'}
                            placeholder={'select'}
                            disabled
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div>
                  <HookFormTextField name={`street`} label={`Street Address`} />
                </div>

                <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2">
                    <HookFormTextField name={`city`} label={`City`} />
                  </div>
                  <div className="md:w-1/2">
                    <HookFormTextField name={`postalCode`} label={`Zipcode`} />
                  </div>
                </div>
              </div>

              <div>
                <Alert
                  label={``}
                  reverse
                  color={'accent'}
                  className="mb-4"
                  body={
                    <div className={`text-md`}>
                      The following "Support" contact phone/email is only used
                      if there is an issue with your registration:
                    </div>
                  }
                />
                <div className="md:flex space-x-2 space-y-2 md:space-y-0">
                  <div className="md:w-1/2">
                    {/* @ts-ignore */}
                    <PhoneNumberInput
                      name="phone"
                      label={`Support Phone Number`}
                    />
                  </div>
                  <div className="md:w-1/2">
                    <HookFormTextField
                      name={`email`}
                      label={`Support Email Address`}
                    />
                  </div>
                </div>
              </div>

              {/*               
              <Select
                field="country"
                title="Country of Registration"
                options={COUNTRIES}
                disabled
              /> */}

              {/* <Select field="vertical" title="Vertical" options={VERTICALS} /> */}
            </div>

            <SubmitArea onSubmit={onSubmit} />
          </div>
        </FormProvider>
      </div>
    </>
  );
};

// // use the below for more Brand entityTypes (when we want to support Public Company, Government, etc)
// const EntityPublic = ({ changeType }) => {
//   const { account_id, owner_id, auth_token } = useAuthSelector();
//   // const createBrand = useBrandCreate();
//   const [showMfa, showMfaSet] = React.useState(false);
//   const createBrand = useTioMutation();
//   const create_payment = useTioMutation();
//   const queryClient = useQueryClient();
//   const two_factor_numbers = useTioQuery(`/mfa/phone_numbers`);
//   const { data: account, refetch: refetchAccount } = useAccountQuery();
//   const messaging_setup_ledger = useTioQuery(
//     `/messaging/payment_setup`,
//     account?.internal?.integrations?.trunkingio?.account_id ? true : false,
//   );
//   // console.log('messaging_setup_ledger:', messaging_setup_ledger?.data);

//   const handleCreateMessagingPayment = async () => {
//     // @ts-ignore
//     const result = await create_payment.mutateAsync({
//       method: 'POST',
//       url: '/messaging/payment_setup',
//       data: {},
//     });
//     console.log('payment result:', result);
//     messaging_setup_ledger.refetch();
//   };

//   const methods = useForm({
//     defaultValues: {
//       success: false,
//       // entityType: '',
//       country: 'US',
//       // altBusinessIdType: 'NONE',
//       // altBusinessId: '',
//       einIssuingCountry: 'US',

//       // TESTING
//       displayName: '',
//       entityType: 'PRIVATE_PROFIT',
//       companyName: `Testing 2600hz ${new Date().toLocaleDateString('en-us', {
//         // weekday: 'long',
//         // year: 'numeric',
//         month: 'short',
//         day: 'numeric',
//         hour: 'numeric',
//         minute: 'numeric',
//       })}`,
//       ein: '123456789',
//       altBusinessId: '223334444',
//       altBusinessIdType: 'DUNS',
//       street: '2831 St. Rose Parkway',
//       city: 'Henderson',
//       state: 'NV',
//       postalCode: '89052',
//       website: 'https://www.2600hz.com',
//       email: 'nick@2600hz.com',
//       phone: '+16027059885',
//       vertical: 'TECHNOLOGY',
//     },
//     // resolver: joiResolver(schema),
//   });
//   const {
//     watch,
//     register,
//     getValues,
//     setError,
//     clearErrors,
//     formState: { errors },
//   } = methods;

//   const onSubmit = async () => {
//     console.log('submitting');
//     const data = getValues();
//     console.log('data:', data);

//     console.log('Current data:', JSON.parse(JSON.stringify(data)));

//     // // // clear values if not used
//     // const nullFields = ['displayName'];
//     // for (let field of nullFields) {
//     //   if (!data[field]?.length) {
//     //     delete data[field];
//     //   }
//     // }

//     if (!data.displayName?.length) {
//       data.displayName = data.companyName;
//     }

//     console.log('To-save data:', JSON.parse(JSON.stringify(data)));

//     // @ts-ignore
//     const resp = await createBrand.mutateAsync({
//       method: 'PUT',
//       url: `/messaging/brands`,
//       data,
//     });

//     console.log('brand create resp:', resp);

//     // TODO: handle "generic" error codes, such as 553 (unsupported email address)
//     if (resp?.brandId) {
//       // success!
//       alert('Succeeded creating Brand!');
//     } else {
//       // expecting an array of failed values
//       console.error('Failed creating brand');
//       try {
//         let generalErrors: any[] = [];
//         for (let item of resp) {
//           switch (item.code) {
//             case 501:
//               for (let field of item.fields) {
//                 setError(field, {
//                   type: 'custom',
//                   message: item.description,
//                 });
//               }
//               break;

//             default:
//               console.error('Invalid item error code:', item);
//               generalErrors.push({ description: item.description });
//               break;
//           }
//         }
//         if (generalErrors.length) {
//           // @ts-ignore
//           setError(`generalErrors`, { type: 'custom', arr: generalErrors });
//         }
//       } catch (err) {
//         // unknown error
//         console.error('Failed Brand Create validation:', err);
//       }
//     }
//     // console.log('resp:', resp);
//   };

//   const isSoleProprietor =
//     watch('entityType') === 'SOLE_PROPRIETOR' ? true : false;
//   const isPublicCompany =
//     watch('entityType') === 'PUBLIC_PROFIT' ? true : false;

//   if (Object.keys(errors).length) {
//     console.log('ERRORS:', errors);
//   }
//   // useEffect(() => {
//   //   // clearErrors();
//   // }, []);

//   return (
//     <>
//       <div>
//         <FormProvider {...methods}>
//           <MfaDialog
//             onComplete={() => {
//               showMfaSet(false);
//               two_factor_numbers.refetch();
//             }}
//             onCancel={() => {
//               showMfaSet(false);
//             }}
//             // number={pipe.key}
//             open={showMfa}
//           />
//           <Button
//             color={'accent'}
//             variant={'outline'}
//             onClick={() => showMfaSet(true)}
//             className="mb-2"
//           >
//             MFA Phone Numbers
//           </Button>

//           <div className="p-3 flex flex-col mt-4 h-full">
//             <div className="mb-6 text-lg font-bold">Brand Details</div>
//             {/* @ts-ignore */}
//             {errors?.generalErrors?.arr ? (
//               <div className="p-4 mb-4 rounded bg-red-100/20">
//                 <div className="font-bold text-lg">Errors</div>
//                 {/* @ts-ignore */}
//                 {errors?.generalErrors?.arr?.map((err, i) => (
//                   <div key={i} className="text-base">
//                     {err.description}
//                   </div>
//                 ))}
//               </div>
//             ) : null}
//             <div className="space-y-6 ">
//               {!isSoleProprietor ? (
//                 <TextField field="companyName" title="Legal Company Name" />
//               ) : null}
//               <TextField
//                 field="displayName"
//                 title="DBA or Brand Name, if different from Legal Name"
//                 // disabled={isSoleProprietor}
//               />

//               <Select
//                 field="country"
//                 title="Country of Registration"
//                 options={COUNTRIES}
//                 disabled
//               />

//               <TextField
//                 field="ein"
//                 title="Tax Number/ID/EIN (depends on Country)"
//                 disabled={isSoleProprietor}
//               />
//               <Select
//                 field="einIssuingCountry"
//                 title="EIN Issuing Country"
//                 options={COUNTRIES}
//                 disabled={isSoleProprietor}
//               />
//               <Select
//                 field="altBusinessIdType"
//                 title="Additional Businesss ID Type"
//                 options={[
//                   {
//                     label: '',
//                     value: 'NONE',
//                   },
//                   {
//                     label: 'DUNS',
//                     value: 'DUNS',
//                   },
//                   {
//                     label: 'GIIN',
//                     value: 'GIIN',
//                   },
//                   ,
//                   {
//                     label: 'LEI',
//                     value: 'LEI',
//                   },
//                 ]}
//                 disabled={isSoleProprietor}
//               />
//               <TextField
//                 field="altBusinessId"
//                 title={`${
//                   watch('altBusinessIdType') !== 'NONE'
//                     ? watch('altBusinessIdType')
//                     : 'DUNS or GIIN or LEI'
//                 } Number`}
//                 disabled={
//                   !watch('altBusinessIdType')?.length || isSoleProprietor
//                 }
//               />
//               <TextField field="street" title={`Address/Street`} />
//               <TextField field="city" title={`City`} />
//               <Select field="state" title="State" options={US_STATES} />
//               <TextField field="postalCode" title={`Zipcode`} />
//               <TextField field="website" title={`Website`} />
//               <Select field="vertical" title="Vertical" options={VERTICALS} />

//               {isPublicCompany ? (
//                 <>
//                   <Select
//                     field="stockExchange"
//                     title="Stock Exchange"
//                     options={STOCK_EXCHANGES}
//                     disabled={!isPublicCompany}
//                   />
//                   <TextField
//                     field="stockSymbol"
//                     title={`Stock Symbol`}
//                     disabled={!isPublicCompany}
//                   />
//                 </>
//               ) : null}

//             </div>

//             <div className="mt-8 mb-6 text-lg font-bold">Contact Details</div>

//             <div className="space-y-6">
//               <TextField field="phone" title={`Support Phone Number`} />
//               <TextField field="email" title={`Support Email Address`} />

//             </div>

//             <SubmitArea onSubmit={onSubmit}/>

//           </div>
//         </FormProvider>
//       </div>
//     </>
//   );
// };

const SubmitArea = ({ onSubmit }) => {
  const methods = useFormContext();
  const {
    watch,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors: formErrors, isSubmitting },
  } = methods;

  const createBrand = useTioMutation();
  const create_payment = useTioMutation();
  const { data: account, refetch: refetchAccount } = useAccountQuery();
  const messaging_setup_ledger = useTioQuery(
    `/messaging/payment_setup`,
    account?.internal?.integrations?.trunkingio?.account_id ? true : false,
  );
  // console.log('messaging_setup_ledger:', messaging_setup_ledger?.data);

  const handleCreateMessagingPayment = async () => {
    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/messaging/payment_setup',
      data: {},
    });
    console.log('payment result:', result);
    messaging_setup_ledger.refetch();
  };

  if (Object.keys(formErrors).length) {
    console.log('formErrors:', formErrors);
  }

  return (
    <div className="mt-8 space-y-6 mt-12">
      <hr />
      <div>
        {/* Payment required? */}
        {messaging_setup_ledger.isLoading ? (
          <div className="my-4">Checking for 10DLC payment...</div>
        ) : messaging_setup_ledger.data?.id ? null : (
          <>
            <Alert
              label={`10DLC Payment Required`}
              reverse
              color={'attention'}
              className="mb-4"
              body={
                <>
                  The Campaign Registry charges $4 to register a
                  company/individual, and $15 to register each Campaign. We are
                  required to collect these fees before submission.
                  <br />
                  This will add $19 to your TrunkingIO balance, which is
                  deducted when your Brand and Campaign are submitted.
                </>
              }
            />
            <Button
              color={'accent'}
              // variant={'outline'}
              size="md"
              onClick={handleCreateMessagingPayment}
              // className="inline-flex"
              className="mb-6"
              disabled={create_payment.isLoading}
            >
              Make 10DLC Payment
            </Button>
          </>
        )}

        <Alert
          label={``}
          reverse
          color={'attention'}
          className="mb-4"
          body={
            <>
              Please double-check the above information to ensure it is correct!
              Invalid information can lead to your registration being rejected,
              and cost $4 to re-apply.
            </>
          }
        />

        {Object.keys(formErrors).length ? (
          <Alert
            label={`Errors encountered before submission`}
            reverse
            color={'negative'}
            className="mb-4"
            body={
              <>
                Check text fields above for error messages!
                <br />
                <br />
                Note: this did NOT charge you anything. Only a successful
                submission will result in a charge.
              </>
            }
          />
        ) : null}

        <Button
          // variant={'outline'}
          color={'positive'}
          size="md"
          onClick={() => {
            clearErrors();
            methods.handleSubmit(onSubmit)();
          }}
          disabled={isSubmitting || !messaging_setup_ledger.data?.id}
        >
          {isSubmitting ? 'please wait...' : 'Submit Registration'}
        </Button>
      </div>
    </div>
  );
};

// interface SelectProps {
//   field: string;
//   title: any;
//   // hint: any;
//   options?: any;
//   placeholder?: any;
//   disabled?: boolean;
// }
const PhoneNumberInput = ({
  helperText,
  infoText,
  label,
  name,
  disabled,
  startIcon,
  errorMessageParser,
}) =>
  // : SelectProps
  {
    const {
      register,
      watch,
      setValue,
      formState: { errors: formErrors },
    } = useFormContext();

    const error = getAtPath(formErrors, name);

    const errorMessage = error
      ? errorMessageParser
        ? errorMessageParser(error)
        : error.message
      : undefined;

    return (
      <InputWrapper
        hasError={!!errorMessage}
        helperText={
          errorMessage?.replace(`"${name}"`, startCase(name)) ?? helperText
        }
        infoText={infoText}
        label={label}
        name={name}
        disabled={disabled}
        startIcon={startIcon}
      >
        <PhoneInputInput
          country="US"
          inputComponent={TextField}
          value={watch(name)}
          onChange={v => setValue(name, v)}
        />
        {/* <select
          disabled={disabled}
          
          className="rounded-lg border border-border-neutral"
        >
          {options.map(opt => (
            <option key={opt.value} value={opt.value} disabled={opt.disabled}>
              {opt.label}
            </option>
          ))}
        </select> */}
      </InputWrapper>
      // <div className="">
      //   <div className={'text-sm font-bold'}>{title}</div>

      //   {errors[field] && (
      //     <p className="text-red-500">{errors[field]?.message}</p>
      //   )}
      // </div>
    );
  };

const SuccessDialog = () => {
  const { watch } = useFormContext();

  const router = useHistory();

  const handleGoToCampaigns = () => {
    router.push('/v2/settings/campaigns');
  };

  return (
    <Dialog size={'md'} onClose={() => {}} open={watch('success')}>
      <DialogContent>
        <div className="space-y-4">
          <div className="text-xl">Registration submitted!</div>
          <div className="text-md">
            {watch('entityType') === 'SOLE_PROPRIETOR' ? (
              <>
                Within about 2 minutes, verification should be complete, and you
                can continue to register your Campaign.
              </>
            ) : (
              <>
                Within about 2 minutes, verification should be complete, and you
                can continue to register your Campaign.
              </>
            )}
          </div>
          <div className="">
            <Button
              component={Link}
              // variant={'ghost'}
              size={'md'}
              color={'accent'}
              // className={'no-underline text-content-accent'}
              to="/v2/settings/campaigns"
            >
              Go to 10DLC Home
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const Select = ({
  options,
  helperText,
  infoText,
  label,
  name,
  errorField,
  errorMessageParser,
  disabled,
  startIcon,
}) =>
  // : SelectProps
  {
    const {
      register,
      formState: { errors: formErrors },
    } = useFormContext();

    const error = getAtPath(formErrors, errorField || name);

    const errorMessage = error
      ? errorMessageParser
        ? errorMessageParser(error)
        : error.message
      : undefined;

    console.log('Select errorMessage', { name, error, errorMessage });

    return (
      <InputWrapper
        helperText={errorMessage?.length ? errorMessage : helperText}
        hasError={errorMessage?.length ? true : false}
        infoText={infoText}
        label={label}
        name={name}
        disabled={disabled}
        startIcon={startIcon}
      >
        <select
          disabled={disabled}
          {...register(name)}
          className="rounded-lg border border-border-neutral"
        >
          {options.map(opt => (
            <option key={opt.value} value={opt.value} disabled={opt.disabled}>
              {opt.label}
            </option>
          ))}
        </select>
      </InputWrapper>
      // <div className="">
      //   <div className={'text-sm font-bold'}>{title}</div>

      //   {errors[field] && (
      //     <p className="text-red-500">{errors[field]?.message}</p>
      //   )}
      // </div>
    );
  };

// interface TextFieldProps {
//   field: string;
//   title: any;
//   hint?: any;
//   placeholder?: any;
//   disabled?: boolean;
// }
// const TextField = ({
//   field,
//   title,
//   hint = null,
//   placeholder = '',
//   disabled = false,
// }: TextFieldProps) => {
//   const {
//     register,
//     formState: { errors },
//   } = useFormContext();

//   return (
//     <div className="">
//       <div className={'text-sm font-bold'}>{title}</div>
//       <input
//         className={
//           'rounded text-base border-solid border border-border-neutral py-1.5 px-3 font-sans'
//         }
//         type="text"
//         placeholder={placeholder}
//         disabled={disabled}
//         {...register(field)}
//       />
//       {hint !== null ? (
//         <p className="text-sm text-neutral-600">{hint}</p>
//       ) : null}
//       {errors[field] && (
//         <p className="text-red-500">{errors[field]?.message}</p>
//       )}
//     </div>
//   );
// };

export default BrandCreate;

import { MediaDialog } from 'app/components/MediaDialog';
import { Button } from 'app/design';
import * as React from 'react';
import { useReducer } from 'react';
import { MediaType } from 'types/media';

const CreateMediaButton = ({ onComplete }) => {
  const [showAddDialog, toggleAddDialog] = useReducer(show => !show, false);

  const handleOnComplete = mediaId => {
    onComplete();
    toggleAddDialog();
  };

  return (
    <>
      <MediaDialog
        open={showAddDialog}
        mediaNameToSaveAs={`Media ${new Date().toLocaleString()}`}
        onCancel={toggleAddDialog}
        onComplete={handleOnComplete}
        allowedTypes={[
          MediaType.Tts,
          MediaType.Upload,
          MediaType.Recording,
          MediaType.Custom,
        ]}
      />
      <Button variant={'contained'} onClick={toggleAddDialog}>
        Add Media
      </Button>
    </>
  );
};

export default CreateMediaButton;

import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import { buildPatchDefault } from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    account: (vars, fetchOptions) =>
      fetchFromGraphql('account', vars, fetchOptions),
    stripeCustomer: (vars, fetchOptions) =>
      fetchFromGraphql('stripeCustomer', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    updatePartial: buildPatchDefault('accounts', {
      buildUrlOpts: { account: true },
    }),
  },
};

export default exports;

import { useEffect, useState } from 'react';

type NotificationSound = 'digital' | 'organic';

export const useNotificationSound = (sound: NotificationSound = 'digital') => {
  const [sounds, setSounds] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);

  // lazy load notification sounds
  useEffect(() => {
    import('./sounds.json').then(data => {
      setSounds(data);
      setLoaded(true);
    });
  }, [setSounds]);

  const play = () => {
    if (!sounds) {
      console.error('notification sounds not loaded!');
    }

    const audio = new Audio(sounds[sound]);

    try {
      audio.play();
    } catch (e) {
      // silently catch for now
    }
  };

  return { play, loaded };
};

export default useNotificationSound;

const mediaQueryKeys = {
  all: ['media'] as const,
  list: (
    orderBy: any,
    filters: any,
    queryPageSize: number,
    queryPageIndex: number,
  ) =>
    [
      ...mediaQueryKeys.all,
      'list',
      orderBy,
      filters,
      queryPageSize,
      queryPageIndex,
    ] as const,
  url: (id?: string | null) => [...mediaQueryKeys.all, 'url', id] as const,
  byId: (id?: string | null) => [...mediaQueryKeys.all, id] as const,
};

export default mediaQueryKeys;

import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { UsedByColumn } from 'app/components/ListMedia/useMediaColumns';
import { CreateScheduleButton } from 'app/components/ListSchedules/components/CreateScheduleButton';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Schedule } from 'types/schedule';

const QuickFinderScheduleDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (schedule: Schedule) => schedule.doc.name,
      },
      {
        Header: 'Usage',
        id: 'usage',
        Cell: ({ row }) => {
          const { original: schedule }: { original: Schedule } = row;
          return <UsedByColumn usedByArray={schedule.usedBy} />;
        },
      },
    ],
    [],
  );

  // schedule list
  const scheduleListQuery = useListSchedulesQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['name', 'asc']],
    filters: { searchInput: querySearchInput },
  });
  const { callflows: schedules, totalCount = 0 } = scheduleListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      scheduleListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  const handleCreate = (id: string) => {
    // TODO: does not work with passing resource atm
    props.onSelect([id]);
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Schedules' : 'Select Schedule'}
      titleButton={
        props.allowCreate ? (
          <CreateScheduleButton
            onComplete={handleCreate}
            // navigateOnComplete={false}
          />
        ) : undefined
      }
      // table
      getRowId={(row: Schedule) => row.id}
      searchPlaceholder={'Search Schedules...'}
      totalCount={totalCount}
      columns={columns}
      data={schedules ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={scheduleListQuery.isFetching}
      queryIsLoading={scheduleListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderScheduleDialog;

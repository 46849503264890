import { VmboxMessageUpdatePayload } from 'types/vmboxMessage';
import buildItemResourceUrl from './buildItemResourceUrl';
import { postResourceDefault } from './postDefault';

// TODO: make more generic if any other resources have similar url scheme
// - uses buildResourceUrl and passes thru options from opts.buildUrlOpts
export const buildPostVmboxMessage =
  (resourceApiPathname, itemName, opts?) =>
  async (payload: VmboxMessageUpdatePayload, auth_token) => {
    // uses a POST to update items of resourves

    // TODO: any checks on POST?

    return postResourceDefault({
      url: buildItemResourceUrl(
        resourceApiPathname,
        itemName,
        payload.vmbox_id,
        payload.media_id,
        auth_token,
        opts?.buildUrlOpts,
      ),
      doc: payload.data,
      auth_token,
    });
  };

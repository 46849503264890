import { gql } from 'graphql-request';

export default gql`
  query Misc($scheduleFilters: JSON, $callflowFilters: JSON) {
    schedules: callflows(filters: $scheduleFilters) {
      totalCount
    }
    menus {
      totalCount
    }
    medias {
      totalCount
    }
    callflows(filters: $callflowFilters) {
      totalCount
    }
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */

import { find, toInteger } from 'lodash';
import { getAtPath } from 'app/utilities';

import { nodeTypes, nodeTypeAllowAfter } from './nodes';

// BETTER NODE/TREE EDITOR??: https://github.com/Naihan/react-d3-tree-editor
// - also: https://microsoft.github.io/react-dag-editor/
// - also: https://www.npmjs.com/package/react-flow-editor
// - also: (node-red is prob overkill): https://nodered.org
// - problems with current node editor are:
//   - flicker when adding/removing/modifying nodes (recalculate AFTER render, cuz render gets actual dimensions for each node)

const position = { x: 0, y: 0 };
const edgeType = 'smoothstep';

let nextId = 0;
const getNextId = () => {
  nextId++;
  return `el${nextId.toString()}`;
};

export interface ModuleItem {
  type: string;
  data: any;
}

export interface RootData {
  elements: any; // nodes and edges
  callflowIds: any; // to prevent recursive displays
  referencedCallflowIds: any; // to keep track of related callflows that we need to load
}

// Schedules and Templates are similar
// - should share the same "building" functionality
// - because we need to be able to "reapply" Templates and Schedules server-side!!!
//   - ie: changing one affects MANY!

// with Schedules, if I add/remove/re-arrange times, then I need to re-save each (dependent) Callflow to include the new time
// - if I simply update the Days/Hours IN each box, then I DONT need to re-save each Callflow
// - NOTE: on ADD a new time, every dependent callflow wont know what branch to go to for that time! (maybe why should use a Template that uses a Schedule?)
//   - should provide an easy way to "apply branch to all callflows that use this schedule" (or something like that?)

// Templates reference a callflowId
// - and also store the actual Template data locally, which can be updated from the callfowId
// - so when we update a Template, we need to manually update EVERY other Callflow that uses the template!

// called with an eventEmitter that we attach to nodes/dialogs
// - used for "duplicating" and "moving" nodes
const RootConvertFunction = eventEmitter => {
  if (!eventEmitter) {
    debugger;
  }
  // recusive function for creating nodes/edges (and missing/needed callflows)
  const convertCallflowToFlowElements = ({
    isInlineCallflow = false,
    expandSimple,
    skipEditing, // ie "notes" options, adding to menus, etc. meant for QuickDisplay mode!
    callflow, // root callflow (use modifyPath to find "current")
    setCallflow,
    modifyPath,
    // for parentSourceNode:
    // - should be passing in the connectNode to parent, w/ everything EXCEPT target filled out (only added to elements if target exists!)
    // - todo: have a way of knowing if we've reached an "expected end" or if the call is just going to hangup or something! (they prob want something else to happen)
    parentSourceNode,
    nextOptionalEdge = null, // TODO: this is what i want to call the comment above...
    rootData, // RootData
    edgeData, // elements that only are added/exist if the child exists??
    templateParent, // plural??
    templateRef, // modify-by-reference
    relatedCallflows = {}, // use this to pass in schedules/etc that are needed
    pipe,
  }) => {
    // parentSource used to create Edge to parent node!
    // let {
    //   elements,
    //   callflowIds
    //  } = rootData;

    // const { list: callflows } = store.getState().lists.callflows;

    let disallowedAddAfter = false;
    let currentCallflow = getAtPath(callflow, modifyPath);

    if (!currentCallflow) {
      // failed even loading callflow, show a "fix, replace w/ "
      // - this state should NOT happen! instead, each module should be making sure the necessary blank callflow is already created
      //   - or have an "autofix" ??
      const fixCreateBlankNode = {
        id: getNextId(),
        type: 'FixCreateBlankNode',
        data: { skipEditing, callflow, setCallflow, modifyPath },
        position,
      };
      rootData.elements.push(fixCreateBlankNode);
      if (parentSourceNode) {
        const iiedge = {
          id: getNextId(),
          source: parentSourceNode.id,
          target: fixCreateBlankNode.id,
          animated: true,
          type: 'smoothstep',
        };
        rootData.elements.push(iiedge);
      }
      parentSourceNode = fixCreateBlankNode;
      return rootData;
    }

    // // generic strategy id only!!!
    switch (currentCallflow.strategy?.type) {
      case 'blank':
        // normal/expected
        break;
      case 'schedule':
        console.error('Not editing schedule here!');
        return;

      case 'account':
        // may or may not have a Schedule at the root
        const incomingNode = {
          id: getNextId(),
          type: 'IncomingNode',
          data: {
            isInlineCallflow:
              isInlineCallflow ||
              callflow?.id === 'inline' ||
              callflow?.id?.startsWith('builtin'),
            skipEditing,
            callflow,
            setCallflow,
            modifyPath: `${modifyPath}`,
          },
          position,
        };

        if (currentCallflow.strategy?.data?.schedule?.enabled) {
          // has schedule, get and follow times
          // - this is basically the same as ScheduleNode below!
          // - slightly different
          let el1 = {
            id: getNextId(),
            type: 'ScheduleNode',
            data: {
              skipEditing,
              // insertBefore,
              // insertAfterData,
              // build moduleItem special for the schedule node to display correctly (expects to be part of a "blank" usually?)
              // - TODO: build as more modular, pass in "scheduleId" and "onComplete" functions for saving
              moduleItem: {
                data: {
                  time: {
                    callflow: {
                      id: currentCallflow.strategy?.data?.schedule?.callflow
                        ?.id,
                    },
                  },
                },
              },
              // moduleData,
              // moduleInfo,
              currentCallflow,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.schedule.callflow`, // this may be wrong! (expected to be used as part of "modules", but isnt when used here)
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.schedule.callflow`,
            // height: 100,
            position,
          };

          let edgeIncoming1 = {
            id: getNextId(),
            source: incomingNode.id,
            target: el1.id,
            animated: true,
            type: 'smoothstep',
          };

          rootData.elements.push(incomingNode);
          rootData.elements.push(el1);
          rootData.elements.push(edgeIncoming1);
          // rootData.elements.push(edge);
          // let prevNode = addNoteNodeBefore(
          //   skipEditing,
          //   rootData,
          //   null, // prevNode
          //   el1,
          //   modifyPath,
          //   // insertAfterData,
          //   // infoIdx,
          // );
          // prevNode = el1;

          // load schedule callflow, or if it does NOT exist in the local cache, then we need to report that it should be loaded
          const scheduleCallflowId =
            currentCallflow.strategy?.data?.schedule?.callflow?.id;
          if (scheduleCallflowId) {
            rootData.referencedCallflowIds.push(scheduleCallflowId);
          }
          const scheduleCallflowResult = relatedCallflows[scheduleCallflowId];
          // @ts-ignore
          const scheduleCallflow = scheduleCallflowResult?.result?.data;
          //   .find(
          //   cf => cf.id === scheduleCallflowId,
          // );
          if (!scheduleCallflow?.doc) {
            // error, or loading still?
            console.log('scheduleCallflow Error, or loading??');
            rootData.missingCallflowIds.push(scheduleCallflowId);
            return rootData;
          }

          // process times/categories
          // - same as "schedulenode" below!
          let times = scheduleCallflow?.doc?.strategy?.data?.times ?? []; //moduleData.times ?? {};
          console.log('scheduleCallflow TIMES:', times);
          times.forEach((timeDetails, i) => {
            // console.log('timeDetails:', timeDetails);
            // number in (edge/connect/line)
            const el = {
              id: getNextId(),
              type: 'OptionScheduleNode',
              data: {
                skipEditing,
                scheduleCallflow: scheduleCallflow?.doc,
                currentCallflow,
                callflow,
                setCallflow,
                modifyPath: `${modifyPath}.strategy.data.times`, // maybe wrong...
                timeKey: timeDetails.id,
                templateRef,
                templateParent,
              },
              position,
            };
            const edge = {
              id: getNextId(),
              source: el1.id,
              target: el.id,
              animated: true,
              type: 'smoothstep',
            };
            rootData.elements.push(el);
            rootData.elements.push(edge);

            console.log('account:', callflow);

            // @ts-ignore
            rootData = convertCallflowToFlowElements({
              isInlineCallflow,
              expandSimple,
              skipEditing,
              callflow, //: target?.callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.schedule.times.${timeDetails.id}.callflow`,
              parentSourceNode: el,
              edgeData: {},
              rootData,
              templateRef,
              templateParent,
              relatedCallflows,
            });
          });
        } else {
          // no schedule (jump straight into "blank" for no_schedule)

          rootData.elements.push(incomingNode);

          rootData = convertCallflowToFlowElements({
            isInlineCallflow,
            expandSimple,
            skipEditing,
            callflow, //: target?.callflow,
            setCallflow,
            modifyPath: `${modifyPath}.strategy.data.no_schedule.callflow`,
            parentSourceNode: incomingNode, //el,
            edgeData: {},
            rootData,
            templateRef,
            templateParent,
            relatedCallflows,
            pipe,
          });

          // console.log('ROOT DATA AFTER', rootData);
        }
        return rootData;

      default:
        console.error(
          'Invalid currentCallflow strategy',
          currentCallflow.strategy?.type,
          currentCallflow,
        );
        return rootData;
      // break;
    }

    // UNCOMMENT following two lines to fix a broken strategy
    // currentCallflow.strategy.type = 'blank';
    // setCallflow({ ...currentCallflow }, {}, true);

    //   const brokenNode = {
    //     id: getNextId(),
    //     type: 'MissingNode',
    //     data: {
    //       title: 'Unsupported',
    //       label: `Type: ${currentCallflow.strategy.id}`,
    //     },
    //     position,
    //   };
    //   rootData.elements.push(brokenNode);
    //   if (parentSourceNode) {
    //     const brokenEdge1 = {
    //       id: getNextId(),
    //       source: parentSourceNode.id,
    //       target: brokenNode.id,
    //       animated: true,
    //       type: 'smoothstep',
    //     };
    //     rootData.elements.push(brokenEdge1);
    //   }
    //   parentSourceNode = brokenNode;
    //   return rootData;

    // // console.log('convertCallflowToFlowElements:', currentCallflow);

    // TODO: prevent recursive loops (search previous elements before going to an external callflow ["Extension IVR Menu" or "User Extension"])
    // - determine if callflow is already shown in elements!
    if (currentCallflow.id && currentCallflow.id !== 'inline') {
      if (rootData.callflowIds.includes(currentCallflow.id)) {
        console.info(
          'Already displaying this menu previously in flow! (preventing recursive)',
          currentCallflow,
          rootData.callflowIds,
        );
        return rootData;
      }
      rootData.callflowIds.push(currentCallflow.id);
    }

    if (!rootData.elements.length) {
      // first overall
      // - handles if a "template" or not
      const incomingNode = {
        id: getNextId(),
        type: 'IncomingNode',
        data: {
          isInlineCallflow:
            isInlineCallflow ||
            callflow?.id === 'inline' ||
            callflow?.id?.startsWith('builtin'),
          skipEditing,
          callflow,
          setCallflow,
          modifyPath: `${modifyPath}`,
        },
        position,
      };
      rootData.elements.push(incomingNode);
      parentSourceNode = incomingNode;
    }

    // "SimpleMenu" view
    // - for when the ENTIRE callflow is a "simple" flow (ie NOT a "Simple" module in an simple.enabled=false callflow)
    // - this *may* be called a SimpleSchedule or SimpleCallflow instead of SimpleMenu
    //   - should depend on the parentSourceNode's type (menu option, schedule option, start of callflow etc)
    if (currentCallflow.strategy?.simple?.enabled && !expandSimple) {
      if (parentSourceNode?.type === 'OptionNode') {
        const simpleEl1 = {
          id: getNextId(),
          type: 'SimpleMenuNode',
          data: {
            skipEditing,
            // insertBefore,
            // insertAfterData,
            // moduleItem,
            // moduleData,
            // moduleInfo,
            currentCallflow,
            callflow,
            setCallflow,
            modifyPath,
            // : `${modifyPath}.strategy.data.modules.${infoIdx}`,
            templateRef,
            templateParent,
            // pass this along for passing into the "modify" dialog
            menuData: parentSourceNode.data.menuData,
            targetKey: parentSourceNode.data.targetKey,
          },
          key: modifyPath,
          // `${modifyPath}.strategy.data.modules.${infoIdx}`,
          // height: 100,
          position,
        };
        const simpleEdge1 = {
          id: getNextId(),
          source: parentSourceNode.id,
          target: simpleEl1.id,
          animated: true,
          type: 'smoothstep',
        };

        rootData.elements.push(simpleEl1);
        rootData.elements.push(simpleEdge1);
      } else {
        // NOT a menu option
        // - likely the start of the callflow, or maybe a schedule?
        const simpleEl2 = {
          id: getNextId(),
          type: 'SimpleCallflowNode',
          data: {
            skipEditing,
            // insertBefore,
            // insertAfterData,
            // moduleItem,
            // moduleData,
            // moduleInfo,
            currentCallflow,
            callflow,
            setCallflow,
            modifyPath,
            // : `${modifyPath}.strategy.data.modules.${infoIdx}`,
            templateRef,
            templateParent,
          },
          key: modifyPath,
          // `${modifyPath}.strategy.data.modules.${infoIdx}`,
          // height: 100,
          position,
        };
        const simpleEdge2 = {
          id: getNextId(),
          source: parentSourceNode.id,
          target: simpleEl2.id,
          animated: true,
          type: 'smoothstep',
        };

        rootData.elements.push(simpleEl2);
        rootData.elements.push(simpleEdge2);
      }

      return rootData;
    }

    // Pass to strategy
    // - strategy returns elements array (MUST already exist!)
    // - TODO: handle ones besides Generic/blank
    let canContinue = true,
      prevNode = parentSourceNode,
      currentIdx = -1;
    for (let infoIdxStr in currentCallflow.strategy.data?.modules ?? []) {
      // console.log(
      //   'infoIdx:',
      //   typeof infoIdx,
      //   infoIdx,
      //   typeof currentCallflow.strategy.config?.modules,
      //   currentCallflow.strategy.config?.modules,
      //   modifyPath
      // );
      let infoIdx = toInteger(infoIdxStr);
      currentIdx = infoIdx;
      if (!canContinue) {
        continue;
      }
      const moduleItem: ModuleItem =
        currentCallflow.strategy.data.modules[infoIdx];
      // const moduleInfo = currentCallflow.strategy.config.modules[infoIdx];
      // const moduleData = currentCallflow.strategy.data.opts[infoIdx];

      const insertBefore = infoIdx === 0 ? true : false;
      const insertAfterData = {
        pipe,
        callflow,
        setCallflow,
        modifyPath,
        index: infoIdx + 1, // CURRENT index
        requireAllowBefore: false, // never true? only WAS used for "single item" generic callflows
        requireAllowAfter:
          infoIdx === currentCallflow.strategy.data?.modules.length - 1
            ? false
            : true,
      };

      // console.log('insertBefore:', insertBefore, infoIdx);
      // console.log('insertAfterData:', insertAfterData);

      // // if this is the 1st one in THIS list (but other lists exist, ie NOT the first callflow in IVR Menu), then allow "insert above" button
      // const showInsertBefore = infoIdx === 0 ? true : false;

      // if removing and LAST in list, then REMOVE from parent (if parent is menu?)

      // list of modules, display them!
      let el1, el2, el3, el4;
      let edge1, edge2, edge3, edge4;
      let nel1, nel2; // note element
      let nedge1, nedge2; // node edge;
      switch (moduleItem.type) {
        case 'Menu':
          // link from previous to this one
          // -
          el1 = {
            id: getNextId(),
            type: 'MenuNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              infoIdx,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              currentCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          // expecting this to be the last item in the modules array in the Strategy (menu handles all the options and the timeout/fallback option?)
          // - NOTE: there is NO "children._" for a menu! only "timeout" that works!
          // - TODO: allow ones to come after, but make sure to specify that it is a "fallback" type of Edge relationship/connection
          let targets = moduleItem.data?.targets ?? {};
          Object.keys(targets)
            .sort()
            // eslint-disable-next-line no-loop-func
            .forEach(key => {
              const target = targets[key];

              // callflow exists? dont show option if not exists
              if (skipEditing) {
                if (!target?.callflow) {
                  // continue/skip
                  return;
                }
              }

              // number in (edge/connect/line)
              const el = {
                id: getNextId(),
                type: 'OptionNode',
                data: {
                  skipEditing,
                  text: key,
                  callflow,
                  currentCallflow,
                  setCallflow,
                  modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.targets`,
                  targetKey: key,
                  templateRef,
                  templateParent,
                  menuData: el1.data,
                },
                position,
              };
              const edge = {
                id: getNextId(),
                source: el1.id,
                target: el.id,
                animated: true,
                type: 'smoothstep',
              };
              rootData.elements.push(el);
              rootData.elements.push(edge);

              rootData = convertCallflowToFlowElements({
                expandSimple,
                skipEditing,
                callflow, //: target?.callflow,
                setCallflow,
                modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.targets.${key}.callflow`,
                parentSourceNode: el,
                edgeData: {},
                rootData,
                templateRef,
                templateParent,
                relatedCallflows,
                pipe,
              });
            });

          // console.log('MenuTemplate:', templateParent);
          if (!skipEditing) {
            const insertNodeMenu = {
              id: getNextId(),
              type: 'InsertMenuOptionNode',
              data: {
                skipEditing,
                // insertAfterData: {
                currentCallflow,
                callflow,
                setCallflow,
                modifyPath,
                index: currentIdx + 1, // the "splice" point
                requireAllowBefore: false,
                requireAllowAfter: false,
                // },
                templateRef,
                templateParent,
                menuData: el1.data,
              },
              // height: 24,
              position,
            };
            const iiedgeMenu = {
              id: getNextId(),
              source: el1.id,
              target: insertNodeMenu.id,
              animated: true,
              type: 'smoothstep',
            };
            rootData.elements.push(insertNodeMenu);
            rootData.elements.push(iiedgeMenu);
          }
          break;

        case 'Schedule':
          // link from previous to this one
          // -
          el1 = {
            id: getNextId(),
            type: 'ScheduleNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              infoIdx,
              moduleItem,
              // moduleData,
              // moduleInfo,
              currentCallflow,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          const schedule_id = moduleItem?.data?.schedule_id; // belongs to line (strategy.data.schedules[schedule_id])
          const schedule = callflow?.schedules?.[schedule_id];
          const categories = [...(schedule?.categories ?? [])];

          // console.log('SCHEDULE:', { schedule, moduleItem });
          // ROOT OF CALLFLOW-STRATEGY HOLDS THE IDs (not the routes though!)
          // - cuz we want the IDs to be "shared", but the routes are NOT shared
          // currentCallflow?.strategy?.data?.schedules?.[schedule_id]? = {
          //   categories: [{
          //     id: 'xyz',
          //     name: 'xyz',
          //     values: [{
          //       type: 'hours',
          //       value: ''
          //     }]
          //   }],
          //   fallback: {
          //     name: ''
          //     // NO VALUES!
          //   },
          //   forced_catgory_id: 'xyz',
          //   timezone: '...'
          // }

          // ROUTES
          // moduleItem.data.routes: { [category_id]: { callflow: ... }}, // callflows

          // add fallback time
          categories.push({
            id: 'fallback',
            name: 'Fallback',
            values: [],
            ...(schedule?.fallback ?? {}),
          });

          // console.log('Categories:', categories);

          // // load schedule callflow, or if it does NOT exist in the local cache, then we need to report that it should be loaded
          // const timeCallflowId = moduleItem?.data?.time?.callflow?.id;
          // if (timeCallflowId) {
          //   rootData.referencedCallflowIds.push(timeCallflowId);
          // }
          // const scheduleCallflowResult = relatedCallflows[timeCallflowId];
          // // @ts-ignore
          // const scheduleCallflow = scheduleCallflowResult?.result?.data;
          // //   .find(
          // //   cf => cf.id === timeCallflowId,
          // // );
          // if (!scheduleCallflow?.doc) {
          //   // error, or loading still?
          //   rootData.missingCallflowIds.push(timeCallflowId);
          //   continue;
          // }

          // // expecting this to be the last item in the modules array in the Strategy (menu handles all the options and the timeout/fallback option?)
          // // - NOTE: there is NO "children._" for a schedule! only "timeout" that works!
          // // - TODO: allow ones to come after, but make sure to specify that it is a "fallback" type of Edge relationship/connection
          // let categories = scheduleCallflow?.doc?.strategy?.data?.categories ?? []; //moduleData.categories ?? {};
          // eslint-disable-next-line no-loop-func
          categories.forEach((category, i) => {
            // console.log('timeDetails:', timeDetails);
            // number in (edge/connect/line)
            const el = {
              id: getNextId(),
              type: 'OptionScheduleNode',
              data: {
                skipEditing,
                // scheduleCallflow: scheduleCallflow?.doc,
                schedule_id,
                // moduleItem,
                category,
                currentCallflow,
                callflow,
                setCallflow,
                // modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.categories`, // unnecessary, always saving to root-ish
                // caetgoryIndex: i,
                templateRef,
                templateParent,
              },
              position,
            };
            const edge = {
              id: getNextId(),
              source: el1.id,
              target: el.id,
              animated: true,
              type: 'smoothstep',
            };
            rootData.elements.push(el);
            rootData.elements.push(edge);

            // @ts-ignore
            rootData = convertCallflowToFlowElements({
              expandSimple,
              skipEditing,
              callflow, //: target?.callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.routes.${category.id}.callflow`,
              parentSourceNode: el,
              edgeData: {},
              rootData,
              templateRef,
              templateParent,
              relatedCallflows,
              pipe,
            });
          });

          if (!skipEditing) {
            const insertNodeSchedule = {
              id: getNextId(),
              type: 'InsertScheduleOptionNode',
              data: {
                skipEditing,
                // insertAfterData: {
                currentCallflow,
                callflow,
                setCallflow,
                modifyPath,
                index: currentIdx + 1, // the "splice" point
                requireAllowBefore: false,
                requireAllowAfter: false,
                // },
                templateRef,
                templateParent,
                scheduleData: el1.data,
              },
              // height: 24,
              position,
            };
            const iiedgeMenu = {
              id: getNextId(),
              source: el1.id,
              target: insertNodeSchedule.id,
              animated: true,
              type: 'smoothstep',
            };
            // TODO: insert BEFORE "fallback" (better for UX)?
            rootData.elements.push(insertNodeSchedule);
            rootData.elements.push(iiedgeMenu);
          }

          break;

        case 'Message':
          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'MessageNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              infoIdx,
              currentCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          // edge to previous node

          // Note/Comment tester
          // - if the previous one is NOT already a Note
          //   - then add the AddNote type!
          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        case 'GoToInFlow':
          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'GoToInFlowNode',
            data: {
              rootData,
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              infoIdx,
              currentCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          // edge to previous node

          // Note/Comment tester
          // - if the previous one is NOT already a Note
          //   - then add the AddNote type!
          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          // TODO:
          // - create EDGE to wherever this module points!!! (by ID)
          //   - actually, do this AFTER creating all nodes (in case we are skipping to a module that is created later (ie, in a menu/schedule))

          break;

        case 'PlayAudio':
          // link to viewing that callflow (NOT showing here!)
          // - this is an EXTERNAL callflow! NOT an inline one!

          // // Note/Comment module/edge
          // // - add Note if this moduleData has a note!
          // const addNoteNodeBeforeEdge = (cData) => {
          //   // cData = moduleData
          //   // - more prone to failure?
          // };

          // // - OR?: if the previous one is NOT already a Note
          // //   - then add the AddNote type!
          // if (prevNode?.type !== 'NoteNode') {
          //   // custom EDGE!
          // }

          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'PlayAudioNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              infoIdx,
              currentCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          // edge to previous node

          // Note/Comment tester
          // - if the previous one is NOT already a Note
          //   - then add the AddNote type!
          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          // edge1 = {
          //   id: getNextId(),
          //   source: prevNode.id,
          //   target: el1.id,
          //   type: 'insert',
          //   animated: true,
          //   // arrowHeadType: 'arrow',
          // };
          // rootData.elements.push(el1);
          // rootData.elements.push(edge1);
          // prevNode = el1;

          // edge to previous node
          //
          // type: 'custom',
          // data: { text: 'custom edge' },

          break;

        case 'Note':
          // link to viewing that callflow (NOT showing here!)
          // - this is an EXTERNAL callflow! NOT an inline one!

          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'NoteNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };
          // edge to previous node
          //
          // type: 'custom',
          // data: { text: 'custom edge' },

          // // Note/Comment module/edge
          // // - if the previous one is NOT already a Note
          // //   - then add the AddNote type!
          // if (prevNode.type === 'NoteNode') {
          //   // custom Note Edge
          // } else {
          //   // normal edge
          // }

          edge1 = {
            id: getNextId(),
            source: prevNode.id,
            target: el1.id,
            // type: edgeType,
            type: 'smoothstep',
            animated: true,
            // arrowHeadType: 'arrow',
          };
          rootData.elements.push(el1);
          rootData.elements.push(edge1);
          prevNode = el1;

          break;

        case 'Ring':
          // link to viewing that callflow (NOT showing here!)
          // - this is an EXTERNAL callflow! NOT an inline one!

          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'RingNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              currentCallflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        case 'Directory':
          // link to viewing that callflow (NOT showing here!)
          // - this is an EXTERNAL callflow! NOT an inline one!

          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'DirectoryNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              currentCallflow,

              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        case 'ConferenceRoom':
          // link to viewing that callflow (NOT showing here!)
          // - this is an EXTERNAL callflow! NOT an inline one!

          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'ConferenceRoomNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              currentCallflow,

              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        case 'Transfer':
          el1 = {
            id: getNextId(),
            type: 'TransferNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              currentCallflow,

              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;
          break;

        case 'Hangup':
          el1 = {
            id: getNextId(),
            type: 'HangupNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              currentCallflow,

              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
              pipe,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        case 'VoicemailBox':
          el1 = {
            id: getNextId(),
            type: 'VoicemailNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              currentCallflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
              pipe,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;
        case 'Simple':
          // render the Simple node, or if expandSimple then render individual items of it!
          // console.log('HEREHERHEREHER');
          el1 = {
            id: getNextId(),
            type: 'SimpleNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              currentCallflow,

              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        case 'Template':
          // Process strategy for Template
          // - will have been pre-built (when added)
          // - returns "output" points/ids that we then attach to
          /*
          templateId: 'xyz',
          templateData: {...}, // for updating the callflow separately from a remote template change (when re-building template)
          variables: {}, // for building template (cascade/override for applying)
          outputCallflow: {...},
          continueTo: [ { id: 'template_endpoint_xyz', callflow: {...} } ], //
          */
          el1 = {
            id: getNextId(),
            type: 'TemplateNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              currentCallflow,

              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          // const templateId = moduleItem?.data?.templateId;
          // if (templateId) {
          //   rootData.referencedCallflowIds.push(templateId);
          // }
          // const templateCallflowResult = relatedCallflows[templateId];
          // const templateCallflow = templateCallflowResult?.result?.data;
          // //   .find(
          // //   cf => cf.id === timeCallflowId,
          // // );
          // if (!templateCallflow?.doc) {
          //   // error, or loading still?
          //   rootData.missingCallflowIds.push(templateId);
          //   continue;
          // }

          // "templateCallflow" is only used when updating (or first apply)
          // - instead we use the "cached" version that exists in the moduleData
          const usedTemplateCallflow = moduleItem?.data?.templateCallflow;

          // process outputStrategy (outputCallflow) (already has correct variables!)
          // - contains Endpoint (Ids to replace!)
          // - expecting those Ids to exist in the moduleData.continueTo ([{id: 'xyz', callflow: {...}}])
          const outputCallflow = moduleItem?.data?.outputCallflow;

          if (!outputCallflow) {
            console.info(
              'Missing outputCallflow for Template, likely just needs to be built/rendered',
            );
            continue;
          }

          // needs to return TemplateEndpoint id/path for each??
          // - return through modifying-by-reference?
          //  - templateData.continueIdsToEls = {id: node} ??
          let thisTemplateRef = { continueIdsToEls: {} };
          // @ts-ignore
          rootData = convertCallflowToFlowElements({
            expandSimple,
            skipEditing,
            callflow,
            setCallflow,
            modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.outputCallflow`,
            parentSourceNode: prevNode,
            edgeData: {},
            rootData,
            templateParent: moduleItem, // mostly for knowing if IN a template (ie, cannot edit necessarily, "ejects" from Template)
            templateRef: thisTemplateRef, // for catching created nodes (to reference later)
            relatedCallflows,
            pipe,
          });

          // id => el of node?
          // - same as should happen in GenericDefault?? that should be more of a setAtPath path....
          // thisTemplateRef.continueIdsToEls;

          const continueTo = moduleItem?.data.continueTo || [];

          // eslint-disable-next-line no-loop-func
          Object.keys(thisTemplateRef.continueIdsToEls).forEach(id => {
            const el = thisTemplateRef.continueIdsToEls[id];

            const continueIdx = continueTo.findIndex(item => item.id === id);
            if (continueIdx > -1) {
              // @ts-ignore
              rootData = convertCallflowToFlowElements({
                expandSimple,
                skipEditing,
                callflow, //: target?.callflow,
                setCallflow,
                modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.continueTo.${continueIdx}.callflow`,
                parentSourceNode: el,
                edgeData: {},
                rootData,
                templateRef,
                relatedCallflows,
                pipe,
                // templateParent // moduleData?
              });
            } else {
              // no path to go to, create a new one here
              // - does it automatically!
              const insertNode = {
                id: getNextId(),
                type: 'FixCreateBlankNode',
                data: {
                  skipEditing,
                  // insertAfterData: {
                  callflow,
                  setCallflow,
                  modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.data.continueTo`,
                  templateParent,
                  templateRef,
                  fixData: {
                    id,
                    callflow: {
                      id: 'inline',
                      flow: {},
                      numbers: [],
                      strategy: {
                        type: 'blank',
                        data: {
                          modules: [],
                        },
                      },
                    },
                  },
                  fixBySpliceIdx: continueTo.length,
                  pipe,
                  // index: currentIdx + 1, // the "splice" point
                  // requireAllowBefore: false,
                  // requireAllowAfter: false,
                  // templateParent,
                  // },
                },
                // height: 24,
                position,
              };
              const iiedge = {
                id: getNextId(),
                source: el.id,
                target: insertNode.id,
                animated: true,
                type: 'smoothstep',
              };
              rootData.elements.push(insertNode);
              rootData.elements.push(iiedge);
            }
          });

          // continueTo.map((item, i) => {
          //   // TemplateEndpoint exists?
          //   // - TODO: remove the TemplateEndpoint from the view?
          //   if (!thisTemplateRef.continueIdsToEls[item.id]) {
          //     // no node to attach to!
          //     return;
          //   }
          //   rootData = convertCallflowToFlowElements({
          //     callflow, //: target?.callflow,
          //     setCallflow,
          //     modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}.continueTo[${i}].callflow`,
          //     parentSourceNode: thisTemplateRef.continueIdsToEls[item.id],
          //     edgeData: {},
          //     rootData,
          //     templateRef,
          //   });
          // });

          // disallowedAddAfter = true;

          break;

        case 'TemplateEndpoint':
          el1 = {
            id: getNextId(),
            type: 'TemplateEndpointNode',
            data: {
              skipEditing,
              insertBefore,
              insertAfterData,
              moduleItem,
              currentCallflow,

              // moduleData,
              // moduleInfo,
              callflow,
              setCallflow,
              modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
              templateRef,
              templateParent,
            },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            // height: 100,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          if (templateRef?.continueIdsToEls) {
            templateRef.continueIdsToEls[moduleItem?.data?.id ?? ''] = el1;
          }

          break;

        case 'ContinueToCallflow':
          // link to viewing that callflow (NOT showing here!)
          // - this is an EXTERNAL callflow! NOT an inline one!
          window.alert('fix ContinueToCallflow');

          // link from previous to this one
          el1 = {
            id: getNextId(),
            type: 'ContinueToCallflowNode',
            // data: moduleData, // This is the old format of passing in data
            // moduleInfo,
            moduleItem,
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            position,
          };

          prevNode = addNoteNodeBefore(
            skipEditing,
            rootData,
            prevNode,
            el1,
            modifyPath,
            insertAfterData,
            infoIdx,
          );
          prevNode = el1;

          break;

        default:
          el1 = {
            id: getNextId(),
            type: 'MissingNode',
            data: { skipEditing, label: moduleItem.type },
            key: `${modifyPath}.strategy.data.modules.${infoIdx}`,
            position,
          };
          edge1 = {
            id: getNextId(),
            source: prevNode.id,
            target: el1.id,
            // type: edgeType,
            type: 'smoothstep',
            animated: true,
            arrowHeadType: 'arrow',
          };
          rootData.elements.push(el1);
          rootData.elements.push(edge1);
          prevNode = el1;
          break;
      }
    }

    // add a trailing "Insert" if allowed
    // if none in strategy, show a Plus button
    // console.log('currentCallflow:', currentCallflow);
    if (
      !disallowedAddAfter &&
      (!currentCallflow?.strategy?.data?.modules?.length ||
        nodeTypeAllowAfter[prevNode?.type])
    ) {
      // TODO: have the "skipEditing" version display differently? (like an error??)
      const insertNode = {
        id: getNextId(),
        type: 'InsertNode',
        data: {
          skipEditing,
          // insertAfterData: {
          callflow,
          setCallflow,
          currentCallflow,
          modifyPath,
          index: currentIdx + 1, // the "splice" point
          requireAllowBefore: false,
          requireAllowAfter: false,
          templateRef,
          templateParent,
          pipe,
          // },
        },
        // height: 24,
        position,
      };
      const iiedge = {
        id: getNextId(),
        source: prevNode.id,
        target: insertNode.id,
        animated: true,
        type: 'smoothstep',
      };
      rootData.elements.push(insertNode);
      rootData.elements.push(iiedge);
    }

    return rootData;
  };
  return convertCallflowToFlowElements;
};

const addNoteNodeBefore = (
  skipEditing, // skip "placeholder" notes
  rootData,
  prevNode,
  thisEl,
  modifyPath,
  insertAfterData,
  infoIdx,
) => {
  // cData = moduleData
  // - more prone to failure?
  let thisEdge1, thisEdge2, noteNode, noteEdge;
  if (prevNode.type === 'NoteNode') {
    // edge from existing Note
    thisEdge1 = {
      id: getNextId(),
      source: prevNode.id,
      target: thisEl.id,
      // type: edgeType,
      type: 'smoothstep',
      animated: true,
      arrowHeadType: 'arrow',
    };
    rootData.elements.push(thisEl);
    rootData.elements.push(thisEdge1);
  } else {
    // AddNote node/edge
    // console.log('TYPE INSERT');
    if (!skipEditing) {
      noteNode = {
        id: getNextId(),
        type: 'NoteNode',
        data: {
          skipEditing,
          exists: false, // this will be semi-obvious to the NoteNode module because the moduleData won't exist!
          insertAfterData,
          // callflow,
          // setCallflow,
          // modifyPath: `${modifyPath}.strategy.data.modules.${infoIdx}`,
        },
        key: `${modifyPath}.strategy.data.modules.${infoIdx}__notenode`, // for focus after modify
        // height: 100,
        position,
      };
      thisEdge1 = {
        id: getNextId(),
        source: prevNode.id,
        target: noteNode.id,
        // type: edgeType,
        type: 'smoothstep',
        animated: true,
        // arrowHeadType: 'arrow',
      };
      thisEdge2 = {
        id: getNextId(),
        source: noteNode.id,
        target: thisEl.id,
        // type: edgeType,
        type: 'smoothstep',
        animated: true,
        arrowHeadType: 'arrow',
      };
      rootData.elements.push(noteNode);
      rootData.elements.push(thisEl);
      rootData.elements.push(thisEdge1);
      rootData.elements.push(thisEdge2);
    } else {
      // QuickDisplay
      thisEdge1 = {
        id: getNextId(),
        source: prevNode.id,
        target: thisEl.id,
        // type: edgeType,
        type: 'smoothstep',
        animated: true,
        // arrowHeadType: 'arrow',
      };
      rootData.elements.push(thisEl);
      rootData.elements.push(thisEdge1);
    }
  }
  return prevNode;
};

export default RootConvertFunction;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from 'app/design';
import React, { useState } from 'react';

// import { useSetupHook } from '../SetupHook';

// let gAccessTokenUUID; // = localStorage.getItem('ms_auth_access_token');

interface CsvProps {
  onClose: (event?: any) => void;
}

export const Csv = ({ onClose }: CsvProps) => {
  // const dispatch = useDispatch();

  const [localState, setLocalState] = useState({
    error: null,
    users: [] as { id: string; displayName: string }[],
    loading: false,
  });

  // const { list: callflows } = useSelector((state) => state.lists.callflows);

  // const {
  //   defaultAccount,
  //   createUser,
  //   allowExtensions,
  //   syncAll,
  // } = useSetupHook();

  // const handleCsv = () => {
  //   setLocalState(s => ({
  //     ...s,
  //     loading: true,
  //     loaded: true,
  //     error: null,
  //   }));
  // };

  return (
    <Dialog
      // fullWidth
      maxWidth="md"
      open
      // onClose={onClose}
    >
      {localState.loading ? (
        <DialogInProgress title={'Fetching users...'} />
      ) : (
        <>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Import from CSV / Sheets</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ padding: '8px 12px' }}>
            {localState.users.length ? (
              <div
                // ref={contentRef}
                style={
                  {
                    // width: '50vw',
                    // padding: '8px',
                  }
                }
              >
                <div>
                  {localState.users.map(user => {
                    return <div key={user.id}>{user.displayName}</div>;
                  })}
                </div>
              </div>
            ) : (
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={e => onClose()}
                >
                  Upload CSV
                </Button>
                &nbsp;
                <Button color="info" variant="link" size="small" href="#">
                  Download Example CSV
                </Button>
              </div>
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            <div style={{ width: '100%' }}>
              {localState.users.length ? (
                <Button variant="outlined" onClick={e => onClose()}>
                  Import Users
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="gray"
                  onClick={e => onClose()}
                >
                  Cancel
                </Button>
              )}
            </div>
          </DialogActions>

          {/* <DialogSaveActions
            errors={[error]}
            label={'After creating your user you can assign them devices, etc'}
            onSave={handleCreateUser}
          /> */}
        </>
      )}
    </Dialog>
  );
};

export default Csv;

import { useQuery } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { User } from 'types/user';
import { userQueryKeys } from './index';
import { sdk } from 'app/sdk';
// import { fetchFromGraphql } from 'app/sdk/utils';

export const useAuthenticatedUserQuery = () => {
  const authState = useAuthSelector();
  const owner_id = authState?.auth_token_decoded?.owner_id;
  return useQuery<User, Error>(
    userQueryKeys.byId(owner_id),
    () =>
      sdk.user.query.userById(
        { id: owner_id },
        { authToken: authState.auth_token },
      ),
    // fetchFromGraphql(
    //   'userById',
    //   { id: owner_id },
    //   { authToken: authState.auth_token },
    // ),
    // don't fetch until the auth_token (and decoded owner_id) exist
    { enabled: !!owner_id },
  );
};

export default useAuthenticatedUserQuery;

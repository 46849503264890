import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListDirectoriesQuery } from 'app/hooks/queries/directory';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Directory } from 'types/directory';

const QuickFinderDirectoryDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (directory: Directory) => directory.doc.name,
      },
    ],
    [],
  );

  // directory list
  const directoryListQuery = useListDirectoriesQuery(
    queryPageIndex * queryPageSize,
    queryPageSize,
    [['name', 'asc']],
    { searchInput: querySearchInput },
  );
  const { directories, totalCount = 0 } = directoryListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      directoryListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={
        props.multiple ? 'Select Multiple Directories' : 'Select Directory'
      }
      // table
      getRowId={(row: Directory) => row.id}
      searchPlaceholder={'Search Directories...'}
      totalCount={totalCount}
      columns={columns}
      data={directories ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={directoryListQuery.isFetching}
      queryIsLoading={directoryListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderDirectoryDialog;

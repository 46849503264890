import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  SwipeLeftAlt as SwipeLeftAltIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../../..';

// import { USER_ADDABLE_COMPONENTS } from '../../../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../../../Strategies/components';

import { ChooseDialog } from '../../ChooseDialog';

export const MenuItem_EjectSimple = props => {
  const { data, mode, onClick, onClose } = props;
  const {
    // callflow: rootCallflow,
    callflow,
    setCallflow,
    modifyPath,
    // insertBefore, // bool
    // insertAfterData,
    currentCallflow,
    templateParent,
    // callflow,
    // setCallflow,
    // modifyPath,
  } = data;

  // const { callflow, setCallflow, modifyPath } = insertAfterData;

  const [showInsertAfter, setShowInsertAfter] = useState(null);
  const setShowInsertAfterWrap = setTo => () => setShowInsertAfter(setTo);

  // const handleEject = () => {
  //   // simple change the "simple.enabled" setting
  //   setAtPath(callflow, 'strategy.simple.enabled', false);
  //   setCallflow({ ...callflow });
  // };

  return (
    <>
      <MenuItem
        onClick={e => {
          // if (rootCallflow.type != 'template' && templateParent) {
          //   alert(
          //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
          //   );
          // }
          onClose();
          // handleEject();
          onClick();
        }}
        component={'a'}
      >
        <ListItemIcon>
          <SwipeLeftAltIcon />
        </ListItemIcon>
        <ListItemText primary="Eject" />
      </MenuItem>
    </>
  );
};

export default MenuItem_EjectSimple;

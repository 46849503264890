import { gql } from 'graphql-request';

export default gql`
  query callflowById($mask: String, $id: String) {
    callflowById(mask: $mask, id: $id) {
      id
      account_id
      doc
      listing
      createdAt
      updatedAt
      usedBy
      Schedules
      Owner {
        id
        doc
        extra
      }
    }
  }
`;

import { useAuthSelector } from 'app/data/auth';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { sdk } from 'app/sdk';
import { Menu } from 'types/menu';
import { Vmbox } from 'types/vmbox';
import vmboxQueryKeys from './vmboxQueryKeys';

interface QueryParams {
  id?: string;
  options?: Omit<
    UseQueryOptions<Vmbox, Error, Vmbox, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export const useVmboxQuery = ({ id, options }: QueryParams) => {
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = vmboxQueryKeys.byId(id);

  const query = useQuery<Vmbox, Error>(
    queryKey,
    () =>
      sdk.vmbox.query.vmboxById({ id }, { authToken: authState.auth_token }),
    {
      enabled: id ? true : false, //authState?.auth_token_decoded?.owner_id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useVmboxQuery;

// Create URL from [resourceApiPathname: users]
// - expects auth_token for getting account_id (from decoded jwt)
// - can accept options for encoding the ID (for phoneNumer) or skipping the "id" (for account)
import { jwtDecode } from './kazoo';

const buildResourceUrl = (
  resourceApiPathname,
  doc_id,
  auth_token,
  buildUrlOpts,
) => {
  const { account_id } = jwtDecode(auth_token);

  if (buildUrlOpts?.encodeId) {
    return `/accounts/${account_id}/${resourceApiPathname}/${encodeURIComponent(
      doc_id,
    )}`;
  }
  if (buildUrlOpts?.account) {
    return `/accounts/${account_id}`;
  }

  // Make sure to add skipId to create/put
  if (buildUrlOpts?.skipId) {
    return `/accounts/${account_id}/${resourceApiPathname}`;
  }

  if (buildUrlOpts?.mediaUrl) {
    // need updated_at at end? not on any docs I see in db ("&_=${updated_at}
    return `/accounts/${account_id}/${resourceApiPathname}/${doc_id}/raw?auth_token=${auth_token}&_=${buildUrlOpts?.mediaUpdatedAt}`;
  }

  return `/accounts/${account_id}/${resourceApiPathname}/${doc_id}`;
};

// TODO: still needed?
const buildResourceUrlWrapper = (
  resourceApiPathname,
  doc_id,
  auth_token,
  buildUrlOpts,
) => {
  return `${buildResourceUrl(
    resourceApiPathname,
    doc_id,
    auth_token,
    buildUrlOpts,
  )}${buildUrlOpts?.skipInternalSync ? '?skip-internal-sync=true' : ''}`;
};

export default buildResourceUrlWrapper;

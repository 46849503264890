import { useAuthSelector } from 'app/data/auth';

import { Box, Button, Grid, Typography } from 'app/design';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { useQueryClient } from 'react-query';
import { UserPrivLevel } from '../../../types/user';
import { Alert, Container } from '../../design';
import { useUpdateDevicePartial } from '../../hooks/mutations/device';
import deviceQueryKeys from '../../hooks/queries/device/deviceQueryKeys';
import { useAuthenticatedUserQuery } from '../../hooks/queries/user';

import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useDispatch } from 'react-redux';
import { useWebphoneContext } from 'app/components/Webphone/Store';
import { useWebphoneSelector } from 'app/data/webphone';

import { WebphoneUI } from './UI';

import { CreateWebphone } from './UI/CreateWebphone';

const Webphone = () => {
  // TODO: Get the user's webphone
  // - type=softphone
  // - subtype=webphone
  const { data: authenticatedUser } = useAuthenticatedUserQuery();
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  const owner_id = authState?.auth_token_decoded?.owner_id;
  const updateDevice = useUpdateDevicePartial();
  const webphoneQuery = useListDevicesQuery({
    take: 1,
    filters: {
      raw: [
        {
          or: [
            {
              variables: {
                docFilters: [
                  ['owner_id', '=', owner_id],
                  ['device_type', '=', 'webphone'],
                ],
              },
            },
            {
              variables: {
                docFilters: [
                  ['owner_id', '=', owner_id],
                  ['subtype', '=', 'webphone'],
                ],
              },
            },
          ],
        },
      ],
    },
  });

  // console.log('webphoneQuery:', webphoneQuery?.data);

  const device = webphoneQuery.data?.devices?.length
    ? webphoneQuery.data?.devices[0]
    : undefined;

  // console.log('DEVICE:', device);
  // const { devices, user } = useSelector(state => ({
  //   devices: state.lists.devices.list ?? [],
  //   user: state.auth.user_data,
  // }));

  // // TODO: pass in the user's "webphone" device
  // // - allow them to choose which webphone, etc? why would they want to?? (caller id changes??)
  // const device = devices.find(
  //   d =>
  //     d.doc.owner_id === user.id &&
  //     d.doc.device_type === 'softphone' &&
  //     d.doc?.subtype === 'webphone',
  // );

  const enableWebphone = async () => {
    // enable web phone
    const resp = await updateDevice.mutateAsync({
      id: device.id,
      enabled: true,
    });

    // error handled in jsx
    if (resp.status !== 'success') return;

    // refetch webphone
    await webphoneQuery.refetch();
    await queryClient.refetchQueries(deviceQueryKeys.byId(device.id));
  };

  if (webphoneQuery.isLoading) {
    // console.log('Webphone: Loading');
    return <LoadingWebphone />;
  }

  if (!device) {
    // console.log('Webphone: Creating');
    return <CreateWebphone refetch={webphoneQuery.refetch} auto />;
  }

  // console.log('Webphone: Show');
  // prevent web phone init if device is not enabled
  if (device?.doc?.enabled === false) {
    return (
      <Container
        style={{
          height: '100vh',
          background: '#F8FDFD',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Box sx={{ display: 'grid', placeItems: 'center' }}>
          <Alert severity="error">
            <Typography variant="body1">Webphone Device Disabled:</Typography>
            <Typography variant="h6">
              {device?.doc?.name ?? 'Unknown Webphone Device'}
            </Typography>
          </Alert>
          {authenticatedUser?.doc.priv_level === UserPrivLevel.Admin ? (
            <Button
              onClick={enableWebphone}
              sx={{ mt: 2 }}
              variant={'contained'}
              color={'success'}
              loading={updateDevice.isLoading}
            >
              Enable
            </Button>
          ) : null}
          {updateDevice.isError ? (
            <Typography color={'error'} sx={{ mt: 1 }} variant={'h6'}>
              Error enabling device
            </Typography>
          ) : null}
        </Box>
      </Container>
    );
  }

  return <WebphoneUI device={device} />;
};

const LoadingWebphone = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%', padding: 3 }}
    >
      <Grid item>
        <Box sx={{ maxWidth: '280px', textAlign: 'center' }}>
          <Typography variant="body1" component="div" gutterBottom>
            Initializing, one moment
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const useWebphoneCall = () => {
  const { callingDisabled } = useWebphoneSelector();
  const [state, dispatchWebphone] = useWebphoneContext();
  const { makeCall, eventBus } = state;

  const dispatch = useDispatch();
  const { actions: localActions } = useLocalSlice();

  const openDialer = (number, from = undefined) => {
    // openTour(null); // close any open tour
    dispatch(localActions.set_webphone_visible(true));
    dispatchWebphone({
      type: 'sip:update',
      payload: {
        mainTab: 'dialer',
        dialerText: number,
        dialerFrom: from,
      },
    });
  };

  const handleCall = number => {
    // // if (callingDisabled) {
    // //   window.alert('calling is disabled: missing input or output device');
    // //   return;
    // // }
    // makeCall(
    //   number,
    //   false, // is video
    //   undefined, // local tag (used default)
    //   undefined, // remote tag (uses default)
    //   {},
    // );
    // eventBus.emit('started-call');
  };

  return {
    openDialer,
  };
};

export default Webphone;

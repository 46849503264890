import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
} from '@mui/icons-material';
import { DisabledTooltip } from 'app/components/DisabledToolTip';
import { ChipSwitch, StatusTooltip } from 'app/design';
import { Switch } from 'app/design-lib';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DeviceDoc } from 'types/device';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { useMediaQuery } from '@mui/material';
interface DeviceSettingProps {
  deviceDoc: DeviceDoc;
}

interface DisableValues {
  disable: boolean;
}

const DeviceDisableSettings = ({ deviceDoc }: DeviceSettingProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const updateDevice = useUpdateDevicePartial();
  const {
    formState: { isDirty },
    watch,
    control,
    handleSubmit,
    reset,
  } = useForm<DisableValues>({
    defaultValues: { disable: !deviceDoc.enabled ?? false },
  });
  const watchEnabled = watch('disable');
  const statusRef = useRef(updateDevice.status);
  statusRef.current = updateDevice.status;

  // console.log('disable', deviceDoc);
  // console.log('changed', deviceDoc);

  // reset form if device doc changes
  useEffect(() => {
    reset({ disable: !deviceDoc.enabled ?? false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDoc.enabled]);

  // display switch tooltip based on status
  useEffect(() => {
    if (updateDevice.status === 'loading') setShowTooltip(true);
    else if (showTooltip)
      setTimeout(
        // @ts-ignore
        () => statusRef.current !== 'loading' && setShowTooltip(false),
        1000,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDevice.status]);

  // trigger submit on switch trigger
  useEffect(() => {
    if (isDirty) {
      // only submit if form is dirty (we reset form on success, fail)
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEnabled]);

  const onSubmit = async (disableValues: DisableValues) => {
    try {
      const resp = await updateDevice.mutateAsync(
        {
          id: deviceDoc.id,
          enabled: !disableValues.disable,
        },
        // {
        //   onSuccess: resp => {
        //     const { data } = resp;
        //
        //     // reset form data (needed since this form is initialized once, not
        //     // - every time like when a dialog opens). We need to reset the default
        //     // - values which are the source of truth for error reset/isDirty value
        //     reset({ disable: !data.enabled });
        //   },
        //   onError: () => {
        //     reset(); // reset form to last valid state
        //     toast.error('Failed to update device settings.');
        //   },
        // },
      );

      if (resp.status === 'success') {
        const { data } = resp;

        // reset form data (needed since this form is initialized once, not
        // - every time like when a dialog opens). We need to reset the default
        // - values which are the source of truth for error reset/isDirty value
        reset({ disable: !data.enabled });
      }
    } catch (e) {
      reset(); // reset form to last valid state
      toast.error('Failed to update device settings.');
    }
  };

  const isSmallMobile = useMediaQuery('(max-width:400px)', { noSsr: true });

  return (
    <Controller
      control={control}
      name={'disable'}
      render={({ field: { onChange, value, ...props } }) => (
        <StatusTooltip
          status={updateDevice.status}
          placement={'right'}
          open={showTooltip}
        >
          <div>
            <DisabledTooltip>
              <Switch
                checked={!value}
                onClick={e => onChange(!value)}
                // checkedColor={'positive'}
                // uncheckedColor={'negative'}
                title={
                  value
                    ? 'This device will NOT be able to make or receive calls'
                    : 'This device WILL be able to make or receive calls'
                }
                label={value ? 'Disabled' : 'Enabled'}
                condensedLabel={isSmallMobile}
                size={isSmallMobile ? 'sm' : 'md'}
                // icon={value ? <PhoneDisabledIcon /> : <PhoneEnabledIcon />}
              />
              {/* <div
                className="flex items-center cursor-pointer"
                onClick={e => onChange(!value)}
              >
                <button
                  className={`relative w-12 cursor-pointer h-6 rounded-full p-0 border-0 flex items-center transition duration-300 focus:outline-none ring-1 ring-inset ${
                    value
                      ? ' bg-background-negative-secondary-hover ring-neutral-400'
                      : ' bg-background-positive-secondary-hover ring-neutral-400'
                  }`}
                  type="button"
                  title={
                    value
                      ? 'This device will NOT be able to make or receive calls'
                      : 'This device WILL be able to make or receive calls'
                  }
                >
                  <div
                    className={`w-6 h-6 relative
                 rounded-full transition-all duration-500 p-1 text-white grid place-items-center ${
                   !value
                     ? ' bg-content-positive  ignore_translate-x-4 ml-[calc(100%-24px)]'
                     : ' text-neutral-500   bg-content-negative ml-0 ignore_-translate-x-2'
                 }`}
                  >
                    {value ? (
                      <PhoneDisabledIcon sx={{ fontSize: 16 }} />
                    ) : (
                      <PhoneEnabledIcon sx={{ fontSize: 16 }} />
                    )}
                  </div>
                </button>
                <span
                  className={`ml-2  ${value ? 'font-bold' : 'font-medium'}`}
                >
                  <span
                    className={
                      value ? 'text-content-negative' : 'text-content-positive'
                    }
                  >
                    {value ? 'Disabled' : 'Enabled'}
                  </span>
                </span>
              </div>*/}
              {/*<ChipSwitch*/}
              {/*  size="small"*/}
              {/*  label="Disable"*/}
              {/*  offColor="default"*/}
              {/*  onColor="error"*/}
              {/*  checked={value}*/}
              {/*  onChange={e => onChange(e.target.checked)}*/}
              {/*/>*/}
            </DisabledTooltip>
          </div>
        </StatusTooltip>
      )}
    />
  );
};

export default DeviceDisableSettings;

import { gql } from 'graphql-request';

export default gql`
  mutation contactUpdate($id: ID, $info: JSON) {
    contactUpdate(id: $id, info: $info) {
      success
      message
      data
    }
  }
`;

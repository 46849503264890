import { ExtensionInUseWarnings } from 'app/components/ExtensionInUseWarnings';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import {
  AnimationDialog,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { getTimeZones } from '@vvo/tzdb';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const TIMEZONE_LIST = getTimeZones().map(tz => ({
  label: `${tz.name.replace('_', ' ')} (${tz.abbreviation})`,
  value: tz.name,
}));

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface VmboxTimezoneForm {
  timezone?: { label: string; value: string };
}

// interface declaring which props are required/allowed
interface VmboxTimezoneDialogContentProps {
  vmboxId: string;
  onCancel: () => void;
  onComplete: () => void;
}

interface VmboxTimezoneDialogProps {
  ContentProps: VmboxTimezoneDialogContentProps;
  DialogProps: AnimationDialogProps;
}

const TimezoneDialogVmbox = ({
  ContentProps,
  DialogProps,
}: VmboxTimezoneDialogProps) => {
  return (
    <AnimationDialog maxWidth={'xs'} {...DialogProps}>
      <TimezoneDialogVmboxContent {...ContentProps} />
    </AnimationDialog>
  );
};

const TimezoneDialogVmboxContent = ({
  vmboxId,
  onCancel,
  onComplete,
}: VmboxTimezoneDialogContentProps) => {
  const {
    data: vmbox,
    isLoading: vmboxIsLoading,
    refetch: refetchVmbox,
  } = useVmboxQuery({ id: vmboxId });
  const updateVmbox = useUpdateVmboxPartial();

  const formMethods = useForm<VmboxTimezoneForm>();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = formMethods;

  const onSubmit = async (vmboxForm: VmboxTimezoneForm) => {
    const { timezone } = vmboxForm;

    const resp = await updateVmbox.mutateAsync(
      {
        id: vmboxId,
        timezone: timezone?.value ?? null,
      },
      // {
      //   onSuccess: () => {
      //     refetchVmbox();
      //     onComplete();
      //   },
      // },
    );

    // const resp = await toast.promise(updateVmboxPromise, {
    //   pending: 'Updating Voicemail Box timezone...',
    //   error: 'Failed to update Voicemail Box timezone.',
    //   success: 'Voicemail Box timezone updated!',
    // });

    if (resp.status === 'success') {
      refetchVmbox();
      // onComplete();
    }
  };

  return (
    <GenericMutationDialogContent
      onComplete={onComplete}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'Voicemail box timezone updated'}
      isLoadingLabel={'Updating voicemail box timezone'}
      title={'Edit Voicemail Box Timezone'}
      mutation={updateVmbox}
      formMethods={formMethods}
      submitButtonLabel={'Update Timezone'}
    >
      <Controller
        name="timezone"
        control={control}
        defaultValue={TIMEZONE_LIST.find(
          tz => tz.value === vmbox?.doc.timezone,
        )}
        // rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            options={TIMEZONE_LIST}
            getOptionLabel={option => option.label}
            value={value}
            onChange={(event, value) => {
              onChange(value);
            }}
            renderInput={params => {
              // @ts-ignore
              params.InputLabelProps.shrink = true;
              return (
                <TextField
                  {...params}
                  label={'Timezone'}
                  placeholder={'select'}
                />
              );
            }}
          />
        )}
      />
    </GenericMutationDialogContent>
  );

  // return (
  //   <Dialog open={true} fullWidth maxWidth={'sm'}>
  //     {vmboxIsLoading ? (
  //       <DialogInProgress title={'Loading Voicemail Box...'} />
  //     ) : updateVmbox.isLoading ? (
  //       <DialogInProgress title={'Updating Voicemail Box timezone...'} />
  //     ) : (
  //       <>
  //         <DialogTitle>Edit Voicemail Box Timezone</DialogTitle>
  //         <Divider />
  //         <>
  //           <DialogContent>
  //             {vmbox ? (
  //               <Controller
  //                 name="timezone"
  //                 control={control}
  //                 defaultValue={TIMEZONE_LIST.find(
  //                   tz => tz.value === vmbox.doc.timezone,
  //                 )}
  //                 // rules={{ required: true }}
  //                 render={({ field: { value, onChange } }) => (
  //                   <Autocomplete
  //                     options={TIMEZONE_LIST}
  //                     getOptionLabel={option => option.label}
  //                     value={value}
  //                     onChange={(event, value) => {
  //                       onChange(value);
  //                     }}
  //                     renderInput={params => {
  //                       // @ts-ignore
  //                       params.InputLabelProps.shrink = true;
  //                       return (
  //                         <TextField
  //                           {...params}
  //                           label={'Timezone'}
  //                           placeholder={'select'}
  //                         />
  //                       );
  //                     }}
  //                   />
  //                 )}
  //               />
  //             ) : (
  //               <Typography color={'error'}>
  //                 Invalid Voicemail Box ID
  //               </Typography>
  //             )}
  //           </DialogContent>
  //           <Divider />
  //           <DialogActions sx={{ justifyContent: 'space-between' }}>
  //             <Button
  //               variant={'outlined'}
  //               color={'error'}
  //               onClick={handleCancel}
  //             >
  //               Cancel
  //             </Button>
  //             {updateVmbox.error ? (
  //               <Typography color={'error'}>
  //                 {`${updateVmbox.error.message} ${
  //                   updateVmbox.error.response
  //                     ? `: ${updateVmbox.error.response?.statusText}`
  //                     : ''
  //                 }`}
  //               </Typography>
  //             ) : null}
  //             <Button
  //               variant={'outlined'}
  //               color={'success'}
  //               disabled={!isDirty}
  //               onClick={handleSubmit(onSubmit)}
  //             >
  //               Update
  //             </Button>
  //           </DialogActions>
  //         </>
  //       </>
  //     )}
  //   </Dialog>
  // );
};

export default TimezoneDialogVmbox;

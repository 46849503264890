import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { Dialog } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateAccountPartial } from 'app/hooks/mutations/account';
import { useAccountQuery } from 'app/hooks/queries/account';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import Joi from 'joi';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface AccountDetailsFields {
  name: string;
}

// interface declaring which props are required/allowed
interface ContentProps {
  onCancel: () => void;
  onComplete: () => void;
}

interface AccountDetailsDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}
const DetailsDialogAccount = ({
  ContentProps,
  DialogProps,
}: AccountDetailsDialogProps) => {
  return (
    <Dialog open={DialogProps.open} onClose={ContentProps.onCancel}>
      <DetailsDialogAccountContent {...ContentProps} />
    </Dialog>
  );
};

const DetailsDialogAccountContent = ({
  onCancel,
  onComplete,
}: ContentProps) => {
  const { data: account, isLoading: accountIsLoading } = useAccountQuery({});
  const formMethods = useForm<AccountDetailsFields>({
    defaultValues: {
      name: account?.doc.name,
    },
    resolver: joiResolver(Joi.object({ name: Joi.string().max(128) })),
  });
  const updateAccount = useUpdateAccountPartial();
  const { setError } = formMethods;
  const onSubmit = async (accountForm: AccountDetailsFields) => {
    try {
      await updateAccount.mutateAsync({
        id: account?.id!,
        ...accountForm,
      });
    } catch (e: any) {
      parseAndSetKazooMutationErrors({
        response: e.response,
        setError,
      });
    }
  };

  return (
    <GenericMutationDialogContent
      onCancel={onCancel}
      onSubmit={formMethods.handleSubmit(onSubmit)}
      onComplete={onComplete}
      onSuccessLabel={'Account name has been updated'}
      isLoadingLabel={'Updating account name...'}
      title={'Edit Account Name'}
      mutation={updateAccount}
      formMethods={formMethods}
      queryIsLoading={accountIsLoading}
      queryLabel={'Loading account details'}
    >
      <HookFormTextField
        autoComplete={'off'}
        autoFocus
        name={'name'}
        label={'name'}
      />
    </GenericMutationDialogContent>
  );
};

export default DetailsDialogAccount;

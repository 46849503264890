import { IconButton } from 'app/design-lib/components/IconButton';
import {
  Cancel as CloseIcon,
  WarningHexagon as WarningIcon,
} from 'iconoir-react';
import React, { ReactNode } from 'react';

interface DialogHeaderProps {
  errorMessage: string;
  onClose: () => void;
}

export const DialogAlert = ({ errorMessage, onClose }: DialogHeaderProps) => {
  return (
    <div
      className={
        'bg-negative-60 mt-1 pl-4 space-x-2 text-white flex items-center'
      }
    >
      <WarningIcon fr={undefined} height={16} strokeWidth={2} width={16} />
      <span className={'text-md font-medium'}>{errorMessage}</span>
      <span className={'text-sm flex-1 text-neutral-20'}>Try again</span>
      <IconButton
        clear
        size={'md'}
        onClick={onClose}
        variant={'ghost'}
        color={'neutral-reverse'}
      >
        <CloseIcon fr={undefined} />
      </IconButton>
    </div>
  );
};

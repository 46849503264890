import { SmartphoneDevice } from 'iconoir-react';
import React from 'react';
import { Tooltip, ButtonGroup, IconButton } from 'app/design';
import { Button } from 'app/design-lib';

import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useGlobalTour } from 'app/components/Tourguide/Helper';

import { useMobileAppDialog } from './MobileAppDialog';
import { useMediaQuery } from '@mui/material';

const MobileAppButton = ({ className = '' }) => {
  const dispatch = useDispatch();
  const { actions: localActions } = useLocalSlice();
  const [{ openTour, tourEvents }] = useGlobalTour();

  const {
    toggleOpen: toggleOpenMobileAppDialog,
    MobileAppDialog,
    MobileAppDialogProps,
  } = useMobileAppDialog({});

  const handleOpen = () => {
    toggleOpenMobileAppDialog();
    openTour(null);
    // tourEvents.emit('next');
  };

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  return (
    <>
      <MobileAppDialog {...MobileAppDialogProps} />
      <Button
        className={className}
        onClick={handleOpen}
        data-tour-is-mobileapp
        variant={isMobile ? 'outline' : 'fill'}
        color="accent"
        size="md"
        startIcon={<SmartphoneDevice fr={undefined} />}
      >
        {isMobile ? 'App' : 'Mobile App'}
      </Button>
    </>
  );
};

export default MobileAppButton;

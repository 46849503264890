import { Button } from 'app/design-lib/components/Button';
import { ButtonProps } from 'app/design-lib/components/Button/Button';
import { CancelSubDialog } from 'app/pages/admin/Billing/Home/components/CancelSubDialog';
import React, { ReactNode } from 'react';
import SimpleBar from 'simplebar-react';
import { SquareProgress } from 'app/design-lib/components/SquareProgress';

interface SettingsPageWrapperProps {
  children: ReactNode;
  label: ReactNode;
  subLabel?: ReactNode;
  isLoading?: boolean;
  loadingLabel?: string;
  buttonProps?: ButtonProps;
  buttonNode?: ReactNode;
}

export const SettingsPageWrapper = ({
  children,
  loadingLabel,
  isLoading,
  label,
  subLabel,
  buttonProps,
  buttonNode,
}: SettingsPageWrapperProps) => {
  if (isLoading)
    return (
      <div
        className={`
          sm:h-screen h-full 
          flex-1 w-full grid place-items-center`}
      >
        <div className={'flex flex-col items-center'}>
          <SquareProgress />
          <span className={'font-medium  text-neutral-60 text-md'}>
            {loadingLabel}
          </span>
        </div>
      </div>
    );

  return (
    <div
      className={`
      h-full sm:h-screen
      flex-1 w-full overflow-hidden
      pb-20 sm:pb-3`}
    >
      {/* <div className={'p-4 pt-3 h-full w-full'}> */}
      <SimpleBar
        className="p-2 pr-3 sm:p-4 pt-3 flex flex-col"
        style={{ height: '100%' }}
      >
        <div className={'w-full flex flex-col space-y-6'}>
          <div className={'flex justify-between items-start'}>
            <div className={'items-baseline flex-wrap   flex'}>
              <span className={'text-lg pt-1 font-medium mr-2'}>{label}</span>
              <span className={'text-sm pt-1.5 text-neutral-60 font-medium'}>
                {subLabel}
              </span>
            </div>
            {buttonProps ? (
              <Button
                {...(buttonProps as any)}
                variant={'fill'}
                color={'accent'}
                size={'md'}
              />
            ) : null}
            {buttonNode ? buttonNode : null}
            {/*<Button
          size={'md'}
          color={'accent'}
          startIcon={<AddIcon fr={undefined} />}
          onClick={toggleOpenCreditCardAddDialog}
        >
          Add payment method
        </Button>
        <CreditCardAddDialog
          {...CreditCardAddDialogProps}
          formDefaults={{ setDefault: !stripeData?.data?.cards?.length }}
          onComplete={handleCardAdded}
        />*/}
          </div>
          <div className={'flex flex-1 flex-col space-y-8'}>{children}</div>
        </div>
      </SimpleBar>
      {/* </div> */}
    </div>
  );
};

export default SettingsPageWrapper;

import React, { memo, useState, useRef } from 'react';
import { Handle } from 'react-flow-renderer';

import { Typography } from 'app/design';

import { HelpOutline as HelpOutlineIcon } from 'app/design/icons-material';

// import useStyles from './styles';

import { useHoverDirty } from 'react-use';
import { Box } from '../common/Box';

const MissingNode = memo(({ data }) => {
  // const classes = useStyles();

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);
  // const actAsHovering = isHovering || anchorEl || anchorFakeEl; // try to detect if a menu was selected

  return (
    <Box pref={boxRef} borderColor="red">
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <div
        style={{
          padding: 8,
          textAlign: 'center',
        }}
      >
        <Typography variant="caption">
          {' '}
          <HelpOutlineIcon style={{ verticalAlign: 'middle' }} />{' '}
          {data.title || 'Unknown Action'}
        </Typography>
        <Typography variant="body1" bold>
          {data.label}
        </Typography>
      </div>
      {/* <Handle
        type="source"
        position="bottom"
        // id="b"
        style={{ background: '#555' }}
      /> */}
    </Box>
  );
});

export default MissingNode;

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { DevicesPageResponse } from 'types/device';
import deviceQueryKeys from './deviceQueryKeys';

interface QueryParams {
  skip?: number;
  take?: number;
  orderBy?: any;
  filters?: any;
  mask?: any;
  options?: Omit<
    UseQueryOptions<DevicesPageResponse, Error, DevicesPageResponse, QueryKey>,
    'queryKey' | 'queryFn'
  >;
  quick?: boolean;
}

export const useDevicesListQuery = ({
  skip = 0,
  take = 10000,
  orderBy = [['number', 'asc']],
  filters = {},
  mask = '*',
  quick = false,
  options,
}: QueryParams) => {
  // returns list of devices
  // - just get ALL devices+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = quick
    ? deviceQueryKeys.listQuick(skip, take, orderBy, filters, mask)
    : deviceQueryKeys.list(skip, take, orderBy, filters, mask);

  const func = quick ? 'devicesQuick' : 'devices';
  const query = useQuery<DevicesPageResponse, Error>(
    queryKey,
    () =>
      sdk.device.query[func](
        {
          skip,
          take,
          orderBy,
          filters,
          mask,
        },
        { authToken: authState.auth_token },
      ),
    {
      // enabled: authState?.auth_token_decoded?.owner_id ? true : false,

      // prevents pagination from looking like a hard load;
      //  - keeps previous data displayed until new data arrives
      ...options,
      keepPreviousData: options?.keepPreviousData ?? true,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useDevicesListQuery;

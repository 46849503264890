import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { ContactCreate } from 'types/contact';
import contactQueryKeys from 'app/hooks/queries/contact/contactQueryKeys';

export const useCreateContact = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, ContactCreate, unknown>(
    (contactData: ContactCreate) =>
      sdk.contact.mutate.create(
        {
          data: contactData,
          // other values such as "accept_charges" could go here
        },
        {
          authToken: authState?.auth_token,
        },
      ),
    {
      onSuccess: resp => {
        // this always runs, even if the caller provides an onSuccess
        queryClient.invalidateQueries(contactQueryKeys.all);
        // console.log('val:', val);
      },
    },
  );
};

import { useAuthSelector } from 'app/data/auth';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { sdk } from 'app/sdk';
import { Device } from 'types/device';
import { User } from 'types/user';
import deviceQueryKeys from './deviceQueryKeys';

export const useDeviceQuery = (
  id?: string | null,
  options?: Omit<
    UseQueryOptions<Device, Error, Device, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  // returns list of devices
  // - just get ALL devices+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = deviceQueryKeys.byId(id);

  const query = useQuery<Device, Error>(
    queryKey,
    () =>
      sdk.device.query.deviceById({ id }, { authToken: authState.auth_token }),
    {
      enabled: !!id,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useDeviceQuery;

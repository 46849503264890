import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';
import { setAtPath } from '../../../../../utilities';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';
import { Box } from '../common/Box';
// import { ModifyDialog } from './ModifyDialog';
import { MenuButton } from '../common/MenuButton';
import { Plus as PlusIcon, SigmaFunction } from 'iconoir-react';

// why use memo??
const GenericMatchNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  // Load media via query
  // const {
  //   data: media,
  //   isLoading,
  //   isFetched,
  // } = useMediaByIdQuery(moduleItem?.data?.id);
  // const mediaUrl = useMediaUrl({ id:moduleItem?.data?.id });

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);
  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    // if (data.optsPath === modifyPath) {
    //   setShowModifyDialog(true);
    // }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMatch = () => {
    let newText = prompt('Match', JSON.stringify(matches));
    if (!newText) {
      return false;
    }
    try {
      newText = JSON.parse(newText);
    } catch (err) {
      alert('Sorry, unable to parse that');
      return false;
    }
    setAtPath(callflow, `${modifyPath}.data.match`, newText);
    setCallflow({ ...callflow });
    // setShowModifyDialogWrap(null);
  };

  const handleAdd = value => {
    console.log('Matches:', matches, value);
    setAtPath(callflow, `${modifyPath}.data.match`, [...matches, value]);
    setCallflow({ ...callflow });
    // setShowModifyDialogWrap(null);
  };

  let matches = moduleItem?.data?.match ?? [];

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      {/* <AudioDialog
        onComplete={mediaId => {
          setAtPath(callflow, `${modifyPath}.data.id`, mediaId);
          setCallflow({ ...callflow });
        }}
        onClose={() => setShowModifyDialog(false)}
        open={!!showModifyDialog}
        mediaId={mediaId}
      /> */}
      <div
        className={`flex flex-col items-center  whitespace-nowrap w-full pt-4 px-2 pb-2`}
      >
        <div className={`flex space-x-2 text-neutral-60`}>
          <div
            className={`text-sm flex items-center space-x-2 whitespace-nowrap`}
          >
            <SigmaFunction width={14} height={14} />
            <span>Match Conditions:</span>
          </div>
        </div>
        <div
          className={`text-md w-full flex flex-col text-center justify-center font-medium`}
        >
          {matches.length ? (
            <div className="my-2 space-y-4">
              {matches.map((match, idx) => {
                return (
                  <MatchCondition
                    key={idx}
                    idx={idx}
                    conditionPath={idx}
                    match={match}
                    data={data}
                  />
                );
              })}
            </div>
          ) : null}
          {skipEditing ? null : (
            <IconButtonDropdown
              variant={'ghost'}
              subArrowRight
              // onClick={() => handleAdd([ringOptions[0], terminationOptions[1]])}
              pill
              align={'left'}
              color={'neutral'}
              className={`opacity-50 hover:opacity-100 my-2 mx-auto`}
              size={'sm'}
              // disabled={skipEditing}
              menuItems={[
                {
                  label: 'Communicated within',
                  onClick: () =>
                    handleAdd({
                      type: 'time_since_communication',
                      data: {
                        timeframe: 'never',
                      },
                    }),
                },
                // {
                //   label: 'Time since last sent',
                //   onClick: () =>
                //     handleAdd({
                //       type: 'time_since_message_sent',
                //     }),
                // },
                // {
                //   label: 'Time since last call',
                //   onClick: () =>
                //     handleAdd({
                //       type: 'time_since_recent_call',
                //     }),
                // },
              ]}
            >
              <PlusIcon />
            </IconButtonDropdown>
          )}
          {/* {mediaId ? (
            <AudioPlayer
              mini
              variant={'ghost'}
              mediaId={mediaId}
              onEdit={skipEditing ? undefined : () => setShowModifyDialog(true)}
            />
          ) : (
            <Button
              color={'attention'}
              variant={'ghost'}
              size={'sm'}
              className={`self-center`}
              startIcon={<WarningTriangle />}
              onClick={() => setShowModifyDialog(true)}
              disabled={skipEditing}
            >
              {skipEditing ? 'No' : 'Add'} audio
            </Button>
          )} */}
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );
});

const MatchCondition = ({ data, idx, conditionPath, match }) => {
  switch (match?.type || match) {
    case 'time_since_communication':
      return (
        <Condition_time_since_communication
          {...{ data, idx, conditionPath, match }}
        />
      );
  }
  return <div className="">Unknown match type</div>;
};

const Condition_time_since_communication = ({
  data,
  idx,
  conditionPath,
  match,
}) => {
  const { skipEditing, moduleItem, callflow, setCallflow, modifyPath } = data;
  // get the most recent communication time
  // - "how long since we last communicated over phone/message" (checks PipeMessage types "call" and "message" for most recent)

  // TODO: use date-fns for start/end of dates (today, yesterday)
  const items = [
    {
      text: 'Never',
      value: 'never',
    },
    {
      text: '5 min',
      value: [
        [
          'sub',
          {
            minutes: 5,
          },
        ],
      ],
    },
    {
      text: '30 min',
      value: [
        [
          'sub',
          {
            minutes: 30,
          },
        ],
      ],
    },
    {
      text: '1 hour',
      value: [
        [
          'sub',
          {
            hour: 1,
          },
        ],
      ],
    },
    {
      text: 'Today',
      value: [['startOfDay']],
    },
    {
      text: 'Yesterday',
      value: [['startOfYesterday']],
    },
    {
      text: '90 Days',
      value: [
        [
          'sub',
          {
            days: 90,
          },
        ],
      ],
    },
  ];

  const timeframe = JSON.stringify(match?.data?.timeframe);

  const handleUpdateTimeframe = value => {
    setAtPath(
      callflow,
      `${modifyPath}.data.match.${conditionPath}.data.timeframe`,
      value,
    );
    setCallflow({ ...callflow });
  };

  return (
    <div className="space-y-1">
      <div className="">Last Call/Msg Before: </div>
      <ButtonDropdownMenu
        // @ts-ignore
        label={
          items.find(item => JSON.stringify(item.value) === timeframe)?.text ??
          '--'
        }
        variant="button"
        buttonVariant="outlined"
        menuItems={items.map(item => {
          return {
            ...item,
            onClick: () => handleUpdateTimeframe(item.value),
          };
        })}
        // {...buttonDropdownProps}
        // menuItemsDependencies={[dispatchRedux, actions]}
      />
    </div>
  );
};

export default GenericMatchNode;

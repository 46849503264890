// Not complete! What fields are missing?
import { CallerId, CallForward, DoNotDisturb } from 'types/generic';

export enum UserPrivLevel {
  Admin = 'admin',
  User = 'user',
}

export interface UserDoc {
  /* required fields */
  id: string;
  title?: string;
  first_name: string;
  last_name: string;
  priv_level: UserPrivLevel;
  presence_id: string;
  username: string;
  enabled: boolean;

  /* optional fields */
  call_forward?: CallForward;
  do_not_disturb?: DoNotDisturb;
  caller_id?: CallerId;
  vm_to_email_enabled?: boolean;
  send_email_on_creation?: boolean;
  email?: string;
  password?: string;
  cio_allow_appphone?: boolean;
  call_restriction?: any;
  timezone?: string | null;
  music_on_hold: {
    media_id?: string | null;
  };
}

import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import menuQueryKeys from 'app/hooks/queries/menu/menuQueryKeys';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { MenuCreateDoc } from 'types/menu';

export const useCreateMenu = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, MenuCreateDoc, unknown>(
    (createMenuDoc: MenuCreateDoc) =>
      sdk.menu.mutate.create(createMenuDoc, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(menuQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Account } from 'types/account';
import { User } from 'types/user';
import accountQueryKeys from './accountQueryKey';

export const useAccountQuery = (
  options?: Omit<
    UseQueryOptions<Account, Error, Account, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  const authState = useAuthSelector();
  // const { account_id } = authState.auth_token_decoded.account_id;
  const queryKey = accountQueryKeys.account;

  const query = useQuery<Account, Error>(
    queryKey,
    () => sdk.account.query.account({}, { authToken: authState?.auth_token }), // fetchAccount(authState.auth_token),
    {
      //
      enabled: authState?.auth_token_decoded?.owner_id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useAccountQuery;

import { TextField } from 'app/design-lib';
import {
  AddPhonesFormFields,
  PhoneFields,
} from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/types';
import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { DeviceTypes } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/AddPhonesBulkForm';

interface OwnerIdFieldProps {
  index: number;
  formErrors: any;
  disabled: boolean;
  conflict: boolean;
  control: Control<AddPhonesFormFields, object>;
  type: DeviceTypes;
}

const MacAddressField = ({
  index,
  control,
  disabled,
  conflict,
  formErrors,
  type,
}: OwnerIdFieldProps) => {
  const fieldName = `phones.${index}`;

  return (
    <Controller
      // @ts-ignore
      name={`${fieldName}.mac_address`}
      control={control}
      render={({ field: { value, onChange, onBlur, ...props } }) => (
        <InputMask
          mask="**:**:**:**:**:**"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          color={conflict ? 'warning' : undefined}
          error={!!formErrors.phones?.[index]?.mac_address}
          helperText={
            formErrors.phones?.[index]?.mac_address?.message ??
            (conflict ? 'MAC address is used in another field' : undefined)
          }
        >
          <TextField
            // @ts-ignore
            {...props}
            // @ts-ignore
            label="MAC address"
            // size="small"
            // fullWidth
          />
        </InputMask>
      )}
    />
  );
};

export default MacAddressField;

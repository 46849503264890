import { gql } from 'graphql-request';

export default gql`
  query TemporalRules(
    # $skip: Int
    # $take: Int
    # $orderBy: JSON
    $filters: JSON # $mask: String
  ) {
    temporalRules(
      # skip: $skip
      # take: $take
      # orderBy: $orderBy
      filters: $filters # mask: $mask
    ) {
      id
      account_id
      doc
      createdAt
      updatedAt
      # totalCount
      # skip
      # take
      # temporalRules {
      #   id
      #   account_id
      #   doc
      #   createdAt
      #   updatedAt
      # }
    }
  }
`;

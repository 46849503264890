import { datadogLogs } from '@datadog/browser-logs';

import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import constants from 'app/constants';

const isDatadog =
  constants.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
  process.env.NODE_ENV === 'production'
    ? true
    : false;

// console.log('Mixpanel init', constants.env.REACT_APP_DATADOG_CLIENT_TOKEN);
// mixpanel.init(constants.env.REACT_APP_DATADOG_CLIENT_TOKEN, { debug: false });

if (isDatadog) {
  // console.log('Init datadog');
  datadogLogs.init({
    clientToken: constants.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: constants.env.REACT_APP_DATADOG_SITE,
    service: 'callingio-browser-app',
    env: process.env.NODE_ENV,
    // forwardConsoleLogs: ['error'],
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
} else {
  console.log('No datadog');
}

// export const MixpanelComponent = () => {
//   const { loggedIn, auth_token_decoded } = useAuthSelector();

//   const {
//     data: userData,
//     isLoading: userIsLoading,
//     error: userError,
//   } = useAuthenticatedUserQuery();

//   useEffect(() => {
//     if (isDatadog && auth_token_decoded?.owner_id && userData) {
//       mixpanel.identify(
//         `${auth_token_decoded?.account_id}-${auth_token_decoded?.owner_id}`,
//       );
//       mixpanel.people.set({
//         $email: userData.doc.email || userData.doc.username,
//         $first_name: userData.doc.first_name,
//         $last_name: userData.doc.last_name,
//         $name: userData.extra?.fullName,
//         account_id: auth_token_decoded?.account_id,
//         owner_id: auth_token_decoded?.owner_id,
//       });
//     }
//   }, [auth_token_decoded?.owner_id, userData]);

//   return null;
// };

export default datadogLogs;

import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import constants from 'app/constants';

const isMixpanel = constants.env.REACT_APP_MIXPANEL_TOKEN ? true : false;

// console.log('Mixpanel init', constants.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.init(constants.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });
if (isMixpanel) {
  // mixpanel.track('heartbeat', {
  //   //
  // });
}

export const MixpanelComponent = () => {
  const { loggedIn, auth_token_decoded } = useAuthSelector();

  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();

  useEffect(() => {
    if (isMixpanel && auth_token_decoded?.owner_id && userData) {
      mixpanel.identify(
        `${auth_token_decoded?.account_id}-${auth_token_decoded?.owner_id}`,
      );
      mixpanel.people.set({
        $email: userData.doc.email || userData.doc.username,
        $first_name: userData.doc.first_name,
        $last_name: userData.doc.last_name,
        $name: userData.extra?.fullName,
        account_id: auth_token_decoded?.account_id,
        owner_id: auth_token_decoded?.owner_id,
      });
    }
  }, [auth_token_decoded?.owner_id, userData]);

  return null;
};

export default mixpanel;

import { Drawer } from 'app/design';
import { startCase } from 'lodash';
import React from 'react';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useDispatch } from 'react-redux';
import { Webphone, useWebphoneContext } from 'app/components/Webphone';
import { Portal } from 'react-portal';
import Draggable from 'react-draggable';
import { useLocalStorage } from 'react-use';

const WebphoneContainer = props => {
  const nodeRef = React.useRef(null);
  const dispatch = useDispatch();
  const { webphone_visible, webphone_background } = useLocalSelector();
  const { actions: localActions } = useLocalSlice();
  const [webphoneState] = useWebphoneContext();
  const [position, setWebphonePosition] = useLocalStorage('webphone-position', {
    x: 5,
    y: 5,
  });

  const { calls } = webphoneState;

  let allowClose = true;
  if (calls && calls !== undefined) {
    const hasCalls = calls.length > 0;
    if (hasCalls) {
      // open = true;
      // allowClose = false;
    }
  }
  const handleClose = () => {
    if (!allowClose) {
      return false;
    }
    dispatch(localActions.set_webphone_visible(false));
  };

  const handleStop = (e, data) => {
    // console.log('dat:', data);
    setWebphonePosition({ x: data.x, y: data.y });
  };

  return (
    <>
      <Portal node={document && document.body}>
        <Draggable
          // axis="x"
          nodeRef={nodeRef}
          // nodeRef={nodeRef}
          handle=".drag-handle"
          defaultPosition={position}
          // defaultPosition={{ x: 5, y: 5 }}
          // position={position}
          // grid={[25, 25]}
          bounds={{
            top: 5,
            left: 0,
            right: window.innerWidth - 20,
            bottom: window.innerHeight - 40,
          }}
          scale={1}
          onStop={handleStop}
        >
          <div
            ref={nodeRef}
            className={[
              `absolute top-0 left-0 w-[480px] ${
                webphone_background ? '' : 'z-20'
              }`,
              webphone_visible || calls?.length ? '' : 'hidden',
            ].join(' ')}
          >
            <Webphone />
          </div>
        </Draggable>
      </Portal>
    </>
  );
};

export default WebphoneContainer;

import { CreateMediaButton } from 'app/components/ListMedia/components/CreateMediaButton';
import PlayAudioTableCell from 'app/components/PlayTableCell/PlayAudioTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListMediasQuery } from 'app/hooks/queries/media';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Media } from 'types/media';

const QuickFinderMediaDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (media: Media) => media.doc.name,
      },
      {
        Header: 'Play',
        id: 'play',
        accessor: (media: Media) => media.doc.tts?.text,
        Cell: ({ row }) => {
          const { original: media }: { original: Media } = row;

          return <PlayAudioTableCell media={media} />;
        },
        disableSortBy: true,
      },
    ],
    [],
  );

  // media list
  const mediaListQuery = useListMediasQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['name', 'asc']],
    filters: { searchInput: querySearchInput },
  });
  const { medias, totalCount = 0 } = mediaListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      mediaListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  const handleCreate = (mediaId: string) => {
    // TODO: does not work with passResource
    props.onSelect([mediaId]);
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Media' : 'Select Media'}
      titleButton={
        props.allowCreate ? (
          <CreateMediaButton onComplete={handleCreate} />
        ) : null
      }
      // table
      getRowId={(row: Media) => row.id}
      searchPlaceholder={'Search Media...'}
      totalCount={totalCount}
      columns={columns}
      data={medias ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={mediaListQuery.isFetching}
      queryIsLoading={mediaListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderMediaDialog;

import React, { useState, useCallback } from 'react';

// import * as OptionComponents from '../../components';
// import * as MODULES from '../../flowTransformModules';

import { orderBy } from 'lodash';

import { v4 as uuidv4 } from 'uuid';
import {
  Clock,
  Community,
  Dialpad,
  Group,
  MusicNote,
  Phone,
  SmartphoneDevice,
  Strategy,
  User,
  Voice,
  Message,
  ArcheryMatch,
  TextAlt,
  ClockRotateRight,
} from 'iconoir-react';

export const USER_ADDABLE_COMPONENTS = new Map([
  [
    'misc',
    [
      {
        name: 'Match Text', // and Options
        type: 'MatchText',
        icon: <TextAlt />,
        details: 'Route based on incoming text contents',
        options: {
          props: {},
        },
        allowAfter: true,
        data: {
          targets: [],
        },
      },
      {
        name: 'Schedule', // and Options
        type: 'Schedule',
        icon: <Clock />,
        details: 'Route based on your business hours.',
        options: {
          props: {},
        },
        allowAfter: true,
      },
      // {
      //   name: 'Generic Match',
      //   icon: <ArcheryMatch />,
      //   details:
      //     'Route based on generic matching options (last message time, etc)',
      //   type: 'GenericMatch',
      //   options: {
      //     props: {
      //       // type: ['is_menu', 'time_of_day_menu'],
      //       // possibleStrategyTypes:[],
      //       // required: true,
      //     },
      //   },
      //   allowAfter: true,
      //   data: {
      //     targets: {
      //       // _next: {}, // this is not necessary, assumed as part of continuation
      //       match: {},
      //       no_match: {},
      //     },
      //   },
      // },
      {
        name: 'Communicated Within',
        icon: <ClockRotateRight />,
        details: 'Route based on last communication with customer.',
        type: 'LastCommunicated',
        options: {
          props: {
            // type: ['is_menu', 'time_of_day_menu'],
            // possibleStrategyTypes:[],
            // required: true,
          },
        },
        allowAfter: true,
        data: {
          targets: {
            // _next: {}, // this is not necessary, assumed as part of continuation
            match: {},
            no_match: {},
          },
          match: [
            {
              type: 'time_since_communication',
              data: {
                timeframe: 'never',
              },
            },
          ],
        },
      },
      {
        name: 'Send Message',
        icon: <Message />,
        details: 'Reply with a message',
        type: 'Message',
        options: {
          props: {},
        },
        data: {},
        allowAfter: true,
        allowFunc: ({ pipe, devMode, isInTemplate, endpointAlreadyInArr }) => {
          // if (!isInTemplate || (isInTemplate && endpointAlreadyInArr)) {
          //   // console.log({ isInTemplate, endpointAlreadyInArr });
          //   return false;
          // }
          // return true;
          // console.log('PIPE:', pipe);
          return true;
          // if (pipe?.type === 'external' && devMode) {
          //   return true;
          // }
          // return false;
        },
      },
    ],
  ],
]);

import { useLocalStorage } from 'react-use';

export const useDevMode = () => {
  const [devMode, setDevMode] = useLocalStorage(
    'dev-mode',
    process.env.NODE_ENV === 'development',
  );
  const toggleDevMode = () => {
    setDevMode(!devMode);
  };

  // @ts-ignore
  window.devMode = toggleDevMode;

  return { devMode, toggleDevMode };
};

export default useDevMode;

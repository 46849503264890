import React, { useState, useCallback, useEffect } from 'react';

const useHighlightPulse = ({ duration = 150 }) => {
  // start a timer
  const [doPulse, setDoPulse] = useState(false);
  const startAnimation = useCallback(() => {
    setDoPulse(true);
    setTimeout(() => {
      setDoPulse(false);
    }, duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    doPulse,
    setDoPulse,
    startAnimation,
    duration,
  };
};

export default useHighlightPulse;

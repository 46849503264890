import { Box, CircularProgress, DialogContent, Grid } from 'app/design';
import * as React from 'react';

interface DialogCircularProgressProps {
  label?: string;
}

const DialogCircularProgress = ({ label }: DialogCircularProgressProps) => {
  const _label = label
    ? label.endsWith('...')
      ? label
      : `${label}...`
    : 'Loading...';

  return (
    <DialogContent>
      <div className={'w-full space-y-4 flex-col flex items-center'}>
        <div className={'text-lg font-medium'}>{_label}</div>
        <CircularProgress variant={'indeterminate'} />
      </div>
    </DialogContent>
  );
};

export default DialogCircularProgress;

import { FeatureCodesDrawer } from 'app/components/FeatureCodesDrawer';
import { FeatureCodeBadge } from 'app/components/ListExtensions/FeatureCodeBadge';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Extension } from 'types/extension';

const QuickFinderExtensionDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Ext',
        id: 'ext',
        accessor: (extension: Extension) => extension.number,
        disableToggle: true,
        headerProps: {
          width: 200,
          position: 'sticky',
        },
        Cell: ({
          row,
          value,
        }: {
          row: { original: Extension };
          value: string;
        }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 2 }}>{value}</div>
              <FeatureCodeBadge ext={value} />
            </div>
          );
        },
      },
    ],
    [],
  );

  // extension list
  const extensionListQuery = useListExtensionsQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['number', 'asc']],
    filters: {
      searchInput: querySearchInput,
      context: {
        // exclude feature codes
        numbersExcludeQuoted: ['6683'],
      },
    },
  });
  const { extensions, totalCount = 0 } = extensionListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      extensionListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Extensions' : 'Select Extension'}
      // table
      getRowId={(row: Extension) => row.number}
      searchPlaceholder={'Search extensions...'}
      totalCount={totalCount}
      columns={columns}
      data={extensions ?? []}
      onSearch={handleSearch}
      // query
      queryIsFetching={extensionListQuery.isFetching}
      queryIsLoading={extensionListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderExtensionDialog;

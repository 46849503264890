import { Refresh as RefreshIcon } from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { DeviceType } from 'app/components/ListDevices/components/DeviceType';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { Button as ButtonMUI, Grid, Typography } from 'app/design';
import { FindInPage } from 'app/components/FindInPage';
import constants from 'app/constants';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { useNavDetails } from 'app/hooks/general/useNavDetails';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import useDeviceQuery from 'app/hooks/queries/device/useDeviceQuery';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { DeviceCallerIdCard } from 'app/pages/admin/Devices/View/components/DeviceCallerIdCard';
import { DeviceCallforwardSettings } from 'app/pages/admin/Devices/View/components/DeviceCallForwardSettings';
import { DeviceCallHandlingCard2 } from 'app/pages/admin/Devices/View/components/DeviceCallHandlingCard2';
import { DeviceDoNotDisturbSettings } from 'app/pages/admin/Devices/View/components/DeviceDoNotDisturbSettings';
import { DeviceMacSettings } from 'app/pages/admin/Devices/View/components/DeviceMacSettings';
import { DeviceSettingsCard } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard';
import { CallRouting } from 'app/pages/v2/settings/PipeView/components/CallRouting';
import { MessageRouting } from 'app/pages/v2/settings/PipeView/components/MessageRouting';
import { Messaging } from 'app/pages/v2/settings/PipeView/components/Messaging';
import { Users } from 'app/pages/v2/settings/PipeView/components/Users';
import { Voicemail } from 'app/pages/v2/settings/PipeView/components/Voicemail';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import { de } from 'date-fns/locale';
import { Emoji } from 'emoji-picker-react';
import { ArrowLeft, EditPencil } from 'iconoir-react';
import { startCase } from 'lodash';
import { Button, IconButton, Setting } from 'app/design-lib';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { DeviceDisableSettings } from './components/DeviceDisableSettings';
import { DeviceOwner } from 'app/pages/admin/Devices/View/components/DeviceOwner';
import { GroupsCard } from 'app/pages/admin/Users/View/components/UserGroupsCard';
import { parseGraphqlErrorCode } from 'app/utilities';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';

import { SettingsButton } from './components/SettingsButton';
import { account } from 'app/sdk/resources/account';
import { useAccountQuery } from 'app/hooks/queries/account';
import DeviceSipCredentials from 'app/pages/admin/Devices/View/components/DeviceSipCredentials/DeviceSipCredentials';
import DeviceSipSetupZoiper from 'app/pages/admin/Devices/View/components/DeviceSipSetupZoiper/DeviceSipSetupZoiper';
import DeviceSipSetupBria from 'app/pages/admin/Devices/View/components/DeviceSipSetupBria/DeviceSipSetupBria';

import PerfectScrollbar from 'react-perfect-scrollbar';

function DeviceView() {
  // @ts-ignore
  const { device_id } = useParams();
  const {
    data: device,
    status,
    isLoading: deviceIsLoading,
    isFetching: deviceIsFetching,
    error: deviceError,
    refetch: refetchDevice,
  } = useDeviceQuery(device_id);
  // const { isLoading, data: user } = useAuthenticatedUserQuery();

  // console.log('DEVICE:', device);
  // useNavDetails({
  //   sidebar_key: constants.nav.ADMIN_DEVICE_VIEW.sidebar_key,
  //   crumbs: () => [
  //     constants.nav.ADMIN.crumb,
  //     constants.nav.ADMIN_DEVICES_LIST.crumb,
  //     constants.nav.ADMIN_DEVICE_VIEW.crumb({
  //       id: device_id,
  //       device,
  //       status,
  //     }),
  //   ],
  //   deps: [device_id, device, status],
  // });
  return (
    <>
      <SettingsPageWrapper
        isLoading={deviceIsLoading}
        loadingLabel={'Loading device...'}
        buttonNode={
          device ? <DeviceDisableSettings deviceDoc={device.doc} /> : null
        }
        label={
          device ? (
            <div className={`flex flex-col`}>
              <div className={'flex items-center space-x-2'}>
                <IconButton
                  component={Link}
                  variant={'ghost'}
                  size={'sm'}
                  color={'neutral'}
                  className={'no-underline text-content-accent'}
                  to="/v2/settings/devices"
                >
                  <ArrowLeft fr={undefined} />
                </IconButton>
                <span>{device.doc.name} Device Settings</span>
              </div>
              <span className={`ml-9 font-medium text-sm text-neutral-60`}>
                <DeviceType device={device} />
              </span>
            </div>
          ) : null
        }
      >
        {device ? (
          <>
            <DeviceOwner device={device} onComplete={refetchDevice} />
            <DeviceSettingsCard device={device} onComplete={refetchDevice} />
            <DeviceMacSettings device={device} onComplete={refetchDevice} />
            {/* @ts-ignore */}
            {['sip_device'].indexOf(device?.doc?.device_type) > -1 ? (
              <DeviceSipCredentials device={device} />
            ) : null}
            {device?.doc?.subtype === 'zoiper' ? (
              <DeviceSipSetupZoiper device={device} />
            ) : null}
            {device?.doc?.subtype === 'bria' ? (
              <DeviceSipSetupBria device={device} />
            ) : null}
            {/*<SettingsPanel*/}
            {/*  leftChild={*/}
            {/*    <Setting*/}
            {/*      label={'Line details'}*/}
            {/*      body={'Name and emoji to display along with the line number.'}*/}
            {/*      // iconButtonProps={{*/}
            {/*      //   children: <EditPencil fr={undefined} />,*/}
            {/*      //   onClick: toggleEditPipeDetails,*/}
            {/*      // }}*/}
            {/*    />*/}
            {/*  }*/}
            {/*  rightChild={*/}
            {/*    <>*/}
            {/*      <SettingsButton device={device} onComplete={refetchDevice} />*/}
            {/*    </>*/}
            {/*  }*/}
            {/*/>*/}
          </>
        ) : null}
        {/*{pipe ? (*/}
        {/*  <>*/}

        {/*    <Users pipe={pipe} refetch={pipesQuery.refetch} />*/}
        {/*    <Voicemail pipe={pipe} refetch={pipesQuery.refetch} />*/}
        {/*    <CallRouting pipe={pipe} />*/}
        {/*    {process.env.NODE_ENV === 'development' ||*/}
        {/*    pipe.type === 'external' ? (*/}
        {/*      <MessageRouting pipe={pipe} />*/}
        {/*    ) : null}*/}
        {/*    /!* <Schedule pipe={pipe} refetch={pipesQuery.refetch} /> *!/*/}
        {/*    {devMode &&*/}
        {/*    (process.env.NODE_ENV === 'development' ||*/}
        {/*      pipe?.key?.length > 8) ? (*/}
        {/*      <>*/}
        {/*        <Messaging pipe={pipe} />*/}
        {/*      </>*/}
        {/*    ) : null}*/}
        {/*  </>*/}
        {/*) : (*/}
        {/*  'Error loading pipe'*/}
        {/*)}*/}
      </SettingsPageWrapper>
    </>
  );

  if (deviceIsLoading) {
    return <>Loading device</>;
  }

  if (deviceError) {
    // @ts-ignore
    const code = parseGraphqlErrorCode(deviceError) ?? deviceError.message;

    return (
      <Typography sx={{ color: 'error.main' }}>
        Error loading device: {code}
      </Typography>
    );
  }

  if (!device) {
    return (
      <Typography sx={{ color: 'error.main' }}>invalid device id</Typography>
    );
  }

  // const { doc: deviceDoc } = device;
  //
  // return (
  //   <div
  //     className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //   >
  //     <div className="overflow-hidden bg-background-neutral/75 relative h-screen w-full">
  //       <PerfectScrollbar
  //         options={{ wheelPropagation: false }}
  //         // onScrollY={handleScrollY}
  //       >
  //         <div className={'px-8 py-3 h-full'}>
  //           <div className="border-solid w-full pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
  //             <div className="text-sm mb-2">
  //               <Link
  //                 className={'no-underline text-content-accent'}
  //                 to="/v2/settings/devices"
  //               >
  //                 &lt; Back to Devices
  //               </Link>
  //             </div>
  //             <div className={'flex w-full items-center justify-between'}>
  //               <div className={'flex  items-center space-x-2'}>
  //                 <div
  //                   className={
  //                     'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
  //                   }
  //                 >
  //                   <PhonelinkSetupIcon
  //                     className={'fill-content-accent '}
  //                     sx={{ fontSize: 'inherit' }}
  //                   />
  //                 </div>
  //                 <div>
  //                   <div className="font-bold text-xl">
  //                     {device.doc.name ?? 'Unnamed'}
  //                   </div>
  //                   <div className="text-base text-neutral-500">
  //                     <DeviceType device={device} />
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className={'self-end mb-1'}>
  //
  //               </div>
  //             </div>
  //           </div>
  //           <div className="p-4">
  //             <div className="text-lg font-bold mb-2">Owner</div>
  //             {/* <div className="text-sm mb-2">
  //               <Link to="/v2/settings/devices">&lt;&lt; Back to Devices</Link>
  //             </div>
  //             <div>
  //               <Grid container spacing={1} alignItems={'center'}>
  //                 <Grid item sx={{ flex: 1, whiteSpace: 'nowrap' }}>
  //                   <Typography variant="h1">{device.doc.name}</Typography>
  //                 </Grid>
  //
  //                 <Grid item>
  //                   <ButtonMUI
  //                     color="gray"
  //                     // variant="outlined"
  //                     onClick={refetchDevice}
  //                     startIcon={<RefreshIcon />}
  //                     loading={deviceIsFetching}
  //                   >
  //                     Refresh
  //                   </ButtonMUI>
  //                 </Grid>
  //                  <Grid item md={3}>
  //         <FindInPage />
  //       </Grid>
  //                 <Grid item>
  //                   <SettingsButton
  //                     device={device}
  //                     onComplete={refetchDevice}
  //                   />
  //                 </Grid>
  //               </Grid>*/}
  //
  //
  //             <br />
  //             {/* <div className="flex justify-between">
  //               <div>
  //                  <Grid container spacing={1} alignItems={'center'}>
  //                   <Grid item>
  //                     <DeviceDoNotDisturbSettings deviceDoc={deviceDoc} />
  //                   </Grid>
  //                   <Grid item sx={{ flex: 1 }}>
  //                     <DeviceDisableSettings deviceDoc={deviceDoc} />
  //                   </Grid>
  //                 </Grid>
  //                  <br />
  //                 <DeviceCallforwardSettings
  //                   deviceDoc={deviceDoc}
  //                   onComplete={refetchDevice}
  //                 />
  //               </div>*/}
  //
  //             <DeviceSipCredentials device={device} />
  //             <br />
  //             <DeviceSettingsCard device={device} onComplete={refetchDevice} />
  //             {/*</div>*/}
  //             {/* <br />
  //               <br />*/}
  //             {/* <Grid container spacing={2}>
  //               <Grid item md={4}> */}
  //             {/*<DeviceCallerIdCard*/}
  //             {/*  device={device}*/}
  //             {/*  onComplete={refetchDevice}*/}
  //             {/*/>*/}
  //             {/* </Grid>
  //               <Grid item md={8}>
  //                 <DeviceCallHandlingCard2
  //                   device={device}
  //                   onComplete={refetchDevice}
  //                 />
  //               </Grid> */}
  //             {/* <Grid item md={7}>
  //         <DeviceSettingsCard device={device} onComplete={refetchDevice} />
  //       </Grid> */}
  //             {/* </Grid> */}
  //             {/*<br />
  //               <GroupsCard
  //                 memberId={device.id}
  //                 device={device}
  //                 onComplete={refetchDevice}
  //               />*/}
  //             {/*</div>*/}
  //           </div>
  //         </div>
  //       </PerfectScrollbar>
  //     </div>
  //   </div>
  // );
}

export default DeviceView;

import { AudioPlayer } from 'app/components/AudioPlayer';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { MediaDialog } from 'app/components/MediaDialog';
import { Typography } from 'app/design';
import { getAtPath, useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import * as React from 'react';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface HookFormTextFieldProps {
  name: string;
  label: string;
  editLabel?: string;
  onChange?: any;
}

const HookFormMediaSelect = ({
  name,
  label,
  editLabel,
  onChange,
}: HookFormTextFieldProps) => {
  const [showSelectDialog, toggleSelectDialog] = useToggleReducer();
  const [mediaId, setMediaId] = useState<null | string | undefined>(null);
  const {
    formState: { errors: formErrors },
    control,
  } = useFormContext();

  const errorMessage = getAtPath(formErrors, name);
  const { t } = useMarkdownTranslate();

  return (
    <Controller
      control={control}
      // @ts-ignore
      name={name}
      render={({ field: { onChange: hookOnChange, value, ...props } }) => {
        const onMutate = async (mediaId?: string | null) => {
          if (!mediaId) {
            toggleSelectDialog();
            hookOnChange(mediaId);
            onChange && onChange(mediaId); // pass to parent easier that 'watch'ing
          }

          setMediaId(mediaId);
        };

        return (
          <>
            <MediaDialog
              open={showSelectDialog}
              mediaNameToSaveAs={`Menu ${label}`}
              externalMutation={{
                onMutate,
              }}
              onComplete={() => {
                hookOnChange(mediaId);
                onChange && onChange(mediaId); // pass to parent easier that 'watch'ing
                toggleSelectDialog();
              }}
              onCancel={toggleSelectDialog}
              mediaId={value}
            />
            <GenericSettingsCardField
              title={label}
              titleHelp={t('menu.greeting.tooltip')}
              content={value ? <AudioPlayer mediaId={value} /> : undefined}
              editOnClick={() => {
                setMediaId(null);
                toggleSelectDialog();
              }}
              editLabel={editLabel ?? `Change ${label}`}
            />
            <br />
            {errorMessage ? (
              <Typography color={'error'} variant={'caption'}>
                {errorMessage}
              </Typography>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default HookFormMediaSelect;

import { LinkProps } from '@mui/material/Link';
import { Link } from 'app/design';
import { LinkProps as RouterLinkProp, Link as RLINK } from 'react-router-dom';

interface RouterLinkProps extends LinkProps {
  to: string;
}

export const RouterLink = ({ children, ...props }: RouterLinkProps) => {
  return (
    <Link {...props} component={RLINK}>
      {children}
    </Link>
  );
};

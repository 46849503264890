import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import scheduleQueryKeys from 'app/hooks/queries/schedule/scheduleQueryKeys';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { MenuCreateDoc } from 'types/menu';
import { ScheduleCreateDoc } from 'types/schedule';

export const useCreateSchedule = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, ScheduleCreateDoc, unknown>(
    (scheduleCreateDoc: ScheduleCreateDoc) =>
      sdk.schedule.mutate.create(
        {
          data: scheduleCreateDoc,
          // other values such as "accept_charges" could go here
        },
        {
          authToken: authState?.auth_token,
        },
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(callflowQueryKeys.all);
        await queryClient.invalidateQueries(scheduleQueryKeys.all);
      },
    },
  );
};

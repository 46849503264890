import { PayloadAction } from '@reduxjs/toolkit';
import { useAuthSelector } from 'app/data/auth';
import { useWebphoneSelector } from 'app/data/webphone/selectors';
import { useMemo } from 'react';
import { Callflow } from 'types/callflow';
import { Contact } from 'types/contact';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { webphoneSaga } from './saga';
import { WebphoneState } from './types';
import {
  parsePhoneNumber,
  parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js';

import { defaultsDeep, merge } from 'lodash';

import { setAtPath } from 'app/utilities';

const persistedInitialState = {
  dialer: {
    input: {
      country: 'US', // not actually using this yet
    },
    outbound: null, // should get set to default
  },
  // call_history: [],
  settings: {
    ring: {
      ringtone_internal: null,
    },
    keypad: {
      disableSounds: true,
      volume: 0.01,
    },
    // remember previous device and select (if available)
    input: {
      device: null,
      volume: null,
    },
    // remember settings and previous device and select (if available)
    output: {
      device: null,
      volume: null,
    },
  },
  contacts: [],
};

export const initialState: WebphoneState = {
  ...persistedInitialState,
  numberContactDictionary: {},
  // ephemeral
  device: null,
  callingDisabled: false,
};

export const parseNumberKey = (number: string) => {
  // strip all chars/country codes to create key of just digits of number/ext
  // tested for following number formats
  //
  // +17863900086
  // 7863900086
  // (786) 390-0086
  // 101
  // 102
  //
  // console.log('Number for parseNumberKey');
  if (typeof number === 'string') {
    return number.replace(/(\+1|[() -])/g, '');
  }
  console.log('invalid Number provided to parseNumberKey:', number);
  // throw new Error('test');
  return '';
};

// look up matching contacts (if it exists) by number otherwise return empty array
export const useLookupContact = (number: string) => {
  const { numberContactDictionary } = useWebphoneSelector();

  const contacts = useMemo(() => {
    if (!number) return [];
    // check exact match
    let contact = numberContactDictionary[number];

    if (contact) return contact;

    // stripped match
    return numberContactDictionary[parseNumberKey(number)] ?? [];
  }, [number, numberContactDictionary]);

  // console.log('contacts mem9o', contacts);
  return contacts;
};

export const createNumberContactDictionary = (contacts: Contact[]) => {
  const dictionary: { [key: string]: Contact[] } = {};

  contacts.forEach(contact => {
    contact.info.phoneNumbers.forEach(numberData => {
      const exactKey = numberData.number;
      if (dictionary[exactKey]) {
        // create exact match
        dictionary[exactKey].push(contact);
      } else {
        // create exact match
        dictionary[exactKey] = [contact];
      }

      const strippedKey = parseNumberKey(numberData.number);

      if (strippedKey !== exactKey) {
        // add stripped match
        if (dictionary[strippedKey]) {
          dictionary[strippedKey].push(contact);
        } else {
          dictionary[strippedKey] = [contact];
        }
      }

      // "full" international key (ie "555-555-5555 > "+15555555555"")
      // - do this correctly
      const fullKey = fullInternationalPtn(numberData.number);

      if (fullKey !== exactKey) {
        // add stripped match
        if (dictionary[fullKey]) {
          dictionary[fullKey].push(contact);
        } else {
          dictionary[fullKey] = [contact];
        }
      }
    });
  });

  // sort contacts match so that external contacts are earlier in the array
  Object.entries(dictionary).forEach(([key, contacts]) => {
    dictionary[key] = contacts.sort(function (left, right) {
      return left.hasOwnProperty('user')
        ? 1
        : right.hasOwnProperty('user')
        ? -1
        : 0;
    });
  });

  // console.log('dictionary', dictionary);
  return dictionary;
};

const fullInternationalPtn = number => {
  try {
    // @ts-ignore
    const num = parsePhoneNumber(number, {
      // @ts-ignore
      defaultCountry: 'US',
    });
    if (num) {
      return `+${num.countryCallingCode}${num.nationalNumber}`;
    }
  } catch (e) {
    console.error('unable to parse number');
    return number;
  }

  return number;
};

export const persistMask = Object.keys(persistedInitialState)
  .join(',')
  .replace(/\s/g, '');

const slice = createSlice({
  name: 'webphone',
  initialState: () => {
    // eh, merging the "saved" state with the "initial"/"default" state is hard, so simplify by only providing top-level keys that can be saved
    // - doing deep-merge is error-prone and more complicated
    let state = initialState;

    try {
      state = defaultsDeep(
        {
          // @ts-ignores
          ...(JSON.parse(localStorage.getItem(`app_persist_state_webphone`)) ??
            {}),
        },
        initialState,
      );
      // state = {
      //   ...initialState,
      //   // @ts-ignore
      //   ...(JSON.parse(localStorage.getItem(`app_persist_state_webphone`)) ??
      //     initialState),
      // };
    } catch (err) {}

    state.numberContactDictionary = createNumberContactDictionary(
      state.contacts,
    );

    return state;
  },
  reducers: {
    set_device(state, action: PayloadAction<any>) {
      state.device = action.payload;
    },
    // set_call_history(state, action: PayloadAction<any>) {
    //   state.call_history = action.payload;
    // },
    // add_to_call_history(state, action: PayloadAction<any>) {
    //   state.call_history.push(action.payload);
    // },
    set_setting(state, action: PayloadAction<{ path: string; value: any }>) {
      setAtPath(state.settings, action.payload.path, action.payload.value);
    },
    set_dialer_input_country(state, action: PayloadAction<any>) {
      state.dialer.input.country = action.payload;
    },
    disableKeypadSounds(state, action: PayloadAction<boolean>) {
      state.settings.keypad.disableSounds = action.payload;
    },
    disableCalling(state, action: PayloadAction<boolean>) {
      state.callingDisabled = action.payload;
    },
    setKeypadVolume(state, action: PayloadAction<number>) {
      state.settings.keypad.volume = action.payload;
    },
    setContacts(state, action: PayloadAction<Contact[]>) {
      state.contacts = action.payload;
      state.numberContactDictionary = createNumberContactDictionary(
        action.payload,
      );
    },
  },
  extraReducers: {
    'auth/logout': (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;

export const { actions, reducer } = slice;

export const useWebphoneSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: webphoneSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useWebphoneSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

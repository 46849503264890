// import {
//   getAtPath,
//   preEndpointsToCallflowModuleFormat,
//   preEndpointsToGroupFormat,
//   randomString,
//   setAtPath,
// } from '@KazooSDK/utils';
import {
  Card,
  Chip,
  Divider,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
  Box,
} from 'app/design';
import {
  AccessTime as AccessTimeIcon,
  DragHandle as DragHandleIcon,
  Edit as EditIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Layers as LayersIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Group as GroupIcon,
  Person as PersonIcon,
  PhoneAndroid as PhoneAndroidIcon,
} from 'app/design/icons-material';
import { CollectionListHeader } from 'app/components/CollectionListHeader';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { useListUsersQuery } from 'app/hooks/queries/user';
import {
  getAtPath,
  randomString,
  setAtPath,
  useToggleReducer,
} from 'app/utilities';
import {
  preEndpointsToCallflowModuleFormat,
  preEndpointsToGroupFormat,
} from 'app/utilities/utils';

import { toInteger } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// import { fetchAllVmboxes } from '../../../../../Actions/voicemails';
import { AddComponent } from '../AddComponent';
import { RingConfig as RingConfigCustom } from '../Custom/Custom';
import {
  Alert,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  TextField,
  DialogHeader,
} from 'app/design-lib';
import {
  Cancel,
  Clock,
  EditPencil,
  KeyframesCouple,
  Menu,
  Phone,
  Strategy,
  User,
  WarningTriangle,
  Wrench,
} from 'iconoir-react';
import { StepTab } from 'app/components/Onboarding/Vsb/Vsb';
import { useMediaQuery } from '@mui/material';
import { isMobile } from '@headlessui/react/dist/utils/platform';

// Select users from list of users
export const SequentialComponent = props => {
  const {
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    // users,
    // devices,
    defaultToFirst,
    ownerUser,
    // fetchUsers,
    allow,
    useGroupFormat, // vs. "ringgroup" Format (requires conversion! has restrictions!)
    onSave,
    onReset,
    startEditing,
    users,
    devices,
    onClose,
    errorMessage,
  } = props;

  const [isEditing, setIsEditing] = useState(startEditing ? true : false);

  useEffect(() => {
    if (errorMessage) setIsEditing(true);
  }, [errorMessage]);

  const ringGroup = getAtPath(strategyCallflow, `${modifyPath}`, {
    // endpoints: useGroupFormat ? {} : [],
    // preEndpoints: [], // gets built into "endpoints" depending on the useGroupFormat
    // timeout: 20,
    // strategy: 'simultaneous',
  });

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${modifyPath}.preEndpoints`,
    [],
  );

  const handleResetToChooser = () => {
    setAtPath(strategyCallflow, `${modifyPath}.type`, 'none');
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleSave = () => {
    const data = getAtPath(strategyCallflow, `${modifyPath}`);

    let { preEndpoints = [] } = data;

    // rebuild preEndpoints timeout and delay
    // - assigns for Tiers
    let delay = 0;
    let newTimeout = 0;
    preEndpoints = preEndpoints.map(endpoint => {
      endpoint.delay = delay;
      endpoint.timeout = toInteger(endpoint.timeout);
      delay += endpoint.timeout;
      newTimeout += endpoint.timeout;
      return endpoint;
    });

    const endpoints = useGroupFormat
      ? preEndpointsToGroupFormat(preEndpoints)
      : preEndpointsToCallflowModuleFormat(preEndpoints);
    setAtPath(strategyCallflow, `${modifyPath}.timeout`, newTimeout);
    setAtPath(strategyCallflow, `${modifyPath}.endpoints`, endpoints);
    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, preEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
    console.log('Sequential Endpoints Build:', { preEndpoints, endpoints });
    onSave && onSave();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const endpoints = reorder(
        selectedEndpoints,
        result.source.index,
        result.destination.index,
      );
      setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, endpoints);

      // TODO: convert according to useGroupFormat

      setStrategyCallflow({ ...strategyCallflow });

      // convert according to useGroupFormat
    },
    [selectedEndpoints, modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleAdd = new_endpoints_arr => {
    let currentOpts = {
      delay: 0,
      timeout: 10,
    };
    let newArr = new_endpoints_arr.map(t => ({ ...t, ...currentOpts }));

    const updatedEndpoints = [...selectedEndpoints, ...newArr];

    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  return (
    <>
      <DialogHeader
        title={
          isMobile
            ? 'Ring Strategy: Sequential'
            : 'Ring Strategy: Sequential (one-by-one)'
        }
        subTitle={`Users and devices will be rung one-after-another, in the order you
            specify.`}
        onClose={onClose}
      />
      <DialogContent className={`overflow-y-auto`}>
        {errorMessage ? (
          <div className={'pt-0 px-2 pb-3'}>
            <Alert
              label={'Error Saving Ring Group'}
              reverse
              color={'negative'}
              icon={<WarningTriangle fr={undefined} />}
              body={errorMessage}
            />
          </div>
        ) : null}
        {isEditing ? (
          <div
            className={`flex pb-4 flex-1 justify-center mx-4 sm:mx-44 items-center space-x-4`}
          >
            <StepTab
              link
              label={'1. Select Strategy'}
              icon={<Strategy width={12} height={12} fr={undefined} />}
              currentStep={1}
              step={0}
              onClick={handleResetToChooser}
            />
            <div
              className={`flex-grow h-[1px] bg-neutral-20 rounded-full`}
            ></div>
            <div className={`w-`}></div>
            <StepTab
              label={'2. Customize Group'}
              icon={<Wrench width={12} height={12} fr={undefined} />}
              currentStep={1}
              step={1}
            />
          </div>
        ) : null}
        <div className={`bg-neutral-10 rounded p-4`}>
          <div className={`flex space-x-4 items-start`}>
            <div className={`flex-1`}>
              <span className={`text-md font-medium underline`}>
                Members & Ring Order
              </span>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedEndpoints.length ? (
                        selectedEndpoints.map((endpoint, i) =>
                          isEditing ? (
                            <RingConfig
                              key={endpoint.id}
                              index={i}
                              endpoint={endpoint}
                              users={users}
                              devices={devices}
                              timeout={ringGroup.timeout}
                              endpointsModifyPath={`${modifyPath}.preEndpoints`}
                              modifyPath={`${modifyPath}.preEndpoints.${i}`} // TODO: verify w/ Nick this is new intended format?
                              parentModifyPath={modifyPath}
                              strategyCallflow={strategyCallflow}
                              setStrategyCallflow={setStrategyCallflow}
                              provided={provided}
                              isEditing={isEditing}
                              allow={allow}
                            />
                          ) : (
                            <RingConfigCustom
                              key={endpoint.id}
                              index={i}
                              endpoint={endpoint}
                              users={users}
                              devices={devices}
                              timeout={ringGroup.timeout}
                              endpointsModifyPath={`${modifyPath}.preEndpoints`}
                              modifyPath={`${modifyPath}.preEndpoints.${i}`} // TODO: verify w/ Nick this is new intended format?
                              parentModifyPath={modifyPath}
                              strategyCallflow={strategyCallflow}
                              setStrategyCallflow={setStrategyCallflow}
                              provided={provided}
                              isEditing={isEditing}
                              allow={allow}
                            />
                          ),
                        )
                      ) : (
                        <Alert
                          className={'mt-2'}
                          icon={<WarningTriangle fr={undefined} />}
                          label={'No users or devices'}
                          color={'attention'}
                          reverse
                          body={
                            'Add at least two users or devices to this ring group'
                          }
                        />
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div>
              {/*<TextField
              startIcon={<Clock fr={undefined} />}
              label="Ring duration"
              value={ringTimeout}
              disabled={!isEditing}
              type="number"
              min={0}
              onChange={e =>
                // setDefaultRingTotalTime(toInteger(e.target.value))
                handleSetRingTimeout(toInteger(e.target.value))
              }
            />*/}
            </div>
          </div>
          {isEditing && !ringGroup.managed ? (
            <div className={`pt-2`}>
              <AddComponent
                // label={'Add to Group:'}
                {...props}
                selectedEndpoints={selectedEndpoints}
                allow={[...props.allow, 'tier']}
                onAdd={handleAdd}
                tierCount={
                  selectedEndpoints.filter(ep => {
                    return ep.endpoint_type === 'tier';
                  }).length
                }
              />
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions
        className={`flex ${isEditing ? 'justify-between' : 'justify-end'}`}
      >
        {isEditing ? (
          <>
            <Button
              color="negative"
              variant="fill"
              size={'md'}
              onClick={e => {
                setIsEditing(false);
                onReset();
              }}
            >
              Cancel Editing
            </Button>
            {/*
            {ringGroup.managed ? null : (
              <Button
                color="attention"
                variant="outline"
                size={'md'}
                onClick={handleResetToChooser}
              >
                Change strategy
              </Button>
            )}
*/}
            <Button
              color="accent"
              variant="fill"
              size={'md'}
              onClick={e => {
                handleSave();
                setIsEditing(false);
              }}
            >
              Save Ring Group
            </Button>
          </>
        ) : (
          <Button
            variant={'fill'}
            color={'accent'}
            size={'md'}
            onClick={e => setIsEditing(true)}
          >
            Edit Ring Group
          </Button>
        )}
      </DialogActions>
    </>
  );

  // return (
  //   <div>
  //     <CollectionListHeader
  //       icon={
  //         <FormatListNumberedIcon color={'primary'} style={{ fontSize: 20 }} />
  //       }
  //       title="Ring Strategy: Sequential (one-by-one)"
  //       subtitle="Users and devices will be rung one-after-another, in the order you
  //           specify."
  //       actionButton={
  //         isEditing ? (
  //           <>
  //             <Grid container spacing={1}>
  //               {ringGroup.managed ? null : (
  //                 <Grid item>
  //                   <Button
  //                     color="primary"
  //                     variant="contained"
  //                     size="small"
  //                     // startIcon={<EditIcon />}
  //                     disableRipple
  //                     onClick={handleResetToChooser}
  //                   >
  //                     Change Ring Strategy
  //                   </Button>
  //                 </Grid>
  //               )}
  //               <Grid item>
  //                 <Button
  //                   color="error"
  //                   variant="outlined"
  //                   size="small"
  //                   // startIcon={<EditIcon />}
  //                   disableRipple
  //                   onClick={e => {
  //                     setIsEditing(false);
  //                     onReset();
  //                   }}
  //                 >
  //                   Cancel
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </>
  //         ) : (
  //           <Button
  //             //color="info"
  //             variant={'contained'}
  //             onClick={e => setIsEditing(true)}
  //           >
  //             Edit
  //           </Button>
  //         )
  //       }
  //     />
  //     {!isEditing ? <br /> : null}
  //     {/*<Divider />*/}
  //
  //     <Box
  //       sx={{
  //         width: '100%',
  //         pl: 3,
  //       }}
  //     >
  //       <DragDropContext onDragEnd={onDragEnd}>
  //         <Droppable droppableId="droppable">
  //           {provided => (
  //             <div ref={provided.innerRef} {...provided.droppableProps}>
  //               {selectedEndpoints.length ? (
  //                 selectedEndpoints.map((endpoint, i) => (
  //                   <RingConfig
  //                     key={endpoint.id}
  //                     index={i}
  //                     endpoint={endpoint}
  //                     users={users}
  //                     devices={devices}
  //                     timeout={ringGroup.timeout}
  //                     endpointsModifyPath={`${modifyPath}.preEndpoints`}
  //                     modifyPath={`${modifyPath}.preEndpoints.${i}`} // TODO: verify w/ Nick this is new intended format?
  //                     parentModifyPath={modifyPath}
  //                     strategyCallflow={strategyCallflow}
  //                     setStrategyCallflow={setStrategyCallflow}
  //                     provided={provided}
  //                     isEditing={isEditing}
  //                     allow={allow}
  //                   />
  //                 ))
  //               ) : (
  //                 <Typography
  //                   variant="body1"
  //                   style={{
  //                     fontStyle: 'italic',
  //                     opacity: 0.8,
  //                     textAlign: 'left',
  //                     py: 5,
  //                   }}
  //                 >
  //                   No users or devices added to ring group
  //                 </Typography>
  //               )}
  //               {provided.placeholder}
  //             </div>
  //           )}
  //         </Droppable>
  //       </DragDropContext>
  //       {isEditing ? (
  //         <>
  //           <br />
  //           <Grid
  //             // should match styling of ring config row item
  //             container
  //             spacing={1}
  //             sx={{ px: 1.5 }}
  //             wrap={'nowrap'}
  //             alignItems="center"
  //           >
  //             <Grid
  //               item
  //               style={{
  //                 width: 34,
  //                 display: isEditing ? undefined : 'none',
  //               }}
  //             ></Grid>
  //             <Grid item>
  //               <Typography variant={'body1'}>
  //                 {selectedEndpoints.length + 1}.
  //               </Typography>
  //             </Grid>
  //             <Grid item>
  //               <AddComponent
  //                 // label={'Add to Group:'}
  //                 {...props}
  //                 selectedEndpoints={selectedEndpoints}
  //                 allow={[...props.allow, 'tier']}
  //                 onAdd={handleAdd}
  //                 tierCount={
  //                   selectedEndpoints.filter(ep => {
  //                     return ep.endpoint_type === 'tier';
  //                   }).length
  //                 }
  //               />
  //             </Grid>
  //           </Grid>
  //         </>
  //       ) : null}
  //
  //       <br />
  //       {isEditing ? (
  //         <>
  //           <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
  //             <Button
  //               variant="contained"
  //               color="success"
  //               disableRipple
  //               onClick={e => {
  //                 handleSave();
  //                 setIsEditing(false);
  //               }}
  //             >
  //               Save
  //             </Button>
  //           </Box>
  //         </>
  //       ) : null}
  //     </Box>
  //   </div>
  // );
};

const RingConfig = props => {
  const {
    index,
    endpoint,
    timeout,
    users,
    devices,
    endpointsModifyPath,
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    isEditing,
  } = props;

  const [showEditTierName, toggleEditTierName] = useToggleReducer();

  const value = [endpoint.delay, endpoint.timeout + endpoint.delay];

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${endpointsModifyPath}`,
    [],
  );

  const handleChange = useCallback(
    (e, value) => {
      // console.log('modifyPath:', modifyPath, value);
      setAtPath(strategyCallflow, `${modifyPath}.delay`, value[0]);
      setAtPath(strategyCallflow, `${modifyPath}.timeout`, value[1] - value[0]);
      setStrategyCallflow({ ...strategyCallflow });
    },
    [modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleRemove = useCallback(() => {
    const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
    endpointsArr.splice(index, 1);
    setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
    setStrategyCallflow({ ...strategyCallflow });
  }, [index, endpointsModifyPath, strategyCallflow, setStrategyCallflow]);

  const handleAddToTier = new_endpoints_arr => {
    console.log('adding to tier', new_endpoints_arr);
    let new_arr = [...tiers, ...new_endpoints_arr];
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, new_arr);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };
  // console.log('SC', strategyCallflow);

  const handleSetRingTimeout = timeoutInt => {
    setAtPath(strategyCallflow, `${modifyPath}.timeout`, timeoutInt);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleChangeTierName = tierName => {
    setAtPath(strategyCallflow, `${modifyPath}.tier_name`, tierName);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleBreakTier = () => {
    let currentOpts = {
      delay: endpoint.delay,
      timeout: endpoint.timeout,
    };
    let newArr = endpoint.tiers.map(t => ({ ...t, ...currentOpts }));
    const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
    endpointsArr.splice.apply(endpointsArr, [index, 1].concat(newArr));
    setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleConvertToTier = () => {
    // move the endpoint_type to the list of endpoints in tiers
    const tiers = [
      JSON.parse(JSON.stringify(endpoint)), // id, endpoint_type
    ];
    // set a default tier name

    setAtPath(strategyCallflow, `${modifyPath}.tier_name`, 'Tier');
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, tiers);
    setAtPath(strategyCallflow, `${modifyPath}.endpoint_type`, 'tier');
    setAtPath(strategyCallflow, `${modifyPath}.id`, randomString(5));
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const user = users.find(d => d.id === endpoint.id);
  const device = devices.find(d => d.id === endpoint.id);

  const isTier = endpoint.endpoint_type === 'tier' ? true : false;
  const tierName = endpoint.tier_name;

  const tiers = endpoint.tiers || [];

  const ringTimeout = endpoint.timeout;

  return (
    <Draggable draggableId={endpoint.id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {/*{isEditing ? <br /> : null}*/}
          <Box
            className={`w-full flex sm:items-center relative sm:flex-row flex-col`}
          >
            <Box sx={{ width: '100%' }}>
              <Grid
                container
                spacing={1}
                sx={{ p: 1.5 }}
                alignItems="center"
                justifyContent={isEditing ? 'space-between' : 'left'}
                wrap={'nowrap'}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    sx={{}}
                    wrap={'nowrap'}
                    alignItems="center"
                  >
                    <Grid
                      item
                      style={{
                        width: 34,
                        display: isEditing ? undefined : 'none',
                      }}
                    >
                      <IconButton
                        size={'sm'}
                        variant={'ghost'}
                        color={'neutral'}
                        {...provided.dragHandleProps}
                      >
                        <Menu width={16} height={16} fr={undefined} />
                      </IconButton>
                    </Grid>

                    <Grid item style={{}}>
                      <span className={`text-md`}>{index + 1}. </span>
                    </Grid>
                    <Grid item sx={{ flexGrow: isEditing ? 1 : undefined }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {endpoint.endpoint_type === 'user' ? (
                          <div className={`flex space-x-2 items-center`}>
                            <div>
                              <User
                                className={`text-neutral-60`}
                                width={16}
                                height={16}
                                fr={undefined}
                              />
                            </div>
                            <span className={'font-medium text-md'}>
                              {user?.extra?.fullName ?? 'Unknown User'}
                            </span>
                          </div>
                        ) : (
                          // <>{user?.extra?.fullName ?? 'Unknown User'}</>
                          ''
                        )}
                        {endpoint.endpoint_type === 'device' ? (
                          <div className={`flex space-x-2 items-center`}>
                            <div>
                              <Phone
                                className={`text-neutral-60`}
                                width={16}
                                height={16}
                                fr={undefined}
                              />
                            </div>
                            <span className={'font-medium text-md'}>
                              {device?.doc?.name}
                            </span>
                          </div>
                        ) : (
                          // <>{device?.doc?.name}</>
                          ''
                        )}
                        {endpoint.endpoint_type === 'tier' ? (
                          <div style={{ paddingTop: 5, display: 'flex' }}>
                            {isEditing && showEditTierName ? (
                              <TextField
                                autoFocus
                                label="Tier Name"
                                value={endpoint.tier_name ?? ''}
                                onChange={e =>
                                  handleChangeTierName(e.target.value)
                                }
                              />
                            ) : (
                              <div className={`flex space-x-2 items-center`}>
                                <div>
                                  <KeyframesCouple
                                    className={`text-neutral-60`}
                                    width={16}
                                    height={16}
                                    fr={undefined}
                                  />
                                </div>
                                <span className={'font-medium text-md'}>
                                  {endpoint.tier_name}
                                </span>
                                {!isEditing && (
                                  <Grid item>
                                    <Tooltip
                                      placement="right"
                                      arrow
                                      title={
                                        <>
                                          {endpoint.tiers.map(
                                            (tierEndpoint, tierEndpointIdx) => (
                                              <TierComponent
                                                users={users}
                                                devices={devices}
                                                key={tierEndpoint.id}
                                                {...props}
                                                tierEndpointIdx={
                                                  tierEndpointIdx
                                                }
                                                tierEndpoint={tierEndpoint}
                                              />
                                            ),
                                          )}
                                        </>
                                      }
                                    >
                                      <div>
                                        <Chip
                                          sx={{ height: 20 }}
                                          label={endpoint.tiers?.length}
                                        />
                                      </div>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </div>
                              // <div>
                              //   <Typography variant="body2">
                              //     {endpoint.tier_name}
                              //   </Typography>
                              // </div>
                            )}
                            {isEditing ? (
                              <Box
                                sx={{
                                  ml: 0.5,
                                  display: 'grid',
                                  placeItems: 'center',
                                }}
                              >
                                <IconButton
                                  size={'sm'}
                                  color={'accent'}
                                  variant={'ghost'}
                                  onClick={toggleEditTierName}
                                >
                                  <EditPencil fr={undefined} />
                                </IconButton>
                              </Box>
                            ) : null}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {endpoint.endpoint_type === 'tier'
                ? isEditing && (
                    <Box className={`pl-[2.8rem] -mt-1`}>
                      {endpoint.tiers.map((tierEndpoint, tierEndpointIdx) => (
                        <TierComponent
                          users={users}
                          devices={devices}
                          key={tierEndpoint.id}
                          {...props}
                          tierEndpointIdx={tierEndpointIdx}
                          tierEndpoint={tierEndpoint}
                        />
                      ))}

                      <AddComponent
                        {...props}
                        label={'Add to sub-group'}
                        selectedEndpoints={selectedEndpoints}
                        modifyPath={props.parentModifyPath}
                        onAdd={handleAddToTier}
                      />
                      <br />
                      <br />
                    </Box>
                  )
                : ''}
            </Box>

            <Box className={'mr-2 ml-4 sm:ml-0 mb-2 sm:my-2'}>
              <TextField
                disabled={!isEditing}
                label="Ring duration (seconds)"
                value={ringTimeout}
                onChange={e => handleSetRingTimeout(e.target.value)}
                type="number"
                min={0}
              />
            </Box>

            {isEditing ? (
              <Box
                style={{
                  height: '100%',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                {/* @ts-ignore */}
                <IconButton
                  onClick={handleRemove}
                  color={'negative'}
                  size={'sm'}
                  variant={'ghost'}
                  className={'sm:relative  absolute top-3 sm:right-0 right-3'}
                  // color="secondary"
                  // variant="outlined"
                  // sx={{ padding: 0, fontSize: 20 }}
                >
                  <Cancel fr={undefined} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
          {/*{isEditing && <br />}*/}
          <Divider sx={{ width: '100%' }} />
        </div>
      )}
    </Draggable>
  );
};

const TierComponent = props => {
  const {
    strategyCallflow,
    setStrategyCallflow,
    modifyPath,
    tierEndpoint,
    tierEndpointIdx,
    isEditing,
    users,
    devices,
  } = props;

  const user = users.find(d => d.id === tierEndpoint.id);
  const device = devices.find(d => d.id === tierEndpoint.id);

  const handleRemove = () => {
    const tiers = getAtPath(strategyCallflow, `${modifyPath}.tiers`);
    tiers.splice(tierEndpointIdx, 1);
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, tiers);
    setStrategyCallflow({ ...strategyCallflow });
  };

  return (
    <div className={`my-1`} style={{ width: '100%' }}>
      <Grid
        container
        spacing={1}
        wrap={'nowrap'}
        alignItems={'center'}
        style={{ width: '100%' }}
      >
        <Grid item>
          {tierEndpoint.endpoint_type === 'user' ? (
            <div className={`flex space-x-1 items-center`}>
              <User
                fr={undefined}
                className={`text-neutral-60`}
                width={16}
                height={16}
              />
              <span className={'font-medium text-md whitespace-nowrap'}>
                {user?.extra?.fullName ?? 'Unknown User'}
              </span>
            </div>
          ) : (
            // <>{user?.extra?.fullName ?? 'Unknown User'}</>
            ''
          )}
          {tierEndpoint.endpoint_type === 'device' ? (
            <div className={`flex space-x-1 items-center`}>
              <Phone
                fr={undefined}
                className={`text-neutral-60`}
                width={16}
                height={16}
              />
              <span className={'font-medium text-md'}>{device?.doc?.name}</span>
            </div>
          ) : (
            // <>{device?.doc?.name}</>
            ''
          )}
        </Grid>
        {isEditing && (
          <Grid item style={{ width: 34, textAlign: 'left' }}>
            <IconButton
              onClick={handleRemove}
              color={'negative'}
              variant={'ghost'}
            >
              <Cancel fr={undefined} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const DividerWithText = ({ children }) => {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Divider style={{ flex: '1 1 auto' }} />
      <span style={{ padding: '0 10px' }}>{children}</span>
      <Divider style={{ flex: '1 1 auto' }} />
    </Grid>
  );
};

export default SequentialComponent; //connect(mapStateToProps, { fetchUsers })(RingGroup);

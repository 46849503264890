import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { Badge } from 'app/design-lib';
import * as React from 'react';
import { Group } from 'types/group';

interface RingStrategyCellProps {
  group: Group;
}

const RingStrategyCell = ({ group }: RingStrategyCellProps) => {
  let label;

  switch (group.doc.type) {
    case 'custom':
      label = 'Custom';
      break;
    case 'fallback':
      label = 'Fallback';
      break;
    case 'seq':
      label = 'Sequence';
      break;
    case 'simu':
      label = 'Simultaneous';
      break;
    default:
      return null;
    // <Badge size={'sm'} variant={'fill'} reverse color={'negative'}>
    //   Not setup
    // </Badge>
  }

  return (
    <Badge size={'sm'} variant={'fill'} reverse color={'accent'}>
      {label}
    </Badge>
  );
};

export default RingStrategyCell;

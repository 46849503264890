import { BulkActionsGroups } from 'app/components/ListGroups/components/BulkActionsGroups';
import { Drawer } from 'app/design';
import { Delete as DeleteIcon } from 'app/design/icons-material';
import { OptionsTableCell } from 'app/components/OptionsTableCell';
import { useDeleteGroup } from 'app/hooks/mutations/group';
import { GroupRingStrategyCard } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Group } from 'types/group';

interface GroupListOptionsProps {
  group: Group;
  onComplete: () => void;
  onDelete: () => void;
  toggleRowSelected: any;
  vertical?: boolean;
}

const GroupListOptions = ({
  onDelete,
  onComplete,
  group,
  toggleRowSelected,
  vertical = true,
}: GroupListOptionsProps) => {
  return (
    <>
      <BulkActionsGroups
        onComplete={() => {
          onComplete();
          onDelete();
        }}
        toggleRowSelected={toggleRowSelected}
        vertical={vertical}
        selectedRows={[group]}
      />
    </>
  );
  // const deleteGroup = useDeleteGroup();
  //
  // const handleDelete = () => {
  //   const createGroupPromise = deleteGroup.mutateAsync(group.id, {
  //     onSuccess: async resp => {
  //       onComplete();
  //       onDelete();
  //       console.log('delete group resp', resp);
  //     },
  //     onError: error => console.error(error),
  //   });
  //
  //   toast.promise(createGroupPromise, {
  //     pending: 'Deleting group...',
  //     success: 'Group deleted!',
  //     error: 'Error deleting group.',
  //   });
  // };
  //
  // return (
  //   <OptionsTableCell
  //     options={[
  //       { label: 'Delete', icon: <DeleteIcon />, onClick: handleDelete },
  //     ]}
  //   />
  // );
};

export default GroupListOptions;

// import { AudioPlayer } from 'app/components/AudioPlayer';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { EmptyTableCell } from 'app/components/EmptyTableCell';
import * as React from 'react';
import { Media } from 'types/media';

interface MediaSourceCellProps {
  media: Media;
  vmboxId?: string;
  type?: 'VMBOX_MESSAGE';
  onPlay?: () => void;
}

const PlayAudioTableCell = ({
  media,
  vmboxId,
  type,
  onPlay,
}: MediaSourceCellProps) => {
  if (!media) {
    return <EmptyTableCell label={'no audio'} />;
  }

  return (
    <AudioPlayer
      mediaId={media.id}
      vmboxId={vmboxId}
      type={type}
      updated={media.updatedAt}
      onPlay={onPlay}
    />
  );
};

export default PlayAudioTableCell;

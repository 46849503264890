import { Typography } from 'app/design';
import React from 'react';

interface EmptyTableCellProps {
  label: string;
}

const EmptyTableCell = ({ label }: EmptyTableCellProps) => {
  return (
    <Typography sx={{ fontStyle: 'italic', color: '#afafaf' }}>
      {label}
    </Typography>
  );
};

export default EmptyTableCell;

import { Grid, Tooltip, Typography } from 'app/design';
import React, { ReactNode } from 'react';

interface NodeInfoDisplayProps {
  icon: ReactNode;
  type: string;
  title: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
}

const NodeInfoDisplay = ({
  icon,
  type,
  title,
  content,
  footer,
}: NodeInfoDisplayProps) => {
  return (
    <div style={{ padding: 8, textAlign: 'center', alignItems: 'center' }}>
      <div>
        <Typography variant="caption">
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item style={{ paddingTop: 3, marginRight: 3 }}>
              {icon}
            </Grid>
            <Grid item>{type}</Grid>
          </Grid>
        </Typography>
      </div>
      <div>
        <Tooltip
          title={typeof title === 'string' ? title : ''}
          arrow
          placement={'bottom'}
        >
          <div>
            <Typography variant="body1" bold noWrap>
              {title}
            </Typography>
          </div>
        </Tooltip>
      </div>
      {content && (
        <div style={{ overflowX: 'hidden' }}>
          <Typography variant={'subtitle1'}>{content}</Typography>
        </div>
      )}
      {footer && <Typography variant={'caption'}>{footer}</Typography>}
    </div>
  );
};

export default NodeInfoDisplay;

import { gql } from 'graphql-request';

export default gql`
  query omni(
    $take: Int
    $filtersDefault: JSON
    $maskDefault: String
    $filtersUsers: JSON
    $maskUsers: String
  ) {
    callflows(filters: $filtersDefault, mask: $maskDefault, take: $take) {
      callflows {
        id
        doc
      }
    }
    devices(filters: $filtersDefault, mask: $maskDefault, take: $take) {
      devices {
        id
        doc
      }
    }
    directories(filters: $filtersDefault, mask: $maskDefault, take: $take) {
      directories {
        id
        doc
      }
    }
    groups(filters: $filtersDefault, mask: $maskDefault, take: $take) {
      groups {
        id
        doc
      }
    }
    phoneNumbers(filters: $filtersDefault, mask: $maskDefault, take: $take) {
      phoneNumbers {
        id
        doc
      }
    }
    users(filters: $filtersUsers, mask: $maskUsers, take: $take) {
      users {
        id
        doc
        hasPhoto
        extra
      }
    }
  }
`;

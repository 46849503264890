import { gql } from 'graphql-request';

export default gql`
  query Query($skip: Int, $take: Int, $filters: JSON, $orderBy: JSON) {
    vmboxes(skip: $skip, take: $take, filters: $filters, orderBy: $orderBy) {
      totalCount
      vmboxes {
        id
        doc
        updatedAt
        folders {
          new
          saved
          deleted
        }
        createdAt
        listing
        #        Callflow {
        #          id
        #          doc
        #        }
        Owner {
          doc
          id
        }
        Greeting {
          id
          doc
          updatedAt
        }
        TempGreeting {
          id
          doc
          updatedAt
        }
      }
    }
  }
`;

import {
  Alert,
  Badge,
  Dialog,
  Setting,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { SettingsPanel } from 'app/pages/v2/settings/shared/SettingsPanel';
import useDevMode from 'app/utilities/useDevMode';
import {
  MoreHoriz as OptionsIcon,
  Plus as AddIcon,
  WarningTriangle,
} from 'iconoir-react';
import React, { useState } from 'react';
import {
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  Link,
  Chip,
  Divider,
} from 'app/design';
import { Invoice as InvoiceSummary } from 'app/pages/admin/Billing/Summary/components/Invoice';
import { Link as RouterLink } from 'react-router-dom';

import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import currency from 'currency.js';

import { sortBy } from 'lodash';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';

function Invoices({ customer, invoices, nextInvoice, refetch }) {
  const createTestInvoice = useAccountStripeCustomerMutate();
  const [showInvoice, setShowInvoice] = useState<string | null>(null);

  const { devMode } = useDevMode();
  const handleCreateTestInvoice = async () => {
    await createTestInvoice.mutateAsync({
      data: {
        action: 'createInvoice',
        data: {},
      },
    });
    window.location.reload();
  };

  // period start results in incorrect ordering
  const sorted = sortBy(invoices?.data ?? [], 'created_at').filter(
    invoice => invoice.status !== 'draft' && invoice.total !== 0,
  );

  return (
    <SettingsPanel
      leftChild={<Setting label={'Invoices'} body={'View statements.'} />}
      rightChild={
        sorted.length || nextInvoice ? (
          // <SimpleBar style={{ height: '100%' }}>
          <Table>
            {nextInvoice ? (
              <Invoice
                customer={customer}
                invoice={nextInvoice}
                refetch={refetch}
                onView={invoice => setShowInvoice(invoice)}
                isNext
                showBorder={!!sorted.length}
              />
            ) : null}
            {sorted.map((invoice, index) => {
              return (
                <Invoice
                  showBorder={sorted.length > 1 && index < sorted.length - 1}
                  customer={customer}
                  invoice={invoice}
                  refetch={refetch}
                  onView={invoice => setShowInvoice(invoice)}
                />
              );
            })}
          </Table>
        ) : (
          <Alert
            label={'No invoices'}
            body={'No invoices could be found associated with your account.'}
            color={'neutral'}
            reverse
          />
        )
      }
    />
  );

  // return (
  //   <div>
  //     <Dialog
  //       className={'h-full'}
  //       size={'4xl'}
  //       open={!!showInvoice}
  //       onClose={() => setShowInvoice(null)}
  //     >
  //       <InvoiceSummary yearMonth={showInvoice} />
  //     </Dialog>
  //     <span className={'text-lg font-bold'}>
  //       Invoices &nbsp;
  //       {devMode ? (
  //         <Link
  //           onClick={handleCreateTestInvoice}
  //           className={
  //             'font-sans text-content-accent decoration-content-accent'
  //           }
  //           variant="caption"
  //         >
  //           Create Test Invoice
  //         </Link>
  //       ) : null}
  //     </span>
  //
  //     {!invoices?.data?.length ? (
  //       <div>No Invoices</div>
  //     ) : (
  //       <div>
  //         {nextInvoice ? (
  //           <Invoice
  //             customer={customer}
  //             invoice={nextInvoice}
  //             refetch={refetch}
  //             onView={invoice => setShowInvoice(invoice)}
  //             isNext
  //           />
  //         ) : null}
  //         {sortBy(invoices.data, 'period_start')
  //           .reverse()
  //           .filter(invoice => invoice.status !== 'draft')
  //           .map(invoice => {
  //             return (
  //               <Invoice
  //                 customer={customer}
  //                 invoice={invoice}
  //                 refetch={refetch}
  //                 onView={invoice => setShowInvoice(invoice)}
  //               />
  //             );
  //           })}
  //       </div>
  //     )}
  //   </div>
  // );
}

const Invoice = ({
  customer,
  invoice,
  isNext = false,
  refetch,
  onView,
  showBorder = false,
}) => {
  const payNow = useAccountStripeCustomerMutate();

  const [showLines, setShowLines] = useState(false);

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  const handlePayNow = async () => {
    // charges the customer's existing default method
    // - does NOT go to the hosted invoice page (disable that? must have to just hide it, cannot actually disable it)
    if (customer.default_source?.substring(0, 5) !== 'card_') {
      alert('Please add a Credit Card first');
      return;
    }
    console.log('customer:', customer);
    // return;
    toggleBackdrop();
    await payNow.mutateAsync({
      data: {
        action: 'payNow',
        data: {
          invoice_id: invoice.id,
        },
      },
    });
    refetch();
    toggleBackdrop();
    // window.location.reload();
  };

  const isMobile = useMediaQuery('(max-width:450px)', { noSsr: true });

  // Invoice PDF URL: `invoice.invoice_pdf`
  // Receipt: only when `invoice.charge` exists, then get the `charge.receipt_url` (have to request the actual charge first!)

  // console.log('Invoice:', invoice);

  let name = 'Invoice';
  if (invoice.metadata.name) {
    name = invoice.metadata.name;
  } else if (isNext) {
    name = `Upcoming Invoice (${moment
      .utc(invoice.period_end * 1000)
      .format('MMM Do yyyy')})`;
  } else if (invoice.subscription) {
    name = `${moment.utc(invoice.period_end * 1000).format('MMMM Do yyyy')}`;
    // name = 'Subscription';
  } else if (invoice.number) {
    name = `Invoice: ${invoice.number}`;
  }

  return (
    <>
      <TableRow showBorder={showBorder}>
        <TableCell flex>
          <div>
            <button
              className={`font-medium`}
              onClick={() => onView(invoice.metadata.summaryYearMonth)}
            >
              {name}
            </button>
            {showLines &&
              invoice.lines.data.map(item => (
                <div className={'text-sm text-neutral-60'} key={item.id}>
                  {item.description}

                  {/*{currency(item.amount).divide(100).format()}*/}
                </div>
              ))}
          </div>
        </TableCell>
        {invoice.payment_intent?.last_payment_error ? (
          <Badge color={'negative'} variant={'fill'} reverse>
            Past Due - {invoice.payment_intent?.last_payment_error.message}
          </Badge>
        ) : null}
        <TableCell>
          <div className={'flex flex-col text-right'}>
            <span>{currency(invoice.total).divide(100).format()}</span>
            {!isMobile ? null : invoice.paid || invoice.paid_out_of_band ? (
              <a
                href={invoice.hosted_invoice_url}
                // component={'a'}
                target="_blank"
                // onClick={handlePayNow}
                className={'text-neutral-60 no-underline'}
                rel="noreferrer"
                // color={'success.dark'}
              >
                View Receipt
              </a>
            ) : isNext ? (
              <button
                className={'text-neutral-60 no-underline'}
                onClick={e => setShowLines(!showLines)}
              >
                {showLines ? 'Hide' : 'Show'} Details
              </button>
            ) : (
              <button
                // href={invoice.hosted_invoice_url}
                // component={'a'}
                // target="_blank"
                onClick={handlePayNow}
                // color={'error'}
                className={'text-positive-60 no-underline'}
              >
                Pay Now
              </button>
            )}
          </div>
        </TableCell>
        {isMobile ? null : (
          <TableCell>
            {invoice.paid || invoice.paid_out_of_band ? (
              <a
                href={invoice.hosted_invoice_url}
                // component={'a'}
                target="_blank"
                // onClick={handlePayNow}
                className={'text-neutral-60 no-underline'}
                rel="noreferrer"
                // color={'success.dark'}
              >
                View Receipt
              </a>
            ) : isNext ? (
              <button
                className={'text-neutral-60 no-underline'}
                onClick={e => setShowLines(!showLines)}
              >
                {showLines ? 'Hide' : 'Show'} Details
              </button>
            ) : (
              <button
                // href={invoice.hosted_invoice_url}
                // component={'a'}
                // target="_blank"
                onClick={handlePayNow}
                // color={'error'}
                className={'text-positive-60 no-underline'}
              >
                Pay Now
              </button>
            )}
          </TableCell>
        )}
        {/* <td className={'py-2 px-4'}>
          {customer.default_source === card.id ? 'Default' : null}
        </td>
        <td className={'py-2 px-4'}>
          <IconButtonDropdown
            align={'right'}
            variant={'ghost'}
            size={'sm'}
            children={<OptionsIcon fr={undefined} />}
            hideArrow
            menuItems={[
              {
                label: 'Set as default',
                onClick: handleSetDefault,
              },
              {
                label: 'Remove',
                onClick: handleRemove,
              },
            ]}
          />
        </td>*/}
      </TableRow>
    </>
  );

  // return (
  //   <>
  //     <CustomBackdrop {...CustomBackdropProps} />
  //     <Paper variant="outlined" sx={{ p: 1, mb: 1, maxWidth: '800px' }}>
  //       <Grid container columnSpacing={3} alignItems="center">
  //         <Grid item md={7}>
  //           {invoice.metadata.summaryYearMonth ? (
  //             <Link
  //               className={'cursor-pointer'}
  //               onClick={() => onView(invoice.metadata.summaryYearMonth)}
  //               // to={`/admin/billing/summary?yearmonth=${invoice.metadata.summaryYearMonth}`}
  //               // component={RouterLink}
  //             >
  //               {name}
  //             </Link>
  //           ) : (
  //             name
  //           )}
  //         </Grid>
  //         <Grid item md={2}>
  //           {currency(invoice.total).divide(100).format()}
  //           {!isNext && invoice.status === 'draft' ? (
  //             <Chip size="small" label="draft" color="warning" />
  //           ) : null}
  //         </Grid>
  //         <Grid item md={3}>
  //           {invoice.paid || invoice.paid_out_of_band ? (
  //             <Link
  //               href={invoice.hosted_invoice_url}
  //               component={'a'}
  //               target="_blank"
  //               // onClick={handlePayNow}
  //               className={'text-content-positive decoration-content-positive'}
  //               // color={'success.dark'}
  //             >
  //               Paid
  //             </Link>
  //           ) : isNext ? (
  //             <Link
  //               className={'text-content-accent decoration-content-accent'}
  //               onClick={e => setShowLines(!showLines)}
  //             >
  //               {showLines ? 'Hide' : 'Show'} Details
  //             </Link>
  //           ) : (
  //             <Link
  //               // href={invoice.hosted_invoice_url}
  //               // component={'a'}
  //               // target="_blank"
  //               onClick={handlePayNow}
  //               // color={'error'}
  //               className={'text-content-negative decoration-content-negative'}
  //             >
  //               Pay Now
  //             </Link>
  //           )}
  //         </Grid>
  //       </Grid>
  //       {!showLines ? null : (
  //         <>
  //           <Divider />
  //           <Box sx={{ mt: 1 }}>
  //             {invoice.lines.data.map(item => (
  //               <Box key={item.id}>
  //                 <Grid container columnSpacing={3} alignItems="center">
  //                   <Grid item md={7}>
  //                     {item.description}
  //                   </Grid>
  //                   <Grid item md={2}>
  //                     {currency(item.amount).divide(100).format()}
  //                   </Grid>
  //                 </Grid>
  //               </Box>
  //             ))}
  //           </Box>
  //         </>
  //       )}
  //     </Paper>
  //   </>
  // );
};

export default Invoices;

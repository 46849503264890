import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  ScheduleItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  LocalHospitalOutlined as LocalHospitalOutlinedIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities/utils';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../..';

import { InTemplate } from '../common/InTemplate';
import { Button, ButtonDropdown } from 'app/design-lib';
// import { USER_ADDABLE_COMPONENTS } from '../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../Strategies/components';

import { ModifyDialog, useKeyInputDialog } from '../Menu/ModifyDialog';
import { Plus } from 'iconoir-react';

////////////////////////
// THIS IS OLD (well, the InsertNode isnt, but the rest is)!!!!!
// - Use the "MenuButton" from "./nodes/common/MenuButton" instead?

const InsertMatchTextOptionNode = memo(({ data }) => {
  const {
    skipEditing,
    callflow: rootCallflow,
    setCallflow,
    modifyPath,
    index,
    requireAllowBefore, // always false
    requireAllowAfter,
    onClose,
    onAdd, // replaces handleAdd from below!
    templateParent,
    templateRef,
    matchTextData,
    fix,
    fixData,
    fixBySpliceIdx,
  } = data;

  // const scheduleId = matchTextData.moduleItem.data.schedule_id;
  const [showAdd, setShowAdd] = useState(null);
  const setShowAddWrap = setTo => () => setShowAdd(setTo);

  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const sharedFlowState = sharedFlow?.state;

  // const {
  //   Dialog: InputDialog,
  //   DialogProps: InputDialogProps,
  //   toggleOpen: toggleInputDialogOpen,
  // } = useKeyInputDialog();

  let icon = null,
    canMove = true;

  const handleClick = () => {
    // setAtPath(matchTextData.callflow, `schedules.${scheduleId}.categories`, [
    //   ...matchTextData.callflow.schedules[scheduleId].categories,
    //   {
    //     id: (Date.now() + 100).toString(),
    //     name: 'New Route',
    //     values: [],
    //   },
    // ]);
    // matchTextData.setCallflow(
    //   { ...matchTextData.callflow },
    //   { name: 'Add Schedule Flow' },
    // );
    setAtPath(matchTextData.moduleItem, `data.targets`, [
      ...matchTextData.moduleItem.data.targets,
      {
        match: {
          type: 'exact', // starts_with, contains, ends_with, exact
          data: {
            text: '',
            options: {
              trim: true,
              matchCase: false, // default is case-insensitive
            },
          },
        },
        callflow: INLINE_BLANK_CALLFLOW,
      },
    ]);
    setAtPath(
      matchTextData.callflow,
      matchTextData.modifyPath,
      matchTextData.moduleItem,
    );
    matchTextData.setCallflow(
      { ...matchTextData.callflow },
      { name: 'Add Match Text Flow' },
    );
  };

  return (
    <div
      style={{
        // width: 150,
        // height: 24,
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <Button onClick={handleClick} color={'accent'} startIcon={<Plus />}>
          Add match
        </Button>
        {/* {templateParent ? <InTemplate template={templateParent} /> : ''} */}
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    </div>
  );
});

const INLINE_BLANK_CALLFLOW = {
  id: 'inline',
  message_strategy: {
    type: 'blank',
    data: {
      modules: [],
    },
  },
};

export default InsertMatchTextOptionNode;

import { OwnerTableCell } from 'app/components/OwnerTableCell';
import { useMarkdownTranslate } from 'app/utilities/translate';
import * as React from 'react';
import { Callflow } from 'types/callflow';
import { User } from 'types/user';
import { Chip, Grid, InfoTooltip } from 'app/design';

interface CallRouteOwnerCellProps {
  owner: User;
  callRoute: Callflow;
}

const CallRouteOwnerCell = ({ owner, callRoute }: CallRouteOwnerCellProps) => {
  const { t } = useMarkdownTranslate();

  if (callRoute?.doc?.owner_type === 'account') {
    return <Chip label="Account" color="primary" size="small" />;
  }

  if (owner) {
    return <OwnerTableCell owner={owner} type={callRoute?.doc?.owner_type} />;
  }

  return (
    <Grid container columnSpacing={1}>
      {callRoute?.doc?.owner_id ? (
        // if orphaned (owner ID set, user does not exists)
        <>
          <Grid item>
            <Chip
              color={'error'}
              variant={'contained'}
              size={'small'}
              label={'Orphaned'}
            />
          </Grid>
          <Grid item>
            <InfoTooltip title={t('call_route.orphan.tooltip')} />
          </Grid>
        </>
      ) : (
        // unowned
        <>
          <OwnerTableCell owner={owner} />
        </>
      )}
    </Grid>
  );
};

export default CallRouteOwnerCell;

import * as Separator from '@radix-ui/react-separator';

interface DividerProps {
  className?: string;
}

export const Divider = ({ className = '' }: DividerProps) => {
  return (
    <Separator.Root
      className={`w-full rounded-full border border-neutral-20 h-[2px] ${className}`}
    />
  );
};

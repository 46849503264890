import { joiResolver } from '@hookform/resolvers/joi';
import { AddressAutocomplete } from 'app/components/AddressAutocomplete';
import { DialogInProgress, Typography, Box, Link } from 'app/design';
import { Link as RouterLink } from 'react-router-dom';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { useUpdatePhoneNumberPartial } from 'app/hooks/mutations/phoneNumbers';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { DialogBuilder, parseAndSetKazooMutationErrors } from 'app/utilities';
import Joi from 'joi';
import { omit, omitBy, pick, pickBy } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle } from 'app/design';
import { toast } from 'react-toastify';
import { E911 } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

interface EmergencyAddressDialogProps {
  onComplete: () => void;
  number: string;
  onCancel: () => void;
}

const schema = Joi.object({
  e911: Joi.object({
    caller_name: Joi.string().max(128).min(3).required(),
    // street_address: Joi.string().max(128).required(),
    // extended_address: Joi.string().max(128).optional().allow(''),
    // locality: Joi.string().max(128).required(),
    // region: Joi.string().max(128).required(),
    // postal_code: Joi.string().max(128).required(),
    // notification_contact_email: Joi.array()
    //   .items(Joi.string().email({ tlds: { allow: false } }))
    //   .optional(),
  }),
  address: Joi.object({
    streetLine: Joi.string().min(3).required(),
  })
    .unknown(true)
    .required(),
});

interface FormFields {
  e911: E911;
  address: any;
}

const EmergencyAddressDialog = ({
  onComplete,
  number,
  onCancel,
}: EmergencyAddressDialogProps) => {
  // needed for invalid geo encoding, not handled as a validation error
  const [genericError, setGenericError] = useImmer<string | undefined>(
    undefined,
  );
  const formMethods = useForm<FormFields>({ resolver: joiResolver(schema) });
  const { handleSubmit, setError, reset, setValue } = formMethods;

  const {
    data: phoneNumber,
    isLoading,
    isFetching,
    isError: numberError,
  } = usePhoneNumberQuery({
    id: number,
    options: {
      onSuccess: phoneNumber => {
        const address = phoneNumber.doc.e911;

        reset({
          address: {
            streetLine: address?.street_address ?? '', // line 1
            secondary: address?.extended_address ?? '',
            city: address?.locality ?? '',
            state: address?.region ?? '', // state
            zipcode: address?.postal_code ?? '',
            plus_four: '',
          },
          e911: pick(phoneNumber.doc.e911, [
            'caller_name',
            // 'street_address',
            // 'extended_address',
            // 'locality',
            // 'region',
            // 'postal_code',
          ]),
        });
      },
    },
  });
  const numberIsLoading = isLoading || isFetching;

  const updateNumber = useUpdatePhoneNumberPartial();

  const handleSave = async (form: FormFields) => {
    try {
      setGenericError(undefined);
      const updateNumberPromise = updateNumber.mutateAsync(
        {
          id: number,
          e911: {
            ...phoneNumber?.doc.e911,
            ...form.e911,
            street_address: form.address.streetLine, // line 1
            extended_address: form.address.secondary,
            locality: form.address.city,
            region: form.address.state, // state
            postal_code: form.address.zipcode,
          },
        },
        // {
        //   onSuccess: () => {
        //     onComplete();
        //   },
        //   onError: error => {
        //     // is generic error not specific to form field validation
        //     if (error.response?.data.message === 'invalid') {
        //       // TODO: are their other generic errors for this mutation?
        //       setGenericError('Location is not Geolocated');
        //     } else {
        //       parseAndSetKazooMutationErrors({
        //         response: error.response,
        //         setError,
        //       });
        //     }
        //   },
        // },
      );

      const resp = await toast.promise(updateNumberPromise, {
        pending: 'Updating phone number...',
        error: 'Error updating phone number.',
        success: 'Phone number updated!',
      });

      if (resp.status === 'success') {
        onComplete();
      }
    } catch (e: any) {
      // is generic error not specific to form field validation
      if (e.response?.data.message === 'invalid') {
        // TODO: are their other generic errors for this mutation?
        setGenericError('Location is not Geolocated');
      } else {
        parseAndSetKazooMutationErrors({
          response: e.response,
          setError,
        });
      }
    }
  };

  const existingAddress = phoneNumber?.doc.e911;
  const error = numberError || !phoneNumber;
  const label = existingAddress
    ? `${existingAddress.street_address} ${existingAddress.extended_address} ${existingAddress.locality}, ${existingAddress.region} ${existingAddress.postal_code}`
    : '';

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {updateNumber.isLoading || numberIsLoading ? (
        <DialogInProgress
          title={
            numberIsLoading
              ? 'Loading phone number...'
              : `Updating phone number...`
          }
        />
      ) : (
        <>
          <DialogTitle>
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span>Edit Emergency Address</span>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {error ? (
              <Typography color={'error'}>Error Loading number.</Typography>
            ) : (
              <FormProvider {...formMethods}>
                <HookFormTextField name={'e911.caller_name'} label={'Name'} />
                <br />
                <br />
                <AddressAutocomplete
                  // @ts-ignore
                  websiteKey={'120287158445923608'}
                  textfieldProps={{
                    placeholder: existingAddress ? label : 'Street Address',
                    label: 'Address',
                  }}
                  autocompleteProps={{
                    sx: {
                      '& .MuiAutocomplete-endAdornment': {
                        opacity: 0,
                      },
                    },
                  }}
                  onChange={value => {
                    // console.log('Address Chosen:', value);
                    if (!value) {
                      return;
                    }
                    setValue('address', value?.value, { shouldDirty: true });
                  }}
                />
                {formMethods.formState.errors?.address?.streetLine ? (
                  <Typography color={'error'} variant={'caption'}>
                    {'Address is required'}
                  </Typography>
                ) : existingAddress &&
                  formMethods.formState.dirtyFields.address ? (
                  <Typography
                    variant="caption"
                    sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                  >
                    current address: {label}
                  </Typography>
                ) : null}
                {/*<HookFormTextField
                  name={'e911.street_address'}
                  label={'Address Line 1'}
                  fullWidth
                />
                <br />
                <br />
                <HookFormTextField
                  name={'e911.extended_address'}
                  label={'Address Line 2'}
                  fullWidth
                />
                <br />
                <br />
                <HookFormTextField name={'e911.locality'} label={'City'} />
                <br />
                <br />
                <HookFormTextField name={'e911.region'} label={'State'} />
                <br />
                <br />
                <HookFormTextField
                  name={'e911.postal_code'}
                  label={'ZIP Code'}
                />*/}
              </FormProvider>
            )}
          </DialogContent>
          <DefaultDialogActions
            onSave={error ? undefined : handleSubmit(handleSave)}
            saveLabel={
              formMethods.formState.isDirty
                ? `Update Number`
                : 'No changes to update'
            }
            saveDisabled={!formMethods.formState.isDirty}
            onCancel={onCancel}
            errorMessage={genericError}
          />
        </>
      )}
    </Dialog>
  );
};

export const useEmergencyAddressDialog = DialogBuilder(EmergencyAddressDialog);

export default EmergencyAddressDialog;

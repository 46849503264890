import { gql } from 'graphql-request';

export default gql`
  query Query {
    contactLists {
      id
      name
      Contacts {
        id
        source
        schema
        info
      }
    }
  }
`;

import { useAuthSelector } from 'app/data/auth';
import menuQueryKeys from 'app/hooks/queries/menu/menuQueryKeys';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Menu } from 'types/menu';

interface QueryParams {
  id?: string;
  options?: Omit<
    UseQueryOptions<Menu, Error, Menu, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export const useMenuQuery = ({ id, options }: QueryParams) => {
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = menuQueryKeys.byId(id);

  const query = useQuery(
    queryKey,
    () => sdk.menu.query.menuById({ id }, { authToken: authState.auth_token }),
    {
      ...options,
      enabled: options?.enabled ?? !!id, // authState?.auth_token_decoded?.owner_id ? true : false,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useMenuQuery;

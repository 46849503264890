import React, { ReactNode } from 'react';
// import "./chip.css";
import { DialogContent, CircularProgress } from '@mui/material';

export interface DialogInProgressProps {
  title?: string | ReactNode;
}

const DialogInProgress = (props: DialogInProgressProps) => {
  return (
    <DialogContent>
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
        <br />
        {props?.title ?? null}
      </div>
    </DialogContent>
  );
};

export default DialogInProgress;

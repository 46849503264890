import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import EmojiPicker, { Emoji } from 'emoji-picker-react';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { Popper } from 'app/design';
import { RichTooltip } from 'app/components/RichTooltip';

import { usePipeUpdate } from 'app/pages/v2/common/sdk';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';

function General({ pipe, refetch }) {
  // shows the users for the user_ids of the pipe

  const pipeUpdate = usePipeUpdate();

  const [name, setName] = React.useState(pipe.metadata.name ?? '');
  const [emoji, setEmoji] = React.useState(pipe.metadata.emoji ?? '');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSave = async () => {
    await pipeUpdate.mutateAsync({
      id: pipe.id,
      data: {
        metadata: {
          ...pipe.metadata,
          emoji,
          name,
        },
      },
    });
    console.log('Updated users!');
    refetch();
  };

  const { data: phoneNumbersPage, isLoading: phoneNumbersLoading } =
    useListPhoneNumbersQuery({
      take: 500,
      filters: {
        // ids: pipe.phoneNumbers,
        id: pipe.key,
      },
      options: {},
    });
  const phoneNumbers = phoneNumbersPage?.phoneNumbers ?? [];
  const phoneNumber = phoneNumbers?.length ? phoneNumbers[0] : null;

  const [emojiPickerOpen, emojiPickerOpenSet] = useState(false);

  console.log('pipe phoneNumbers:', phoneNumbers);

  return (
    <div>
      <div className="mb-4">
        <div className="flex">
          <div className="">
            <RichTooltip
              open={emojiPickerOpen}
              onClose={() => emojiPickerOpenSet(false)}
              placement="bottom"
              arrow
              content={
                <EmojiPicker
                  previewConfig={{ showPreview: false }}
                  onEmojiClick={e => {
                    console.log('emoji:', e?.unified, e);
                    setEmoji(e.unified);
                    emojiPickerOpenSet(false);
                  }}
                />
              }
            >
              <span onClick={e => emojiPickerOpenSet(true)}>
                <Emoji unified={emoji || '1f937'} size={25} />
              </span>
            </RichTooltip>
          </div>
          <div className="ml-4">
            <div className="text-lg">
              <input
                placeholder="Unnamed"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <button onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default General;

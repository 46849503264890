import jwt from 'jsonwebtoken';
import md5 from 'md5';
import { patch, put, del, post, postBase64 } from './old_methods';

export { default as buildResourceUrl } from './buildResourceUrl';
export { buildGetDefault, getResourceDefault } from './getDefault';
export { buildPatchDefault, patchResourceDefault } from './patchDefault';
export { buildDeleteDefault, deleteResourceDefault } from './deleteDefault';
export { buildPutDefault, putResourceDefault } from './putDefault';
export { buildPostDefault, postResourceDefault } from './postDefault';
export { buildPostBase64, postResourceBase64 } from './postBase64';
export { buildPostVmboxMessage } from './postVmboxMessage';
export { getProvisionerPhoneModel } from './getProvisionerPhoneModel';

export { md5, patch, put, del, post, postBase64 };

export const jwtDecode = auth_token => {
  return jwt.decode(auth_token);
};

import React from 'react';
import useToggleReducer from './useToggleReducer';

// const DialogComponent = () => {
//   return null;
// };

interface Dialog {
  initialOpen?: boolean;
  onComplete?: (args?: any) => void;
}

export const useDialogBuilder = DialogComponent => {
  // const DialogComponent = (InputComponent);
  const DialogWrapper = props => {
    // console.log('props wrapper', props);
    if (!props.open) {
      return null;
    }
    return React.createElement(DialogComponent, props);
  };

  const useDialog = (props: Dialog = { initialOpen: false }) => {
    const { initialOpen } = props;
    const [open, toggleOpen] = useToggleReducer(initialOpen);

    const DialogProps = {
      open,
      onCancel: toggleOpen,
    };

    return {
      toggleOpen,
      Dialog: DialogWrapper,
      DialogProps,
    };
  };

  return useDialog;
};

export default useDialogBuilder;

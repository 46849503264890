import React, { useEffect } from 'react';

import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import constants from 'app/constants';

// Heap analytics

// console.log('Heap ID:', constants.env.REACT_APP_HEAP_ID);
// @ts-ignore
const isHeap = window.heap && constants.env.REACT_APP_HEAP_ID ? true : false;

// @ts-ignore
if (isHeap) {
  // @ts-ignore
  window.heap.load(constants.env.REACT_APP_HEAP_ID);
}

export const HeapComponent = () => {
  const { loggedIn, auth_token_decoded } = useAuthSelector();

  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();

  useEffect(() => {
    if (isHeap && auth_token_decoded?.owner_id && userData) {
      // console.log('heap identifying');
      // @ts-ignore
      window.heap.identify(
        `${auth_token_decoded?.account_id}-${auth_token_decoded?.owner_id}`,
      );
      // @ts-ignore
      window.heap.addUserProperties({
        email: userData.doc.email || userData.doc.username,
        first_name: userData.doc.first_name,
        last_name: userData.doc.last_name,
        name: userData.extra?.fullName,
        account_id: auth_token_decoded?.account_id,
        owner_id: auth_token_decoded?.owner_id,
      });
    }
  }, [auth_token_decoded?.owner_id, userData]);

  return null;
};

// @ts-ignore
const heapLib = heap;

export default heapLib; // from index.html, window.heap should exist

import { gql } from 'graphql-request';

export default gql`
  mutation keyValueWrite($data: JSON) {
    keyValueWrite(data: $data) {
      success
      message
      data
    }
  }
`;

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import contactQueryKeys from 'app/hooks/queries/contact/contactQueryKeys';

export const useDeleteContact = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, string, unknown>(
    (id: string) => sdk.contact.mutate.delete({ id }, authState?.auth_token),
    {
      onSuccess: resp => {
        // this always runs, even if the caller provides an onSuccess
        queryClient.invalidateQueries(contactQueryKeys.all);
        // console.log('val:', val);
      },
    },
  );
};

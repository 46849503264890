import React, { useEffect, useState } from 'react';

export interface SquareProgressProps {}

export const SquareProgress = ({ size = 'md' }) => {
  const innerPositons =
    size === 'md'
      ? [
          'translate-x-4 translate-y-0',
          'translate-x-4 translate-y-4',
          'translate-x-0 translate-y-4',
          'translate-x-0 translate-y-0',
        ].reverse()
      : [
          'translate-x-2 translate-y-0',
          'translate-x-2 translate-y-2',
          'translate-x-0 translate-y-2',
          'translate-x-0 translate-y-0',
        ].reverse();
  // const outerPositions = [
  //   'rotate-[45deg]',
  //   'rotate-[135deg]',
  //   'rotate-[225deg]',
  //   'rotate-[315deg]',
  // ];
  const [positionIndex, setPositionIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      // start animation immediately
      setPositionIndex(1);
    }, 100);

    const interval = setInterval(() => {
      setPositionIndex(prev => (prev <= 2 ? prev + 1 : 0));
    }, 700);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`grid place-items-center ${
        size === 'md' ? 'h-20 w-20' : 'h-10 w-10'
      } aspect-square overflow-hidden`}
    >
      <div
        className={`relative animate-spin-slow  ${
          size === 'md' ? 'h-10 w-10 p-1' : 'h-5 w-5 p-0.5'
        }  aspect-square shadow-inner bg-transparency-10 transition-transform duration-700 ease-in-out`}
      >
        <div
          className={`${innerPositons[positionIndex]} ease-in-out  ${
            size === 'md' ? 'h-4 w-4' : 'h-2 w-2'
          } bg-accent-60 absolute aspect-square transition-transform duration-300 delay-200`}
        ></div>
      </div>
    </div>
  );
};

export default SquareProgress;

import { useAuthSelector } from 'app/data/auth';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import groupQueryKeys from 'app/hooks/queries/group/groupQueryKeys';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { DirectoryUpdatePartialDoc } from 'types/directory';
import { GroupUpdatePartialDoc } from 'types/group';

export const useUpdateGroupPartial = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, GroupUpdatePartialDoc, unknown>(
    (groupUpdatePartialDoc: GroupUpdatePartialDoc) =>
      sdk.group.mutate.updatePartial(
        groupUpdatePartialDoc,
        authState?.auth_token,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(groupQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

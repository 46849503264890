/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

// import {
//   Grid,
//   Button,
//   ButtonGroup,
//   Link,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Divider,
//   Typography,
// } from 'app/design';

import * as Sentry from '@sentry/react';

export const ErrorBoundary = ({
  title = 'Uh-oh, unexpected error encountered!',
  subtitle = 'Please refresh the page',
  component = null,
  children,
}) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrorFallback
          title={title}
          subtitle={subtitle}
          component={component}
          error={error}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export function ErrorFallback({
  title = 'Uh-oh, unexpected error encountered!',
  subtitle = 'Please refresh the page',
  component,
  error,
}) {
  // Handles failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  if (component) {
    return component({ error });
  }

  return (
    <div className="w-full h-full flex justify-center items-center p-24">
      <div className="text-left max-w-[480px]">
        <div className="text-content">{title}</div>
        <div className="text-sm text-neutral-500">{subtitle}</div>
        <div className="mt-4 text-sm text-neutral-300">{error?.message}</div>
      </div>
    </div>
  );
}

// export default ErrorFallback;

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export default ErrorBoundary;

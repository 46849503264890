// Create URL from [resourceApiPathname: users]
// - expects auth_token for getting account_id (from decoded jwt)
// - can accept options for encoding the ID (for phoneNumer) or skipping the "id" (for account)
import { jwtDecode } from './kazoo';

const buildItemResourceUrl = (
  resourceApiPathname,
  itemName,
  resourceId,
  itemId,
  auth_token,
  buildUrlOpts,
) => {
  const { account_id } = jwtDecode(auth_token);

  // Make sure to add skipId to create/put
  if (buildUrlOpts?.skipId) {
    return `/accounts/${account_id}/${resourceApiPathname}/${resourceId}/${itemName}`;
  }

  return `/accounts/${account_id}/${resourceApiPathname}/${resourceId}/${itemName}/${itemId}`;
};

export default buildItemResourceUrl;

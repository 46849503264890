import { Avatar } from 'app/design-lib/components/Avatar';
import { Size } from 'app/design-lib/types';
import React, { ReactElement, ReactNode } from 'react';
import { AvatarSize } from 'app/design-lib/components/Avatar/Avatar';

export interface AvatarGroupProps {
  children: ReactNode;
  size?: AvatarSize;
  condensed?: boolean;
}

const limit = 4;

const AvatarGroup = ({
  children,
  size = 'md',
  condensed,
}: AvatarGroupProps) => {
  const count = React.Children.count(children);

  if (condensed) {
    return (
      <>
        {React.Children.map(children, (child, index) => {
          if (index > 0) {
            return <></>;
          }

          return (
            <div className={`rounded-full w-min relative`}>
              {React.cloneElement(child as ReactElement, {
                size,
              })}
              <div
                className={`rounded-full ${
                  size === 'sm'
                    ? 'text-micro h-3 w-3 -bottom-1 -right-1'
                    : 'text-xs h-4 w-4 -bottom-0.5 -right-1'
                } border border-white text-white bg-gray-60 grid place-items-center font-medium absolute `}
              >
                <span className={``}>{count}</span>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className={`flex space-x-1 items-center`}>
      <div
        className={`flex w-min w-full ${
          size === 'sm'
            ? '-space-x-2'
            : size === 'md'
            ? '-space-x-2.5'
            : '-space-x-3'
        }`}
      >
        {React.Children.map(children, (child, index) => {
          if (index > limit - 1) {
            return null;
          }

          return (
            <div className={`rounded-full p-0.5 bg-white relative`}>
              {React.cloneElement(child as ReactElement, { size })}
            </div>
          );
        })}
      </div>
      {limit < count ? (
        <span
          className={`text-gray-60 ${
            size === 'sm' ? 'text-xs' : 'text-sm'
          } font-medium `}
        >{`+ ${count - limit} more`}</span>
      ) : null}
    </div>
  );
};
export default AvatarGroup;

import { gql } from 'graphql-request';

export default gql`
  query Query($skip: Int, $take: Int, $filters: JSON, $orderBy: JSON) {
    phoneNumbers(
      skip: $skip
      take: $take
      filters: $filters
      orderBy: $orderBy
    ) {
      totalCount
      phoneNumbers {
        id
        doc
        createdAt

        Callflow {
          id
          doc
          Owner {
            id
            doc
            extra
          }
        }
      }
    }
  }
`;

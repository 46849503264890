import React from 'react';
import { Link, Typography as MuiTypography } from '@mui/material';

export interface TypographyProps {}

const Typography = React.forwardRef((props: any, ref) => {
  if (props.link) {
    const { link, linkProps, ...rest } = props;
    return (
      <MuiTypography ref={ref} {...rest}>
        <Link component={link} to={props.to} color="inherit" {...linkProps}>
          {props.children}
        </Link>
      </MuiTypography>
    );
  }
  return <MuiTypography ref={ref} {...props} />;
});

export default Typography;

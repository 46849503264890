import { gql } from 'graphql-request';

export default gql`
  query VmboxMessages($filters: JSON, $skip: Int, $take: Int, $orderBy: JSON) {
    vmboxMessages(
      filters: $filters
      skip: $skip
      take: $take
      orderBy: $orderBy
    ) {
      totalCount
      skip
      take
      vmboxMessages {
        id
        doc
        listing
        vmbox_id
        account_id
        vmbox_message_id
      }
    }
  }
`;

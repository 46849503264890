import { AddPhonesFormFields } from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/types';
import { validateEmail } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard/components/NotificationEmailsDialog/NotificationEmailsDialog';
import { setAtPath } from 'app/utilities';
import { UnpackNestedValue } from 'react-hook-form';
import { Device } from 'types/device';
import { Extension } from 'types/extension';

const addBulkPhonesFormResolver =
  (
    existingDevices: Device[],
    setFormConflicts: ({ formMacAddresses }) => void,
  ) =>
  (values: UnpackNestedValue<AddPhonesFormFields>, context, options) => {
    const errors = {};
    // add existing mac addresses to tracking obj
    const exisingMacAddresses = {},
      formMacAddresses: { [key: string]: number } = {};
    existingDevices.forEach(device => {
      if (device.doc.mac_address)
        exisingMacAddresses[device.doc.mac_address] = true;
    });

    values.phones.forEach((phoneFields, index) => {
      // track field presence IDs for conflicts
      const currentMacAddress = phoneFields.mac_address;
      if (currentMacAddress) {
        formMacAddresses[currentMacAddress] =
          (formMacAddresses[currentMacAddress] ?? 0) + 1;

        if (formMacAddresses[currentMacAddress] > 1) {
          // @ts-ignore
          errors.conflictingField = true;
        }
      }

      // skip saved fields for re-validation on error
      if (phoneFields.save_status === 'error' || !phoneFields.save_status) {
        // validateBrandAndModel(phoneFields, index, errors);
        validateName(phoneFields, index, errors);
        validateMacAddress(phoneFields, index, errors, exisingMacAddresses);
      }
    });

    setFormConflicts({ formMacAddresses });

    return { values, errors };
  };

const macAddressRegex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;

const validateMacAddress = (
  phoneFields,
  index,
  errors,
  existingMacAddresses,
) => {
  if (phoneFields.device_type.includes('application')) return;

  // check if valid mac address
  if (!macAddressRegex.test(phoneFields.mac_address.toUpperCase())) {
    setAtPath(
      errors,
      `phones.${index}.mac_address.message`,
      'Invalid MAC Address',
    );
  }

  // see if mac address in use
  if (existingMacAddresses[phoneFields.mac_address.replaceAll(':', '')]) {
    setAtPath(
      errors,
      `phones.${index}.mac_address.message`,
      'MAC Address is unavailable: used by existing phone',
    );
  }
};

const validateRequired = (value: string) => {
  return !value.trim();
};

const validateName = (phoneField, index, errors) => {
  // name
  //  1. required
  // TODO: min length or other req?

  if (validateRequired(phoneField.name)) {
    // name not set, add error message
    setAtPath(errors, `phones.${index}.name.message`, `Phone name is required`);
  }
};

const validateBrandAndModel = (phoneFields, index, errors) => {
  if (!phoneFields.provision) {
    // brand/ model not set, add error message
    setAtPath(
      errors,
      `phones.${index}.provision.message`,
      `Brand / Model is required`,
    );
  }
};

export default addBulkPhonesFormResolver;

import { IconButton } from '@mui/material';
import {
  Button,
  CircularProgress,
  Grid,
  InfoTooltip,
  TextField as InputTextField,
  Typography,
} from 'app/design';
import { TextField } from 'app/design-lib';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
} from 'app/design/icons-material';
import {
  AddPhonesFormFields,
  PhoneFields,
} from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/types';
import BrandAndModelField from 'app/components/CreatePhonesBulk/components/PhoneFieldsRow/components/BrandModelField/BrandModelField';
import { MacAddressField } from 'app/components/CreatePhonesBulk/components/PhoneFieldsRow/components/MacAddressField';
import { OwnerIdField } from 'app/components/CreatePhonesBulk/components/PhoneFieldsRow/components/OwnerIdField';
import { startCase } from 'lodash';
import React from 'react';
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { PhoneTypeField } from 'app/components/CreatePhonesBulk/components/PhoneFieldsRow/components/PhoneTypeField';
import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import { User, UserPrivLevel } from 'types/user';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

interface PhoneFieldsRowProps {
  index: number;
  phoneFields: PhoneFields;

  users: User[];
  register: UseFormRegister<AddPhonesFormFields>;
  onRemove: () => void;
  formErrors: any;
  control: Control<AddPhonesFormFields, object>;
  formSaving: boolean;
  // formEmails: { [key: string]: number };
  formMacAddresses: { [key: string]: number };
  setValue: UseFormSetValue<AddPhonesFormFields>;
}

const PhoneFieldsRow = ({
  index,
  phoneFields,
  register,
  onRemove,
  formErrors,
  formSaving,
  setValue,
  formMacAddresses,
  control,
  users,
}: // formEmails,
// formPresenceIds,
PhoneFieldsRowProps) => {
  const fieldName = `phones.${index}`;
  const status = phoneFields.save_status;

  const disabled =
    formSaving || status === 'saved' || status === 'partial_error';

  const deviceType = phoneFields.device_type;

  const conflictingMacAddress = formMacAddresses[phoneFields.mac_address] > 1;
  // const conflictingPresenceId = formPresenceIds[phoneFields.presence_id] > 1;
  const isApp = deviceType.includes('application');

  return (
    <div className={`grid grid-cols-4 w-full gap-2`}>
      <div className={`col-span-4 sm:col-span-2`}>
        <TextField
          label="Phone name"
          // @ts-ignore
          {...register(`${fieldName}.name`)}
          // size="small"
          // fullWidth
          hasError={!!formErrors.phones?.[index]?.name}
          helperText={formErrors.phones?.[index]?.name?.message}
          disabled={disabled}
        />
      </div>
      <div className={`col-span-4 sm:col-span-2`}>
        <PhoneTypeField
          setValue={setValue}
          control={control}
          // phoneFields={phoneFields}
          index={index}
          disabled={disabled}
          formErrors={formErrors}
        />
      </div>
      <div className={`${isApp ? 'col-span-4' : 'col-span-4 sm:col-span-2'}`}>
        <OwnerIdField
          setValue={setValue}
          phoneFields={phoneFields}
          index={index}
          disabled={disabled}
          formErrors={formErrors}
          users={users}
        />
      </div>
      {isApp ? null : (
        <div className={`col-span-4 sm:col-span-2`}>
          <MacAddressField
            type={deviceType}
            index={index}
            formErrors={formErrors}
            disabled={disabled}
            conflict={conflictingMacAddress}
            control={control}
          />
        </div>
      )}
    </div>
  );

  // return (
  //   <Grid
  //     container
  //     spacing={3}
  //     className={'items-end'}
  //     sx={
  //       {
  //         // marginBottom: 2, pb: 2, borderBottom: '1px solid #ccc'
  //       }
  //     }
  //   >
  //     <Grid item md={1}>
  //       <div className={'flex space-x-1 items-center font-medium text-lg'}>
  //         <IconButton onClick={onRemove} size="small" disabled={disabled}>
  //           <CloseIcon />
  //         </IconButton>
  //         <span>{index + 1}.</span>
  //       </div>
  //       <div className={'flex items-center space-x-1'}>
  //         <span className={'text-sm font-medium'}>
  //           {status ? startCase(status.replace('_', ' ')) : ''}
  //         </span>
  //         {status === 'saving' ? (
  //           <CircularProgress size={20} />
  //         ) : status === 'saved' ? (
  //           <CheckIcon color={'success'} />
  //         ) : status === 'partial_error' ? (
  //           <>
  //             <ErrorIcon color={'warning'} />
  //             <br />
  //             <Grid container spacing={1} wrap={'nowrap'}>
  //               <Grid item>
  //                 <Typography variant={'caption'}>Failed to save:</Typography>
  //               </Grid>
  //               <Grid item>
  //                 <InfoTooltip
  //                   size={'small'}
  //                   title={
  //                     'Phone creation was successful - see phone page to resolve below error'
  //                   }
  //                 />
  //               </Grid>
  //             </Grid>
  //             {phoneFields.save_error.callflow_saved ? null : (
  //               <>
  //                 <Typography variant={'caption'} color={'error'}>
  //                   - Call Routing
  //                 </Typography>
  //                 <br />
  //               </>
  //             )}
  //           </>
  //         ) : status === 'error' ? (
  //           <ClearIcon color={'error'} />
  //         ) : null}
  //       </div>
  //     </Grid>
  //     <Grid item md={11} sx={{ flex: 1 }}>
  //       <Grid container spacing={1} sx={{}}>
  //         <Grid item md={4}>
  //           <TextField
  //             label="Friendly Name of Phone"
  //             // @ts-ignore
  //             {...register(`${fieldName}.name`)}
  //             // size="small"
  //             // fullWidth
  //             hasError={!!formErrors.phones?.[index]?.name}
  //             helperText={formErrors.phones?.[index]?.name?.message}
  //             disabled={disabled}
  //           />
  //         </Grid>
  //         <Grid className={'items-end  flex'} item md={isApp ? 3 : 2}>
  //           <OwnerIdField
  //             setValue={setValue}
  //             phoneFields={phoneFields}
  //             index={index}
  //             disabled={disabled}
  //             formErrors={formErrors}
  //           />
  //         </Grid>
  //         <Grid item md={3}>
  //           <PhoneTypeField
  //             setValue={setValue}
  //             control={control}
  //             // phoneFields={phoneFields}
  //             index={index}
  //             disabled={disabled}
  //             formErrors={formErrors}
  //           />
  //         </Grid>
  //         {isApp ? null : (
  //           <Grid item md={3}>
  //             <MacAddressField
  //               type={deviceType}
  //               index={index}
  //               formErrors={formErrors}
  //               disabled={disabled}
  //               conflict={conflictingMacAddress}
  //               control={control}
  //             />
  //           </Grid>
  //         )}
  //       </Grid>
  //     </Grid>
  //     {/* <Grid item md={4}>
  //       <BrandAndModelField
  //         type={deviceType}
  //         disabled={disabled}
  //         index={index}
  //         phoneFields={phoneFields}
  //         setValue={setValue}
  //         formErrors={formErrors}
  //         control={control}
  //       />
  //     </Grid> */}
  //   </Grid>
  // );
};

export default PhoneFieldsRow;

import { PlateRenderLeafProps } from '@udecode/plate';
import { Typography } from 'app/design';
import { KeywordContext } from 'app/components/SlateSearch/SlateSearch';
import { useContext } from 'react';
import { matchSorter } from 'match-sorter';
import { Menu, MenuItem, Tooltip } from '@mui/material';

interface MarkKeywordProps extends PlateRenderLeafProps {}

const MarkValue = (props: MarkKeywordProps) => {
  const {
    attributes,
    children,
    nodeProps,
    element,
    prefix,
    as,
    onClick,
    renderLabel,
  } = props;

  const keywords = useContext(KeywordContext);
  // remove exclude if present for keyword check
  const keyword: string = children.props.leaf.value.slice(
    children.props.leaf.value.startsWith('-') ? 1 : 0,
  );
  const value: string = children.props.leaf.text;
  const keywordValues = keywords[keyword]?.values;
  const keywordHasValues = !!keywordValues;

  const validKeyword = !!keywords[keyword];
  const validValue = keywordHasValues
    ? keywords[keyword].values?.includes(value)
    : true;

  let valueHelp: any[] = [];
  if (!validValue && keywordHasValues) {
    valueHelp = matchSorter(keywords[keyword]?.values!, value);
  }

  return (
    <Tooltip
      arrow
      title={
        validKeyword && !validValue ? (
          <span>
            Invalid Value:
            <br />
            {valueHelp?.length ? (
              <>
                Did you mean...
                <br />
                <br />
                {valueHelp.map(value => (
                  <div>{value}</div>
                ))}
              </>
            ) : (
              <>
                No values match.
                <br />
                <br />
                Try:
                {keywordValues?.map(value => (
                  <div>{value}</div>
                ))}
              </>
            )}
          </span>
        ) : (
          ''
        )
      }
      open={validKeyword}
    >
      <span
        {...attributes}
        {...nodeProps}
        style={{
          color: keywordHasValues
            ? validValue
              ? 'green'
              : 'darkred'
            : 'green',
          fontWeight: 700,
          // background: 'lightgray',
          borderBottomRightRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default MarkValue;

// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { sdk } from 'app/sdk';

const setupAccountCallerId = async ({
  auth_token,
  ptn,
  accountId,
  companyName,
}) => {
  const accountResult = await sdk.account.mutate.updatePartial(
    {
      id: accountId,
      caller_id: {
        external: {
          number: ptn?.length > 4 ? ptn : undefined,
        },
        emergency: {
          number: ptn?.length > 4 ? ptn : undefined,
        },
        internal: {
          number: ptn,
          name: companyName?.substr(0, 14),
        },
      },
    },
    auth_token,
  );
  console.log('accountResult:', accountResult);
  if (accountResult.status !== 'success') {
    throw 'Failed saving account';
  }

  // const accountId = accountResult.data.id;
  // return { accountId };
};

export default setupAccountCallerId;

import { joiResolver } from '@hookform/resolvers/joi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ConfirmationDialog } from 'app/components/DefaultDialogActions/DefaultDialogActions';
import { ScheduleStructureEditor } from 'app/components/ScheduleCreateDialog/components/ScheduleStructureEditor';
import { ScheduleTemplateEditor } from 'app/components/ScheduleCreateDialog/components/ScheduleTemplateEditor';
import { ScheduleTemplateSelect } from 'app/components/ScheduleCreateDialog/components/ScheduleTemplateSelect';
import {
  SCHEDULE_TEMPLATES,
  ScheduleTemplate,
} from 'app/components/ScheduleCreateDialog/scheduleTemplates';
import { Button, Dialog } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateCallflowPartial } from 'app/hooks/mutations/callflow';
import {
  useCreateSchedule,
  useUpdateSchedulePartial,
} from 'app/hooks/mutations/schedule';
import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import { useToggleReducer } from 'app/utilities';
import Joi from 'joi';
import { cloneDeep, isEqual } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  name: Joi.string().max(128).required(),
  template: Joi.string().required(),
  times: Joi.array().required(),
  timezone: Joi.string().optional(),
});

export interface ScheduleCreateForm {
  name: string;
  template: ScheduleTemplate;
  times: any[];
  timezone?: string;
}

// interface declaring which props are required/allowed
interface ScheduleEditorContentProps {
  scheduleId?: string;
  name?: string; // for create
  doc_additional?: any;
  owner_id?: string;
  owner_type?: string;
  onCancel: () => void;
  onComplete: (scheduleId?: string) => void;
}

interface ScheduleEditorDialog {
  ContentProps: ScheduleEditorContentProps;
  DialogProps: AnimationDialogProps;
}

const ScheduleEditorDialog = ({
  ContentProps,
  DialogProps,
}: ScheduleEditorDialog) => {
  return (
    <Dialog
      size={'3xl'}
      open={DialogProps.open}
      onClose={ContentProps.onCancel}
    >
      <ScheduleEditorDialogContent {...ContentProps} />
    </Dialog>
  );
};

const ScheduleEditorDialogContent = ({
  scheduleId,
  name,
  doc_additional = {},
  owner_id,
  owner_type,
  onCancel,
  onComplete,
}: ScheduleEditorContentProps) => {
  const [isEditMode, SetEditMode] = useState(!!scheduleId);
  const [editStructure, toggleEditStructure] = useToggleReducer(false);
  const [showConfirmationDialog, toggleConfirmationDialog] =
    useToggleReducer(false);

  const {
    data: scheduleResp,
    isLoading: scheduleIsLoading,
    error: scheduleError,
  } = useListCallflowsQuery({
    filters: {
      context: {
        ids: [scheduleId],
        types: ['schedule'],
      },
    },
    options: {
      onSuccess: callflows => {
        const [callflow] = callflows.callflows;
        reset({
          name: callflow.doc.name,
          template:
            callflow.doc.strategy.data.template ?? ScheduleTemplate.Restaurant,
          times: callflow.doc.strategy.data.times,
          timezone: callflow.doc.strategy.data.timezone,
        });
      },
      enabled: !!scheduleId,
    },
  });

  const [schedule] = scheduleResp?.callflows ?? [];

  const formMethods = useForm<ScheduleCreateForm>({
    defaultValues: {
      name: schedule?.doc.name ?? name ?? 'Schedule',
      template:
        schedule?.doc.strategy.data.template ?? ScheduleTemplate.Restaurant,
      times:
        schedule?.doc.strategy.data.times ??
        SCHEDULE_TEMPLATES[ScheduleTemplate.Restaurant].value,
      timezone: schedule?.doc.strategy.data.timezone,
    },
    resolver: joiResolver(schema), // pass in custom validation
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    watch,
    reset,
  } = formMethods;

  const createSchedule = useCreateSchedule();
  const updateSchedule = useUpdateSchedulePartial();
  const updateCallflow = useUpdateCallflowPartial();

  // only need to update callflow if schedule is being used and
  // times have changed (added or removed) or timezone changed
  const callflowsNeedUpdate =
    !!schedule?.usedBy.length &&
    (!isEqual(
      schedule.doc.strategy.data.times.map(time => time.id),
      watch('times').map(time => time.id),
    ) ||
      !isEqual(schedule.doc.strategy.data.timezone, watch('timezone')));

  const onSubmit = async (scheduleForm: ScheduleCreateForm) => {
    if (scheduleId) {
      const promises: Promise<any>[] = [];
      const resp = await updateSchedule.mutateAsync({
        ...doc_additional,
        id: scheduleId,
        name: scheduleForm.name ?? '',
        owner_id,
        owner_type,
        strategy: {
          ...schedule?.doc.strategy,
          data: {
            ...schedule?.doc.strategy.data,
            timezone: scheduleForm.timezone,
            template: scheduleForm.template,
            times: cloneDeep(scheduleForm.times),
          },
        },
      });

      // const scheduleResp = await toast.promise(updateSchedulePromise, {
      //   pending: 'Updating Schedule...',
      //   success: 'Schedule updated!',
      //   error: 'Error updating Schedule',
      // });

      if (resp.success !== true) {
        throw new Error('error updating schedule');
      }

      // only update schedules if times have changed (added or removed)
      if (callflowsNeedUpdate) {
        console.log('TIMES CHANGED - UPDATING');
        for (const { value: callflow } of schedule.usedBy) {
          const updateCallflowPromise = updateCallflow.mutateAsync({
            id: callflow.id,
            strategy: callflow.doc.strategy,
          });

          promises.push(updateCallflowPromise);
        }

        await Promise.all(promises);
        // await toast.promise(Promise.all(promises), {
        //   pending: 'Updating Call Routes...',
        //   success: 'Call Routes updated!',
        //   error: 'Error updating Call Routes',
        // });
      }
      // redirect to Group
      // router.push(`/admin/schedule/edit/${resp.data.id}`);
    } else {
      // @ts-ignore
      const createSchedulePromise = createSchedule.mutateAsync({
        ...doc_additional,
        name: scheduleForm.name ?? '',
        // owner_id: authenticatedUser?.id!,
        // owner_type: 'user', // should be "menu" instead? ...that is where it is used, NOT owned??
        type: 'schedule',
        owner_id,
        owner_type,
        // is_account_default,
        numbers: [],
        flow: {
          module: 'callflow',
          data: { id: 'placeholder' },
        },
        metaflow: {},
        strategy: {
          type: 'schedule', // TODO: switch to a dedicated "schedule" handler like GenericDefault?
          data: {
            timezone: scheduleForm.timezone,
            template: scheduleForm.template,
            times: cloneDeep(scheduleForm.times),
          },
        },
      });

      // toast.promise(createSchedulePromise, {
      //   pending: 'Creating Schedule...',
      //   success: 'Schedule created!',
      //   error: 'Error creating Schedule',
      // });

      const resp = await createSchedulePromise;
      // if (resp.success === true) {
      //   // redirect to Group
      //   // router.push(`/admin/schedule/edit/${resp.data.id}`);
      //   onComplete(resp.data.id);
      // }
    }
  };

  const handleComplete = () => {
    // get Id from mutation on clicking complete
    const mutation = updateSchedule.data ?? createSchedule.data;
    console.log('id', mutation.data);
    onComplete(mutation.data.id);
  };

  const handleSave = () => {
    if (isEditMode) {
      handleSubmit(onSubmit)();
    } else {
      SetEditMode(true);
    }
  };

  const handleCancel = () => {
    // if (isEditMode) {
    //   SetEditMode(false);
    // } else {
    updateSchedule.reset();
    createSchedule.reset();
    onCancel();
    // }
  };

  const handleChangeTemplate = () => {
    SetEditMode(false);
  };

  const errorLoadingSchedule = scheduleError || (!schedule && scheduleId);

  return (
    <>
      <ConfirmationDialog
        open={showConfirmationDialog}
        title="Confirm Update Call Routes"
        label={
          'For schedule changes to take effect, we need to update all Call Routes that use this schedule'
        }
        onConfirm={() => {
          handleSave();
          toggleConfirmationDialog();
        }}
        onCancel={toggleConfirmationDialog}
        onClose={toggleConfirmationDialog}
      />
      <GenericMutationDialogContent
        onComplete={handleComplete}
        onCancel={onCancel}
        startDirty
        onSubmit={callflowsNeedUpdate ? toggleConfirmationDialog : handleSave}
        onSuccessLabel={
          isEditMode
            ? `Schedule ${scheduleId ? 'Updated' : 'Created'}`
            : 'Choose your schedule'
        }
        isLoadingLabel={`${scheduleId ? 'Updating' : 'Creating'} Schedule`}
        title={
          <div className={'flex space-x-2 items-center'}>
            {isEditMode && !editStructure && !scheduleId ? (
              <Button size={'sm'} onClick={handleChangeTemplate}>
                <ArrowBackIcon />
              </Button>
            ) : null}
            {`${scheduleId ? 'Edit' : 'Create'} Schedule`}
          </div>
        }
        mutation={scheduleId ? updateSchedule : createSchedule}
        formMethods={formMethods}
        submitButtonLabel={
          isSubmitSuccessful
            ? 'Close'
            : isEditMode
            ? `${scheduleId ? 'Update' : 'Create'} Schedule`
            : 'Next'
        }
        additionalActions={
          isEditMode && !isSubmitSuccessful ? (
            <Button onClick={toggleEditStructure} color={'attention'}>
              {editStructure ? (
                <>
                  <ArrowBackIcon />
                  Done Editing Categories
                </>
              ) : (
                'Add/Remove/Rename Categories'
              )}
            </Button>
          ) : null
        }
        hideSubmitButton={isEditMode && editStructure}
        queryLabel={'Loading schedule'}
        queryIsLoading={scheduleIsLoading}
      >
        {scheduleIsLoading ? null : isEditMode ? (
          editStructure ? (
            <ScheduleStructureEditor />
          ) : (
            <ScheduleTemplateEditor />
          )
        ) : (
          <ScheduleTemplateSelect />
        )}
      </GenericMutationDialogContent>
    </>
  );

  // return (
  //   <>
  //     <Dialog open={true} fullWidth maxWidth={'md'}>
  //       {createSchedule.isLoading ||
  //       updateSchedule.isLoading ||
  //       updateCallflow.isLoading ||
  //       scheduleIsLoading ? (
  //         <DialogInProgress
  //           title={
  //             <>
  //               {scheduleIsLoading ? (
  //                 'Loading schedule...'
  //               ) : scheduleId ? (
  //                 'Updating Schedule...'
  //               ) : (
  //                 <>
  //                   <Typography variant="h6">
  //                     One moment while we create your schedule...
  //                   </Typography>
  //                 </>
  //               )}
  //             </>
  //           }
  //         />
  //       ) : (
  //         <>
  //           {isSubmitSuccessful ? null : !scheduleId &&
  //              ? (
  //             <DialogTitle>
  //               <Grid container alignItems={'center'}>
  //                 {/* <Grid sx={{ flex: 1 }} item>
  //                   {isEditMode ? 'Edit Schedule' : 'Choose your schedule'}
  //                 </Grid> */}
  //                 {!scheduleId && isEditMode ? <Grid item></Grid> : null}
  //               </Grid>
  //             </DialogTitle>
  //           ) : null}
  //           <DialogContent dividers>
  //             {isSubmitSuccessful ? (
  //               <div
  //                 className={
  //                   'flex items-center space-x-1 justify-center text-lg font-medium'
  //                 }
  //               >
  //                 <CheckCircleIcon className={'text-green-400 text-2xl'} />
  //                 <div>
  //                   {scheduleId ? 'Schedule updated' : 'Schedule created'}
  //                 </div>
  //               </div>
  //             ) : errorLoadingSchedule ? (
  //               <Typography color={'error'}>Error loading schedule.</Typography>
  //             ) : (
  //               <>
  //                 <FormProvider {...formMethods}></FormProvider>
  //               </>
  //             )}
  //           </DialogContent>
  //           <DialogActions
  //             sx={{
  //               justifyContent: isSubmitSuccessful ? 'end' : 'space-between',
  //             }}
  //           >
  //             {(isEditMode && editStructure) || isSubmitSuccessful ? null : (
  //               <Button
  //                 variant={'outlined'}
  //                 color={'error'}
  //                 onClick={handleCancel}
  //               >
  //                 {'Cancel'}
  //               </Button>
  //             )}
  //             {createSchedule.error || updateSchedule.error ? (
  //               <Typography variant={'h6'} color={'error'}>
  //                 Error saving schedule.
  //               </Typography>
  //             ) : null}
  //             { ? null : errorLoadingSchedule ? null : (
  //               <Button
  //                 onClick={
  //                   isSubmitSuccessful
  //                     ? handleComplete
  //                     :
  //                 }
  //                 variant={'contained'}
  //                 color={'success'}
  //               >
  //                 {}
  //               </Button>
  //             )}
  //           </DialogActions>
  //         </>
  //       )}
  //     </Dialog>
  //   </>
  // );
};

// export const useScheduleEditorDialog = DialogBuilder(ScheduleEditorDialog);

export default ScheduleEditorDialog;

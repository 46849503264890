import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
// import { patch } from 'app/sdk/utils/kazoo';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { UserCreateDoc, UserUpdatePartialDoc } from 'types/user';

export const useDeleteUser = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, string, unknown>(async (userId: string) =>
    sdk.user.mutate.delete(userId, authState?.auth_token),
  );
};

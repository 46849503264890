import useMediaQuery from 'app/hooks/queries/media/useMediaQuery';
import useMediaUrl from 'app/hooks/queries/media/useMediaUrl';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
// MUST be imported!
import { IconButton, Popover, Slider } from 'app/design-lib';
import {
  Play,
  Pause,
  SoundHigh,
  SoundOff,
  SoundLow,
  SoundMin,
  EditPencil,
} from 'iconoir-react';
import { format } from 'date-fns';
import { formatDigits } from 'app/reports/components/utils';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { useMedia } from 'react-use';

interface AudioPlayerProps {
  mini?: boolean;
  mediaId: string;
  updated?: any;
  type?: 'VMBOX_MESSAGE';
  vmboxId?: string;
  onPlay?: () => void;
  onEdit?: () => void;
  variant?: 'fill' | 'ghost';
  autoPlay?: boolean;
}

export const durationFormat = totalSeconds => {
  if (!totalSeconds) {
    return '0:00';
  }
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return `${minutes}:${formatDigits(Math.floor(seconds))}`;
};

const AudioPlayer = ({
  mediaId,
  updated,
  type,
  mini = false,
  vmboxId,
  variant = 'fill',
  onPlay,
  onEdit,
  autoPlay,
}: AudioPlayerProps) => {
  const { data: media } = useMediaQuery({
    id: mediaId,
    options: { enabled: !!mediaId },
  });

  const mediaUrl = useMediaUrl({
    id: mediaId,
    updatedAt: updated?.toString() ?? media?.updatedAt ?? '',
    type,
    vmboxId,
  });
  const audioEl = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    audioEl.current?.load();
  }, [mediaId, updated]);

  const {
    currentTime,
    duration = 0,
    playing,
    setPlaying,
    setClickedTime,
    currentVolume,
    setClickedVolume,
  } = useAudioPlayer({ audioRef: audioEl, autoPlay });

  return (
    <div>
      <audio
        ref={audioEl}
        preload="metadata"
        // controls
        controlsList={'nodownload'}
        // style={{ height: 50 }}
        onPlay={onPlay}
        onEnded={() => {
          setPlaying(false);
          // handleEnded && handleEnded(mediaId);
        }}
        // controlsList='nodownload'
      >
        <source src={mediaUrl} />
        <p> Your browser doesn't support the audio tag </p>
      </audio>
      <Bar
        mini={mini}
        variant={variant}
        playing={playing}
        setPlaying={setPlaying}
        duration={duration}
        currentTime={currentTime}
        onTimeUpdate={setClickedTime}
        onVolumeUpdate={setClickedVolume}
        currentVolume={currentVolume}
        onEdit={onEdit}
      />
    </div>
  );
};

const Bar = props => {
  const {
    playing,
    setPlaying,
    duration,
    currentTime,
    onTimeUpdate,
    onVolumeUpdate,
    currentVolume,
    variant,
    mini,
    onEdit,
  } = props;

  return (
    <div
      className={`${
        variant === 'fill' ? 'border border-neutral-20 h-10' : 'h-8'
      }  ${
        mini ? 'w-full' : 'min-w-[200px]'
      }  px-3 flex space-x-2 items-center rounded-full  w-full`}
    >
      {mini ? (
        <IconButton
          size={'sm'}
          color={'neutral'}
          variant={'ghost'}
          pill
          onClick={() => setPlaying(prev => !prev)}
        >
          {playing ? <Pause fr={undefined} /> : <Play fr={undefined} />}
        </IconButton>
      ) : (
        <div className={`text-sm w-8 text-center`}>
          {durationFormat(currentTime)}
        </div>
      )}
      <div className={`flex-1`}>
        <Slider
          max={duration}
          value={currentTime ?? 0}
          step={0.1}
          onChange={onTimeUpdate}
        />
      </div>
      {mini ? null : (
        <div className={`text-sm w-8  text-center`}>
          {durationFormat(duration)}
        </div>
      )}
      {mini ? null : (
        <IconButton
          size={'sm'}
          color={'neutral'}
          variant={'ghost'}
          pill
          onClick={() => setPlaying(prev => !prev)}
        >
          {playing ? <Pause fr={undefined} /> : <Play fr={undefined} />}
        </IconButton>
      )}
      {mini && onEdit ? (
        <IconButton
          size={'sm'}
          color={'neutral'}
          variant={'ghost'}
          pill
          onClick={onEdit}
        >
          <EditPencil fr={undefined} />
        </IconButton>
      ) : (
        <Popover
          iconButtonProps={{
            children:
              currentVolume === 0 ? (
                <SoundMin fr={undefined} />
              ) : currentVolume === 1 ? (
                <SoundHigh fr={undefined} />
              ) : (
                <SoundLow fr={undefined} />
              ),
          }}
        >
          <div
            className={'space-y-1 flex flex-col items-center justify-center'}
          >
            <IconButton
              size={'sm'}
              color={'neutral'}
              variant={'ghost'}
              pill
              onClick={() => onVolumeUpdate(1)}
            >
              {<SoundHigh fr={undefined} />}
            </IconButton>
            <div className={`h-20`}>
              <Slider
                orientation={'vertical'}
                value={currentVolume}
                max={1}
                step={0.01}
                onChange={onVolumeUpdate}
              />
            </div>
            <IconButton
              size={'sm'}
              color={'neutral'}
              variant={'ghost'}
              pill
              onClick={() => onVolumeUpdate(0.01)}
            >
              <SoundLow fr={undefined} />
            </IconButton>
          </div>
        </Popover>
      )}
    </div>
  );

  // return (
  //   <Grid container spacing={1} noWrap>
  //     <Grid item>
  //       {playing ? (
  //         <PauseCircleFilled
  //           className={classes.playPauseIcon}
  //           onClick={() => setPlaying(false)}
  //         />
  //       ) : (
  //         <PlayCircleFilled
  //           className={classes.playPauseIcon}
  //           onClick={() => setPlaying(true)}
  //         />
  //       )}
  //     </Grid>
  //     <Grid item>
  //       <Typography className={classes.timing}>
  //         {formatDuration(currentTime)}
  //       </Typography>
  //     </Grid>
  //     <Grid item style={{ flexGrow: 1 }}>
  //       <div
  //         ref={progressRef}
  //         className={classes.progressBar}
  //         onPointerDown={e => handleTimeDrag(e)}
  //       >
  //         <div
  //           className={classes.knob}
  //           // className="bar__progress__knob"
  //           // style={{ left: `${currentPercentage - 2}%` }}
  //         >
  //           &nbsp;
  //         </div>
  //       </div>
  //     </Grid>
  //     <Grid item className="bar__time">
  //       <Typography className={classes.duration}>
  //         {formatDuration(duration)}
  //       </Typography>
  //     </Grid>
  //   </Grid>
  // );
};

const useAudioPlayer = ({ audioRef, autoPlay = false }) => {
  const [duration, setDuration] = useState();
  const [currentTime, setCurrentTime] = useState<any>();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState<any>();
  const [clickedVolume, setClickedVolume] = useState<any>();
  const [currentVolume, setCurrentVolume] = useState<any>();
  const autoPlayedRef = useRef(false);

  useEffect(() => {
    if (!audioRef || !audioRef.current) {
      return;
    }

    const audio = audioRef.current;

    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
      setCurrentVolume(audio.volume);

      if (audio.duration === Infinity) {
        // set egregious current time, this triggers the duration to be corrected
        audio.currentTime = new Date().getTime();

        // reset current time
        setTimeout(() => {
          audio.currentTime = 0;
        }, 10);
      }
    };

    const setAudioTime = () => setCurrentTime(audio.currentTime);
    const setAudioVolume = () => setCurrentVolume(audio.volume);

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);
    audio.addEventListener('volumechange', setAudioVolume);

    if (clickedTime && clickedTime !== currentTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    if (clickedVolume && clickedVolume !== currentVolume) {
      audio.volume = clickedVolume;
      setClickedVolume(null);
    }

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
      audio.removeEventListener('volumechange', setAudioVolume);
    };
  }, [audioRef, clickedTime, currentTime, clickedVolume, currentVolume]);

  useEffect(() => {
    if (!audioRef || !audioRef.current) {
      return;
    }
    const audio = audioRef.current;

    if (playing || (autoPlay && !playing && autoPlayedRef.current === false)) {
      autoPlayedRef.current = true;
      audio.play();
    } else {
      audio.pause();
    }
  }, [audioRef, playing]);

  // this effect and the below if statement is used to fix a bug when audio is recorded
  // in google chrome having a duration of infinity initially (making the audio not seekable)
  // see https://bugs.chromium.org/p/chromium/issues/detail?id=642012
  useEffect(() => {
    const audio = audioRef.current;

    if (duration === Infinity) {
      // update the correct duration
      setDuration(audio.duration);
    }
  }, [audioRef?.current?.duration]);

  const audio = audioRef?.current;
  if (audio?.duration === Infinity) {
    // set egregious current time, this triggers the duration to be corrected
    audio.currentTime = new Date().getTime();

    // reset current time
    setTimeout(() => {
      audio.currentTime = 0;
    }, 10);
  }

  return {
    currentTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    currentVolume,
    setClickedVolume,
  };
};

export default AudioPlayer;

// link to theme explorer: https://mui.com/customization/default-theme/

// Figure out how to fix Typescript so we can add new color variations
// TODO: https://mui.com/customization/palette/#adding-new-colors

const customPalette = {
  content: {
    color: '#8BD6E5', // for text
    background: '#121212',
  },
  // sidebar: {
  //   main: "#00BCD4",
  //   light: "#62EFFF",
  //   dark: "#008BA3",
  //   contrastText: "#fff",
  // },
};
const palette = {
  mode: 'dark',
  ...customPalette,
  primary: {
    main: '#65CADD', // gets used for outline/border of Input (should instead have "white" as a color for input!)
    light: '#62EFFF',
    dark: '#008BA3',
    contrastText: '#fff',
  },
  // secondary: {
  //   main: "#EAB464",
  //   light: "#FFE693",
  //   dark: "#B58437",
  //   contrastText: "#fff",
  // },
  // error: {
  //   main: "#F44336",
  //   light: "#F88078",
  //   dark: "#E31B0C",
  //   contrastText: "#fff",
  // },
  // info: {
  //   main: "#2196F3",
  //   light: "#64B6F7",
  //   dark: "#0B79D0",
  //   contrastText: "#fff",
  // },
  // warning: {
  //   main: "#ED6C02",
  //   light: "#FFB547",
  //   dark: "#C77700",
  //   contrastText: "#fff",
  // },
  // success: {
  //   main: "#4CAF50",
  //   light: "#7BC67E",
  //   dark: "#3B873E",
  //   contrastText: "#fff",
  // },
  gray: {
    main: '#9e9e9e',
    light: '#300',
    dark: '#424242',
    contrastText: '#fff',
  },
  white: {
    main: '#fff',
    light: '#fff',
    dark: '#fff',
    contrastText: '#fff',
  },
  black: {
    main: '#000',
    light: '#000',
    dark: '#000',
    contrastText: '#000',
  },
  background: {
    paper: '#0F2C36',
    default: '#121212',
  },
  card: {
    border: '#D6E8EB',
  },
  action: {
    active: '#fff',
    hover: 'rgba(255, 255, 255, 0.08)',
    selected: 'rgba(255, 255, 255, 0.16)',
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  text: {
    primary: customPalette.content.color, // #0B2027
    secondary: 'rgba(255, 255, 255, 0.7)', // do not remove this property; @react-theming/storybook-addon will throw error
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
};

export default palette;

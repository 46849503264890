import {
  AnimationDialog,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { Dialog } from 'app/design-lib';
import { getTimeZones } from '@vvo/tzdb';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useUpdateAccountPartial } from 'app/hooks/mutations/account';
import { useAccountQuery } from 'app/hooks/queries/account';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const TIMEZONE_LIST = getTimeZones().map(tz => ({
  label: `${tz.name.replace('_', ' ')} (${tz.abbreviation})`,
  value: tz.name,
}));

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface AccountTimezoneForm {
  timezone?: { label: string; value: string };
}

// interface declaring which props are required/allowed
interface AccountTimezoneDialogContentProps {
  onCancel: () => void;
  onComplete: () => void;
}

interface AccountTimezoneDialogProps {
  ContentProps: AccountTimezoneDialogContentProps;
  DialogProps: AnimationDialogProps;
}

const TimezoneDialogAccount = ({
  ContentProps,
  DialogProps,
}: AccountTimezoneDialogProps) => {
  return (
    <Dialog open={DialogProps.open} onClose={ContentProps.onCancel}>
      <TimezoneDialogAccountContent {...ContentProps} />
    </Dialog>
  );
};

const TimezoneDialogAccountContent = ({
  onCancel,
  onComplete,
}: AccountTimezoneDialogContentProps) => {
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = useAccountQuery();
  const updateAccount = useUpdateAccountPartial();

  const formMethods = useForm<AccountTimezoneForm>();
  const { control, handleSubmit, setError } = formMethods;

  const onSubmit = async (accountForm: AccountTimezoneForm) => {
    const { timezone } = accountForm;

    try {
      const resp = await updateAccount.mutateAsync({
        id: account?.id!,
        timezone: timezone?.value ?? null,
      });

      if (resp.status === 'success') {
        refetchAccount();
        // onComplete();
      }
    } catch (e: any) {
      parseAndSetKazooMutationErrors({
        response: e.response,
        setError,
      });
    }
  };

  return (
    <GenericMutationDialogContent
      onCancel={onCancel}
      onComplete={onComplete}
      onSubmit={handleSubmit(onSubmit)}
      onSuccessLabel={'Account timezone has been updated'}
      isLoadingLabel={'Updating account timezone...'}
      title={'Edit Account Timezone'}
      mutation={updateAccount}
      formMethods={formMethods}
      queryIsLoading={accountIsLoading}
      queryLabel={'Loading account timezone'}
    >
      <Controller
        name="timezone"
        control={control}
        defaultValue={TIMEZONE_LIST.find(
          tz => tz.value === account?.doc.timezone,
        )}
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            options={TIMEZONE_LIST}
            getOptionLabel={option => option.label}
            value={value}
            onChange={(event, value) => {
              onChange(value);
            }}
            renderInput={params => {
              // @ts-ignore
              params.InputLabelProps.shrink = true;
              return (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    className:
                      'focus:border-0 p-0 m-0 h-max max-h-[0.2rem] focus:ring-0 focus:outline-none',
                  }}
                  label={'Timezone'}
                  placeholder={'select'}
                />
              );
            }}
          />
        )}
      />
    </GenericMutationDialogContent>
  );
};

export default TimezoneDialogAccount;

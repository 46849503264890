import { useEffect, useReducer } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

export const QUERY_PAGE_INDEX_CHANGED = 'PAGE_CHANGED';
export const QUERY_PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
export const QUERY_ORDER_BY_CHANGED = 'ORDER_BY_CHANGED';
export const QUERY_SEARCH_INPUT_CHANGED = 'SEARCH_INPUT_CHANGED';
const PAGE_DEFUALT = 1;
const SIZE_DEFAULT = 10;

const tablePaginationReducer = (state, { type, payload }) => {
  switch (type) {
    case QUERY_PAGE_INDEX_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case QUERY_PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case QUERY_ORDER_BY_CHANGED:
      return {
        ...state,
        queryOrderBy: payload,
      };
    case QUERY_SEARCH_INPUT_CHANGED:
      return {
        ...state,
        querySearchInput: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const useQueryPaginationReducer = (
  initialQueryPageIndex = 0,
  initialQueryPageSize = 10,
  initialQueryOrderBy = [['createdAt', 'desc']],
  initialQuerySearchInput = '',
) => {
  return useReducer(tablePaginationReducer, {
    queryPageIndex: initialQueryPageIndex,
    queryPageSize: initialQueryPageSize,
    queryOrderBy: initialQueryOrderBy,
    querySearchInput: initialQuerySearchInput,
  });
};

export const mapQueryOrderByToTableSortBy = (orderBy: string[][]) => {
  return orderBy.map(order => ({
    id: order[0],
    desc: order[1] === 'desc',
  }));
};

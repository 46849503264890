import { Tooltip } from '@mui/material';
import { Button, ButtonGroup, Typography } from 'app/design';
import {
  Edit as EditIcon,
  Close as CloseIcon,
} from 'app/design/icons-material';
import {
  AddPhonesFormFields,
  PhoneFields,
} from 'app/components/CreatePhonesBulk/components/AddPhonesBulkForm/types';
import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useUserQuery } from 'app/hooks/queries/user';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { HookFormListbox } from 'app/components/reactHookFormComponents/HookFormListbox';
import { User } from 'types/user';

interface OwnerIdFieldProps {
  index: number;
  phoneFields: PhoneFields;
  setValue: UseFormSetValue<AddPhonesFormFields>;
  disabled: boolean;
  formErrors: any;
  users: User[];
}

const OwnerIdField = ({
  index,
  phoneFields,
  setValue,
  disabled,
  formErrors,
  users,
}: OwnerIdFieldProps) => {
  const owner = phoneFields.owner_id;

  const { data: user, isFetching } = useUserQuery(owner);

  const [showOwnerDialog, toggleShowOwnerDialog] = useToggleReducer(false);

  const handleSelect = (selected: string[]) => {
    const [userId] = selected;
    // @ts-ignore
    setValue(`phones.${index}.owner_id`, userId);
    toggleShowOwnerDialog();
  };

  const handleClearFromDialog = () => {
    handleClear();
    toggleShowOwnerDialog();
  };

  const handleClear = () => {
    // @ts-ignore
    setValue(`phones.${index}.owner_id`, undefined);
  };

  return (
    <HookFormListbox
      name={`phones.${index}.owner_id`}
      options={users.map(user => ({
        value: user.id,
        label: user.extra.fullName!,
      }))}
      optional
      label={'Owner'}
      className={`w-full`}
    />
  );

  // return (
  //   <>
  //     <QuickFinderUserDialog
  //       open={showOwnerDialog}
  //       onSelect={handleSelect}
  //       onCancel={toggleShowOwnerDialog}
  //       onClear={handleClearFromDialog}
  //       initialSelected={owner ? [owner] : undefined}
  //       selectionTitle={
  //         user ? `${user.doc.first_name} ${user.doc.last_name}` : null
  //       }
  //     />
  //     <ButtonGroup
  //       variant="outlined"
  //       sx={{ width: '100%' }}
  //       disabled={disabled}
  //     >
  //       <Tooltip arrow title={'The owner of this device'}>
  //         <Button
  //           onClick={toggleShowOwnerDialog}
  //           variant="outlined"
  //           sx={{
  //             overflowX: 'hidden',
  //           }}
  //           // color={
  //           //   !!formErrors.phones?.[index]?.owner_id
  //           //     ? 'error'
  //           //     : owner
  //           //     ? 'primary'
  //           //     : 'gray'
  //           // }
  //         >
  //           <div
  //             style={{
  //               width: '100%',
  //               whiteSpace: 'nowrap',
  //               textOverflow: 'ellipsis',
  //               overflowX: 'hidden',
  //             }}
  //           >
  //             {user ? `${user?.extra?.fullName}` : 'Set Owner'}
  //           </div>
  //         </Button>
  //       </Tooltip>
  //       {owner ? (
  //         <>
  //           <Tooltip arrow title={'Change owner'}>
  //             <Button
  //               onClick={toggleShowOwnerDialog}
  //               variant="outlined"
  //               size="small"
  //               // color={
  //               //   !!formErrors.phones?.[index]?.owner_id
  //               //     ? 'error'
  //               //     : owner
  //               //     ? 'primary'
  //               //     : 'gray'
  //               // }
  //             >
  //               <EditIcon />
  //             </Button>
  //           </Tooltip>
  //           <Tooltip arrow title={'Remove owner'}>
  //             <Button
  //               // color={
  //               //   !!formErrors.phones?.[index]?.owner_id
  //               //     ? 'error'
  //               //     : owner
  //               //     ? 'primary'
  //               //     : 'gray'
  //               // }
  //               onClick={handleClear}
  //               variant="outlined"
  //               size="small"
  //             >
  //               <CloseIcon />
  //             </Button>
  //           </Tooltip>
  //         </>
  //       ) : null}
  //     </ButtonGroup>
  //     {!!formErrors.phones?.[index]?.owner_id ? (
  //       <Typography color={'error'} variant={'caption'}>
  //         {formErrors.phones[index].owner_id.message}
  //       </Typography>
  //     ) : null}
  //   </>
  // );
};

export default OwnerIdField;

import { gql } from 'graphql-request';

export default gql`
  query Query(
    $skip: Int
    $take: Int
    $filters: JSON
    $orderBy: JSON
    $mask: String
  ) {
    vmboxes(
      skip: $skip
      take: $take
      filters: $filters
      orderBy: $orderBy
      mask: $mask
    ) {
      totalCount
      vmboxes {
        id
        doc
        createdAt
        listing
      }
    }
  }
`;

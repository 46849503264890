import { Grid, Typography } from 'app/design';
import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { AddUsersBulkForm } from 'app/components/CreateUsersBulk/components/AddUsersBulkForm';
import { AddUsersFormFields } from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/types';
import { DialogLoading } from 'app/design-lib';
import { useCreateCallflow } from 'app/hooks/mutations/callflow';
import { useCreateUser } from 'app/hooks/mutations/user';
import { useCreateVmbox } from 'app/hooks/mutations/vmbox';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { useListVmboxesQuery } from 'app/hooks/queries/vmbox';
import React, { useReducer } from 'react';
import { useImmer, useImmerReducer } from 'use-immer';
import { usePipesQuery } from 'app/pages/v2/common/sdk';
import { store } from 'store';

export const CreateUsersBulk = props => {
  // Load all Users and Extensions and VmBoxes (anything where there can be a conflict)
  const [isRefetching, setIsRefetching] = useImmer(false);

  // Users
  const {
    data: usersResp,
    isLoading: usersIsLoading,
    refetch: refetchUsers,
  } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};

  const pipesQuery = usePipesQuery({
    filters: {
      users: {
        path: [store.getState().auth.owner_id, 'enabled'],
        equals: true,
      },
      NOT: {
        type: 'room',
      },
    },
  });

  // Extensions
  const {
    data: extensionsResult = {},
    isLoading: extensionsIsLoading,
    refetch: refetchExtensions,
  } = useListExtensionsQuery({
    mask: 'number,callflow(id,doc(type,owner_type))', // mask
  });
  // @ts-ignore
  const { extensions = [] } = extensionsResult ?? {};

  // VoicemailBoxes
  const {
    data: vmboxesResp,
    isLoading: vmboxesIsLoading,
    refetch: refetchVmboxes,
  } = useListVmboxesQuery({
    mask: 'id,doc(mailbox)', // mask
    quick: true, // quick
  });
  // @ts-ignore
  const { vmboxes = [] } = vmboxesResp?.vmboxes ?? {};

  const isLoading =
    vmboxesIsLoading ||
    extensionsIsLoading ||
    usersIsLoading ||
    pipesQuery.isLoading;

  const handleRefetch = async (silent = true) => {
    // silent to not show backdrop unless it is an error
    if (silent) {
      await Promise.all([
        refetchUsers(),
        refetchVmboxes(),
        refetchExtensions(),
      ]);
    } else {
      setIsRefetching(true);
      await Promise.all([
        refetchUsers(),
        refetchVmboxes(),
        refetchExtensions(),
      ]);
      setIsRefetching(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <DialogLoading
          label={'Loading existing users data...'}
          secondaryText={'This may take a moment.'}
        />
      ) : (
        <AddUsersBulkForm
          lines={pipesQuery.data.pipes}
          users={users}
          extensions={extensions}
          vmboxes={vmboxes}
          onRefetch={handleRefetch}
          isRefetching={isRefetching}
          onComplete={props.onComplete}
        />
      )}
    </>
  );
};

export default CreateUsersBulk;

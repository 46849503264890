// Default PUT-building function
import { PostBase64Payload } from 'types/methods';
import buildResourceUrl from './buildResourceUrl';
import { postBase64 } from './methods';

// Default POST-building function
// - uses buildResourceUrl and passes thru options from opts.buildUrlOpts
export const buildPostBase64 =
  (resourceApiPathname, opts?) =>
  async ({ id, encodedFile }: PostBase64Payload, auth_token) => {
    // uses a POST to upload a encodedFile

    // TODO: any checks on POST?

    return postResourceBase64({
      url: buildResourceUrl(
        resourceApiPathname,
        id,
        auth_token,
        opts?.buildUrlOpts,
      ),
      encodedFile,
      auth_token,
    });
  };

export const postResourceBase64 = async ({ url, encodedFile, auth_token }) => {
  const resp = await postBase64(url, encodedFile, {
    auth_token,
    contentType: 'application/x-base64',
  });
  return resp.data;
};

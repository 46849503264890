import { textToSlateTemporalRows } from 'app/utilities';

export enum ScheduleTemplate {
  DentalOffice = 'dental_office',
  Restaurant = 'restaurant',
}

export const generateEmptyRule = (name?) => ({
  id: `${name}_${Date.now()}`,
  name: name ?? 'Unnamed Time',
  rule: {
    type: 'free_form_multiple', // template id like "single_day"
    data: {
      value: textToSlateTemporalRows(``),
    }, // data for the rule (specific to the template!)
    outputRuleIds: [], // temporal_rules generated (just the ids)
  },
});

export const SCHEDULE_TEMPLATES = {
  [ScheduleTemplate.Restaurant]: {
    description:
      'Simple schedule with Open, Holiday, and Closed hours. Perfect for businesses with continuous hours.',
    value: [
      {
        id: 'open',
        name: 'Open',
        rule: {
          type: 'free_form_multiple', // template id like "single_day"
          data: {
            value: textToSlateTemporalRows(`
             Mon 11:00am - 8:00pm
             Tue 11:00am - 8:00pm
             Wed 11:00am - 8:00pm
             Thu 11:00am - 8:00pm
             Fri 11:00am - 8:00pm
             Sat 11:00am - 8:00pm
             Sun 11:00am - 8:00pm
              `),
          }, // data for the rule (specific to the template!)
          outputRuleIds: [], // temporal_rules generated (just the ids)
        },
      },
      {
        id: 'holiday',
        name: 'Holiday',
        rule: {
          type: 'free_form_multiple', // template id like "single_day"
          data: {
            value: textToSlateTemporalRows(`
             Dec 25
             Jan 1
              `),
          }, // data for the rule (specific to the template!)
          outputRuleIds: [], // temporal_rules generated (just the ids)
        },
      },
      {
        isElse: true,
        id: 'skip',
        name: 'Closed',
      },
    ],
  },
  [ScheduleTemplate.DentalOffice]: {
    description:
      'Schedule with Open, Lunch, Holiday, and Closed hours. Perfect for businesses with intermittent hours throughout the day.',
    value: [
      {
        id: 'open',
        name: 'Open',
        rule: {
          type: 'free_form_multiple', // template id like "single_day"
          data: {
            value: textToSlateTemporalRows(`
             Mon 9:00am - 5:00pm
             Tue 9:00am - 5:00pm
             Wed 9:00am - 5:00pm
             Thu 9:00am - 5:00pm
             Fri 9:00am - 5:00pm
              `),
          }, // data for the rule (specific to the template!)
          outputRuleIds: [], // temporal_rules generated (just the ids)
        },
      },
      {
        id: 'lunch',
        name: 'Lunch',
        rule: {
          type: 'free_form_multiple', // template id like "single_day"
          data: {
            value: textToSlateTemporalRows(`
             Mon 12:00pm - 1:00pm
             Tue 12:00pm - 1:00pm
             Wed 12:00pm - 1:00pm
             Thu 12:00pm - 1:00pm
             Fri 12:00pm - 1:00pm
              `),
          }, // data for the rule (specific to the template!)
          outputRuleIds: [], // temporal_rules generated (just the ids)
        },
      },
      {
        id: 'holiday',
        name: 'Holiday',
        rule: {
          type: 'free_form_multiple', // template id like "single_day"
          data: {
            value: textToSlateTemporalRows(`
             Dec 24 - 31
             Jan 1
             Jul 4

              `),
          }, // data for the rule (specific to the template!)
          outputRuleIds: [], // temporal_rules generated (just the ids)
        },
      },
      {
        isElse: true,
        id: 'skip',
        name: 'Closed',
      },
    ],
  },
};

import { Grid, InfoTooltip, Link, Typography } from 'app/design';
import { Button } from 'app/design-lib';
import React, { ReactNode } from 'react';

interface GenericSettingsField {
  title: ReactNode;
  titleHelp?: ReactNode | string | any;
  content?: ReactNode;
  caption?: ReactNode;
  chip?: ReactNode;
  editLabel?: string;
  editOnClick?: () => void;
}

const GenericSettingsCardField = ({
  title,
  titleHelp,
  content,
  chip,
  caption,
  editLabel,
  editOnClick,
}: GenericSettingsField) => {
  return (
    <div className={`flex w-full justify-between`}>
      <div className={'w-full'}>
        <div>
          <div>
            <span className={'text-neutral-500 font-medium text-lg font-sans'}>
              {title}{' '}
              {titleHelp ? (
                <InfoTooltip title={titleHelp} size={'small'} />
              ) : null}{' '}
              {chip ? (
                <div style={{ display: 'inline-block', marginBottom: 5 }}>
                  {chip}
                </div>
              ) : null}
            </span>
            {caption ? (
              <span className={'text-sm text-content/50'}>{caption}</span>
            ) : null}
          </div>
        </div>
        {content ? (
          <div className={'w-full text-lg'}>{content}</div>
        ) : (
          <span className={'text-lg italic text-content/50'}>Not set...</span>
        )}
      </div>
      {editOnClick ? (
        <div className={'h-full flex'}>
          <Button
            variant={'ghost'}
            color={'accent'}
            // variant="body2"
            onClick={editOnClick}
            // underline="hover"
            className={'font-sans text-content-access'}
          >
            {editLabel ?? `Edit ${title}`}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default GenericSettingsCardField;

import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';
import deviceQueryKeys from 'app/hooks/queries/device/deviceQueryKeys';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { DeviceCreateDoc } from 'types/device';

export const useCreateDevice = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation(
    (doc: DeviceCreateDoc) =>
      sdk.device.mutate.create(doc, authState?.auth_token),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(deviceQueryKeys.all);
        await queryClient.invalidateQueries(callflowQueryKeys.all);
      },
    },
  );
};

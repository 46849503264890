import { gql } from 'graphql-request';

export default gql`
  query Query {
    contacts {
      id
      source
      schema
      info
      # totalCount
      # contacts {
      #   id
      #   source
      #   schema
      #   info
      # }
    }
  }
`;

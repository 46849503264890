import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Group } from 'types/group';
import groupQueryKeys from './groupQueryKeys';

export const useGroupQuery = (
  id: string | undefined,
  options?: Omit<
    UseQueryOptions<Group, Error, Group, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = groupQueryKeys.byId(id);

  const query = useQuery<Group, Error>(
    queryKey,
    () =>
      sdk.group.query.groupById({ id }, { authToken: authState.auth_token }),
    {
      enabled: id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useGroupQuery;

import { Dialog } from 'app/design-lib';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import Joi from 'joi';
import { useExtensionUsedByQuery } from 'app/hooks/general/useExtensionUseQuery';
import GenericMutationDialogContent from 'app/design/components/tailwind/GenericMutationDialogContent/GenericMutationDialogContent';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { ExtensionInUseWarnings } from 'app/components/ExtensionInUseWarnings';
import { User } from 'types/user';
import { usePipeNew } from 'app/pages/v2/settings/Pipes/Pipes';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { getNextExtension } from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/AddUsersBulkForm';

interface AddSharedLineProps {
  open: boolean;
  user?: User;
  onComplete: (lineId: string) => void;
  onCancel: () => void;
}
const AddSharedLineDialog = ({ open, ...props }: AddSharedLineProps) => {
  return (
    <Dialog open={open} onClose={props.onCancel}>
      <AddSharedLineDialogContent {...props} />
    </Dialog>
  );
};
const AddSharedLineDialogContent = ({
  user,
  onComplete,
  onCancel,
}: Omit<AddSharedLineProps, 'open'>) => {
  const pipeNew = usePipeNew();
  const formMethods = useForm({
    defaultValues: {
      ext: null as null | number, // not set till next ext is determined below
      name: '',
    },
    resolver: joiResolver(
      Joi.object({
        ext: Joi.number()
          .min(0)
          .message('Extension must be a positive value')
          .max(9999)
          .message('Extension cannot exceed 4 digits'),
        name: Joi.string().max(128),
      }),
    ),
  });
  const handleNewInternal = async ({ name, ext }: any) => {
    let users = {};
    if (user) {
      // @ts-ignore
      users[user.id] = { enabled: true };
    }

    // const ext = prompt('Extension:', '');
    // if (!ext?.length) {
    //   return null;
    // }

    // TODO: validate that extension is available!

    // const name = prompt('Group Name:', 'Sales');
    // if (!name?.length) {
    //   return null;
    // }

    // create:
    // - pipe
    // - call route
    // - vmbox

    const pipe = await pipeNew.mutateAsync({
      name: name,
      emoji: '1f64c', // raised hands
      ext: ext.toString(),
      type: 'shared',
      users,
    });
  };
  const { data: extensionsResp, isFetching: extensionAreLoading } =
    useListExtensionsQuery({
      mask: 'number',
      options: {
        onSuccess: extensionsResult => {
          // @ts-ignore
          const { extensions = [] } = extensionsResult ?? {};

          if (!formMethods.watch('ext'))
            formMethods.reset({
              ext: getNextExtension(300, null, [], extensions),
            });
        },
        // default to 300 on error
        onError: () =>
          formMethods.reset({
            ext: 300,
          }),
      },
    });

  const query = useExtensionUsedByQuery({
    exts: formMethods.formState.isSubmitSuccessful
      ? []
      : [formMethods.watch('ext')?.toString() ?? ''],
  });

  return (
    <GenericMutationDialogContent
      queryIsLoading={extensionAreLoading || !formMethods.watch('ext')}
      formMethods={formMethods}
      title={'Add Shared Line'}
      mutation={pipeNew}
      onComplete={() => {
        onComplete(pipeNew.data.id);
      }}
      onCancel={onCancel}
      onSubmit={formMethods.handleSubmit(handleNewInternal)}
      isLoadingLabel={'Adding shared line'}
      onSuccessLabel={'Shared line added'}
      submitButtonLabel={'Add'}
      submitButtonDisabled={
        pipeNew.isSuccess ? false : query.isFetching || query.match
      }
    >
      <div className={'flex flex-col space-y-8'}>
        <HookFormTextField name={'name'} label={'Line Name'} />
        <HookFormTextField name={'ext'} label={'Extension'} type={'number'} />
      </div>
      <ExtensionInUseWarnings query={query} />
    </GenericMutationDialogContent>
  );
};

export default AddSharedLineDialog;

// import { PayloadAction } from '@reduxjs/toolkit';
import { useAuthSelector } from 'app/data/auth';
// import { fetchUser } from 'app/sdk/utils';
import { sdk } from 'app/sdk';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { User } from 'types/user';
import userQueryKeys from './userQueryKeys';

export const useUserQuery = (
  id?: string | null,
  options?: Omit<
    UseQueryOptions<User, Error, User, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  // returns single user
  // - just get user and relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = userQueryKeys.byId(id);

  const query = useQuery<User, Error>(
    queryKey,
    () => sdk.user.query.userById({ id }, { authToken: authState.auth_token }), // fetchUser(id, authState.auth_token),
    {
      enabled: !!id, //authState?.auth_token_decoded?.owner_id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useUserQuery;
